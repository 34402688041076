import React from 'react';
import newsImage1 from '../images/news/bb.jpg'; // Adjust the path as needed
import newsImage2 from '../images/news/1.jpg'; // Adjust the path as needed
import newsImage3 from '../images/news/latest-news-img2.jpg'; // Adjust the path as needed
import { Link } from 'react-router-dom';

const LatestNews = () => {
  const newsArticles = [
    {
      date: { day: '10', month: 'MAR' },
      image: newsImage1,
      title: 'Enjoy Best Travel Experience',
      description: "At SSK Travels 24, we ensure your journeys are comfortable and hassle-free. Enjoy exceptional service and make the most of your adventures with us!"

,
      link: 'news-single.html',
    },
    {
      date: { day: '12', month: 'MAR' },
      image: newsImage2,
      title: 'The Future of Car Rent',
      description: "At SSK Travels 24, we prioritize your journey, ensuring that every trip is seamless and enjoyable, while you explore with peace of mind.",
      link: 'news-single.html',
    },
    
    {
      date: { day: '14', month: 'MAR' },
      image: newsImage3,
      title: 'Holiday Tips For Backpacker',
      description: ' Ready to backpack? Pack light, stay flexible, and use apps for easy navigation. Budget wisely at local spots and embrace the culture. Every trip is a new adventure',
      link: 'news-single.html',
    },
  ];

  return (
    <section id="section-news pt-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 text-center ">
          <div className=' justify-content-center align-items-center text-center'>
            <h2 className='bluecolor'>Latest News</h2>
            <p className='text-black'>Breaking news, fresh perspectives, and in-depth coverage - stay ahead with our latest news, insights, and analysis.</p>
            <div className="spacer-20"></div>
            </div>
           
          </div>

          {newsArticles.map((article, index) => (
            <div className="col-lg-4 mb10" key={index}>
              <div className="bloglist s2 item">
                <div className="post-content">
                  <div className="post-image">
                    <div className="date-box">
                      {/* <div className="m">{article.date.day}</div> */}
                      {/* <div className="d">{article.date.month}</div> */}
                    </div>
                    <img alt="" src={article.image} className="lazy" />
                  </div>
                  <div className="post-text">
                    <h4><a href={article.link}>{article.title}<span></span></a></h4>
                    <p>{article.description}</p>
                    <Link className="btn-main" to="/service">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
          
        </div>
      </div>
    </section>
  );
};

export default LatestNews;

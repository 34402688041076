
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Urbaniabusonrentinmumbai(){


    const cardData = 
    {
        keyword: 'Urbania Bus on Rent in Mumbai Airport',
        heading: 'SSKTravels24: Urbania Bus on Rent in Mumbai Airport',
        headingDescription: 'Are you landing at Mumbai Airport and looking for the perfect transportation option? Look no further! With SSKTravels24, you can rent an Urbania bus that not only provides comfort but also a luxurious experience as you explore the vibrant city of Mumbai. Whether you are traveling with family, friends, or a corporate group, our Urbania buses offer ample space, modern amenities, and professional drivers to ensure a hassle-free journey.',
        
        topPlaces : [
            {
                title: "1. Gateway of India",
                description: "An iconic landmark overlooking the Arabian Sea, the Gateway of India was built to commemorate King George V's visit in 1911. Its stunning architecture blends Hindu and Muslim styles, making it a perfect backdrop for photos. Visitors can enjoy the sea breeze and take boat rides to nearby attractions like Elephanta Island."
            },
            {
                title: "2. Marine Drive",
                description: "Often referred to as the 'Queen’s Necklace,' Marine Drive is a picturesque promenade that stretches along the coast of Mumbai. This 3.6-kilometer stretch is ideal for leisurely walks, especially at sunset when the lights twinkle like pearls. The surrounding skyline offers a magnificent view, making it a favorite spot for locals and tourists alike."
            },
            {
                title: "3. Chhatrapati Shivaji Maharaj Terminus",
                description: "A UNESCO World Heritage Site, Chhatrapati Shivaji Maharaj Terminus is a historic railway station showcasing stunning Victorian Gothic architecture. Its intricate details and grand clock tower make it a significant symbol of Mumbai. Visitors can explore the beautifully preserved interiors and learn about its historical importance."
            },
            {
                title: "4. Elephanta Caves",
                description: "Located on Elephanta Island, these rock-cut caves are famous for their impressive sculptures dedicated to Lord Shiva, including the monumental Trimurti. A ferry ride from the city takes you to this tranquil escape, where you can explore the caves, enjoy lush greenery, and take in the serene atmosphere away from the city's hustle."
            },
            {
                title: "5. Juhu Beach",
                description: "A favorite among locals, Juhu Beach is renowned for its vibrant atmosphere, golden sands, and delicious street food stalls. From vada pav to bhel puri, the beach is a food lover's paradise. Visitors can relax on the beach, watch the sunset, or participate in various recreational activities like horse riding and beach volleyball."
            },
            {
                title: "6. Colaba Causeway",
                description: "A bustling street market, Colaba Causeway is a haven for shopping enthusiasts. Here, you can find everything from trendy clothes and accessories to unique souvenirs and antiques. The lively atmosphere, combined with nearby cafes and restaurants, makes it an ideal spot to explore Mumbai's local culture."
            },
            {
                title: "7. Chhatrapati Shivaji Maharaj Vastu Sangrahalaya (CSMVS)",
                description: "This museum is a treasure trove of art and history, housing an extensive collection of artifacts ranging from ancient Indian sculptures to contemporary art. Its stunning Indo-Saracenic architecture adds to the charm, and visitors can enjoy guided tours to delve deeper into the exhibits and their significance."
            },
            {
                title: "8. Siddhivinayak Temple",
                description: "One of the most famous Hindu temples dedicated to Lord Ganesha, Siddhivinayak Temple attracts thousands of devotees and tourists alike. The temple’s stunning architecture, intricate carvings, and spiritual ambiance create a serene atmosphere, making it a must-visit for anyone exploring the city."
            },
            {
                title: "9. Bandra-Worli Sea Link",
                description: "An engineering marvel, the Bandra-Worli Sea Link connects the suburbs of Bandra and Worli, offering breathtaking views of the Mumbai skyline and the Arabian Sea. A drive across this cable-stayed bridge, especially at night, provides a unique perspective of the city’s beauty and is a popular spot for photography."
            },
            {
                title: "10. Haji Ali Dargah",
                description: "Situated on a tiny islet off the coast of Worli, Haji Ali Dargah is a significant religious site for both Muslims and Hindus. The beautiful white marble architecture and serene surroundings create a peaceful retreat. Visitors can walk along the causeway during low tide to reach the dargah and enjoy the picturesque views of the sea."
            }
        ],
        
        services : [
            {
                name: "Pune to Mumbai Airport Urbania on Rent",
                description: "Traveling from Pune to Mumbai Airport? Opt for our Urbania on rent service to enjoy a comfortable and stylish journey. Our buses are equipped with modern amenities like air conditioning, plush seating, and entertainment systems, ensuring a smooth ride for you and your group. Whether it’s for business or leisure, we guarantee a pleasant experience."
            },
            {
                name: "Mumbai Airport to Pune Urbania on Rent",
                description: "Arriving at Mumbai Airport? Choose our Urbania bus on rent for your trip back to Pune. With spacious seating, climate control, and a professional driver, you can relax and enjoy the scenic route. We ensure timely pickups to help you reach your destination without stress."
            },
            {
                name: "FORCE Urbania on Rent in Mumbai",
                description: "Experience the luxury of Force Urbania on rent in Mumbai. Ideal for group outings or corporate events, these buses offer the perfect blend of comfort and convenience. Enjoy features like reclining seats, ample luggage space, and a smooth ride, making your travel enjoyable and hassle-free."
            },
            {
                name: "Luxury Force Urbania on Rent in Pune",
                description: "Looking for a luxurious travel option? Our luxury Force Urbania on rent in Pune is perfect for special occasions. With plush seating, high-end entertainment systems, and modern facilities, your journey will be unforgettable. Whether it's a wedding or a corporate event, we provide the ultimate travel experience."
            },
            {
                name: "13 Seater Urbania on Rent in Pimpri Chinchwad",
                description: "Traveling in a small group? Our 13-seater Urbania on rent in Pimpri Chinchwad is perfect for family outings or business trips. Enjoy a spacious ride with comfortable seating, ensuring everyone travels together without compromising on comfort."
            },
            {
                name: "17 Seater Urbania on Rent in Pimpri Chinchwad",
                description: "For larger groups, we offer the 17-seater Urbania on rent in Pimpri Chinchwad. This option ensures everyone travels together, making it ideal for corporate events or group travel. With generous legroom and modern amenities, your journey will be both comfortable and enjoyable."
            },
            {
                name: "13 Seater Urbania on Rent in Pune",
                description: "Need a compact solution for your Pune travels? Rent our 13-seater Urbania in Pune for an efficient and comfortable ride that caters to smaller groups. Our well-maintained buses provide a reliable option for any occasion."
            },
            {
                name: "17 Seater Urbania on Rent in Pune",
                description: "Our 17-seater Urbania on rent in Pune is designed for larger groups looking for an affordable and spacious travel option. Perfect for both personal and corporate use, enjoy the comfort and convenience of our well-equipped buses."
            },
            {
                name: "Urbania Bus on Rent for Corporate Employees",
                description: "Enhance your corporate travel experience with our Urbania bus on rent for corporate employees. This service ensures your team travels comfortably and arrives at meetings refreshed and ready to perform. We focus on punctuality and professionalism."
            },
            {
                name: "Pune to Mumbai Urbania Bus on Rent",
                description: "Our Pune to Mumbai Urbania bus on rent service guarantees a stress-free travel experience. Book now for group travel that prioritizes comfort and convenience. With amenities designed for long-distance travel, enjoy the scenic route while relaxing with your group."
            },
            {
                name: "Force Urbania Luxury Mini Bus on Rent",
                description: "Discover the ultimate travel experience with our Force Urbania luxury mini bus on rent. This option is perfect for those who want to combine luxury with practicality during their travels. With premium seating and top-notch amenities, your group will travel in style."
            },
            {
                name: "Force Urbania 17 Seater for Rent",
                description: "If you need space and comfort, our Force Urbania 17-seater for rent is the right choice. Ideal for outings, this bus ensures that everyone travels together in style. With features like large windows and comfortable seating, it’s perfect for enjoying the views."
            },
            {
                name: "Force Urbania for Rent Near Me",
                description: "Searching for Force Urbania for rent near me? Look no further! We offer competitive rates and exceptional service for your convenience. Our local presence ensures quick bookings and easy access to our luxury bus services."
            },
            {
                name: "Force Urbania Luxury Mini Bus on Rent in Pune",
                description: "Explore Pune in style with our Force Urbania luxury mini bus on rent in Pune. Perfect for weddings, corporate events, or special occasions, this option combines luxury with comfort. We provide tailored services to meet your specific needs."
            },
            {
                name: "Pune to Mumbai Airport Urbania Bus on Rent",
                description: "Traveling to Mumbai Airport from Pune? Choose our Pune to Mumbai Airport Urbania bus on rent for a reliable and enjoyable journey. With professional drivers and a focus on customer satisfaction, we ensure a smooth ride every time."
            },
            {
                name: "Urbania on Rent in Pimpri Chinchwad",
                description: "If you’re in Pimpri Chinchwad, our Urbania on rent service is at your disposal. Enjoy group travel with the best amenities tailored for your needs. Our buses provide a comfortable and enjoyable ride for any occasion."
            },
            {
                name: "Urbania Bus On Rent in Mumbai Airport Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Urbania Bus On Rent in Mumbai Airport. We ensure a smooth and enjoyable ride for all our customers. Our dedicated team is ready to assist you with all your travel needs."
            }
        ],
        tableData : [
            ['- Pune to Mumbai Airport Urbania on Rent', '- Mumbai Airport to Pune Urbania on Rent'],
            ['- FORCE Urbania on Rent in Mumbai', '- Luxury Force Urbania on Rent in Pune'],
            ['- 13 Seater Urbania on Rent in Pimpri Chinchwad', '- 17 Seater Urbania on Rent in Pimpri Chinchwad'],
            ['- 13 Seater Urbania on Rent in Pune', '- 17 Seater Urbania on Rent in Pune'],
            ['- Urbania Bus on Rent for Corporate Employees', '- Pune to Mumbai Urbania Bus on Rent'],
            ['- Force Urbania Luxury Mini Bus on Rent', '- Force Urbania 17 Seater for Rent'],
            ['- Force Urbania for Rent Near Me', '- Force Urbania Luxury Mini Bus on Rent in Pune'],
            ['- Pune to Mumbai Airport Urbania Bus on Rent', '- Urbania on Rent in Pimpri Chinchwad']
        ],
        
    
        whychoose : [
            {
                "WhyChooseheading": "Why Choose Us for Urbania Bus on Rent in Mumbai Airport – SSKTravels24",
                "WhyChoosedescription": "Comfort and Luxury: Our Urbania buses are designed with your comfort in mind. Spacious seating, air conditioning, and modern amenities ensure a pleasant journey for you and your group."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "We provide experienced and professional drivers who are well-versed in the city’s routes. They prioritize your safety and comfort, allowing you to relax and enjoy the ride."
            },
            {
                "WhyChooseheading": "Timely Service:",
                "WhyChoosedescription": "Punctuality is our commitment. We understand the importance of timely arrivals, especially when it comes to catching flights. Our drivers will ensure you reach your destination on time."
            },
            {
                "WhyChooseheading": "Flexible Booking Options:",
                "WhyChoosedescription": "Whether you need a bus for a few hours or an entire day, we offer flexible rental options to suit your needs. Customize your travel itinerary and enjoy a seamless experience."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "We offer transparent pricing with no hidden costs. Our rates are competitive, ensuring you receive value for your money without compromising on quality."
            },
            {
                "WhyChooseheading": "Customer-Centric Approach:",
                "WhyChoosedescription": "At SSKTravels24, customer satisfaction is our top priority. Our dedicated support team is available to assist you with your bookings and answer any queries."
            },
            {
                "WhyChooseheading": "Group Travel Solutions:",
                "WhyChoosedescription": "Whether you’re traveling for business, events, or family outings, our Urbania buses accommodate groups of all sizes. Enjoy traveling together without the hassle of multiple vehicles."
            },
            {
                "WhyChooseheading": "Hygiene and Safety:",
                "WhyChoosedescription": "We adhere to strict hygiene protocols to ensure a clean and safe environment for all passengers. Your health and safety are paramount to us."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "How do I book an Urbania bus with SSKTravels24?",
            answer: "You can easily book an Urbania bus by visiting our website or contacting our customer service team. Provide your travel details, and we’ll take care of the rest!"
        },
        {
            question: "What is the capacity of the Urbania bus?",
            answer: "Our Urbania buses typically accommodate groups ranging from 13 to 17 passengers, making them ideal for both small and medium-sized groups."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes! Our Urbania buses come with comfortable seating, air conditioning, and ample luggage space. Some may also feature entertainment systems."
        },
        {
            question: "Can I customize my travel itinerary?",
            answer: "Absolutely! We offer flexible booking options, allowing you to customize your travel itinerary according to your needs."
        },
        {
            question: "What is the cancellation policy?",
            answer: "Our cancellation policy is designed to be customer-friendly. Please contact us for detailed information about cancellations and refunds."
        },
        {
            question: "Are the drivers trained and professional?",
            answer: "Yes, all our drivers are experienced and trained professionals. They are familiar with the local routes and prioritize passenger safety."
        },
        {
            question: "Is there a minimum rental duration?",
            answer: "We offer flexible rental options, but typically, there is a minimum rental duration. Please check with our customer service for specific details."
        },
        {
            question: "How do I make payment?",
            answer: "Payments can be made through various methods, including online transfers, credit cards, or cash. We provide a secure payment process for your convenience."
        },
        {
            question: "What safety measures are in place?",
            answer: "We follow stringent hygiene and safety protocols, including regular cleaning of buses and health checks for drivers, to ensure a safe travel experience."
        },
        {
            question: "Can I rent a bus for corporate events?",
            answer: "Yes! Our Urbania buses are perfect for corporate events, conferences, and team outings. We provide tailored services to meet your corporate travel needs."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Vikram Nair",
            text: "We hired an Urbania bus from SSKTravels24 for our group trip from Mumbai Airport, and it was a fantastic experience! The bus was spacious, clean, and equipped with modern amenities that made our journey comfortable. Our driver was punctual and navigated the traffic smoothly, allowing us to relax and enjoy the ride. I highly recommend SSKTravels24 for anyone needing reliable transportation!"
        },
        {
            name: "Ms. Neha Singh",
            text: "Our corporate team used SSKTravels24’s Urbania bus service for airport transfers, and it exceeded our expectations. The bus was stylish and comfortable, perfect for our group of executives. The booking process was straightforward, and the customer support team was very helpful. We will definitely use SSKTravels24 for our future travel needs!"
        }
    ];
    
    
    const contactData = {
        heading: "Urbania Bus On Rent in Mumbai Airport Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Urbania Bus On Rent in Mumbai Airport | Travel in Style</title>
  <meta name="description" content="Rent Urbania buses for comfortable travel between Pune and Mumbai Airport. Choose from 13 and 17-seater options for your convenience." />
  <meta name="keywords" content="Urbania Bus On Rent in Mumbai Airport | Pune to Mumbai Airport Urbania on Rent | Mumbai Airport to Pune Urbania on Rent | Force Urbania on Rent in Mumbai | Luxury Force Urbania on Rent in Pune | 13 Seater Urbania on Rent in Pimpri Chinchwad | 17 Seater Urbania on Rent in Pimpri Chinchwad | Urbania Bus on Rent for Corporate Employees" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Urbania Bus On Rent in Mumbai Airport | Travel in Style" />
  <meta property="og:description" content="Book Urbania buses for seamless travel between Pune and Mumbai Airport. Comfortable and luxurious options available for your needs." />
  <meta property="og:url" content="https://https://ssktravels24.com//urbania-bus-on-rent-mumbai-airport" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//urbania-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-01.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About Urbania Bus on Rent in Mumbai Airport - SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Urbaniabusonrentinmumbai;
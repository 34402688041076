
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Seatertempotravelleronrent(){


    const cardData = 
    {
      keyword: 'Tempo Traveller on Rent for Pune to Mahabaleshwar',
      heading: 'SSKTravels24: 25 Seater Tempo Traveller on Rent in Pune',
      headingDescription: 'When you need a spacious and comfortable option for group travel in Pune, the 25-seater Tempo Traveller from SSKTravels24 is the perfect choice. Ideal for corporate events, family gatherings, school excursions, or any large group outing, our 25-seater Tempo Travellers blend luxury and practicality. Here’s why our Tempo Travellers stand out and what you can expect from our rental service:',
      
       topPlaces : [
        {
            title: "1. Generous Seating Capacity",
            description: "The 25-seater Tempo Traveller comfortably accommodates up to 25 passengers. Each seat is strategically placed to provide ample legroom and elbow space, enhancing the comfort of long journeys."
        },
        {
            title: "2. Luxurious Interiors",
            description: "Experience premium travel with our Tempo Traveller's plush, ergonomically designed seats. The interiors are crafted with high-quality materials, ensuring a luxurious ambiance for a relaxed travel environment."
        },
        {
            title: "3. Powerful Air Conditioning",
            description: "Equipped with advanced air conditioning systems, our 25-seater Tempo Travellers maintain a cool and comfortable atmosphere inside, regardless of external weather conditions—perfect for Pune's warmer months."
        },
        {
            title: "4. Modern Entertainment Systems",
            description: "Our Tempo Travellers are fitted with state-of-the-art entertainment systems, including high-quality speakers and DVD players. Enjoy your favorite music or movies during your journey for an enhanced travel experience."
        },
        {
            title: "5. Ample Luggage Space",
            description: "Traveling with a lot of baggage? No problem! The 25-seater Tempo Traveller features generous luggage compartments and overhead storage, ensuring your belongings are securely stored and easily accessible."
        },
        {
            title: "6. Experienced and Professional Drivers",
            description: "Safety and reliability are our top priorities. Our drivers are highly experienced and familiar with Pune’s routes and outstation destinations, committed to providing a smooth and enjoyable travel experience."
        },
        {
            title: "7. Well-Maintained Vehicles",
            description: "Each Tempo Traveller in our fleet undergoes regular maintenance and thorough inspections to ensure optimal performance and cleanliness, guaranteeing you travel in a reliable and well-maintained vehicle."
        },
        {
            title: "8. Flexible Rental Options",
            description: "We offer customizable rental packages to fit your specific needs. Whether you require the Tempo Traveller for a few hours, a day, or an extended period, we can tailor our services to suit your schedule and budget."
        },
        {
            title: "9. Competitive Pricing",
            description: "At SSKTravels24, we provide competitive and transparent pricing for our 25-seater Tempo Travellers. Our clear pricing structure means no hidden fees or unexpected charges, offering you great value for your money."
        },
        {
            title: "10. 24/7 Customer Support",
            description: "Our dedicated customer support team is available around the clock to assist with any inquiries or issues. From booking to on-road assistance, we ensure a seamless and hassle-free experience for our clients."
        }
    ],    
    
     services : [
        {
            name: "Tempo Traveller 25 Seater Rent Per Km in Pune",
            description: "Looking for a 25-seater Tempo Traveller rental in Pune on a per-kilometer basis? At SSKTravels24, we offer flexible rental options that allow you to pay only for the distance you travel. This model is ideal for longer journeys or outstation trips, providing you control over your budget while ensuring comfort throughout your trip."
        },
        {
            name: "Tempo Traveller 25 Seater AC Bus on Rent Pune",
            description: "Experience luxurious air-conditioned travel with our 25-seater AC Tempo Travellers in Pune. Perfect for group outings, corporate events, or family trips, our AC Tempo Travellers come equipped with modern amenities to keep you cool and comfortable, no matter the weather."
        },
        {
            name: "Tempo Traveller 26 Seater Non-AC Bus on Rent in Pune",
            description: "For those seeking a more economical option, we offer 26-seater non-AC Tempo Travellers in Pune. These vehicles are ideal for budget-conscious groups wanting a spacious and comfortable ride without the additional cost of air conditioning."
        },
        {
            name: "26 Seater Bus on Rent for Weddings in Pune",
            description: "Planning a wedding and need a large bus for your guests? Our 26-seater buses are perfect for wedding transportation in Pune. Whether shuttling guests to the venue or providing a comfortable ride for the bridal party, our spacious and elegant buses ensure everyone arrives in style and comfort."
        },
        {
            name: "25 Bus on Rent in Pimpri Chinchwad Pune",
            description: "If you’re in Pimpri Chinchwad and need a 25-seater bus, SSKTravels24 has you covered. We offer convenient and reliable rental services in Pimpri Chinchwad, ensuring access to a well-maintained bus that meets your group travel needs."
        },
        {
            name: "25 Bus on Rent in Pune for Picnics",
            description: "Planning a picnic with a large group in Pune? Our 25-seater buses are perfect for transporting your party to your favorite picnic spots. Enjoy ample space, comfortable seating, and reliable transportation to make your outing enjoyable and stress-free."
        },
        {
            name: "Pune to Goa 25 Seater Bus Fare",
            description: "Heading from Pune to Goa and need a 25-seater bus? Our competitive pricing ensures you get excellent value for your money. The fare is based on factors such as rental duration and specific requirements. For an accurate quote and to book your bus, contact us directly."
        },
        {
            name: "26 Seater Tempo Traveller on Rent in Pune",
            description: "If you require a 26-seater Tempo Traveller for your travels in Pune, SSKTravels24 offers well-maintained vehicles equipped with modern amenities. Our Tempo Travellers provide ample space and comfort, making them ideal for larger groups."
        },
        {
            name: "25 Seater Bus on Rent in Hadapsar Pune",
            description: "For those located in Hadapsar, we offer 25-seater bus rentals tailored to your needs. Our well-maintained buses are available for various purposes, from corporate events to family outings."
        },
        {
            name: "Pune to Shirdi 25 Seater Bus on Rent",
            description: "Traveling from Pune to Shirdi with a large group? Our 25-seater buses provide comfortable and efficient group transportation. Equipped with modern amenities, we ensure a smooth journey to Shirdi."
        },
        {
            name: "Pune Airport to Shirdi 25 Seater Tempo Traveller on Rent",
            description: "Arriving at Pune Airport and need a 25-seater Tempo Traveller to Shirdi? We provide convenient airport transfer services with our spacious Tempo Travellers, ensuring a comfortable ride from the airport to your destination."
        },
        {
            name: "Pune to Bhimashankar 25 Seater Tempo Traveller",
            description: "Planning a trip from Pune to Bhimashankar? Our 25-seater Tempo Travellers are ideal for this journey, offering ample space and comfort for your group."
        },
        {
            name: "Pune to 5 Jyotirlinga Darshan Tempo Traveller on Rent",
            description: "Embarking on a pilgrimage to visit the 5 Jyotirlingas? Our 25-seater Tempo Travellers are perfect for this spiritual journey, ensuring comfortable travel as you visit each Jyotirlinga."
        },
        {
            name: "25 Seater Tempo Traveller on Rent in Pimpri Chinchwad",
            description: "If you are in Pimpri Chinchwad and need a 25-seater Tempo Traveller, SSKTravels24 has you covered. We offer reliable rental services to ensure you have access to a comfortable vehicle for all your group travel needs."
        },
        {
            name: "Tempo Traveller on Rent in Hinjewadi",
            description: "Located in Hinjewadi and need a Tempo Traveller for your group? SSKTravels24 provides excellent rental options, ensuring a comfortable and enjoyable ride for both local outings and outstation journeys."
        },
        {
            name: "Tempo Traveller on Rent in Kharadi",
            description: "For those in Kharadi, SSKTravels24 offers top-notch Tempo Traveller rental services. Our well-maintained vehicles are perfect for various travel needs, ensuring a comfortable experience."
        },
        {
            name: "Luxury Tempo Traveller on Rent in Kothrud Pune",
            description: "Looking for luxury in your travels? Our luxury Tempo Travellers in Kothrud Pune offer premium comfort and style. Equipped with high-end amenities and plush seating, they are perfect for special occasions, corporate events, or upscale travel experiences."
        },
        {
            name: "25 Seater Tempo Traveller on Rent in Pune Contact Number",
            description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient 25 Seater Tempo Traveller on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
     tableData : [
        ['Tempo Traveller 25 Seater Rent Per Km in Pune', 'Tempo Traveller 25 Seater AC Bus on Rent Pune'],
        ['Tempo Traveller 26 Seater Non-AC Bus on Rent in Pune', '26 Seater Bus on Rent for Weddings in Pune'],
        ['25 Bus on Rent in Pimpri Chinchwad Pune', '25 Bus on Rent in Pune for Picnics'],
        ['Pune to Goa 25 Seater Bus Fare', '26 Seater Tempo Traveller on Rent in Pune'],
        ['25 Seater Bus on Rent in Hadapsar Pune', 'Pune to Shirdi 25 Seater Bus on Rent'],
        ['Pune Airport to Shirdi 25 Seater Tempo Traveller on Rent', 'Pune to Bhimashankar 25 Seater Tempo Traveller'],
        ['Pune to 5 Jyotirlinga Darshan Tempo Traveller on Rent', '25 Seater Tempo Traveller on Rent in Pimpri Chinchwad'],
        ['Tempo Traveller on Rent in Hinjewadi', 'Tempo Traveller on Rent in Kharadi'],
        ['Luxury Tempo Traveller on Rent in Kothrud Pune', '']
    ],    
    whychoose : [
        {
            "WhyChooseheading": "Why Choose SSKTravels24 for Your 25-Seater Tempo Traveller Rental in Pune?",
            "WhyChoosedescription": "When planning group travel in Pune, SSKTravels24 stands out as the premier choice for renting a 25-seater Tempo Traveller. Our commitment to quality service, safety, and passenger comfort makes us the best option for your travels. Here’s why you should consider us:"
        },
        {
            "WhyChooseheading": "Exceptional Comfort:",
            "WhyChoosedescription": "Our 25-seater Tempo Travellers are specifically designed to ensure a comfortable journey. Each vehicle features spacious seating with excellent legroom, allowing passengers to relax, even on long trips."
        },
        {
            "WhyChooseheading": "Modern Features:",
            "WhyChoosedescription": "Travel in style with our vehicles that boast contemporary amenities. Enjoy a smooth ride with advanced climate control systems to keep the cabin cool, and revel in the pleasure of a well-maintained interior that enhances your travel experience."
        },
        {
            "WhyChooseheading": "Safety and Reliability:",
            "WhyChoosedescription": "Your safety is paramount. Each vehicle in our fleet undergoes thorough maintenance checks to ensure they are roadworthy and safe. We equip our Tempo Travellers with necessary safety features and ensure that our drivers are trained in safety protocols."
        },
        {
            "WhyChooseheading": "Professional Drivers:",
            "WhyChoosedescription": "Our drivers are not only skilled at navigating the roads but also prioritize your comfort and safety. With extensive knowledge of local routes and destinations, they are dedicated to providing a pleasant travel experience."
        },
        {
            "WhyChooseheading": "Customizable Packages:",
            "WhyChoosedescription": "We understand that each trip is unique. That’s why we offer flexible rental packages tailored to your specific needs, whether you require transportation for a day, a weekend, or a special event."
        },
        {
            "WhyChooseheading": "Transparent Pricing:",
            "WhyChoosedescription": "At SSKTravels24, we pride ourselves on clear and competitive pricing. You can expect no hidden fees; our quotes are straightforward and comprehensive, ensuring you understand the costs involved."
        },
        {
            "WhyChooseheading": "Simple Booking Process:",
            "WhyChoosedescription": "We strive to make the booking process as seamless as possible. You can easily reserve your vehicle via our website or by calling our customer support team, who are ready to assist you."
        },
        {
            "WhyChooseheading": "Timely Service:",
            "WhyChoosedescription": "We value your time and ensure that our vehicles arrive punctually at the agreed location. You can trust us to adhere to your schedule, making your travel plans hassle-free."
        },
        {
            "WhyChooseheading": "24/7 Support:",
            "WhyChoosedescription": "Our customer service team is available around the clock to assist you with any inquiries or issues that may arise during your booking or travel. We’re dedicated to ensuring your journey is smooth from start to finish."
        },
        {
            "WhyChooseheading": "Versatile Travel Options:",
            "WhyChoosedescription": "Whether you’re looking to explore local attractions, go on a weekend getaway, or plan a large event, our 25-seater Tempo Travellers are equipped to handle all types of journeys, providing a reliable solution for all your group travel needs."
        }
    ]    
    };    

    
   
    const faqData = [
        {
            question: "How do I start the booking process?",
            answer: "You can begin by calling us at +91 8956170493 or visiting our website. Our friendly team will guide you through the reservation process."
        },
        {
            question: "What kind of amenities will I find in the vehicle?",
            answer: "Our Tempo Travellers are equipped with features designed for passenger comfort and convenience, including climate control and comfortable seating arrangements, providing a pleasant travel atmosphere."
        },
        {
            question: "Are there any extra charges for long trips?",
            answer: "Yes, additional fees may apply for long-distance travel. We will inform you of any extra charges during the booking process, ensuring transparency."
        },
        {
            question: "Can I specify my travel routes?",
            answer: "Certainly! You have the option to customize your travel routes and make stops along the way. Just let us know your preferences when you book."
        },
        {
            question: "What is your cancellation policy?",
            answer: "If you need to cancel or modify your booking, please reach out to our customer support team as soon as possible. They will inform you of any applicable fees and assist you in the process."
        },
        {
            question: "How is the maintenance of the vehicles handled?",
            answer: "We prioritize safety through regular maintenance and inspections of our Tempo Travellers, ensuring they remain in optimal condition for your journey."
        },
        {
            question: "Can I use the Tempo Traveller for both local and outstation trips?",
            answer: "Absolutely! Our vehicles are suitable for both local excursions within Pune and outstation trips. We provide flexible options to meet your travel needs."
        },
        {
            question: "What should I do if I encounter issues during my trip?",
            answer: "In case of any issues, please contact our customer support team immediately. We are here to help resolve any concerns and ensure your journey continues smoothly."
        },
        {
            question: "How can I get an accurate quote for my trip?",
            answer: "To receive a quote, simply provide us with details about your trip, including the destination, duration, and any specific requests. We will respond with a clear and comprehensive quote."
        },
        {
            question: "Can I request additional amenities?",
            answer: "Yes, you can request specific amenities or features when you book. We will do our best to accommodate your needs and enhance your travel experience."
        }
    ];
    
      

    const testimonials = [
        {
            name: "Rajesh Thakur",
            text: "For our family reunion trip to Lonavala, we chose SSKTravels24 for a 25-seater Tempo Traveller. The vehicle was spotless and spacious, providing an enjoyable ride for everyone. The comfort and amenities made a big difference, and our driver was professional and friendly. I wholeheartedly recommend SSKTravels24 for anyone looking for reliable transportation. We'll definitely book with them again!"
        },
        {
            name: "Sneha Joshi",
            text: "We needed a 25-seater Tempo Traveller for our corporate offsite to Mahabaleshwar, and SSKTravels24 truly impressed us. The bus had all the necessary features, including comfortable seating and excellent air conditioning, making our long journey pleasant. The booking process was straightforward, and our driver was on time and courteous. Thanks to SSKTravels24, our team had a fantastic experience!"
        }
    ];
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: " 25 Seater Tempo Traveller on Rent in Pune Contact Number: ",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000",
         
        ],
        email: "booking@ssktravels24.com"
      };


      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 25 Seater Tempo Traveller on Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent a 25 seater tempo traveller in Pune for trips to Shirdi, Goa, and more. Enjoy competitive rates for both AC and non-AC options. Book your tempo traveller today!" />
  <meta name="keywords" content="Tempo traveller 25 seater rent per km in Pune, Tempo traveller 25 seater AC bus on rent Pune, Tempo traveller 26 seater non-AC bus on rent in Pune, 26 bus on rent for wedding in Pune, 25 bus on rent Pimpri Chinchwad Pune, 25 bus on rent in Pune for picnics, Pune to Goa 25 seater bus fare, 26 seater tempo traveller on rent in Pune, 25 seater bus on rent in Hadapsar Pune, Pune to Shirdi 25 seater bus on rent, Pune Airport to Shirdi 25 tempo traveller on rent, Pune to Bhimashankar 25 seater tempo traveller, Pune to 5 Jyotirlinga darshan tempo traveller on rent, 25 seater tempo traveller on rent in Pimpri Chinchwad, Tempo traveller on rent in Hinjewadi, Tempo traveller on rent in Kharadi, luxury tempo traveller on rent in Kothrud Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 25 Seater Tempo Traveller on Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for 25 seater tempo travellers in Pune. Enjoy competitive rates for trips to Shirdi, Goa, and more. Contact us to book your journey!" />
  <meta property="og:url" content="https://https://ssktravels24.com//25-seater-tempo-traveller-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//25-seater-tempo-traveller-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>25 Seater Tempo Traveller on Rent in Pune</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-09.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About Renting a 25-Seater Tempo Traveller with SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Seatertempotravelleronrent;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function FortuneronRentinPune(){


    const cardData = 
    {
        keyword: 'Fortuner on Rent in Pune',
        heading: 'Experience Luxury and Comfort with SSKTravels24',
        headingDescription: 'Renting a Toyota Fortuner in Pune is an excellent choice for traveling in style and comfort. Known for its robust performance and luxurious interiors, the Fortuner offers an ideal blend of elegance and functionality. At SSKTravels24, we provide top-notch Fortuner rental services tailored to meet your needs.',
        
        topPlaces: [
          {
            title: "1. Spacious Interiors",
            description: "The Fortuner is designed to provide ample space for passengers and luggage, making it perfect for family trips or group outings."
          },
          {
            title: "2. Luxurious Experience",
            description: "With premium upholstery and high-end features, riding in a Fortuner feels like a first-class experience."
          },
          {
            title: "3. Powerful Performance",
            description: "Equipped with a robust engine, the Fortuner handles both city roads and highways effortlessly, ensuring a smooth ride."
          },
          {
            title: "4. Safety Features",
            description: "The Fortuner comes with advanced safety features, including airbags, ABS, and stability control, giving you peace of mind during your travels."
          },
          {
            title: "5. Stylish Design",
            description: "Its bold and sophisticated design makes a statement wherever you go, whether it's a business meeting or a social gathering."
          },
          {
            title: "6. Versatile Rental Options",
            description: "SSKTravels24 offers flexible rental options, including hourly, daily, and weekly packages to suit your travel plans."
          },
          {
            title: "7. Well-Maintained Fleet",
            description: "Our Fortuner vehicles are regularly serviced and maintained, ensuring reliability and comfort for every journey."
          },
          {
            title: "8. Experienced Drivers",
            description: "Our professional drivers are knowledgeable about the best routes and destinations, enhancing your travel experience."
          },
          {
            title: "9. Affordable Pricing",
            description: "Enjoy luxury without breaking the bank. Our competitive pricing ensures you get the best value for your money."
          },
          {
            title: "10. 24/7 Customer Support",
            description: "We provide round-the-clock customer support, ensuring assistance is always available when you need it."
          }
        ],
        
        services: [
          {
            name: "Toyota Fortuner on Rent for Weddings",
            description: "Make your special day even more memorable with our Toyota Fortuner hire for wedding functions, providing the perfect setting for your bridal party or guests."
          },
          {
            name: "Fortuner Rental for Corporate Events",
            description: "Elevate your corporate gatherings with our Toyota Fortuner hire, impressing clients and colleagues with a luxurious ride."
          },
          {
            name: "Fortuner on Rent for Outstation Trips",
            description: "Planning a road trip? Our Fortuner ensures you have a comfortable and reliable vehicle for long journeys, complete with all necessary amenities."
          },
          {
            name: "Fortuner Hire for One Day",
            description: "Need a vehicle for a short trip? Our Fortuner rent for one day option allows you to enjoy a premium ride without long-term commitments."
          },
          {
            name: "Fortuner Rental at Pune Airport",
            description: "Traveling to Pune by air? Our Toyota Fortuner rental services at Pune Airport ensure that you start your journey in style."
          },
          {
            name: "Fortuner on Rent in Pimpri Chinchwad",
            description: "Residents of Pimpri Chinchwad can enjoy our Fortuner hire services tailored specifically for this area."
          },
          {
            name: "Fortuner on Rent Monthly",
            description: "For those needing a vehicle for an extended period, our Fortuner on rent in Pune monthly package offers great value."
          },
          {
            name: "Fortuner Rent Per Km",
            description: "Our Fortuner rent per km pricing ensures that you only pay for what you use, perfect for travelers requiring a vehicle for a short duration."
          },
          {
            name: "Contact Number for Fortuner on Rent in Pune",
            description: "For prompt and efficient Fortuner rental services in Pune, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['Fortuner on Rent in Pune', 'Fortuner Rent in Pune'],
            ['Toyota Fortuner on Rent in Pune', 'Toyota Fortuner Rental in Pune'],
            ['Toyota Fortuner Rental in Pune Airport', 'Toyota Fortuner Rental in Pune Airport Contact Number'],
            ['Toyota Fortuner Hire for Corporate Events', 'Toyota Fortuner Hire for Corporate Events Near Me'],
            ['Toyota Fortuner Hire for Wedding Function', 'Fortuner on Rent for Wedding'],
            ['Fortuner Rent for One Day', 'Fortuner Hire in Pimpri Chinchwad'],
            ['Fortuner on Rent for Outstation', 'Fortuner Hire for Corporate Office'],
            ['Fortuner on Rent in Pune Monthly', 'Fortuner Rent Per Km'],
            ['Fortuner on Rent in Mumbai', 'Fortuner Rent in Mumbai'],
            ['Toyota Fortuner on Rent in Mumbai', 'SSK Travels24 in Pune']
          ],
        
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for Fortuner on Rent in Pune – SSK Travels24?",
              WhyChoosedescription: "Choosing SSK Travels24 for your Fortuner on rent in Pune ensures an unparalleled travel experience marked by luxury, comfort, and reliability. Here are several reasons why you should opt for our services:"
            },
            {
              WhyChooseheading: "Quality Fleet:",
              WhyChoosedescription: "Our Fortuner vehicles are well-maintained, ensuring you enjoy a smooth and luxurious ride every time."
            },
            {
              WhyChooseheading: "Professional Drivers:",
              WhyChoosedescription: "Our experienced and courteous drivers are well-versed with the routes and ensure a safe and comfortable journey."
            },
            {
              WhyChooseheading: "Flexible Packages:",
              WhyChoosedescription: "We offer a range of rental packages, including daily, weekly, and monthly options, tailored to fit your specific needs."
            },
            {
              WhyChooseheading: "Affordable Pricing:",
              WhyChoosedescription: "Enjoy competitive rates without compromising on quality. Our transparent pricing policy means no hidden fees."
            },
            {
              WhyChooseheading: "24/7 Customer Support:",
              WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with any inquiries or issues you may have."
            },
            {
              WhyChooseheading: "Convenient Booking:",
              WhyChoosedescription: "Our user-friendly online booking system makes it easy to rent a Fortuner in just a few clicks."
            },
            {
              WhyChooseheading: "Luxury for Every Occasion:",
              WhyChoosedescription: "Whether it's a wedding, corporate event, or family trip, our Fortuner rental service caters to all occasions with style."
            },
            {
              WhyChooseheading: "Outstation Services:",
              WhyChoosedescription: "Planning a trip outside Pune? We provide reliable outstation cab services with the Fortuner for a comfortable journey."
            },
            {
              WhyChooseheading: "Safety First:",
              WhyChoosedescription: "We prioritize your safety with regular vehicle inspections and adherence to all safety protocols."
            },
            {
              WhyChooseheading: "Local Expertise:",
              WhyChoosedescription: "Our drivers are familiar with the best routes and attractions in and around Pune, ensuring you make the most of your journey."
            }
          ]
    };    

    
    const faqData = [
        {
          question: "What is the booking process for renting a Fortuner?",
          answer: "You can easily book a Fortuner through our website or by calling our customer service. Just provide the necessary details like date, time, and duration."
        },
        {
          question: "Are there any mileage limits on the rental?",
          answer: "Our rental packages come with flexible mileage options. Please check with our team for specific package details."
        },
        {
          question: "Is there an option for self-drive rental?",
          answer: "Currently, we only offer Fortuner rentals with a chauffeur for a more comfortable and hassle-free experience."
        },
        {
          question: "What documents are required for booking?",
          answer: "You will need to provide a valid driving license, ID proof, and any other documentation specified at the time of booking."
        },
        {
          question: "Can I hire a Fortuner for outstation travel?",
          answer: "Yes, we offer outstation rental services for the Fortuner, perfect for trips outside Pune."
        },
        {
          question: "What are the payment options?",
          answer: "We accept various payment methods, including credit/debit cards and cash, for your convenience."
        },
        {
          question: "Is insurance included in the rental price?",
          answer: "Yes, our rentals include basic insurance coverage. Additional coverage options can also be discussed at the time of booking."
        },
        {
          question: "How do I cancel my booking?",
          answer: "Cancellations can be made by contacting our customer service. Please review our cancellation policy for any applicable charges."
        },
        {
          question: "What if my plans change on the day of rental?",
          answer: "We strive to be flexible and can often accommodate changes. Just contact us as soon as possible to discuss your options."
        },
        {
          question: "Are there any additional charges I should be aware of?",
          answer: "Our pricing is transparent, but additional charges may apply for extra services or extended mileage. We'll inform you of all potential charges upfront."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Rahul Mehta",
          text: "I recently rented a Fortuner from SSK Travels24 for a family wedding, and I couldn't be happier with the experience. The vehicle was in pristine condition, and our driver was incredibly professional and courteous. He knew all the best routes and made our journey smooth and enjoyable. I highly recommend SSK Travels24 for anyone looking for a luxury car rental in Pune!"
        },
        {
          name: "Miss Priya Deshmukh",
          text: "SSK Travels24 made my trip to Mahabaleshwar unforgettable with their Fortuner rental service. The car was spacious and comfortable, perfect for our group. Our driver was punctual and knowledgeable about the area, which added to our experience. I will definitely be using SSK Travels24 for my future travels!"
        }
      ];
      
      const contactData = {
        heading: "Fortuner on Rent in Pune Contact Information",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Fortuner On Rent in Pune | Luxury Car Rental</title>
  <meta name="description" content="Rent a Toyota Fortuner in Pune for weddings, corporate events, or outstation trips. Enjoy luxury and comfort with SSK Travels24." />
  <meta name="keywords" content="Fortuner on rent Pune, Toyota Fortuner rental in Pune, Fortuner hire for corporate events, Fortuner rent for wedding, Fortuner rent per km" />
  <meta property="og:title" content="Fortuner On Rent in Pune | SSK Travels24" />
  <meta property="og:description" content="Experience luxury with our Toyota Fortuner rentals in Pune. Ideal for weddings, corporate functions, and outstation travel." />
  <meta property="og:url" content="https://https://ssktravels24.com//fortuner-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//fortuner-rent-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-29.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default FortuneronRentinPune;
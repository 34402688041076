
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function ErtigaCabBookinginPune(){


    const cardData = 
    {
        keyword: 'Ertiga Cab Booking in Pune',
        heading: 'SSKTravels24: Ertiga Cab Booking in Pune',
        headingDescription: 'When it comes to comfortable and spacious travel options in Pune, the Ertiga stands out as an excellent choice. SSKTravels24 offers reliable Ertiga cab booking services that cater to both individual and group needs. Whether you\'re planning a family trip, a corporate event, or just need a ride to the airport, our Ertiga cabs provide the perfect blend of comfort and affordability.',
        
        topPlaces: [
            {
                title: "1. Spacious Interior",
                description: "The Ertiga's roomy cabin comfortably accommodates up to 7 passengers, making it ideal for family trips or group outings."
            },
            {
                title: "2. Economical Rates",
                description: "Our Ertiga cab services are priced competitively, ensuring you get the best value for your money without compromising on comfort."
            },
            {
                title: "3. Well-Maintained Vehicles",
                description: "We take pride in our fleet of Ertiga cabs, which are regularly serviced and maintained to ensure a smooth ride every time."
            },
            {
                title: "4. Professional Drivers",
                description: "Our experienced drivers are well-versed in local routes and provide safe, reliable transportation. They also offer excellent customer service."
            },
            {
                title: "5. Flexible Booking Options",
                description: "Whether you need a one-way ride or a round trip, we offer flexible booking options to meet your travel requirements."
            },
            {
                title: "6. Easy Online Booking",
                description: "Our user-friendly website makes it simple to book your Ertiga cab in just a few clicks. You can also contact our support team for assistance."
            },
            {
                title: "7. On-Time Service",
                description: "We value your time. Our drivers arrive promptly at your designated pickup location, ensuring you reach your destination without delays."
            },
            {
                title: "8. Variety of Packages",
                description: "SSKTravels24 offers various cab packages, including hourly rentals, outstation trips, and airport transfers, tailored to your needs."
            },
            {
                title: "9. Safety First",
                description: "The safety of our passengers is our top priority. Our cabs are equipped with safety features, and our drivers adhere to all traffic regulations."
            },
            {
                title: "10. Customer-Centric Approach",
                description: "We aim to provide the best customer experience, and our team is always ready to assist you with any inquiries or special requests."
            }
        ],
        
        services: [
            {
                name: "Ertiga Rent Per Km in Pune",
                description: "Our transparent pricing structure means you can easily calculate your trip costs based on the distance traveled, ensuring you get the best deal."
            },
            {
                name: "Pune to Outstation Ertiga Cab",
                description: "Our outstation Ertiga cabs are perfect for family getaways or group travel, ensuring comfort and reliability while traveling to popular destinations."
            },
            {
                name: "Contact Number for New Ertiga Cab Booking in Pune",
                description: "For quick bookings or inquiries about our new Ertiga cabs, you can contact us directly at +91 8956170493."
            },
            {
                name: "Ertiga Cab Booking in Kharadi and Hinjewadi",
                description: "We cater to various localities in Pune, including Kharadi and Hinjewadi, ensuring efficient transportation to your desired location."
            },
            {
                name: "Pune to Mahabaleshwar Ertiga Cab",
                description: "Our Pune to Mahabaleshwar Ertiga cab services offer a comfortable ride to this scenic hill station, perfect for a family outing."
            },
            {
                name: "7 Seater and 8 Seater Car Rentals in Pune",
                description: "In addition to the Ertiga, we offer 7-seater and 8-seater car rentals for larger groups, ensuring everyone travels together comfortably."
            },
            {
                name: "Ertiga Cab Booking in Baner and Aundh",
                description: "We provide Ertiga cab booking services in popular areas like Baner and Aundh, making it easy to access our services no matter where you are in Pune."
            },
            {
                name: "Pune Darshan Ertiga Cab Booking",
                description: "Experience the best of Pune with our Pune Darshan Ertiga cab bookings, visiting iconic landmarks and attractions while enjoying a comfortable ride."
            },
            {
                name: "Ertiga Cab Rentals in Pune",
                description: "Our Ertiga cab rentals in Pune are designed for those who value comfort and space, whether for a short trip or a long journey."
            },
            {
                name: "Hire Ertiga Cab for Outstation",
                description: "For your outstation travel needs, hiring an Ertiga cab from SSKTravels24 is a great choice, ensuring a spacious and relaxing journey."
            },
        ],
        tableData: [
            ['Ertiga rent per km in Pune', 'Ertiga cab booking in Pune'],
            ['Book Ertiga car in Pune', 'Pune to outstation Ertiga cab'],
            ['New Ertiga cab booking in Pune', 'New Ertiga cab booking in Pune contact number'],
            ['New Ertiga cab booking in Pune contact number Hadapsar', 'Ertiga cab booking in Kharadi'],
            ['Ertiga cab booking in Hinjewadi', 'Pune to Mahabaleshwar Ertiga cab'],
            ['7 seater car on rent in Pune', '8 seater car on rent in Pune'],
            ['Ertiga cab booking in Baner Aundh', 'Ertiga car on rent in Pune'],
            ['Pune Darshan Ertiga cab booking in Pune', 'Ertiga Cab Rentals in Pune'],
            ['Cab for Outstation Journey', 'Hire Ertiga cab for outstation'],
            ['SSK Travels24 in Pune', '']
        ],
        
        whychoose: [
            {
                "WhyChooseheading": "Why Choose Us for Ertiga Cab Booking in Pune?",
                "WhyChoosedescription": "At SSKTravels24, we understand the diverse travel needs of our customers. Here’s why our Ertiga cab booking service stands out in Pune:"
            },
            {
                "WhyChooseheading": "Spacious Comfort:",
                "WhyChoosedescription": "The Ertiga is designed to provide ample space for passengers and luggage, making it ideal for family trips and group outings."
            },
            {
                "WhyChooseheading": "Affordable Rates:",
                "WhyChoosedescription": "We offer competitive pricing with no hidden charges, ensuring you get value for your money."
            },
            {
                "WhyChooseheading": "Reliable Service:",
                "WhyChoosedescription": "Our fleet of well-maintained Ertiga cabs and experienced drivers guarantees a safe and comfortable journey."
            },
            {
                "WhyChooseheading": "Flexible Booking Options:",
                "WhyChoosedescription": "Whether you need a cab for local travel, outstation trips, or special occasions, we offer flexible booking options to suit your needs."
            },
            {
                "WhyChooseheading": "24/7 Availability:",
                "WhyChoosedescription": "Our cab services are available around the clock, allowing you to book a ride at your convenience."
            },
            {
                "WhyChooseheading": "Customer-Centric Approach:",
                "WhyChoosedescription": "We prioritize customer satisfaction, providing personalized service and assistance throughout your journey."
            },
            {
                "WhyChooseheading": "Easy Online Booking:",
                "WhyChoosedescription": "Our user-friendly online booking system makes it quick and easy to reserve your Ertiga cab."
            },
            {
                "WhyChooseheading": "Local Expertise:",
                "WhyChoosedescription": "Our drivers are familiar with Pune’s routes and traffic patterns, ensuring a smooth and efficient ride."
            },
            {
                "WhyChooseheading": "Safety First:",
                "WhyChoosedescription": "We implement strict safety protocols and maintain our vehicles regularly to ensure your safety on the road."
            },
            {
                "WhyChooseheading": "Diverse Travel Packages:",
                "WhyChoosedescription": "We offer various travel packages, including Pune sightseeing tours and outstation trips, making us your one-stop solution for travel needs."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "How do I book an Ertiga cab in Pune?",
            answer: "You can book an Ertiga cab through our website or by contacting our customer service team directly."
        },
        {
            question: "What are the rates for Ertiga cab rentals?",
            answer: "Our rates are competitive and vary based on the distance and duration of your trip. You can check our pricing details on our website or inquire with our customer service."
        },
        {
            question: "Is the Ertiga cab suitable for outstation travel?",
            answer: "Yes, the Ertiga is perfect for outstation travel due to its spaciousness and comfort, making long journeys enjoyable."
        },
        {
            question: "Can I hire an Ertiga cab for a day trip?",
            answer: "Absolutely! We offer flexible rental options, including hourly and daily bookings."
        },
        {
            question: "Do you provide drivers with the Ertiga cabs?",
            answer: "Yes, all our Ertiga rentals come with experienced drivers to ensure a safe and hassle-free journey."
        },
        {
            question: "Are there any additional charges for booking an Ertiga cab?",
            answer: "We believe in transparency. There are no hidden charges, but additional fees may apply for tolls, parking, or extended travel distances."
        },
        {
            question: "What if I need to cancel my booking?",
            answer: "Our cancellation policy allows you to cancel bookings with minimal fees. Please refer to our website for detailed information."
        },
        {
            question: "How can I contact customer support for assistance?",
            answer: "You can reach our customer support team via phone, email, or through our website's contact form for any assistance."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rajiv Patil",
            text: "I recently booked an Ertiga cab with SSKTravels24 for a family trip to Mahabaleshwar, and it was a fantastic experience! The cab was spacious and comfortable, perfect for our group. The driver was professional and knew the best routes to avoid traffic. I highly recommend SSKTravels24 for anyone looking for reliable cab services in Pune!"
        },
        {
            name: "Miss. Anjali Sharma",
            text: "SSKTravels24 made our Pune sightseeing tour so much easier with their Ertiga cab service. The booking process was simple, and the cab arrived on time. Our driver was friendly and shared interesting facts about the places we visited. I will definitely choose them again for future trips!"
        }
    ];
    
    const contactData = {
        heading: "Ertiga Cab Booking in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Ertiga Cab Booking in Pune | Affordable Car Rentals</title>
  <meta name="description" content="Book Ertiga cabs in Pune for comfortable and spacious travel. Ideal for outstation trips, family journeys, and local travel with SSK Travels24." />
  <meta name="keywords" content="Ertiga cab booking Pune, Ertiga rental in Pune, outstation Ertiga cab, Ertiga cab service, Pune to Mahabaleshwar Ertiga cab" />
  <meta property="og:title" content="Ertiga Cab Booking in Pune | SSK Travels24" />
  <meta property="og:description" content="Enjoy spacious travel with our Ertiga cab services in Pune. Perfect for family trips and outstation journeys. Book now!" />
  <meta property="og:url" content="https://https://ssktravels24.com//ertiga-cab-booking-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//ertiga-cab-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-31.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default ErtigaCabBookinginPune;
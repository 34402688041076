
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetomumbaicab(){


    const cardData = 
    {
        keyword: 'Pune to Mumbai Cab Services',
        heading: 'SSKTravels24: Pune to Mumbai Cab Services',
        headingDescription: 'Are you planning a trip from Pune to Mumbai? Look no further than SSKTravels24 for reliable and comfortable cab services. Our dedicated fleet ensures a smooth journey to one of India’s most vibrant and bustling cities. Whether you’re traveling for business, pleasure, or a special occasion, we provide top-notch cab services tailored to your needs.',
        
        topPlaces: [
            {
                title: "1. Gateway of India",
                description: "The Gateway of India is an iconic landmark overlooking the Arabian Sea. Built in 1924, this majestic archway is a symbol of Mumbai's rich history. Visitors can enjoy boat rides from here to nearby attractions like Elephanta Island."
            },
            {
                title: "2. Marine Drive",
                description: "Marine Drive, also known as the 'Queen's Necklace,' is a beautiful promenade along the coastline. It's the perfect spot for a leisurely stroll, especially during sunset. Enjoy the stunning views of the Arabian Sea while sipping on fresh coconut water from local vendors."
            },
            {
                title: "3. Chhatrapati Shivaji Maharaj Terminus (CST)",
                description: "A UNESCO World Heritage Site, CST is an architectural marvel that showcases Victorian Gothic Revival style. It’s one of Mumbai's busiest railway stations and an excellent example of the city’s historical significance."
            },
            {
                title: "4. Colaba Causeway",
                description: "Colaba Causeway is a vibrant shopping street where you can find everything from trendy boutiques to local handicrafts. Don’t forget to haggle for the best prices while soaking in the lively atmosphere of this bustling market."
            },
            {
                title: "5. Elephanta Caves",
                description: "Located on Elephanta Island, these ancient rock-cut caves date back to the 5th century. The stunning sculptures and intricate carvings dedicated to Hindu gods make it a must-visit for history enthusiasts and art lovers."
            },
            {
                title: "6. Dhobi Ghat",
                description: "Experience Mumbai's unique open-air laundry at Dhobi Ghat, where hundreds of washers (dhobis) work tirelessly to wash clothes. It's a fascinating sight and provides insight into the daily life of Mumbai’s residents."
            },
            {
                title: "7. Juhu Beach",
                description: "Juhu Beach is one of Mumbai’s most popular beaches, perfect for relaxation and enjoying local street food. Whether you want to watch the sunset or take a stroll along the shore, this beach offers a vibrant atmosphere."
            },
            {
                title: "8. Siddhivinayak Temple",
                description: "Siddhivinayak Temple is one of the most famous Hindu temples dedicated to Lord Ganesha. Pilgrims and tourists flock here to seek blessings, making it a spiritually enriching experience."
            },
            {
                title: "9. Haji Ali Dargah",
                description: "Situated on an islet off the coast of Worli, Haji Ali Dargah is a stunning mosque and tomb that attracts visitors from all walks of life. The beautiful architecture and serene atmosphere make it a peaceful retreat."
            },
            {
                title: "10. Bandra-Worli Sea Link",
                description: "The Bandra-Worli Sea Link is an engineering marvel that connects Bandra to Worli. Take a drive across this iconic bridge to enjoy breathtaking views of the Mumbai skyline and the Arabian Sea."
            }
        ],
        
        services: [
            {
                name: "Pune to Mumbai Cab",
                description: "Enjoy a comfortable journey with Pune to Mumbai cab services that cater to both personal and business travel. Choose from a variety of cab options, such as sedans, SUVs, or even luxury cars, to ensure a convenient and enjoyable ride."
            },
            {
                name: "Pune to Mumbai Airport Cab",
                description: "If you're flying out of Mumbai International or Domestic Airport, a Pune to Mumbai airport cab ensures timely airport drop services. Reliable drivers and clean vehicles make this option ideal for frequent flyers and vacationers alike."
            },
            {
                name: "Pune to Mumbai Airport Cab Fare",
                description: "Cab fare from Pune to Mumbai airport is competitive, with a variety of options depending on the vehicle type. Pune to Mumbai airport cab fare varies based on the distance, vehicle, and any additional services requested. Pre-book your cab to lock in the best rates."
            },
            {
                name: "Pune to Mumbai Airport Drop",
                description: "Opt for a Pune to Mumbai airport drop service for stress-free travel before your flight. Whether you’re flying out of Chhatrapati Shivaji Maharaj International Airport or a domestic terminal, reliable Pune to Mumbai cabs can get you there on time."
            },
            {
                name: "Pune to Mumbai Cheapest Cab",
                description: "Looking for an affordable ride? Pune to Mumbai cheapest cab options include budget-friendly services that don't compromise on safety or comfort. Book in advance to find the best deals."
            },
            {
                name: "Pune to Mumbai Cab Service",
                description: "The Pune to Mumbai cab service ensures a smooth and safe journey between the two cities. Choose from one-way or round-trip services with options for sightseeing and personalized itineraries."
            },
            {
                name: "Pune to Mumbai Cab Fare",
                description: "Get transparent pricing for your Pune to Mumbai cab fare with no hidden charges. The fare depends on vehicle type and travel distance. Fare breakdown includes fuel, driver allowance, and toll charges."
            },
            {
                name: "Pune to Mumbai Cab Charges",
                description: "Understanding Pune to Mumbai cab charges ahead of time helps you plan your trip budget. Charges are influenced by factors such as vehicle type, one-way or round-trip journeys, and any special requests like additional luggage or overnight stays."
            },
            {
                name: "Pune Mumbai Taxi Service",
                description: "For quick and easy travel, Pune Mumbai taxi service provides reliable cabs driven by experienced drivers who know the best routes to ensure a smooth journey."
            },
            {
                name: "Pune Mumbai Taxi Fare",
                description: "Get the best rates with Pune Mumbai taxi fare options that suit your budget. Compare fares for different vehicle types to find the right choice for your needs."
            },
            {
                name: "Pune to Mumbai Car",
                description: "Need a Pune to Mumbai car for a personal trip? Choose from a variety of car rental services to meet your needs. Whether it's a sedan, hatchback, or luxury car, you can find the perfect ride."
            },
            {
                name: "Pune to Mumbai International Airport Cab",
                description: "For international travelers, Pune to Mumbai international airport cab services provide a stress-free transfer, ensuring you reach the airport in plenty of time for your flight."
            },
            {
                name: "Pune to Mumbai Cab Price",
                description: "The Pune to Mumbai cab price varies based on the type of vehicle and any additional services. You can choose from economy, standard, and premium cabs depending on your budget and comfort needs."
            },
            {
                name: "Pune to Mumbai Airport Cab Price",
                description: "Whether you're catching a domestic or international flight, Pune to Mumbai airport cab price ranges depending on the car you choose. Ensure a hassle-free drop at Mumbai’s airport with competitive cab pricing."
            },
            {
                name: "Pune to Mumbai Innova Cab",
                description: "Traveling with family or a larger group? Book a Pune to Mumbai Innova cab for extra space and comfort. The spacious SUV can comfortably accommodate passengers and luggage."
            },
            {
                name: "Pune to Mumbai Taxi One-Way",
                description: "For travelers looking for one-way travel, Pune to Mumbai taxi one-way services offer an affordable option. Book your cab for a single trip and avoid the extra charges of a round trip."
            },
            {
                name: "Pune to Mumbai Cab Booking",
                description: "Book your Pune to Mumbai cab booking in advance to ensure availability, especially during peak travel seasons. Enjoy the convenience of online booking and secure your ride with a few simple steps."
            },
            {
                name: "Pune to Mumbai Cab Cost",
                description: "The Pune to Mumbai cab cost depends on the distance, vehicle type, and any additional services like tolls or night charges. Choose a cab that fits your budget and enjoy your journey."
            },
            {
                name: "Pune to Mumbai Airport Taxi",
                description: "Get to the airport hassle-free with Pune to Mumbai airport taxi services. These cabs offer on-time pick-up and drop-off services to ensure you never miss your flight."
            },
            {
                name: "Pune Mumbai Car Hire",
                description: "Looking for flexibility in your travel? Opt for Pune Mumbai car hire to choose your preferred vehicle, with options ranging from standard to luxury cars for your journey."
            },
            {
                name: "Pune to Mumbai SUV Cab",
                description: "For a more spacious ride, consider a Pune to Mumbai SUV cab. Perfect for larger groups or families, an SUV provides extra legroom and comfort during the journey."
            },
            {
                name: "Pune to Mumbai Taxi Price",
                description: "Get transparent pricing for your journey with Pune to Mumbai taxi price. Compare prices across different services and vehicles to find the right fit for your travel budget."
            },
            {
                name: "Pune to Mumbai Central Cab",
                description: "If you're traveling to central Mumbai, Pune to Mumbai central cab services offer a direct, convenient ride to your destination without any detours."
            },
            {
                name: "Pune Mumbai Pune Cab",
                description: "Need a round-trip service? Pune Mumbai Pune cab options allow for a comfortable journey both ways, ensuring you can relax and enjoy your trip."
            },
            {
                name: "Airport Drop Mumbai from Pune",
                description: "For those needing to get to Mumbai airport from Pune, airport drop Mumbai from Pune services ensure timely and reliable transfers, helping you avoid the stress of last-minute travel arrangements."
            },
            {
                name: "Pune to Mumbai SSK Travels",
                description: "Pune to Mumbai SSK Travels provides 24/7 service, offering a range of vehicle options for your trip. With experienced drivers and competitive fares, you can book a reliable cab any time of the day or night."
            },
            {
                name: "Contact Number for Pune to Mumbai Cab",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Pune to Mumbai Cab. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        tableData: [
            ['-Pune to Mumbai Cabs', '-Pune to Mumbai Airport Cab'],
            ['-Pune to Mumbai Airport Drop', '-Pune to Mumbai Cab Service'],
            ['-Pune to Mumbai Cab Charges', '-Pune Mumbai Taxi Fare'],
            ['-Pune to Mumbai International Airport Cab', '-Pune to Mumbai SUV Cab'],
            ['-Pune to Mumbai Cab Price', '-Pune to Mumbai Airport Cab Price'],
            ['-Pune to Mumbai Innova Cab', '-Pune to Mumbai Taxi One Way'],
            ['-Pune to Mumbai Cab Booking', '-Pune to Mumbai Cab Cost'],
            ['-Pune to Mumbai Airport Taxi', '-Pune Mumbai Car Hire'],
            ['-Pune to Mumbai Taxi Price', '-Pune Mumbai Pune Cab'],
            ['-Pune to Mumbai Central Cab', '-Pune to Mumbai SSK Travels 24'],
            ['-Airport Drop Mumbai from Pune', '']
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSK Travels 24 for Pune to Mumbai Cab?",
                "WhyChoosedescription": "SSK Travels 24 stands out for its commitment to delivering exceptional travel experiences for your journey from Pune to Mumbai. Here’s why you should choose us:"
            },
            {
                "WhyChooseheading": "24/7 Availability:",
                "WhyChoosedescription": "We operate round-the-clock, ensuring you can book a cab anytime you need, whether it's an early morning flight or a late-night trip."
            },
            {
                "WhyChooseheading": "Reliable Service:",
                "WhyChoosedescription": "Our experienced drivers are well-trained and punctual, providing a safe and comfortable journey from Pune to Mumbai."
            },
            {
                "WhyChooseheading": "Transparent Pricing:",
                "WhyChoosedescription": "We believe in clear and upfront pricing with no hidden fees. Our fare structure is designed to fit various budgets without compromising quality."
            },
            {
                "WhyChooseheading": "Diverse Fleet Options:",
                "WhyChoosedescription": "Choose from a variety of vehicles, including sedans, SUVs, and premium cars, to match your comfort and travel needs."
            },
            {
                "WhyChooseheading": "Customer-Centric Approach:",
                "WhyChoosedescription": "Your satisfaction is our priority. Our friendly customer service team is always ready to assist you with any queries or special requests."
            },
            {
                "WhyChooseheading": "Convenient Booking Process:",
                "WhyChoosedescription": "Book your cab easily through our user-friendly online platform or via phone, ensuring a hassle-free experience."
            },
            {
                "WhyChooseheading": "Safety First:",
                "WhyChoosedescription": "We adhere to all safety protocols, ensuring that your journey is not only comfortable but also secure."
            }
        ]
    };    

    
    const faqData = [
        {
          question: "What types of cabs are available for Pune to Mumbai?",
          answer: "We offer a range of vehicles, including sedans, SUVs, and premium cars, to suit your travel preferences."
        },
        {
          question: "How can I book a cab from Pune to Mumbai?",
          answer: "You can book a cab easily through our website or by calling our customer service team."
        },
        {
          question: "What is the fare for a cab from Pune to Mumbai?",
          answer: "Our fares depend on the vehicle type and distance. Please check our pricing page or contact us for specific rates."
        },
        {
          question: "Are there any hidden charges?",
          answer: "No, we pride ourselves on transparent pricing. The fare quoted is the final amount with no hidden charges."
        },
        {
          question: "Do you provide airport drop services?",
          answer: "Yes, we offer reliable airport drop services to and from Mumbai Airport, ensuring timely arrivals."
        },
        {
          question: "Can I book a round trip?",
          answer: "Yes, we provide round trip services for your convenience. You can book both legs of your journey at once."
        },
        {
          question: "What if my flight is delayed?",
          answer: "Our drivers monitor flight schedules, so if your flight is delayed, we will adjust pickup times accordingly."
        },
        {
          question: "Is your service available 24/7?",
          answer: "Yes, our cab services are available 24 hours a day, 7 days a week."
        },
        {
          question: "What safety measures do you have in place?",
          answer: "We prioritize safety by adhering to strict hygiene protocols, regular vehicle maintenance, and ensuring all our drivers are trained in safety measures."
        },
        {
          question: "How do I contact customer support?",
          answer: "You can reach our customer support team via phone or email, and we’ll be happy to assist you with any questions or concerns."
        }
      ];
      
      const testimonials = [
        {
          name: "Ms. Ananya S.",
          text: "SSKTravels24 made my recent trip from Pune to Mumbai a seamless experience. The cab was clean and comfortable, and my driver was punctual and friendly. I appreciated the transparent pricing with no hidden fees, which made budgeting easy. The whole journey was smooth, and I felt safe throughout. I will definitely use SSKTravels24 again for my future travels!"
        },
        {
          name: "Mr. Rajesh K.",
          text: "I recently booked a cab with SSKTravels24 for an airport drop, and it was fantastic. The driver was professional, arrived on time, and even helped with my luggage. The ride was smooth, and I felt relaxed knowing I was in good hands. I highly recommend SSKTravels24 for anyone needing reliable and efficient cab services from Pune to Mumbai!"
        }
      ];
      
      const contactData = {
        heading: "Pune to Mumbai Cab Contact Number",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai Cab Taxi Service | Affordable Rates</title>
  <meta name="description" content="Reliable Pune to Mumbai cab service for airport transfers and city travel. Affordable rates on Innova, Ertiga, and more." />
  <meta name="keywords" content="Pune to Mumbai cab, Pune to Mumbai airport cab, Pune Mumbai taxi, cheapest Pune to Mumbai cab, Pune to Mumbai Innova cab" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai Cab Taxi Service | Affordable Rates" />
  <meta property="og:description" content="Book your Pune to Mumbai taxi with SSK Travels24 for comfortable and reliable travel at competitive rates." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-mumbai-cab" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-mumbai-cab-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\punetomambai.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetomumbaicab;
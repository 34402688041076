
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Seaterbusonrent(){


    const cardData = 
    {
      keyword: 'Tempo Traveller on Rent for Pune to Mahabaleshwar',
      heading: 'SSKTravels24: 20-Seater Bus on Rent in Pune',
      headingDescription: 'Planning a trip from Pune to Mahabaleshwar? SSKTravels24 offers the perfect Tempo Traveller rental solutions to make your journey comfortable and enjoyable. Discover the top features and services we provide for your Pune to Mahabaleshwar travel needs.When it comes to group travel in Pune, our 20-seater buses at SSKTravels24 offer an exceptional blend of comfort, functionality, and reliability. Whether you are planning a corporate outing, a family event, or a school trip, our 20-seater buses are designed to provide a seamless travel experience. Discover why our 20-seater buses are a top choice and what features set them apart.',
      
       topPlaces : [
        {
            title: "1. Spacious Interiors",
            description: "Enjoy the generous interior space of our 20-seater buses, which ensures ample legroom and headroom for all passengers. Whether you're traveling with family or a larger group, our vehicles provide a comfortable environment where everyone can relax and enjoy the journey."
        },
        {
            title: "2. Luxurious Seating",
            description: "Travel in style with our plush, reclining seats. Each seat is equipped with individual armrests and adjustable headrests, ensuring maximum comfort during both short and long trips."
        },
        {
            title: "3. Powerful Air Conditioning",
            description: "Beat the Pune heat with our advanced air conditioning systems. Our 20-seater buses are designed to keep the interior cool and comfortable, making your journey pleasant no matter the weather outside."
        },
        {
            title: "4. Ample Luggage Space",
            description: "Pack all your essentials without worry. Our buses come with substantial luggage compartments that accommodate your bags and belongings securely, leaving ample room for passengers."
        },
        {
            title: "5. Modern Entertainment Systems",
            description: "Enhance your travel experience with our cutting-edge entertainment options. Each bus features music players and LED screens, providing entertainment throughout your trip, whether you’re enjoying your favorite music or a movie."
        },
        {
            title: "6. Experienced and Professional Drivers",
            description: "Safety and professionalism are our priorities. Our experienced drivers are well-versed with Pune’s routes and beyond, ensuring a smooth and safe journey while you sit back and enjoy the ride."
        },
        {
            title: "7. Well-Maintained Vehicles",
            description: "Our buses are regularly serviced and maintained to the highest standards. We ensure that each vehicle is in top condition, providing you with a reliable and clean travel experience."
        },
        {
            title: "8. Flexible Rental Options",
            description: "We offer flexible rental packages to suit your specific needs. Whether it’s a short day trip or a longer journey, you can choose a rental option that best fits your schedule and budget."
        },
        {
            title: "9. Competitive Pricing",
            description: "Get the best value for your money with our transparent pricing. We offer clear, upfront rates with no hidden charges, making it easy to plan your travel expenses without surprises."
        },
        {
            title: "10. 24/7 Customer Support",
            description: "Our dedicated customer support team is available around the clock to assist you with bookings, inquiries, and any travel-related concerns. We are committed to providing a hassle-free experience from start to finish."
        }
    ],    
    
     services : [
        {
            name: "20 Seater Bus on Rent in Pune",
            description: "Looking for a 20-seater bus on rent in Pune? Our 20-seater buses are ideal for group travel, offering ample space and comfort for a variety of occasions. Whether you're planning a family outing, a corporate event, or a group trip, our buses provide a reliable and comfortable transportation solution."
        },
        {
            name: "20 Seater Bus Rental in Pune for Outstation",
            description: "Planning an outstation trip with a group? Our 20-seater bus rental services are perfect for journeys beyond Pune. With comfortable seating and modern amenities, our buses ensure a pleasant travel experience whether you're heading to distant destinations or exploring new places."
        },
        {
            name: "20 Seater Bus Rental in Pimpri Chinchwad",
            description: "In need of a 20-seater bus in Pimpri Chinchwad? We offer convenient and affordable bus rental services in this area. Our well-maintained 20-seater buses are designed to provide a comfortable and enjoyable travel experience for various occasions."
        },
        {
            name: "20 Seater Mini Bus Hire in Pimpri Chinchwad",
            description: "For those seeking a mini bus hire in Pimpri Chinchwad, our 20-seater mini buses are the perfect choice. Offering flexibility and comfort, our mini buses are ideal for both local and outstation travel, ensuring a smooth and enjoyable journey."
        },
        {
            name: "Pune to Mumbai Airport Mini Bus on Rent",
            description: "Traveling from Pune to Mumbai Airport? Our mini bus rental service provides a comfortable and efficient solution for airport transfers. With spacious seating and reliable service, our mini buses make your journey to the airport stress-free and convenient."
        },
        {
            name: "Pune to Shirdi 20 Seater Mini Bus on Rent",
            description: "Heading from Pune to Shirdi? Our 20-seater mini buses are an excellent option for this trip. With ample space and modern amenities, our mini buses ensure a comfortable and enjoyable journey to the pilgrimage site of Shirdi."
        },
        {
            name: "20 Seater Bus for Corporate Events in Pune",
            description: "Planning a corporate event in Pune? Our 20-seater buses are ideal for transporting employees or clients to and from corporate events. With comfortable seating and professional service, we ensure that your corporate travel needs are met with ease."
        },
        {
            name: "20 Seater Tempo Traveller Booking",
            description: "Looking to book a 20-seater tempo traveller? Our booking process is simple and efficient. We offer a range of 20-seater tempo travellers equipped with modern amenities to ensure a comfortable and enjoyable travel experience for your group."
        },
        {
            name: "Non-AC Seater Tempo Traveller in Pune",
            description: "For a more budget-friendly option, consider our non-AC seater tempo travellers in Pune. These vehicles provide comfortable seating and ample space without the added expense of air conditioning, making them a great choice for cost-effective group travel."
        },
        {
            name: "20 Seater Bus on Rent for Wedding in Pune",
            description: "Planning a wedding in Pune and need transportation for your guests? Our 20-seater buses are perfect for wedding transportation. With spacious interiors and comfortable seating, our buses ensure that your guests travel in style and comfort."
        },
        {
            name: "Pune to Shirdi 20 Seater Bus on Rent",
            description: "Traveling from Pune to Shirdi with a group? Our 20-seater buses offer a comfortable and spacious option for this journey. Enjoy a smooth ride with our well-maintained buses, designed to make your trip to Shirdi both pleasant and enjoyable."
        },
        {
            name: "Pune to Lonavala 20 Seater Bus on Rent",
            description: "Heading to Lonavala from Pune? Our 20-seater bus rental service provides a comfortable and reliable transportation option for your trip. With ample space and modern amenities, our buses are ideal for a relaxing journey to Lonavala."
        },
        {
            name: "20 Seater Tempo Traveller Booking in Pune",
            description: "If you're looking to book a 20-seater tempo traveller in Pune, SSKTravels24 offers a range of options tailored to meet your group travel needs. Our 20-seater tempo travellers are ideal for group excursions, corporate events, or family trips. Enjoy a comfortable ride with our well-maintained vehicles and professional drivers."
        },
        {
            name: "20 Seater Bus On Rent in Pune Airport",
            description: "Traveling to or from Pune Airport? Our 20-seater bus rental service is perfect for airport transfers. With spacious seating and reliable service, our buses ensure that your journey to and from the airport is comfortable and hassle-free."
        },
        {
            name: "Pune to Hyderabad Tempo Traveller on Rent",
            description: "Planning a trip from Pune to Hyderabad? Our tempo travellers are an excellent choice for this long-distance journey. With comfortable seating and modern amenities, our 20-seater tempo travellers provide a pleasant and relaxing ride, making your journey to Hyderabad enjoyable."
        },
        {
            name: "Pune to Konkan Darshan Tempo Traveller on Rent",
            description: "Experience the scenic beauty of Konkan with our tempo traveller rental service. Our 20-seater tempo travellers are perfect for a Konkan Darshan trip, offering ample space and comfort. Enjoy the picturesque landscapes and explore Konkan with ease in our well-maintained vehicles."
        },
        {
            name: "Mini Bus on Rent in Pune - SSKTravels24",
            description: "For mini bus rentals in Pune, SSKTravels24 provides a range of options to suit your needs. Our mini buses are ideal for small group travel, corporate outings, and family events. With comfortable seating and reliable service, we ensure a pleasant journey for all passengers."
        },
        {
            name: "20 Seater Bus on Rent in Pimpri Chinchwad",
            description: "In Pimpri Chinchwad and need a 20-seater bus? SSKTravels24 offers competitive rates and reliable service for 20-seater bus rentals in this area. Whether it's for a family gathering, corporate event, or other occasions, our buses provide the space and comfort you need."
        },
        {
            name: "20 Seater Bus on Rent in Pimpri Chinchwad for Family",
            description: "Looking for a 20-seater bus for a family outing in Pimpri Chinchwad? Our buses are perfect for accommodating large families, offering spacious seating and comfort for a memorable journey. Enjoy your family trip with our reliable and well-maintained 20-seater buses."
        },
        {
            name: "Tempo Traveller on Rent in Pimpri Chinchwad",
            description: "If you need a tempo traveller in Pimpri Chinchwad, SSKTravels24 offers various options to meet your requirements. Our tempo travellers are suitable for group travel and come equipped with modern amenities to ensure a comfortable and enjoyable ride."
        },
        {
            name: "20 Seater Bus on Rent in Pune Contact Number",
            description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient 20 Seater Bus on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
     tableData : [
        ['20 Seater Bus on Rent in Pune', '20 Seater Bus Rental in Pune for Outstation'],
        ['20 Seater Bus Rental in Pimpri Chinchwad', '20 Seater Mini Bus Hire in Pimpri Chinchwad'],
        ['Pune to Mumbai Airport Mini Bus on Rent', 'Pune to Shirdi 20 Seater Mini Bus on Rent'],
        ['20 Seater Bus for Corporate Events in Pune', '20 Seater Tempo Traveller Booking'],
        ['Non-AC Seater Tempo Traveller in Pune', '20 Seater Bus on Rent for Wedding in Pune'],
        ['Pune to Shirdi 20 Seater Bus on Rent', 'Pune to Lonavala 20 Seater Bus on Rent'],
        ['20 Seater Tempo Traveller Booking in Pune', '20 Seater Bus On Rent in Pune Airport'],
        ['Pune to Hyderabad Tempo Traveller on Rent', 'Pune to Konkan Darshan Tempo Traveller on Rent'],
        ['Mini Bus on Rent in Pune - SSKTravels24', '20 Seater Bus on Rent in Pimpri Chinchwad'],
        ['20 Seater Bus on Rent in Pimpri Chinchwad for Family', 'Tempo Traveller on Rent in Pimpri Chinchwad']
    ],    
     whychoose : [
        {
            "WhyChooseheading": "Why Choose SSKTravels24 for 20-Seater Bus on Rent in Pune?",
            "WhyChoosedescription": "When it comes to renting a 20-seater bus in Pune, SSKTravels24 is your ideal choice. Our commitment to comfort, reliability, and customer satisfaction ensures that your travel experience is both enjoyable and hassle-free. Here’s why SSKTravels24 stands out:"
        },
        {
            "WhyChooseheading": "Spacious and Comfortable Buses:",
            "WhyChoosedescription": "Our 20-seater buses are designed to provide ample space for passengers. With comfortable seating and generous legroom, every journey is enjoyable, whether it's a short trip or a long journey."
        },
        {
            "WhyChooseheading": "Modern Amenities:",
            "WhyChoosedescription": "Each bus in our fleet is equipped with modern amenities to enhance your travel experience. From air conditioning to entertainment systems, we ensure you travel in comfort and style."
        },
        {
            "WhyChooseheading": "Well-Maintained Fleet:",
            "WhyChoosedescription": "We prioritize the maintenance of our buses to ensure they are in top condition. Regular servicing and inspections guarantee safety, reliability, and a trouble-free travel experience."
        },
        {
            "WhyChooseheading": "Experienced and Professional Drivers:",
            "WhyChoosedescription": "Our drivers are highly skilled and experienced, familiar with Pune’s routes and beyond. They are committed to providing safe and smooth journeys, ensuring you arrive at your destination comfortably."
        },
        {
            "WhyChooseheading": "Flexible Rental Options:",
            "WhyChoosedescription": "We offer flexible rental packages to accommodate different needs and budgets. Whether you need the bus for a few hours or several days, we can tailor our services to fit your requirements."
        },
        {
            "WhyChooseheading": "Competitive and Transparent Pricing:",
            "WhyChoosedescription": "At SSKTravels24, we provide competitive rates with no hidden fees. Our transparent pricing structure means you’ll know exactly what you’re paying for, ensuring great value for your money."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with bookings, inquiries, and any issues that may arise. We’re here to ensure a smooth and hassle-free experience."
        },
        {
            "WhyChooseheading": "On-Time Service:",
            "WhyChoosedescription": "We value punctuality and strive to adhere to schedules. Our buses arrive on time, allowing you to plan your journey with confidence and reliability."
        },
        {
            "WhyChooseheading": "Hassle-Free Booking Process:",
            "WhyChoosedescription": "Booking a 20-seater bus with us is simple and efficient. You can reserve your vehicle through our website or by contacting our customer support team, making the process seamless and convenient."
        },
        {
            "WhyChooseheading": "Comprehensive Coverage:",
            "WhyChoosedescription": "Whether you need a bus for local travel within Pune or for outstation trips, SSKTravels24 has you covered. We offer services for various destinations and occasions."
        }
    ]    
    };    

    
   
    const faqData = [
        {
            question: "How can I book a 20-seater bus with SSKTravels24?",
            answer: "You can book a 20-seater bus by calling us at +91 8956170493 or through our website. Our team will guide you through the booking process and provide all necessary information."
        },
        {
            question: "What is included in the rental of a 20-seater bus?",
            answer: "Our rental package includes a well-maintained 20-seater bus, an experienced driver, and modern amenities. Additional services or features can be requested and may incur extra charges."
        },
        {
            question: "Are there any hidden fees in the rental cost?",
            answer: "No, we provide transparent pricing with no hidden fees. Any additional costs, such as for extra mileage or extended rental periods, will be clearly communicated before you finalize your booking."
        },
        {
            question: "Can I request an AC or non-AC bus?",
            answer: "Yes, we offer both AC and non-AC options for our 20-seater buses. You can specify your preference at the time of booking to ensure your comfort during the trip."
        },
        {
            question: "What if I need to cancel or change my booking?",
            answer: "If you need to cancel or modify your booking, please contact our customer support team as soon as possible. We will guide you through the process and inform you of any applicable cancellation fees."
        },
        {
            question: "How are your buses maintained?",
            answer: "Our buses undergo regular inspections and maintenance to ensure they are in excellent condition. We prioritize safety and comfort, so you can travel with peace of mind."
        },
        {
            question: "Can the bus be used for both local and outstation travel?",
            answer: "Yes, our 20-seater buses are suitable for both local travel within Pune and outstation trips. We provide flexible rental options to accommodate different travel needs."
        },
        {
            question: "What amenities are available on the bus?",
            answer: "Our 20-seater buses come equipped with amenities such as air conditioning, comfortable seating, and entertainment systems. Additional features can be requested based on your needs."
        },
        {
            question: "How do you ensure the safety of passengers?",
            answer: "We prioritize safety by employing experienced drivers and maintaining our buses to high standards. Additionally, our drivers are trained to handle various road conditions and ensure a safe journey."
        },
        {
            question: "What should I do if I encounter any issues during the trip?",
            answer: "If you experience any issues during your trip, please contact our customer support team immediately. We are available to address any concerns and ensure a smooth travel experience."
        }
    ];
    

    const testimonials = [
        {
            name: "Miss Anjali Patel",
            text: "I recently hired a 20-seater bus from SSKTravels24 for our company's annual team outing. The entire experience was outstanding. The bus was spacious, comfortable, and well-maintained, which made our journey pleasant and stress-free. The driver was professional and punctual, which was crucial for our tight schedule. The booking process was straightforward, and their customer support team was very helpful throughout. I highly recommend SSKTravels24 for anyone looking for reliable and top-quality bus rentals in Pune."
        },
        {
            name: "Mr. Rajesh Kumar",
            text: "SSKTravels24 provided us with a fantastic 20-seater bus for our family reunion trip. The bus was equipped with modern amenities, including air conditioning and a good sound system, which made our trip enjoyable and comfortable. The driver was courteous and ensured we had a smooth journey from start to finish. The service was exceptional, and the pricing was fair and transparent. We had a great experience and will definitely use SSKTravels24 for our future travel needs."
        }
    ];
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: "20 Seater Bus on Rent in Pune Contact Number: ",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000",
         
        ],
        email: "booking@ssktravels24.com"
      };


      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 20 Seater Bus on Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent a 20 seater bus in Pune for various trips including airport transfers, corporate events, and weddings. Competitive rates for local and outstation travel." />
  <meta name="keywords" content="20 seater bus on rent in Pune, 20 seater bus rental in Pune for outstation, 20 seater bus rental in Pimpri Chinchwad, 20 seater mini bus hire in Pimpri Chinchwad, Pune to Mumbai airport mini bus on rent, Pune to Shirdi 20 seater mini bus on rent, 20 seater bus for corporate events in Pune, 20 seater tempo traveller booking, non AC seater tempo traveller in Pune, 20 bus on rent for wedding in Pune, Pune to Shirdi 20 seater bus on rent, Pune to Lonavala 20 seater bus on rent, 20 seater tempo traveller booking in Pune, 20 seater bus on rent in Pune airport, Pune to Hyderabad tempo traveller on rent, Pune to Konkan Darshan tempo traveller on rent, mini bus on rent in Pune - SSK Travels24, 20 seater bus on rent in Pimpri Chinchwad, 20 seater bus on rent in Pimpri Chinchwad for family, tempo traveller on rent in Pimpri Chinchwad" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 20 Seater Bus on Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for 20 seater buses in Pune. Enjoy competitive rates for airport transfers, corporate events, and more. Contact us to book your bus!" />
  <meta property="og:url" content="https://https://ssktravels24.com//20-seater-bus-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//20-seater-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>20 Seater Bus on Rent in Pune</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-08.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About Renting a 20-Seater Bus in Pune with SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Seaterbusonrent;
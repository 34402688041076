
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busonrentkharadipune(){


    const cardData = 
    {
        keyword: 'Bus on Rent in Kharadi',
        heading: 'SSKTravels24: Bus on Rent in Kharadi',
        headingDescription: 'Are you planning a group outing, corporate event, or family gathering in Kharadi, Pune? Look no further! SSKTravels24 offers exceptional bus rental services in Kharadi, ensuring comfort, convenience, and reliability for all your transportation needs. Our diverse fleet includes a variety of buses tailored to different group sizes and requirements, making your journey smooth and enjoyable.',
        
        topPlaces : [
            {
                title: "1. EON IT Park",
                description: "One of Pune's largest IT hubs, EON IT Park is perfect for transporting your team for corporate events or client meetings. Our bus rental service ensures a stress-free journey to this bustling area."
            },
            {
                title: "2. Phoenix Market City",
                description: "As a premier shopping and entertainment destination, Phoenix Market City offers a variety of retail stores and dining options. With our comfortable buses, you can enjoy shopping and leisure activities in style."
            },
            {
                title: "3. Ranjn’s Garden",
                description: "This serene park is ideal for family outings or team picnics. Renting a bus from SSKTravels24 guarantees a hassle-free trip to enjoy the lush greenery and tranquil surroundings."
            },
            {
                title: "4. Aga Khan Palace",
                description: "A historical monument known for its stunning architecture and gardens, Aga Khan Palace is a significant landmark. Our bus service can take your group on a cultural tour of this renowned site."
            },
            {
                title: "5. Koregaon Park",
                description: "Renowned for its vibrant atmosphere, Koregaon Park is filled with cafes, boutiques, and cultural venues. Explore this trendy neighborhood without worrying about parking and traffic with our comfortable buses."
            },
            {
                title: "6. Shivajinagar",
                description: "As a commercial and educational hub, Shivajinagar is home to colleges, markets, and eateries. Our bus rental service is perfect for school trips, college excursions, and corporate tours in this lively area."
            },
            {
                title: "7. Bund Garden",
                description: "Located along the Pune riverside, Bund Garden is a picturesque spot for relaxation and outdoor activities. Our buses can take your group to enjoy the scenic beauty and recreational opportunities."
            },
            {
                title: "8. Pune University",
                description: "Home to prestigious institutions and a vibrant campus life, Pune University is a key educational destination. Our buses are well-suited for transporting students, faculty, and visitors."
            },
            {
                title: "9. Bhandarkar Oriental Research Institute",
                description: "This renowned research institute boasts a rich collection of manuscripts and historical documents, making it an excellent destination for academic and cultural tours."
            },
            {
                title: "10. Osho International Meditation Resort",
                description: "For those seeking peace and spiritual growth, the Osho International Meditation Resort offers a tranquil retreat. Our bus rental service ensures a comfortable journey to this serene location for workshops and meditation sessions."
            }
        ],
        
         services : [
            {
                name: "Urbania Bus on Rent in Kharadi",
                description: "Our Urbania buses are perfect for group travel, featuring spacious interiors and modern amenities. Ideal for corporate outings, school trips, or family gatherings."
            },
            {
                name: "17-Seater Bus on Rent Per Km in Kharadi",
                description: "For smaller groups, our 17-seater buses are available at competitive rates per kilometer, providing an economical solution."
            },
            {
                name: "Tempo Traveller on Rent in Kharadi",
                description: "Rent one of our tempo travellers for a more personalized travel experience, perfect for medium-sized groups with ample luggage space."
            },
            {
                name: "14, 17, 20, 25 Seater Bus on Rent in Kharadi",
                description: "Choose from a range of bus sizes to suit your needs, from 14-seater mini-buses to 25-seater options."
            },
            {
                name: "32, 35, 40, 45, 50 Seater Bus on Rent in Kharadi",
                description: "For larger groups, our fleet includes buses that can accommodate up to 50 passengers, ideal for corporate events and school excursions."
            },
            {
                name: "Bus Hire in Kharadi IT Park",
                description: "Our bus hire services are tailored for corporate travel to and from Kharadi IT Park, ensuring comfortable and reliable transportation for your team."
            },
            {
                name: "Top Bus on Rent Monthly Basis in Kharadi Pune",
                description: "Consider our monthly bus rental options for regular travel needs, offering the convenience of a dedicated bus available throughout the month."
            },
            {
                name: "Monthly Bus Rentals in Kharadi, Pune",
                description: "Our monthly rental services provide flexibility and cost-efficiency for groups requiring regular transportation."
            },
            {
                name: "Mini Bus on Rent in Kharadi Pune",
                description: "Ideal for smaller groups, our mini buses offer a comfortable and cost-effective solution for local travel."
            },
            {
                name: "Force Urbania on Rent in Kharadi",
                description: "The versatile Force Urbania is perfect for medium-sized groups, providing comfort and ample space for passengers and luggage."
            },
            {
                name: "Bus on Rent in Wagholi Pune for Outstation",
                description: "Planning an outstation trip? Our buses are ready for long-distance travel from Wagholi, ensuring a comfortable journey to your destination."
            },
            {
                name: "Bus Hire in Wagholi for Picnic",
                description: "Organize a memorable picnic with our bus hire services in Wagholi, offering spacious and comfortable buses for your favorite picnic spots."
            },
            {
                name: "Wedding Bus on Rental in Wagholi",
                description: "Make your special day even more memorable with our wedding bus rental services, providing elegant buses to transport guests."
            },
            {
                name: "Bus on Rent for Wedding in Wagholi",
                description: "Our wedding buses ensure comfort and style for your celebrations, guaranteeing a smooth transportation experience for your guests."
            },
            {
                name: "Urbania Bus on Rent in Wagholi",
                description: "Perfect for weddings, corporate events, or large group outings, our Urbania buses offer ample space and comfort for travel within Wagholi and beyond."
            },
            {
                name: "Bus On Rent in Kharadi Pune Contact Number:",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Bus On Rent in Kharadi Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        
        tableData : [
            ['- Urbania bus on rent in Kharadi', '- 17 Seater bus on rent per km in Kharadi'],
            ['- Tempo traveller on rent in Kharadi', '- Bus on Rent in Wagholi Pune for Outstation'],
            ['- Bus hire in Wagholi for picnic', '- Wedding bus on rental in Wagholi'],
            ['- Bus On Rent For Wedding in Wagholi', '- Urbania bus on rent in Wagholi'],
            ['- Force Urbania on rent in Kharadi', '- Bus Hire in Kharadi IT Park'],
            ['- 14, 17, 20, 25 Seater bus on Rent in Kharadi', '- 32, 35, 40, 45, 50 Seater bus on Rent in Kharadi'],
            ['- Bus Hire in Kharadi for Corporate Outing', '- Top Bus On Rent Monthly Basis in Kharadi Pune'],
            ['- Monthly Bus Rentals in Kharadi, Pune', '- Mini Bus On Rent in Kharadi Pune'],
            ['- Tempo traveller on rent in Kharadi', '- Force Urbania on rent in Kharadi'],
            ['- Force Urbania on rent in Kalyani Nagar', '- Force Urbania on rent in Viman Nagar']
        ],
    
        whyChoose : [
            {
                "WhyChooseheading": "Diverse Fleet of Buses:",
                "WhyChoosedescription": "From 14-seater mini-buses to 50-seater coaches, our extensive fleet caters to a variety of group sizes and travel needs. Each vehicle is meticulously maintained, clean, and equipped with comfortable seating and modern amenities, ensuring a pleasant travel experience."
            },
            {
                "WhyChooseheading": "Comfort and Convenience:",
                "WhyChoosedescription": "Our buses are outfitted with modern amenities such as comfortable seating, air conditioning, and entertainment options. We prioritize your comfort whether you're heading to a corporate event, family gathering, or school trip."
            },
            {
                "WhyChooseheading": "Experienced Drivers:",
                "WhyChoosedescription": "Our professional drivers are well-trained to navigate all types of road conditions, ensuring a safe journey. Their knowledge of local routes and traffic patterns helps avoid delays, providing a smooth travel experience."
            },
            {
                "WhyChooseheading": "Flexible Rental Options:",
                "WhyChoosedescription": "We offer customizable rental packages tailored to your specific needs. Whether you need a bus for a few hours or a full day, we can adapt our services to fit your schedule and budget, ensuring a seamless rental experience."
            },
            {
                "WhyChooseheading": "Affordable Rates:",
                "WhyChoosedescription": "At SSKTravels24, we provide competitive pricing without sacrificing quality. Our transparent pricing structure means no hidden charges, allowing you to get the best value for your money and making transportation solutions accessible for every occasion."
            },
            {
                "WhyChooseheading": "24/7 Customer Support:",
                "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with any queries or concerns. Whether you need to adjust your booking or require assistance during your trip, we are here to help."
            },
            {
                "WhyChooseheading": "Safety and Hygiene:",
                "WhyChoosedescription": "We adhere to strict safety and hygiene standards to protect our passengers. Our buses undergo regular sanitization, and we follow comprehensive safety protocols to ensure a hygienic environment for all travelers."
            },
            {
                "WhyChooseheading": "Customizable Itineraries:",
                "WhyChoosedescription": "Every trip is unique, and we offer customizable itineraries to align with your specific travel plans. Whether you have a detailed schedule or need suggestions for sightseeing, we can tailor our services to meet your requirements."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for rent in Kharadi?",
            answer: "We provide a variety of buses, including mini-buses (14-17 seats), standard buses (25-32 seats), and larger coaches (35-50 seats). Choose the bus that best fits your group size and travel needs."
        },
        {
            question: "How can I book a bus with SSKTravels24?",
            answer: "Booking a bus is easy! Contact us via phone, email, or our website. Share details about your travel dates, group size, and destination, and we will help you select the best bus and package for your needs."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes, our buses come equipped with a range of amenities, including comfortable seating, air conditioning, audio-visual systems, and more. Specific features may vary depending on the bus model."
        },
        {
            question: "Can I customize my bus rental itinerary?",
            answer: "Absolutely! We offer fully customizable itineraries to suit your travel plans. Whether you need specific stops, sightseeing recommendations, or a flexible schedule, we can adapt our services accordingly."
        },
        {
            question: "Is there a minimum rental duration?",
            answer: "Our rental services are flexible, offering options for both short-term and long-term rentals. You can book a bus for just a few hours or for an entire day based on your requirements."
        },
        {
            question: "What is the cancellation policy?",
            answer: "We maintain a clear and transparent cancellation policy. If you need to cancel or modify your booking, please reach out to us as soon as possible. Our team will guide you through the process and inform you of any applicable charges."
        },
        {
            question: "Are there any additional charges?",
            answer: "We strive for transparent pricing with no hidden fees. However, additional costs may apply for extra services or changes to your booking. We will inform you of any potential additional charges upfront."
        },
        {
            question: "How do you ensure the safety and cleanliness of your buses?",
            answer: "Our buses are regularly cleaned and sanitized to maintain a hygienic environment. We follow strict safety protocols and maintenance routines to ensure the safety and comfort of all passengers."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Aarav Choudhury",
            text: "We recently hired a bus from SSKTravels24 for our corporate team outing in Kharadi, and the experience was outstanding! The 35-seater bus was immaculate, comfortable, and fully equipped for our journey. Our driver was professional and punctual, ensuring our trip was smooth and enjoyable. We couldn’t have asked for better service. I highly recommend SSKTravels24 for anyone seeking reliable and comfortable bus rentals!"
        },
        {
            name: "Miss Priya Kulkarni",
            text: "Our family trip to Lonavala was truly enhanced by SSKTravels24. We rented a 32-seater bus for our group, and everything was perfect from start to finish. The bus was in pristine condition, and the booking process was effortless. The team at SSKTravels24 was extremely accommodating, ensuring that all our needs were met. If you’re looking for a hassle-free and comfortable bus hire experience in Kharadi, SSKTravels24 is the way to go!"
        }
    ];
    
    
    const contactData = {
        heading: "Bus On Rent in Kharadi Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus On Rent in Kharadi Pune | Convenient Travel Solutions</title>
  <meta name="description" content="Discover a range of bus rental options in Kharadi, Pune, including Urbania buses, Tempo travellers, and corporate transportation services." />
  <meta name="keywords" content="Bus On Rent in Kharadi Pune | Urbania Bus on Rent in Kharadi | 17 Seater Bus on Rent in Kharadi | Tempo Traveller on Rent in Kharadi | Bus Hire in Wagholi | Wedding Bus Rental in Wagholi | Corporate Bus Hire in Kharadi | Monthly Bus Rentals in Kharadi" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus On Rent in Kharadi Pune | Convenient Travel Solutions" />
  <meta property="og:description" content="Book Urbania buses, Tempo travellers, and more for reliable transportation in Kharadi, Pune. Ideal for weddings, corporate outings, and picnics." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-kharadi-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//kharadi-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-02.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>Frequently Asked Questions (FAQs) – Bus on Rent in Kharadi, Pune</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busonrentkharadipune;
import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics';

const BookTaxi = () => {
  const [bookingMessage, setBookingMessage] = useState('');

  const containerStyle = {
    backgroundColor: '#f8f9fa',
    textAlign: 'center',
    padding: '20px 10px',
  };

  const titleStyle = {
    marginBottom: '40px',
    fontSize: '2.5em',
    fontWeight: 'bold',
    color: '#343a40',
    fontFamily: 'Arial, sans-serif',
  };

  const optionsContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
    padding: '0 15px',
  };

  const optionStyle = {
    background: '#ffffff',
    borderRadius: '15px',
    overflow: 'hidden',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 12px 24px rgba(0, 0, 0, 0.3)',
      transform: 'scale(1.05)',
    },
  };

  const imageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    marginBottom: '15px',
  };

  const infoStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  };

  const headingStyle = {
    margin: '0 0 10px 0',
    fontSize: '1.5em',
    color: '#212529',
    fontWeight: 'bold',
  };

  const detailsStyle = {
    fontSize: '0.9em',
    color: '#6c757d',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  };

  const buttonStyle = {
    marginTop: '15px',
    padding: '10px 20px',
    backgroundColor: '#913923',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '0.9em',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    textTransform: 'uppercase',
  };

  const vehicleData = [
    {
      name: '13 Seater Urbania',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-01.jpg',
      seats: '13',
      outstation: '₹6000 (300km)',
      driverAllowance: '₹400',
      nightHalt: '₹500',
    },
    {
      name: '17 Seater Urbania',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-02.jpg',
      seats: '17',
      outstation: '₹7000 (300km)',
      driverAllowance: '₹450',
      nightHalt: '₹550',
    },
    {
      name: 'Tempo Traveller 13 Seater',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-03.jpg',
      seats: '13',
      outstation: '₹9000 (300km)',
      driverAllowance: '₹500',
      nightHalt: '₹600',
    },
    {
      name: 'Tempo Traveller 17 Seater',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-04.jpg',
      seats: '17',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: 'Tata Winger',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-05.jpg',
      seats: '14',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: '25 Seater Ac / Non AC bus',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-06.jpg',
      seats: '25',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: '32 Seater Ac / Non AC bus',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-07.jpg',
      seats: '32',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: '35 Seater Ac / Non AC bus',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-08.jpg',
      seats: '35',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: 'Corporate Bus ',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-09.jpg',
      seats: '30',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: '40 Seater Ac/ Non AC bus',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-10.jpg',
      seats: '40',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: '45 Seater Ac/ Non AC bus',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-11.jpg',
      seats: '45',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: '50 Seater Ac / Non AC bus',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-12.jpg',
      seats: '50',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: 'Luxury Bus On Rent Pune',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-13.jpg',
      seats: '40',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: 'Semi Luxury Bus Hire',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-14.jpg',
      seats: '40',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    {
      name: 'Volvo Bus on Rent',
      imgSrc: '/images/ssktravels____galleryimages/Gallery-15.jpg',
      seats: '40',
      outstation: '₹11000 (300km)',
      driverAllowance: '₹600',
      nightHalt: '₹700',
    },
    
  ];

  const handleBooking = (vehicle) => {
    const message = `I want to Book ${vehicle.name} with SSKTravels24. Request you Please Send More Details or Call Me. Thank you.`;
    const whatsappLink = `https://wa.me/918956170493?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <div>
      <GoogleAnalytics/>
<Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>

<section id="about" className="jarallax text-light">
            <div className="center-y relative text-center p-0">
                <div className="container ">
                    <div className="row ">
                        <div className="col-md-12 text-center">
                            <h1>Booking</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>


  
      <section style={containerStyle}>
        <div style={optionsContainerStyle} className='p-0'> 
          {vehicleData.map((vehicle, index) => (
            <div key={index} style={optionStyle} onClick={() => handleBooking(vehicle)}>
              <img src={vehicle.imgSrc} alt={vehicle.name} style={imageStyle} />
              <div style={infoStyle}>
                <h3 style={headingStyle}>{vehicle.name}</h3>
                <div style={detailsStyle}>
                  <div><strong>Seats:</strong> {vehicle.seats}</div>
                  {/* <div><strong>Outstation:</strong> {vehicle.outstation}</div> */}
                  {/* <div><strong>Driver Allowance:</strong> {vehicle.driverAllowance}</div> */}
                  {/* <div><strong>Night Halt:</strong> {vehicle.nightHalt}</div> */}
                </div>
                <button style={buttonStyle}>Book</button>
              </div>
            </div>
          ))}
        </div>
        {bookingMessage && (
          <div style={{ marginTop: '20px', fontSize: '1.2em', color: '#28a745' }}>
            {bookingMessage}
          </div>
        )}
      </section>

      <style jsx>{`
        @media (max-width: 768px) {
          h2 {
            font-size: 2em;
          }
          button {
            padding: 12px; /* Adjust button size for mobile */
          }
        }
      `}</style>
    </div>
  );
};

export default BookTaxi;

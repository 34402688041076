import React from 'react';
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics';
const TermsConditions = () => {
  return (

<div>
  <GoogleAnalytics/>
<Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
            <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row g-0 ">
                        <div className="col-md-12 text-center">
                            <h1>Terms & Conditions</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



     
    <div className='container-fluid '>

    <div className='row g-0'>

   
<div className="terms-conditions-container col-12 col-md-6 offset-md-1 ">
      
      <h3 className="title">Terms and Conditions</h3>
      <div className="content px-2">
        <p><b className=" darkcolor">1. Booking Confirmation:</b><br />
          All bookings with SSKTravels24(A Division of SSK Logistics) are subject to availability and confirmation. Confirmation will be provided upon successful payment and issuance of a booking reference number.</p>

        <p><b className="highlight darkcolor">2. Payment:</b><br />
          Full payment is required at the time of booking to secure your reservation. Payments can be made online via our secure payment gateway or at any of our authorized booking offices.</p>

        <p><b className="highlight darkcolor">3. Cancellation and Rescheduling:</b><br />
          Bookings may be canceled or rescheduled in accordance with our cancellation policy. Fees may apply based on the timing of the cancellation or rescheduling. Please review our cancellation policy for detailed information.</p>

        <p><b className="highlight darkcolor">4. Passenger Responsibility:</b><br />
          Passengers must ensure they have all necessary travel documents, such as valid identification and tickets, before boarding. SSKTravels24(A Division of SSK Logistics) reserves the right to refuse boarding to passengers without proper documentation.</p>

        <p><b className="highlight darkcolor">5. Baggage:</b><br />
          A limited amount of baggage is allowed per ticket, as specified at the time of booking. Additional baggage may incur extra charges.</p>

        <p><b className="highlight darkcolor">6. Travel Insurance:</b><br />
          We strongly recommend purchasing travel insurance to cover unexpected events such as trip cancellations, delays, or medical emergencies.</p>

        <p><b className="highlight darkcolor">7. Safety Regulations:</b><br />
          Passengers must adhere to all safety regulations and follow instructions provided by SSKTravels24(A Division of SSK Logistics)' staff during the journey.</p>

        <p><b className=" darkcolor">8. Changes to Itinerary:</b><br />
          SSKTravels24(A Division of SSK Logistics) reserves the right to alter itineraries, including routes and schedules, due to unforeseen circumstances such as road closures, weather conditions, or other factors beyond our control.</p>

        <p><b className="highlight darkcolor">9. Refusal of Service:</b><br />
          SSKTravels24(A Division of SSK Logistics) reserves the right to refuse service to passengers who exhibit disruptive behavior or violate our terms and conditions.</p>

        <p><b className="highlight darkcolor">10. Jurisdiction:</b><br />
          These terms and conditions are governed by the laws of [Country/State], and any disputes shall be subject to the exclusive jurisdiction of the courts in [City], [Country/State].</p>

        <p><b className="highlight darkcolor">General Policy:</b></p>
        <p><b className="highlight darkcolor"> Booking Procedure:</b> Book travel services through our website, mobile app, or customer service team.</p>
        <p><b className="highlight darkcolor"> Payment:</b> Full payment is required at the time of booking unless otherwise stated.</p>
        <p><b className="highlight darkcolor"> Confirmation:</b> Booking confirmation will be sent via email or SMS upon successful payment.</p>
        <p><b className="highlight darkcolor"> Cancellation:</b> Follow the terms outlined in our cancellation policy for cancellations or modifications.</p>
        <p><b className="highlight darkcolor"> Customer Service:</b> Our customer service team is available to assist with inquiries, issues, or special requests before, during, and after your journey.</p>

        <p><b className="highlight darkcolor">Refund Policy:</b></p>
        <p><b className="highlight darkcolor">Cancellation Fees:</b> Fees may apply depending on the timing of the cancellation and the type of service booked.</p>
        <p><b className="highlight darkcolor"> Refund Process:</b> Refunds will be processed according to the original payment method and may take a few business days.</p>
        <p><b className="highlight darkcolor">No-Show:</b> No-shows may not be eligible for refunds, as per the terms and conditions of the booking.</p>
      </div>
    </div>
    <div className='col-12 col-md-5'>
    <div className="image-container">
        <img src="\images\For mail\privacy policy and Term & condition-02.jpg" className="bg-cover" alt="Terms and Conditions" />
      </div>
    </div>

    

                
<div className="services__two-item d-flex flex-column align-items-center">
  <div className="mybox1 text-center p-4 mb-4 shadow-sm" style={{ borderRadius: '8px', backgroundColor: '#913923',color:'#fff' }}>
    <h4 className='text-white mb-3'>Phone Numbers</h4>
    <i className="bi bi-telephone-fill fs-1 mb-2"></i>
    <ul className="list-unstyled">
      <li>
        <a href="tel:+918956170493" className='text-decoration-none text-white'>
          +91 8956170493
        </a>
      </li>
      <li>
        <a href="tel:+917276307000" className='text-decoration-none text-white'>
          +91 7276307000
        </a>
      </li>
    </ul>
  </div>

  <div className="mybox1 text-center p-4 mb-4 shadow-sm" style={{ borderRadius: '8px', backgroundColor: '#913923',color:'#fff' }}>
    <h4 className='text-white mb-3'>Email</h4>
    <i className="bi bi-envelope fs-1 mb-2"></i>
    <a href="mailto:booking@ssktravels24.com
" className='text-decoration-none text-white'>
      booking@ssktravels24.com
    </a>
  </div>
</div>
    </div>
    </div>

</div>
  );
};

export default TermsConditions;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function TheBestCabServiceinPune(){


    const cardData = 
    {
        keyword: 'Best Cab Service in Pune',
        heading: 'SSKTravels24: Best Cab Service in Pune',
        headingDescription: 'When it comes to finding the best cab service in Pune, SSKTravels24 stands out for its commitment to quality, reliability, and customer satisfaction. Whether you need a ride to the airport, a trip to Lonavala, or local transportation within the city, SSKTravels24 provides a range of services tailored to meet your needs.',
        
        topPlaces: [
          {
            title: "1. Punctuality and Reliability",
            description: "At SSKTravels24, we understand that time is of the essence. Our drivers are trained to arrive on time, ensuring you reach your destination without delays."
          },
          {
            title: "2. Wide Range of Vehicle Options",
            description: "We offer a diverse fleet of vehicles, from sedans to SUVs, ensuring that we can accommodate groups of any size."
          },
          {
            title: "3. Transparent Pricing",
            description: "We provide clear quotes without hidden charges, allowing you to plan your budget effectively."
          },
          {
            title: "4. User-Friendly Booking Process",
            description: "Booking a cab with SSKTravels24 is easy and convenient through our online platform or customer service team."
          },
          {
            title: "5. Professional and Experienced Drivers",
            description: "Our drivers are professional, courteous, and familiar with the best routes in Pune."
          },
          {
            title: "6. 24/7 Availability",
            description: "Whether you need a late-night ride or an early morning pickup, SSKTravels24 is available around the clock."
          },
          {
            title: "7. Safety and Hygiene Standards",
            description: "We prioritize your safety and comfort with thoroughly cleaned and sanitized vehicles."
          },
          {
            title: "8. Flexible Payment Options",
            description: "We offer multiple payment options, including cash, credit/debit cards, and digital wallets."
          },
          {
            title: "9. Customer Support",
            description: "Our customer support team is available to address any queries or concerns you may have."
          },
          {
            title: "10. Special Packages and Offers",
            description: "We regularly introduce special packages and discounts for popular routes and tourist destinations."
          }
        ],
        
        services: [
          {
              "name": "Best Outstation Cab Service in Pune",
              "description": "For those looking to explore beyond the city, our outstation cab service is perfect for weekend getaways or business trips. We provide a wide range of vehicles to suit your group size and travel preferences, ensuring comfort and convenience as you discover new destinations."
          },
          {
              "name": "Best Taxi Service in Pune",
              "description": "SSKTravels24 is recognized for offering a fleet of well-maintained vehicles and exceptional customer service. Our commitment to punctuality and safety makes us a top choice for travelers in Pune, whether for short trips or longer journeys."
          },
          {
              "name": "Best Cab Service Pune to Mumbai",
              "description": "Planning a trip to Mumbai? Our cab service guarantees timely pickups and comfortable rides, making your travel experience stress-free. With experienced drivers who know the best routes, you can relax and enjoy the journey."
          },
          {
              "name": "Best Cab Service from Mumbai Airport to Pune",
              "description": "For travelers arriving at Mumbai Airport, we ensure a smooth transition with timely pickups and professional drivers. Our cabs are equipped with amenities to make your ride comfortable after a long flight."
          },
          {
              "name": "Best Pune to Mumbai Cab Service",
              "description": "Our cab service is designed for convenience, offering multiple vehicle options to cater to your needs and a commitment to timely service. Whether you're traveling solo or with family, we have the right cab for you."
          },
          {
              "name": "Book a Cab Service in Pune at Affordable Rates",
              "description": "At SSKTravels24, we provide quality service at reasonable prices for all your travel needs. Our transparent pricing and no hidden fees make us a trusted choice for budget-conscious travelers."
          },
          {
              "name": "Outstation Taxi Service Pune",
              "description": "Our outstation taxi service covers a wide range of destinations for your convenience. Whether it's a trip to the mountains or a visit to nearby cities, we ensure a comfortable ride with well-trained drivers."
          },
          {
              "name": "Full Day Taxi in Pune",
              "description": "Need a vehicle for the entire day? Our full day taxi service is perfect for business meetings, family outings, or city tours. Enjoy the flexibility of having a dedicated driver at your disposal, allowing you to maximize your time."
          },
          {
              "name": "Contact Number for Best Cab Service in Pune",
              "description": "For prompt and efficient service, contact SSKTravels24 at +91 8956170493. Our customer support team is ready to assist you with bookings, inquiries, and any travel needs you may have."
          }
      ],      
        tableData: [
            ['Best Cab Service in Pune', 'Best Outstation Cab Service in Pune'],
            ['Best Taxi Service in Pune', 'Best Cab Service Pune to Mumbai'],
            ['Best Cab Service Mumbai to Pune', 'Best Cab in Pune'],
            ['Best Cab Service from Pune to Goa', 'Best Cab Service from Mumbai Airport to Pune'],
            ['Best Cab Service from Pune to Mumbai Airport', 'Best Mumbai Pune Cab Service'],
            ['Best Pune to Mumbai Cab Service', 'Book a Cab Service in Pune at Affordable Rates'],
            ['Outstation Taxi Service Pune', 'Full Day Taxi in Pune'],
            ['Best Outstation Cab Service in Pune', 'Outstation Taxi Service Near Me'],
            ['Outstation Taxi Services in Mumbai', 'Best Cab Service in Mumbai'],
            ['Best Cab Service in Pimpri Chinchwad', 'Taxi Services Pimpri-Chinchwad, Local and Outstation'],
            ['Cheap Taxi Services Pimpri Chinchwad Local and Outstation', '']
          ],
          whychoose: [
            {
              WhyChooseHeading: "Why Choose Us for the Best Cab Service in Pune?",
              WhyChooseDescription: "When it comes to choosing a cab service in Pune, SSKTravels24 stands out for several reasons:"
            },
            {
              WhyChooseHeading: "Reliable and Punctual:",
              WhyChooseDescription: "We prioritize your time. Our drivers are known for their punctuality, ensuring you reach your destination without delays."
            },
            {
              WhyChooseHeading: "Wide Range of Vehicles:",
              WhyChooseDescription: "From compact cars to luxury SUVs, we offer a variety of vehicles to cater to different needs and group sizes."
            },
            {
              WhyChooseHeading: "Transparent Pricing:",
              WhyChooseDescription: "With no hidden charges, our pricing is straightforward, allowing you to plan your budget effectively."
            },
            {
              WhyChooseHeading: "Safety and Comfort:",
              WhyChooseDescription: "Our vehicles are well-maintained and equipped with safety features. We also prioritize the comfort of our passengers, providing a pleasant ride experience."
            },
            {
              WhyChooseHeading: "Professional Drivers:",
              WhyChooseDescription: "Our drivers are not only skilled but also courteous and knowledgeable about the best routes, ensuring a smooth journey."
            },
            {
              WhyChooseHeading: "24/7 Availability:",
              WhyChooseDescription: "Whether it's an early morning ride or a late-night pickup, our services are available round the clock to accommodate your schedule."
            },
            {
              WhyChooseHeading: "Easy Booking Process:",
              WhyChooseDescription: "Our user-friendly booking platform allows you to reserve your cab quickly and conveniently, either online or through our app."
            },
            {
              WhyChooseHeading: "Customer Support:",
              WhyChooseDescription: "Our dedicated customer support team is always ready to assist you with any queries or concerns, ensuring a seamless experience."
            }
          ]
    };    

    
   
    const faqData = [
        {
            question: "How can I book a cab with SSKTravels24?",
            answer: "You can book a cab through our website or mobile app. Simply enter your pickup and drop-off locations, select your vehicle type, and confirm your booking."
        },
        {
            question: "What types of vehicles do you offer?",
            answer: "We offer a range of vehicles, including sedans, SUVs, and luxury cars, to cater to different travel needs and group sizes."
        },
        {
            question: "Are there any hidden charges?",
            answer: "No, we maintain transparency in our pricing. The fare you see during booking is what you will pay, with no hidden charges."
        },
        {
            question: "Is your service available 24/7?",
            answer: "Yes, our cab services are available round the clock, ensuring you have access to transportation whenever you need it."
        },
        {
            question: "Can I book a cab for outstation travel?",
            answer: "Absolutely! We offer outstation cab services for various destinations. Just let us know your requirements, and we’ll take care of the rest."
        },
        {
            question: "What safety measures do you have in place?",
            answer: "We ensure all vehicles are regularly maintained and sanitized. Our drivers are trained in safety protocols to provide a secure travel experience."
        },
        {
            question: "What should I do if I have a complaint or feedback?",
            answer: "You can reach out to our customer support team via phone or email. We value your feedback and are committed to resolving any issues promptly."
        },
        {
            question: "Can I request a specific driver for my ride?",
            answer: "While we cannot guarantee a specific driver, we strive to assign professional and experienced drivers to ensure a pleasant journey."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rahul Patil",
            text: "I've been using SSKTravels24 for all my transportation needs in Pune, and I couldn't be happier! The booking process is super easy, and the drivers are always punctual and courteous. Whether it's for business meetings or family outings, their service is reliable and comfortable. Highly recommend them!"
        },
        {
            name: "Ms. Anjali Mehta",
            text: "SSKTravels24 is hands down the best cab service I've ever used in Pune. The cars are well-maintained, and the drivers are professional and friendly. I particularly appreciate their transparency in pricing—no hidden fees! It's great to have a service I can trust for all my travels. Thank you, SSKTravels24!"
        }
    ];
    
    const contactData = {
        heading: "Best Cab Service in Pune - SSKTravels24",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
            <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Goa Bus Hire | Enjoy Your Coastal Getaway</title>
  <meta name="description" content="Book your bus from Pune to Goa for a memorable trip. Choose from 14 to 50 seater options, including luxury and mini buses." />
  <meta name="keywords" content="Pune to Goa Bus Hire, Pune to Goa 17 Seater Bus on Rent, Urbania Bus Hire Pune to Goa, Goa Bus Packages from Pune, Tempo Traveller for Goa" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Goa Bus Hire | Enjoy Your Coastal Getaway" />
  <meta property="og:description" content="Plan your trip to Goa with our affordable bus hire services from Pune. Enjoy comfortable travel with various bus options." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-goa-bus-hire" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//goa-bus-hire-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-26.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>

<div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseHeading}</h3>
          <p>{item.WhyChooseDescription}</p>
        </div>
      ))}
    </div>


   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default TheBestCabServiceinPune;
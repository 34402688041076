import React from 'react';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';

function Enquiry() {
  const [result, setResult] = React.useState(""); // Stores the submission result

  const onSubmit = async (event) => {
    event.preventDefault(); // Prevents page refresh
    const formData = new FormData(event.target);

    formData.append("access_key", "18ea58f6-bdb1-4cff-8ddc-856d9726d816");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      });

      const data = await res.json();

      // Check if submission was successful
      if (data.success) {
        setResult("Your enquiry has been successfully submitted.");
        console.log("Success", data);
      } else {
        setResult("There was an issue with your submission. Please try again.");
        console.error("Error", data);
      }
    } catch (error) {
      setResult("An error occurred. Please check your connection and try again.");
      console.error("Error", error);
    }
  };

  return (
    <div>
      <GoogleAnalytics/>
      <Helmet>
        <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
        <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
        <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
        <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
        <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
        <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
      </Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Enquiry</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form-section">
        <div className="contact-form-container">
          <h2 className="contact-form-title">Get in Touch</h2>
          <form className="contact-form" onSubmit={onSubmit}>
            <div className="form-row">
              <input type="text" name="name" placeholder="Your Full Name" required />
              <input type="email" name="email" placeholder="Your Email" required />
            </div>
            <div className="form-row">
              <input type="tel" name="phone" placeholder="Your Phone" required />
              <input type="text" name="cab_location" placeholder="Cabs" required />
            </div>
            <div className="form-row">
              <input type="number" name="days" placeholder="Number of Days" required min="1" />
              <input type="text" name="pick_up_point" placeholder="Pick Up Point" required />
            </div>
            <div className="form-group">
              <label>Select Cabs</label>
              <select name="cabs" required>
                <option value="" disabled>Select Cabs</option>
                <option value="13 Seater Urbania Bus">Mini Bus</option>
                <option value="13 Seater Urbania Bus">Corporate Bus</option>
                <option value="13 Seater Urbania Bus">Innova Crysta</option>
                <option value="13 Seater Urbania Bus">Toyota Innova</option>
                <option value="13 Seater Urbania Bus">Mercedes GLE coupe</option>
                <option value="13 Seater Urbania Bus">Audi A6 Avant</option>
                <option value="13 Seater Urbania Bus">14 Seater Tata Winger</option>
                <option value="13 Seater Urbania Bus">20 Seater Ac / Non AC bus</option>
                <option value="13 Seater Urbania Bus">25 Seater Ac / Non AC bus</option>
                <option value="13 Seater Urbania Bus">40 Seater Ac/ Non AC bus</option>
                <option value="13 Seater Urbania Bus">45 Seater Ac/ Non AC bus</option>
                <option value="13 Seater Urbania Bus">50 Seater Ac/ Non AC bus</option>
                <option value="13 Seater Urbania Bus">Luxury Bus On Rent Pune</option>
                <option value="13 Seater Urbania Bus">Semi Luxury Bus Hire</option>
                <option value="13 Seater Urbania Bus">Volvo Bus on Rent</option>
                <option value="Luxury Bus On Rent Pune">Luxury Bus On Rent Pune</option>
              </select>
            </div>
            <div className="form-row">
              <input type="date" name="date" required />
            </div>
            <div className="form-group">
              <textarea name="message" placeholder="Your Message" required></textarea>
            </div>
            <div className="form-submit">
              <input type="submit" value="Submit Now" className="default-btn" />
            </div>
          </form>
          {result && <div className="form-result">{result}</div>}
        </div>
      </section>

      <style>{`
        .contact-form-section {
          padding: 40px 20px;
          background: #913923; /* Vibrant gradient background */
        }
        .contact-form-container {
          max-width: 600px;
          margin: 0 auto;
          background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
          padding: 30px;
          border-radius: 12px;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        }
        .contact-form-title {
          text-align: center;
          margin-bottom: 20px;
          color: #333;
          font-size: 32px;
          font-weight: bold;
        }
        .form-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
        }
        .form-row input,
        .form-group select,
        .form-group textarea {
          width: 98%;
          padding: 12px;
          border: 1px solid #ddd;
          border-radius: 6px;
          outline: none;
          transition: border-color 0.3s, background-color 0.3s;
          font-size: 16px;
          background-color: #ffffff; /* White background for inputs */
        }
        .form-row input:focus,
        .form-group select:focus,
        .form-group textarea:focus {
          border-color: #ff7e5f; /* Color change on focus */
          box-shadow: 0 0 5px rgba(255, 126, 95, 0.5);
        }
        .form-group {
          margin-bottom: 15px;
        }
        .form-group label {
          font-weight: bold;
          margin-bottom: 8px;
          display: block;
        }
        .form-submit {
          text-align: center;
        }
        .form-submit .default-btn {
          background-color: #e74c3c; /* Red button */
          color: #fff;
          padding: 12px 24px;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
        }
        .form-submit .default-btn:hover {
          background-color: #c0392b;
        }
        .form-result {
          margin-top: 20px;
          text-align: center;
          padding: 10px;
          font-size: 16px;
          background-color: #f8d7da;
          color: #721c24;
          border: 1px solid #f5c6cb;
          border-radius: 4px;
        }
      `}</style>
    </div>
  );
}

export default Enquiry;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busonrentinkharadipune(){


    const cardData = 
    {
        keyword: 'Bus on Rent in Kharadi Pune',
        heading: 'SSKTravels24: Bus on Rent in Kharadi Pune',
        headingDescription: 'Are you in Kharadi, Pune, and searching for a dependable and comfortable bus rental service? SSKTravels24 is your premier choice for all types of bus rentals, offering a wide variety of vehicles tailored to meet diverse needs. Whether you’re organizing a corporate event, a family outing, or a local sightseeing tour, we have the perfect bus for you. Discover our top 10 bus rental options in Kharadi, Pune:',
        
         topPlaces : [
            {
                title: "1. 32-Seater Luxury Bus",
                description: "Ideal for medium-sized groups seeking comfort and elegance, our 32-seater luxury bus comes equipped with plush seating, air conditioning, and entertainment systems. Perfect for corporate meetings, weddings, and special events."
            },
            {
                title: "2. 35-Seater Mini Bus",
                description: "Our 35-seater mini bus is perfect for larger groups needing ample space. It's great for school trips, community events, or family gatherings, offering a smooth and comfortable travel experience."
            },
            {
                title: "3. 40-Seater Standard Bus",
                description: "For organizing tours or events for bigger groups, our 40-seater standard bus provides ample space while maintaining comfort. It’s ideal for group excursions, city tours, or any occasion requiring moderate seating capacity."
            },
            {
                title: "4. 45-Seater Executive Bus",
                description: "Experience luxury and convenience with our 45-seater executive bus. Featuring ergonomic seating, onboard refreshments, and modern amenities, this bus is perfect for business trips, team outings, and executive travel."
            },
            {
                title: "5. 50-Seater Deluxe Bus",
                description: "Designed for large groups, our 50-seater deluxe bus offers extensive seating and top-notch comfort. Whether it’s a corporate event, family reunion, or community function, this bus ensures a pleasant journey."
            },
            {
                title: "6. Kharadi Local Sightseeing Bus",
                description: "Explore Kharadi and nearby areas with our local sightseeing bus, tailored for city tours. This bus covers major attractions and landmarks, making it an ideal choice for tourists and locals wanting to discover the region comfortably."
            },
            {
                title: "7. Kharadi Darshan Mini Bus",
                description: "Experience a comprehensive tour of Kharadi with our Darshan mini bus. This service is perfect for tourists and residents interested in exploring the city’s key attractions in a comfortable and organized manner."
            },
            {
                title: "8. Local Bus for Corporate Events",
                description: "Our local buses are equipped to meet the professional needs of business functions and corporate events. With comfortable seating and ample space for equipment, these buses ensure smooth and efficient business travel."
            },
            {
                title: "9. Local Bus for School Trips",
                description: "Safety and comfort are paramount for school trips. Our buses are specifically designed to cater to school outings, ensuring a secure and enjoyable journey for students and teachers alike."
            },
            {
                title: "10. Local Bus for Family Gatherings",
                description: "Whether it’s a family reunion, wedding, or social gathering, our local buses provide the comfort and convenience needed for a memorable event. Enjoy spacious interiors and a hassle-free journey with our family-friendly bus rentals."
            }
        ],        
        
        services : [
            {
                name: "Urbania Bus on Rent in Kharadi",
                description: "Looking for a modern, comfortable bus for your local travel needs in Kharadi? The Urbania bus on rent in Kharadi offers spacious seating and contemporary amenities. Perfect for corporate outings, family gatherings, or local tours, the Urbania ensures a smooth and enjoyable ride."
            },
            {
                name: "17-Seater Bus on Rent Per Km in Kharadi",
                description: "For smaller groups needing flexibility, the 17-seater bus on rent per km in Kharadi provides an ideal solution. With competitive pricing based on distance traveled, it’s a cost-effective choice for short trips, local events, and day outings."
            },
            {
                name: "Tempo Traveller on Rent in Kharadi",
                description: "The Tempo Traveller on rent in Kharadi is perfect for medium-sized groups seeking comfort and efficiency. Whether you're heading out for a business trip or a leisure tour, the Tempo Traveller offers ample space and convenience for all passengers."
            },
            {
                name: "Bus on Rent in Wagholi Pune for Outstation",
                description: "Planning a trip outside of Pune? The bus on rent in Wagholi Pune for outstation travel ensures that your journey is comfortable and hassle-free. Ideal for group travel to destinations beyond Pune, these buses come equipped with everything you need for a pleasant trip."
            },
            {
                name: "Bus Hire in Wagholi for Picnic",
                description: "A day out with family or friends is always more enjoyable with the right transportation. The bus hire in Wagholi for picnic trips provides comfort and convenience, making your outdoor excursions and recreational activities stress-free and enjoyable."
            },
            {
                name: "Wedding Bus on Rental in Wagholi",
                description: "Make your special day even more memorable with a wedding bus on rental in Wagholi. These buses offer elegant and comfortable transport for your wedding guests, ensuring they arrive at the venue in style and with ease."
            },
            {
                name: "Bus On Rent For Wedding in Wagholi",
                description: "Looking for a tailored solution for your wedding transport needs? The bus on rent for wedding in Wagholi can accommodate large groups and is equipped to handle the specifics of wedding transportation, providing comfort and elegance on your big day."
            },
            {
                name: "Urbania Bus on Rent in Wagholi",
                description: "For a modern and stylish transport solution, consider the Urbania bus on rent in Wagholi. With its sleek design and high-end features, it’s perfect for both corporate events and social gatherings in Wagholi."
            },
            {
                name: "Force Urbania on Rent in Kharadi",
                description: "The Force Urbania on rent in Kharadi offers a robust and comfortable option for various transportation needs. Whether it’s for a corporate event, a family outing, or a local tour, the Force Urbania delivers reliability and comfort."
            },
            {
                name: "Bus Hire in Kharadi IT Park",
                description: "For businesses in Kharadi IT Park, bus hire services are tailored to corporate requirements. Providing reliable transportation for team outings, client meetings, and other corporate events, these buses ensure a smooth and professional travel experience."
            },
            {
                name: "14, 17, 20, 25 Seater Bus on Rent in Kharadi",
                description: "When you need a bus with specific seating capacity, the 14, 17, 20, and 25-seater bus on rent in Kharadi offers the flexibility to choose the right size for your group. Perfect for office trips, family gatherings, and local excursions."
            },
            {
                name: "32, 35, 40, 45, 50 Seater Bus on Rent in Kharadi",
                description: "For larger groups, the 32, 35, 40, 45, and 50-seater buses on rent in Kharadi provide ample space and comfort. Ideal for school trips, corporate functions, and community events, these buses ensure everyone travels together comfortably."
            },
            {
                name: "Bus Hire in Kharadi for Corporate Outing",
                description: "The bus hire in Kharadi for corporate outings offers professional and reliable transportation for your team. With comfortable seating and essential amenities, these buses are perfect for team-building activities, conferences, and business travel."
            },
            {
                name: "Bus Hire in Kharadi for Corporate Employees",
                description: "SSKTravels24 offers reliable bus hire in Kharadi for corporate employees. Our fleet includes a variety of buses suitable for corporate travel, ensuring that your employees travel comfortably to and from work, events, or seminars."
            },
            {
                name: "Luxury Bus Hire in Kharadi for Corporate Employees",
                description: "For a premium travel experience, consider our luxury bus hire in Kharadi for corporate employees. SSKTravels24 provides high-end buses equipped with modern amenities such as comfortable seating, Wi-Fi, and entertainment systems."
            },
            {
                name: "Tempo Traveller on Rent in Kharadi Wagholi",
                description: "Need a tempo traveller on rent in Kharadi Wagholi? SSKTravels24 offers well-maintained tempo travellers that are perfect for small group travel."
            },
            {
                name: "Tempo Traveller Hire in Viman Nagar",
                description: "SSKTravels24 provides tempo traveller hire in Viman Nagar, ideal for group outings and local travel."
            },
            {
                name: "Tempo Traveller Hire in Wadgaon Sheri",
                description: "For those in Wadgaon Sheri, SSKTravels24 offers tempo traveller hire services that cater to both small and large groups."
            },
            {
                name: "Tempo Traveller Hire in Chandan Nagar",
                description: "If you're looking for tempo traveller hire in Chandan Nagar, SSKTravels24 has you covered with a range of options."
            },
            {
                name: "Mini Bus On Rent in Pune Contact Number",
                description: "Contact SSKTravels at +91 8956170493 for prompt and efficient Mini Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],        
        tableData : [
            ['- Urbania bus on rent in Kharadi', '- 17 Seater bus on rent per km in Kharadi'],
            ['- Tempo traveller on rent in Kharadi', '- Bus on Rent in Wagholi Pune for Outstation'],
            ['- Bus hire in Wagholi for picnic', '- Wedding bus on rental in Wagholi'],
            ['- Bus On Rent For Wedding in Wagholi', '- Urbania bus on rent in Wagholi'],
            ['- Force Urbania on rent in Kharadi', '- Bus Hire in Kharadi IT Park'],
            ['- 14, 17, 20, 25 Seater bus on Rent in Kharadi', '- 32, 35, 40, 45, 50 Seater bus on Rent in Kharadi'],
            ['- Bus Hire in Kharadi for Corporate Outing', '- Force Urbania on Rent in Kharadi'],
            ['- Bus hire in Kharadi for corporate employees', '- Luxury bus hire in Kharadi for corporate employees'],
            ['- Tempo traveller on rent in Kharadi Wagholi', '- Tempo Traveller hire in Viman Nagar'],
            ['- Tempo traveller hire in Wadgaon Sheri', '- Tempo traveller hire in Chandan Nagar']
        ],        
    
        "whychoose": [
    {
        "WhyChooseheading": "Diverse Fleet:",
        "WhyChoosedescription": "We offer a wide selection of buses to meet various needs, ranging from compact 14-seater options to spacious 50-seater buses. Whether you’re planning a corporate event, a family outing, or a local tour, we have the perfect vehicle for you."
    },
    {
        "WhyChooseheading": "Comfort and Luxury:",
        "WhyChoosedescription": "Our buses are equipped with modern amenities to ensure a comfortable and enjoyable journey. Features such as air conditioning, plush seating, and entertainment systems make every trip relaxing and pleasant."
    },
    {
        "WhyChooseheading": "Professional Drivers:",
        "WhyChoosedescription": "Our experienced drivers are not only skilled but also courteous and well-versed in local routes. They guarantee a safe and smooth travel experience while adhering to all safety regulations and schedules."
    },
    {
        "WhyChooseheading": "Affordable Rates:",
        "WhyChoosedescription": "We provide competitive and transparent pricing with no hidden fees. Our rental rates are designed to accommodate various budgets while offering exceptional value for your money."
    },
    {
        "WhyChooseheading": "Flexible Booking Options:",
        "WhyChoosedescription": "We offer flexible booking solutions to fit your schedule and itinerary. Whether you need a bus for a few hours or several days, we can customize our services to meet your specific requirements."
    },
    {
        "WhyChooseheading": "Reliable Service:",
        "WhyChoosedescription": "At SSKTravels24, we take pride in our reliability and commitment to customer satisfaction. Our booking process is straightforward, and our team is always available to assist with any inquiries or special requests."
    },
    {
        "WhyChooseheading": "Hassle-Free Experience:",
        "WhyChoosedescription": "From booking to the end of your journey, we ensure a seamless experience. Our well-maintained buses and efficient service allow you to focus on enjoying your trip without worrying about the details."
    }
]

    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for rent in Kharadi, Pune?",
            answer: "At SSKTravels24, we offer a variety of buses, including 14-seater, 17-seater, 20-seater, 25-seater, 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. Each bus is equipped with modern amenities to cater to different needs."
        },
        {
            question: "How can I book a bus with SSKTravels24?",
            answer: "You can book a bus by contacting us directly via phone or through our online booking platform. Simply provide us with details such as the type of bus, rental duration, and any special requirements you may have."
        },
        {
            question: "Are the buses equipped with air conditioning?",
            answer: "Yes, all our buses are equipped with air conditioning to ensure a comfortable journey, regardless of the weather conditions."
        },
        {
            question: "What is the pricing structure for renting a bus in Kharadi?",
            answer: "Our pricing is competitive and based on factors such as the type of bus, rental duration, and distance traveled. We offer transparent rates with no hidden fees. For a detailed quote, please contact us directly."
        },
        {
            question: "Are drivers provided with the rental?",
            answer: "Yes, all our bus rentals come with professional drivers who are experienced, courteous, and knowledgeable about local routes. They ensure a safe and smooth travel experience."
        },
        {
            question: "Can I make changes to my booking after it is confirmed?",
            answer: "Yes, you can make changes to your booking based on availability and our policy. Please contact us as soon as possible to discuss any modifications to your reservation."
        },
        {
            question: "Is there a minimum rental duration for the buses?",
            answer: "Our minimum rental duration may vary depending on the type of bus and the specifics of your trip. Please reach out for detailed information regarding rental durations."
        },
        {
            question: "Do you offer buses for outstation travel?",
            answer: "Yes, we offer buses for both local and outstation travel. Whether you’re planning a day trip or a longer journey, we can accommodate your travel needs."
        },
        {
            question: "Are there any additional charges for long-distance travel?",
            answer: "Additional charges may apply for long-distance travel, depending on the distance and duration of the trip. Our team will provide a detailed quote that includes all applicable fees."
        },
        {
            question: "What should I do if I have special requirements or requests?",
            answer: "If you have any special requirements or requests, please inform us at the time of booking. We will do our best to accommodate your needs and ensure a satisfactory experience."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Anil Joshi",
            text: "I recently organized a corporate event in Kharadi, Pune, and chose SSKTravels24 for our transportation needs. The service was impeccable from start to finish. We rented a 40-seater bus for the day, and it was spotlessly clean, comfortable, and well-maintained. The driver was punctual, professional, and had excellent knowledge of the area, ensuring a smooth and stress-free journey for our team. The booking process was straightforward, and the team at SSKTravels24 was incredibly responsive to all our queries. I highly recommend them for anyone needing reliable bus rental services in Kharadi!"
        },
        {
            name: "Mrs. Priya Mehta",
            text: "Our family reunion in Kharadi was made even more memorable thanks to SSKTravels24. We rented a 35-seater bus for a day trip around Pune, and the entire experience was fantastic. The bus was spacious, comfortable, and equipped with all the amenities we needed. The driver was courteous and ensured that we reached all our destinations on time. The team at SSKTravels24 went above and beyond to accommodate our schedule and special requests. If you’re looking for top-notch bus rental services in Kharadi, look no further than SSKTravels24!"
        }
    ];
    
    
    const contactData = {
        heading: "Bus On Rent in Kharadi Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus On Rent in Kharadi Pune | Affordable Bus Rental Services</title>
  <meta name="description" content="Explore bus rental options in Kharadi Pune, including Urbania buses and tempo travellers for weddings, corporate outings, and picnics." />
  <meta name="keywords" content="Urbania bus on rent in Kharadi | 17 Seater bus on rent per km in Kharadi | Tempo traveller on rent in Kharadi | Bus on Rent in Wagholi Pune for Outstation | Bus hire in Wagholi for picnic | Wedding bus on rental in Wagholi | Bus On Rent For Wedding in Wagholi | Urbania bus on rent in Wagholi | Force Urbania on rent in Kharadi | Bus Hire in Kharadi IT Park | 14 17 20 25 Seater bus on Rent in Kharadi | 32 35 40 45 50 Seater bus on Rent in Kharadi | Bus Hire in Kharadi for Corporate Outing | Force Urbania on Rent in Kharadi | Bus hire in Kharadi for corporate employees | Luxury bus hire in Kharadi for corporate employees | Tempo traveller on rent in Kharadi Wagholi | Tempo Traveller hire in Viman Nagar | Tempo Traveller hire in Wadgaon Sheri | Tempo Traveller hire in Chandan Nagar" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus On Rent in Kharadi Pune | Affordable Bus Rental Services" />
  <meta property="og:description" content="Discover a range of bus rental services in Kharadi Pune, perfect for corporate events, weddings, and local trips. Book now for the best rates!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-kharadi" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//kharadi-bus-rent-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-28.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs: Bus on Rent in Kharadi, Pune - SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busonrentinkharadipune;
import React from 'react';
import Carousel from '../pages/Carousel';
import SectionCars from '../pages/SectionCars';
import StatsSection from '../pages/StatsSection';
import FeaturesSection from '../pages/FeaturesSection';
import ImageWithTabSection from '../pages/ImageWithTabSection';
import LatestNews from '../pages/LatestNews';
import Testimonials from '../pages/Testimonials';
import FAQSection from '../pages/FAQSection';
import LogoSlider from '../pages/LogoSlider';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../pages/GoogleAnalytics';


const HeroSection = () => {
  return (
    
<div className=''>
<GoogleAnalytics/>
<Helmet>
<title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
        <meta name="description" content="Explore the world with SSK Travel24. Best travel deals and guides." />
        <meta name="keywords" content="SSK Travels24, travel, travel deals, SSK24, SSKTravels24" />
      </Helmet>
      <Helmet>
  {/* Other tags */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "TravelAgency",
      name: "SSK Travel24",
      url: "https://https://ssktravels24.com/",
      logo: "https://https://ssktravels24.com/images/logo.jpg",
      description: "Explore the world with SSK Travels24."
    })}
  </script>
</Helmet>

    <Carousel/>
<SectionCars/>
<StatsSection/>
<FeaturesSection/>
<LatestNews/>
<Testimonials/>

<section id="section-call-to-action" className="bg-color-2 pt60 pb60 text-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 offset-lg-2">
            <span className="subtitle text-white">Call us for further information</span>
            <h2 className="s2">SSK Travels24 customer care is here to help you anytime.</h2>
          </div>

          <div className="col-lg-4 text-lg-center text-sm-center">
            <div className="phone-num-big">
              <i className="fa fa-phone"></i>
              <span className="pnb-text">Call Us Now</span>
              <a href="tel:+918956170493" className="pnb-num text-white">+91 89561 70493</a>
            </div>
            <Link to='/contact' className="btn-main">Contact Us</Link>
          </div>
        </div>
      </div>
    </section>
<LogoSlider/>

</div>
  );
};

export default HeroSection;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function LuxuryCarRentalinPune(){


    const cardData = 
    {
        keyword: 'Luxury Car Rental in Pune',
        heading: 'Luxury Car Rental in Pune – SSKTravels24',
        headingDescription: 'Pune, known as the "Oxford of the East," is a vibrant city that beautifully blends rich heritage with modernity. With its pleasant weather, lush greenery, and cultural landmarks, Pune attracts visitors from all over. Whether you\'re in town for business, a family vacation, or a leisurely getaway, exploring Pune in a luxury car enhances your experience.',
      
        topPlaces: [
          {
            title: "1. Aga Khan Palace",
            description: "This historical monument is not only a beautiful architectural marvel but also a significant site in India's freedom struggle. The palace houses a museum dedicated to Mahatma Gandhi and his associates."
          },
          {
            title: "2. Shaniwar Wada",
            description: "Once the seat of the Peshwas of the Maratha Empire, Shaniwar Wada is a historical fortification that showcases impressive architecture and offers insights into Pune's regal past."
          },
          {
            title: "3. Sinhagad Fort",
            description: "A popular trekking destination, Sinhagad Fort offers stunning views and a glimpse into Maharashtra's history. The fort is known for its role in the Maratha Empire and is a must-visit for history buffs."
          },
          {
            title: "4. Osho Ashram",
            description: "Located in the serene surroundings of Koregaon Park, this spiritual retreat is famous for meditation and wellness programs, attracting visitors seeking peace and tranquility."
          },
          {
            title: "5. Parvati Hill",
            description: "For panoramic views of the city, climb the 108 steps to Parvati Hill. The temple at the summit is dedicated to the goddess Parvati and offers a peaceful environment away from the city's hustle."
          },
          {
            title: "6. Pune Okayama Friendship Garden",
            description: "Inspired by the famous Korakuen Garden in Okayama, Japan, this beautifully landscaped garden is a perfect spot for relaxation and leisurely strolls amidst nature."
          },
          {
            title: "7. National War Museum",
            description: "This museum is dedicated to the soldiers who fought for India, showcasing various artifacts, weapons, and vehicles from different eras, making it an educational visit for all."
          },
          {
            title: "8. Katraj Snake Park",
            description: "A fascinating place for families, this park houses various species of snakes and offers educational insights into wildlife conservation."
          },
          {
            title: "9. Lal Mahal",
            description: "A historic palace that dates back to the 17th century, Lal Mahal is associated with the life of Chhatrapati Shivaji Maharaj and is an important cultural landmark in Pune."
          },
          {
            title: "10. Empress Botanical Gardens",
            description: "Spanning over 39 acres, these gardens are home to a variety of flora and fauna, making it a delightful spot for nature lovers and picnickers."
          }
        ],
      
        services: [
          {
            name: "Audi on Rent in Pune",
            description: "Experience the elegance and performance of an Audi. Our Audi rental service in Pune offers a range of models to suit your needs, whether it's for a special event or a business trip."
          },
          {
            name: "BMW Rent in Pune",
            description: "Drive in style with our BMW rentals. Known for their luxury and performance, BMW cars are perfect for both corporate events and personal use. Enjoy a seamless rental experience with SSKTravels24."
          },
          {
            name: "Jaguar Car on Rent in Pune",
            description: "Feel the thrill of driving a Jaguar. Our Jaguar rental service provides you with a premium driving experience, ideal for making a statement at any event or occasion."
          },
          {
            name: "Luxury Cars on Rent for Wedding in Pune",
            description: "Make your wedding day unforgettable with our luxury car rental service. Choose from a variety of stunning vehicles to arrive in style on your special day."
          },
          {
            name: "Luxury Car Rental in Pimpri Chinchwad",
            description: "SSKTravels24 provides luxury car rentals in Pimpri Chinchwad. Whether for business or pleasure, our cars offer comfort and style."
          },
          {
            name: "Rolls Royce on Rent in Pune",
            description: "Experience the pinnacle of luxury with our Rolls Royce rentals. Perfect for weddings and special occasions, this car will make you feel like royalty."
          },
          {
            name: "Lamborghini on Rent in Pune",
            description: "Make a statement by renting a Lamborghini. This iconic sports car is available for those looking to experience ultimate luxury and performance."
          },
          {
            name: "Luxury Car Rental Pune for Outstation",
            description: "Planning an outstation trip? Our luxury car rental service offers comfortable vehicles for long journeys, ensuring a premium travel experience."
          },
          {
            name: "Cheap Luxury Car on Rent in Pune for Wedding",
            description: "Enjoy affordable luxury with our budget-friendly options for wedding car rentals. Experience elegance without breaking the bank."
          },
          {
            name: "Luxury Car on Rent for Corporate Events",
            description: "Impress clients and colleagues by renting a luxury car for corporate events. Our premium fleet will ensure you arrive in style."
          },
          {
            name: "Contact Number for Luxury Car Rental in Pune",
            description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Luxury Car Rental in Pune. We ensure a smooth and enjoyable ride for all our customers."
          }
        ],
        tableData: [
            ['Audi on Rent in Pune', 'BMW Rent in Pune'],
            ['Jaguar Car on Rent in Pune', 'Hire Luxury Cars in Pune'],
            ['Jaguar on Rent in Pune', 'Luxury Car on Rent for Wedding in Pune'],
            ['Luxury Cars on Rent in Pune for Wedding', 'Premium Car Rental Pune'],
            ['Rent a Mercedes in Pune', 'Rent Luxury Cars Pune'],
            ['Wedding Cars for Rent in Pune', 'Luxury Car on Rent in Pune for Wedding'],
            ['Cheap Luxury Car on Rent in Pune for Wedding', 'Luxury Car Rental in Pimpri Chinchwad'],
            ['Best Luxury Car Rental in Pimpri Chinchwad', 'Wedding Car Rental Pune'],
            ['Luxury Car on Rent for Corporate Events', 'Rolls Royce on Rent in Pune'],
            ['Luxury Car on Rent in Pune', 'Lamborghini on Rent in Pune'],
            ['Luxury Car Rental Pune for Outstation', '']
          ],
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for Luxury Car Rental in Pune - SSKTravels24?",
              WhyChoosedescription: "At SSKTravels24, we specialize in providing a seamless luxury car rental experience tailored to your specific needs. Here are several reasons to choose us:"
            },
            {
              WhyChooseheading: "Diverse Fleet:",
              WhyChoosedescription: "Our extensive selection of luxury vehicles, including Audi, BMW, Mercedes, and more, ensures that you find the perfect car for any occasion."
            },
            {
              WhyChooseheading: "Affordable Pricing:",
              WhyChoosedescription: "We offer competitive pricing without compromising on quality. Enjoy luxury at rates that won’t break the bank."
            },
            {
              WhyChooseheading: "Excellent Customer Service:",
              WhyChoosedescription: "Our dedicated team is committed to providing exceptional customer service, ensuring a hassle-free rental process from start to finish."
            },
            {
              WhyChooseheading: "Flexible Rental Options:",
              WhyChoosedescription: "Whether you need a car for a day, a week, or for a special event, we provide flexible rental packages to meet your requirements."
            },
            {
              WhyChooseheading: "Well-Maintained Vehicles:",
              WhyChoosedescription: "Our cars are regularly serviced and meticulously maintained, guaranteeing a safe and comfortable ride."
            },
            {
              WhyChooseheading: "Convenient Booking Process:",
              WhyChoosedescription: "With our user-friendly online booking system, reserving your luxury car is quick and easy."
            },
            {
              WhyChooseheading: "Special Packages for Events:",
              WhyChoosedescription: "We offer customized packages for weddings, corporate events, and other special occasions, complete with professional chauffeurs if needed."
            },
            {
              WhyChooseheading: "24/7 Support:",
              WhyChoosedescription: "Our customer support is available around the clock to assist you with any queries or concerns during your rental period."
            }
          ]
    };    

    
    const faqData = [
        {
          question: "How can I book a luxury car with SSKTravels24?",
          answer: "You can book a luxury car through our website by selecting your desired vehicle, rental dates, and completing the booking form. Our team will confirm your reservation shortly."
        },
        {
          question: "What types of luxury cars are available for rent?",
          answer: "We offer a diverse range of luxury cars, including Audi, BMW, Mercedes, Jaguar, Rolls Royce, and Lamborghini, among others."
        },
        {
          question: "Are there any hidden charges in the rental price?",
          answer: "No, we believe in transparency. The price you see during the booking process includes all taxes and charges. There are no hidden fees."
        },
        {
          question: "Is a driver included with the rental?",
          answer: "You can choose to rent a car with or without a driver. Our experienced chauffeurs are available for hire to ensure a comfortable and stress-free ride."
        },
        {
          question: "What is the minimum rental period?",
          answer: "Our minimum rental period is usually 24 hours, but we also offer hourly packages for specific events."
        },
        {
          question: "Do you provide cars for weddings?",
          answer: "Yes, we specialize in wedding car rentals and offer customized packages to make your special day unforgettable."
        },
        {
          question: "Can I pick up and drop off the car at different locations?",
          answer: "Yes, we offer flexible pick-up and drop-off options, including different locations within Pune and nearby areas."
        },
        {
          question: "What if I need to cancel my booking?",
          answer: "We understand that plans can change. Our cancellation policy allows you to cancel your booking, but please refer to our terms for any applicable charges."
        },
        {
          question: "How do I make payment for the rental?",
          answer: "Payments can be made online through secure payment gateways or directly at the time of vehicle pickup."
        },
        {
          question: "Are the cars insured?",
          answer: "Yes, all our rental cars come with comprehensive insurance coverage to ensure your safety and peace of mind during the rental period."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Rajesh Verma",
          text: "Renting a luxury car from SSKTravels24 was an amazing experience! The vehicle was in perfect condition and made our trip feel extra special. The service was prompt and professional. I highly recommend them for anyone looking to travel in style!"
        },
        {
          name: "Ms. Pratiksha Rao",
          text: "I recently hired a luxury car for a wedding event through SSKTravels24, and it was fantastic! The car was elegant and well-maintained, adding a touch of class to our celebration. The driver was punctual and very courteous. I will definitely choose them again for future rentals!"
        }
      ];
      
      const contactData = {
        heading: "Luxury Car Rental in Pune Contact Information",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Luxury Car Rental in Pune | Audi, BMW, Rolls Royce & More</title>
  <meta name="description" content="Experience premium luxury car rentals in Pune. Choose from Audi, BMW, Jaguar, and more for weddings, corporate events, and special occasions." />
  <meta name="keywords" content="Luxury car rental in Pune, Audi on rent in Pune, BMW rent in Pune, Jaguar car on rent, wedding car rental Pune, luxury cars for corporate events" />
  <meta property="og:title" content="Luxury Car Rental in Pune | SSK Travels24" />
  <meta property="og:description" content="Book luxury cars in Pune for weddings, corporate events, and more. Enjoy competitive rates and exceptional service." />
  <meta property="og:url" content="https://https://ssktravels24.com//luxury-car-rental-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//luxury-car-rental-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-27.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default LuxuryCarRentalinPune ;
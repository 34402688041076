
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function BusRentInPune50Seater(){


    const cardData = 
    {
        keyword: '50 Seater Bus Rent in Pune',
        heading: 'SSK Travels: 50 Seater Bus Rent in Pune',
        headingDescription: 'For large group travel in Pune, SSK Travels offers reliable and comfortable 50-seater bus rentals. Perfect for corporate events, weddings, school excursions, and family reunions. Explore the top features and services we provide to meet your group travel needs.',
        
        topPlaces: [
          {
            title: "1. Experienced and Professional Drivers",
            description: "Our drivers are experienced and prioritize safety, punctuality, and courteous service for a smooth journey."
          },
          {
            title: "2. Well-Maintained Fleet",
            description: "We maintain our fleet to the highest standards, ensuring each bus is clean and in optimal condition for every journey."
          },
          {
            title: "3. Affordable Pricing",
            description: "Our competitive pricing is transparent with no hidden fees, providing excellent value for your group travel needs."
          },
          {
            title: "4. Customizable Travel Packages",
            description: "We offer customizable travel packages to suit your unique requirements, whether for a day trip or a multi-day excursion."
          },
          {
            title: "5. Spacious Seating",
            description: "Each bus is designed with ample seating space and ergonomic seats for maximum comfort on long journeys."
          },
          {
            title: "6. Air-Conditioned Comfort",
            description: "Our fully air-conditioned buses provide a cool and pleasant environment, especially during hot summer months."
          },
          {
            title: "7. Reclining Seats",
            description: "Equipped with reclining seats for added comfort, allowing passengers to adjust their seating position."
          },
          {
            title: "8. Advanced Entertainment System",
            description: "Our buses feature LED TVs and high-quality sound systems to keep passengers entertained throughout the journey."
          },
          {
            title: "9. Ample Luggage Space",
            description: "Plenty of luggage storage, including overhead compartments, ensuring all travel essentials fit comfortably."
          },
          {
            title: "10. Modern Safety Features",
            description: "Safety is paramount with seat belts, emergency exits, fire extinguishers, first-aid kits, and GPS tracking."
          }
        ],
        
        services: [
          {
            name: "50 Seater Bus on Rent for Weddings in Pune",
            description: "Ensure seamless transportation for your wedding guests with spacious interiors and luxurious comfort."
          },
          {
            name: "50 Seater Bus for Corporate Events",
            description: "Professional transportation for your team, ensuring timely arrivals and a comfortable travel experience."
          },
          {
            name: "50 Seater AC and Non-AC Bus Options",
            description: "Choose from air-conditioned or budget-friendly non-AC buses, both offering ample space for luggage and passengers."
          },
          {
            name: "50 Seater Bus for Religious Tours",
            description: "Comfortable travel for sacred site visits, including Ashtavinayak Darshan and Jyotirlinga tours."
          },
          {
            name: "50 Seater Bus on Rent in Pimpri Chinchwad",
            description: "Convenient transportation solutions for group travel needs in Pimpri Chinchwad."
          },
          {
            name: "50 Seater Bus for Outstation Trips",
            description: "Ideal for long-distance travel, ensuring safety and comfort on journeys to popular destinations."
          },
          {
            name: "50 Seater Bus Rent Price in Pune",
            description: "Contact us for personalized quotes based on your travel requirements with transparent pricing."
          },
          {
            name: "Contact Number for 50 Seater Bus Rent in Pune",
            description: "For prompt and efficient 50 Seater Bus Rent in Pune, contact SSK Travels at +91 8956170493."
          }
        ],
        tableData: [
            ['50 Seater Bus per km rate in Pune', 'Bus on Rent in Pimpri Chinchwad'],
            ['50 Seater Bus on Rent Hadapsar', '50 Seater Coach Hire Prices'],
            ['50 Seater Bus Rent Price', '50 Seater Coach Bus Hire in Pune'],
            ['50 Seater Bus Hire in Kharadi', '50 Seater AC Bus per km Rate'],
            ['Hire 50 Seater Bus on Rent', '50 Seater Bus for Hire for Outstation from Pune'],
            ['50 Seater Bus on Rent for Outstation', '50 Seater Bus for Wedding in Pune'],
            ['50 Seater Bus on Rent for Corporate Picnic', '50 Seater Bus on Rent for Corporate Event'],
            ['50 Seater Bus on Rent in Viman Nagar', '50 Seater Bus on Rent for Ashtavinayak Darshan'],
            ['50 Seater Bus on Rent for Jyotirlinga Darshan', '']
          ],
          "whychoose": [
            {
              "WhyChooseheading": "Why Choose SSKTravels24 for 50-Seater Bus Rentals in Pune?",
              "WhyChoosedescription": "When it comes to 50-seater bus rentals in Pune, SSKTravels24 stands out for several compelling reasons. We offer a comprehensive range of services designed to meet the diverse needs of our clients, whether for corporate events, family gatherings, weddings, or outstation trips. Here’s why you should choose us for your next journey:"
            },
            {
              "WhyChooseheading": "Spacious and Comfortable Buses:",
              "WhyChoosedescription": "Our 50-seater buses prioritize passenger comfort. With ample legroom, plush seating, and modern amenities, we ensure a pleasant journey for everyone, whether you're traveling within Pune or heading out for a longer trip."
            },
            {
              "WhyChooseheading": "Reliable and Experienced Drivers:",
              "WhyChoosedescription": "At SSKTravels24, we take pride in our professional driving team. Our drivers are highly experienced, licensed, and knowledgeable about local and outstation routes. Their primary goal is to provide a safe and comfortable journey, ensuring timely arrivals and departures."
            },
            {
              "WhyChooseheading": "Well-Maintained Fleet:",
              "WhyChoosedescription": "We maintain our 50-seater buses to the highest standards. Each bus undergoes regular servicing and thorough inspections to ensure optimal performance and safety, minimizing the risk of breakdowns."
            },
            {
              "WhyChooseheading": "Transparent and Competitive Pricing:",
              "WhyChoosedescription": "Our pricing for 50-seater bus rentals is both competitive and transparent. We provide clear and detailed quotes with no hidden fees, allowing you to budget effectively."
            },
            {
              "WhyChooseheading": "Customizable Rental Options:",
              "WhyChoosedescription": "Whether you need a bus for a wedding, corporate event, school picnic, or an outstation journey, we offer customizable rental options tailored to your specific needs."
            },
            {
              "WhyChooseheading": "Excellent Customer Support:",
              "WhyChoosedescription": "From the initial booking to the end of your journey, our customer support team is available to assist you, prioritizing your needs and providing prompt, courteous, and effective support."
            },
            {
              "WhyChooseheading": "Punctual and Reliable Service:",
              "WhyChoosedescription": "We understand the importance of timeliness for group travel. Our buses adhere to strict schedules to ensure punctual departures and arrivals."
            },
            {
              "WhyChooseheading": "Variety of Amenities:",
              "WhyChoosedescription": "Depending on your needs, our 50-seater buses can be equipped with various amenities such as air conditioning, audio systems, and Wi-Fi, enhancing your travel experience."
            }
          ]
    };    

    
   
    const faqData = [
        {
          question: "What is included in the rental of a 50-seater bus?",
          answer: "Our 50-seater bus rental includes comfortable seating, air conditioning, and professional driver services. Additional amenities like audio systems and Wi-Fi may be available upon request, depending on the bus model."
        },
        {
          question: "How do I book a 50-seater bus with SSKTravels24?",
          answer: "Booking a 50-seater bus is simple. Contact us via phone, email, or through our online booking form. Provide details such as your travel dates, pick-up and drop-off locations, and any special requirements, and we’ll assist you with the reservation process."
        },
        {
          question: "Are there any additional charges I should be aware of?",
          answer: "Our pricing is transparent with no hidden fees. However, additional charges may apply for extra services such as extended travel hours or specific requests. We will provide a detailed quote before finalizing your booking."
        },
        {
          question: "What is the cancellation policy for a 50-seater bus rental?",
          answer: "Our cancellation policy depends on the timing of the cancellation. Generally, cancellations made well in advance may incur a nominal fee, while last-minute cancellations could involve higher charges. Please contact our customer service for specific details regarding your booking."
        },
        {
          question: "Can I request a specific type of 50-seater bus?",
          answer: "Yes, you can request specific features or types of buses based on availability. We offer a range of options to suit different needs, including luxury and standard models."
        },
        {
          question: "Are the drivers experienced and licensed?",
          answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are familiar with local routes and are dedicated to providing a safe and comfortable travel experience."
        },
        {
          question: "Can I make changes to my booking after it is confirmed?",
          answer: "Yes, changes to your booking can be made, subject to availability and any applicable fees. Please contact our customer service team as soon as possible to discuss any modifications to your travel plans."
        },
        {
          question: "What should I do if I need assistance during my journey?",
          answer: "If you require any assistance during your journey, contact the driver or our customer service team immediately. We are committed to addressing any concerns or issues promptly to ensure a smooth trip."
        },
        {
          question: "How far in advance should I book a 50-seater bus?",
          answer: "To secure your preferred bus and ensure availability, we recommend booking as early as possible. For peak seasons or special events, booking well in advance is advisable to avoid last-minute complications."
        },
        {
          question: "Are there any restrictions on luggage or other items?",
          answer: "Standard luggage allowances apply, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when making your booking so we can make the necessary arrangements."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Aditya Sharma",
          text: "We recently hired SSKTravels24 for our company’s annual corporate picnic, and the experience was fantastic! The 50-seater bus was spacious, clean, and perfectly maintained. Our driver, Mr. Raghav, was punctual and professional, ensuring a smooth ride for everyone. The air-conditioned comfort made the journey pleasant, even in the heat. I highly recommend SSKTravels24 for anyone needing reliable bus rental services in Pune!"
        },
        {
          name: "Miss Sneha Kulkarni",
          text: "I had the pleasure of using SSKTravels24 for my daughter’s wedding, needing a 50-seater bus to transport guests. The service exceeded our expectations! The bus was immaculate and had all the necessary amenities for comfort. Our driver, Ms. Priya, was friendly and made the journey seamless. The team was incredibly accommodating, and I’m thankful for their professionalism. I will definitely choose them again for future events."
        }
      ];
      
      const contactData = {
        heading: "Contact SSKTravels24 for 50-Seater Bus Rentals in Pune",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@SSKtravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 50 Seater Bus On Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent a 50 seater bus in Pune for corporate events, weddings, and outstation trips. Competitive rates for both AC and non-AC options. Book your bus today!" />
  <meta name="keywords" content="50 seater bus on rent in Pune | 50 seater bus per km rate in Pune | bus on rent in Pimpri Chinchwad | 50 seater bus on rent Hadapsar | 50 seater coach hire prices | 50 seater bus rent price | 50 seater coach bus hire in Pune | 50 seater bus hire in Kharadi | 50 seater AC bus per km rate | hire 50 seater bus on rent | 50 seater bus for hire for outstation from Pune | 50 seater bus on rent for outstation | 50 seater bus for wedding in Pune | 50 seater bus on rent for corporate picnic | 50 seater bus on rent for corporate event | 50 seater bus on rent in Viman Nagar | 50 seater bus on rent for Ashtavinayak Darshan | 50 seater bus on rent for Jyotirlinga Darshan" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 50 Seater Bus On Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for 50 seater buses in Pune. Enjoy competitive rates for weddings, corporate events, and more. Contact us to book your bus!" />
  <meta property="og:url" content="https://https://ssktravels24.com//50-seater-bus-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//50-seater-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-14.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default BusRentInPune50Seater;
import React from 'react';
import '../css/ServiceSection.css'; 
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics';
const services = [
  {
    id: 1,
    imgSrc: '/images/ssktravels___services/Image for Service-01.jpg',
    title: 'Force Urbaina On Rent',
    description: 'Experience vibrant urban travel with SSKTravels24. Every journey is exciting, and our exceptional service ensures seamless adventures.',
    link: '/book-taxi'
  },
  {
    id: 2,
    imgSrc: '/images/ssktravels___services/Image for Service-02.jpg',
    title: 'Tempo Traveller Booking',
    description: 'Travel in comfort and style with our Tempo Traveller booking, perfect for group adventures.',
    link: '/book-taxi'
  },
  {
    id: 3,
    imgSrc: '/images/ssktravels___services/Image for Service-05.jpg',
    title: 'Outstation Cab Services',
    description: 'Embark on your outstation journey with our premium car rentals crafted for comfort and convenience.',
    link: '/book-taxi'
  },
  {
    id: 4,
    imgSrc: '/images/ssktravels___services/Image for Service-03.jpg',
    title: 'Mini Bus on rent',
    description: 'Seamless airport transfers with professional drivers and premium vehicles ensuring timely service.',
    link: '/book-taxi'
  },
  {
    id: 5,
    imgSrc: '/images/ssktravels___services/Image for Service-04.jpg',
    title: 'Bus On Rent',
    description: 'Ultimate group travel with our Bus on Rent service, combining comfort, convenience, and safety for every journey.',
    link: '/book-taxi'
  },
  {
    id: 6,
    imgSrc: '/images/ssktravels___services/Image for Service-06.jpg',
    title: 'Temple Tours and Pilgrimages',
    description: 'Explore sacred sites with our comprehensive temple tours, enhancing your spiritual experience.',
    link: '/book-taxi'
  },
];

const ServiceSection = () => {
  return (
    <div>
      <GoogleAnalytics/>
      <Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
         <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Our Services </h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>

        <section className="service-section">
      <div className="container p-0">
        <div className="row g-0">
          {services.map((service) => (
            <div key={service.id} className="col-12 col-md-6 col-lg-4">
              <div className="service-card">
                <div className="service-image">
                  <img src={service.imgSrc} alt={service.title} className="img-fluid" />
                </div>
                <div className="service-content">
                  <h3>
                    <Link to={service.link}>{service.title}</Link>
                  </h3>
                  <p>{service.description}</p>
                  <Link className="btn" to={service.link} >Learn More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
  );
};

export default ServiceSection;

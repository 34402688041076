import React from 'react';
import '../css/OurNetwork.css';
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics';
const OurNetwork = () => {
  const cities = [
    {
      name: 'Pune',
      imgSrc: '/images/For mail/Our Network/Our network-01.jpg',
      description: 'Discover Pune, a city rich in history and culture. Visit landmarks like Shaniwar Wada and enjoy its vibrant markets, green spaces, and delicious food.',
    },
    {
      name: 'Mumbai',
      imgSrc: '/images/For mail/Our Network/Our network-03.jpg',
      description: 'Discover Mumbai, a city of dreams with landmarks like the Gateway of India. Enjoy vibrant street food and experience the energy of India’s entertainment and financial hub.',
    },
    {
      name: 'Pimpri chinchwad',
      imgSrc: '/images/For mail/Our Network/Our network-02.jpg',
      description: 'Explore Pimpri Chinchwad, a vibrant city near Pune known for its industries, bustling markets, green spaces, and rich local culture.',
    },
    {
      name: 'Navi Mumbai',
      imgSrc: '/images/For mail/Our Network/Our network-04.jpg',
      description: 'Discover Navi Mumbai, a modern planned city featuring beautiful parks, shopping areas, and a serene atmosphere, all with easy access to Mumbai.',
    },
    {
      name: 'Goa',
      imgSrc: '/images/For mail/Our Network/Our network-05.jpg',
      description: 'Discover Goa, a coastal paradise famous for its stunning beaches, vibrant nightlife, and rich culture. Enjoy water sports, delicious cuisine, and a relaxed atmosphere.',
    },
    {
      name: 'Kohlapur',
      imgSrc: '/images/For mail/Our Network/Our network-06.jpg',
      description: 'Discover Kolhapur, known for its rich history, cuisine, and the Mahalakshmi Temple. Explore historic forts and enjoy local delicacies like misal and pedha.',
    },
    {
      name: 'Nashik',
      imgSrc: '/images/For mail/Our Network/Our network-07.jpg',
      description: 'Discover Nashik, renowned for its vineyards, scenic landscapes, and rich cultural heritage. It is also famous for the Kumbh Mela and its sacred ghats along the Godavari River.',
    },
    {
      name: 'Shirdi',
      imgSrc: '/images/For mail/Our Network/Our network-08.jpg',
      description: 'Explore Shirdi, known for the sacred shrine of Sai Baba. This spiritual town attracts pilgrims seeking blessings in a serene atmosphere, complemented by vibrant markets and local cuisine.',
    },
    {
      name: 'Mumbai Airport',
      imgSrc: '/images/For mail/Our Network/Our network-09.jpg',
      description: 'Discover Mumbai Airport, a bustling hub known for its modern facilities and efficient services for international and domestic flights. Enjoy a range of dining and shopping options.',
    },
  ];

  return (
   <div>
    <GoogleAnalytics/>
<Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
<section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Our Network</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>


<section className="our-network px-0">
      <div className="container p-0">
        <div className="network-cards">
          {cities.map((city, index) => (
            <div className="network-card" key={index}>
              <div className="network-image-container">
                <img src={city.imgSrc} alt={city.name} className="network-image" />
              </div>
              <div className="network-content">
                <h3 className="network-name">{city.name}</h3>
                <p className="network-description">{city.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
   </div>
  );
};

export default OurNetwork;

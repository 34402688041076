
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function OnlineTaxiBookingPune(){


    const cardData = 
    {
        keyword: 'Online Taxi Booking Pune',
        heading: 'SSKTravels24: Online Taxi Booking Pune',
        headingDescription: 'Looking to explore Pune or nearby attractions? SSKTravels24 offers reliable online taxi booking services for all your travel needs, from city tours to airport transfers.',
        
        topPlaces: [
          {
            title: "1. Shaniwar Wada",
            description: "A historic fortification that once served as the seat of the Peshwas, Shaniwar Wada is a must-visit for history enthusiasts. Its impressive architecture and historical significance make it a top attraction."
          },
          {
            title: "2. Aga Khan Palace",
            description: "Known for its beautiful gardens and rich history, especially related to Mahatma Gandhi, Aga Khan Palace is ideal for leisurely strolls and photography."
          },
          {
            title: "3. Sinhagad Fort",
            description: "A popular trekking spot, Sinhagad Fort offers breathtaking views of the surrounding valleys. Our taxis can take you to the base, where you can start your hike."
          },
          {
            title: "4. Pune Okayama Friendship Garden",
            description: "Known for its lush greenery and Japanese-style landscaping, this garden is perfect for relaxation. Book a taxi to easily reach this peaceful retreat."
          },
          {
            title: "5. Osho Ashram",
            description: "This spiritual center attracts visitors from all over the world. You can explore meditation and wellness programs while enjoying the tranquil environment."
          },
          {
            title: "6. Raja Dinkar Kelkar Museum",
            description: "A treasure trove of artifacts showcasing India's rich cultural heritage, the museum is a fantastic place to learn about traditional arts and crafts."
          },
          {
            title: "7. Khadakwasla Dam",
            description: "Ideal for nature lovers, the dam offers stunning views and a peaceful atmosphere, perfect for a picnic or a day out."
          },
          {
            title: "8. Lonavala and Khandala",
            description: "Just a short drive from Pune, these hill stations are perfect for a weekend getaway. You can easily book a taxi for a scenic day trip."
          },
          {
            title: "9. Pune Zoo",
            description: "Located in the heart of the city, the Pune Zoo is home to various animal species and is a great spot for families and children."
          },
          {
            title: "10. Fergusson College Road",
            description: "Known for its vibrant atmosphere, this area is filled with cafes, shops, and bookstores, offering a glimpse into local culture and nightlife."
          }
        ],
        
        services: [
          {
              "name": "Mumbai to Pune Cab on Rent",
              "description": "Enjoy a hassle-free ride with our online cab booking service from Mumbai to Pune. We offer flexible timings, competitive rates, and well-maintained vehicles to ensure a comfortable journey. Whether you're traveling for business or leisure, our experienced drivers will navigate the busy roads, allowing you to relax and enjoy the views along the way."
          },
          {
              "name": "Pune to Shirdi Online Cab",
              "description": "Make your pilgrimage comfortable with our Pune to Shirdi cab booking online service. Our reliable and punctual drivers ensure you arrive at the holy city stress-free. With comfortable seating and ample luggage space, you can focus on your spiritual journey while we take care of the transportation."
          },
          {
              "name": "Pune to Mahabaleshwar Cab",
              "description": "Escape to the hills with ease! Our Pune to Mahabaleshwar online cab booking service guarantees a safe and comfortable trip. Enjoy the scenic beauty and fresh air as you travel in our well-equipped vehicles, perfect for family outings or romantic getaways. Our drivers are knowledgeable about the area, ensuring you get the best experience."
          },
          {
              "name": "Pune to Lonavala Taxi",
              "description": "A quick getaway to Lonavala is just a cab ride away! Use our online taxi booking service to plan your trip today. With comfortable rides and prompt service, you can enjoy the stunning landscapes and enjoy local attractions like the Bhushi Dam and Karla Caves without worrying about transportation."
          },
          {
              "name": "Pune to Bhimashankar Cab",
              "description": "Explore the scenic beauty of Bhimashankar with our dedicated online cab booking service. Known for its lush greenery and the famous Bhimashankar Temple, our cabs provide a comfortable and reliable way to experience this beautiful destination. Our drivers are experienced and familiar with the best routes to ensure a smooth journey."
          },
          {
              "name": "Pune to Kolhapur Bus Booking",
              "description": "Experience a smooth journey with our Pune to Kolhapur online cab booking option, perfect for business or leisure. Enjoy comfortable seating and a stress-free ride as you travel to one of Maharashtra's cultural hubs. Whether you're visiting for business or exploring local cuisine and attractions, we ensure a pleasant experience."
          },
          {
              "name": "Mumbai Airport to Pune Taxi",
              "description": "Arriving at Mumbai Airport? Our taxi online booking service ensures a seamless transfer to Pune. With professional drivers and well-maintained vehicles, we prioritize your comfort and punctuality. Enjoy a stress-free ride as we handle the traffic and logistics, getting you to your destination on time."
          },
          {
              "name": "Online Taxi Booking in Pimpri Chinchwad",
              "description": "We cater to Pimpri Chinchwad, making online taxi booking in this area a breeze. Our fleet of vehicles is ready to provide you with reliable transportation for any occasion. Whether you need a ride for a business meeting or a casual outing, our prompt service and professional drivers will ensure your journey is comfortable."
          },
          {
              "name": "Pune Airport Online Cab Booking",
              "description": "Our Pune Airport online cab booking service provides reliable transport to and from the airport. Enjoy convenient and timely pickups and drop-offs, with drivers who are well-acquainted with airport procedures. Avoid the stress of public transport and let us take care of your travel needs."
          },
          {
              "name": "Contact Number for Online Taxi Booking Pune",
              "description": "For prompt and efficient online taxi booking in Pune, contact SSKTravels24 at +91 8956170493. Our friendly customer service team is available to assist you with bookings, answer your queries, and ensure a smooth travel experience."
          }
      ],      
        tableData: [  
            ['Online taxi booking Pune', 'Online cab booking Pune'],
            ['Book cab from Mumbai to Pune', 'Cab booking app in Pune'],
            ['Online cabs in Pune', 'Online cab service in Pune'],
            ['Online cab booking Mumbai to Pune', 'Pune to Shirdi cab booking online'],
            ['Pune to Mahabaleshwar online cab booking', 'Pune to Lonavala online cab booking'],
            ['Pune to Bhimashankar online cab booking', 'Pune to Mumbai online cab booking'],
            ['Pune to Kolhapur online cab booking', 'Mumbai airport to Pune taxi online booking'],
            ['SSK Travels 24', 'Online taxi booking Pimpri Chinchwad'],
            ['Online cab booking in Pimpri Chinchwad', 'Pune Airport online cab booking']
          ],
          
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for Online Taxi Booking in Pune - SSKTravels24?",
              WhyChoosedescription: "At SSKTravels24, we prioritize your comfort and convenience, making your online taxi booking experience seamless and enjoyable. Here’s why you should choose us:"
            },
            {
              WhyChooseheading: "User-Friendly Platform:",
              WhyChoosedescription: "Our online taxi booking system is designed for ease of use. Whether you’re using our website or mobile app, booking a ride is quick and hassle-free."
            },
            {
              WhyChooseheading: "Wide Range of Services:",
              WhyChoosedescription: "From local rides to intercity travel, we offer a comprehensive range of services, including airport transfers, corporate travel, and weekend getaways."
            },
            {
              WhyChooseheading: "Affordable Pricing:",
              WhyChoosedescription: "SSKTravels24 provides transparent and competitive pricing with no hidden fees, ensuring you get great value for your money."
            },
            {
              WhyChooseheading: "Reliable Fleet:",
              WhyChoosedescription: "Our fleet consists of well-maintained vehicles of various types, including sedans, SUVs, and luxury cars, catering to all your travel needs."
            },
            {
              WhyChooseheading: "Experienced Drivers:",
              WhyChoosedescription: "Our professional drivers are highly trained, courteous, and familiar with the best routes, guaranteeing a safe and comfortable journey."
            },
            {
              WhyChooseheading: "24/7 Availability:",
              WhyChoosedescription: "We are available around the clock, allowing you to book your ride anytime, day or night, for immediate or scheduled travel."
            },
            {
              WhyChooseheading: "Real-Time Tracking:",
              WhyChoosedescription: "Our real-time tracking feature allows you to monitor your cab’s location, giving you peace of mind while you wait."
            },
            {
              WhyChooseheading: "Excellent Customer Support:",
              WhyChoosedescription: "Our dedicated customer service team is always ready to assist you with any queries or concerns, ensuring a smooth travel experience."
            },
            {
              WhyChooseheading: "Safety First:",
              WhyChoosedescription: "We prioritize passenger safety with rigorous hygiene protocols, regular vehicle maintenance, and adherence to safety guidelines."
            },
            {
              WhyChooseheading: "Positive Customer Feedback:",
              WhyChoosedescription: "We have built a reputation for reliability and quality service, as evidenced by numerous positive customer testimonials."
            }
          ]
    };    

    
    const faqData = [
        {
          question: "How do I book a taxi with SSKTravels24?",
          answer: "You can book a taxi easily through our website or mobile app by entering your pickup location, destination, and preferred vehicle type."
        },
        {
          question: "What types of vehicles are available for booking?",
          answer: "We offer a range of vehicles including sedans, SUVs, and luxury cars, catering to different group sizes and travel preferences."
        },
        {
          question: "Is there a cancellation policy?",
          answer: "Yes, we have a flexible cancellation policy. Please refer to our website for detailed information on cancellation terms."
        },
        {
          question: "How is the pricing structured?",
          answer: "Our pricing is transparent and competitive, with no hidden charges. You’ll see the total fare before confirming your booking."
        },
        {
          question: "Are your drivers experienced?",
          answer: "Yes, all our drivers are professional, licensed, and experienced in navigating Pune and surrounding areas."
        },
        {
          question: "Can I book a cab for a specific date and time?",
          answer: "Absolutely! You can schedule your ride in advance by selecting your preferred date and time during the booking process."
        },
        {
          question: "Do you provide services for long-distance travel?",
          answer: "Yes, we offer intercity cab services, including trips to popular destinations like Mumbai, Lonavala, and Mahabaleshwar."
        },
        {
          question: "What safety measures are in place during the pandemic?",
          answer: "We follow strict hygiene protocols, including regular sanitization of vehicles and health checks for our drivers."
        },
        {
          question: "How can I pay for my ride?",
          answer: "We accept multiple payment methods, including cash, credit/debit cards, and digital wallets for your convenience."
        },
        {
          question: "Is customer support available if I encounter issues?",
          answer: "Yes, our customer support team is available 24/7 to assist you with any issues or queries regarding your booking."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Rahul Deshmukh",
          text: "I recently used SSKTravels24 for my airport transfer, and I couldn't be more satisfied with the service. The booking process was straightforward, and the cab arrived right on time. My driver was professional and friendly, making the trip to the airport smooth and stress-free. I will definitely choose SSKTravels24 for my future travel needs!"
        },
        {
          name: "Ms. Priya Sharma",
          text: "SSKTravels24 has been my go-to service for all my taxi bookings in Pune. The app is user-friendly, and I appreciate the transparent pricing with no hidden charges. The drivers are always punctual and courteous. I recently booked a ride for a corporate event, and everything went perfectly. Highly recommend their services!"
        }
      ];
      
      const contactData = {
        heading: "Contact SSKTravels24 for Online Taxi Booking in Pune",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Online Taxi Booking in Pune | Easy & Affordable</title>
  <meta name="description" content="Book your taxi online in Pune with SSK Travels24. Convenient, affordable, and reliable cab services for all your travel needs." />
  <meta name="keywords" content="online taxi booking Pune, online cab booking Pune, cab booking app Pune, Pune to Mumbai online cab, Pune to Shirdi online cab" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Online Taxi Booking in Pune | Easy & Affordable" />
  <meta property="og:description" content="Explore convenient online taxi booking in Pune with SSK Travels24. Book cabs for local and outstation travel effortlessly." />
  <meta property="og:url" content="https://https://ssktravels24.com//online-taxi-booking-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//online-taxi-booking-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-15.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default OnlineTaxiBookingPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function ForceUrbaniaonRentforAshtavinayakDarshan(){


    const cardData = 
    {
        keyword: 'Force Urbania on Rent for Ashtavinayak Darshan',
        heading: 'SSKTravels24: Force Urbania on Rent for Ashtavinayak Darshan',
        headingDescription: 'Embark on a spiritual journey to the Ashtavinayak temples with SSKTravels24. Our comfortable Force Urbania rentals ensure a memorable pilgrimage, accommodating all your travel needs.',
        
        topPlaces: [
            {
                title: "1. Moreshwar Temple (Morgaon)",
                description: "Considered the first and most important of the Ashtavinayak temples, known for its unique idol of Ganesh."
            },
            {
                title: "2. Siddhivinayak Temple (Siddhatek)",
                description: "Famous for its idol with a distinctive curved trunk, believed to have significant spiritual powers."
            },
            {
                title: "3. Ballaleshwar Temple (Pali)",
                description: "Renowned for its deity that protects devotees, uniquely representing Ganesh with an elephant-like trunk."
            },
            {
                title: "4. Varadavinayak Temple (Mahad)",
                description: "Considered a wish-fulfilling deity, this temple's idol is adorned with precious stones."
            },
            {
                title: "5. Chintamani Temple (Theur)",
                description: "Significant for relieving devotees from stress, with the deity believed to grant peace and satisfaction."
            },
            {
                title: "6. Girijatmaj Temple (Lonavala)",
                description: "Famous for its natural cave setting and one of the oldest statues of Ganesh in the Ashtavinayak series."
            },
            {
                title: "7. Vighneshwar Temple (Ozar)",
                description: "Dedicated to Ganesh as the remover of obstacles, revered for its spiritual energy."
            },
            {
                title: "8. Ranjangaon Temple (Ranjangaon)",
                description: "Known for its powerful deity that removes troubles and grants success in endeavors."
            }
        ],
        
        services: [
            {
                name: "Pune to Ashtavinayak Urbania Tour Package",
                description: "Enjoy a comfortable journey to the eight revered Ganesh temples with our customizable tour packages."
            },
            {
                name: "Affordable Ashtavinayak Tour from Pune",
                description: "Experience economical pilgrimage solutions with competitive pricing for Force Urbania rentals."
            },
            {
                name: "13-Seater Urbania on Rent for Ashtavinayak Tour",
                description: "Perfect for smaller groups or families seeking a comfortable pilgrimage experience."
            },
            {
                name: "17-Seater Urbania on Rent for Ashtavinayak Tour",
                description: "Ideal for slightly larger groups, featuring luxurious interiors and advanced amenities."
            },
            {
                name: "Urbania on Rent for Pune to Ranjangaon Ganpati Darshan",
                description: "Experience a divine journey to the Ranjangaon Ganpati temple with our comfortable Urbania rentals."
            },
            {
                name: "Contact Number for Force Urbania on Rent",
                description: "For prompt and efficient Force Urbania on Rent for Ashtavinayak Darshan, contact SSKTravels at +91 8956170493."
            }
        ],
        tableData: [
            ['- Pune to Ashtavinayak Urbania Tour Package from Pune', '- Cheapest Ashtavinayak Tour from Pune'],
            ['- 13 Seater Urbania on Rent for Ashtavinayak Tour from Pune', '- 17 Seater Urbania on Rent for Ashtavinayak Tour from Pune'],
            ['- Urbania on Rent for Pune to Ranjangaon Ganpati Darshan', '- Pune to Siddhivinayak Temple Force Urbania on Rent'],
            ['- Force Urbania on Rent for Pune to Moreshwar Temple at Morgaon Ashtavinayak Darshan', '- Pune to Siddhivinayak Temple Siddhatek Force Urbania on Rent'],
            ['- Force Urbania on Rent for Ashtavinayak Darshan from Pimpri Chinchwad', '- Pune to Ballaleshwar Temple Pali Urbania Bus On Rent'],
            ['- Pune to Varad Vinayak Temple Mahad Ashtavinayak Darshan', '- Pune to Chintamani Temple Theur Ashtavinayak Darshan Urbania on Rent'],
            ['- Pune to Girijatmaj Temple Lenyadri Urbania on Rent', '- Pune to Vighnahar Temple Ozar Ganpati Ashtavinayak Darshan']
        ],
    
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for Force Urbania on Rent for Ashtavinayak Darshan",
                "WhyChoosedescription": "SSKTravels24 stands out as the premier choice for renting a Force Urbania for your Ashtavinayak Darshan, dedicated to providing exceptional travel experiences. Here’s why you should choose us:"
            },
            {
                "WhyChooseheading": "Comfort and Luxury:",
                "WhyChoosedescription": "Our Force Urbania buses are designed for maximum comfort with spacious seating, air-conditioning, and modern amenities, ensuring a relaxed journey to all Ashtavinayak temples."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our drivers are experienced and well-versed in the routes to various Ashtavinayak temples, guaranteeing a smooth and safe journey."
            },
            {
                "WhyChooseheading": "Reliable Service:",
                "WhyChoosedescription": "We pride ourselves on punctuality and reliability. Our buses are meticulously maintained to ensure they are always in top condition, so you can count on us for a hassle-free trip."
            },
            {
                "WhyChooseheading": "Customizable Packages:",
                "WhyChoosedescription": "We offer flexible rental packages tailored to your needs, whether you're planning a day trip or an extended pilgrimage, ensuring that you get the best value for your journey."
            },
            {
                "WhyChooseheading": "Top-Notch Safety:",
                "WhyChoosedescription": "Your safety is our top priority. Our Force Urbania buses are equipped with modern safety features including GPS tracking, seat belts, and emergency equipment."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "We offer transparent and competitive pricing with no hidden charges. Our rates are designed to provide you with excellent value without compromising on quality."
            },
            {
                "WhyChooseheading": "Well-Maintained Vehicles:",
                "WhyChoosedescription": "Our Urbania buses are regularly serviced and cleaned to maintain high standards of hygiene and comfort, making your journey pleasant and enjoyable."
            },
            {
                "WhyChooseheading": "Easy Booking Process:",
                "WhyChoosedescription": "We provide a simple and straightforward booking process. You can easily book your Urbania online or through our customer service team, ensuring a seamless experience."
            },
            {
                "WhyChooseheading": "Customer Support:",
                "WhyChoosedescription": "Our dedicated customer support team is available to assist you with any queries or special requests, providing you with a personalized experience from start to finish."
            },
            {
                "WhyChooseheading": "Positive Reviews:",
                "WhyChoosedescription": "We have a track record of satisfied customers who have enjoyed our service. Our positive reviews reflect our commitment to excellence and customer satisfaction."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What is the seating capacity of the Force Urbania buses?",
            answer: "Our Force Urbania buses come in various capacities including 13, 17, and 20-seaters. Please specify your group size, and we will provide the appropriate bus for your needs."
        },
        {
            question: "Are the Force Urbania buses equipped with air-conditioning?",
            answer: "Yes, all our Force Urbania buses are fully air-conditioned to ensure a comfortable journey regardless of the weather conditions."
        },
        {
            question: "Can I book the Urbania for a one-day trip?",
            answer: "Absolutely! We offer flexible rental options, including one-day trips. You can choose a package that best suits your itinerary for the Ashtavinayak Darshan."
        },
        {
            question: "How do I book a Force Urbania for Ashtavinayak Darshan?",
            answer: "Booking is simple. You can either visit our website or contact our customer service team to make a reservation. We will guide you through the process and confirm your booking promptly."
        },
        {
            question: "What safety features are included in the Force Urbania?",
            answer: "Our Force Urbania buses are equipped with safety features such as seat belts, emergency exits, fire extinguishers, and GPS tracking for real-time monitoring."
        },
        {
            question: "Are there any additional charges for the Urbania rental?",
            answer: "We offer transparent pricing with no hidden charges. The cost of your rental will be provided upfront, and any additional fees will be clearly communicated before booking."
        },
        {
            question: "Can I request special amenities on the bus?",
            answer: "Yes, if you have specific requirements or requests, please let us know at the time of booking. We will do our best to accommodate your needs."
        },
        {
            question: "What is the cancellation policy for the Urbania rental?",
            answer: "Our cancellation policy varies depending on the booking terms. Please review our terms and conditions or contact our customer service team for details on cancellations."
        },
        {
            question: "Are the drivers experienced and familiar with the Ashtavinayak routes?",
            answer: "Yes, our drivers are experienced and knowledgeable about the routes to the Ashtavinayak temples, ensuring a smooth and safe journey."
        },
        {
            question: "How can I contact SSKTravels24 for further assistance?",
            answer: "You can reach out to us via our website, email, or phone. Our customer support team is available to assist you with any questions or concerns regarding your Urbania rental."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Ravi Deshmukh",
            text: "We recently hired a Force Urbania from SSKTravels24 for our Ashtavinayak Darshan trip, and it was an outstanding experience. The bus was luxurious, clean, and incredibly comfortable. The air-conditioning made our journey pleasant even in the hot weather, and the reclining seats provided ample relaxation. Our driver, Mr. Shinde, was punctual and knowledgeable, ensuring we visited all the temples smoothly and safely. SSKTravels24 made our pilgrimage stress-free and enjoyable. Highly recommend their services!"
        },
        {
            name: "Miss Snehal Patel",
            text: "Our family trip to the Ashtavinayak temples was made perfect thanks to SSKTravels24. We opted for their Force Urbania on rent, and it exceeded our expectations. The bus was spacious, with plenty of room for everyone, and the amenities were top-notch. The booking process was straightforward, and the customer support team was helpful and responsive. The driver, Miss Anjali, was excellent, ensuring a smooth and safe journey. We couldn’t have asked for a better service. Thank you, SSKTravels24, for making our spiritual journey memorable!"
        }
    ];
    
    const contactData = {
        heading: "Force Urbania On Rent for Ashtavinayak Darshan",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Force Urbania On Rent for Ashtavinayak Darshan | Affordable Rental Services</title>
  <meta name="description" content="Book Force Urbania for Ashtavinayak Darshan from Pune. Enjoy comfortable and affordable travel to all Ganpati temples." />
  <meta name="keywords" content="Pune to Ashtavinayak Urbania tour package from Pune | Cheapest Ashtavinayak tour from Pune | 13 seater Urbania on rent for Ashtavinayak tour from Pune | 17 seater Urbania on rent for Ashtavinayak tour from Pune | Urbania on rent for Pune to Ranjangaon Ganpati darshan | Pune to Siddhivinayak Temple Force Urbania on rent | Force Urbania on rent for Pune to Moreshwar Temple at Morgaon Ashtavinayak Darshan | Pune to Siddhivinayak Temple Siddhatek Force Urbania on rent | Force Urbania on rent for Ashtavinayak darshan from Pimpri Chinchwad | Pune to Ballaleshwar Temple Pali Urbania bus on rent | Pune to Varad Vinayak Temple Mahad Ashtavinayak Darshan | Pune to Chintamani Temple Theur Ashtavinayak Darshan Urbania on rent | Pune to Girijatmaj Temple Lenyadri Urbania on rent | Pune to Vighnahar Temple Ozar Ganpati Ashtavinayak Darshan" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Force Urbania On Rent for Ashtavinayak Darshan | Affordable Rental Services" />
  <meta property="og:description" content="Experience comfortable travel with Force Urbania for Ashtavinayak Darshan from Pune. Book your journey today!" />
  <meta property="og:url" content="https://https://ssktravels24.com//force-urbania-ashtavinayak-darshan" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//force-urbania-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\ashta-force.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default ForceUrbaniaonRentforAshtavinayakDarshan;
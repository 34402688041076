
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function OneWayCabinPune(){


    const cardData = 
    {
        keyword: 'One Way Cab in Pune',
        heading: 'SSKTravels24: One Way Cab Services in Pune',
        headingDescription: 'Experience the convenience and comfort of one-way cab services with SSKTravels24. Whether you’re exploring Pune’s historical sites or traveling to nearby destinations, we ensure a hassle-free journey tailored to your needs.',
      
        topPlaces: [
          {
            title: "1. Shaniwar Wada",
            description: "A historical fortification once the seat of the Peshwas of the Maratha Empire, known for its impressive architecture and beautiful gardens."
          },
          {
            title: "2. Aga Khan Palace",
            description: "A majestic palace of significant historical importance, used as a prison during the Quit India Movement, surrounded by lush gardens."
          },
          {
            title: "3. Sinhagad Fort",
            description: "Located a short drive from Pune, offering breathtaking views and a popular trekking spot with rich Maratha history."
          },
          {
            title: "4. Osho Ashram",
            description: "A tranquil space in Koregaon Park for meditation and wellness, featuring serene gardens and various meditation programs."
          },
          {
            title: "5. Pataleshwar Cave Temple",
            description: "An ancient rock-cut temple dedicated to Lord Shiva, showcasing intricate carvings and a serene atmosphere."
          },
          {
            title: "6. Khadakwasla Dam",
            description: "A scenic spot perfect for picnics with beautiful landscapes and a backdrop of hills, ideal for a day trip."
          },
          {
            title: "7. Pune Okayama Friendship Garden",
            description: "Inspired by Japanese gardens, this beautifully landscaped area is perfect for leisurely strolls and enjoying nature."
          },
          {
            title: "8. Shivneri Fort",
            description: "The birthplace of Chhatrapati Shivaji Maharaj, offering insight into Maratha history and panoramic views of surrounding hills."
          },
          {
            title: "9. Lonavala",
            description: "A popular hill station known for lush greenery, waterfalls, and pleasant weather, ideal for weekend getaways."
          },
          {
            title: "10. Mahabaleshwar",
            description: "Renowned for its stunning viewpoints and strawberry farms, perfect for those looking to escape the city."
          }
        ],
      
        services: [
          {
            name: "Pune to Shirdi Cab One Way",
            description: "Experience a hassle-free ride to the sacred city of Shirdi, famous for the Sai Baba Temple, with direct transportation."
          },
          {
            name: "One Way Cab Pune to Mumbai",
            description: "Enjoy spacious seating and a smooth ride with our one way cab from Pune to Mumbai, tailored for your comfort."
          },
          {
            name: "One Way Cab Pune",
            description: "Offering various one-way options within Pune for easy and convenient transportation across the city."
          },
          {
            name: "One Way Cab Mumbai to Pune",
            description: "Ensures a seamless journey for travelers heading back to Pune from Mumbai, with competitive pricing."
          },
          {
            name: "Pune to Aurangabad Cab One Way",
            description: "Visit the historical sites of Aurangabad with our comfortable and dedicated one way cab service."
          },
          {
            name: "One Way Cab from Pune to Goa",
            description: "Make your getaway to Goa effortless with our comfortable one way cab service."
          },
          {
            name: "One Way Cab from Pune to Kolhapur",
            description: "Travel to Kolhapur effortlessly with our dedicated one way cab service designed for comfort and convenience."
          },
          {
            name: "One Way Cab Pune to Ahmednagar",
            description: "Plan your trip to Ahmednagar with ease using our reliable one way cab service."
          },
          {
            name: "Contact Number for One Way Cab in Pune",
            description: "For prompt assistance with one way cab services, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['Pune to Shirdi Cab One Way', 'One Way Cab Pune to Mumbai'],
            ['One Way Cab Pune', 'One Way Cab Mumbai to Pune'],
            ['Mumbai Pune Cab One Way', 'Pune to Mumbai Taxi One Way'],
            ['Pune to Aurangabad Cab One Way', 'Pune Mumbai Cab One Way'],
            ['Goa to Pune One Way Cab', 'One Way Taxi Mumbai to Pune'],
            ['One Way Taxi from Shirdi to Pune', 'One Way Cab from Pune to Aurangabad'],
            ['One Way Cab from Pune to Goa', 'One Way Cab from Pune to Kolhapur'],
            ['One Way Cab from Pune to Mahabaleshwar', 'One Way Cab Pune to Ahmednagar'],
            ['One Way Cab Pune to Aurangabad', 'One Way Car Rental Pune'],
            ['Pune to Aurangabad One Way Taxi', 'Pune to Kolhapur One Way Cab'],
            ['Pune to Goa Taxi Fare One Way', 'Pune to Solapur One Way Cab'],
            ['Pune to Shirdi Cab Two Way', 'Pune to Thane One Way Cab']
          ],
          
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for One Way Cab in Pune – SSKTravels24?",
              WhyChoosedescription: "At SSKTravels24, we prioritize your convenience and comfort. Our one way cab services in Pune are designed to provide a seamless travel experience. Here’s why you should choose us:"
            },
            {
              WhyChooseheading: "Flexible Booking Options:",
              WhyChoosedescription: "Enjoy the ease of online booking with our user-friendly platform. Schedule your ride at your convenience without any hassle."
            },
            {
              WhyChooseheading: "Competitive Pricing:",
              WhyChoosedescription: "We offer transparent pricing with no hidden charges. Get the best value for your journey without compromising on quality."
            },
            {
              WhyChooseheading: "Wide Range of Vehicles:",
              WhyChoosedescription: "Choose from a variety of well-maintained vehicles to suit your needs, whether you’re traveling alone or with family."
            },
            {
              WhyChooseheading: "Experienced Drivers:",
              WhyChoosedescription: "Our professional drivers are knowledgeable about the routes and committed to ensuring a safe and comfortable journey."
            },
            {
              WhyChooseheading: "24/7 Customer Support:",
              WhyChoosedescription: "Our dedicated customer service team is available around the clock to assist you with any inquiries or concerns."
            },
            {
              WhyChooseheading: "Punctuality:",
              WhyChoosedescription: "We value your time. Our cabs arrive on schedule, ensuring you reach your destination without delay."
            },
            {
              WhyChooseheading: "Safety and Hygiene:",
              WhyChoosedescription: "Your safety is our priority. Our vehicles undergo regular maintenance, and we follow strict hygiene protocols."
            },
            {
              WhyChooseheading: "User Reviews:",
              WhyChoosedescription: "Our satisfied customers speak volumes about our service quality. Check out testimonials to see why we’re a preferred choice for one way travel in Pune."
            }
          ]
    };    

    const faqData = [
        {
          question: "What is a one way cab service?",
          answer: "A one way cab service allows you to book a cab for a single journey to your destination without needing to plan a return trip."
        },
        {
          question: "How do I book a one way cab in Pune?",
          answer: "You can easily book a one way cab through our website or mobile app. Select your pickup and drop-off locations, choose your vehicle, and confirm your booking."
        },
        {
          question: "What types of vehicles are available for one way cab bookings?",
          answer: "We offer a range of vehicles, including sedans, SUVs, and luxury cars, to meet your specific travel requirements."
        },
        {
          question: "Are there any hidden charges?",
          answer: "No, we believe in transparent pricing. The fare you see at the time of booking is what you pay, with no hidden fees."
        },
        {
          question: "Can I change my booking after confirming it?",
          answer: "Yes, you can modify your booking details. Please contact our customer support for assistance with any changes."
        },
        {
          question: "What if my flight or train is delayed?",
          answer: "Our drivers are trained to track your flight or train status, ensuring they arrive at the right time to pick you up."
        },
        {
          question: "Is your service available at all times?",
          answer: "Yes, our one way cab service operates 24/7, allowing you to book rides at any time of day or night."
        },
        {
          question: "How do I cancel my booking?",
          answer: "Cancellations can be made through our website or by contacting customer support. Please check our cancellation policy for details."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Anil Patil",
          text: "I recently booked a one way cab from Pune to Mumbai with SSKTravels24, and it was an excellent experience. The driver was punctual, friendly, and the car was clean and comfortable. The whole booking process was straightforward, and the fare was reasonable. I will definitely choose SSKTravels24 again for my future travels!"
        },
        {
          name: "Miss Priya Sharma",
          text: "I had to travel from Pune to Aurangabad for a family function, and I opted for SSKTravels24’s one way cab service. The ride was smooth, and the driver was very professional. I appreciate the timely service and the transparency in pricing. Highly recommend this service for anyone looking for a hassle-free travel experience!"
        }
      ];
      
      const contactData = {
        heading: "Contact SSKTravels24 for One Way Cab in Pune",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | One Way Cab Services in Pune | Affordable Taxi Booking</title>
  <meta name="description" content="Book one way cab services in Pune for your travel needs. Affordable and reliable one way cabs to Shirdi, Mumbai, Aurangabad, Goa, and more." />
  <meta name="keywords" content="Pune to Shirdi cab one way, one way cab Pune to Mumbai, one way cab Pune, one way cab Mumbai to Pune, Pune to Aurangabad cab one way, one way taxi from Shirdi to Pune, one way cab from Pune to Goa" />
  <meta property="og:title" content="One Way Cab Services | SSK Travels24" />
  <meta property="og:description" content="Affordable one way cab services from Pune to various destinations like Shirdi, Mumbai, Aurangabad, and Goa. Book now!" />
  <meta property="og:url" content="https://https://ssktravels24.com//one-way-cab-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//one-way-cab-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-24.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default OneWayCabinPune ;
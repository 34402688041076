
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function BusRentInPune32Seater(){


    const cardData = 
    {
       keyword : '32 Seater Bus Rent in Pune',
        heading : 'SSKTravels24: 32 Seater Bus Rent in Pune',
         headingDescription : 'Planning group travel in Pune? SSKTravels24 offers spacious, well-maintained 32-seater buses perfect for corporate events, family gatherings, and weekend getaways. Discover the top features and services we provide for your group travel needs.',
        
        topPlaces : [
            {
                title: "1. Customizable Travel Packages",
                description: "We provide customizable travel packages tailored to your itinerary and budget, whether you need the bus for a day, a week, or a special event."
            },
            {
                title: "2. Affordable Pricing",
                description: "Our competitive pricing for 32-seater bus rental services ensures excellent value for your travel needs, with no hidden costs."
            },
            {
                title: "3. Ample Luggage Space",
                description: "Our buses are designed with spacious compartments to accommodate all passengers’ belongings comfortably."
            },
            {
                title: "4. Spacious Seating",
                description: "Each 32-seater bus provides generous space and ergonomically designed seats for a comfortable travel experience."
            },
            {
                title: "5. Air-Conditioned Comfort",
                description: "Stay cool with our fully air-conditioned buses, ensuring a pleasant environment regardless of the weather."
            },
            {
                title: "6. Reclining Seats",
                description: "Our buses feature reclining seats that allow passengers to adjust their seating for a more relaxed journey."
            },
            {
                title: "7. Entertainment System",
                description: "Buses come equipped with LED TVs and sound systems to keep everyone engaged during long rides."
            },
            {
                title: "8. Safety Features",
                description: "Safety is a top priority, with modern safety measures like seat belts, emergency exits, and GPS tracking."
            },
            {
                title: "9. Experienced Drivers",
                description: "Our professional drivers are well-acquainted with local and outstation routes, ensuring a smooth journey."
            },
            {
                title: "10. Well-Maintained Buses",
                description: "Each bus undergoes regular inspections to ensure cleanliness and top-notch performance."
            }
        ],
        
         services : [
            {
                name: "32 Seater Bus on Rent for Corporate Events",
                description: "Ensure punctual and professional transportation for your team outings, allowing them to travel comfortably and arrive on time."
            },
            {
                name: "32 Seater Bus on Hire for Weddings in Pune",
                description: "Provide reliable transportation for your wedding guests with our spacious interiors and luxurious comfort."
            },
            {
                name: "32 Seater AC and Non-AC Bus Options",
                description: "Choose from air-conditioned or budget-friendly non-AC options, both providing ample space for luggage and passengers."
            },
            {
                name: "32 Seater Bus Hire for Religious Tours",
                description: "Visit sacred sites comfortably, whether it's the Ashtavinayak Darshan or the 5 Jyotirlinga tour."
            },
            {
                name: "32 Seater Bus on Rent in Pimpri Chinchwad",
                description: "Experience excellent customer service and experienced drivers for all your group travel needs in Pimpri Chinchwad."
            },
            {
                name: "32 Seater Bus for Outstation Trips",
                description: "Perfect for destinations like Mahabaleshwar, Shirdi, and Lonavala, ensuring safety and comfort throughout your journey."
            },
            {
                name: "32 Seater Bus Price in Pune",
                description: "Contact us for personalized quotes based on your travel needs, ensuring competitive and transparent pricing."
            },
            {
                name: "Contact Number for 32 Seater Bus Rent in Pune",
                description: "For prompt and efficient 32 Seater Bus Rent in Pune, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['32 Seater Bus Rent in Pune', '32 Seater Bus on Rent in Pune'],
            ['32 Seater Bus Price in Pune', '32 Seater Bus On Hire'],
            ['32 Seater Bus on Hire for Wedding in Pune', '32 Seater Bus on Rent for Marriage'],
            ['32 Seater Bus on Rent for Corporate Events', '32 Seater Bus on Rent for Mahabaleshwar'],
            ['32 Seater AC Bus on Hire', '32 Seater Non-AC Bus on Hire'],
            ['32 Seater Bus Hire for Ashtavinayak Darshan', '32 Seater Bus Hire for 5 Jyotirlinga Darshan'],
            ['Pune to Konkan Darshan 32 Seater Bus on Rent', '32 Seater Bus on Rent in Hadapsar'],
            ['32 Seater Bus on Rent in Pimpri Chinchwad', 'Pune to Bhimashankar 32 Seater Bus Hire'],
            ['Pune to Ashtavinayak 32 Seater Bus on Rent', '32 Seater Bus on Rent in Kothrud'],
            ['32 Seater Bus for Corporate Office', '32 Seater Bus for Outstation from Pune'],
            ['32 Seater Bus Rental Services in Pune', '']
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for 32-Seater Bus Rental in Pune?",
                "WhyChoosedescription": "When it comes to organizing group travel in Pune, SSKTravels24 is your premier choice for 32-seater bus rentals. Our commitment to comfort, reliability, and customer satisfaction ensures that your travel experience is both enjoyable and hassle-free. Here’s what sets our service apart and makes us the best option for your transportation needs:"
            },
            {
                "WhyChooseheading": "Spacious and Comfortable Buses",
                "WhyChoosedescription": "Our 32-seater buses are crafted for maximum comfort. With generous legroom, plush seating, and effective air-conditioning, your group will enjoy a relaxing journey, whether you're exploring Pune or traveling longer distances."
            },
            {
                "WhyChooseheading": "Professional and Experienced Drivers",
                "WhyChoosedescription": "Your safety is paramount. Our highly experienced drivers are trained to ensure a smooth and secure travel experience. They know local routes and traffic patterns, making your journey both efficient and pleasant."
            },
            {
                "WhyChooseheading": "Well-Maintained Fleet",
                "WhyChoosedescription": "We take pride in our meticulously maintained fleet. Our 32-seater buses undergo regular servicing and rigorous inspections, ensuring they are in top-notch condition and minimizing the risk of breakdowns during your travels."
            },
            {
                "WhyChooseheading": "Affordable Pricing",
                "WhyChoosedescription": "We believe quality transportation should be accessible. Our competitive pricing for 32-seater bus rentals ensures you get great value without compromising on quality. Plus, our transparent pricing structure means no hidden fees—what you see is what you get."
            },
            {
                "WhyChooseheading": "Customizable Travel Solutions",
                "WhyChoosedescription": "Every group has unique needs. Whether it’s a corporate event, family gathering, school trip, or any other group travel requirement, we offer customizable solutions tailored specifically to your preferences, ensuring a seamless experience."
            },
            {
                "WhyChooseheading": "Excellent Customer Service",
                "WhyChoosedescription": "Our customer service team is here for you from booking to the end of your journey. We prioritize your needs and are committed to providing prompt, effective support, ensuring your travel experience is hassle-free."
            },
            {
                "WhyChooseheading": "Punctual and Reliable",
                "WhyChoosedescription": "Time is crucial, especially in group travel. Our buses adhere to strict schedules for timely departures and arrivals, helping you stay on track with your plans and ensuring a smooth experience."
            }
        ]
    };    

    
    const faqData = [
        {
            question: "What is included in the rental of a 32-seater bus?",
            answer: "Our rental includes comfortable seating, air-conditioning, and professional driver services. Depending on the bus model, additional amenities like audio systems and Wi-Fi may also be available upon request."
        },
        {
            question: "How do I book a 32-seater bus with SSKTravels24?",
            answer: "Booking is easy! Contact us via phone or email, or fill out our online form. Just provide your travel details, including dates, pick-up and drop-off locations, and any special requests."
        },
        {
            question: "Are there any additional charges I should be aware of?",
            answer: "Our pricing is transparent, with no hidden fees. However, additional charges may apply for extra services like extended travel hours or special requests, and we’ll provide a detailed quote before finalizing your booking."
        },
        {
            question: "What is the cancellation policy for a 32-seater bus rental?",
            answer: "Our cancellation policy varies based on the timing of your cancellation. Typically, early cancellations incur a nominal fee, while last-minute cancellations may involve higher charges. Contact customer service for specific details."
        },
        {
            question: "Can I request a specific type of 32-seater bus?",
            answer: "Yes, you can request specific features or types of buses based on availability. We offer various options, including luxury and standard models, to meet your group’s needs."
        },
        {
            question: "Are the drivers experienced and licensed?",
            answer: "Absolutely! All our drivers are professionally trained, experienced, and hold valid licenses. They are dedicated to providing a safe and comfortable travel experience."
        },
        {
            question: "Can I make changes to my booking after it is confirmed?",
            answer: "Yes, you can make changes to your booking, subject to availability and applicable fees. Please reach out to our customer service team as soon as possible for any modifications."
        },
        {
            question: "What should I do if I need assistance during my journey?",
            answer: "If you need assistance, please contact the driver or our customer service team immediately. We are committed to resolving any concerns swiftly to ensure a smooth trip."
        },
        {
            question: "How far in advance should I book a 32-seater bus?",
            answer: "To secure your preferred bus and ensure availability, we recommend booking as early as possible. During peak seasons or special events, booking well in advance is advisable to avoid last-minute complications."
        },
        {
            question: "Are there any restrictions on luggage or other items?",
            answer: "We have standard luggage allowances, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when booking, and we’ll make the necessary arrangements."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Suresh Patil",
            text: "I recently rented a 32-seater bus from SSK Travels for a family wedding in Pune, and it was a fantastic experience! The bus was incredibly comfortable with generous seating and efficient air-conditioning. Our driver was punctual and knowledgeable about the routes, ensuring our journey was smooth and enjoyable. I wholeheartedly recommend SSK Travels for anyone needing bus rental services in Pune for events or group trips."
        },
        {
            name: "Miss. Anjali Deshmukh",
            text: "We organized a corporate outing using a 32-seater bus from SSK Travels, and we were thoroughly impressed! The bus was spotless, well-maintained, and equipped with all the modern amenities necessary for a long journey. The staff was friendly, and our driver made sure we arrived at our destination safely and on schedule. For anyone seeking dependable and comfortable bus rental services in Pune, SSK Travels is the way to go!"
        }
    ];
    
    const contactData = {
        heading: "32 Seater Bus Rent in Pune Contact Number:",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@SSKtravels24.com"
    };
     
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 32 Seater Bus Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent a 32 seater bus in Pune for weddings, corporate events, and outstation trips. Enjoy competitive rates for both AC and non-AC options. Book your bus today!" />
  <meta name="keywords" content="32 seater bus rent in Pune | 32 seater bus on rent in Pune | 32 seater bus price in Pune | 32 seater bus on hire | 32 seater bus on hire for wedding in Pune | 32 seater bus on rent for marriage | 32 seater bus on rent for corporate events | 32 seater bus on rent for Mahabaleshwar | 32 seater AC bus on hire | 32 seater non-AC bus on hire | 32 seater bus hire for Ashtavinayak Darshan | 32 seater bus hire for 5 Jyotirlinga Darshan | Pune to Konkan Darshan 32 seater bus on rent | 32 seater bus on rent in Hadapsar | 32 seater bus on rent in Pimpri Chinchwad | Pune to Bhimashankar 32 seater bus hire | Pune to Ashtavinayak 32 seater bus on rent | 32 seater bus on rent in Kothrud | 32 seater bus for corporate office | 32 seater bus for outstation from Pune | 32 seater bus rental services in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 32 Seater Bus Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for 32 seater buses in Pune. Enjoy competitive rates for weddings, corporate events, and more. Contact us to book your bus!" />
  <meta property="og:url" content="https://https://ssktravels24.com//32-seater-bus-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//32-seater-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-11.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default BusRentInPune32Seater;
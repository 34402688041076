
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Bushireschool(){


    const cardData = 
    {
        keyword: 'Bus Hire for School Trips in Pune',
        heading: 'SSKTravels24: Bus Hire for School Trips in Pune',
        headingDescription: 'When organizing school trips, safety, comfort, and reliability are essential. At SSKTravels24, we understand these needs and offer exceptional bus hire services tailored specifically for school trips in Pune. Here are the top features of our bus hire service that make us the preferred choice for educational institutions:',
        
        topPlaces : [
            {
                title: "1. Spacious and Comfortable Seating",
                description: "Our buses are designed with spacious seating arrangements to ensure student comfort. With ample legroom and well-cushioned seats, your students will enjoy a relaxed journey, enhancing their trip experience."
            },
            {
                title: "2. Safety First",
                description: "Safety is our highest priority. Our buses are equipped with advanced safety features, including seat belts, emergency exits, and GPS tracking. Our drivers are highly trained and experienced in ensuring a secure journey for all passengers."
            },
            {
                title: "3. Air-Conditioned Buses",
                description: "To keep students comfortable in all weather conditions, we provide air-conditioned buses. Our climate control systems ensure a pleasant atmosphere throughout the trip, regardless of the weather outside."
            },
            {
                title: "4. Well-Maintained Fleet",
                description: "We take pride in maintaining our fleet to the highest standards. Each bus undergoes regular inspections and servicing to ensure it is in excellent condition. This rigorous maintenance schedule minimizes breakdown risks and guarantees a smooth ride."
            },
            {
                title: "5. Professional and Friendly Drivers",
                description: "Our drivers are not only skilled and professional but also friendly and approachable. They are trained to handle the unique needs of school trips, ensuring a smooth and enjoyable experience for both students and teachers."
            },
            {
                title: "6. Customizable Travel Packages",
                description: "We offer flexible and customizable travel packages to suit your school’s specific needs. Whether you need a full-day trip, a half-day outing, or transportation for special events, we can tailor our services to fit your schedule and budget."
            },
            {
                title: "7. Entertainment Options",
                description: "To keep students entertained during the journey, many of our buses come equipped with entertainment options like audio systems and DVD players. This feature helps make the trip enjoyable and engaging."
            },
            {
                title: "8. Affordable Rates",
                description: "At SSKTravels24, we offer competitive pricing for our school trip bus hire services. Our transparent pricing structure means there are no hidden fees, ensuring great value for your money."
            },
            {
                title: "9. Punctuality and Reliability",
                description: "We understand the importance of adhering to schedules, especially for school trips. Our buses follow strict schedules to ensure timely departures and arrivals, keeping your planned itinerary on track."
            },
            {
                title: "10. Excellent Customer Support",
                description: "Our dedicated customer support team is available to assist you throughout the booking process and during the trip. We are committed to addressing any concerns or issues promptly, ensuring a hassle-free experience for schools and students alike."
            }
        ],
        
        services : [
            {
                name: "Bus Hire for School Trips in Hadapsar, Pune",
                description: "SSKTravels24 offers reliable and comfortable bus hire services specifically tailored for school trips in Hadapsar, Pune. Our buses come with essential safety features and amenities to ensure a smooth and enjoyable journey for students and teachers alike."
            },
            {
                name: "Bus Hire for School Trips in Pimpri Chinchwad",
                description: "In Pimpri Chinchwad, SSKTravels24 is your go-to provider for school trip bus hires. We offer various buses, from mini buses to larger options, all designed to provide comfort and safety."
            },
            {
                name: "Bus Hire for School Picnic Morachi Chincholi",
                description: "Planning a school picnic to Morachi Chincholi? SSKTravels24 has you covered with our specialized bus hire services, perfect for transporting students and teachers to this scenic location."
            },
            {
                name: "Bus Hire for School Picnic Pune to Mahabaleshwar",
                description: "Traveling from Pune to Mahabaleshwar for a school picnic? Choose SSKTravels24 for a smooth journey with comfortable buses and professional drivers."
            },
            {
                name: "Bus Hire for School Picnic Pune to Alibaug",
                description: "For school picnics to Alibaug, SSKTravels24 offers reliable bus hire services designed to provide a safe and comfortable ride for students."
            },
            {
                name: "Bus Hire for Pune to Mumbai for School Picnic",
                description: "If your school picnic destination is Mumbai, SSKTravels24 provides exceptional bus hire services to ensure a smooth and enjoyable trip."
            },
            {
                name: "Bus Hire for Pune to Water Park for School Picnic",
                description: "For a fun day at the water park, SSKTravels24 offers specialized bus hire services to transport students safely and comfortably."
            },
            {
                name: "Bus Hire for Pune to Outstation School Picnic",
                description: "SSKTravels24 is your trusted partner for outstation school picnics, providing reliable and comfortable travel options for longer journeys."
            },
            {
                name: "Bus Hire for School Picnic in Pimpri Chinchwad",
                description: "For school picnics within Pimpri Chinchwad, SSKTravels24 offers excellent bus hire services that ensure a comfortable and safe travel experience for students."
            },
            {
                name: "Luxury Bus Hire for School Trips in Pune",
                description: "For luxury and comfort, SSKTravels24 offers the best luxury bus hire services for school trips. Our fleet is equipped with modern amenities to ensure an enjoyable journey."
            },
            {
                name: "Best Luxury Bus Hire for School Trips in Pune",
                description: "For the finest luxury bus hire experience, choose SSKTravels24. Our commitment to high-quality service ensures your school trip is memorable and stress-free."
            },
            {
                name: "Bus Hire for School Trips in Kothrud",
                description: "SSKTravels24 provides excellent bus hire services for school trips in Kothrud, ensuring safe and comfortable transportation for students."
            },
            {
                name: "Bus Hire for School Trips in Karve Nagar",
                description: "If you're planning a school trip from Karve Nagar, SSKTravels24 offers reliable and comfortable bus hire services equipped with essential amenities."
            },
            {
                name: "Bus Hire for School Trips in Somwar Peth",
                description: "For school trips in Somwar Peth, SSKTravels24 offers tailored bus hire services with a focus on comfort and reliability."
            },
            {
                name: "Bus Hire for School Trips in Viman Nagar",
                description: "SSKTravels24 provides top-quality bus hire services for school trips in Viman Nagar, ensuring a smooth and efficient travel experience."
            },
            {
                name: "Bus Hire for School Trips in Camp Pune",
                description: "For school trips in Camp Pune, SSKTravels24 offers reliable bus hire services tailored to your specific needs, ensuring a successful and enjoyable outing."
            },
            {
                name: "Mini Bus On Rent in Pune",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Mini Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        tableData : [
            ['Bus Hire for School Trips in Hadapsar, Pune', 'Bus Hire for School Trips in Pimpri Chinchwad'],
            ['Bus Hire for School Picnic Morachi Chincholi', 'Bus Hire for School Picnic Pune to Mahabaleshwar'],
            ['Bus Hire for School Picnic Pune to Alibaug', 'Bus Hire for Pune to Mumbai for School Picnic'],
            ['Bus Hire for Pune to Water Park for School Picnic', 'Bus Hire for Pune to Outstation School Picnic in Pune'],
            ['Bus Hire for School Picnic in Pimpri Chinchwad', 'Luxury Bus Hire for School Trips in Pune'],
            ['Best Luxury Bus Hire for School Trips in Pune', 'Bus Hire for School Trips in Kothrud'],
            ['Bus Hire for School Trips in Karve Nagar', 'Bus Hire for School Trips in Somwar Peth'],
            ['Bus Hire for School Trips in Viman Nagar', 'Bus Hire for School Trips in Camp Pune']
        ],
    
        whychoose : [
            {
                "WhyChooseheading": "Safe and Secure Transportation",
                "WhyChoosedescription": "At SSKTravels24, we prioritize the safety of your children. Our buses are equipped with GPS tracking, seat belts, and experienced drivers to ensure a secure journey during school trips."
            },
            {
                "WhyChooseheading": "Well-Maintained Fleet",
                "WhyChoosedescription": "Our fleet of buses is regularly serviced to ensure optimal performance and comfort. We offer a range of buses, including mini-buses and larger coaches, to accommodate groups of all sizes."
            },
            {
                "WhyChooseheading": "Affordable Rates",
                "WhyChoosedescription": "We provide competitive pricing for school bus hire, ensuring you get the best value for your money. Our transparent pricing policy means no hidden charges, making it easy for schools to plan their trips within budget."
            },
            {
                "WhyChooseheading": "Experienced Drivers",
                "WhyChoosedescription": "Our drivers are professional, courteous, and well-trained in handling school trips. Familiar with Pune’s routes, they ensure that students reach their destinations safely and on time."
            },
            {
                "WhyChooseheading": "Comfortable and Spacious Buses",
                "WhyChoosedescription": "Our buses feature comfortable seating and ample space for both students and teachers. The interiors are designed to provide a pleasant journey, ensuring students arrive refreshed and ready for their activities."
            },
            {
                "WhyChooseheading": "Tailored Services",
                "WhyChoosedescription": "We recognize that each school trip is unique. Whether it’s a day excursion, a picnic, or an educational tour, we offer customized bus hire services to meet your specific needs."
            },
            {
                "WhyChooseheading": "Punctual and Reliable",
                "WhyChoosedescription": "Punctuality is essential for school trips. We ensure that our buses arrive on time, allowing the trip to proceed smoothly without delays. Our reliability is why schools in Pune trust SSKTravels24."
            },
            {
                "WhyChooseheading": "Support for School Events",
                "WhyChoosedescription": "In addition to regular trips, we provide bus hire services for school events such as sports meets, educational tours, and annual functions. Our services are flexible and can be tailored to your event requirements."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What safety measures do you provide for school trips?",
            answer: "At SSKTravels24, safety is our top priority. Our buses are equipped with seat belts, GPS tracking, and first-aid kits. Our drivers are experienced and trained to handle school groups with care."
        },
        {
            question: "What are the seating capacities of your buses?",
            answer: "We offer buses with various seating capacities, ranging from 20-seater mini-buses to 50-seater large coaches. You can select the bus that fits your group size."
        },
        {
            question: "How do you ensure that the buses are clean and well-maintained?",
            answer: "Our buses undergo regular maintenance checks and are thoroughly cleaned before every trip, ensuring they are in excellent condition for a comfortable and hassle-free experience."
        },
        {
            question: "Can we hire buses for overnight school trips or outstation tours?",
            answer: "Yes, we provide bus hire services for both day trips and overnight outstation school tours. Our services are flexible and tailored to meet the duration and location of your school trip."
        },
        {
            question: "Are the drivers familiar with the routes to common school trip destinations in and around Pune?",
            answer: "Yes, our drivers are experienced and knowledgeable about popular school trip destinations in Pune and nearby areas, ensuring a smooth journey."
        },
        {
            question: "What are your charges for school bus hire?",
            answer: "Our charges depend on the type of bus, distance, and duration of the trip. We offer competitive pricing and provide a detailed quote after discussing your requirements."
        },
        {
            question: "How do I book a bus for a school trip?",
            answer: "You can book a bus by contacting us via our website, phone, or email. Our customer service team will assist you in selecting the right bus and providing a customized quote based on your needs."
        },
        {
            question: "Is there an option to reschedule or cancel a bus booking?",
            answer: "Yes, you can reschedule or cancel your booking based on our cancellation policy. We advise early communication to avoid any cancellation charges."
        },
        {
            question: "Do you offer additional support for school events like sports or annual functions?",
            answer: "Yes, we offer bus hire services for school events such as sports meets, annual functions, and educational tours, accommodating any special requirements for smooth transportation."
        },
        {
            question: "Can we request additional staff to accompany the trip for added safety?",
            answer: "Yes, we can provide additional staff or supervisors to accompany the trip for enhanced safety and assistance, especially for younger students. This can be arranged during the booking process."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Ms. Sunita Deshmukh",
            text: "We have been using SSKTravels24 for our school trips for the past two years, and their service has always been outstanding. The buses are well-maintained, and the drivers are professional and punctual. Safety is our top concern, and SSKTravels24 has consistently provided a secure and comfortable experience for our students. Their competitive rates and flexibility make them our go-to bus service for all our school events. Highly recommended!"
        },
        {
            name: "Mr. Sunil Patil",
            text: "SSKTravels24 made our recent school trip to Sinhagad Fort incredibly smooth. The bus was clean, spacious, and arrived right on schedule. The driver was courteous and ensured that the students were comfortable throughout the journey. We also appreciated the GPS tracking feature, which gave parents peace of mind. SSKTravels24 is reliable, and we will definitely be using their services for future school outings!"
        }
    ];
    
    
    const contactData = {
        heading: "Bus Hire for School Trips in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\img\Keyword Based Image\Keyword Based Image-01.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs: Bus Hire for School Trips in Pune at SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Bushireschool;
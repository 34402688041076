import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 

// Sample testimonials data
const testimonials = [
    {
        id: 1,
        name: 'Deepak Verma',
        text: 'My trip with SSK Travels 24 was nothing short of amazing! Every detail was meticulously planned, making my journey smooth and enjoyable.',
        position: 'Business Traveler',
    },
    {
        id: 2,
        name: 'Neha Mehta',
        text: 'I highly recommend SSK Travels 24. Their team is professional and attentive, ensuring that all my needs were met throughout the trip.',
        position: 'Leisure Traveler',
    },
    {
        id: 3,
        name: 'Rajesh Gupta',
        text: 'SSK Travels 24 exceeded my expectations! The itinerary was perfect, and the guides were knowledgeable and friendly.',
        position: 'Adventure Seeker',
    },
    {
        id: 4,
        name: 'Sonia Kapoor',
        text: 'From booking to returning home, SSK Travels 24 was exceptional. They provided a personalized touch that made all the difference.',
        position: 'Family Traveler',
    }
];

const VimanTestimonials = () => {
    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],
    };

    return (
        <section style={sectionStyle}>
            <div style={containerStyle}>
                <h2 style={headingStyle}>What Our Travelers Say</h2>
                <Slider {...settings}>
                    {testimonials.map(testimonial => (
                        <div key={testimonial.id} style={slideContainerStyle}>
                            <div style={slideStyle}>
                                <p style={textStyle}>
                                    "{testimonial.text}"
                                </p>
                                <div style={namePositionContainerStyle}>
                                    <h4 style={nameStyle}>{testimonial.name}</h4>
                                    <p style={positionStyle}>{testimonial.position}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

// Styles
const sectionStyle = {
    padding: '20px',
    backgroundColor: '#f7f9fc',
};

const containerStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
};

const headingStyle = {
    textAlign: 'center',
    color: '#24255B', // Updated color
    marginBottom: '30px',
    fontSize: '2.5rem',
    fontWeight: 'bold',
};

const slideContainerStyle = {
    padding: '0 10px',
    margin: '0 5px', // Add horizontal margin for spacing
};

const slideStyle = {
    padding: '30px',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
};

const textStyle = {
    fontStyle: 'italic',
    color: '#34495e',
    fontSize: '1.1rem',
    marginBottom: '20px',
};

const namePositionContainerStyle = {
    textAlign: 'center',
};

const nameStyle = {
    margin: '5px 0',
    color: '#24255B', // Updated color
    fontSize: '1.3rem',
};

const positionStyle = {
    color: '#7f8c8d',
    fontSize: '1rem',
};

export default VimanTestimonials;

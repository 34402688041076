
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function JyotirlingDarshanCabBookingwithSSKTravels24(){


    const cardData = 
    {
        keyword: '5 Jyotirling Darshan Cab Booking',
        heading: '5 Jyotirling Darshan Cab Booking with SSKTravels24',
        headingDescription: 'If you\'re planning a spiritual journey to visit the sacred Jyotirlingas in Maharashtra, SSKTravels24 is your ideal partner for cab booking. The state is home to five of the twelve revered Jyotirlingas in India, attracting pilgrims from all over the country.',
        
        topPlaces: [
          {
            title: "1. Nashik",
            description: "Explore the vineyards, the holy Godavari River, and the famous Kumbh Mela site."
          },
          {
            title: "2. Ellora Caves",
            description: "A UNESCO World Heritage Site, these rock-cut caves showcase remarkable architecture and intricate sculptures."
          },
          {
            title: "3. Ajanta Caves",
            description: "Another UNESCO site, Ajanta features stunning ancient Buddhist rock-cut caves and exquisite frescoes."
          },
          {
            title: "4. Shirdi",
            description: "Visit the Samadhi Mandir of Sai Baba, a major pilgrimage destination that draws millions of devotees."
          },
          {
            title: "5. Panchavati",
            description: "A sacred place in Nashik, known for its temples and the holy river Godavari."
          },
          {
            title: "6. Khandala",
            description: "A scenic hill station near Bhimashankar, perfect for nature lovers and trekking enthusiasts."
          },
          {
            title: "7. Sinhagad Fort",
            description: "Located near Pune, this fort offers stunning views and a rich history."
          },
          {
            title: "8. Lonavala",
            description: "Famous for its picturesque landscapes, this hill station is perfect for a quick getaway."
          },
          {
            title: "9. Maharashtra Mandir",
            description: "A beautifully designed temple in Pune dedicated to Lord Ganesha."
          },
          {
            title: "10. Mulshi Lake",
            description: "A serene spot near Pune, ideal for relaxation and nature walks."
          }
        ],
        
        services: [
          {
            name: "Book Your 5 Jyotirlinga Darshan Cab Online",
            description: "Easily schedule your trip with our online cab booking system, offering a range of vehicles from sedans to SUVs."
          },
          {
            name: "Pune to Bhimashankar Cab Service",
            description: "Premium cab services to visit Bhimashankar, ensuring scenic views of the Western Ghats."
          },
          {
            name: "Pune to Trimbakeshwar Cab Booking",
            description: "Experience a serene journey to Trimbakeshwar with our competitive taxi fares."
          },
          {
            name: "Pune to Grishneshwar Jyotirlinga Cab Service",
            description: "Visit Grishneshwar with our convenient cab service, including nearby attractions."
          },
          {
            name: "Pune to Aundha Nagnath Cab Booking",
            description: "Safe and timely travel to Aundha Nagnath with courteous drivers."
          },
          {
            name: "Pune to Vaijnath Jyotirlinga Darshan Cab Package",
            description: "Tailored packages with all necessary amenities for a pleasant pilgrimage."
          },
          {
            name: "5 Jyotirlinga Darshan Cab Booking from Pimpri Chinchwad",
            description: "Specialized services for easy access to all sacred temples from Pimpri Chinchwad."
          },
          {
            name: "5 Jyotirlinga Darshan Cab Booking Contact Number",
            description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient cab booking."
          }
        ],
      

        tableData: [
            ['5 Jyotirlinga Darshan Cab Booking', '5 Jyotirlinga Darshan Cab Booking Online'],
            ['5 Jyotirlinga Darshan Cab Booking from Pune', 'Pune to Bhimashankar Cab'],
            ['Taxi from Pune to Bhimashankar', 'Pune to Bhimashankar Cab Service'],
            ['Taxi Service from Pune to Bhimashankar', 'Pune to Trimbakeshwar Cab'],
            ['Pune to Trimbakeshwar Car Rental', 'Pune to Trimbakeshwar Cab Fare'],
            ['Pune to Grishneshwar Cab Service', 'Pune to Grishneshwar Jyotirlinga Cab'],
            ['Pune to Grishneshwar Jyotirlinga Taxi Service', 'Pune to Aundha Nagnath Cab Service'],
            ['Pune to Aundha Nagnath Cab Booking', 'Pune to Vaijnath Jyotirlinga Cab Package'],
            ['Pune to Vaijnath Jyotirlinga Darshan Cab Service', 'Pune to Vaijnath Jyotirlinga Cab Package'],
            ['Pune to Vaijnath Jyotirlinga Cab Package from Pune', '5 Jyotirlinga Darshan Cab Booking from Pimpri Chinchwad']
          ],
          
          whychoose: [
            {
              "WhyChooseheading": "Why Choose Us for 5 Jyotirlinga Darshan Cab Booking at SSKTravels24?",
              "WhyChoosedescription": "Our user-friendly platform makes it easy to book your 5 Jyotirlinga Darshan cab online. You can quickly secure your ride from the comfort of your home, ensuring a stress-free travel experience."
            },
            {
              "WhyChooseheading": "Diverse Vehicle Options:",
              "WhyChoosedescription": "Whether you're traveling alone or in a group, we offer a range of vehicles to suit your needs, including sedans, SUVs, and luxury cars. Enjoy comfort and space as you embark on your spiritual journey."
            },
            {
              "WhyChooseheading": "Experienced and Professional Drivers:",
              "WhyChoosedescription": "Our drivers are not only skilled but also knowledgeable about the routes to the Jyotirlingas. They prioritize your safety and comfort, ensuring a pleasant journey to each destination."
            },
            {
              "WhyChooseheading": "Customized Packages:",
              "WhyChoosedescription": "We provide tailored packages that cater to your specific needs, whether you're looking for a quick visit to a single Jyotirlinga or a comprehensive tour of all five."
            },
            {
              "WhyChooseheading": "Transparent Pricing:",
              "WhyChoosedescription": "With SSKTravels24, you can expect fair and upfront pricing with no hidden costs. We provide detailed fare information for each route, allowing you to budget your trip effectively."
            },
            {
              "WhyChooseheading": "24/7 Customer Support:",
              "WhyChoosedescription": "Our dedicated customer service team is available around the clock to assist you with any queries or changes to your booking, ensuring you have support whenever you need it."
            },
            {
              "WhyChooseheading": "Commitment to Customer Satisfaction:",
              "WhyChoosedescription": "Our focus is on providing an exceptional travel experience. We value your feedback and continuously strive to improve our services based on your needs."
            }
          ]
    };    

    
    const faqData = [
        {
          question: "How do I book a cab for the 5 Jyotirlinga Darshan?",
          answer: "You can book your cab easily through our website or mobile app by entering your travel details, selecting your preferred vehicle, and completing the payment."
        },
        {
          question: "What vehicles are available for the Jyotirlinga tour?",
          answer: "We offer a variety of vehicles, including standard sedans, SUVs like the Innova, and luxury cars to cater to different group sizes and preferences."
        },
        {
          question: "Can I customize my Jyotirlinga tour package?",
          answer: "Absolutely! We provide flexible packages that can be tailored to your specific travel needs, including the number of Jyotirlingas you wish to visit and the duration of your trip."
        },
        {
          question: "What are the payment options available?",
          answer: "We accept various payment methods, including credit/debit cards, UPI, and cash payments, providing you with flexibility and convenience."
        },
        {
          question: "Are the drivers experienced and trustworthy?",
          answer: "Yes, all our drivers are professionally trained, experienced, and well-versed in the routes to the Jyotirlingas, ensuring a safe and pleasant journey."
        },
        {
          question: "Is there a cancellation policy?",
          answer: "Yes, we have a cancellation policy that allows you to cancel your booking within a specified timeframe. Please check our website for detailed terms and conditions."
        },
        {
          question: "What if I have special requests or requirements?",
          answer: "Feel free to communicate any special requests during the booking process, and we will do our best to accommodate your needs."
        },
        {
          question: "How do I contact customer support?",
          answer: "You can reach our customer support team via phone, email, or live chat on our website. We’re here to assist you 24/7."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Aniket Bikkad",
          text: "I recently booked a cab through SSK Travels 24 for my pilgrimage to the 5 Jyotirlingas in Maharashtra, and it was a wonderful experience! The driver was punctual and knowledgeable about the routes, making the journey smooth and comfortable. The vehicle was clean and well-maintained, which added to our comfort. I highly recommend SSK Travels 24 for anyone looking to embark on a spiritual journey!"
        },
        {
          name: "Ms. Neha Joshi",
          text: "Our family chose SSK Travels 24 for our Jyotirlinga darshan, and we couldn’t be happier with the service. The booking process was straightforward, and the team was very accommodating to our needs. The cab was spacious, perfect for our group, and the driver ensured we had a safe and pleasant trip. Thank you, SSK Travels 24, for making our pilgrimage memorable!"
        }
      ];
      
      const contactData = {
        heading: "5 Jyotirlinga Darshan Cab Booking Contact Information",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>5 Jyotirling Darshan Cab Booking | SSK Travels24</title>
  <meta name="description" content="Book your 5 Jyotirlinga Darshan cab from Pune with SSK Travels24. Enjoy a comfortable journey to all sacred sites." />
  <meta name="keywords" content="5 Jyotirlinga Darshan cab booking, Pune to Bhimashankar cab, Pune to Trimbakeshwar cab, Grishneshwar cab service, Aundha Nagnath taxi, Vaijnath Jyotirlinga cab booking, SSK Travels 24" />
  <meta property="og:title" content="5 Jyotirling Darshan Cab Booking | SSK Travels24" />
  <meta property="og:description" content="Experience a divine journey with our 5 Jyotirlinga Darshan cab services from Pune. Easy online booking available!" />
  <meta property="og:url" content="https://https://ssktravels24.com//5-jyotirling-darshan-cab-booking" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//jyotirling-darshan-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-23.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default JyotirlingDarshanCabBookingwithSSKTravels24;
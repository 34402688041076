
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function MumbaiInternationalAirporttoPune(){


    const cardData = 
    {
        keyword: 'Mumbai International Airport to Pune Cab',
        heading: 'SSKTravels24: Mumbai International Airport to Pune Cab Service',
        headingDescription: 'Traveling from Mumbai International Airport to Pune? SSKTravels24 offers a reliable, comfortable, and convenient cab service. With experienced drivers and well-maintained vehicles, we ensure a smooth journey so you can focus on your travel plans.',
        
        topPlaces: [
          {
            title: "1. Shaniwar Wada",
            description: "This historical fortification was the seat of the Peshwas of the Maratha Empire. The architecture and the gardens offer a glimpse into Pune's royal past."
          },
          {
            title: "2. Aga Khan Palace",
            description: "A beautiful example of Indo-Saracenic architecture, this palace is significant for its role in India’s independence movement. It also houses a museum dedicated to Mahatma Gandhi."
          },
          {
            title: "3. Sinhagad Fort",
            description: "A popular trekking destination, this fort offers breathtaking views and a rich history dating back to the 17th century. It’s an excellent spot for adventure enthusiasts."
          },
          {
            title: "4. Osho Ashram",
            description: "A serene retreat in the bustling city, this ashram offers meditation and wellness programs. It's a great place to relax and rejuvenate."
          },
          {
            title: "5. Parvati Hill",
            description: "Climbing to the top rewards you with stunning views of the city and a visit to the temples dedicated to various Hindu deities."
          },
          {
            title: "6. Pune Okayama Friendship Garden",
            description: "Inspired by the 300-year-old Okayama Korakuen Garden in Japan, this beautifully landscaped garden is perfect for a peaceful stroll or a picnic."
          },
          {
            title: "7. Katraj Snake Park",
            description: "An engaging destination for families, this park features a variety of reptiles and is dedicated to the conservation of snakes."
          },
          {
            title: "8. National War Museum",
            description: "A tribute to the valor of Indian soldiers, this museum showcases military artifacts, making it an educational experience for visitors."
          },
          {
            title: "9. MG Road",
            description: "Known for its vibrant shopping scene, MG Road is the place to indulge in retail therapy, with numerous shops, cafes, and restaurants lining the street."
          },
          {
            title: "10. Lonavala and Khandala",
            description: "Just a short drive from Pune, these hill stations are perfect for weekend getaways. Enjoy the stunning landscapes, waterfalls, and pleasant weather."
          }
        ],
      
        services: [
          {
            name: "Mumbai International Airport to Pune Cab Service",
            description: "Our dedicated cab service provides a seamless transfer from Mumbai International Airport to Pune, ensuring timely pickups and drop-offs."
          },
          {
            name: "Pune to Mumbai International Airport Cab",
            description: "Planning a trip from Pune to Mumbai International Airport? Our service ensures you arrive on time, with a variety of vehicle options available."
          },
          {
            name: "Mumbai International Airport to Pune Innova Cab",
            description: "For larger groups or families, our Innova cabs offer spacious comfort for a pleasant travel experience."
          },
          {
            name: "Mumbai International Airport to Pune Innova Crysta Taxi",
            description: "Travel in style and comfort with our premium Innova Crysta taxis, perfect for those who appreciate luxury during their journey."
          },
          {
            name: "Taxi from Mumbai International Airport to Pune",
            description: "Enjoy a hassle-free ride with our taxis that provide excellent service from the airport straight to your destination in Pune."
          },
          {
            name: "Contact Number for Mumbai International Airport to Pune Cab",
            description: "For prompt and efficient service, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['Mumbai International Airport to Pune Cab', 'Pune to Mumbai International Airport Cab'],
            ['Mumbai International Airport to Pune Cab Service', 'Pune to Mumbai Domestic Airport Cab'],
            ['Taxi from Mumbai International Airport to Pune', 'Mumbai International Airport to Pune Innova Cab'],
            ['Mumbai International Airport to Pune Innova Crysta Taxi', 'Mumbai International Airport Terminal 1 to Pune Cab'],
            ['Mumbai International Airport Terminal 2 to Pune Cab', 'Mumbai International Airport Terminal 3 to Pune Cab'],
            ['Mumbai International Airport Terminal to Pune Drop Taxi', 'SSK Travels 24']
          ],
          
          whychoose: [
            {
              WhyChooseheading: "Reliable Service",
              WhyChoosedescription: "At SSKTravels24, we prioritize punctuality and reliability. Our drivers are trained to ensure timely pickups and drop-offs, giving you peace of mind as you travel."
            },
            {
              WhyChooseheading: "Comfortable Vehicles",
              WhyChoosedescription: "Our fleet includes a range of vehicles, from standard sedans to spacious Innova and luxury options. We ensure that each vehicle is well-maintained and equipped with modern amenities for your comfort."
            },
            {
              WhyChooseheading: "Experienced Drivers",
              WhyChoosedescription: "Our professional drivers are experienced and knowledgeable about the best routes. They prioritize your safety and comfort, ensuring a smooth journey from Mumbai International Airport to Pune."
            },
            {
              WhyChooseheading: "Transparent Pricing",
              WhyChoosedescription: "We believe in transparent pricing with no hidden fees. You'll receive a detailed quote upfront, so you know exactly what to expect."
            },
            {
              WhyChooseheading: "24/7 Availability",
              WhyChoosedescription: "Whether your flight arrives early in the morning or late at night, our services are available round the clock to accommodate your travel needs."
            },
            {
              WhyChooseheading: "Easy Booking Process",
              WhyChoosedescription: "Our user-friendly online booking system makes reserving your cab quick and hassle-free. You can book your ride in just a few clicks."
            },
            {
              WhyChooseheading: "Customer Support",
              WhyChoosedescription: "Our dedicated customer service team is always ready to assist you with any queries or changes to your booking, ensuring a seamless experience."
            }
          ]
    };    

    
   
    const faqData = [
        {
          question: "How can I book a cab from Mumbai International Airport to Pune?",
          answer: "You can easily book a cab through our website or by contacting our customer service team. Just provide your flight details and destination."
        },
        {
          question: "What types of vehicles are available?",
          answer: "We offer a variety of vehicles, including sedans, SUVs, and luxury options like the Innova Crysta, ensuring you can choose based on your needs."
        },
        {
          question: "What is the estimated fare for the cab service?",
          answer: "The fare depends on the vehicle type and distance. We provide a transparent quote during the booking process with no hidden charges."
        },
        {
          question: "Are your drivers experienced?",
          answer: "Yes, all our drivers are experienced professionals familiar with the best routes and committed to ensuring your safety and comfort."
        },
        {
          question: "How long does the journey from Mumbai Airport to Pune take?",
          answer: "The journey typically takes about 2.5 to 3 hours, depending on traffic conditions."
        },
        {
          question: "Is the service available 24/7?",
          answer: "Yes, we offer round-the-clock service to accommodate all flight schedules."
        },
        {
          question: "Can I make changes to my booking?",
          answer: "Yes, you can modify your booking by contacting our customer service team, who will assist you with any changes."
        },
        {
          question: "What safety measures are in place?",
          answer: "We prioritize passenger safety by regularly sanitizing our vehicles and ensuring our drivers follow health guidelines."
        },
        {
          question: "Do you offer one-way and round-trip options?",
          answer: "Yes, we provide both one-way and round-trip options for your convenience."
        },
        {
          question: "What if my flight is delayed?",
          answer: "Our drivers monitor flight statuses, so they will be there to pick you up even if your flight is delayed."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Amit Deshmukh",
          text: "I had a fantastic experience using SSKTravels24 for my ride from Mumbai International Airport to Pune. The driver was punctual and friendly, making my arrival seamless. The car was clean and comfortable, and I appreciated the smooth ride after a long flight. Highly recommend their services!"
        },
        {
          name: "Ms. Priya Sharma",
          text: "I frequently travel between Mumbai and Pune, and SSKTravels24 has become my go-to cab service. Their rates are reasonable, and the convenience of booking online is a huge plus. The drivers are professional, and I always feel safe. This time, the driver even helped with my luggage. Great service!"
        }
      ];
      
      const contactData = {
        heading: "Mumbai International Airport to Pune Cab Contact Information",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Mumbai International Airport to Pune Cab Services</title>
  <meta name="description" content="Book reliable and affordable cabs from Mumbai International Airport to Pune. Enjoy a comfortable ride with SSK Travels24." />
  <meta name="keywords" content="Mumbai international airport to Pune cab, Pune to Mumbai international airport cab, Mumbai airport taxi service, Mumbai airport to Pune innova, SSK Travels 24" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Mumbai International Airport to Pune Cab Services" />
  <meta property="og:description" content="Experience hassle-free travel from Mumbai International Airport to Pune with our reliable cab services. Quick booking available!" />
  <meta property="og:url" content="https://https://ssktravels24.com//mumbai-international-airport-to-pune-cab" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//mumbai-airport-to-pune-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-22.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default MumbaiInternationalAirporttoPune;
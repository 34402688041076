
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Manyseaterbus(){


    const cardData = 
    {
        keyword: '32, 35, 40, 45, and 50 Seater Buses on Rent in Pune',
        heading: 'SSKTravels24: 32, 35, 40, 45, and 50 Seater Buses on Rent in Pune',
        headingDescription: 'When seeking reliable and comfortable bus rental services in Pune, SSKTravels24 provides a range of options to suit your needs. From corporate events and family outings to special occasions and local tours, our diverse fleet ensures you travel in style and comfort. Here’s a look at the top bus rental options available with SSKTravels24 in Pune:',
        
        topPlaces : [
            {
                title: "1. 32-Seater Bus",
                description: "Our 32-seater bus is an excellent choice for medium-sized groups. It offers a spacious and comfortable interior, making it ideal for various types of local travel within Pune."
            },
            {
                title: "2. 35-Seater Bus",
                description: "The 35-seater bus provides additional room for larger groups. Perfect for corporate events, extended local trips, or significant family outings, it ensures a comfortable travel experience."
            },
            {
                title: "3. 40-Seater Bus",
                description: "Designed for larger groups, the 40-seater bus offers ample seating and comfort. It’s suitable for major events and group travel, providing reliable transportation across Pune."
            },
            {
                title: "4. 45-Seater Bus",
                description: "The 45-seater bus balances capacity and comfort, making it suitable for sizable groups. Ideal for extended trips or large-scale events, it ensures a pleasant journey."
            },
            {
                title: "5. 50-Seater Bus",
                description: "Our 50-seater bus accommodates very large groups with maximum seating capacity. It’s perfect for major corporate events, large school excursions, and extensive local tours, ensuring everyone travels comfortably."
            },
            {
                title: "6. Luxury 32-Seater Bus",
                description: "The luxury 32-seater bus offers a premium travel experience with high-end features. Ideal for special occasions, executive travel, or luxurious group outings, it provides top-level comfort and style."
            },
            {
                title: "7. AC 35-Seater Bus",
                description: "Our AC 35-seater bus keeps you cool during warmer months. Perfect for local trips and corporate events, it offers a comfortable and refreshing environment."
            },
            {
                title: "8. Non-AC 40-Seater Bus",
                description: "The non-AC 40-seater bus is a cost-effective option that provides reliable transportation with ample space. It’s suitable for budget-conscious groups needing dependable travel within Pune."
            },
            {
                title: "9. Luxury 45-Seater Bus",
                description: "Combining spaciousness with high-end features, the luxury 45-seater bus is ideal for upscale events and luxurious tours, providing a premium travel experience for large groups."
            },
            {
                title: "10. Executive 50-Seater Bus",
                description: "The executive 50-seater bus delivers a top-tier travel experience for very large groups. Equipped with executive features, it’s perfect for high-end corporate events and large-scale conferences, ensuring a sophisticated and comfortable journey."
            }
        ],
        
        services: [
                {
                    name: "32 Seater Bus Hire Near Me in Pune",
                    description: "Find the best local 32-seater bus rental options with SSKTravels24. Ideal for medium-sized groups, our buses offer comfort and reliability for all local travel needs."
                },
                {
                    name: "32 Seater Bus Hire for Outstation in Pune",
                    description: "Planning an outstation trip? Our 32-seater buses are perfect for comfortable and efficient travel to destinations outside Pune."
                },
                {
                    name: "32 Seater Bus Rate Per Km in Pune",
                    description: "Get competitive rates for 32-seater bus rentals based on distance traveled. We offer transparent pricing to suit your budget and travel requirements."
                },
                {
                    name: "35 Seater Bus on Rent in Pune",
                    description: "Ideal for slightly larger groups, our 35-seater buses provide ample space and comfort for various occasions, including family gatherings and corporate events."
                },
                {
                    name: "35 Seater Tourist Bus Near Me in Pune",
                    description: "Discover local options for 35-seater tourist buses. Perfect for sightseeing tours and travel within Pune."
                },
                {
                    name: "35 Seater Tourist Bus For Outstation in Pune",
                    description: "For outstation trips, our 35-seater buses offer a comfortable and spacious solution, ensuring a pleasant journey for larger groups."
                },
                {
                    name: "35 Seater Bus for Corporate Office in Pune",
                    description: "Arrange transportation for your corporate office with our 35-seater buses, suitable for business meetings, seminars, and team outings."
                },
                {
                    name: "35 Seater Bus for Corporate Outing in Pune",
                    description: "Plan a memorable corporate outing with our 35-seater buses, offering ample space and comfort for your team."
                },
                {
                    name: "40 Seater Tourist Bus Near Me in Pune",
                    description: "Find local rental options for 40-seater tourist buses. Perfect for large group travel and tours within Pune."
                },
                {
                    name: "40 Seater Bus Rent Per Km in Pune",
                    description: "Get detailed information on the cost of renting a 40-seater bus per kilometer. We provide competitive and transparent pricing."
                },
                {
                    name: "40 Seater Bus on Rent in Pune",
                    description: "For larger events and group travel, our 40-seater buses offer plenty of space and comfort for an enjoyable journey."
                },
                {
                    name: "40 Seater Bus on Rent in Pune for Weddings",
                    description: "Make your wedding transportation seamless with our 40-seater buses. Ideal for transporting guests comfortably to and from the wedding venue."
                },
                {
                    name: "45 Seater Bus Near Me for Rent",
                    description: "Looking for a 45-seater bus near you? At SSKTravels24, we offer spacious and comfortable 45-seater buses suitable for various occasions."
                },
                {
                    name: "45 Seater Bus for School Trips",
                    description: "For school trips, a 45-seater bus from SSKTravels24 ensures that all students travel comfortably and safely."
                },
                {
                    name: "45 Seater Bus Price Per Km in Pune",
                    description: "Curious about the price per km for renting a 45-seater bus in Pune? We offer competitive rates based on distance and duration."
                },
                {
                    name: "50 Seater Bus Rental Services in Pune",
                    description: "SSKTravels24 provides reliable 50-seater bus rental services in Pune, ideal for large groups."
                },
                {
                    name: "50 Seater Bus for Corporate Outing Near Me",
                    description: "Planning a corporate outing and need a 50-seater bus near you? Our buses provide a comfortable and professional environment."
                },
                {
                    name: "50 Seater Bus Hire in Pune",
                    description: "For reliable 50-seater bus hire in Pune, turn to SSKTravels24. Our buses are ideal for large groups and come with a range of amenities."
                },
                {
                    name: "32, 35, 40, 45, 50 Seater Bus on Rent in Pune for Family",
                    description: "Looking for a bus to accommodate your family trip? We offer options from 32 to 50-seater buses."
                },
                {
                    name: "32, 35, 40, 45, 50 Seater Bus on Rent in Pimpri Chinchwad",
                    description: "SSKTravels24 offers a variety of bus rental options in Pimpri Chinchwad for local events or outstation trips."
                },
                {
                    name: "32, 35, 40, 45, 50 Seater Bus for Outstation Trip",
                    description: "Planning an outstation trip and need a bus for your group? Our buses are equipped to handle long-distance travel."
                },
                {
                    name: "32, 35, 40, 45, 50 Seater Bus for Corporate Trip",
                    description: "For corporate trips, we offer a variety of bus sizes from 32 to 50-seater to accommodate your team."
                },
                {
                    name: "Contact Number for 32, 35, 40, 45, 50 Seater Bus on Rent in Pune",
                    description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient bus rental services."
                }
            ],
            tableData : [
                ['- 32 Seater Bus Hire Near Me in Pune', '- 32 Seater Bus Hire for Outstation in Pune'],
                ['- SSKTravels24 Pune', '- 32 Seater Bus Rate Per Km in Pune'],
                ['- 35 Seater Bus on Rent in Pune', '- 35 Seater Tourist Bus Near Me in Pune'],
                ['- 35 Seater Tourist Bus For Outstation in Pune', '- 35 Seater Bus for Corporate Office in Pune'],
                ['- 35 Seater Bus for Corporate Outing in Pune', '- 40 Seater Tourist Bus Near Me in Pune'],
                ['- 40 Seater Bus Rent Per Km in Pune', '- 40 Seater Bus on Rent in Pune'],
                ['- 40 Seater Bus on Rent in Pune for Weddings', '- 45 Seater Bus Near Me for Rent'],
                ['- 45 Seater Bus for School Trip', '- 45 Seater Bus Price Per Km in Pune'],
                ['- 50 Seater Bus Rental Services in Pune', '- 50 Seater Bus for Corporate Outing Near Me'],
                ['- 50 Seater Bus Hire in Pune', '- 32, 35, 40, 45, 50 Seater Bus on Rent in Pune for Family'],
                ['- 32, 35, 40, 45, 50 Seater Bus on Rent in Pimpri Chinchwad', '- 32, 35, 40, 45, 50 Seater Bus for Outstation Trip'],
                ['- 32, 35, 40, 45, 50 Seater Bus for Corporate Trip', '']
            ],            
    
            whychoose : [
                {
                    "WhyChooseheading": "Why Choose Us for 32, 35, 40, 45, 50 Seater Bus on Rent in Pune – SSKTravels24",
                    "WhyChoosedescription": "Diverse Fleet: We offer a comprehensive selection of buses, ranging from 32 to 50-seaters, ensuring you find the perfect vehicle for your specific needs. Whether it’s a small family outing or a large corporate event, we have you covered."
                },
                {
                    "WhyChooseheading": "Comfort and Safety:",
                    "WhyChoosedescription": "Our buses are meticulously maintained and equipped with modern amenities to guarantee a comfortable and safe journey. From plush seating to efficient air conditioning, your comfort is our priority."
                },
                {
                    "WhyChooseheading": "Experienced Drivers:",
                    "WhyChoosedescription": "Our professional drivers are courteous, highly trained, and experienced in ensuring a smooth ride. They know local and outstation routes well, helping you reach your destination with ease."
                },
                {
                    "WhyChooseheading": "Affordable Pricing:",
                    "WhyChoosedescription": "We provide competitive and transparent pricing for our bus rentals. Enjoy peace of mind with no hidden fees and great value for your money through our clear pricing structure."
                },
                {
                    "WhyChooseheading": "Flexible Booking:",
                    "WhyChoosedescription": "We offer flexible booking options tailored to your needs, whether it's for a few hours, a full day, or multiple days. Our straightforward booking process makes it easy to plan your trip."
                },
                {
                    "WhyChooseheading": "Punctual Service:",
                    "WhyChoosedescription": "At SSKTravels24, we understand the significance of time. We ensure that our buses arrive punctually and adhere to your schedule, delivering reliable service every time."
                },
                {
                    "WhyChooseheading": "Personalized Solutions:",
                    "WhyChoosedescription": "We offer customized solutions to meet your specific requirements, whether it’s a family trip, corporate outing, or school event. Our goal is to provide a tailored experience that suits your needs."
                },
                {
                    "WhyChooseheading": "24/7 Customer Support:",
                    "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with any inquiries, bookings, or concerns you may have. We are committed to providing responsive and helpful support."
                }
            ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for rent?",
            answer: "SSKTravels24 offers a variety of buses, including 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. We cater to various group sizes and travel needs."
        },
        {
            question: "How do I book a bus with SSKTravels24?",
            answer: "You can book a bus by visiting our website, calling our customer service hotline, or stopping by our office. We recommend booking in advance to secure your preferred vehicle."
        },
        {
            question: "What amenities are included in the buses?",
            answer: "Our buses come equipped with comfortable seating, air conditioning, and other amenities to ensure a pleasant journey. The specific amenities may vary by bus type."
        },
        {
            question: "What is the cost of renting a bus?",
            answer: "The cost of renting a bus depends on factors such as bus size, duration of hire, and distance traveled. We offer competitive and transparent pricing. Contact us for a tailored quote based on your requirements."
        },
        {
            question: "Can I hire a bus for an outstation trip?",
            answer: "Yes, we provide bus rentals for both local and outstation trips. Whether it’s a day trip or a longer journey, we can meet your travel needs."
        },
        {
            question: "Are your buses well-maintained?",
            answer: "Absolutely! All our buses undergo regular maintenance and thorough cleaning to ensure they are in excellent condition. Your safety and comfort are our top priorities."
        },
        {
            question: "Can I request a specific type of bus?",
            answer: "We will do our best to accommodate your request for a specific type of bus based on availability. Please share your requirements when booking, and we will work to meet them."
        },
        {
            question: "What is your cancellation policy?",
            answer: "We have a cancellation policy in place, and charges may apply based on when you cancel. For more details, please reach out to our customer support team."
        },
        {
            question: "Are your drivers experienced and professional?",
            answer: "Yes, our drivers are experienced and professional, trained to provide safe and reliable transportation. They are familiar with local routes and committed to excellent service."
        },
        {
            question: "Do you offer 24/7 customer support?",
            answer: "Yes, our customer support team is available 24/7 to assist with bookings, answer queries, and address any issues you may encounter."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Rajesh Patel",
            text: "We recently hired a 50-seater bus from SSKTravels24 for our company's annual conference, and the experience was outstanding. The bus was spacious, comfortable, and equipped with modern amenities that made our journey enjoyable. Our driver was professional and punctual, ensuring we arrived at our destination right on time. The booking process was smooth, and the customer service exceeded our expectations. I highly recommend SSKTravels24 for anyone looking for reliable and comfortable bus rentals in Pune."
        },
        {
            name: "Miss Aisha Deshmukh",
            text: "I booked a 35-seater bus from SSKTravels24 for my family's reunion trip, and it was a fantastic experience. The bus was clean, comfortable, and just right for our group size. Our driver was friendly and well-versed in the best routes, making the journey even more enjoyable. The entire process, from booking to the trip itself, was seamless. SSKTravels24 is definitely my go-to choice for bus rentals in Pune, and I’ll be using their services again in the future."
        }
    ];
    
    
    const contactData = {
        heading: "32,35,40,45,50 Seater Bus on Rent in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 32, 35, 40, 45, 50 Seater Bus on Rent in Pune</title>
  <meta name="description" content="Explore affordable 32, 35, 40, 45, and 50 seater bus rental options in Pune for corporate outings, family trips, and more." />
  <meta name="keywords" content="32 Seater Bus Hire Pune, 35 Seater Bus on Rent, 40 Seater Bus Rental, 45 Seater Bus for School Trip, 50 Seater Bus Hire Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 32, 35, 40, 45, 50 Seater Bus on Rent in Pune" />
  <meta property="og:description" content="Book spacious and comfortable buses in Pune for various needs. Competitive rates for 32 to 50 seater buses available." />
  <meta property="og:url" content="https://https://ssktravels24.com//32-35-40-45-50-seater-bus-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//bus-rental-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\32-35.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for “32, 35, 40, 45, 50 Seater Bus on Rent in Pune” – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Manyseaterbus;
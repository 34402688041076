
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function PremierCorporateEventCabServiceinPune(){


    const cardData = 
    {
        keyword: 'Premier Corporate Event Cab Service in Pune',
        heading: 'SSKTravels24: Premier Corporate Event Cab Service in Pune',
        headingDescription: 'In the bustling corporate landscape of Pune, effective transportation plays a crucial role in the success of any event. At SSKTravels24, we specialize in providing top-notch corporate event cab services tailored to meet the diverse needs of businesses. Our commitment to reliability, professionalism, and customer satisfaction sets us apart, making us the preferred choice for corporate travel in Pune.',
      
        topPlaces: [
          {
            title: "1. Airport Transfers",
            description: "Seamless transportation for corporate travelers arriving at or departing from Pune Airport. Ensure timely arrivals and departures for all your guests."
          },
          {
            title: "2. Conference Transportation",
            description: "Efficient transport solutions for attendees of conferences and seminars, making sure they reach the venue punctually."
          },
          {
            title: "3. Client Visits",
            description: "Professional cab services for clients visiting your office, enhancing their experience with comfortable and stylish transportation."
          },
          {
            title: "4. Corporate Outings",
            description: "Group transportation for team-building events, off-site meetings, or corporate retreats, facilitating a relaxed and enjoyable atmosphere."
          },
          {
            title: "5. Product Launch Events",
            description: "Coordinate logistics for product launches, ensuring your guests arrive in style and comfort, allowing you to focus on your event."
          },
          {
            title: "6. Employee Shuttles",
            description: "Regular shuttle services for employees commuting to work or attending off-site functions, promoting convenience and efficiency."
          },
          {
            title: "7. VIP Transportation",
            description: "Luxury vehicles for high-profile guests, providing a premium experience that reflects your company’s standards."
          },
          {
            title: "8. Event Staff Transport",
            description: "Reliable transport for event staff and crew, ensuring all operational aspects run smoothly without logistical hiccups."
          },
          {
            title: "9. Custom City Tours",
            description: "Arrange personalized city tours for corporate clients, combining business with pleasure to leave a lasting impression."
          },
          {
            title: "10. One-Way and Round-Trip Services",
            description: "Flexible options for all corporate travel needs, whether it's a one-off event or a recurring need for transportation."
          }
        ],
      
        services: [
          {
            name: "Corporate Cab Services in Pune",
            description: "Our corporate cab services in Pune are tailored to meet the demands of businesses, providing comfortable and efficient transportation for meetings, conferences, and events."
          },
          {
            name: "Cheapest Corporate Cab Services in Pune",
            description: "SSK Travels 24 provides the cheapest corporate cab services in Pune without compromising on quality, ensuring you get the best value."
          },
          {
            name: "Monthly Cab Service in Pune",
            description: "For organizations that require regular transportation, our monthly cab service in Pune is ideal, offering flexible packages to cater to your schedule."
          },
          {
            name: "Office Pickup and Drop Service in Pune",
            description: "Our office pickup and drop service in Pune ensures that your employees enjoy safe and comfortable travel to and from the workplace."
          },
          {
            name: "Corporate Event Cab Service in Pimpri Chinchwad",
            description: "SSK Travels 24 extends its corporate event cab services to Pimpri Chinchwad, providing seamless transport solutions for businesses."
          },
          {
            name: "Innova Crysta Cab for Corporate Taxi in Pune",
            description: "For those seeking luxury and comfort, our Innova Crysta cabs are the perfect choice for corporate travel in Pune."
          },
          {
            name: "Corporate Event Cab Service in Hinjewadi Pune",
            description: "Count on us for punctual and reliable transportation tailored to meet the needs of professionals in the Hinjewadi area."
          },
          {
            name: "Corporate Event Cab Service in Kharadi",
            description: "Our specialized corporate event cab services in Kharadi ensure smooth travel for all your events with drivers familiar with the area."
          },
          {
            name: "Corporate Event Cab Service in Viman Nagar",
            description: "We focus on safety and comfort to enhance your travel experience in Viman Nagar, a key location for businesses."
          },
          {
            name: "Fortuner on Rent for Corporate Party",
            description: "Looking to make a statement? Rent a Fortuner for a luxurious travel experience that reflects your company’s prestige."
          },
          {
            name: "+91 8956170493",
            description: "Contact SSKTravels24 for prompt and efficient Corporate Event Cab Service in Pune. We ensure a smooth and enjoyable ride for all our customers."
          }
        ],
        "tableData": [
            ["-17 Seater Tempo Traveller on Rent in Pimpri Chinchwad", "-Tempo Traveller Hire in Hinjewadi"],
            ["-13 Seater Tempo Travellers on Rent in Pimpri Chinchwad", "-Tempo Traveller Hire in Aundh Pune"],
            ["-9, 12, 14, 17, 20, 24 Seater Tempo Traveller on Rent in Pune", "-Pimpri Chinchwad to Shirdi Tempo Traveller on Rent"],
            ["-Tempo Traveller on Rent in Bhosari", "-17 Seater Tempo Traveller on Rent in Baner"],
            ["-25 Seater Bus on Rent in Pune", "-Tempo Traveller on Rent in Pimpri Chinchwad"]
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for Pune to Mahabaleshwar Tempo Traveller?",
                "WhyChoosedescription": "When it comes to renting a Tempo Traveller for your journey from Pune to Mahabaleshwar, SSKTravels24 is your ideal provider. Our commitment to comfort, reliability, and customer satisfaction ensures that your travel experience is both enjoyable and hassle-free. Here’s why SSKTravels24 stands out:"
            },
            {
                "WhyChooseheading": "Dependable Service",
                "WhyChoosedescription": "At SSKTravels24, we pride ourselves on offering reliable and punctual service for your journey from Pune to Mahabaleshwar, ensuring you arrive at your destination on time, every time."
            },
            {
                "WhyChooseheading": "Comfortable Rides",
                "WhyChoosedescription": "Our well-maintained vehicles are designed to provide a smooth and enjoyable travel experience, equipped with modern amenities and plenty of legroom for all passengers."
            },
            {
                "WhyChooseheading": "Professional Drivers",
                "WhyChoosedescription": "Our experienced drivers are not only knowledgeable about the best routes but are also committed to your safety and comfort, ensuring a pleasant journey."
            },
            {
                "WhyChooseheading": "Competitive Pricing",
                "WhyChoosedescription": "We offer transparent and competitive rates with no hidden charges, giving you the best value for your money while ensuring a premium travel experience."
            },
            {
                "WhyChooseheading": "Flexible Booking",
                "WhyChoosedescription": "Enjoy easy and flexible booking options tailored to your schedule, whether you need a one-way trip or a round trip, making the planning process simple and convenient."
            },
            {
                "WhyChooseheading": "24/7 Customer Support",
                "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with bookings, answer any questions, and address any concerns you may have during your journey."
            },
            {
                "WhyChooseheading": "Clean and Safe Vehicles",
                "WhyChoosedescription": "We prioritize hygiene and safety; our vehicles are regularly cleaned and maintained to the highest standards, ensuring a safe travel environment for all passengers."
            }
        ]
    };    

    
   
    const faqData = [
        {
          question: "How do I book a corporate event cab with SSKTravels24?",
          answer: "You can book your cab through our website or mobile app by selecting your desired vehicle and entering the event details."
        },
        {
          question: "What types of vehicles are available for corporate events?",
          answer: "We offer a variety of vehicles, including sedans, SUVs, and vans, to accommodate different group sizes and travel needs."
        },
        {
          question: "Can I customize the itinerary for my corporate event?",
          answer: "Yes, we provide flexible booking options and can tailor the itinerary based on your event schedule and requirements."
        },
        {
          question: "Are your drivers trained for corporate transportation?",
          answer: "Yes, all our drivers are professionally trained, ensuring they provide courteous and reliable service tailored for corporate clients."
        },
        {
          question: "What safety measures do you have in place?",
          answer: "We follow strict hygiene and safety protocols, including regular vehicle maintenance and cleaning, to ensure the safety of our passengers."
        },
        {
          question: "Is customer support available during the event?",
          answer: "Yes, our customer support team is available 24/7 to assist you with any questions or issues during your corporate event."
        },
        {
          question: "How do you ensure timely pickups and drop-offs?",
          answer: "Our experienced drivers are familiar with Pune's routes and traffic patterns, allowing us to plan for timely pickups and drop-offs."
        },
        {
          question: "Can you accommodate large groups?",
          answer: "Yes, we have vehicles that can accommodate large groups, ensuring everyone travels together comfortably."
        },
        {
          question: "Do you offer corporate packages or discounts?",
          answer: "We offer competitive pricing and can provide customized packages based on your specific requirements. Please contact us for more details."
        },
        {
          question: "How can I pay for the cab service?",
          answer: "We accept multiple payment methods, including cash, credit/debit cards, and mobile wallets, for your convenience."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Vikram Joshi",
          text: "We recently used SSKTravels24 for our annual corporate conference, and I couldn’t be happier with the service. The drivers were punctual, professional, and dressed appropriately for our event. The vehicles were clean and comfortable, which made a great impression on our guests. SSKTravels24 helped us ensure a smooth transportation experience, and we’ll definitely use them for future events!"
        },
        {
          name: "Ms. Priya Mehta",
          text: "Our company chose SSKTravels24 for a team-building retreat, and they exceeded our expectations. The booking process was easy, and the customer support was very responsive. Our driver was not only friendly but also knowledgeable about the area, which added value to our journey. Everything went perfectly, and I highly recommend SSKTravels24 for any corporate transportation needs!"
        }
      ];
      
      const contactData = {
        heading: "Contact Us for Corporate Event Cab Service in Pune",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-16.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PremierCorporateEventCabServiceinPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function TempoTravellerHireinPune(){


    const cardData = 
    {
        keyword: 'Tempo Traveller Hire in Pune',
        heading: 'SSKTravels24: Tempo Traveller Hire in Pune',
        headingDescription: 'When it comes to group travel in Pune, SSKTravels24 offers premier tempo traveller hire services tailored to meet all your transportation needs. Whether you\'re planning a corporate outing, family vacation, school trip, or any other group event, our tempo travellers are designed to provide comfort, convenience, and reliability.',
        
        topPlaces: [
            {
                title: "1. Spacious Seating Arrangements",
                description: "Our tempo travellers come in various seating configurations, including 9-seater, 12-seater, and 14-seater, comfortably accommodating different group sizes."
            },
            {
                title: "2. Climate-Controlled Comfort",
                description: "Equipped with efficient air conditioning, our tempo travellers ensure a pleasant temperature inside for an enjoyable travel experience."
            },
            {
                title: "3. Luxurious Interiors",
                description: "Featuring high-quality upholstery and spacious cabins, our tempo travellers enhance your travel experience with a focus on luxury."
            },
            {
                title: "4. Advanced Entertainment Systems",
                description: "Each vehicle is equipped with LED TVs, music systems, and DVD players to keep passengers entertained throughout the trip."
            },
            {
                title: "5. Ample Luggage Space",
                description: "Our tempo travellers come with generous luggage compartments to ensure all your belongings are safely stored and easily accessible."
            },
            {
                title: "6. Top-Tier Safety Features",
                description: "Modern safety features like seat belts, first-aid kits, and GPS tracking ensure a secure journey for all passengers."
            },
            {
                title: "7. Professional and Experienced Drivers",
                description: "Our drivers are well-trained and familiar with local routes, prioritizing punctuality and safety for a stress-free travel experience."
            },
            {
                title: "8. Well-Maintained Vehicles",
                description: "Each tempo traveller undergoes regular servicing and inspections to ensure optimal performance and cleanliness."
            },
            {
                title: "9. Flexible Rental Options",
                description: "We offer flexible rental options tailored to your schedule and budget, making the booking process straightforward."
            },
            {
                title: "10. Competitive and Transparent Pricing",
                description: "Our transparent pricing model ensures you receive excellent value with a clear breakdown of all costs involved."
            }
        ],
        
        services: [
            {
                name: "14 Seater Tempo Traveller Hire in Pune",
                description: "Ideal for medium-sized groups, our 14-seater tempo travellers offer comfortable seating and ample luggage space."
            },
            {
                name: "17 Seater Tempo Traveller on Rent in Pimpri Chinchwad",
                description: "Perfect for slightly larger groups, well-suited for group travel across the city or to nearby destinations."
            },
            {
                name: "Tempo Traveller on Rent Near Me",
                description: "Find convenient tempo traveller rental options across Pune, ensuring a rental location that’s close to you."
            },
            {
                name: "20 Seater Tempo Traveller on Rent in Pune",
                description: "Ideal for larger groups, perfect for group tours, school trips, or corporate events."
            },
            {
                name: "25 Seater Tempo Traveller on Rent in Pune",
                description: "Offering ample room and comfort, ideal for large family gatherings and corporate outings."
            },
            {
                name: "32 Seater Tempo Traveller on Rent in Pune",
                description: "Equipped with luxurious interiors and advanced amenities, perfect for very large groups and extended trips."
            },
            {
                name: "Pune to Shirdi Tempo Traveller on Rent",
                description: "Comfortable and reliable transportation for your spiritual journey from Pune to Shirdi."
            },
            {
                name: "Pune to Mahabaleshwar Tempo Traveller on Rent",
                description: "Plan a scenic getaway with our tempo travellers, ensuring a comfortable ride through beautiful landscapes."
            },
            {
                name: "Pune to Ajanta Ellora Tempo Traveller on Rent",
                description: "Convenient travel to explore the historic Ajanta and Ellora Caves."
            },
            {
                name: "Pune to Solapur Tempo Traveller on Rent",
                description: "Reliable services ensuring a smooth ride for all passengers traveling to Solapur."
            },
            {
                name: "Pune to Kolhapur Darshan Tempo Traveller on Rent",
                description: "Experience the cultural richness of Kolhapur with our tempo traveller rentals, perfect for darshan trips."
            },
            {
                name: "Pune to Goa Tempo Traveller on Rent",
                description: "Ideal for beach vacations, our tempo travellers offer comfort and convenience for your journey to Goa."
            },
            {
                name: "Pune to Per KM Tempo Traveller on Rent",
                description: "Flexible rental options with per-kilometer pricing to manage your travel expenses efficiently."
            },
            {
                name: "Contact Number for Tempo Traveller Hire",
                description: "For prompt and efficient tempo traveller hire in Pune, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['- 14 Seater Tempo Traveller Hire in Pune', '- 17 Seater Tempo Traveller on Rent in Pimpri Chinchwad'],
            ['- Tempo Traveller on Rent Near Me', '- 20 Seater Tempo Traveller on Rent in Pune'],
            ['- 25 Seater Tempo Traveller on Rent in Pune', '- 32 Seater Tempo Traveller on Rent in Pune'],
            ['- Pune to Shirdi Tempo Traveller on Rent', '- Pune to Mahabaleshwar Tempo Traveller on Rent'],
            ['- Pune to Ajanta Ellora Tempo Traveller on Rent', '- Pune to Solapur Tempo Traveller on Rent'],
            ['- Pune to Kolhapur Darshan Tempo Traveller on Rent', '- Pune to Mahabaleshwar Luxury Tempo Traveller on Rent'],
            ['- Pune to Goa Tempo Traveller on Rent', '- Pune to Per KM Tempo Traveller on Rent']
        ],
    
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose Us for Tempo Traveller Hire in Pune - SSKTravels24",
                "WhyChoosedescription": "When it comes to hiring a tempo traveller in Pune, SSKTravels24 stands out for its commitment to delivering exceptional service and comfort. Here’s why you should choose us for your next journey:"
            },
            {
                "WhyChooseheading": "Diverse Fleet:",
                "WhyChoosedescription": "We offer a wide range of tempo travellers, including 14-seater, 17-seater, 20-seater, 25-seater, and even 32-seater vehicles. Whatever your group size, we have the right vehicle to meet your needs."
            },
            {
                "WhyChooseheading": "Comfort and Convenience:",
                "WhyChoosedescription": "Our tempo travellers are equipped with comfortable seating, ample luggage space, and climate control to ensure a pleasant journey. Whether you're traveling locally or on a long-distance trip, our vehicles provide a superior travel experience."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our experienced and professional drivers are well-versed in local routes and outstation travel. They prioritize safety and punctuality, ensuring a smooth and hassle-free trip."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "We offer transparent and competitive pricing for our tempo traveller rentals. With no hidden costs, you’ll receive excellent value for your money."
            },
            {
                "WhyChooseheading": "Flexible Rental Options:",
                "WhyChoosedescription": "Whether you need a vehicle for a few hours, a day, or a week, we offer flexible rental options to accommodate your specific requirements."
            },
            {
                "WhyChooseheading": "Clean and Well-Maintained Vehicles:",
                "WhyChoosedescription": "Our tempo travellers are regularly serviced and thoroughly inspected to ensure they are in top condition. Cleanliness and hygiene are maintained to the highest standards."
            },
            {
                "WhyChooseheading": "Customizable Solutions:",
                "WhyChoosedescription": "We understand that every trip is unique. Our services can be tailored to suit your itinerary, budget, and specific travel needs, whether it’s for a family vacation, corporate event, or pilgrimage."
            },
            {
                "WhyChooseheading": "Easy Booking Process:",
                "WhyChoosedescription": "Our user-friendly booking process makes it simple to reserve a tempo traveller. You can book online or contact us directly for personalized assistance."
            },
            {
                "WhyChooseheading": "Reliable Customer Support:",
                "WhyChoosedescription": "Our dedicated customer support team is available to assist you with any queries or issues that may arise before, during, or after your journey."
            },
            {
                "WhyChooseheading": "Positive Customer Feedback:",
                "WhyChoosedescription": "Our commitment to providing excellent service has earned us positive feedback from satisfied customers. We take pride in our reputation for reliability and customer satisfaction."
            }
        ]
    };    

    
    const faqData = [
        {
            question: "What types of tempo travellers do you offer for rent?",
            answer: "We offer a range of tempo travellers, including 14-seater, 17-seater, 20-seater, 25-seater, and 32-seater vehicles. This variety ensures that we can accommodate groups of all sizes."
        },
        {
            question: "Are your tempo travellers air-conditioned?",
            answer: "Yes, all our tempo travellers are equipped with air conditioning to ensure a comfortable journey regardless of the weather."
        },
        {
            question: "Can I hire a tempo traveller for a one-way trip?",
            answer: "Yes, we offer flexible rental options, including one-way trips. You can choose the rental type that best suits your travel needs."
        },
        {
            question: "How can I book a tempo traveller with SSKTravels24?",
            answer: "You can book a tempo traveller through our user-friendly online booking system or by contacting our customer support team directly for personalized assistance."
        },
        {
            question: "Are your drivers experienced and professional?",
            answer: "Yes, all our drivers are experienced and professional. They are familiar with local routes and outstation travel, ensuring a safe and smooth journey."
        },
        {
            question: "What is included in the rental price?",
            answer: "Our rental prices are competitive and transparent, with no hidden costs. The rental price typically includes the vehicle, driver, and basic amenities. Any additional services or requirements can be discussed and priced accordingly."
        },
        {
            question: "Do you offer tempo travellers for outstation travel?",
            answer: "Yes, we offer tempo traveller rentals for both local and outstation travel. Whether you're heading to a nearby destination or a distant location, we have the right vehicle for you."
        },
        {
            question: "What safety measures are in place in your tempo travellers?",
            answer: "Our vehicles are equipped with modern safety features, including seat belts, emergency exits, fire extinguishers, first-aid kits, and GPS tracking for real-time monitoring."
        },
        {
            question: "Can I customize my tempo traveller rental to fit my itinerary?",
            answer: "Yes, we offer customizable solutions to match your specific travel itinerary and requirements. Our team will work with you to ensure your trip is tailored to your needs."
        },
        {
            question: "What should I do if I have a problem during my rental period?",
            answer: "Our customer support team is available to assist you with any issues that may arise during your rental period. Please contact us immediately, and we will address your concerns promptly."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rajesh Kumar",
            text: "I recently hired a tempo traveller from SSKTravels24 for a family trip to Mahabaleshwar, and I couldn’t be happier with the service. The vehicle was clean, spacious, and well-maintained, making our journey very comfortable. The driver was professional and courteous, ensuring we arrived on time and safely. The booking process was smooth, and the rates were very reasonable. I highly recommend SSKTravels24 for anyone looking for reliable tempo traveller hire in Pune."
        },
        {
            name: "Miss Priya Sharma",
            text: "SSKTravels24 provided an excellent tempo traveller for our corporate outing. The 20-seater bus was perfect for our group, with ample space and modern amenities. The air conditioning worked flawlessly, and the entertainment system kept everyone engaged throughout the trip. The driver was punctual and handled the vehicle expertly. Their customer service was top-notch, making the entire experience stress-free. If you need a tempo traveller in Pune, SSKTravels24 is the way to go!"
        }
    ];
    
    const contactData = {
        heading: "Tempo Traveller Hire in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@SSKtravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Tempo Traveller Hire in Pune | Affordable Rental Services</title>
  <meta name="description" content="Hire tempo travellers in Pune for various trips, including local and outstation journeys. Competitive rates for different seating capacities." />
  <meta name="keywords" content="14 seater tempo traveller hire in Pune | 17 seater tempo traveller on rent in Pimpri Chinchwad | Tempo traveller on rent near me | 20 seater tempo traveller on rent in Pune | 25 seater tempo traveller on rent in Pune | 32 seater tempo traveller on rent in Pune | Pune to Shirdi tempo traveller on rent | Pune to Mahabaleshwar tempo traveller on rent | Pune to Ajanta Ellora tempo traveller on rent | Pune to Solapur tempo traveller on rent | Pune to Kolhapur darshan tempo traveller on rent | Pune to Mahabaleshwar luxury tempo traveller on rent | Pune to Goa tempo traveller on rent | Pune to per km tempo traveller on rent" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Tempo Traveller Hire in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Explore affordable tempo traveller hire services in Pune for local and outstation trips. Book your vehicle today for a comfortable journey!" />
  <meta property="og:url" content="https://https://ssktravels24.com//tempo-traveller-hire-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//tempo-traveller-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-21.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default TempoTravellerHireinPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Ashtavinayakdarshancab(){


    const cardData = 
    {
        keyword: 'Ashtavinayak Darshan Cab Service',
        heading: 'SSKTravels24: Ashtavinayak Darshan Cab Service',
        headingDescription: 'Embark on a spiritual journey with SSKTravels24 as we take you on the revered Ashtavinayak Darshan. Visit eight sacred Ganesha temples in the picturesque landscapes of Maharashtra, each with unique history and significance.',
        
        topPlaces: [
            {
                title: "1. Moreshwar (Morgaon)",
                description: "The main shrine of the Ashtavinayak pilgrimage where Lord Ganesha is worshipped in the form of Moreshwar."
            },
            {
                title: "2. Sidhi Vinayak (Siddhatek)",
                description: "Known for its unique idol of Lord Ganesha with a large trunk, said to grant all desires."
            },
            {
                title: "3. Ballaleshwar (Pali)",
                description: "Dedicated to Lord Ballaleshwar, known for fulfilling the wishes of devotees."
            },
            {
                title: "4. Varad Vinayak (Mahad)",
                description: "Revered for its serene atmosphere, blessing devotees with success and fulfillment."
            },
            {
                title: "5. Chintamani (Theur)",
                description: "Famous for its historical significance, where the idol is believed to fulfill wishes and remove anxieties."
            },
            {
                title: "6. Girijatmaj (Lenyadri)",
                description: "Located in a mountain cave, offering a beautiful trek and connection with nature."
            },
            {
                title: "7. Vighnahar (Otur)",
                description: "Known for its tranquil environment, helping devotees overcome obstacles."
            },
            {
                title: "8. Mahaganapati (Ranjangaon)",
                description: "One of the most important Ganesha temples in Maharashtra, revered for its divine powers."
            }
        ],
    
        services: [
          {
              "name": "Cheapest Ashtavinayak Tour from Pune",
              "description": "Enjoy budget-friendly tours without compromising on quality, ensuring a memorable pilgrimage experience. Our tours are designed to make spiritual journeys accessible, offering comprehensive itineraries that include visits to all eight Ashtavinayak temples, along with comfortable transportation and knowledgeable guides."
          },
          {
              "name": "Ashtavinayak Tour from Pune by Bus Package",
              "description": "Join our economical bus package for guided tours to all eight temples, perfect for group travel. This package includes comfortable seating, group discounts, and a dedicated tour guide who will enrich your experience with insights about the significance of each temple. It's a cost-effective way to embark on a spiritual journey with friends or family."
          },
          {
              "name": "Ashtavinayak Darshan Innova Crysta Cab",
              "description": "Travel in luxury with spacious seating and comfort during your spiritual journey. Our Innova Crysta cabs offer premium features, including air conditioning, ample luggage space, and experienced drivers who know the best routes, ensuring a smooth and enjoyable ride as you visit the sacred temples."
          },
          {
              "name": "Ashtavinayak Tour from Pune by Car Package",
              "description": "Enjoy a flexible car package that allows you to explore the beauty of the route to each temple at your own pace. With personalized itineraries, you can choose how long to spend at each location, making it an ideal option for families and individuals who prefer a more tailored experience."
          },
          {
              "name": "Cab for Ashtavinayak Darshan",
              "description": "Our reliable cab service ensures timely pickups and drop-offs for a smooth pilgrimage experience. Our professional drivers are familiar with the routes and local conditions, making your travel efficient and stress-free, allowing you to focus on your spiritual journey."
          },
          {
              "name": "Ashtavinayak Darshan Package from Pune",
              "description": "This comprehensive travel solution includes transportation and guided tours at each temple. Experience the significance of each site with our knowledgeable guides, who will provide context and stories that enhance your pilgrimage, making each visit spiritually enriching."
          },
          {
              "name": "Ashtavinayak Darshan from Pune in 2 Days",
              "description": "Our well-planned itinerary allows you to visit all eight temples in just two days, maximizing your spiritual experience. This package includes comfortable transportation, accommodations, and meals, ensuring you have everything you need for a fulfilling pilgrimage without the hassle of planning."
          },
          {
              "name": "Pune to Ashtavinayak Taxi Service",
              "description": "Enjoy comfortable and direct taxi service to the sacred temples with our experienced drivers. We prioritize your comfort and safety, providing vehicles that are well-maintained and equipped for a pleasant journey, making it easy for you to visit the revered Ashtavinayak temples."
          },
          {
              "name": "Cheapest Tempo Traveller for Ashtavinayak Tour from Pune",
              "description": "Ideal for group travel, our tempo traveller service provides spacious seating and affordability. Perfect for larger families or groups, this option ensures everyone can travel together comfortably, making your pilgrimage a shared experience filled with devotion and camaraderie."
          },
          {
              "name": "Contact Number for Ashtavinayak Darshan Cab",
              "description": "For prompt and efficient Ashtavinayak Darshan Cab services, contact SSKTravels24 at +91 8956170493. Our customer service team is ready to assist you with your booking and answer any questions you may have, ensuring a smooth and enjoyable pilgrimage experience."
          }
      ],      
        tableData: [
            ['-Ashtavinayak Tour from Pune by Cab Package', '-Cheapest Ashtavinayak Tour from Pune'],
            ['-Ashtavinayak Tour from Pune by Bus Package', '-Ashtavinayak Darshan Innova Crysta Cab'],
            ['-Ashtavinayak Tour from Pune by Car Package', '-Cab for Ashtavinayak Darshan'],
            ['-Ashtavinayak Darshan Package from Pune', '-Ashtavinayak Darshan from Pune in 2 Days'],
            ['-Pune to Ashtavinayak Taxi Service', '-Pune to Ashtavinayak Tour Package'],
            ['-Pune to Ashtavinayak Darshan Package', '-Cheapest Ashtavinayak Tour from Pune'],
            ['-Cheapest Tempo Traveller for Ashtavinayak Tour from Pune', '-SSK Travels 24'],
            ['-Pune to Ashtavinayak Cab', '-Ashtavinayak Yatra Tour Cabs']
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose Us for Ashtavinayak Darshan Cab - SSKTravels24?",
                "WhyChoosedescription": "At SSKTravels24, we prioritize your comfort and satisfaction throughout your Ashtavinayak pilgrimage. Here’s why you should choose us:"
            },
            {
                "WhyChooseheading": "Comfort and Convenience:",
                "WhyChoosedescription": "Our fleet of well-maintained vehicles ensures a smooth ride as you visit each of the eight sacred Ganesha temples."
            },
            {
                "WhyChooseheading": "Experienced Drivers:",
                "WhyChoosedescription": "Our professional drivers are knowledgeable about the Ashtavinayak pilgrimage and can provide insights about the temples and local attractions."
            },
            {
                "WhyChooseheading": "Customizable Itineraries:",
                "WhyChoosedescription": "We create personalized itineraries that fit your schedule and preferences, whether you want a quick trip or a leisurely pace."
            },
            {
                "WhyChooseheading": "Affordable Pricing:",
                "WhyChoosedescription": "Enjoy competitive rates with various packages tailored to suit different budgets, ensuring a cost-effective way to experience the Ashtavinayak Darshan."
            },
            {
                "WhyChooseheading": "Safety First:",
                "WhyChoosedescription": "We adhere to strict hygiene protocols and maintain our vehicles to the highest standards for your safety."
            },
            {
                "WhyChooseheading": "24/7 Customer Support:",
                "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with any queries or concerns."
            },
            {
                "WhyChooseheading": "Real-Time Tracking:",
                "WhyChoosedescription": "Monitor your cab's location with our real-time tracking feature, giving you peace of mind while you wait."
            },
            {
                "WhyChooseheading": "Group Travel Options:",
                "WhyChoosedescription": "We offer a range of vehicles to accommodate solo travelers and large groups, ensuring everyone enjoys the pilgrimage together."
            },
            {
                "WhyChooseheading": "Local Insights:",
                "WhyChoosedescription": "Our drivers can recommend local eateries and attractions, allowing you to explore Maharashtra's culinary and cultural richness."
            },
            {
                "WhyChooseheading": "Commitment to Customer Satisfaction:",
                "WhyChoosedescription": "We value your feedback and continuously strive to improve our services based on customer experiences."
            }
        ]
    };    

    
   
    const faqData = [
        {
          question: "How do I book an Ashtavinayak Darshan cab with SSKTravels24?",
          answer: "You can book your cab easily through our website or mobile app by selecting your preferred itinerary and vehicle type."
        },
        {
          question: "What vehicles are available for the Ashtavinayak Darshan?",
          answer: "We offer a variety of vehicles, including sedans, SUVs, and luxury cars, allowing you to choose according to your comfort and group size."
        },
        {
          question: "Can I customize my itinerary for the pilgrimage?",
          answer: "Yes! We encourage you to customize your itinerary based on your preferences, whether you wish to visit all eight temples or select a few."
        },
        {
          question: "Is there a cancellation fee if I change my plans?",
          answer: "Our cancellation policy is flexible. Please check our website for specific details regarding cancellation fees and procedures."
        },
        {
          question: "Are your drivers familiar with the temple locations?",
          answer: "Absolutely! Our drivers are well-acquainted with the Ashtavinayak pilgrimage and can guide you throughout the journey."
        },
        {
          question: "Do you offer group bookings?",
          answer: "Yes, we provide options for group bookings, ensuring that families and friends can travel together comfortably."
        },
        {
          question: "How can I ensure my safety during the journey?",
          answer: "We maintain high safety standards with regular vehicle maintenance and strict hygiene protocols to ensure your safety and comfort."
        },
        {
          question: "What payment methods do you accept?",
          answer: "We accept various payment methods, including cash, credit/debit cards, and mobile wallets, for your convenience."
        },
        {
          question: "Is customer support available during the journey?",
          answer: "Yes, our customer support team is available 24/7 to assist you with any questions or issues that may arise during your trip."
        },
        {
          question: "Can you recommend places to eat along the route?",
          answer: "Our drivers are knowledgeable about local dining options and can recommend the best eateries for you to enjoy authentic Maharashtrian cuisine during your journey."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Ravi Kulkarni",
          text: "Our Ashtavinayak Darshan trip with SSKTravels24 was truly a memorable experience. The cab was comfortable, and our driver was very knowledgeable about the temples. He shared interesting stories and ensured we had a smooth journey throughout. The flexibility in our itinerary allowed us to explore the temples at our own pace. I highly recommend SSKTravels24 for anyone looking to undertake this spiritual journey!"
        },
        {
          name: "Ms. Anjali Deshmukh",
          text: "We had an amazing experience with SSKTravels24 during our Ashtavinayak pilgrimage. The vehicle was clean and well-maintained, making our long journey enjoyable. Our driver was not only punctual but also friendly and accommodating, providing us with helpful insights about each temple we visited. The entire process from booking to the end of the trip was seamless. Thank you, SSKTravels24, for a wonderful journey!"
        }
      ];
      
      const contactData = {
        heading: "Ashtavinayak Darshan Cab Contact Number",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Ashtavinayak Darshan Cab | Affordable Tour Packages</title>
  <meta name="description" content="Explore the Ashtavinayak temples with our cab services from Pune. Enjoy affordable packages, comfortable travel, and expert guidance." />
  <meta name="keywords" content="Ashtavinayak Darshan Cab, Ashtavinayak Tour from Pune, Cheapest Ashtavinayak Tour, Pune to Ashtavinayak Taxi Service, Ashtavinayak Yatra Tour Cabs" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Ashtavinayak Darshan Cab | Affordable Tour Packages" />
  <meta property="og:description" content="Book your Ashtavinayak Darshan tour from Pune with SSK Travels24. Choose from various cab options for a comfortable journey." />
  <meta property="og:url" content="https://https://ssktravels24.com//ashtavinayak-darshan-cab" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//ashtavinayak-tour-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\ashta-cab.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Ashtavinayakdarshancab;
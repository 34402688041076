import React from 'react';
import '../css/OurFleet.css';
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics';
const OurFleet = () => {
  const fleetData = [
    {
      id: 1,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-01.jpg',
      title: 'Ertiga',
      description: 'Spacious and comfortable, perfect for medium-sized groups.'
    },
    {
      id: 2,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-02.jpg',
      title: 'Sedan - Toyota Etios',
      description: 'Ideal for large groups or corporate events, combining comfort and style.'
    },
    {
      id: 3,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-03.jpg',
      title: 'Hyundai Aura',
      description: 'Modern and stylish, designed for your group travel needs.'
    },
    {
      id: 4,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-04.jpg',
      title: ' Hyundai Verna ',
      description: 'Spacious interior for family trips or group outings.'
    },
    {
      id: 5,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-05.jpg',
      title: 'MUV - Suzuki Ertiga',
      description: 'Perfectly equipped for larger groups, providing ample space and comfort.'
    },
    {
      id: 6,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-06.jpg',
      title: 'Scorpio',
      description: 'Perfect for extensive travel needs with comfort in mind.'
    },
    {
      id: 7,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-07.jpg',
      title: 'Toyota Innova',
      description: 'A spacious mini bus for mid-sized groups and long journeys.'
    },
    {
      id: 8,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-08.jpg',
      title: ' Innova Crysta ',
      description: 'Comfortable seating available in AC and non-AC variants.'
    },
    {
      id: 9,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-09.jpg',
      title: 'Toyota Fortuner',
      description: 'Ideal for larger groups or corporate outings.'
    },
    {
      id: 10,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-10.jpg',
      title: 'BMW',
      description: 'Perfect for large events and gatherings.'
    },
    {
      id: 11,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-11.jpg',
      title: 'Mercedes GLE coupe',
      description: 'A spacious option for the largest groups and events.'
    },
    {
      id: 12,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-12.jpg',
      title: 'Audi A6 Avant',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 13,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-13.jpg',
      title: 'Land Rover mg',
      description: 'Perfect for large events and gatherings.'
    },
    {
      id: 14,
      imgSrc: '/images/For mail/Fleet/Our fleets Cars-14.jpg',
      title: 'Rolls Royce Ghost',
      description: 'A spacious option for the largest groups and events.'
    },
    {
      id: 15,
      imgSrc: '/images/For mail/Fleet/Our fleets-01.jpg',
      title: '13 Searter Urbania Bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 16,
      imgSrc: '/images/For mail/Fleet/Our fleets-02.jpg',
      title: '17 Searter Urbania Bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 17,
      imgSrc: '/images/For mail/Fleet/Our fleets-03.jpg',
      title: '13 Seater Tempo Traveller',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 18,
      imgSrc: '/images/For mail/Fleet/Our fleets-04.jpg',
      title: '17 Seater Tempo Traveller',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 19,
      imgSrc: '/images/For mail/Fleet/Our fleets-05.jpg',
      title: '14 Seater Tata Winger',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 20,
      imgSrc: '/images/For mail/Fleet/Our fleets-06.jpg',
      title: '20 Seater Ac / Non AC bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 21,
      imgSrc: '/images/For mail/Fleet/Our fleets-07.jpg',
      title: '25 Seater Ac / Non AC bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 22,
      imgSrc: '/images/For mail/Fleet/Our fleets-08.jpg',
      title: '32 Seater Ac / Non AC bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 23,
      imgSrc: '/images/For mail/Fleet/Our fleets-09.jpg',
      title: '35 Seater Ac / Non AC bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 24,
      imgSrc: '/images/For mail/Fleet/Our fleets-10.jpg',
      title: '40 Seater Ac / Non AC bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 25,
      imgSrc: '/images/For mail/Fleet/Our fleets-11.jpg',
      title: '45 Seater Ac / Non AC bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 26,
      imgSrc: '/images/For mail/Fleet/Our fleets-12.jpg',
      title: '50 Seater Ac / Non AC bus',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 27,
      imgSrc: '/images/For mail/Fleet/Our fleets-13.jpg',
      title: 'Luxury Bus On Rent Pune',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 28,
      imgSrc: '/images/For mail/Fleet/Our fleets-14.jpg',
      title: 'Semi Luxury Bus Hire',
      description: 'For those who seek comfort and luxury on the road.'
    },
    {
      id: 29,
      imgSrc: '/images/For mail/Fleet/Our fleets-15.jpg',
      title: 'Volvo Bus on Rent',
      description: 'For those who seek comfort and luxury on the road.'
    },
  ];

  return (
    <div>
      <GoogleAnalytics/>
      <Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
      
        <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Our Fleets</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>

      <section className="our-fleet">
        <div className="container p-0">
          <div className="row g-0">
            {fleetData.map(fleet => (
              <div key={fleet.id} className="col-12 col-md-6 col-lg-4 p-0">
                <div className="fleet-card g-0 p-0">
                  <div className="image-container">
                    <img src={fleet.imgSrc} alt={fleet.title} />
                  </div>
                  <div className="card-details">
                    <h3>{fleet.title}</h3>
                    <p>{fleet.description}</p>
                    <a href="https://wa.me/918956170493" className="btn-book " target="_blank" rel="noreferrer">Book Now</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurFleet;

                                                                 
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function BusRentalinPune14_17_20and_25_Seater(){


    const cardData = 
    {
        keyword: '14 17 20 25 Seater Bus Rental in Pune',
        heading: 'SSKTravels24: 14, 17, 20, and 25-Seater Bus Rental in Pune',
        headingDescription: 'Planning group travel in Pune? SSKTravels24 offers a variety of comfortable and spacious buses to accommodate your needs, from corporate events to family outings. Discover the top features and services we provide for your travel requirements.',
        
        topPlaces: [
            {
                title: "1. Flexible Seating Arrangements",
                description: "Our fleet includes 14, 17, 20, and 25-seater buses, providing the flexibility to accommodate different group sizes."
            },
            {
                title: "2. Air-Conditioned Comfort",
                description: "All our buses are equipped with advanced air-conditioning systems to ensure a cool and comfortable journey."
            },
            {
                title: "3. Reclining Seats",
                description: "Passengers can adjust their seating positions with our reclining seats, providing optimal relaxation during travel."
            },
            {
                title: "4. Modern Entertainment Systems",
                description: "Enjoy movies and music with LED TVs and high-quality sound systems installed in our buses."
            },
            {
                title: "5. Ample Luggage Space",
                description: "Our buses come with spacious compartments to accommodate all your bags and belongings safely."
            },
            {
                title: "6. Advanced Safety Features",
                description: "Safety features include seat belts, emergency exits, fire extinguishers, and GPS tracking for real-time monitoring."
            },
            {
                title: "7. Skilled and Professional Drivers",
                description: "Our experienced drivers ensure a smooth, safe, and timely travel experience across local and outstation routes."
            },
            {
                title: "8. Well-Maintained Vehicles",
                description: "We maintain high standards of cleanliness and hygiene, ensuring all buses are regularly serviced and inspected."
            },
            {
                title: "9. Competitive Pricing",
                description: "Our transparent pricing structure offers great value for your money, with no hidden costs."
            },
            {
                title: "10. Customizable Solutions",
                description: "We provide flexible and customizable rental services to meet your specific travel requirements."
            }
        ],
        
        services: [
            {
                name: "14, 17, 20, 25-Seater Bus Hire for Corporate Events",
                description: "Ensure professional transportation for your team outings, allowing them to travel comfortably and arrive on time."
            },
            {
                name: "Bus Rental for Family Outings",
                description: "Perfect for family gatherings, our buses provide spacious interiors and modern amenities for a pleasant journey."
            },
            {
                name: "14, 17, 20, 25-Seater Bus for Religious Tours",
                description: "Comfortable transport for visits to sacred sites, ensuring a smooth pilgrimage experience."
            },
            {
                name: "14, 17, 20, 25-Seater Bus for Outstation Trips",
                description: "Ideal for trips to destinations like Mahabaleshwar, Shirdi, and Goa, ensuring comfort and safety."
            },
            {
                name: "Contact Number for 14, 17, 20, 25-Seater Bus Rental in Pune",
                description: "For prompt and efficient service, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['- 14, 17, 20, 25 Seater Bus Hire in Pune', '- Pune to Shirdi 14, 17, 20, 25 Seater Bus on Rent'],
            ['- Pune to Mahabaleshwar 14, 17, 20, 25 Seater Bus on Rent', '- Pune to Goa 14, 17, 20, 25 Seater Bus on Rent'],
            ['- Pune to Mumbai 14, 17, 20, 25 Seater Bus on Rent', '- Pune to Ashtavinayak Darshan 14, 17, 20, 25 Seater Bus on Rent'],
            ['- Pune to 5 Jyotirlinga 14, 17, 20, 25 Seater Bus on Rent', '- Pune to 5 Sadeteen Shaktipeeth 14, 17, 20, 25 Seater Bus on Rent'],
            ['- Pune to 5 Aurangabad 14, 17, 20, 25 Seater Bus on Rent', '']
        ],
        
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for 14, 17, 20, and 25-Seater Bus Rentals in Pune?",
                "WhyChoosedescription": "At SSKTravels24, we offer a versatile range of bus sizes, including 14, 17, 20, and 25-seater options. Whether you're organizing a small family gathering or a large corporate event, we have the perfect bus to meet your specific needs."
            },
            {
                "WhyChooseheading": "Diverse Fleet of Buses:",
                "WhyChoosedescription": "Our buses are designed for maximum comfort, featuring spacious seating, air-conditioning, and modern amenities. We prioritize passenger comfort to ensure every journey is enjoyable and stress-free."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our team of experienced and well-trained drivers is committed to providing safe and reliable transportation. Familiar with local routes, they ensure a smooth and timely journey, letting you relax and enjoy the ride."
            },
            {
                "WhyChooseheading": "Well-Maintained Vehicles:",
                "WhyChoosedescription": "We take pride in our meticulously maintained fleet. Each bus undergoes regular inspections and servicing to guarantee it's in top condition, reducing the risk of breakdowns during your trip."
            },
            {
                "WhyChooseheading": "Flexible Booking Options:",
                "WhyChoosedescription": "SSKTravels24 offers flexible booking solutions to fit your schedule. Whether you need a bus for a few hours or several days, we can easily accommodate your request."
            },
            {
                "WhyChooseheading": "Affordable Pricing:",
                "WhyChoosedescription": "Our competitive rates provide excellent value for money. We pride ourselves on transparent pricing with no hidden fees, ensuring you know exactly what to expect from your rental."
            },
            {
                "WhyChooseheading": "Customizable Travel Solutions:",
                "WhyChoosedescription": "We tailor our services to meet your specific requirements. Whether you need transportation for a corporate event, family outing, school trip, or any other occasion, we customize our offerings to fit your needs."
            },
            {
                "WhyChooseheading": "Excellent Customer Service:",
                "WhyChoosedescription": "Our dedicated customer service team is here to assist you throughout the booking process and beyond. We are committed to addressing any questions or concerns you might have."
            },
            {
                "WhyChooseheading": "Timely and Reliable:",
                "WhyChoosedescription": "Punctuality is essential for group travel. We adhere to strict schedules, ensuring you can rely on us to get you to your destination on time, every time."
            },
            {
                "WhyChooseheading": "Hassle-Free Experience:",
                "WhyChoosedescription": "From the moment you book until your journey concludes, we strive to make the entire process as smooth and hassle-free as possible. Our goal is to provide a seamless travel experience for you and your group."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses do you offer for rent?",
            answer: "We offer a variety of buses, including 14-seater, 17-seater, 20-seater, and 25-seater options, all equipped with comfortable seating and air-conditioning."
        },
        {
            question: "How can I book a bus with SSKTravels24?",
            answer: "You can easily book a bus by contacting us via phone, email, or through our online booking form. Just provide details such as your travel dates, pick-up and drop-off locations, and any special requests."
        },
        {
            question: "Are there any additional costs I should be aware of?",
            answer: "Our pricing is transparent with no hidden fees. Additional charges may apply for extra services or extended travel hours, but we will provide a detailed quote before finalizing your booking."
        },
        {
            question: "What is your cancellation policy?",
            answer: "Our cancellation policy varies based on how far in advance you cancel. Generally, cancellations made well in advance incur a nominal fee, while last-minute cancellations may involve higher charges. Please contact our customer service for specifics."
        },
        {
            question: "Can I request specific features or amenities?",
            answer: "Yes, you can request specific features or amenities based on availability. We offer a range of options to cater to different needs, including luxury and standard models."
        },
        {
            question: "Are the drivers experienced and licensed?",
            answer: "All our drivers are experienced, professionally trained, and hold valid licenses. They are familiar with local routes and dedicated to ensuring a safe and comfortable journey."
        },
        {
            question: "Can I make changes to my booking after it is confirmed?",
            answer: "Yes, changes can be made to your booking, subject to availability and any applicable fees. Please contact our customer service team as soon as possible to discuss modifications."
        },
        {
            question: "What should I do if I need assistance during my journey?",
            answer: "If you require assistance during your journey, please contact the driver or our customer service team immediately. We are dedicated to addressing any issues promptly."
        },
        {
            question: "How far in advance should I book a bus?",
            answer: "To ensure availability and secure your preferred bus, we recommend booking as early as possible. For peak seasons or special events, early booking is advisable to avoid any last-minute issues."
        },
        {
            question: "Are there any restrictions on luggage or other items?",
            answer: "There are standard luggage allowances, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when making your booking."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Anil K., Pune",
            text: "I recently rented a 25-seater bus from SSKTravels24 for a family trip to Lonavala, and the experience was fantastic! The bus was spacious and clean, making it perfect for our group. The driver was not only punctual but also very friendly and accommodating. He even suggested some scenic spots along the way, enhancing our trip. I would highly recommend SSKTravels24 for anyone needing a reliable bus service in Pune!"
        },
        {
            name: "Ms. Sneha R., Pune",
            text: "Our organization needed a 20-seater bus for an employee training workshop, and SSKTravels24 truly exceeded our expectations. The booking process was seamless, and their staff was very professional and attentive to our requirements. The bus was equipped with comfortable seating and modern amenities, making our journey enjoyable. Our driver navigated the busy streets with ease, ensuring we arrived on schedule. I’ll definitely choose SSKTravels24 for our future transportation needs!"
        }
    ];
    
    const contactData = {
        heading: "14, 17, 20, and 25-Seater Bus Rentals in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@SSKtravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 14 17 20 25 Seater Bus on Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent 14, 17, 20, and 25 seater buses in Pune for local and outstation trips. Competitive rates for various destinations. Book your bus today!" />
  <meta name="keywords" content="14 17 20 25 seater bus hire in Pune | Pune to Shirdi 14 17 20 25 seater bus on rent | Pune to Mahabaleshwar 14 17 20 25 seater bus on rent | Pune to Goa 14 17 20 25 seater bus on rent | Pune to Mumbai 14 17 20 25 seater bus on rent | Pune to Ashtavinayak darshan 14 17 20 25 seater bus on rent | Pune to 5 Jyotirlinga 14 17 20 25 seater bus on rent | Pune to 5 Shaktipeeth 14 17 20 25 seater bus on rent | Pune to Aurangabad 14 17 20 25 seater bus on rent" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 14 17 20 25 Seater Bus on Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best bus rental services for 14, 17, 20, and 25 seater options in Pune. Enjoy competitive rates for local and outstation trips. Contact us to book!" />
  <meta property="og:url" content="https://https://ssktravels24.com//14-17-20-25-seater-bus-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//14-17-20-25-seater-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\14-17.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default  BusRentalinPune14_17_20and_25_Seater;   

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busbookingforweddinginpune(){


    const cardData = 
    {
        keyword: 'Bus Booking for Weddings in Pune',
        heading: 'SSKTravels24: Bus Booking for Weddings in Pune',
        headingDescription: 'Planning a wedding involves numerous details, and ensuring that your guests have reliable and comfortable transportation is crucial. SSKTravels24 specializes in providing premium bus rental services for weddings in Pune, ensuring that your big day goes off without a hitch. Whether you are hosting a local event or an outstation wedding, we have the perfect transportation solution for you. Discover our range of bus rental options and services below:',
        
         topPlaces : [
            {
                title: "1. Wide Range of Bus Sizes",
                description: "We offer a variety of bus sizes to accommodate different group sizes, including 17-seater, 25-seater, 32-seater, 35-seater, and 40-seater options. Choose the perfect size for your wedding party and ensure everyone travels comfortably."
            },
            {
                title: "2. Luxury and Comfort",
                description: "Our fleet includes luxury and well-maintained buses designed for maximum comfort. Enjoy plush seating, spacious interiors, and modern amenities to make the journey as enjoyable as the destination."
            },
            {
                title: "3. Air-Conditioned Buses",
                description: "All our buses are equipped with air conditioning to ensure a cool and comfortable ride, especially during warmer months."
            },
            {
                title: "4. Professional Drivers",
                description: "Our experienced and professional drivers are skilled in handling wedding transportation, ensuring a smooth, safe, and timely journey for your guests."
            },
            {
                title: "5. Customizable Packages",
                description: "We offer customizable rental packages to meet your specific needs. Whether you need additional amenities or special arrangements, we can tailor a package to suit your requirements."
            },
            {
                title: "6. Transparent Pricing",
                description: "Enjoy competitive and transparent pricing with no hidden fees. We provide clear and upfront pricing, so you know exactly what to expect."
            },
            {
                title: "7. Punctual Service",
                description: "We prioritize punctuality, ensuring that all transportation schedules are adhered to. Your guests will arrive on time, allowing you to focus on celebrating your special day."
            },
            {
                title: "8. Local and Outstation Rentals",
                description: "Whether your wedding is local or requires outstation travel, we provide reliable transportation solutions for both. Enjoy seamless travel between your wedding venue, reception, and other locations."
            },
            {
                title: "9. Easy Booking Process",
                description: "Booking with SSKTravels24 is straightforward. Contact us via phone, email, or our online booking form, and we’ll handle all the details for you."
            },
            {
                title: "10. Exceptional Customer Support",
                description: "Our dedicated customer support team is available to assist with any queries or issues. We ensure a smooth booking and travel experience from start to finish."
            }
        ],             
        
        services : [
            {
                name: "Bus Rental for Weddings",
                description: "SSKTravels24 provides a comprehensive range of bus rental options for weddings. From intimate gatherings to grand celebrations, our fleet can accommodate your specific needs, ensuring a memorable experience for your guests."
            },
            {
                name: "Wedding Bus Hire Near Me Pune",
                description: "For convenient wedding bus hire in Pune, SSKTravels24 offers localized services to meet your needs. Whether you’re in the city or its surrounding areas, we have the right bus solution for you."
            },
            {
                name: "Shuttle Rental for Weddings in Pune",
                description: "Our shuttle rental services are ideal for transporting guests between the wedding venue, reception, and other locations. Ensure smooth and timely travel with our reliable shuttles."
            },
            {
                name: "Wedding Party Bus Rental",
                description: "Celebrate in style with our wedding party bus rentals. Our luxury party buses come with premium amenities to enhance the celebration, making the journey as enjoyable as the destination."
            },
            {
                name: "Wedding Bus Transportation in Pune",
                description: "Our wedding bus transportation services in Pune ensure a seamless experience for your guests. We handle all aspects of transportation, allowing you to focus on enjoying your special day."
            },
            {
                name: "Party Bus for Wedding",
                description: "Transform your wedding transportation with our party buses. Equipped with festive features, our party buses offer a fun and unique way to travel to your wedding venue."
            },
            {
                name: "Bus for Wedding Travels",
                description: "Our buses are perfect for various wedding travel needs, whether it’s moving guests between locations or embarking on pre-wedding excursions. We ensure a comfortable and well-coordinated journey."
            },
            {
                name: "Bus Booking for Wedding",
                description: "Secure your wedding bus with SSKTravels24 for a reliable and stylish transportation solution. Our efficient booking process ensures you get the perfect bus for your wedding day."
            },
            {
                name: "Bus on Rent for Wedding in Pimpri Chinchwad",
                description: "For residents of Pimpri Chinchwad, our specialized wedding bus rental services offer comfort and reliability. We provide a range of buses to suit any wedding party size."
            },
            {
                name: "Bus Rental for Wedding Near Me",
                description: "Find the ideal bus rental for your wedding with our localized services. SSKTravels24 provides convenient options for booking a bus near you, simplifying your wedding planning."
            },
            {
                name: "SSKTravels24 Pune",
                description: "As a leading bus rental service provider in Pune, SSKTravels24 is committed to making your wedding transportation seamless and stress-free. Our team ensures all aspects of your bus rental are handled with professionalism and care."
            },
            {
                name: "AC Bus Booking for Marriage",
                description: "Choose our air-conditioned buses for a cool and comfortable ride on your wedding day. Our AC buses are ideal for maintaining comfort throughout the journey."
            },
            {
                name: "Full Bus Booking for Marriage Price",
                description: "We offer competitive pricing for full bus bookings, providing excellent value for your wedding transportation needs. Contact us for a detailed quote based on your specific requirements."
            },
            {
                name: "Pune to Outstation Bus Booking for Marriage",
                description: "For weddings involving travel to outstation locations, SSKTravels24 provides reliable and comfortable buses. Ensure your guests travel in style and comfort, no matter the distance."
            },
            {
                name: "Bus Booking for Marriage Event",
                description: "From ceremonies to receptions, our bus booking services cover all aspects of your marriage event. We handle transportation logistics to ensure a smooth and enjoyable experience."
            },
            {
                name: "Private Bus Booking for Wedding in Pune",
                description: "For a more personalized experience, consider our private bus booking options. We offer exclusive rentals tailored specifically to your wedding needs."
            },
            {
                name: "Bus Booking for Wedding in Pimpri Chinchwad",
                description: "SSKTravels24 provides comprehensive bus rental services for weddings in Pimpri Chinchwad. Our range of buses ensures a smooth and comfortable journey for your guests."
            },
            {
                name: "Bus on Rent for Wedding in Karve Nagar",
                description: "Residents of Karve Nagar can benefit from our specialized wedding bus rental services. We offer convenient and reliable transportation options for your wedding event."
            },
            {
                name: "Luxury Bus on Rent for Wedding in Karve Nagar",
                description: "Experience luxury with our premium bus rentals in Karve Nagar. Our luxury buses are equipped with high-end features to ensure a stylish and comfortable ride."
            },
            {
                name: "Wedding Bus Hire in Pimpri Chinchwad",
                description: "Our wedding bus hire services in Pimpri Chinchwad provide reliable and comfortable transportation for your wedding event. Choose from our range of buses to suit your needs."
            },
            {
                name: "Wedding Bus Hire in Wadgaon Sheri",
                description: "We offer wedding bus hire services in Wadgaon Sheri, ensuring that your guests travel comfortably to and from your wedding venue. Our buses are well-maintained and equipped for your convenience."
            },
            {
                name: "Wedding Bus Hire in Hadapsar",
                description: "Our wedding bus hire services in Hadapsar offer a reliable and stylish transportation solution for your wedding event. Enjoy a smooth journey with our well-equipped buses."
            },
            {
                name: "Wedding Bus Hire in Katraj Pune",
                description: "For weddings in Katraj, Pune, SSKTravels24 provides specialized bus hire services. Ensure that your guests have a comfortable and enjoyable travel experience with our dedicated service."
            },
            {
                name: "Affordable Mini Bus on Rent in Pune for Wedding",
                description: "For smaller wedding parties, our affordable mini bus rentals provide a cost-effective solution. Enjoy reliable and comfortable transportation without exceeding your budget."
            },
            {
                name: "Bus booking for wedding in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient bus booking for weddings in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
         tableData : [
            ['- Bus Rental for Wedding', '- Wedding Bus Hire Near Me Pune'],
            ['- Shuttle Rental for Wedding in Pune', '- Wedding Party Bus Rental'],
            ['- Wedding Bus Transportation in Pune', '- Party Bus for Wedding'],
            ['- Bus for Wedding Travels', '- Bus Booking for Wedding'],
            ['- Bus on Rent for Wedding in Pimpri Chinchwad', '- Bus Rental for Wedding Near Me'],
            ['- AC Bus Booking for Marriage', '- Full Bus Booking for Marriage Price'],
            ['- Pune to Outstation Bus Booking for Marriage', '- Bus Booking for Marriage Event'],
            ['- Private Bus Booking for Wedding in Pune', '- Bus Booking for Wedding in Pimpri Chinchwad'],
            ['- Bus on Rent for Wedding in Karve Nagar', '- Luxury Bus on Rent for Wedding in Karve Nagar'],
            ['- Wedding Bus Hire in Pimpri Chinchwad', '- Wedding Bus Hire in Wadgaon Sheri'],
            ['- Wedding Bus Hire in Hadapsar', '- Wedding Bus Hire in Katraj Pune']
        ],        
    
         whychoose : [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for Your Wedding Bus Rental?",
                "WhyChoosedescription": "Choosing SSKTravels24 for your wedding bus rental means opting for excellence in both service and comfort. Our commitment to quality is reflected in every aspect of our service."
            },
            {
                "WhyChooseheading": "Experience and Expertise:",
                "WhyChoosedescription": "With years of experience in providing wedding transportation, we understand the unique needs of such events and deliver exceptional service accordingly."
            },
            {
                "WhyChooseheading": "Tailored Solutions:",
                "WhyChoosedescription": "We customize our services to meet your specific requirements, ensuring that all aspects of transportation are perfectly aligned with your wedding plans."
            },
            {
                "WhyChooseheading": "High-Quality Fleet:",
                "WhyChoosedescription": "Our fleet consists of well-maintained, luxury buses that offer the utmost comfort and style, making your guests' travel experience enjoyable."
            },
            {
                "WhyChooseheading": "Reliability:",
                "WhyChoosedescription": "We pride ourselves on our reliability. From punctuality to adherence to your requirements, we ensure everything runs smoothly on your special day."
            },
            {
                "WhyChooseheading": "Affordability:",
                "WhyChoosedescription": "Our transparent pricing and customizable packages make luxury wedding transportation affordable. We provide excellent value without compromising on quality."
            },
            {
                "WhyChooseheading": "Personalized Service:",
                "WhyChoosedescription": "We offer personalized service with attention to detail, making sure that every aspect of the transportation is handled with care and professionalism."
            },
            {
                "WhyChooseheading": "Customer Satisfaction:",
                "WhyChoosedescription": "Our dedicated customer support team is available to address any concerns and ensure that your experience with us is seamless and stress-free."
            },
            {
                "WhyChooseheading": "Comprehensive Coverage:",
                "WhyChoosedescription": "Whether it’s a local wedding or an outstation event, we offer comprehensive coverage to meet all your transportation needs."
            },
            {
                "WhyChooseheading": "Safety and Comfort:",
                "WhyChoosedescription": "We prioritize the safety and comfort of your guests, with experienced drivers and well-maintained vehicles that meet all safety standards."
            },
            {
                "WhyChooseheading": "Flexibility:",
                "WhyChoosedescription": "Our flexible booking options and customizable packages allow us to adapt to your changing needs and provide the best possible service for your wedding."
            }
        ]        
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for wedding bookings?",
            answer: "We offer a variety of buses, including luxury coaches, mini-buses, and standard buses, to accommodate different guest capacities and comfort levels."
        },
        {
            question: "How can I book a bus for my wedding?",
            answer: "You can book a bus by visiting our website or calling our customer service. Provide details like the date, pickup and drop-off locations, and the number of guests."
        },
        {
            question: "Is there a minimum or maximum number of passengers for booking?",
            answer: "We can accommodate bookings for small groups starting from 20 passengers up to larger groups of 50 or more, depending on the bus type."
        },
        {
            question: "Can I customize the itinerary for my wedding bus?",
            answer: "Absolutely! We offer customizable itineraries to ensure your guests are picked up and dropped off at convenient locations throughout the wedding events."
        },
        {
            question: "Are there any additional charges I should be aware of?",
            answer: "Additional charges may apply for extra stops, late-night service, or specific amenities. We’ll provide a detailed quote before finalizing your booking."
        },
        {
            question: "Do you provide buses for pre-wedding and post-wedding events?",
            answer: "Yes, we provide bus services for all wedding-related events, including pre-wedding ceremonies, receptions, and post-wedding gatherings."
        },
        {
            question: "How far in advance should I book the bus?",
            answer: "We recommend booking at least 2-3 months in advance, especially during peak wedding seasons, to ensure availability."
        },
        {
            question: "What happens if there are changes to the booking?",
            answer: "Please contact us as soon as possible. We will do our best to accommodate any changes, but some conditions may apply depending on the nature of the adjustments."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes, our luxury buses come with comfortable seating, air conditioning, and entertainment options. Additional amenities can be requested based on your needs."
        },
        {
            question: "Is there a cancellation policy?",
            answer: "Yes, we have a cancellation policy in place. Please check our terms and conditions for specific details regarding cancellations and refunds."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Rajesh Mehta",
            text: "We recently hired a 35-seater bus from SSKTravels24 for our daughter's wedding. The service was outstanding! The bus was luxurious and comfortable, and the driver was very professional. Everything went smoothly, and our guests were very pleased. Highly recommend their services for any wedding transportation needs!"
        },
        {
            name: "Miss Anjali Patil",
            text: "SSKTravels24 made our wedding transportation so easy. We booked a party bus for our reception, and it was a hit! The bus was beautifully maintained and perfect for our celebration. Their customer service was excellent, and the booking process was hassle-free."
        }
    ];
    
    const contactData = {
        heading: "Bus booking for wedding in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus Booking for Wedding in Pune | Affordable Wedding Bus Hire Services</title>
  <meta name="description" content="Book reliable and affordable buses for weddings in Pune. Various options for transportation, shuttles, and more." />
  <meta name="keywords" content="Bus Rental for wedding | Wedding bus hire near me Pune | Shuttle rental for wedding in Pune | Wedding party bus rental | Wedding bus transportation in Pune | Party Bus for Wedding | Bus for wedding Travels | Bus booking for wedding | Bus on rent for wedding in Pimpri Chinchwad | Bus Rental for wedding near me | SSK Travels24 Pune | AC bus booking for marriage | Full bus booking for marriage price | Pune to outstation bus booking for marriage | Bus Booking for Marriage Event | Private bus booking for wedding in Pune | Bus booking for wedding in Pimpri Chinchwad | Bus On Rent For Wedding in Karve Nagar | Luxury bus on rent for wedding in Karve Nagar | Wedding bus hire in Pimpri Chinchwad | Wedding bus hire in Wadgaon Sheri | Wedding Bus Hire in Hadapsar | Wedding bus hire in Katraj Pune | 17 25 32 35 40 Seater Wedding Bus Rental | Bus booking for wedding in Baner | Affordable mini bus on rent in Pune for wedding" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus Booking for Wedding in Pune | Affordable Wedding Bus Hire Services" />
  <meta property="og:description" content="Discover affordable and reliable bus booking services for weddings in Pune. Various options to choose from!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-booking-wedding-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//wedding-bus-rental-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-33.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Bus Booking for Weddings in Pune - SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busbookingforweddinginpune;
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({ items, settings }) => {
  return (
    <div className="slider-container ">
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className="slider-item">
            {item}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;













// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const CustomSlider = ({ items, settings }) => {
//   return (
//     <div>
//       <Slider {...settings}>
//       {items.map((item, index) => (
//         <div key={index} className="slider-item">
//           {item}
//         </div>
//       ))}
//     </Slider>
//     </div>
//   );
// };

// export default CustomSlider;

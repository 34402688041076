
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function TravelAgentsinPuneforMiniBusHire(){


    const cardData = 
    {
        keyword: 'Travel Agents in Pune for Mini Bus Hire',
        heading: 'SSKTravels24: Travel Agents in Pune for Mini Bus Hire',
        headingDescription: 'When it comes to mini bus rentals in Pune, SSKTravels24 is your go-to choice. Whether you’re organizing a corporate event, planning a family gathering, or arranging a school trip, our mini bus hire services are designed to provide comfort, convenience, and reliability.',
        
        topPlaces: [
            {
                title: "1. Spacious and Comfortable Seating",
                description: "Our mini buses are designed with roomy seating arrangements that offer ample legroom and support for a relaxed journey."
            },
            {
                title: "2. Air Conditioning",
                description: "Equipped with efficient air conditioning systems, our mini buses ensure a cool and pleasant environment, no matter the weather."
            },
            {
                title: "3. Professional Drivers",
                description: "Our highly trained and experienced drivers are committed to providing safe and reliable transport, ensuring timely arrivals and departures."
            },
            {
                title: "4. Well-Maintained Vehicles",
                description: "Each mini bus undergoes regular inspections and servicing, guaranteeing it's in top condition to minimize breakdown risks."
            },
            {
                title: "5. Affordable Pricing",
                description: "We offer competitive rates for mini bus rentals without compromising on quality, with a transparent pricing structure and no hidden fees."
            },
            {
                title: "6. Customizable Options",
                description: "Choose from a variety of mini bus models to meet diverse needs, whether you prefer luxury features or basic amenities."
            },
            {
                title: "7. Entertainment Systems",
                description: "Many of our mini buses are equipped with modern entertainment systems, including audio and video options to keep passengers entertained."
            },
            {
                title: "8. Flexible Rental Terms",
                description: "Whether you need a mini bus for a few hours or several days, we offer flexible rental terms to accommodate your schedule."
            },
            {
                title: "9. Safe and Secure Travel",
                description: "Safety is our utmost priority, with essential features like seat belts, first aid kits, and fire extinguishers in every mini bus."
            },
            {
                title: "10. Excellent Customer Service",
                description: "From booking to the end of your journey, our dedicated customer service team is here to support you, ensuring a hassle-free experience."
            }
        ],
        
        services: [
            {
                name: "Mini Bus Hire for Corporate Events",
                description: "Provide reliable transportation for corporate events, ensuring comfortable and timely journeys for your team."
            },
            {
                name: "Mini Bus Hire for Family Gatherings",
                description: "Ideal for family outings, offering spacious and comfortable travel for everyone."
            },
            {
                name: "Mini Bus Hire for School Trips",
                description: "Safe transport for school excursions, ensuring a pleasant journey for students and teachers alike."
            },
            {
                name: "Mini Bus Hire for Local Sightseeing",
                description: "Explore Pune's attractions comfortably with our mini bus rental services tailored for sightseeing tours."
            },
            {
                name: "Mini Bus Hire for Events",
                description: "Organize events smoothly with our bus hire services, providing comfortable transportation for all your guests."
            },
            {
                name: "Mini Bus Hire for Outstation Trips",
                description: "Travel to popular destinations with ease, ensuring comfort and safety throughout your journey."
            },
            {
                name: "Mini Bus Rental Pricing",
                description: "Contact us for personalized quotes based on your travel needs, ensuring competitive and transparent pricing."
            },
            {
                name: "Contact Number for Mini Bus Hire",
                description: "For prompt and efficient mini bus hire in Pune, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['- Tour Agencies in Pune', '- Bus Hire Agency in Pune'],
            ['- Travel Agents in Pune', '- Pune Tours and Travels Agents'],
            ['- Bus Hire Agents in Pune', '- Best Traveller Bus on Rent in Pune'],
            ['- Urbania Bus on Rent in Pune for Corporate Outing', '- Travel Agents in Pimpri Chinchwad'],
            ['- Pune Travel Agency Contact Number', '- Bus Hire for Events Agency'],
            ['- Bus Booking for Corporate Events', '- Bus on Hire for Pune to Mahabaleshwar Package Tour'],
            ['- Tour Operators in Pune', '- Bus Hire for Corporate Events & Tours'],
            ['- Tourist Agency in Pune', '- Best Tours and Travel Company in PCMC'],
            ['- Best Tours and Travel Company in Pune', '']
        ],
    
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for Travel Agents in Pune?",
                "WhyChoosedescription": "We provide a wide selection of mini buses to cater to various group sizes and travel needs. Whether you're organizing a corporate event, a family gathering, or a school outing, SSKTravels24 has the perfect vehicle for you."
            },
            {
                "WhyChooseheading": "Exceptional Comfort:",
                "WhyChoosedescription": "Our mini buses are designed for passenger comfort, featuring plush seating, efficient air conditioning, and modern amenities to ensure a delightful travel experience for everyone on board."
            },
            {
                "WhyChooseheading": "Reliable Service:",
                "WhyChoosedescription": "Punctuality and reliability are at the heart of our operations. Our travel agents work tirelessly to guarantee that your mini bus is ready and waiting on time, ensuring a smooth journey every time."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "SSKTravels24 offers competitive rates for our mini bus hire services without sacrificing quality. Our transparent pricing structure guarantees you get great value for your investment."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our skilled drivers are not only well-trained but also knowledgeable about Pune's routes and outstation destinations. They prioritize your safety and satisfaction, delivering a smooth and secure ride."
            },
            {
                "WhyChooseheading": "Customized Solutions:",
                "WhyChoosedescription": "Every trip is unique, and we get that. Our travel agents provide tailored solutions to meet your specific requirements, whether for special events, corporate functions, or leisurely outings."
            },
            {
                "WhyChooseheading": "Excellent Customer Service:",
                "WhyChoosedescription": "Our dedicated customer service team is here to assist you at every stage of your journey. From booking to the end of your trip, we strive to make your experience seamless and enjoyable."
            },
            {
                "WhyChooseheading": "Well-Maintained Vehicles:",
                "WhyChoosedescription": "We take pride in our fleet. All mini buses undergo regular maintenance and rigorous safety checks to ensure they are in optimal condition, providing clean and reliable transportation for your travels."
            },
            {
                "WhyChooseheading": "Flexible Booking Options:",
                "WhyChoosedescription": "We offer flexible booking options to suit your schedule. Whether you need a mini bus for a few hours or several days, we can adapt our services to meet your needs."
            },
            {
                "WhyChooseheading": "Commitment to Safety:",
                "WhyChoosedescription": "Safety is our top priority. Our mini buses are equipped with essential safety features, and our drivers follow all safety regulations to ensure a secure journey for all passengers."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of mini buses are available for hire?",
            answer: "We offer a variety of mini buses, including 14-seater, 17-seater, 20-seater, and 25-seater options. Each bus is equipped with comfortable seating and modern amenities to suit your travel needs."
        },
        {
            question: "How can I book a mini bus with SSKTravels24?",
            answer: "Booking a mini bus is easy! You can contact us via phone, email, or through our user-friendly online booking system. Our travel agents will help you choose the right vehicle and finalize your booking details."
        },
        {
            question: "What are the payment options for mini bus hire?",
            answer: "We accept multiple payment methods, including cash, credit/debit cards, and online transfers. Payment arrangements will be confirmed during the booking process."
        },
        {
            question: "Are the mini buses equipped with air conditioning?",
            answer: "Yes, all our mini buses come with air conditioning to ensure a comfortable ride for all passengers, regardless of external weather conditions."
        },
        {
            question: "Can I customize my mini bus hire package?",
            answer: "Absolutely! We offer customizable packages tailored to your specific needs. Whether you want additional amenities or special arrangements, our team will work with you to design the perfect package."
        },
        {
            question: "Are the drivers experienced and professional?",
            answer: "Yes, all our drivers are experienced, professional, and well-trained. They are familiar with local routes and dedicated to providing a safe and pleasant journey."
        },
        {
            question: "What should I do if there is a problem during the journey?",
            answer: "Our customer service team is available around the clock. If you experience any issues during your journey, please contact us immediately so we can resolve the situation swiftly."
        },
        {
            question: "How far in advance should I book a mini bus?",
            answer: "We recommend booking as early as possible to ensure availability, especially during peak seasons or for special events. Early booking helps us accommodate your preferred vehicle and schedule."
        },
        {
            question: "Are there any additional charges or hidden fees?",
            answer: "No, we prioritize transparency. All costs will be clearly outlined in your booking agreement, and you will not encounter hidden fees. Any additional charges will be communicated upfront."
        },
        {
            question: "What happens if I need to cancel or modify my booking?",
            answer: "Our cancellation and modification policies will be explained during the booking process. We strive to be flexible and accommodate changes, but certain conditions and fees may apply based on the timing of your request."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rakesh Deshmukh",
            text: "I recently engaged SSKTravels24 for a corporate event, and I was thoroughly impressed with their mini bus hire service. The Urbania mini bus we rented was immaculate, featuring plush seating and top-of-the-line amenities. Our team of 17 enjoyed a comfortable and smooth ride from Pune to Mumbai. The driver was professional and well-versed in the route, ensuring we arrived on time without any hassle. The booking process was straightforward, and the pricing was transparent with no hidden charges. SSKTravels24 has truly set a high standard for group transportation in Pune. Highly recommended for anyone in need of reliable and luxurious mini bus rentals."
        },
        {
            name: "Ms. Neha Sharma",
            text: "I booked a mini bus from SSKTravels24 for a family reunion, and the experience was outstanding. The Force Urbania mini bus we hired was spacious and comfortable, perfectly accommodating our group of 13. The air conditioning worked flawlessly, keeping us cool throughout the trip. The entertainment system was a hit with the kids, making the journey enjoyable for everyone. The driver was punctual and courteous, adding to the overall excellent service. What stood out the most was the transparency in pricing and the seamless booking process. If you’re looking for top-notch mini bus hire services in Pune, SSKTravels24 is the way to go!"
        }
    ];
    
    const contactData = {
        heading: "Travel Agents in Pune for Mini Bus Hire",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@SSKtravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Travel Agents in Pune for Mini Bus Hire | Affordable Rental Services</title>
  <meta name="description" content="Connect with the best travel agents in Pune for mini bus hire, corporate outings, and tour packages. Competitive rates and reliable service." />
  <meta name="keywords" content="Tour agencies in Pune | Bus hire agency in Pune | Tourist agency in Pune | Travel agents in Pune | Pune tours and travels agents | Bus hire agents in Pune | Best traveller bus on rent in Pune | Urbania bus on rent in Pune for corporate outing | Travel agents in Pimpri Chinchwad | Pune travel agency contact number | Bus hire for events agency | Bus hire for corporate events & tours | Bus booking for corporate events | Bus on hire for Pune to Mahabaleshwar package tour | Tour operators in Pune | Best tours and travel company in PCMC | Best tours and travel company in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Travel Agents in Pune for Mini Bus Hire | Affordable Rental Services" />
  <meta property="og:description" content="Discover reliable travel agents in Pune for mini bus hire and corporate outings. Enjoy competitive rates and excellent service. Contact us today!" />
  <meta property="og:url" content="https://https://ssktravels24.com//travel-agents-mini-bus-hire-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//travel-agents-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-20.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default TravelAgentsinPuneforMiniBusHire;
import React, { useState, useEffect } from 'react';

const Visionn = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sectionStyle = {
        padding: isMobile ? '0px' : '40px',
        // backgroundColor: '#f8f9fa',
        margin: '0 auto', // Ensure the section is centered
    };

    const containerStyle = {
        maxWidth: isMobile ? '100%' : '1200px',
        margin: '0 auto',
        padding: '0 15px',
    };

    const rowStyle = {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        gap: '20px',
    };

    const colStyle = {
        flex: '1',
        minWidth: isMobile ? '100%' : '300px',
        // padding: '15px',
        boxSizing: 'border-box',
        marginBottom: isMobile ? '20px' : '0', // Add margin bottom for mobile layout
    };

    const headingStyle = {
        textAlign: 'center',
        paddingBottom: '20px',
        fontSize: isMobile ? '24px' : '32px',
        color: '#343a40',
    };

    const visionContentStyle = {
        backgroundColor: '#913923', //background color
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        color: 'white',  // Dark text for contrast
    };

    const sectionTitleStyle = {
        color: '#24255B',  // Yellow color for titles
    };
    const sectionTitleStylee ={
        color :'white'
    }

    return (
        <section style={sectionStyle}>
            <div style={containerStyle}>
                <h3 style={{ ...headingStyle, ...sectionTitleStyle }}>About Our Vision & Mission</h3>
                <div style={rowStyle}>
                    <div style={colStyle}>
                        <div style={visionContentStyle}>
                            <h4 style={sectionTitleStylee}>Vision</h4>
                            <p>
                            At SSK Travels24, our vision is clear: to become the leading provider of travel services, delivering exceptional experiences that exceed customer expectations. We are dedicated to transforming the way people travel by offering personalized, comfortable, and seamless journeys that connect travelers to their destinations with ease. With a strong focus on innovation, customer satisfaction, and quality service, we are committed to making every journey with us memorable and enriching. Whether for business or leisure, SSK Travels24 ensures a travel experience that is reliable, convenient, and tailored to your needs. Join us on a journey where your comfort and satisfaction come first.
                            </p>
                        </div>
                    </div>
                    <div style={colStyle}>
                        <div style={visionContentStyle}>
                            <h4 style={sectionTitleStylee}>Mission</h4>
                            <p>
                            We are committed to delivering exceptional customer service that consistently exceeds expectations at every interaction. By embracing cutting-edge technology and innovation, we enhance the travel experience and streamline our services for maximum convenience and efficiency. Our mission is to build strong partnerships with key stakeholders, broadening our reach to offer a comprehensive range of services that meet diverse travel needs. We foster a culture of continuous improvement, learning, and development within our team to ensure we remain at the forefront of the industry, setting new standards in travel excellence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Visionn;

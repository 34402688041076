
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetomahabaleshwer(){


    const cardData = 
    {
      keyword: 'Tempo Traveller on Rent for Pune to Mahabaleshwar',
      heading: 'SSKTravels24: Tempo Traveller on Rent for Pune to Mahabaleshwar',
      headingDescription: 'Planning a trip from Pune to Mahabaleshwar? SSKTravels24 offers the perfect Tempo Traveller rental solutions to make your journey comfortable and enjoyable. Discover the top features and services we provide for your Pune to Mahabaleshwar travel needs.',
      
        "topPlaces": [
            {
                "title": "1. Spacious Interiors",
                "description": "Enjoy the generous interior space of our Tempo Travellers, which ensures ample legroom and headroom for all passengers. Whether you're traveling with family or a larger group, our vehicles provide a comfortable environment where everyone can relax and enjoy the journey."
            },
            {
                "title": "2. Luxurious Seating",
                "description": "Travel in style with our plush, reclining seats. Each seat is equipped with individual armrests and adjustable headrests, ensuring maximum comfort during both short and long trips."
            },
            {
                "title": "3. Powerful Air Conditioning",
                "description": "Beat the Pune heat with our advanced air conditioning systems. Our Tempo Travellers are designed to keep the interior cool and comfortable, making your journey pleasant no matter the weather outside."
            },
            {
                "title": "4. Ample Luggage Space",
                "description": "Pack all your essentials without worry. Our Tempo Travellers come with substantial luggage compartments that accommodate your bags and belongings securely, leaving ample room for passengers."
            },
            {
                "title": "5. Modern Entertainment Systems",
                "description": "Enhance your travel experience with our cutting-edge entertainment options. Each Tempo Traveller features music players and LED screens, providing entertainment throughout your trip, whether you’re enjoying your favorite music or a movie."
            },
            {
                "title": "6. Experienced and Professional Drivers",
                "description": "Safety and professionalism are our priorities. Our experienced drivers are well-versed with Pune’s routes and beyond, ensuring a smooth and safe journey while you sit back and enjoy the ride."
            },
            {
                "title": "7. Well-Maintained Vehicles",
                "description": "Our Tempo Travellers are regularly serviced and maintained to the highest standards. We ensure that each vehicle is in top condition, providing you with a reliable and clean travel experience."
            },
            {
                "title": "8. Flexible Rental Options",
                "description": "We offer flexible rental packages to suit your specific needs. Whether it’s a short day trip or a longer journey, you can choose a rental option that best fits your schedule and budget."
            },
            {
                "title": "9. Competitive Pricing",
                "description": "Get the best value for your money with our transparent pricing. We offer clear, upfront rates with no hidden charges, making it easy to plan your travel expenses without surprises."
            },
            {
                "title": "10. 24/7 Customer Support",
                "description": "Our dedicated customer support team is available around the clock to assist you with bookings, inquiries, and any travel-related concerns. We are committed to providing a hassle-free experience from start to finish."
            }
        ],
    
        "services": [
            {
                "name": "Tempo Traveller from Pune to Mahabaleshwar",
                "description": "Enjoy a comfortable ride with ample space and modern amenities. Our Tempo Travellers are designed to provide a smooth journey through the scenic Western Ghats, making your trip to Mahabaleshwar relaxing and enjoyable."
            },
            {
                "name": "Pune to Mahabaleshwar Tempo Traveller Hire",
                "description": "Our easy and flexible rental options are tailored to your needs. Whether you’re planning a day trip or a weekend getaway, we offer customizable packages that fit your schedule and budget, ensuring a hassle-free experience."
            },
            {
                "name": "Pune to Panchgani Tempo Traveller",
                "description": "Explore nearby destinations like Panchgani with the same comfort and reliability as our Mahabaleshwar service. With spacious interiors and comfortable seating, you can enjoy the breathtaking views and attractions along the way."
            },
            {
                "name": "Tempo Traveller for Rent in Pune",
                "description": "Choose from a variety of vehicles for any type of group travel. Our fleet includes options for families, corporate outings, and friends, all equipped with modern amenities to ensure a pleasant journey for everyone."
            },
            {
                "name": "17-Seater Tempo Traveller for Mahabaleshwar Trip",
                "description": "Ideal for medium to large groups, our 17-seater Tempo Traveller features spacious seating, powerful air conditioning, and ample luggage space. It’s perfect for family gatherings, corporate trips, or vacations with friends."
            },
            {
                "name": "17-Seater Tempo Traveller for Mahabaleshwar Tour Package",
                "description": "Enjoy a well-planned trip with our comfortable and feature-rich Tempo Travellers. Our tour packages include popular attractions, ensuring that you don’t miss out on the best that Mahabaleshwar has to offer."
            },
            {
                "name": "Pune Airport to Mahabaleshwar Tempo Traveller on Rent",
                "description": "Start your trip with ease directly from the airport. Our drivers will meet you at the arrivals area, assist with your luggage, and take you smoothly to your destination, allowing you to begin your vacation stress-free."
            },
            {
                "name": "Pune to Tapola Tempo Traveller on Rent",
                "description": "Discover the beautiful landscapes of Tapola with our reliable rental service. Known for its stunning lakes and lush greenery, Tapola is a perfect getaway, and our Tempo Travellers ensure you travel in comfort and style."
            },
            {
                "name": "14-Seater Tempo Traveller for Maharashtra Trip",
                "description": "Perfect for smaller groups exploring various parts of Maharashtra, this vehicle offers comfort and space for up to 14 passengers. Experience the freedom to visit popular attractions or off-the-beaten-path locations at your own pace."
            },
            {
                "name": "Tempo Traveller on Rent Mahabaleshwar Outings",
                "description": "Rent a vehicle for local sightseeing and outings in Mahabaleshwar. With our experienced drivers and comfortable vehicles, you can visit scenic spots like Arthur’s Seat, Venna Lake, and Mapro Garden without any hassle."
            },
            {
                "name": "Mini Bus on Rent for Mahabaleshwar Trip",
                "description": "Ideal for larger groups needing extra space, our mini buses provide comfort and convenience for longer journeys. Enjoy group travel without compromising on comfort, with ample room for everyone and their belongings."
            },
            {
                "name": "Tempo Traveller on Rent in Pune Rate Per Km",
                "description": "We offer transparent pricing based on the distance traveled, allowing you to budget easily without any hidden fees. Our competitive rates ensure you get the best value for your journey, no matter where you're headed."
            },
            {
                "name": "Pune to Mahabaleshwar Tempo Traveller Price",
                "description": "Enjoy competitive rates for your journey from Pune to Mahabaleshwar. We believe in offering affordable travel options that don’t compromise on quality, ensuring a memorable experience for you and your group."
            },
            {
                "name": "Mini Bus On Rent in Pune Contact Number:",
                "description": "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Mini Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        tableData : [ 
          ['-17 Seater Tempo Traveller on Rent in Pimpri Chinchwad', '-Tempo Traveller Hire in Hinjewadi'],
          ['-13 Seater Tempo Travellers on Rent in Pimpri Chinchwad', '-Tempo Traveller Hire in Aundh Pune'],
          ['-9, 12, 14, 17, 20, 24 Seater Tempo Traveller on Rent in Pune', '-Pimpri Chinchwad to Shirdi Tempo Traveller on Rent'],
          ['-Tempo Traveller on Rent in Bhosari', '-17 Seater Tempo Traveller on Rent in Baner'],
          ['-25 Seater Bus on Rent in Pune', '-Tempo Traveller on Rent in Pimpri Chinchwad']
      ],
      "whychoose": [
        {
            "WhyChooseheading": "Why Choose SSKTravels24 for Pune to Mahabaleshwar Tempo Traveller?",
            "WhyChoosedescription": "When it comes to renting a Tempo Traveller for your journey from Pune to Mahabaleshwar, SSKTravels24 is your ideal provider. Our commitment to comfort, reliability, and customer satisfaction ensures that your travel experience is both enjoyable and hassle-free. Here’s why SSKTravels24 stands out:"
        },
        {
            "WhyChooseheading": "Dependable Service:",
            "WhyChoosedescription": "At SSKTravels24, we pride ourselves on offering reliable and punctual service for your journey from Pune to Mahabaleshwar, ensuring you arrive at your destination on time, every time."
        },
        {
            "WhyChooseheading": "Comfortable Rides:",
            "WhyChoosedescription": "Our well-maintained vehicles are designed to provide a smooth and enjoyable travel experience, equipped with modern amenities and plenty of legroom for all passengers."
        },
        {
            "WhyChooseheading": "Professional Drivers:",
            "WhyChoosedescription": "Our experienced drivers are not only knowledgeable about the best routes but are also committed to your safety and comfort, ensuring a pleasant journey."
        },
        {
            "WhyChooseheading": "Competitive Pricing:",
            "WhyChoosedescription": "We offer transparent and competitive rates with no hidden charges, giving you the best value for your money while ensuring a premium travel experience."
        },
        {
            "WhyChooseheading": "Flexible Booking:",
            "WhyChoosedescription": "Enjoy easy and flexible booking options tailored to your schedule, whether you need a one-way trip or a round trip, making the planning process simple and convenient."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with bookings, answer any questions, and address any concerns you may have during your journey."
        },
        {
            "WhyChooseheading": "Clean and Safe Vehicles:",
            "WhyChoosedescription": "We prioritize hygiene and safety; our vehicles are regularly cleaned and maintained to the highest standards, ensuring a safe travel environment for all passengers."
        }
    ]
    };    

    
   
    const faqData = [
        {
          question: "How can I book a Tempo Traveller from Pune to Mahabaleshwar?",
          answer: "You can book a Tempo Traveller by calling us at +91 8956170493 or through our website. Our team will assist you with the booking process, making it simple and convenient."
        },
        {
          question: "What types of Tempo Travellers are available for the Pune to Mahabaleshwar journey?",
          answer: "We offer a range of Tempo Travellers, including 17-seaters and mini buses, to accommodate different group sizes and preferences, ensuring that you have the right vehicle for your trip."
        },
        {
          question: "Are there any additional charges for the Tempo Traveller service?",
          answer: "Our pricing is transparent, with no hidden fees. Any additional charges for extra stops or extended mileage will be communicated in advance, so you know exactly what to expect."
        },
        {
          question: "How do I know if the Tempo Traveller is well-maintained?",
          answer: "All our vehicles undergo regular inspections and maintenance to ensure they are in excellent condition for your safety and comfort, providing peace of mind during your journey."
        },
        {
          question: "Can I choose the driver for my trip?",
          answer: "While we assign experienced drivers to ensure a high-quality experience, you can let us know of any specific preferences when booking, and we will do our best to accommodate them."
        },
        {
          question: "What if I need to change my booking?",
          answer: "Contact our customer support team to discuss any changes. We will accommodate your requests based on availability, ensuring flexibility to meet your needs."
        },
        {
          question: "Is there a minimum rental period for the Tempo Traveller service?",
          answer: "We offer flexible rental periods to fit your needs, whether for a day trip or a longer journey, making it easy to plan your travel."
        },
        {
          question: "What amenities are included in the Tempo Traveller service?",
          answer: "Our vehicles come with modern amenities such as air conditioning, comfortable seating, and entertainment options, enhancing your travel experience."
        },
        {
          question: "What is the cancellation policy for the Tempo Traveller service?",
          answer: "Cancellation policies may vary. Please contact us for details on our cancellation terms and any applicable fees, ensuring you are informed before making a reservation."
        },
        {
          question: "How can I contact SSKTravels24 for support during my journey?",
          answer: "Reach our 24/7 customer support team at +91 8956170493 for any assistance during your trip. We're here to help ensure a smooth travel experience."
        }
      ];
      

    const testimonials = [
      {
        name: "Miss Rasika Sharma",
        text: "I recently rented a Tempo Traveller from SSKTravels24 for our trip to Mahabaleshwar, and it was a fantastic experience. The vehicle was clean, spacious, and well-maintained. The driver was professional and made our journey enjoyable. Highly recommend SSKTravels24 for group trips!"
      },
      {
        name: "Mr. sunil Patel",
        text: "Our corporate team used SSKTravels24 for a Mahabaleshwar outing, and we were thoroughly impressed. The Tempo Traveller was luxurious and comfortable, perfect for our needs. The booking process was smooth, and the driver was excellent. We’ll definitely choose SSKTravels24 again for future trips."
      }
    ];

    const contactData = {
      heading: " Pune to Mahabaleshwar Tempo Traveller",
      contactNumbers: [
        "+91 8956170493",
        "+91 7276307000",
       
      ],
      email: "booking@ssktravels24.com"
    };
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Mahabaleshwar Tempo Traveller | Affordable Rental Services</title>
  <meta name="description" content="Rent a tempo traveller from Pune to Mahabaleshwar. We offer 17-seater and luxury options for trips to Mahabaleshwar, Panchgani, and more. Competitive rates for local and outstation travel." />
  <meta name="keywords" content="Tempo traveller from Pune to Mahabaleshwar, Pune to Mahabaleshwar tempo traveller hire, Pune to Panchgani tempo traveller, Tempo traveller for rent in Pune, Tempo traveller 17 seater for Mahabaleshwar trip, 17 seater tempo traveller for Mahabaleshwar tour package, Pune Airport to Mahabaleshwar tempo traveller on rent, Pune to Tapola tempo traveller on rent, 14 seater tempo traveller for Maharashtra trip, Tempo traveller on rent Mahabaleshwar outings, Mini bus on rent for Mahabaleshwar trip, Tempo traveller on rent in Pune rate per km, Pune to Mahabaleshwar tempo traveller price" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Mahabaleshwar Tempo Traveller | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best tempo traveller rental services for trips from Pune to Mahabaleshwar. We offer 17-seater and luxury options at competitive rates. Contact us to book your journey today!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-mahabaleshwar-tempo-traveller" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//mahabaleshwar-tempo-traveller-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-02.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetomahabaleshwer;
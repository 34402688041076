
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function LuxuryForceUrbaniaonRentinPune(){


    const cardData = 
    {
        "keyword": "Luxury Force Urbania on Rent in Pune",
        "heading": "SSKTravels24: Luxury Force Urbania on Rent in Pune",
        "headingDescription": "For a premium travel experience in Pune, SSKTravels24 proudly offers the Luxury Force Urbania on rent. Perfect for those seeking a mix of style, comfort, and class, the Luxury Force Urbania is an excellent choice for any occasion.",
        
        "topPlaces": [
          {
            "title": "1. Spacious Interior",
            "description": "The Luxury Force Urbania features an expansive, well-designed interior that offers ample space for passengers to relax, ideal for corporate events, weddings, or family outings."
          },
          {
            "title": "2. Premium Upholstery",
            "description": "Experience sophistication with high-quality, plush upholstery that enhances both comfort and style for a refined and indulgent travel experience."
          },
          {
            "title": "3. Advanced Climate Control",
            "description": "Equipped with cutting-edge climate control, the Force Urbania maintains optimal temperatures throughout the journey for passenger comfort."
          },
          {
            "title": "4. State-of-the-Art Entertainment System",
            "description": "Featuring modern entertainment options such as LED TVs and a premium sound system, ensuring an enjoyable and engaging trip."
          },
          {
            "title": "5. Plush Reclining Seats",
            "description": "Enjoy maximum comfort with reclining seats that provide generous legroom, making long journeys more enjoyable."
          },
          {
            "title": "6. Elegant Interior Design",
            "description": "The meticulous interior design of the Urbania exudes elegance with ambient lighting and stylish finishes."
          },
          {
            "title": "7. Ample Luggage Space",
            "description": "With spacious luggage compartments, the Luxury Force Urbania can easily accommodate all your baggage."
          },
          {
            "title": "8. Advanced Safety Features",
            "description": "Modern safety features like seat belts for every passenger, advanced braking systems, and GPS tracking ensure a safe and secure journey."
          },
          {
            "title": "9. Smooth Ride Experience",
            "description": "Engineered to provide a stable, smooth ride, reducing road vibrations for a more comfortable journey."
          },
          {
            "title": "10. Professional Driver Services",
            "description": "Our experienced drivers are dedicated to offering the highest level of professionalism and care for a smooth journey."
          }
        ],
        
        "services": [
          {
            "name": "Luxury Force Urbania on Rent in Hadapsar",
            "description": "For those in Hadapsar, our Luxury Force Urbania is the ideal choice for group travel, offering style and comfort."
          },
          {
            "name": "13-Seater Force Urbania on Rent in Pune",
            "description": "Perfect for smaller groups, providing ample space and modern amenities for a comfortable journey."
          },
          {
            "name": "17-Seater Force Urbania on Rent in Pune",
            "description": "An ideal solution for larger groups, combining extra seating with luxury features."
          },
          {
            "name": "Urbania Hire in Pune for Outstation",
            "description": "Perfect travel solution for outstation trips, ensuring a smooth, luxurious ride."
          },
          {
            "name": "Pune to Lonavala Urbania Bus on Rent",
            "description": "Our Urbania buses provide a comfortable journey for day trips or extended stays."
          },
          {
            "name": "Pune to 5 Jyotirlinga Darshan Urbania on Rent",
            "description": "Perfect vehicle for your pilgrimage to the 5 Jyotirlinga temples, enhancing your spiritual journey."
          },
          {
            "name": "Pune Darshan Urbania on Rent",
            "description": "Explore Pune conveniently by renting an Urbania bus, perfect for city tours and sightseeing."
          },
          {
            "name": "Pune to Nashik Urbania on Rent",
            "description": "Smooth transport from Pune to Nashik, ideal for weddings or corporate trips."
          },
          {
            "name": "Pune to Bhimashankar Urbania Bus Hire",
            "description": "Luxury Urbania bus hire for trips to Bhimashankar Temple, ensuring a smooth and relaxing pilgrimage."
          },
          {
            "name": "Pune to Ajanta Ellora Urbania Bus Hire",
            "description": "Explore the breathtaking Ajanta and Ellora caves with our Urbania buses for long journeys."
          },
          {
            "name": "Luxury Force Urbania on Rent in Kothrud",
            "description": "Luxury Force Urbania rentals for various occasions in Kothrud, offering spacious interiors and premium features."
          },
          {
            "name": "Luxury Force Urbania on Rent in Pimpri Chinchwad",
            "description": "Luxury Urbania rentals for weddings, family outings, or corporate events in Pimpri Chinchwad."
          },
          {
            "name": "Contact Number for Luxury Force Urbania On Rent in Pune",
            "description": "For prompt and efficient service, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['- Luxury Force Urbania on Rent in Hadapsar', '- 13 Seater Force Urbania on Rent in Pune'],
            ['- 17 Seater Force Urbania on Rent in Pune', '- Urbania Hire in Pune for Outstation'],
            ['- Pune to Lonavala Urbania Bus on Rent', '- Pune to 5 Jyotirlinga Darshan Urbania on Rent'],
            ['- Pune Darshan Urbania on Rent', '- Pune to Nashik Urbania on Rent'],
            ['- Pune to Bhimashankar Urbania Bus Hire', '- Pune to Ajanta Ellora Urbania Bus Hire'],
            ['- Luxury Force Urbania on Rent in Pune Near Me', '- Luxury Force Urbania on Rent in Kothrud'],
            ['- Luxury Force Urbania on Rent in Pune Near Me Contact Number', '- Luxury Force Urbania on Rent in Pimpri Chinchwad']
        ],
        
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravel24 for Force Urbania Rentals?",
                "WhyChoosedescription": "At SSKTravel24, we provide luxurious travel experiences that prioritize comfort, safety, and reliability. Here’s why we stand out in the Force Urbania rental industry:"
            },
            {
                "WhyChooseheading": "Spacious and Comfortable Vehicles:",
                "WhyChoosedescription": "Experience the luxury of space with our Force Urbania buses, designed for comfort on every journey. With ample seating and modern amenities, you can relax whether you're on a quick trip or an extended journey."
            },
            {
                "WhyChooseheading": "Well-Maintained Fleet:",
                "WhyChoosedescription": "We pride ourselves on our meticulously maintained fleet. Each Force Urbania bus undergoes regular servicing and thorough inspections, ensuring peak performance and reliability for every ride."
            },
            {
                "WhyChooseheading": "Professional and Experienced Drivers:",
                "WhyChoosedescription": "Travel with peace of mind knowing our drivers are seasoned professionals. They are skilled, familiar with local routes, and dedicated to providing a safe, timely, and courteous travel experience."
            },
            {
                "WhyChooseheading": "Competitive and Transparent Pricing:",
                "WhyChoosedescription": "At SSKTravel24, we believe in clear pricing. Our rates for Force Urbania rentals are competitive and transparent, with no hidden fees—so you always know what to expect."
            },
            {
                "WhyChooseheading": "Customizable Travel Solutions:",
                "WhyChoosedescription": "We understand that every trip is unique. That’s why we offer tailored services to meet your specific travel needs, whether it’s a family outing, corporate event, or religious pilgrimage."
            },
            {
                "WhyChooseheading": "Excellent Customer Service:",
                "WhyChoosedescription": "From the moment you book until your journey concludes, our customer service team is here to assist you. We are committed to making your experience as hassle-free and enjoyable as possible."
            },
            {
                "WhyChooseheading": "Punctual and Reliable:",
                "WhyChoosedescription": "We recognize the importance of being on time. Our buses adhere to strict schedules to ensure timely departures and arrivals, giving you confidence in your travel plans."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What is included in the rental of a Force Urbania?",
            answer: "Your rental includes comfortable seating, air conditioning, and professional driver services. Additional amenities like audio systems and Wi-Fi can be arranged upon request."
        },
        {
            question: "How do I book a Force Urbania with SSKTravels24?",
            answer: "Booking is easy! Contact us via phone, email, or fill out our online form with your travel details, including dates, pick-up and drop-off locations, and any special requirements."
        },
        {
            question: "Are there any additional charges I should be aware of?",
            answer: "While we maintain transparent pricing, additional charges may apply for extra services or requests. We provide a detailed quote before finalizing your booking."
        },
        {
            question: "What is the cancellation policy for a Force Urbania rental?",
            answer: "Cancellation policies vary, but generally, early cancellations incur a nominal fee, while last-minute changes may involve higher charges. For specifics, please contact customer service."
        },
        {
            question: "Can I request a specific type of Force Urbania?",
            answer: "Absolutely! You can request particular features or types of Urbania buses based on availability. We offer various options to accommodate different needs."
        },
        {
            question: "Are the drivers experienced and licensed?",
            answer: "Yes, all our drivers are professionally trained, experienced, and possess valid licenses, ensuring a safe and comfortable travel experience."
        },
        {
            question: "Can I make changes to my booking after it is confirmed?",
            answer: "Yes, changes can be made subject to availability and any applicable fees. Please reach out to our customer service team as soon as possible for assistance."
        },
        {
            question: "What should I do if I need assistance during my journey?",
            answer: "If you need help during your journey, contact your driver or our customer service team immediately. We are committed to addressing any concerns promptly."
        },
        {
            question: "How far in advance should I book a Force Urbania?",
            answer: "To secure your rental, we recommend booking as early as possible, especially during peak seasons or special events."
        },
        {
            question: "Are there any restrictions on luggage or other items?",
            answer: "Standard luggage allowances apply, but special arrangements can be made upon request. Please inform us of any specific needs at the time of booking."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rajesh Kumar",
            text: "Our experience with SSKTravels24 for a group trip using the Force Urbania was exceptional. The bus was not only luxurious but also impeccably maintained, ensuring a comfortable ride for everyone. Our driver was professional and attentive, navigating us safely to each destination on time. I highly recommend SSKTravels24 for anyone seeking premium travel experiences in Pune."
        },
        {
            name: "Ms. Priya Mehta",
            text: "We rented a Force Urbania for a corporate event, and the service from SSKTravels24 was outstanding. The bus was incredibly spacious and came equipped with all the modern amenities we needed, making our journey enjoyable and stress-free. The booking process was seamless, and our driver was well-versed in the best routes, ensuring we arrived punctually. SSKTravels24 truly exceeded our expectations, and we’ll definitely be using their services again."
        }
    ];
    
    const contactData = {
        heading: "Luxury Force Urbania Rentals in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@SSKtravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Luxury Force Urbania On Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent Luxury Force Urbania buses in Pune for local and outstation trips. Competitive rates for 13 and 17 seater options. Book your Urbania today!" />
  <meta name="keywords" content="Luxury Force Urbania on rent in Pune | Luxury Force Urbania on rent in Hadapsar | 13 seater Force Urbania on rent in Pune | 17 seater Force Urbania on rent in Pune | Urbania hire in Pune for outstation | Pune to Lonavala Urbania bus on rent | Pune to 5 Jyotirlinga darshan Urbania on rent | Pune Darshan Urbania on rent | Pune to Nashik Urbania on rent | Pune to Bhimashankar Urbania bus hire | Pune to Ajanta Ellora Urbania bus hire | luxury Force Urbania on rent in Pune near me | luxury Force Urbania on rent in Kothrud | luxury Force Urbania on rent in Pimpri Chinchwad" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Luxury Force Urbania On Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for Luxury Force Urbania in Pune. Enjoy competitive rates for local and outstation trips. Contact us to book your Urbania!" />
  <meta property="og:url" content="https://https://ssktravels24.com//luxury-force-urbania-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//luxury-force-urbania-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-17.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default LuxuryForceUrbaniaonRentinPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function CarsonRentinPune7Seater(){


    const cardData = 
    {
        keyword: '7 Seater Cars on Rent in Pune',
        heading: 'SSKTravels24: 7 Seater Cars on Rent in Pune',
        headingDescription: 'Planning group travel in Pune? SSKTravels24 offers a range of spacious, well-maintained 7-seater vehicles perfect for family trips, corporate outings, and weekend getaways. Discover the top 7-seater cars available for rent and our services for your travel needs.',
        
        topPlaces: [
          {
            title: "1. Toyota Innova Crysta",
            description: "The Toyota Innova Crysta is a popular choice for its spacious interior, comfort, and powerful performance. It’s perfect for long journeys and can accommodate up to seven passengers with ample luggage space."
          },
          {
            title: "2. Mahindra XUV500",
            description: "Known for its rugged design and powerful engine, the Mahindra XUV500 is great for both urban and off-road travel. Its spacious cabin ensures a comfortable ride for all passengers."
          },
          {
            title: "3. Maruti Suzuki Ertiga",
            description: "The Maruti Suzuki Ertiga offers a perfect blend of comfort and fuel efficiency. Its flexible seating arrangement makes it an excellent choice for families looking for both space and economy."
          },
          {
            title: "4. Honda BR-V",
            description: "The Honda BR-V combines style with functionality. With its spacious interior and advanced features, it’s a great option for both city driving and longer trips."
          },
          {
            title: "5. Renault Triber",
            description: "The Renault Triber stands out with its modular seating and compact size. It’s perfect for city travel and is equipped with features that enhance comfort and convenience."
          },
          {
            title: "6. Nissan Evalia",
            description: "The Nissan Evalia is designed for maximum comfort. Its spacious interior and user-friendly features make it an ideal choice for families or groups traveling together."
          },
          {
            title: "7. Tata Hexa",
            description: "The Tata Hexa offers a premium feel with its comfortable seats and spacious cabin. It’s perfect for long-distance travel and provides a smooth driving experience."
          },
          {
            title: "8. Kia Carens",
            description: "With modern design and advanced technology, the Kia Carens offers a stylish and comfortable ride. It comes with ample space and entertainment options for passengers."
          },
          {
            title: "9. Mahindra Marazzo",
            description: "The Mahindra Marazzo is known for its robust build and spacious interior. It’s perfect for those who need a reliable vehicle for group travel."
          },
          {
            title: "10. Ford EcoSport",
            description: "Though slightly smaller, the Ford EcoSport is a versatile option for urban travel. Its compact size makes it easy to navigate through city traffic while still providing enough room for passengers."
          }
        ],
        
        services: [
          {
            name: "7 Seater Innova on Rent in Pune",
            description: "The Toyota Innova is a top choice for group travel, known for its comfort and reliability. Renting an Innova from us guarantees a smooth ride for you and your companions."
          },
          {
            name: "7 Seater Ertiga Car on Rent in Pune",
            description: "For a more budget-friendly option, the Maruti Suzuki Ertiga provides excellent space and comfort. Perfect for family outings, it's a versatile choice for your rental needs."
          },
          {
            name: "Luxury Car Rental in Pune",
            description: "For those looking for a touch of elegance, our luxury car rental options include premium 7-seater vehicles that are perfect for weddings or special events."
          },
          {
            name: "7 Seater Self Drive Car on Rent in Pune",
            description: "Prefer to drive yourself? We offer self-drive options for our 7-seater cars, giving you the freedom to explore Pune and its surroundings at your own pace."
          },
          {
            name: "7 Seater Car on Rent in Pimpri Chinchwad",
            description: "Our services extend to Pimpri Chinchwad, providing convenient access to 7-seater rentals in this bustling area."
          },
          {
            name: "Pune to Mahabaleshwar 7 Seater Car Rental",
            description: "Planning a trip to Mahabaleshwar? Our 7-seater cars are perfect for the journey, ensuring a comfortable ride for your group."
          },
          {
            name: "Pune to Shirdi 7 Seater on Rent",
            description: "Make your pilgrimage to Shirdi comfortable with our 7-seater rentals, available at competitive rates and inclusive of packages."
          },
          {
            name: "Pune to Mumbai 7 Seater Cab on Rent",
            description: "Traveling to Mumbai? Our 7-seater cab service is designed to provide a hassle-free journey, equipped with all the amenities you need."
          },
          {
            name: "7 Seater Fortuner on Rent in Pune",
            description: "For those who want luxury and power, our Fortuner rental offers an exceptional driving experience. Ideal for long trips and special occasions."
          },
          {
            name: "7 Seater Car on Rent in Pune Contact Number",
            description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient 7 Seater Car on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
          }
        ],
        tableData: [
            ['- 7 Seater Car for Rent in Pune', '- 7 Seater Car Rental Pune'],
            ['- 7 Seater Cars Pune Rent', '- 7 Seater Innova on Rent in Pune'],
            ['- 7 Seater Self Drive Car on Rent in Pune', '- 7 Seater Innova on Rent in Pune'],
            ['- 7 Seater Ertiga Car on Rent in Pune', '- 7 Seater Car on Rent in Pimpri Chinchwad'],
            ['- Pune to Mahabaleshwar 7 Seater Car Rental', '- Pune to Shirdi 7 Seater on Rent'],
            ['- Pune to Mumbai 7 Seater Cab on Rent', '- 7 Seater Kia Carens on Rent in Pune'],
            ['- 7 Seater Fortuner on Rent in Pune', '- Luxury Car Rental Pune'],
            ['- Luxury Car Rentals in Pimpri Chinchwad', '- Wedding Car Rental Pune'],
            ['- 7 Seater Ertiga on Rent in Pune', '- SSK Travels 24']
          ],
          "whychoose": [
            {
              "WhyChooseheading": "Why Choose Us for 7 Seater Car on Rent in Pune?",
              "WhyChoosedescription": "At SSKTravels24, we prioritize your comfort and convenience for all group travel needs. Here’s why you should choose us for your 7-seater car rental in Pune:"
            },
            {
              "WhyChooseheading": "Diverse Fleet:",
              "WhyChoosedescription": "We offer a wide range of 7-seater vehicles, including Innova, Ertiga, and luxury options, catering to various travel needs."
            },
            {
              "WhyChooseheading": "Affordable Pricing:",
              "WhyChoosedescription": "Our competitive rates ensure you get the best value without any hidden fees. Enjoy transparent pricing that fits your budget."
            },
            {
              "WhyChooseheading": "Comfort and Safety:",
              "WhyChoosedescription": "All our vehicles are well-maintained and equipped with modern amenities to ensure a comfortable and safe journey."
            },
            {
              "WhyChooseheading": "Experienced Drivers:",
              "WhyChoosedescription": "Our professional drivers are knowledgeable and committed to providing you with a smooth travel experience, ensuring timely arrivals and departures."
            },
            {
              "WhyChooseheading": "Flexible Booking Options:",
              "WhyChoosedescription": "We provide easy and flexible booking processes, allowing you to rent a car for a day, a weekend, or longer durations based on your needs."
            },
            {
              "WhyChooseheading": "Customer-Centric Service:",
              "WhyChoosedescription": "Our dedicated customer service team is available to assist you at every step, ensuring a hassle-free rental experience."
            },
            {
              "WhyChooseheading": "Convenient Locations:",
              "WhyChoosedescription": "We offer pickup and drop services across Pune, making it easy for you to access your rental vehicle."
            }
          ]
    };    

    
   
    const faqData = [
        {
          question: "What types of 7-seater cars are available for rent?",
          answer: "We offer a variety of 7-seater vehicles, including Toyota Innova, Maruti Ertiga, and luxury options like Fortuner."
        },
        {
          question: "How do I book a 7-seater car?",
          answer: "You can book online through our website or contact our customer service team for assistance with your reservation."
        },
        {
          question: "What are the rental rates for 7-seater cars?",
          answer: "Our rates vary depending on the vehicle model and rental duration. Contact us for detailed pricing information."
        },
        {
          question: "Are there any hidden charges?",
          answer: "No, we maintain transparency in our pricing. You will be informed of all charges upfront before confirming your booking."
        },
        {
          question: "Can I hire a self-drive 7-seater car?",
          answer: "Yes, we offer self-drive options for our 7-seater cars. Ensure you meet the rental requirements for self-driving."
        },
        {
          question: "What is the process for pickup and drop-off?",
          answer: "You can choose your preferred pickup and drop-off locations in Pune, and our team will ensure timely service."
        },
        {
          question: "Are the cars insured?",
          answer: "Yes, all our rental vehicles are fully insured for your safety and peace of mind during the journey."
        },
        {
          question: "What should I do in case of a breakdown?",
          answer: "In the unlikely event of a breakdown, contact our customer service immediately, and we will assist you promptly."
        },
        {
          question: "How far in advance should I book?",
          answer: "We recommend booking as early as possible, especially during peak seasons, to ensure vehicle availability."
        },
        {
          question: "Do you offer long-term rentals?",
          answer: "Yes, we provide flexible rental options for long-term needs. Contact us for special rates on extended rentals."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Vikram Patil",
          text: "We rented a 7-seater Innova from SSKTravels24 for a family trip to Mahabaleshwar, and it was a fantastic experience! The car was in excellent condition, clean, and very comfortable for all of us. The booking process was smooth, and the customer service team was incredibly helpful. Our driver was punctual and very knowledgeable about the area. I highly recommend SSKTravels24 for anyone looking for a reliable car rental service in Pune!"
        },
        {
          name: "Miss Neha Joshi",
          text: "I recently hired a 7-seater Ertiga for a corporate event, and I couldn't be happier with the service provided by SSKTravels24. The vehicle was spacious and comfortable, making our journey enjoyable. The driver was professional and ensured we arrived at our destination on time. Plus, the pricing was very reasonable. I will definitely use SSKTravels24 again for our future transportation needs!"
        }
      ];
      
      const contactData = {
        heading: "Contact SSKTravels24 for 7 Seater Car on Rent in Pune",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 7 Seater Car on Rent in Pune | Affordable & Convenient</title>
  <meta name="description" content="Rent a 7 seater car in Pune with SSK Travels24. Choose from Innova, Ertiga, and more for comfortable travel. Book now!" />
  <meta name="keywords" content="7 seater car on rent in Pune, 7 seater car rental Pune, Innova rent Pune, Ertiga rental Pune, luxury car rental Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 7 Seater Car on Rent in Pune | Affordable & Convenient" />
  <meta property="og:description" content="Explore our range of 7 seater cars for rent in Pune. Ideal for family trips, weddings, and outstation travel." />
  <meta property="og:url" content="https://https://ssktravels24.com//7-seater-car-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//7-seater-car-rent-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-18.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default CarsonRentinPune7Seater ;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus, faHandshake, faUser, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';

const Career = ({ icon, title, description }) => {
    return (
        <div className="col-lg-6 mb-4">
            <a href={`tel:+918956170493`} className="text-decoration-none">
                <div className="career-item p-4 shadow rounded">
                    <div className="career-icon mb-3 text-center">
                        <FontAwesomeIcon icon={icon} size="3x" className="text-primary" />
                    </div>
                    <h4 className="career-title text-center">{title}</h4>
                    <p>{description}</p>
                </div>
            </a>
        </div>
    );
};

const CareersSection = () => {
    return (
        <div>
            <GoogleAnalytics/>
            <Helmet>
                <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
                <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
                <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
                <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
                <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
                <meta property="og:url" content="https://ssktravels24.com/bus-on-rent-in-pune" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://ssktravels24.com/pune-goa-bus-image.jpg" />
            </Helmet>
            
            <section id="about" className="jarallax text-light">
                <div className="center-y relative text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>Career</h1>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section aria-label="Careers" className="bt py-5 careers-section">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-12 text-center mb-5">
                        </div>
                        <Career
                            icon={faBus}
                            title="Bus Driver"
                            description="Join SSK Travels24 as a driver to provide safe, comfortable, and reliable transportation services for all travelers."
                        />
                        <Career
                            icon={faHandshake}
                            title="Bus Partner"
                            description="Partner with us to collaborate with a well-respected name in the travel industry, delivering exceptional services."
                        />
                        <Career
                            icon={faUser}
                            title="Travel Agent"
                            description="Become an agent to promote our services and facilitate bookings, enhancing customer satisfaction."
                        />
                        <Career
                            icon={faDesktop}
                            title="Back Office"
                            description="Join our back office team to ensure seamless operations and help maintain high service standards."
                        />
                    </div>
                </div>
            </section>
            <section aria-label="section " style={{backgroundColor:'#913923'}} >
        <div className="container">
          <div className="row g-custom-x ">
            <div className="col-lg-4 mb-sm-30">
              <div className="de-box mb30 coloredd">
                <h4 className='text-white'>Contact Numbers</h4>
                <address className="s1">
                  <span><i className="fa fa-phone fa-lg" style={{color:'#913923'}}></i>+91 8956170493</span>
                  <span><i className="fa fa-phone fa-lg" style={{color:'#913923'}}></i>+91 7276307000</span>
                </address>
              </div>
            </div>

            <div className="col-lg-4 mb-sm-30">
              <div className="de-box mb30 coloredd">
                <h4 className='text-white'>Email</h4>
                <address className="s1">
                  <span><i className="fas fa-envelope" style={{color:'#913923'}}></i>
                  <a href="mailto:booking@ssktravels24.com" className='text-white'>booking@ssktravels24.com</a></span>
                </address>
              </div>
            </div>
            
            <div className="col-lg-4 ">
              <div className="de-box mb30 coloredd">
                <h4 className='text-white'>Address</h4>
                <address className="s1">
                  <span><i className="id-color fa fa-map-marker fa-lg" style={{color:'#913923'}}></i>office No 109, Ashoka Plaza, First Floor, Vimannagar Pune, Maharashtra 411014</span>
                  
                 
                </address>
              </div>
            </div>
          </div>
        </div>
      </section>


            <style jsx>{`
                .page-header {
                    // background: linear-gradient(to right, #007bff, #0056b3);
                    color: white;
                    padding: 60px 0;
                    text-align: center;
                }

                .page-header h2 {
                    font-size: 2.5rem;
                    font-weight: bold;
                }

                .career-item {
                    // transition: transform 0.2s, background-color 0.3s;
                    border-radius: 0.5rem; /* Rounded corners */
                    background-color: transparent; /* No background color initially */
                }

                .career-item:hover {
                    transform: translateY(-5px);
                    background-color: #913923;
                    color: white; 
                    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
                }

                .career-title {
                    font-weight: bold;
                    margin-top: 10px;
                }

                .section-title {
                    font-size: 2rem;
                    font-weight: bold;
                }

                .section-subtitle {
                    font-size: 1.2rem;
                    color: #6c757d;
                }

                .careers-section {
                    position: relative;
                    overflow: hidden;
                    background: rgba(255, 255, 255, 0.8);
                }

                .careers-section::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    // background: rgba(0, 123, 255, 0.2);
                    backdrop-filter: blur(10px);
                    z-index: 0;
                }

                .career-item {
                    position: relative;
                    z-index: 1;
                }
            `}</style>
        </div>
    );
};

export default CareersSection;

























// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBus, faHandshake, faUser, faDesktop } from '@fortawesome/free-solid-svg-icons';
// import { Helmet } from 'react-helmet'
// const Career = ({ icon, title, description }) => {
//     return (
//         <div className="col-lg-6 mb-4">
//             <div className="career-item p-4 shadow rounded">
//                 <div className="career-icon mb-3 text-center">
//                     <FontAwesomeIcon icon={icon} size="3x" className="text-primary" />
//                 </div>
//                 <h4 className="career-title text-center">
//                     <a href="#" className="text-dark">{title}</a>
//                 </h4>
//                 <p>{description}</p>
//             </div>
//         </div>
//     );
// };

// const CareersSection = () => {
//     return (
//         <div>

// <Helmet>
//   <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
//   <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
//   <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
//   <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
//   <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
//   <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
//   <meta property="og:type" content="website" />
//   <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
// </Helmet>
            
// <section id="about" className="jarallax text-light">
//             <div className="center-y relative text-center">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12 text-center">
//                             <h1>Carrer</h1>
//                         </div>
//                         <div className="clearfix"></div>
//                     </div>
//                 </div>
//             </div>
//         </section>


//             <section aria-label="Careers" className="bt py-5 careers-section">
//                 <div className="container">
//                     <div className="row g-4">
//                         <div className="col-12 text-center mb-5">
//                             <h2 className="section-title">Join Our Team</h2>
//                             <p className="section-subtitle">
//                                 Explore the exciting opportunities to be a part of SSK Travels24.
//                             </p>
//                         </div>
//                         <Career
//                             icon={faBus}
//                             title="Bus Driver"
//                             description="Join SSK Travels24 as a driver to provide safe, comfortable, and reliable transportation services for all travelers."
//                         />
//                         <Career
//                             icon={faHandshake}
//                             title="Bus Partner"
//                             description="Partner with us to collaborate with a well-respected name in the travel industry, delivering exceptional services."
//                         />
//                         <Career
//                             icon={faUser}
//                             title="Travel Agent"
//                             description="Become an agent to promote our services and facilitate bookings, enhancing customer satisfaction."
//                         />
//                         <Career
//                             icon={faDesktop}
//                             title="Back Office"
//                             description="Join our back office team to ensure seamless operations and help maintain high service standards."
//                         />
//                     </div>
//                 </div>
//             </section>

//             <style jsx>{`
//                 .page-header {
//                     background: linear-gradient(to right, #007bff, #0056b3);
//                     color: white;
//                     padding: 60px 0;
//                     text-align: center;
//                 }

//                 .page-header h2 {
//                     font-size: 2.5rem;
//                     font-weight: bold;
//                 }

//                 .career-item {
//                     transition: transform 0.2s, background-color 0.3s;
//                     border-radius: 0.5rem; /* Rounded corners */
//                     padding: 20px; /* Padding for content */
//                     background-color: transparent; /* No background color initially */
//                 }

//                 .career-item:hover {
//                     transform: translateY(-5px);
//                     background-color: #913923;
//                     color: white; 
//                     box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
//                 }

//                 .career-title {
//                     font-weight: bold;
//                     margin-top: 10px;
//                 }

//                 .section-title {
//                     font-size: 2rem;
//                     font-weight: bold;
//                 }

//                 .section-subtitle {
//                     font-size: 1.2rem;
//                     color: #6c757d;
//                 }

//                 .careers-section {
//                     position: relative;
//                     overflow: hidden;
//                     background: rgba(255, 255, 255, 0.8);
//                 }

//                 .careers-section::before {
//                     content: '';
//                     position: absolute;
//                     top: 0;
//                     left: 0;
//                     width: 100%;
//                     height: 100%;
//                     background: rgba(0, 123, 255, 0.2);
//                     backdrop-filter: blur(10px);
//                     z-index: 0;
//                 }

//                 .career-item {
//                     position: relative;
//                     z-index: 1;
//                 }
//             `}</style>
//         </div>
//     );
// };

// export default CareersSection;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function AirportCabServicesinPune(){


    const cardData = 
    {
        keyword: 'Airport Cab Services in Pune',
        heading: 'SSKTravels24: Airport Cab Services in Pune',
        headingDescription: 'When it comes to hassle-free airport transportation, SSKTravels24 stands out as the leading choice for airport cab services in Pune. Whether you’re arriving in Pune or heading to the airport for a flight, our reliable and efficient services ensure a smooth travel experience.',
        
        topPlaces: [
          {
            title: "1. Seamless Booking Experience",
            description: "Our user-friendly online platform allows you to book your airport cab in just a few clicks, making the process quick and efficient."
          },
          {
            title: "2. Affordable Rates",
            description: "We offer competitive pricing for our airport cab services, providing you with great value for your money without compromising on quality."
          },
          {
            title: "3. Local Expertise",
            description: "Our drivers are familiar with all routes leading to Pune Airport, ensuring you take the best path and avoid traffic delays."
          },
          {
            title: "4. Family-Friendly Options",
            description: "Traveling with family? Our spacious vehicles are perfect for families, ensuring everyone travels comfortably."
          },
          {
            title: "5. Pick-Up and Drop Services",
            description: "We provide comprehensive services, including pick-up and drop-off at both Pune Airport and various locations in the city."
          },
          {
            title: "6. Booking Flexibility",
            description: "Whether you need a last-minute cab or prefer to schedule your ride in advance, we accommodate all types of travel plans."
          },
          {
            title: "7. Safe and Secure Travel",
            description: "Enjoy a secure travel experience with SSKTravels24, where passenger safety is our top priority."
          },
          {
            title: "8. Loyalty Benefits",
            description: "Frequent travelers can benefit from our loyalty programs, offering discounts and special deals on repeated bookings."
          },
          {
            title: "9. Multi-Lingual Drivers",
            description: "Our drivers can communicate in multiple languages, making it easier for all passengers to feel comfortable and understood."
          },
          {
            title: "10. Positive Travel Experience",
            description: "We aim to make your airport transfers as pleasant as possible, ensuring a stress-free start or end to your journey."
          }
        ],
      
        services: [
          {
            name: "Pune to Shirdi Cab One Way",
            description: "Our one-way cab service from Pune to Shirdi provides convenience and flexibility, ensuring a comfortable ride in well-maintained vehicles."
          },
          {
            name: "Pune to Mumbai Airport Cab Fare",
            description: "We offer a cost-effective solution for your airport transfers, with competitive pricing that includes no hidden charges."
          },
          {
            name: "Pune Mumbai Airport Drop",
            description: "Our reliable service ensures comfortable drop-offs at Mumbai Airport, allowing you to arrive well in advance for your flight."
          },
          {
            name: "Pune to Shirdi Taxi Drop",
            description: "Planning a trip to Shirdi? Our flexible booking options make it easy to enjoy your pilgrimage."
          },
          {
            name: "Pune Airport to Shirdi Cab",
            description: "Direct pickups from Pune Airport ensure a smooth transition from your flight to your destination."
          },
          {
            name: "Pune Mumbai Cab Service",
            description: "Catering to both business and leisure travelers, our cab service offers a variety of vehicle options for your journey."
          },
          {
            name: "Airport Taxi Pune",
            description: "SSKTravels24 is your go-to choice for airport taxis in Pune, equipped with modern amenities for a pleasant ride."
          },
          {
            name: "Book Airport Cab Pune",
            description: "Visit our website or app to book your airport cab in just a few clicks, offering various vehicle choices to suit your travel preferences."
          },
          {
            name: "Airport Drop Pune",
            description: "Our airport drop service prioritizes efficiency and comfort, ensuring you arrive on time for your flight."
          },
          {
            name: "Airport Taxi Service in Pune",
            description: "Comprehensive airport taxi services focusing on customer satisfaction, ensuring a reliable and comfortable experience for every passenger."
          },
          {
            name: "Fortuner on Rent in Pune Airport",
            description: "For a premium travel experience, our Fortuner on rent provides style and comfort, ideal for business travelers or special occasions."
          },
          {
            name: "Urbania on Rent in Pune Airport",
            description: "Choose our Urbania on rent for spacious and comfortable rides, equipped with modern features for larger groups."
          },
          {
            name: "Innovacryta on Rent in Pune Airport",
            description: "Combining luxury and functionality, our Innovacryta is perfect for corporate travel and family outings."
          },
          {
            name: "Fortuner Hire in Pune with Drive",
            description: "Opt for Fortuner hire with a driver for a stress-free travel experience, allowing you to relax during your journey."
          },
          {
            name: "Contact Number for Airport Cab Services in Pune",
            description: "For prompt and efficient Airport Cab Services, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['Pune to Shirdi Cab One Way', 'Pune to Mumbai Airport Cab Fare'],
            ['Pune to Mumbai Airport', 'Pune Mumbai Airport Drop'],
            ['Pune to Shirdi Taxi Drop', 'Pune Airport to Shirdi Cab'],
            ['Pune Mumbai Cab Service', 'Airport Taxi Pune'],
            ['Pune Airport Taxi', 'Airport Cabs Pune'],
            ['Airport Drop Pune', 'Airport Cab Service Pune'],
            ['Airport Taxi Service in Pune', 'Book Airport Cab Pune'],
            ['Book Cab for Pune Airport', 'Cab for Airport Drop in Pune'],
            ['Pune Airport Cab Booking', 'Pune Airport Drop Cab'],
            ['Pune Airport Drop', 'Pune Airport Drop Cab Service'],
            ['Pune Airport Drop Charges', 'Fortuner on Rent in Pune Airport'],
            ['Urbania on Rent in Pune Airport', 'Innovacryta on Rent in Pune Airport'],
            ['Fortuner Hire in Pune with Drive']
          ],
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for Airport Cab Services in Pune - SSKTravels24?",
              WhyChoosedescription: "When it comes to reliable airport cab services in Pune, SSKTravels24 stands out for several reasons:"
            },
            {
              WhyChooseheading: "Punctuality:",
              WhyChoosedescription: "We understand the importance of timely arrivals, especially when it comes to catching flights. Our drivers are trained to ensure you reach your destination on time."
            },
            {
              WhyChooseheading: "Comfortable Rides:",
              WhyChoosedescription: "Our fleet consists of well-maintained vehicles equipped with modern amenities to ensure a comfortable journey. Whether you're traveling alone or with family, we have the right vehicle for you."
            },
            {
              WhyChooseheading: "Transparent Pricing:",
              WhyChoosedescription: "At SSKTravels24, we believe in transparent pricing. You can expect clear and competitive rates with no hidden charges, allowing for better budgeting of your travel expenses."
            },
            {
              WhyChooseheading: "Professional Drivers:",
              WhyChoosedescription: "Our drivers are experienced and knowledgeable about the best routes. They prioritize your safety and comfort throughout the journey."
            },
            {
              WhyChooseheading: "Flexible Booking Options:",
              WhyChoosedescription: "Booking your airport cab is simple and flexible. You can book online, via our app, or through a quick phone call, making it convenient for you."
            },
            {
              WhyChooseheading: "24/7 Availability:",
              WhyChoosedescription: "We operate round-the-clock, so you can rely on us for early morning or late-night flights. Our services are available whenever you need them."
            },
            {
              WhyChooseheading: "Excellent Customer Support:",
              WhyChoosedescription: "Our customer service team is always ready to assist you with any queries or special requests, ensuring a hassle-free travel experience."
            }
          ]
    };    

    
    const faqData = [
        {
          question: "How can I book an airport cab with SSKTravels24?",
          answer: "You can book your cab online through our website, via our mobile app, or by calling our customer service team."
        },
        {
          question: "What types of vehicles do you offer for airport transfers?",
          answer: "We offer a variety of vehicles including sedans, SUVs, and luxury cars to suit different travel needs."
        },
        {
          question: "Is there a cancellation policy?",
          answer: "Yes, we have a cancellation policy in place. Please check our website or contact customer service for detailed information."
        },
        {
          question: "How are your cab fares calculated?",
          answer: "Our fares are based on distance, vehicle type, and any additional services requested. We provide clear pricing with no hidden charges."
        },
        {
          question: "Do you provide service for late-night or early morning flights?",
          answer: "Absolutely! We operate 24/7 to accommodate flights at any time of the day or night."
        },
        {
          question: "Are your drivers experienced and verified?",
          answer: "Yes, all our drivers are experienced, licensed, and undergo background checks to ensure your safety."
        },
        {
          question: "Can I request a specific vehicle for my airport transfer?",
          answer: "Yes, you can specify your vehicle preference when booking your cab, subject to availability."
        },
        {
          question: "What should I do if my flight is delayed?",
          answer: "Our drivers monitor flight schedules and will adjust their pickup times accordingly to ensure you are not left waiting."
        },
        {
          question: "Is there a waiting charge if I take longer at the airport?",
          answer: "Yes, we have a waiting charge policy, which is clearly outlined during the booking process."
        },
        {
          question: "How do I pay for the cab service?",
          answer: "You can pay via cash, credit/debit card, or through online payment options, depending on your preference."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Suresh Patil",
          text: "I had an excellent experience with SSKTravels24 for my airport transfer in Pune. The cab was clean and well-maintained, and my driver arrived right on time. He was very professional and took the fastest route to the airport, ensuring I reached with plenty of time before my flight. I highly recommend their services for anyone needing reliable airport transportation!"
        },
        {
          name: "Ms. Anjali Deshmukh",
          text: "SSKTravels24 made my trip to Pune so much easier with their airport cab service. Booking was straightforward, and the customer support was very helpful. The driver was courteous and even helped with my luggage. The ride was comfortable and stress-free. I will definitely use their services again for my future travels!"
        }
      ];
      
      const contactData = {
        heading: "Contact Us for Airport Cab Services in Pune",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Airport Cab Services in Pune | Reliable & Affordable</title>
  <meta name="description" content="Book reliable airport cab services in Pune with SSK Travels24. Affordable fares for Mumbai Airport, Shirdi, and more!" />
  <meta name="keywords" content="Pune to Mumbai airport cab, Pune airport taxi, airport cab services in Pune, Pune to Shirdi cab, book airport cab Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Airport Cab Services in Pune | Reliable & Affordable" />
  <meta property="og:description" content="Discover affordable and reliable airport cab services in Pune. Easy booking for airport drops to Mumbai and Shirdi." />
  <meta property="og:url" content="https://https://ssktravels24.com//airport-cab-services-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//airport-cab-services-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-20.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default AirportCabServicesinPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Minibusonrent(){






    const cardData = 
    {
      keyword: 'Mini Bus on Rent in Pune',
      heading: 'SSKTravels24: Mini Bus on Rent in Pune',
      headingDescription: 'When it comes to group travel in Pune, renting a mini bus from SSKTravels24 offers an unbeatable combination of comfort, convenience, and efficiency. Whether you’re planning a corporate event, family outing, or school trip, our mini buses are designed to cater to your needs with a range of top-notch features.',
      
      topPlaces: [
          {
              title: "1. Spacious Interior",
              description: "Our mini buses are designed with a spacious interior to provide ample room for passengers. With comfortable seating arrangements and generous legroom, your journey will be pleasant and relaxed."
          },
          {
              title: "2. Climate-Controlled Environment",
              description: "Our mini buses come equipped with advanced air-conditioning systems to maintain a comfortable climate throughout your journey, ensuring a cool ride regardless of the weather."
          },
          {
              title: "3. Modern Entertainment Systems",
              description: "Features like LED TVs, high-quality audio systems, and Bluetooth connectivity keep passengers entertained, making long trips more engaging."
          },
          {
              title: "4. Ample Luggage Space",
              description: "Our mini buses feature ample luggage compartments to accommodate all your belongings, ensuring everything is securely stored."
          },
          {
              title: "5. Enhanced Safety Features",
              description: "Equipped with modern safety features like seat belts for every passenger, emergency exits, fire extinguishers, and first-aid kits, our mini buses ensure a safe travel experience."
          },
          {
              title: "6. Professional and Experienced Drivers",
              description: "Driven by professional and experienced drivers, our mini buses ensure punctuality and smooth navigation, allowing you to focus on your journey."
          },
          {
              title: "7. Well-Maintained Vehicles",
              description: "Each vehicle undergoes regular servicing and thorough inspections to ensure it is in optimal condition, with cleanliness and hygiene as top priorities."
          },
          {
              title: "8. Flexible Rental Options",
              description: "Our mini bus rental services offer flexible options tailored to your specific needs, whether you require the vehicle for a few hours or several days."
          },
          {
              title: "9. Competitive and Transparent Pricing",
              description: "We offer competitive pricing for our mini bus rentals with a transparent structure and no hidden costs."
          },
          {
              title: "10. Exceptional Customer Service",
              description: "From booking to the end of your journey, our customer service team is dedicated to providing exceptional support and ensuring a seamless travel experience."
          }
      ],
      
      services: [
          {
              name: "Mini Bus on Rent in Hadapsar, Pune",
              description: "Whether you’re organizing a family event or a local corporate function, our mini buses ensure convenience and comfort."
          },
          {
              name: "Mini AC Bus on Rent in Wanowrie, Pune",
              description: "For a pleasant journey, opt for our air-conditioned mini buses, ideal for maintaining a comfortable temperature."
          },
          {
              name: "Top Mini Bus on Rent in Kothrud, Pune",
              description: "Our top-rated mini buses in Kothrud offer spacious seating and advanced amenities for family outings and corporate events."
          },
          {
              name: "Mini Bus on Rent in Kharadi, Pune",
              description: "Rent a mini bus in Kharadi for seamless travel to meetings, events, or leisure activities."
          },
          {
              name: "Mini Bus on Rent in Kalyani Nagar, Pune",
              description: "Enjoy reliable and comfortable transportation for corporate events and social gatherings."
          },
          {
              name: "Mini Bus on Rent in Shivaji Nagar, Pune",
              description: "Choose from our range of options for family events or group outings."
          },
          {
              name: "Best Mini Bus on Rent in Baner, Pune",
              description: "Experience top-notch service with modern amenities for a comfortable and enjoyable ride."
          },
          {
              name: "Mini Bus on Rent for Corporate Events in Pune",
              description: "Provide professional and efficient travel for team outings."
          },
          {
              name: "Minibus Hire for Wedding Party in Pimpri Chinchwad",
              description: "Make your wedding celebrations memorable with our spacious and comfortable mini buses."
          },
          {
              name: "Minibus on Rent in Pune for Outstations",
              description: "Travel to outstation destinations with ease and comfort."
          },
          {
              name: "Pune to Shirdi Mini Bus on Rent",
              description: "Travel comfortably for your pilgrimage or visit with our mini bus rentals."
          },
          {
              name: "Mini Bus on Rent in Pune for Family",
              description: "Enjoy a smooth and enjoyable trip with ample space for family outings."
          },
          {
              name: "Mini Bus on Rent in Pune for Family with Driver",
              description: "Opt for our mini bus rentals with a professional driver for hassle-free travel."
          },
          {
              name: "Contact Number for Mini Bus on Rent in Pune",
              description: "For prompt and efficient mini bus rentals, contact SSKTravels24 at +91 8956170493."
          }
      ],    
      tableData: [
        ['- Mini Bus on Rent in Hadapsar Pune', '- Mini AC Bus on Rent in Wanowrie Pune'],
        ['- Top Mini Bus on Rent in Kothrud Pune', '- Mini Bus on Rent in Kharadi Pune'],
        ['- Mini Bus on Rent in Kalyani Nagar Pune', '- Mini Bus on Rent in Shivaji Nagar'],
        ['- Best Mini Bus on Rent in Baner Pune', '- Mini Bus on Rent for Corporate Events in Pune'],
        ['- Minibus Hire for Wedding Party in Pimpri Chinchwad', '- Minibus on Rent in Pune Outstations'],
        ['- Pune to Shirdi Mini Bus on Rent', '- Mini Bus on Rent in Pune for Family'],
        ['- Mini Bus on Rent in Pune for Family with Driver', '']
    ],
    
    "whychoose": [
        {
            "WhyChooseheading": "Why Choose SSKTravels24 for Mini Bus on Rent in Pune?",
            "WhyChoosedescription": "When it comes to renting a mini bus in Pune, SSKTravels24 is the top choice for individuals and groups seeking reliability, comfort, and excellent service. Here’s why:"
        },
        {
            "WhyChooseheading": "Comfortable and Spacious Vehicles:",
            "WhyChoosedescription": "Our mini buses are designed to provide maximum comfort with spacious seating and ample legroom. Whether you're planning a family outing, corporate event, or school trip, our mini buses ensure a pleasant journey for all passengers."
        },
        {
            "WhyChooseheading": "Modern Amenities:",
            "WhyChoosedescription": "We offer mini buses equipped with the latest amenities such as air-conditioning, high-quality sound systems, and entertainment options to make your travel experience enjoyable. Our vehicles are regularly maintained to ensure they are in pristine condition."
        },
        {
            "WhyChooseheading": "Experienced and Professional Drivers:",
            "WhyChoosedescription": "Our drivers are skilled professionals with extensive experience in navigating Pune and its surroundings. They are committed to providing a safe and smooth journey, ensuring timely arrivals and departures."
        },
        {
            "WhyChooseheading": "Affordable Pricing:",
            "WhyChoosedescription": "At SSKTravels24, we offer competitive and transparent pricing for mini bus rentals. We provide detailed quotes with no hidden charges, making sure you get the best value for your money."
        },
        {
            "WhyChooseheading": "Customized Solutions:",
            "WhyChoosedescription": "We understand that every trip is unique. Whether you need a mini bus for a corporate event, wedding, or a simple city tour, we offer customizable packages to fit your specific needs and preferences."
        },
        {
            "WhyChooseheading": "Excellent Customer Service:",
            "WhyChoosedescription": "From the moment you book with us until the end of your journey, our customer service team is dedicated to assisting you with any queries or special requests. We strive to make your experience as smooth and hassle-free as possible."
        },
        {
            "WhyChooseheading": "Punctual and Reliable:",
            "WhyChoosedescription": "We value your time and ensure that our mini buses adhere to strict schedules. Our commitment to punctuality means you can rely on us to get you to your destination on time, every time."
        },
        {
            "WhyChooseheading": "Well-Maintained Fleet:",
            "WhyChoosedescription": "Our fleet of mini buses is regularly inspected and maintained to ensure optimal performance. Cleanliness and safety are our top priorities, providing you with a worry-free travel experience."
        },
        {
            "WhyChooseheading": "Easy Booking Process:",
            "WhyChoosedescription": "Booking a mini bus with SSKTravels24 is simple and convenient. You can reach us via phone, email, or through our online booking system. Our team will handle all the details to make your booking process seamless."
        },
        {
            "WhyChooseheading": "Safety First:",
            "WhyChoosedescription": "Safety is our highest priority. All our mini buses are equipped with safety features such as seat belts, fire extinguishers, and first-aid kits. Our drivers are trained to handle any situation that might arise, ensuring a safe journey for everyone."
        }
    ]   
    };    

    
   
    const faqData = [
        {
            question: "How do I book a mini bus with SSKTravels24?",
            answer: "You can book a mini bus by contacting us directly via phone or email, or by using our easy-to-navigate online booking system. Our customer support team will assist you throughout the booking process."
        },
        {
            question: "What types of mini buses are available for rent?",
            answer: "We offer a range of mini buses, including 12-seaters, 15-seaters, 17-seaters, 20-seaters, 24-seaters, and 27-seaters. We also provide luxury options with advanced features for a premium experience."
        },
        {
            question: "Are the mini buses equipped with air conditioning?",
            answer: "Yes, all our mini buses come equipped with advanced air conditioning systems to ensure a comfortable environment, regardless of the weather outside."
        },
        {
            question: "Can I hire a mini bus for an outstation trip?",
            answer: "Absolutely. We offer mini bus rentals for outstation trips, including popular destinations like Goa, Lonavala, and Mahabaleshwar. Our drivers are well-versed with routes to ensure a smooth journey."
        },
        {
            question: "How much does it cost to rent a mini bus?",
            answer: "The cost of renting a mini bus varies depending on the type of vehicle, rental duration, and distance traveled. We provide transparent pricing with no hidden charges. Contact us for a detailed quote based on your specific requirements."
        },
        {
            question: "What is included in the rental price?",
            answer: "Our rental price typically includes the use of the mini bus, driver services, and basic amenities. Additional costs may apply for extra services such as extended mileage or special requests. We’ll provide a clear breakdown of costs when you book."
        },
        {
            question: "Are there any additional charges I should be aware of?",
            answer: "We strive to maintain transparent pricing. Any additional charges, such as for extra stops or extended travel beyond the agreed mileage, will be communicated to you upfront."
        },
        {
            question: "Can I choose the driver for my trip?",
            answer: "We assign experienced and professional drivers to ensure the highest quality service. While you can request specific preferences or requirements, we ensure that all our drivers meet our stringent standards of professionalism and safety."
        },
        {
            question: "What should I do if I need to cancel or modify my booking?",
            answer: "If you need to cancel or modify your booking, please contact our customer support team as soon as possible. We will assist you with the changes and inform you of any applicable cancellation or modification policies."
        },
        {
            question: "How can I contact SSKTravels24 for support during my journey?",
            answer: "You can reach our 24/7 customer support team by calling us or sending an email. We are here to assist you with any queries or issues that may arise during your journey to ensure a smooth and enjoyable experience."
        }
    ];
    
      

    const testimonials = [
      {
        name: "— Mr. Rajiv Kumar",
        text: "“We recently hired a mini bus from SSKTravels24 for a family reunion trip in Pune, and the experience was fantastic. The booking process was smooth, and the vehicle was delivered on time, clean, and well-maintained. The spacious interior and comfortable seating made our journey very enjoyable. The driver was courteous and professional, ensuring a safe and pleasant ride. We highly recommend SSKTravels24 for anyone in need of reliable and comfortable mini bus rental services in Pune.”"
      },
      {
        name: "— Ms. Anjali Mehta",
        text: "Our corporate team required a mini bus for an off-site meeting, and SSKTravels24 exceeded our expectations. The mini bus was perfect for our group, offering ample space and modern amenities. The driver was punctual and well-informed about the best routes, which made our trip seamless. The service was top-notch from start to finish, with competitive pricing and excellent customer support. We’ll definitely choose SSKTravels24 for our future group transportation needs in Pune."
      }
    ];
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: "Mini Bus On Rent in Pune Contact Number: ",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000",
         
        ],
        email: "booking@ssktravels24.com"
      };


      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Mini Bus On Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent a mini bus in Pune for various trips, including corporate events, weddings, and picnics. We offer competitive rates for 12 to 27-seater options. Book your mini bus today!" />
  <meta name="keywords" content="Mini bus on rent, Mini bus hire in Pune, Minibus hire with driver for outstation, Mini bus rentals for corporate trip, Small bus rental service in Pune, Minibus rental near me in Pune, Mini bus rent per km, Mini coach hire in Pune, Mini bus for rent near me in Pune, 12 seater mini bus on rent Pune, Mini bus on rent near me for outstation, Mini bus for Lonavala picnic from Pune, Mini bus hire for event in Pune, Pune to Goa mini bus, Mini bus hire in Pimpri Chinchwad, Mini bus hire in Kharadi Pune, Minibus hire for wedding party in Pune, Mini bus on rent in Hinjewadi, Mini bus on rent in Baner, Mini bus on rent in Pune near me, Luxury mini bus on rent in Pune near me, Mini bus on rent in Pune for family, 13 15 17 20 24 27 seater mini bus on rent in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Mini Bus On Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best mini bus rental services in Pune for corporate trips, events, and picnics. Enjoy competitive rates for various seating options. Contact us to book your mini bus!" />
  <meta property="og:url" content="https://https://ssktravels24.com//mini-bus-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//mini-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Mini Bus On Rent in Pune</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-04.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About Mini Bus On Rent in Pune with SSKTravels24:</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Minibusonrent;
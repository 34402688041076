
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function MumbaiAirporttoPuneTaxi(){


    const cardData = 
    {
        keyword: 'Mumbai Airport to Pune Taxi',
        heading: 'SSKTravels24: Mumbai Airport to Pune Taxi',
        headingDescription: 'Traveling from Mumbai Airport to Pune? Look no further than SSKTravels24 for a comfortable, reliable, and hassle-free taxi service. Whether you’re heading for business or leisure, our dedicated team ensures a smooth journey, allowing you to relax and enjoy the scenic beauty of Maharashtra.',
      
        topPlaces: [
          {
            title: "1. Aga Khan Palace",
            description: "A historical monument that played a significant role during the Indian freedom struggle. Its stunning architecture and beautiful gardens make it a must-visit."
          },
          {
            title: "2. Shaniwar Wada",
            description: "A historic fortification that was once the seat of the Peshwas of the Maratha Empire. The magnificent entrance and intriguing tales of its past draw many visitors."
          },
          {
            title: "3. Osho Ashram",
            description: "Located in Koregaon Park, this meditation resort is known for its tranquil ambiance and spiritual retreats, attracting seekers from around the world."
          },
          {
            title: "4. Pune Okayama Friendship Garden",
            description: "Inspired by the Korakuen Garden in Japan, this serene garden is perfect for a peaceful stroll amidst beautiful landscapes."
          },
          {
            title: "5. Sinhagad Fort",
            description: "A historic hill fort offering panoramic views of the Sahyadri mountains. It’s an excellent spot for trekking and learning about Maratha history."
          },
          {
            title: "6. National War Museum",
            description: "A tribute to the Indian Armed Forces, this museum showcases military artifacts, vehicles, and a gallery dedicated to the heroes of the nation."
          },
          {
            title: "7. Parvati Hill",
            description: "This hillock houses several temples and offers a breathtaking view of the city. Climbing the 103 steps to reach the top is worth the effort!"
          },
          {
            title: "8. Raja Dinkar Kelkar Museum",
            description: "A treasure trove of artifacts that reflect the rich cultural heritage of India. It features a vast collection of art, crafts, and everyday items from various eras."
          },
          {
            title: "9. Khadakwasla Dam",
            description: "A scenic spot perfect for picnics, with beautiful views of the water and surrounding hills. It’s a great place to unwind with family and friends."
          },
          {
            title: "10. Fergusson College",
            description: "An iconic institution known for its academic excellence. The beautiful campus and historic architecture are worth exploring."
          }
        ],
      
        services: [
          {
            name: "Mumbai Airport to Pune Taxi Booking",
            description: "Experience the convenience of booking a taxi directly from Mumbai Airport to Pune. Our service ensures you have a smooth transition from your flight to your destination."
          },
          {
            name: "Well-Maintained Cabs",
            description: "Our cabs are well-maintained and equipped with all necessary amenities, making your ride pleasant and comfortable."
          },
          {
            name: "Specialized Service from Terminal 2",
            description: "If you're arriving at Terminal 2, we have specialized services that ensure timely pickups, so you can head to Pune without any hassle."
          },
          {
            name: "Transparent Cab Fare",
            description: "We provide transparent pricing with no hidden charges. Get an estimate of the cab fare before you book your ride, allowing you to plan your budget effectively."
          },
          {
            name: "24/7 Taxi Availability",
            description: "Whether it’s for a business meeting or a family visit, our taxis are available 24/7 to cater to your needs."
          },
          {
            name: "Professional Drivers",
            description: "Our cab service guarantees professional drivers and a commitment to punctuality, ensuring you reach your destination on time."
          },
          {
            name: "Easy Booking Process",
            description: "With our user-friendly booking process, securing a cab from Mumbai Airport to Pune is quick and easy."
          },
          {
            name: "Safety and Comfort",
            description: "Experience top-notch service with our well-trained drivers who prioritize your safety and comfort during the journey."
          },
          {
            name: "Understanding Cab Charges",
            description: "Understanding the cab charges is easy with our transparent pricing structure. Know what to expect before you book."
          },
          {
            name: "Car Hire Services",
            description: "If you prefer to drive yourself, we also offer car hire services, including options for SUVs and sedans."
          }
        ],
        tableData: [
            ['Mumbai Airport to Pune Taxi', 'Mumbai Airport to Pune Cab'],
            ['Mumbai Airport Terminal 2 to Pune Cab', 'Mumbai Airport to Pune Cab Fare'],
            ['Mumbai to Pune Taxi from Airport', 'Cab Service from Mumbai Airport to Pune'],
            ['Mumbai Airport Pune Cab', 'Mumbai Airport Pune Taxi Service'],
            ['Mumbai Airport to Pune Cab Charges', 'Mumbai Airport to Pune Cab Service'],
            ['Mumbai Airport to Pune Cab Time', 'Mumbai Airport to Pune Car Hire'],
            ['Mumbai Airport to Pune Innova', 'Mumbai Airport to Pune Taxi Service'],
            ['Mumbai International Airport to Pune Cab Taxi', 'Mumbai Pune Airport Cab'],
            ['Pune Airport to Mumbai Cab', 'Pune to Mumbai Airport Cab Booking'],
            ['Pune to Mumbai Airport Taxi Fare', 'SSK Travels 24']
          ],
        
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for Mumbai Airport to Pune Taxi – SSKTravels24?",
              WhyChoosedescription: "When traveling from Mumbai Airport to Pune, SSKTravels24 stands out for numerous reasons that ensure a reliable and comfortable journey:"
            },
            {
              WhyChooseheading: "Reliable Service:",
              WhyChoosedescription: "We prioritize punctuality. Our drivers monitor flight schedules to ensure timely pickups, so you can relax knowing we’ll be there when you arrive."
            },
            {
              WhyChooseheading: "Comfort and Convenience:",
              WhyChoosedescription: "Our fleet of well-maintained vehicles ensures a smooth ride. Enjoy comfortable seating, ample legroom, and air conditioning, making your travel experience enjoyable."
            },
            {
              WhyChooseheading: "Professional Drivers:",
              WhyChoosedescription: "Our experienced drivers are not only skilled but also trained in customer service. They know the best routes and are dedicated to ensuring your safety and comfort."
            },
            {
              WhyChooseheading: "Transparent Pricing:",
              WhyChoosedescription: "We offer competitive and clear pricing with no hidden fees. You receive a detailed fare estimate upfront, helping you plan your budget effectively."
            },
            {
              WhyChooseheading: "24/7 Availability:",
              WhyChoosedescription: "Whether your flight lands early in the morning or late at night, our services are available around the clock. You can book your taxi whenever it suits you."
            },
            {
              WhyChooseheading: "Easy Online Booking:",
              WhyChoosedescription: "Our user-friendly online booking system allows you to reserve your taxi in just a few clicks. You can also reach out to our customer service for assistance."
            },
            {
              WhyChooseheading: "Customer Satisfaction:",
              WhyChoosedescription: "We value our customers and strive to provide the best service possible. Positive feedback and repeat customers are a testament to our dedication."
            }
          ]
    };    

    
    const faqData = [
        {
          question: "How do I book a taxi from Mumbai Airport to Pune?",
          answer: "You can easily book your taxi through our website or mobile app. Alternatively, you can call our customer service for assistance."
        },
        {
          question: "What are the fare estimates for the Mumbai Airport to Pune taxi?",
          answer: "Fares vary based on the type of vehicle and traffic conditions. For an accurate quote, you can use our online fare estimator or contact us directly."
        },
        {
          question: "Are your drivers experienced?",
          answer: "Yes, all our drivers are experienced professionals familiar with the best routes and committed to ensuring your safety."
        },
        {
          question: "What types of vehicles do you offer?",
          answer: "We offer a variety of vehicles, including sedans, SUVs, and luxury cars, to suit your preferences and group size."
        },
        {
          question: "Can I make changes to my booking?",
          answer: "Yes, you can modify your booking details by contacting our customer service team. Please do so at least a few hours before your scheduled pickup."
        },
        {
          question: "Do you provide child seats?",
          answer: "Yes, we can arrange child seats upon request. Please inform us in advance when booking your taxi."
        },
        {
          question: "What if my flight is delayed?",
          answer: "No worries! Our drivers monitor flight status and will adjust the pickup time accordingly, ensuring you won’t be left waiting."
        },
        {
          question: "Is the payment process secure?",
          answer: "Absolutely! We use secure payment gateways to ensure your financial information is protected during the transaction."
        },
        {
          question: "Can I book a round trip?",
          answer: "Yes, you can book a round trip for added convenience. Just specify your return details when making your reservation."
        },
        {
          question: "What is your cancellation policy?",
          answer: "We offer a flexible cancellation policy. Please check our website for specific terms or contact our customer service for more details."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Amit Verma",
          text: "I recently traveled from Mumbai Airport to Pune with SSKTravels24, and the experience was fantastic. The driver was waiting for me right outside the arrivals area, which made the pickup process incredibly smooth. The car was clean and comfortable, and the drive was pleasant. I highly recommend SSKTravels24 for anyone looking for reliable and efficient taxi services!"
        },
        {
          name: "Ms. Neha Patil",
          text: "I booked a taxi from Mumbai Airport to Pune for a late-night arrival. I was a bit worried about getting a cab at that hour, but SSKTravels24 exceeded my expectations. The driver was punctual and very friendly. The ride was comfortable, and I felt safe throughout the journey. This is definitely my go-to service for future trips!"
        }
      ];
      
      const contactData = {
        heading: "Mumbai Airport to Pune Taxi Contact",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Mumbai Airport to Pune Taxi Services</title>
  <meta name="description" content="Reliable and affordable taxi services from Mumbai Airport to Pune. Book your cab today for a hassle-free journey!" />
  <meta name="keywords" content="Mumbai airport to Pune taxi, Mumbai airport cab, Mumbai to Pune taxi service, Mumbai airport taxi fare, SSK Travels 24" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Mumbai Airport to Pune Taxi Services" />
  <meta property="og:description" content="Enjoy comfortable and affordable taxi services from Mumbai Airport to Pune. Quick booking and reliable drivers." />
  <meta property="og:url" content="https://https://ssktravels24.com//mumbai-airport-to-pune-taxi" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//mumbai-airport-taxi-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-21.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default MumbaiAirporttoPuneTaxi;
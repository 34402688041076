
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetomumbaiinternationalairport(){


    const cardData = 
    {
        keyword: 'Pune to Mumbai International Airport Cab Service',
        heading: 'SSKTravels24: Pune to Mumbai International Airport Cab Service',
        headingDescription: 'Looking for a reliable Pune to Mumbai International Airport cab service? SSKTravels24 offers hassle-free and comfortable cab services, ensuring you reach Mumbai International Airport on time and in style. With well-maintained vehicles, professional drivers, and 24/7 service, your journey from Pune to Chhatrapati Shivaji Maharaj International Airport (BOM) will be smooth and worry-free.',
    
        topPlaces: [
            {
                title: "1. Gateway of India",
                description: "The Gateway of India is one of Mumbai’s most iconic landmarks, built in 1924. It's a perfect spot for photography, boat rides, and a starting point for a tour of Mumbai."
            },
            {
                title: "2. Marine Drive",
                description: "Marine Drive, known as the 'Queen’s Necklace' due to its glowing streetlights at night, is a popular seaside promenade perfect for evening walks."
            },
            {
                title: "3. Chhatrapati Shivaji Maharaj Terminus (CSMT)",
                description: "A UNESCO World Heritage site, CSMT is a stunning railway station known for its Gothic architecture and bustling hub."
            },
            {
                title: "4. Siddhivinayak Temple",
                description: "One of the most visited religious sites in Mumbai, dedicated to Lord Ganesha, attracting thousands of devotees daily."
            },
            {
                title: "5. Colaba Causeway",
                description: "A bustling market offering everything from clothes to antiques, along with street food and attractions like the Gateway of India."
            },
            {
                title: "6. Juhu Beach",
                description: "A famous beach in Mumbai perfect for unwinding, enjoying local snacks like pav bhaji, and watching the sunset."
            },
            {
                title: "7. Elephanta Caves",
                description: "A UNESCO World Heritage site featuring rock-cut caves with intricate sculptures of Hindu gods, located on Elephanta Island."
            },
            {
                title: "8. Haji Ali Dargah",
                description: "A stunning mosque and tomb located in the Arabian Sea, connected to the mainland by a narrow causeway, ideal for spiritual seekers."
            },
            {
                title: "9. Film City",
                description: "A glimpse into the world of Bollywood, offering guided tours of film sets and shooting locations."
            },
            {
                title: "10. Taraporewala Aquarium",
                description: "One of India’s oldest aquariums, showcasing a variety of fish and marine life, perfect for families and marine enthusiasts."
            }
        ],
    
        services: [
            {
                name: "Pune to Mumbai International Airport Cab",
                description: "Our cab services are available 24/7, ensuring you reach your flight on time with real-time tracking and reliable service."
            },
            {
                name: "Pune to Mumbai Domestic Airport Cab",
                description: "Specialized cab services for those traveling to the Domestic Terminal, with experienced drivers navigating the best routes."
            },
            {
                name: "Vehicle Options Available",
                description: "Choose from a variety of vehicles like sedans, SUVs, and luxury cars for your journey from Pune to Mumbai Airport."
            },
            {
                name: "Innova Crysta for Families",
                description: "Our Innova Crysta is spacious and comfortable, perfect for families or groups traveling to the airport."
            },
            {
                name: "Affordable Pricing",
                description: "Competitive fares for our services ensure you get value for your money while enjoying a premium travel experience."
            },
            {
                name: "Contact Number for Booking",
                description: "For prompt and efficient Pune to Mumbai International Airport cab service, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['Mumbai International Airport to Pune Cab', 'Taxi from Mumbai International Airport to Pune'],
            ['Pune to Mumbai International Airport Cab', 'Pune to Mumbai Airport'],
            ['Pune to Mumbai Domestic Airport Cab', 'Pune to Mumbai International Airport Innova Crysta'],
            ['Pune to Mumbai International Airport Innova Crysta Fare', 'Pune to Mumbai Airport Cab'],
            ['Pune to Mumbai Airport Ertiga Cab', 'Best Cab Service Pune to Mumbai'],
            ['Best Cab Service Pune to Mumbai Sedan Cab', 'Pune to Chhatrapati Shivaji International Airport Taxi'],
            ['Pune to Chhatrapati Shivaji International Airport Terminal 2', 'Pune to Mumbai International Airport Drop'],
            ['Pune to Mumbai International Airport Drop Innova Crysta', 'Pune to Mumbai International Airport Drop Swift Dzire'],
            ['SSK Travels 24', '']
        ],
        
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose Us: Pune to Mumbai International Airport Cab - SSK Travels 24",
                "WhyChoosedescription": "When it comes to booking a cab for your journey from Pune to Mumbai International Airport, SSK Travels 24 is your best choice. Here’s why we stand out:"
            },
            {
                "WhyChooseheading": "Timely Service:",
                "WhyChoosedescription": "Our drivers ensure you arrive at Mumbai International Airport on time, so you never miss a flight."
            },
            {
                "WhyChooseheading": "Comfortable Rides:",
                "WhyChoosedescription": "We offer a range of well-maintained vehicles for a relaxing journey, with spacious interiors and clean seating."
            },
            {
                "WhyChooseheading": "Affordable Pricing:",
                "WhyChoosedescription": "Enjoy competitive rates with no hidden charges, allowing for effective budgeting."
            },
            {
                "WhyChooseheading": "Experienced Drivers:",
                "WhyChoosedescription": "Our professional drivers are knowledgeable about the best routes, ensuring a smooth and efficient ride."
            },
            {
                "WhyChooseheading": "24/7 Availability:",
                "WhyChoosedescription": "Our cab services are available around the clock, whether you have an early morning flight or a late-night arrival."
            },
            {
                "WhyChooseheading": "Safety First:",
                "WhyChoosedescription": "Your safety is our priority; our vehicles are regularly serviced and equipped with necessary safety features."
            },
            {
                "WhyChooseheading": "Easy Booking Process:",
                "WhyChoosedescription": "Booking a cab is simple through our user-friendly website or by contacting our customer service team."
            },
            {
                "WhyChooseheading": "Customer Satisfaction:",
                "WhyChoosedescription": "We pride ourselves on delivering exceptional service and are ready to assist with any questions or special requests."
            }
        ]
    };    

    
    const faqData = [
        {
          question: "How do I book a cab from Pune to Mumbai International Airport?",
          answer: "You can book your cab easily through our website or by contacting our customer service team. The process is quick and user-friendly."
        },
        {
          question: "What is the fare for a cab from Pune to Mumbai International Airport?",
          answer: "Our fares are competitive and depend on the vehicle type. Please check our pricing page or contact us for specific rates."
        },
        {
          question: "Are there any hidden charges?",
          answer: "No, we offer transparent pricing with no hidden fees. The fare quoted is the final amount you will pay."
        },
        {
          question: "Can I choose the type of vehicle for my airport transfer?",
          answer: "Yes, we offer a variety of vehicles, including sedans, SUVs, and luxury cars, allowing you to choose based on your preference and needs."
        },
        {
          question: "Is your service available 24/7?",
          answer: "Yes, our cab services to Mumbai International Airport are available around the clock to accommodate your travel schedule."
        },
        {
          question: "What if my flight is delayed?",
          answer: "Our drivers monitor flight schedules and will adjust the pickup time accordingly, ensuring you’re picked up on time."
        },
        {
          question: "How far in advance should I book my cab?",
          answer: "We recommend booking at least a few hours in advance to ensure availability, especially during peak travel times."
        },
        {
          question: "Are your drivers experienced and professional?",
          answer: "Yes, all our drivers are experienced, well-trained, and committed to providing a safe and pleasant travel experience."
        },
        {
          question: "What safety measures do you have in place?",
          answer: "We follow strict safety protocols, including regular vehicle maintenance and adherence to hygiene practices."
        },
        {
          question: "How can I contact customer support?",
          answer: "You can reach our customer support team via phone or email, and we’ll be happy to assist you with any inquiries or concerns."
        }
      ];
      
      const testimonials = [
        {
          name: "Ms. Neha Singh",
          text: "I recently used SSKTravels24 for my ride from Pune to Mumbai International Airport, and I couldn't be happier! The cab was clean and comfortable, and my driver was prompt and professional. He helped with my luggage and made sure I was relaxed during the journey. I arrived at the airport with plenty of time to spare. I highly recommend SSKTravels24 for anyone needing reliable airport transportation!"
        },
        {
          name: "Mr. Amit Joshi",
          text: "SSKTravels24 provided an excellent experience for my trip to Mumbai International Airport. The booking process was easy, and the fare was very reasonable. My driver was knowledgeable about the best routes and kept me updated on our arrival time. The whole journey was smooth and stress-free. I will definitely use their services again for my future travels!"
        }
      ];
      
      const contactData = {
        heading: "Pune to Mumbai International Airport Cab Contact Number",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai International Airport Cab | Reliable Taxi Service</title>
  <meta name="description" content="Book a comfortable cab from Pune to Mumbai International Airport with SSK Travels24. Enjoy hassle-free travel and competitive rates." />
  <meta name="keywords" content="Pune to Mumbai International Airport Cab, Taxi from Mumbai International Airport to Pune, Pune to Mumbai Airport, Best Cab Service Pune to Mumbai" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai International Airport Cab | Reliable Taxi Service" />
  <meta property="og:description" content="Travel smoothly from Pune to Mumbai International Airport with our reliable cab service. Choose from a range of vehicles including Innova Crysta and Ertiga." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-mumbai-international-airport-cab" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//airport-cab-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-14.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetomumbaiinternationalairport;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Tempotraveeronrentinpune(){



    const cardData = 
    {
      keyword: 'Tempo Traveller On Rent in Pune',
      heading: 'SSKTravels24: Tempo Traveller on Rent in Pune',
      headingDescription: 'Look no further than SSKTravels24 for your Tempo Traveller rental needs. Our vehicles are equipped with a range of top-notch features designed to provide unparalleled comfort and convenience. Discover the top features that make our Tempo Travellers the best choice for your travel requirements in Pune',
      topPlaces: [
        {
          title: "1. Well-Maintained Vehicles",
          description: "Our Tempo Travellers are regularly serviced and maintained to the highest standards. We ensure that each vehicle is in top condition, providing you with a reliable and clean travel experience."
      },
      {
       title: "2. Ample Luggage Space",
        description: "Pack all your essentials without worry. Our Tempo Travellers come with substantial luggage compartments that accommodate your bags and belongings securely, leaving ample room for passengers."
      }, 
        {
            title: "3. Powerful Air Conditioning",
            description: "Beat the Pune heat with our advanced air conditioning systems. Our Tempo Travellers are designed to keep the interior cool and comfortable, making your journey pleasant no matter the weather outside."
        },
        {
          title: "4. Luxurious Seating",
          description: "Travel in style with our plush, reclining seats. Each seat is equipped with individual armrests and adjustable headrests, ensuring maximum comfort during both short and long trips."
      },
        {
            title: "5. Modern Entertainment Systems",
            description: "Enhance your travel experience with our cutting-edge entertainment options. Each Tempo Traveller features music players and LED screens, providing entertainment throughout your trip, whether you’re enjoying your favorite music or a movie."
        },
        {
            title: "6. Experienced and Professional Drivers",
            description: "Safety and professionalism are our priorities. Our experienced drivers are well-versed with Pune’s routes and beyond, ensuring a smooth and safe journey while you sit back and enjoy the ride."
        },
        {
          title: "7. Spacious Interiors",
          description: "Enjoy the generous interior space of our Tempo Travellers, which ensures ample legroom and headroom for all passengers. Whether you're traveling with family or a larger group, our vehicles provide a comfortable environment where everyone can relax and enjoy the journey."
      },
        {
            title: "8. Flexible Rental Options",
            description: "We offer flexible rental packages to suit your specific needs. Whether it’s a short day trip or a longer journey, you can choose a rental option that best fits your schedule and budget."
        },
        {
            title: "9. Competitive Pricing",
            description: "Get the best value for your money with our transparent pricing. We offer clear, upfront rates with no hidden charges, making it easy to plan your travel expenses without surprises."
        },
        {
            title: "10. 24/7 Customer Support",
            description: "Our dedicated customer support team is available around the clock to assist you with bookings, inquiries, and any travel-related concerns. We are committed to providing a hassle-free experience from start to finish."
        }
    ],    
    services: [
        {
            name: "17-Seater Bus on Rent in Pune",
            description: "Looking for a 17-seater bus on rent in Pune? SSKTravels24 provides competitive prices for renting our 17-seater buses. Our pricing structure is transparent, ensuring you get value for money without any hidden fees. Ideal for large groups, our buses are well-maintained and ready to provide a comfortable journey."
        },
        {
            name: "17-Seater Tempo Traveller on Rent in Pune",
            description: "Opt for a 17-seater Tempo Traveller on rent in Pune from SSKTravels24 for spacious and comfortable group travel. Our 17-seater Tempo Travellers are perfect for medium to large groups, featuring comfortable seating, air conditioning, and ample luggage space to ensure a pleasant travel experience."
        },
        {
            name: "Traveller on Rent in Pune",
            description: "If you need a Traveller on rent in Pune, SSKTravels24 has you covered. Our range of Traveller vehicles is designed to accommodate groups of various sizes. Each vehicle comes equipped with modern amenities to make your journey comfortable, whether you're traveling within the city or heading out of town."
        },
        {
            name: "Traveller Bus on Rent in Pune",
            description: "For those requiring a Traveller bus on rent in Pune, SSKTravels24 offers reliable and spacious options. Our Traveller buses are perfect for school trips, corporate outings, or large family gatherings. Enjoy the comfort of well-maintained buses with features like air conditioning and comfortable seating."
        },
        {
            name: "Tempo Traveller on Rent Pune to Outstation",
            description: "Book a Tempo Traveller on rent Pune to Outstation with SSKTravels24 for your next trip outside the city. Our outstation services ensure that you travel comfortably and safely to destinations like Mahabaleshwar, Shirdi, and more. We offer competitive rates and well-maintained vehicles for a hassle-free journey."
        },
        {
            name: "Tempo Traveller on Rent in Pune",
            description: "Choose SSKTravels24 for a Tempo Traveller on rent in Pune. We provide a variety of Tempo Travellers suited for different needs, from short city trips to long-distance travel. Our vehicles are equipped with modern amenities to ensure a comfortable and enjoyable ride for all passengers."
        },
        {
            name: "17-Seater Tempo Traveller on Rent in Pune",
            description: "Our 17-seater Tempo Traveller on rent in Pune offers a spacious solution for group travel. SSKTravels24 ensures that our 17-seater Tempo Travellers are well-maintained and equipped with features like air conditioning and comfortable seating, making them ideal for both short and long journeys."
        },
        {
            name: "Pune to Shirdi Tempo Traveller Rate",
            description: "Find the best Pune to Shirdi Tempo Traveller rate with SSKTravels24. We offer transparent and competitive rates for your pilgrimage to Shirdi. Our Tempo Travellers are equipped to handle your luggage and ensure a comfortable ride, so you can focus on your journey rather than the details."
        },
        {
            name: "Tempo Traveller Hire in Pune",
            description: "For Tempo Traveller hire in Pune, SSKTravels24 offers a range of options to meet your needs. Our fleet of Tempo Travellers is perfect for any occasion, from family trips to corporate events. Enjoy the convenience of well-maintained vehicles and professional drivers dedicated to providing excellent service."
        },
        {
            name: "Tempo Traveller on Rent in Hadapsar",
            description: "If you need a comfortable and spacious vehicle in Hadapsar, renting a Tempo Traveller is a great choice. Our service offers a hassle-free booking process, flexible rental options, and 24/7 customer support, ensuring your group travels in style and comfort."
        },
        {
            name: "Tempo Traveller on Rent in Pune Rate per Km",
            description: "For affordable and transparent pricing on Tempo Traveller rentals in Pune, we offer competitive rates per kilometer. You only pay for the distance you travel, with no hidden costs. Our flexible rental plans and easy online booking system make it simple to manage your travel budget and enjoy a comfortable journey."
        },
        {
            name: "Tempo Traveller Rental Pune",
            description: "Renting a Tempo Traveller in Pune ensures a comfortable and enjoyable trip for groups and families. Our service provides spacious vehicles with modern amenities, flexible rental options, and easy booking."
        },
        {
            name: "Pune to Mahabaleshwar Cab Tempo Traveller",
            description: "Traveling from Pune to Mahabaleshwar is made easy with our Tempo Traveller cab service. Enjoy a comfortable ride with ample space and scenic views of the Western Ghats."
        },
        {
            name: "Pune to Goa Tempo Traveller on Rent",
            description: "Make your trip from Pune to Goa comfortable and stylish with our Tempo Traveller rental service. Ideal for groups, our vehicles offer plenty of space and modern amenities for a pleasant journey."
        },
        {
            name: "Luxury Tempo Traveller on Rent in Pune",
            description: "Travel in luxury with our high-end Tempo Traveller rental in Pune. Featuring plush interiors and advanced amenities, our service is perfect for special events and premium experiences."
        },
        {
            name: "Tempo Traveller On Rent in Pune Contact Number",
            description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Tempo Traveller rentals in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
      tableData: [
        ['-Tempo Traveller from Pune to Mahabaleshwar', '-Pune to Mahabaleshwar Tempo Traveller Hire'],
        ['-Pune to Panchgani Tempo Traveller', '-Tempo Traveller for Rent in Pune'],
        ['-17-Seater Tempo Traveller for Mahabaleshwar Trip', '-17-Seater Tempo Traveller for Mahabaleshwar Tour Package'],
        ['-Pune Airport to Mahabaleshwar Tempo Traveller on Rent', '-Pune to Tapola Tempo Traveller on Rent'],
        ['-14-Seater Tempo Traveller for Maharashtra Trip', '-Tempo Traveller on Rent for Mahabaleshwar Outings'],
        ['-Mini Bus on Rent for Mahabaleshwar Trip', '-Tempo Traveller on Rent in Pune Rate Per Km']
      ],
      whychoose: [
        {
            WhyChooseheading: "Why Choose SSKTravels24 Tempo Traveller on Rent in Pune?",
            WhyChoosedescription: "When it comes to renting a Tempo Traveller in Pune, SSKTravels24 is your go-to provider for a comfortable and reliable journey. Whether you're heading for a family trip or a corporate outing, our service ensures that you travel in style and comfort. Here's why SSKTravels24 is the best choice for your trip:"
        },
        {
            WhyChooseheading: "Dependable Service:",
            WhyChoosedescription: "At SSKTravels24, we provide reliable and punctual cab service for your journey from Pune to Panvel, ensuring you reach your destination on time."
        },
        {
            WhyChooseheading: "Comfortable Rides:",
            WhyChoosedescription: "Our fleet of well-maintained vehicles offers a comfortable and smooth travel experience, complete with modern amenities and ample legroom."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our experienced and courteous drivers are knowledgeable about the best routes and committed to delivering a safe and pleasant journey."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "We offer transparent and competitive rates with no hidden charges, giving you great value for your money."
        },
        {
            WhyChooseheading: "Flexible Booking:",
            WhyChoosedescription: "Enjoy easy and flexible booking options tailored to your schedule, whether you need a one-way trip or a round trip."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our customer support team is available around the clock to assist with your booking, answer questions, and address any concerns."
        },
        {
            WhyChooseheading: "Clean and Safe Vehicles:",
            WhyChoosedescription: "Our cabs are regularly cleaned and maintained to ensure hygiene and safety throughout your journey."
        }
    ]
    };    

    
   
    const faqData = [
      {
        question: "How can I book a Tempo Traveller from Pune to Mahabaleshwar?",
        answer: "You can book a Tempo Traveller by calling us at +91 8956170493 or through our website. Our team will assist you with the booking process."
      },
      {
        question: "What types of Tempo Travellers are available for the Pune to Mahabaleshwar journey?",
        answer: "We offer a range of Tempo Travellers, including 17-seaters and mini buses, to accommodate different group sizes and preferences."
      },
      {
        question: "Are there any additional charges for the Tempo Traveller service?",
        answer: "Our pricing is transparent, with no hidden fees. Any additional charges for extra stops or extended mileage will be communicated in advance."
      },
      {
        question: "How do I know if the Tempo Traveller is well-maintained?",
        answer: "All our vehicles undergo regular inspections and maintenance to ensure they are in excellent condition for your safety and comfort."
      },
      {
        question: "Can I choose the driver for my trip?",
        answer: "While we assign experienced drivers to ensure a high-quality experience, you can let us know of any specific preferences when booking."
      },
      {
        question: "What if I need to change my booking?",
        answer: "Contact our customer support team to discuss any changes. We will accommodate your requests based on availability."
      },
      {
        question: "Is there a minimum rental period for the Tempo Traveller service?",
        answer: "We offer flexible rental periods to fit your needs, whether for a day trip or a longer journey."
      },
      {
        question: "What amenities are included in the Tempo Traveller service?",
        answer: "Our vehicles come with modern amenities such as air conditioning, comfortable seating, and entertainment options."
      },
      {
        question: "What is the cancellation policy for the Tempo Traveller service?",
        answer: "Cancellation policies may vary. Please contact us for details on our cancellation terms and any applicable fees."
      },
      {
        question: "How can I contact SSKTravels24 for support during my journey?",
        answer: "Reach our 24/7 customer support team at +91 8956170493 for any assistance during your trip."
      }
    ];
    

    const testimonials = [
      {
        name: "Miss Rasika Sharma",
        text: "I recently rented a Tempo Traveller from SSKTravels24 for our trip to Mahabaleshwar, and it was a fantastic experience. The vehicle was clean, spacious, and well-maintained. The driver was professional and made our journey enjoyable. Highly recommend SSKTravels24 for group trips!"
      },
      {
        name: "Mr. sunil Patel",
        text: "Our corporate team used SSKTravels24 for a Mahabaleshwar outing, and we were thoroughly impressed. The Tempo Traveller was luxurious and comfortable, perfect for our needs. The booking process was smooth, and the driver was excellent. We’ll definitely choose SSKTravels24 again for future trips."
      }
    ];
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
          
              <p className=''>   <span className="quote-icon">“</span>{testimonial.text}</p>
              <footer className=' '>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: " Tempo Traveller On Rent In Pune Contact Number",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000",
         
        ],
        email: "booking@ssktravels24.com"
      };


      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Tempo Traveller On Rent in Pune | Affordable Tempo Traveller Services | Pune to Shirdi, Goa, Mahabaleshwar and More</title>
  <meta name="description" content="Rent a tempo traveller in Pune for trips to Shirdi, Goa, Mahabaleshwar, and other destinations. We offer 17-seater and luxury tempo travellers with competitive rates for local and outstation travel. Explore our rental options and book today." />
  <meta name="keywords" content="Tempo traveller in Pune, 17 seater bus on rent in Pune price, 17 seater tempo traveller on rent in Pune, Traveller on rent Pune, Traveller bus on rent in Pune, Tempo Traveller on rent Pune to Outstation, Tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller rate, Tempo Traveller hire in Pune, Tempo Traveller on rent in Hadapsar, Tempo Traveller on rent in Pune rate per km, Tempo Traveller Rental Pune, Pune to Mahabaleshwar cab tempo traveller, Pune to Goa tempo traveller on rent, Luxury Tempo Traveller on rent in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Tempo Traveller On Rent in Pune | Affordable Tempo Traveller Services" />
  <meta property="og:description" content="Discover the best tempo traveller rental services in Pune. We offer 17-seater and luxury tempo travellers for trips to Shirdi, Goa, Mahabaleshwar, and more. Affordable rates and comfortable travel options for local and outstation journeys. Contact us at +91 8956170493 | SSK Travels24." />
  <meta property="og:url" content="https://https://ssktravels24.com//tempo-traveller-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//tempo-traveller-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1

                            >Tempo Traveller On Rent in Pune</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-01.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

         <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>
    <div className=''>


      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold ">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Tempotraveeronrentinpune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busserviceforevents(){


    const cardData = 
    {
        keyword: 'Bus on Rent for Events in Pune',
        heading: 'SSKTravels24: Bus on Rent for Events in Pune',
        headingDescription: 'When planning an event in Pune, finding reliable and comfortable transportation is crucial. SSKTravels24 stands out as a premier choice for bus rentals, offering an exceptional range of services tailored to meet your event needs. Here’s why SSKTravels24 should be your top pick for bus rentals for events in Pune:',
        
        topPlaces : [
            {
                title: "1. Wide Range of Buses",
                description: "SSKTravels24 boasts an extensive fleet of buses, including luxury coaches, semi-luxury buses, and standard options. Whether you need a 17-seater for a small group or a 52-seater for a large event, we have the perfect vehicle to suit your requirements."
            },
            {
                title: "2. Modern Amenities",
                description: "Our buses are equipped with the latest amenities to ensure a comfortable and enjoyable journey. Features include air conditioning, plush seating, onboard entertainment systems, and Wi-Fi, ensuring your guests travel in style."
            },
            {
                title: "3. Professional Drivers",
                description: "At SSKTravels24, our drivers are highly experienced and professionally trained. They are familiar with Pune's routes and traffic patterns, ensuring timely and safe transportation for your event."
            },
            {
                title: "4. Flexible Booking Options",
                description: "We offer flexible booking options to accommodate your schedule and event needs. Whether it’s a one-time event or recurring transportation, our team can customize a rental plan that works best for you."
            },
            {
                title: "5. Competitive Pricing",
                description: "Our pricing is transparent and competitive, providing great value for the quality of service you receive. We offer detailed quotes with no hidden charges, so you can budget your event transportation with confidence."
            },
            {
                title: "6. Excellent Customer Service",
                description: "Our dedicated customer service team is available to assist you throughout the booking process and during your event. From initial inquiries to post-event follow-up, we ensure a smooth and hassle-free experience."
            },
            {
                title: "7. Customizable Packages",
                description: "We offer customizable rental packages that can be tailored to fit the specific needs of your event. Whether it’s for corporate functions, weddings, or private parties, we can design a package that meets your unique requirements."
            },
            {
                title: "8. Clean and Well-Maintained Buses",
                description: "Maintaining high standards of cleanliness and hygiene is a top priority at SSKTravels24. Our buses are regularly cleaned and inspected to ensure they are in excellent condition for your event."
            },
            {
                title: "9. On-Time Guarantee",
                description: "Punctuality is crucial for event transportation. We guarantee timely arrivals and departures, so you can be assured that your guests will reach their destination on time."
            },
            {
                title: "10. Positive Customer Feedback",
                description: "Our clients consistently provide positive feedback about our bus rental services. From the ease of booking to the quality of our buses and drivers, SSKTravels24 is highly regarded for delivering reliable and exceptional service."
            }
        ],        
        
        services : [
            {
                name: "Bus on Rent for Wedding Evening in Pune",
                description: "For a memorable wedding evening, SSKTravels24 offers specialized bus rental services to ensure your guests travel comfortably and arrive on time. Our fleet includes a variety of bus options, including luxury and standard models, suitable for both intimate gatherings and grand celebrations."
            },
            {
                name: "Bus Rental for Wedding Party",
                description: "Make your wedding party seamless and stylish with SSKTravels24. We provide dedicated bus rental services tailored for wedding parties, ensuring that all guests are transported efficiently and enjoy a comfortable ride."
            },
            {
                name: "Private Bus Service on Rent for Event in Pune",
                description: "For private events, SSKTravels24 offers exclusive bus rental services to provide a personalized and private travel experience. Our dedicated drivers ensure a smooth and enjoyable journey."
            },
            {
                name: "Bus on Hire for Party in Pune",
                description: "Host your party with ease by choosing SSKTravels24 for your bus rental needs. Our buses are available for various types of parties, providing a reliable and stylish transportation solution."
            },
            {
                name: "Pune to Bhimashankar Bus Tour Package",
                description: "Explore the scenic beauty of Bhimashankar with our dedicated bus tour package, ensuring a smooth journey to one of Maharashtra's prominent pilgrimage destinations."
            },
            {
                name: "Pune to Mahabaleshwar Bus Tour Package",
                description: "Experience the lush landscapes of Mahabaleshwar with our specialized bus tour package, complete with amenities designed to enhance your travel experience."
            },
            {
                name: "Pune to Goa Bus Tour Packages",
                description: "Travel to the vibrant destination of Goa with ease by opting for our bus tour packages, providing a comfortable and enjoyable journey."
            },
            {
                name: "32, 35, 40, 45, 50 Seater Bus on Rent in Pune",
                description: "No matter the size of your group, SSKTravels24 has a range of buses to accommodate your needs, ensuring the perfect size for your event."
            },
            {
                name: "Pune to Shirdi Bus Package",
                description: "Embark on a spiritual journey to Shirdi with our reliable and comfortable bus services, making your pilgrimage both convenient and pleasant."
            },
            {
                name: "Pune to Ajanta Ellora Bus on Rent",
                description: "Discover the historical wonders of Ajanta and Ellora with our bus rental services, ensuring a hassle-free travel experience."
            },
            {
                name: "Pune to Ashtavinayak Darshan Bus Package",
                description: "For those seeking a spiritual journey through the Ashtavinayak temples, enjoy a well-organized trip with our comfortable bus services."
            },
            {
                name: "Luxury Bus on Rent for Event in Pune",
                description: "Elevate your event with our luxury bus rentals, equipped with top-notch amenities for a premium travel experience."
            },
            {
                name: "Tempo Traveller Rent for Events in Pune",
                description: "For a compact transportation option, we offer tempo travellers featuring comfortable seating and modern amenities."
            },
            {
                name: "Mini Bus on Rent for Event in Pune",
                description: "Our mini buses are well-suited for smaller groups and come with comfortable seating, ensuring a pleasant travel experience."
            },
            {
                name: "Bus on Rent for Event in Pimpri Chinchwad",
                description: "For events in Pimpri Chinchwad, we offer a range of bus rental options to ensure a comfortable and smooth journey."
            },
            {
                name: "Bus on Rent For Event in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient bus rental services in Pune."
            }
        ],        
        tableData: [
                ['- Bus on Rent for Wedding Evening Pune', '- Bus Rental for Wedding Party'],
                ['- Private Bus Service on Rent for Event in Pune', '- Bus on Hire for Party in Pune'],
                ['- Pune to Bhimashankar Bus Tour Package', '- Pune to Mahabaleshwar Bus Tour Package'],
                ['- Pune to Goa Bus Tour Packages', '- 32, 35, 40, 45, 50 Seater Bus on Rent in Pune'],
                ['- Pune to Shirdi Bus Package', '- Pune to Ajanta Ellora Bus on Rent'],
                ['- Pune to Ashtavinayak Darshan Bus Package', '- Luxury Bus on Rent for Event in Pune'],
                ['- Luxury Bus on Rent for Event in Pimpri Chinchwad', '- Luxury Bus on Rent for Event in Pune Hadapsar'],
                ['- Luxury Bus on Rent for Event in Pune Hadapsar Contact Number', '- Tempo Traveller Rent for Events in Pune'],
                ['- Luxury Tempo Traveller on Rent in Pune for Event', '- Mini Bus on Rent for Event in Pune'],
                ['- Bus on Rent for Event in Pimpri Chinchwad']
            ],           
            whychoose : [
                {
                    "WhyChooseheading": "Extensive Fleet Selection:",
                    "WhyChoosedescription": "At SSKTravels24, we offer a diverse range of buses to suit your event needs, including luxury buses, mini-buses, and larger coaches. Whether you're hosting an intimate gathering or a grand celebration, we have the perfect bus for you."
                },
                {
                    "WhyChooseheading": "Superior Comfort and Amenities:",
                    "WhyChoosedescription": "Our buses are equipped with comfortable seating, air conditioning, and modern amenities to ensure a pleasant and enjoyable journey for all passengers. Your comfort and satisfaction are our top priorities."
                },
                {
                    "WhyChooseheading": "Experienced Drivers:",
                    "WhyChoosedescription": "Our professional drivers are well-trained and experienced in handling events of all sizes. They are dedicated to providing safe, punctual, and reliable transportation, ensuring your guests arrive on time."
                },
                {
                    "WhyChooseheading": "Customizable Packages:",
                    "WhyChoosedescription": "We understand that every event is unique, which is why we offer customizable rental packages to meet your specific requirements. From luxury options to budget-friendly choices, we tailor our services to fit your needs."
                },
                {
                    "WhyChooseheading": "Competitive Pricing:",
                    "WhyChoosedescription": "SSKTravels24 offers competitive rates for bus rentals, providing exceptional value for money. We strive to deliver high-quality service at affordable prices without compromising on comfort."
                },
                {
                    "WhyChooseheading": "Reliable Service:",
                    "WhyChoosedescription": "Punctuality is crucial for event transportation. Our team ensures that your bus arrives on time and that all logistics are managed smoothly, allowing you to focus on enjoying your event."
                },
                {
                    "WhyChooseheading": "Excellent Customer Support:",
                    "WhyChoosedescription": "Our dedicated customer support team is available to assist you with all inquiries and booking needs. We provide personalized attention to ensure a hassle-free experience from start to finish."
                },
                {
                    "WhyChooseheading": "Well-Maintained Vehicles:",
                    "WhyChoosedescription": "Our fleet is regularly serviced and maintained to ensure all buses are in top condition. We prioritize safety and reliability for every journey, giving you peace of mind."
                },
                {
                    "WhyChooseheading": "Comprehensive Coverage:",
                    "WhyChoosedescription": "Whether you're planning a corporate event, wedding, or any other occasion, we cover a wide range of destinations and routes, ensuring that all your transportation needs are met seamlessly."
                },
                {
                    "WhyChooseheading": "Positive Reputation:",
                    "WhyChoosedescription": "SSKTravels24 is known for its excellent service and high customer satisfaction. We take pride in our reputation and strive to exceed your expectations with every booking."
                }
            ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for rent for events?",
            answer: "SSKTravels24 offers a variety of buses, including luxury buses, mini-buses, and large coaches. Our fleet is designed to accommodate different group sizes and event requirements."
        },
        {
            question: "How can I book a bus for my event?",
            answer: "You can book a bus by contacting us through our website or by phone. Our customer support team will assist you in selecting the right bus and finalizing the details."
        },
        {
            question: "What amenities are provided on the buses?",
            answer: "Our buses come equipped with comfortable seating, air conditioning, and modern amenities. Luxury buses may include additional features such as premium seating and entertainment options."
        },
        {
            question: "Are there options for customizing the rental package?",
            answer: "Yes, we offer customizable rental packages to suit your specific needs. Whether you require additional services or have special requests, we can tailor our packages accordingly."
        },
        {
            question: "How much does it cost to rent a bus for an event?",
            answer: "The cost of renting a bus varies depending on factors like the type of bus, duration of the rental, and event location. For a detailed quote, please contact us with your event details."
        },
        {
            question: "Are the drivers experienced and professional?",
            answer: "Yes, all our drivers are experienced and trained to handle various types of events. They prioritize safety and punctuality to ensure a smooth transportation experience."
        },
        {
            question: "What is the cancellation policy for bus rentals?",
            answer: "Our cancellation policy is designed to be fair and flexible. Please contact us for details on our cancellation terms and conditions, which may vary based on the timing of the cancellation."
        },
        {
            question: "Can the bus be used for multiple trips or stops?",
            answer: "Yes, our buses can be used for multiple trips or stops as needed. Please discuss your specific requirements with us, and we’ll accommodate your plans effectively."
        },
        {
            question: "How do I make changes to my booking?",
            answer: "To make changes to your booking, please contact our customer support team as soon as possible. We will assist you with modifying your reservation based on availability and your updated needs."
        },
        {
            question: "What should I do if there is a problem during the trip?",
            answer: "If you encounter any issues during the trip, please contact our support team immediately. We are committed to resolving any problems promptly and ensuring your experience is satisfactory."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Miss Priya R",
            text: "I recently hired a bus from SSKTravels24 for a corporate event, and I couldn’t be happier with the service. The booking process was seamless and efficient, and the bus itself was outstanding—spotless, comfortable, and well-equipped. Our driver arrived right on schedule and was incredibly professional throughout the event. Our clients were impressed by the level of comfort and punctuality. I highly recommend SSKTravels24 for any event transportation needs. Their commitment to detail and exceptional customer service truly sets them apart."
        },
        {
            name: "Mr. Rajesh M",
            text: "SSKTravels24 provided exceptional service for a wedding I organized recently. We needed a large bus to transport guests between the venue and hotel, and SSKTravels24 exceeded our expectations. The bus was spacious, luxurious, and maintained to the highest standards. Our driver was courteous and ensured everything ran smoothly throughout the day. The entire experience was stress-free, and I received numerous compliments from the guests. If you’re looking for reliable and high-quality bus rental for any event in Pune, SSKTravels24 is definitely the way to go!"
        }
    ];
    
    
    const contactData = {
        heading: "Bus on Rent For Event in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus on Rent For Event in Pune | Reliable Event Transportation</title>
  <meta name="description" content="Rent buses for various events in Pune including weddings, corporate gatherings, and more." />
  <meta name="keywords" content="Bus on rent for wedding evening Pune | Bus rental for wedding party | SSK Travels24 Pune | Private bus service on rent for event in Pune | Bus on hire for party in Pune | Pune to Bhimashankar bus tour package | Pune to Mahabaleshwar bus tour package | Pune to Goa bus tour packages | 32, 35, 40, 45, 50 seater bus on rent in Pune | Pune to Shirdi bus package | Pune to Ajanta Ellora bus on rent | Pune to Ashtavinayak Darshan bus package | Luxury bus on rent for event in Pune | Luxury bus on rent for event in Pimpri Chinchwad | Tempo traveller rent for events in Pune | Mini bus on rent for event in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus on Rent For Event in Pune | Reliable Event Transportation" />
  <meta property="og:description" content="Explore bus rental options for events in Pune, from weddings to corporate gatherings!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-for-event-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//event-bus-service-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-36.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs - Bus on Rent for Event in Pune – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busserviceforevents;
import React from 'react';
import Visionn from './Visionn';
import VimanTestimonials from './VimanTestimonials';
import { Helmet } from 'react-helmet';
import FAQSection from './FAQSection';
import PrivacyPolicy from './PrivacyPolicy';
import GoogleAnalytics from './GoogleAnalytics';
// import './YourStyles.css'; // Import your styles if needed

const About = () => {

    const sectionStyle = {
        padding: '20px',
        backgroundColor: '#f8f9fa',
    };

    const containerStyle = {
        maxWidth: '1140px',
        margin: '0 auto',
    };

    const rowStyle = {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    };

    const colStyle = {
        // padding: '15px',
        boxSizing: 'border-box',
        flex: '1',
        minWidth: '200px', // Minimum width for columns
    };

    const imgStyle = {
        width: '100%',
        height: 'auto',
    };

    // Media queries for responsiveness
    const responsiveStyles = {
        '@media (max-width: 1200px)': {
            containerStyle: {
                maxWidth: '960px',
            },
        },
        '@media (max-width: 992px)': {
            colStyle: {
                flex: '1 0 50%',
                maxWidth: '50%',
            },
        },
        '@media (max-width: 768px)': {
            colStyle: {
                flex: '1 0 100%',
                maxWidth: '100%',
            },
        },
    };



    const applyResponsiveStyles = (style, mediaQueries) => {
        const newStyle = { ...style };
        Object.keys(mediaQueries).forEach((mediaQuery) => {
            if (window.matchMedia(mediaQuery).matches) {
                Object.assign(newStyle, mediaQueries[mediaQuery]);
            }
        });
        return newStyle;
    };
    return (
       <div>
        <GoogleAnalytics/>
         <Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
         <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>About Us</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-section padding">
        <div className="container ">
          <div className="row align-items-center ">
            <div className="col-12 col-md-6 col-sm-6">
            <img className="about-img1 urbania-image" src="/images/Mr-Bhatiya.jpeg" alt="About Image 1" />
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <div className="section-heading mb-40">
                <h4><span></span>About Our Company</h4>
                <h2>SSK Travels24</h2>
                <p>
                
SSK Travels24 has been a trusted provider of premium car rental services for over five years, serving Maharashtra and other Indian Regions. We are proud to be a preferred choice for corporate clients, travel agencies, hotels, embassies, and individual travelers alike. Our extensive fleet offers a wide range of vehicles, from economical options to luxury cars, catering to both personal and business needs at highly competitive rates.

We specialize in both local and outstation luxury fleet services, ensuring a seamless travel experience across India. Our airport transfer services have earned us a strong reputation for reliability and convenience. In addition, we offer tax credits for corporate events, making us a cost-effective solution for business travel.

For larger groups, we provide fast and efficient bus booking services. Whether you need a mini bus for a group transfer or a coach for an event. To make your reservation, simply call us at+91 8956170493, and experience the exceptional service that has made us a leading name in the travel industry


                </p>
              </div>
              <ul className="about-info">
                <li>
                  <div className="owner">
                    <h4>Founder - Mr. Ritesh Bhatiya</h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>





      <section style={sectionStyle}>
            <div style={applyResponsiveStyles(containerStyle, responsiveStyles)}>
                <div >
                    <div style={applyResponsiveStyles(rowStyle, responsiveStyles)}>
                        <div style={applyResponsiveStyles(colStyle, responsiveStyles)}>
                            <div style={{ marginBottom: '15px' }}>
                                <strong style={{ color: '#343a40', padding: '15px 0', display: 'block' }}>
                                    <i className="fas fa-crown px-2" style={{ color: '#913923' }}></i>
                                    Exceptional Luxury Travel Experience
                                </strong>
                                <p style={{ paddingRight: '15px', textAlign: 'left' }}>
                                    World-class luxury travel services where luxury meets exceptional care, creating unforgettable moments and exceeding your every expectation.
                                </p>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <strong style={{ color: '#343a40' }}>
                                    <i className="fas fa-tools px-2" style={{ color: '#913923' }}></i>
                                    Reliable Roadside Assistance
                                </strong>
                                <p style={{ paddingRight: '15px', textAlign: 'left' }}>
                                    Reliable support when you need it most, keeping you on the move with confidence and peace of mind.
                                </p>
                            </div>
                        </div>
                        <div style={applyResponsiveStyles({ ...colStyle, flex: '0 0 50%', maxWidth: '50%' }, responsiveStyles)}>
                            <img src="\images\For mail\Urbania-01.jpg" alt="Tempo Traveller" style={imgStyle} />
                        </div>
                        <div style={applyResponsiveStyles({ ...colStyle, flex: '0 0 25%', maxWidth: '25%' }, responsiveStyles)}>
                            <div style={{ marginBottom: '15px' }}>
                                <strong style={{ color: '#343a40', display: 'block' }}>
                                    <i className="fas fa-balance-scale px-2" style={{ color: '#913923' }}></i>
                                    Quality at Minimum Expense
                                </strong>
                                <p style={{ textAlign: 'left' }}>
                                    Unlocking affordable brilliance with elevating quality while minimizing costs for maximum value.
                                </p>
                            </div>
                            <div style={{ marginBottom: '15px' }}>
                                <strong style={{ color: '#343a40' }}>
                                    <i className="fas fa-exchange-alt px-2" style={{ color: '#913923' }}></i>
                                    Free Pick-Up & Drop-Off
                                </strong>
                                <p style={{  textAlign: 'left' }}>
                                    Enjoy free pickup and drop-off services, adding an extra layer of ease to your car rental experience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       <Visionn/>
       <VimanTestimonials/>
       {/* <FAQSection/> */}
       </div>
    );
};

export default About;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetogoabushire(){


    const cardData = 
    {
        keyword: 'Pune to Goa Bus Hire',
        heading: 'SSKTravels24: Pune to Goa Bus Hire',
        headingDescription: 'Planning a trip from Pune to Goa? SSKTravels24 offers exceptional bus hire services to ensure a smooth, comfortable, and enjoyable journey to one of India’s most vibrant destinations. Whether you’re traveling with family, friends, or colleagues, our reliable bus hire services are designed to cater to all your travel needs, making your Goa trip unforgettable.',
        
        topPlaces: [
            {
                title: "1. Baga Beach",
                description: "One of Goa’s most famous beaches, Baga Beach is known for its lively atmosphere, exciting water sports, and vibrant beach shacks. It’s the perfect spot to relax and enjoy Goa's nightlife."
            },
            {
                title: "2. Calangute Beach",
                description: "Often dubbed the 'Queen of Beaches,' Calangute Beach boasts a wide sandy stretch and numerous water activities, making it ideal for sunbathing and adventure."
            },
            {
                title: "3. Anjuna Beach",
                description: "Renowned for its picturesque beauty and lively flea markets, Anjuna Beach attracts backpackers and those looking to experience Goa’s bohemian vibe."
            },
            {
                title: "4. Fort Aguada",
                description: "This historic fort offers stunning views of the Arabian Sea and is perfect for history enthusiasts. It also features an operational lighthouse."
            },
            {
                title: "5. Basilica of Bom Jesus",
                description: "A UNESCO World Heritage site, the Basilica is famous for its stunning Baroque architecture and the tomb of St. Francis Xavier, making it a significant landmark."
            },
            {
                title: "6. Dudhsagar Waterfalls",
                description: "Located in the Western Ghats, Dudhsagar Waterfalls is a majestic four-tiered waterfall surrounded by lush greenery, making it a must-visit natural wonder."
            },
            {
                title: "7. Chapora Fort",
                description: "Offering breathtaking views of the landscape and Chapora River, this fort is a favorite for sunset seekers."
            },
            {
                title: "8. South Goa Beaches",
                description: "Explore the serene beaches of South Goa, such as Palolem, Agonda, and Colva, known for their peaceful environment and fewer crowds."
            },
            {
                title: "9. Spice Plantations",
                description: "Visit one of Goa’s spice plantations to experience the region’s rich agricultural heritage and enjoy a guided tour of lush plantations."
            },
            {
                title: "10. Goa State Museum",
                description: "For a deeper understanding of Goan culture and history, the Goa State Museum houses an extensive collection of artifacts and historical relics."
            }
        ],
        
        services: [
          {
              "name": "17-Seater Bus on Rent in Pune",
              "description": "Perfect for small groups, our 17-seater buses offer a comfortable and spacious ride, making them ideal for family trips, corporate outings, or short getaways. Each bus is equipped with comfortable seating, ample luggage space, and a driver to ensure a hassle-free journey. Enjoy the scenic route to Goa or any other destination with your friends and family."
          },
          {
              "name": "Urbania Bus Hire in Pune",
              "description": "Experience luxury and comfort with our Urbania buses, designed for a sophisticated travel experience. These buses feature plush seating, modern amenities, and ample legroom, making them perfect for corporate events, weddings, or special occasions. Let us provide you with a premium travel experience that elevates your journey to the next level."
          },
          {
              "name": "AC Bus on Rent in pune",
              "description": "Stay cool during your journey with our AC buses, ensuring a pleasant ride regardless of the weather outside. Our air-conditioned buses are perfect for longer trips, offering a comfortable environment and modern amenities, allowing you to relax and enjoy the ride, whether you’re heading to a corporate meeting or a weekend getaway."
          },
          {
              "name": "14, 17, 25, 32 Seater Options in pune",
              "description": "Choose from a range of bus sizes to match your group’s needs. Our 14, 17, 25, and 32-seater buses provide comfort and convenience throughout your journey, accommodating various group sizes while ensuring a pleasant travel experience. Perfect for school trips, family outings, or corporate functions."
          },
          {
              "name": "35, 40, 45, 50 Seater Buses on Rent",
              "description": "For larger gatherings, our spacious buses can comfortably accommodate your group. Whether it's a family reunion, corporate event, or a vacation with friends, our 35, 40, 45, or 50-seater buses are designed to provide ample space, comfort, and convenience for all passengers, making your journey enjoyable and stress-free."
          },
          {
              "name": "Bharat Benz Hire for Picnic",
              "description": "Enjoy the luxury of Bharat Benz buses for a premium picnic experience. Combining comfort and style, these buses are equipped with high-end features and spacious interiors, perfect for a day out with friends or family. Relax and enjoy your trip, knowing that you’re traveling in one of the best buses available."
          },
          {
              "name": "Pune to Goa Bus Hire Contact Number",
              "description": "For quick and easy booking, contact SSKTravels24 at +91 8956170493 for your Pune to Goa Bus Hire needs. Our friendly staff is ready to assist you with your travel plans, ensuring a seamless booking process and a comfortable journey to your desired destination."
          }
      ],      
        tableData: [
            ['- Pune to Goa 17 Seater Bus on Rent', '- Pune to Goa Urbania Bus Hire'],
            ['- Pune to Goa AC Bus on Rent', '- 14, 17, 25, 32 Seater on Rent for Pune to Goa'],
            ['- 35, 40, 45, 50 Seater on Rent for Pune to Goa', '- Pune to Goa Bus Package for Trip from Pune'],
            ['- Pune to Goa Bus Hire for Picnic', '- Pune to Goa Bharat Benz Hire for Picnic'],
            ['- Pune to Goa Tempo Traveller on Rent', '- Pune to Goa Mini Bus on Rent'],
            ['- 13 Seater Urbania on Rent for Goa Trip', '- 17 Seater Urbania on Rent for Goa Trip'],
            ['- Pune to Goa Mini Bus On Rent', '- Pune to Goa Bus Tour Packages'],
            ['- Bus Hire on Rent for Pune to Goa', '- Pune to Goa Bus Hire on Rent'],
            ['- Pune to Goa Bus Hire on Rent Contact Number', '- Pune Goa Bus Package Tour']
        ],
        whychoose: [
            {
                "WhyChooseheading": "Why Choose Us for Pune to Goa Bus Hire – SSKTravels24?",
                "WhyChoosedescription": "At SSKTravels24, we offer a diverse fleet of vehicles for your Pune to Goa journey, ensuring comfort and convenience for all travelers."
            },
            {
                "WhyChooseheading": "Diverse Fleet of Vehicles:",
                "WhyChoosedescription": "We offer an extensive range of vehicles, including 17-seater buses, Urbania buses, AC buses, and larger options, accommodating small to large groups."
            },
            {
                "WhyChooseheading": "Comfort and Convenience:",
                "WhyChoosedescription": "Our buses are equipped with air conditioning, generous legroom, and plush seating to ensure a relaxing travel experience."
            },
            {
                "WhyChooseheading": "Experienced and Professional Drivers:",
                "WhyChoosedescription": "Our highly trained drivers prioritize your safety and comfort, ensuring timely arrivals to your destination."
            },
            {
                "WhyChooseheading": "Flexible and Customizable Packages:",
                "WhyChoosedescription": "We provide customizable bus hire packages tailored to family vacations, corporate outings, or picnics."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "We pride ourselves on offering competitive pricing with complete transparency, ensuring the best value for your money."
            },
            {
                "WhyChooseheading": "Exceptional Customer Service:",
                "WhyChoosedescription": "Our dedicated customer service team is here to assist you throughout the booking process and during your journey."
            },
            {
                "WhyChooseheading": "Reliable and Punctual:",
                "WhyChoosedescription": "Reliability and punctuality are our core values, ensuring timely departures and arrivals for all our services."
            }
        ]
    };    

    
   
    const faqData = [
        {
          question: "What types of buses are available for hire from Pune to Goa?",
          answer: "We offer a range of buses, including 17-seater, 32-seater, 35-seater, 40-seater, and 50-seater options. Additionally, we have Urbania buses, AC buses, and tempo travellers to cater to different group sizes and preferences."
        },
        {
          question: "How can I book a bus from Pune to Goa with SSKTravels24?",
          answer: "Booking is easy! You can reach out to us via phone or email to discuss your requirements. Our customer service team will assist you with availability, pricing, and booking details to ensure a seamless reservation process."
        },
        {
          question: "Are the buses equipped with air conditioning?",
          answer: "Yes, we offer both AC and non-AC buses. For your comfort, especially in warm weather, our AC buses are equipped with climate control systems."
        },
        {
          question: "What amenities are provided on the bus?",
          answer: "Our buses come with various amenities, including comfortable seating, ample legroom, air conditioning (for AC buses), and sometimes onboard entertainment. The specific amenities may vary depending on the type of bus you choose."
        },
        {
          question: "Is there a minimum or maximum number of passengers required?",
          answer: "We can accommodate various group sizes. From small groups needing a 17-seater to large groups requiring a 50-seater, we have options to fit your needs. For detailed information, please contact us."
        },
        {
          question: "Are there any additional charges?",
          answer: "Our pricing is clear and free from hidden charges. The cost is based on factors such as the type of bus, distance, and duration of hire. We provide detailed quotes, so you're aware of the total cost before confirming your booking."
        },
        {
          question: "Can I customize my bus hire package?",
          answer: "Absolutely! We offer customizable packages to fit your travel needs. Whether you need specific pickup and drop-off points or have unique requirements for your trip, we can tailor the package to suit you."
        },
        {
          question: "What should I do if there is a delay or issue during the trip?",
          answer: "Our customer service team is available to help with any issues that may arise during your journey. If you encounter any problems, please reach out to us immediately, and we will address them promptly."
        },
        {
          question: "How far in advance should I book the bus?",
          answer: "We recommend booking as early as possible to ensure availability, especially during peak travel seasons. Early booking also helps secure the best rates and options for your trip."
        },
        {
          question: "What safety measures are in place for the journey?",
          answer: "Safety is our top priority. All our buses undergo regular maintenance and inspections. Our drivers are trained to handle various road conditions, ensuring a safe and secure journey for all passengers."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Rajesh Sharma",
          text: "Our family trip from Pune to Goa with SSKTravels24 was truly exceptional. We hired a 35-seater bus, and the experience was fantastic from start to finish. The bus was spotless, comfortable, and well-maintained, offering ample space for everyone to relax. Our driver was professional and made sure we had a smooth journey. The booking process was seamless, and the customer service team was incredibly accommodating. I highly recommend SSKTravels24 for anyone planning a trip to Goa!"
        },
        {
          name: "Miss Priya Desai",
          text: "I recently organized a corporate outing from Pune to Goa and chose SSKTravels24 for the bus hire. We opted for a 50-seater AC bus, and it was the perfect choice for our large group. The bus came equipped with modern amenities, and the air conditioning was a welcome relief in the warm weather. The service was impeccable, with our driver being punctual and courteous throughout the trip. The entire experience exceeded our expectations, and I would definitely choose SSKTravels24 again for future trips."
        }
      ];
      
      const contactData = {
        heading: "Pune to Goa Bus Hire Contact Number",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Goa Bus Hire | Enjoy Your Coastal Getaway</title>
  <meta name="description" content="Book your bus from Pune to Goa for a memorable trip. Choose from 14 to 50 seater options, including luxury and mini buses." />
  <meta name="keywords" content="Pune to Goa Bus Hire, Pune to Goa 17 Seater Bus on Rent, Urbania Bus Hire Pune to Goa, Goa Bus Packages from Pune, Tempo Traveller for Goa" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Goa Bus Hire | Enjoy Your Coastal Getaway" />
  <meta property="og:description" content="Plan your trip to Goa with our affordable bus hire services from Pune. Enjoy comfortable travel with various bus options." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-goa-bus-hire" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//goa-bus-hire-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-11.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetogoabushire;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Bushireforoutstation(){


    const cardData = 
    {
        keyword: 'Bus Hire for Outstation in Pune',
        heading: 'SSKTravels24:Bus Hire for Outstation in Pune',
        headingDescription: 'When it comes to reliable and comfortable bus hire for outstation travel from Pune, SSKTravels24 is the premier choice for discerning travelers. Whether you are planning a corporate retreat, a family vacation, or a group tour, our extensive fleet and exceptional service guarantee a hassle-free journey to your chosen destination. Here’s why SSKTravels24 is the go-to option for outstation bus hire and the top destinations we serve with excellence.',
        
        topPlaces : [
            {
                title: "1. Mumbai",
                description: "A bustling metropolis filled with vibrant nightlife and historical landmarks. Our buses ensure a comfortable journey to and from Mumbai, making it perfect for both leisure and business travelers. Explore iconic sites like the Gateway of India, Marine Drive, and vibrant markets like Colaba Causeway."
            },
            {
                title: "2. Lonavala",
                description: "Known for its stunning landscapes, Lonavala is a favorite hill station near Pune. Our buses provide a relaxing ride to this picturesque destination, ideal for weekend getaways. Enjoy attractions such as Bhushi Dam, Tiger’s Leap, and the lush green valleys that surround the area."
            },
            {
                title: "3. Nashik",
                description: "Famous for its vineyards and holy temples, Nashik attracts both tourists and pilgrims. We offer comfortable bus hire services to explore the rich cultural and natural heritage of the region. Don't miss the Sula Vineyards, Panchavati Temple, and the scenic Anjneri Hill."
            },
            {
                title: "4. Goa",
                description: "Renowned for its beautiful beaches and vibrant culture, Goa is a top destination for vacationers. Our buses ensure a smooth journey, allowing you to enjoy all that this sunny paradise has to offer. Explore pristine beaches like Anjuna and Baga, as well as historic churches and local markets."
            },
            {
                title: "5. Shirdi",
                description: "A major pilgrimage site dedicated to Sai Baba, Shirdi welcomes thousands of devotees. Our reliable bus service provides a convenient way to reach this sacred destination. Visit the Sai Baba Temple, explore the Samadhi Mandir, and experience the spiritual ambiance of this revered town."
            },
            {
                title: "6. Mahabaleshwar",
                description: "Known for its pleasant climate and breathtaking viewpoints, Mahabaleshwar is a beloved hill station. We offer comfortable buses to this serene getaway, perfect for relaxation. Enjoy panoramic views at Arthur’s Seat, visit Venna Lake for boating, and explore the lush forests."
            },
            {
                title: "7. Pandharpur",
                description: "Renowned for its spiritual significance and the Vithoba temple, Pandharpur is an important pilgrimage site. Our buses make reaching this revered location simple and convenient. Experience the vibrant festivities during Ashadhi Ekadashi and immerse yourself in the local culture."
            },
            {
                title: "8. Ajanta and Ellora Caves",
                description: "These ancient caves are famous for their intricate carvings and historical significance. Our buses provide a comfortable journey to explore these UNESCO World Heritage Sites. Discover stunning rock-cut architecture, Buddhist stupas, and magnificent frescoes that narrate ancient stories."
            },
            {
                title: "9. Lavasa",
                description: "A planned hill city celebrated for its picturesque setting. Our buses ensure a smooth ride to Lavasa, making it perfect for both business and leisure trips. Enjoy the scenic waterfront, indulge in adventure sports, and savor local cuisine at charming eateries."
            },
            {
                title: "10. Tuljapur",
                description: "Home to the famous Tulja Bhavani Temple, Tuljapur is a key pilgrimage destination. We provide reliable bus services to facilitate your visit to this sacred site. Explore the temple's rich history, partake in local festivals, and appreciate the surrounding natural beauty."
            }
        ],
        
         services : [
            {
                name: "Mini Bus Booking for Outstation",
                description: "SSKTravels24 offers mini bus booking for outstation travel, providing a comfortable and convenient option for your trips. Our mini buses are equipped with modern amenities and spacious interiors, making them perfect for smaller groups looking to explore various destinations."
            },
            {
                name: "Bus Hire for Outstation in Hadapsar",
                description: "If you’re in Hadapsar and need to hire a bus for an outstation trip, SSKTravels24 is here to help. We provide reliable and comfortable bus hire services to various outstation destinations, ensuring a hassle-free travel experience."
            },
            {
                name: "Outstation Mini Bus Hire",
                description: "For outstation travel, our mini bus hire services offer the perfect solution for smaller groups. Enjoy a smooth and comfortable journey with our well-maintained mini buses, ideal for family trips, corporate outings, or leisure travel."
            },
            {
                name: "Hire a Bus for Outstation Pune to Shirdi",
                description: "Travel from Pune to Shirdi with ease by hiring a bus from SSKTravels24. Our buses are designed to provide a reliable and comfortable travel experience, allowing you to focus on your spiritual journey."
            },
            {
                name: "Mini Bus for Outstation from Kharadi",
                description: "For those needing a mini bus for outstation travel from Kharadi, SSKTravels24 offers excellent services to ensure a comfortable and efficient journey to your desired destination."
            },
            {
                name: "Book Bus for Outstation",
                description: "Booking a bus for outstation travel with SSKTravels24 is simple and convenient. Choose from our range of buses to suit your group size and travel needs, and enjoy a seamless booking experience."
            },
            {
                name: "Outstation Bus Hire in Baner",
                description: "If you’re located in Baner and need to hire a bus for an outstation trip, SSKTravels24 provides top-notch services to ensure a pleasant journey. Our buses are equipped with all necessary amenities for your comfort."
            },
            {
                name: "Mini Bus Booking Near Me",
                description: "Find mini bus booking services near you with SSKTravels24. We offer convenient options for booking mini buses for your outstation trips, ensuring a reliable and enjoyable travel experience."
            },
            {
                name: "Kalyani Nagar to Outstation Bus Hire",
                description: "For outstation travel from Kalyani Nagar, SSKTravels24 provides reliable bus hire services to ensure a smooth and comfortable journey. Our buses cater to various group sizes and travel preferences."
            },
            {
                name: "14, 17, 25, 32 Seater Hire for Outstation Trip",
                description: "Choose from our range of 14-seater, 17-seater, 25-seater, and 32-seater buses for your outstation trip. SSKTravels24 offers a variety of options to suit your group size and travel needs, providing comfort and convenience."
            },
            {
                name: "Pune to Ajanta Ellora Caves Bus Tour",
                description: "Experience the wonders of the Ajanta and Ellora Caves with our dedicated bus tour service from Pune. Our buses are equipped with comfortable seating and modern amenities, ensuring your journey to these historic marvels is pleasant and enjoyable."
            },
            {
                name: "AC 17-Seater Bus on Rent in Pune",
                description: "For smaller groups seeking comfort and style, SSKTravels24 offers AC 17-seater buses for rent in Pune. These air-conditioned buses are perfect for family outings, corporate meetings, or group tours, providing a comfortable environment for all passengers."
            },
            {
                name: "Private Bus Service in Pune for Office",
                description: "For corporate outings or meetings, SSKTravels24 offers private bus services in Pune. Our buses are designed to provide a comfortable and efficient travel experience for employees, ensuring your office events run smoothly."
            },
            {
                name: "Outstation School Picnic Bus on Rent",
                description: "Make school picnics memorable with SSKTravels24’s outstation bus rental services. Our buses are ideal for transporting students, offering comfort and safety for school trips, along with experienced drivers to ensure a smooth journey."
            },
            {
                name: "Tempo Traveller for Outstation",
                description: "If you need a compact yet comfortable vehicle for your outstation trip, consider renting a tempo traveller from SSKTravels24. Perfect for medium-sized groups, our tempo travellers provide a flexible and enjoyable ride with ample space for luggage."
            },
            {
                name: "Book Tempo Traveller for Outstation",
                description: "Booking a tempo traveller for your outstation journey is easy with SSKTravels24. Our straightforward process and range of vehicles ensure a reliable and comfortable experience, tailored to your group's needs."
            },
            {
                name: "Tata Winger for Outstation in Pune",
                description: "SSKTravels24 also provides Tata Winger vehicles for outstation trips. The Tata Winger combines comfort and efficiency, making it an excellent choice for group travel. Experience a smooth ride with our well-maintained fleet."
            },
            {
                name: "Bus Hire for Outstation in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Mini Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers, providing excellent service from booking to your journey's end."
            }
        ],        
         tableData : [
            ['- Mini Bus Booking for Outstation', '- Hire Bus for Outstation in Hadapsar'],
            ['- Outstation Mini Bus Hire', '- Mini Bus on Rent for Outstation in Pune'],
            ['- Hire a Bus for Outstation Pune to Shirdi', '- Mini Bus for Outstation from Kharadi'],
            ['- Book Bus for Outstation', '- Outstation Bus Hire in Baner'],
            ['- Mini Bus Booking Near Me', '- Kalyani Nagar to Outstation Bus Hire'],
            ['- 14, 17, 25, 32 Seater Hire for Outstation Trip', '- 14, 17, 25, 32, 35 Seater Hire for Outstation from Pune'],
            ['- Pune to Ajanta Ellora Caves Bus Tour', '- AC 17 Seater Bus on Rent in Pune'],
            ['- Bus Hire for Outstation in Pune', '- Private Bus Service in Pune for Office'],
            ['- Outstation School Picnic Bus on Rent', '- Tempo Traveller for Outstation'],
            ['- Book Tempo Traveller for Outstation', '- Mini Bus on Rent for Outstation in Pune'],
            ['- Tata Winger for Outstation in Pune', '- Additional Services for Group Travel']
        ],        
    
        whychoose : [
            {
                "WhyChooseheading": "Diverse Fleet Options",
                "WhyChoosedescription": "At SSKTravels24, we provide a wide range of bus sizes to suit various group sizes and needs. From compact 14-seater minibuses to spacious 35-seater coaches, our diverse fleet ensures that you find the perfect vehicle for your outstation journey."
            },
            {
                "WhyChooseheading": "Superior Comfort",
                "WhyChoosedescription": "Our buses are designed with your comfort in mind. Enjoy plush seating, ample legroom, air conditioning, and modern amenities to make your travel experience as enjoyable as possible."
            },
            {
                "WhyChooseheading": "Experienced Drivers",
                "WhyChoosedescription": "Our professional drivers are highly trained and possess extensive knowledge of outstation routes. They prioritize your safety and comfort, allowing you to relax and enjoy the journey."
            },
            {
                "WhyChooseheading": "Reliable Service",
                "WhyChoosedescription": "Punctuality is at the core of our operations. We guarantee timely arrivals and adherence to your travel schedule, ensuring a reliable and efficient journey every time."
            },
            {
                "WhyChooseheading": "Affordable Rates",
                "WhyChoosedescription": "We offer competitive pricing without compromising on quality. Our transparent pricing model ensures you receive excellent value for your money, with no hidden fees or surprises."
            },
            {
                "WhyChooseheading": "High Safety Standards",
                "WhyChoosedescription": "Safety is paramount at SSKTravels24. Our buses undergo regular maintenance and stringent safety checks to ensure they meet the highest safety standards, giving you peace of mind during your travels."
            },
            {
                "WhyChooseheading": "Flexible Booking Options",
                "WhyChoosedescription": "We provide flexible booking options tailored to your travel plans. Whether you need a bus for a day trip or an extended tour, our straightforward booking process is designed to accommodate your needs."
            },
            {
                "WhyChooseheading": "Excellent Customer Support",
                "WhyChoosedescription": "Our dedicated customer service team is available to assist you with any queries or issues throughout your booking process and journey, ensuring a seamless experience."
            },
            {
                "WhyChooseheading": "Customized Services",
                "WhyChoosedescription": "We offer tailored services to meet your specific requirements. Whether for corporate events, family outings, or group tours, we can customize our offerings to match your needs perfectly."
            },
            {
                "WhyChooseheading": "Extensive Coverage",
                "WhyChoosedescription": "Our bus hire services cover a wide range of outstation destinations from Pune, including popular spots like Shirdi, Lonavala, and Goa. No matter where you’re headed, SSKTravels24 has you covered."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for outstation hire with SSKTravels24?",
            answer: "We offer a variety of bus sizes, including 14-seater, 17-seater, 25-seater, 32-seater, and 35-seater buses. Choose the size that best fits your group's needs."
        },
        {
            question: "How can I book a bus for an outstation trip with SSKTravels24?",
            answer: "Booking is simple! Contact us via our website or call our customer service team. Provide your travel details, and we’ll assist you in selecting and booking the right bus for your trip."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes, our buses come with modern amenities such as air conditioning, comfortable seating, and ample luggage space to ensure a pleasant journey."
        },
        {
            question: "How much does it cost to hire a bus for an outstation trip?",
            answer: "The cost varies based on factors like bus size, trip distance, and duration. Contact us for a detailed quote based on your specific requirements."
        },
        {
            question: "Are the drivers experienced?",
            answer: "Absolutely! All our drivers are experienced and trained for outstation routes, prioritizing your safety and comfort throughout the journey."
        },
        {
            question: "What safety measures are in place for the buses?",
            answer: "Our buses undergo regular maintenance and safety checks to meet high safety standards, along with established safety protocols for secure travel."
        },
        {
            question: "Can I make changes to my booking?",
            answer: "Yes, changes can be made based on availability. Simply reach out to our customer service team to discuss any modifications needed."
        },
        {
            question: "What is the booking process for an outstation bus?",
            answer: "To book a bus, contact us through our website or phone. Provide details such as travel dates, destination, and bus size requirements, and we will take care of the rest."
        },
        {
            question: "How far in advance should I book a bus for my trip?",
            answer: "We recommend booking as early as possible to ensure availability, especially during peak travel times. Early bookings often secure the best rates."
        },
        {
            question: "What if I have special requirements for my trip?",
            answer: "If you have specific needs or requests, such as additional amenities, let us know when booking. We will do our best to accommodate your requirements."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mrs. Anjali Deshmukh",
            text: "We recently hired a 32-seater bus from SSKTravels24 for our trip from Pune to Shirdi, and I couldn’t be more pleased with the service. The bus was not only comfortable and well-maintained, but the driver was also professional and friendly. The entire process, from booking to the journey itself, was seamless. I highly recommend SSKTravels24 for anyone in need of reliable and comfortable outstation bus hire!"
        },
        {
            name: "Mr. Rajesh Kulkarni",
            text: "Our company organized a corporate outing to Mahabaleshwar using SSKTravels24, and the experience was exceptional. The 25-seater bus was ideal for our group, and the amenities provided ensured everyone was at ease throughout the trip. The driver was punctual and navigated the route effortlessly, making our journey completely stress-free. We will definitely choose SSKTravels24 for our future outstation travel needs!"
        }
    ];
    
    
    const contactData = {
        heading: "Bus Hire for Outstation in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus Hire for Outstation in Pune | Explore Beyond the City</title>
  <meta name="description" content="Discover bus hire options for outstation trips from Pune to popular destinations. Mini buses, luxury options, and more." />
  <meta name="keywords" content="Mini bus booking for outstation | Hire bus for outstation in Hadapsar | SSK Travels24 Pune | Outstation Mini Bus hire | Mini bus on rent for outstation in Pune | Hire a bus for outstation Pune to Shirdi | Mini bus for outstation from Kharadi | Book bus for outstation | Outstation bus hire in Baner | Mini bus booking near me | Kalyani Nagar to outstation bus hire | 14 17 25 32 Seater hire for outstation trip | Pune to Ajanta Ellora Caves bus tour | AC 17 seater bus on rent in Pune | Private bus service in Pune for office | Outstation school picnic bus on rent | Tempo traveller for outstation | Book tempo traveller for outstation | Tata Winger for outstation in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus Hire for Outstation in Pune | Explore Beyond the City" />
  <meta property="og:description" content="Book your bus for outstation travel from Pune to various popular destinations!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-hire-for-outstation-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//outstation-bus-service-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-38.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Bus Hire for Outstation in Pune with SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Bushireforoutstation;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function PunetomahabaleshwerUrbaniaonrent(){


    const cardData = 
    {
        keyword: 'Pune to Mahabaleshwar Urbania On Rent',
        heading: 'SSKTravels24: Pune to Mahabaleshwar Urbania On Rent',
        headingDescription: 'Experience the beauty of Mahabaleshwar with our luxurious Force Urbania buses for rent. Enjoy a comfortable and convenient journey from Pune to this picturesque hill station, ensuring an unforgettable getaway.',
        
        topPlaces: [
            {
                title: "1. Venna Lake",
                description: "A serene spot for boating and picnics, perfect for relaxing amidst stunning landscapes."
            },
            {
                title: "2. Pratapgad Fort",
                description: "A historic fort offering panoramic views and a glimpse into Maratha history."
            },
            {
                title: "3. Elephant's Head Point",
                description: "A scenic viewpoint known for its unique rock formation and breathtaking vistas."
            },
            {
                title: "4. Arthur's Seat",
                description: "Dubbed the 'Queen of the Points', offering spectacular views of the Konkan coast."
            },
            {
                title: "5. Mapro Garden",
                description: "Famous for its strawberry farms and delicious fruit-based products."
            },
            {
                title: "6. Lingmala Waterfall",
                description: "A must-visit waterfall for nature lovers, surrounded by a tranquil forest."
            },
            {
                title: "7. Wilson Point",
                description: "The highest point in Mahabaleshwar, ideal for witnessing stunning sunrises."
            },
            {
                title: "8. Sunset Point",
                description: "A perfect spot to enjoy mesmerizing sunset views over the hills."
            },
            {
                title: "9. Mahabaleshwar Temple",
                description: "An ancient temple dedicated to Lord Shiva, known for its intricate carvings."
            },
            {
                title: "10. Bhilar Waterfall",
                description: "A hidden gem known for its peaceful surroundings and serene beauty."
            }
        ],
        
        services: [
            {
                name: "Force Urbania on Rent for Pune to Mahabaleshwar",
                description: "Enjoy a luxurious and comfortable ride with our well-maintained Force Urbania buses, ensuring a smooth journey."
            },
            {
                name: "Force Urbania 17 Seater for Rent",
                description: "Ideal for medium-sized groups, providing ample space and comfort for a pleasant journey."
            },
            {
                name: "Pune to Panchgani Urbania On Rent",
                description: "Explore the beautiful hill station of Panchgani with our spacious and comfortable Urbania buses."
            },
            {
                name: "Force Urbania Hire in Pimpri Chinchwad",
                description: "Convenient rentals available for residents of Pimpri Chinchwad for local outings and trips."
            },
            {
                name: "Contact Number for Pune to Mahabaleshwar Urbania On Rent",
                description: "For bookings and inquiries, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['- Pune to Mahabaleshwar Urbania On Rent', '- Pune to Panchgani Urbania On Rent'],
            ['- Pune to Mahabaleshwar Urbania On Rent Contact Number', '- Force Urbania Luxury Mini Bus on Rent in Pune'],
            ['- Force Urbania 17 Seater for Rent', '- Pune to Tapola Urbania on Rent'],
            ['- Force Urbania on Rent in Pimpri Chinchwad', '- Pune to Kolhapur Force Urbania on Rent'],
            ['- Pune to Jyotiba Force Urbania on Rent', '- Pune to Wai Satara Force Urbania on Rent'],
            ['- Pune to Kaas Plateau Pathar for Car Urbania on Rent', '- Force Urbania Hire in Karve Nagar Kothrud'],
            ['- Force Urbania Hire in Shivaji Nagar Pune', '- Force Urbania Hire in Pune Railway Station']
        ],
    
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose Us: Pune to Mahabaleshwar Urbania On Rent – SSKTravels24",
                "WhyChoosedescription": "Unmatched Comfort: Our Force Urbania buses prioritize your comfort with spacious seating and generous legroom, making long journeys enjoyable. The advanced climate control ensures a pleasant atmosphere, so you can sit back and relax as you travel from Pune to Mahabaleshwar."
            },
            {
                "WhyChooseheading": "Elegant Travel Experience:",
                "WhyChoosedescription": "Elevate your journey with our luxurious Urbania buses. Featuring stylish interiors, modern entertainment systems, and high-quality audio setups, we guarantee a travel experience that’s both comfortable and sophisticated."
            },
            {
                "WhyChooseheading": "Dependable Service:",
                "WhyChoosedescription": "At SSKTravels24, we take pride in our reliability. Our Urbania buses undergo regular maintenance checks, ensuring a seamless and worry-free ride to the breathtaking landscapes of Mahabaleshwar."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our skilled drivers are not only experienced but also familiar with the best routes to Mahabaleshwar. They focus on safety and comfort, ensuring that you reach your destination smoothly."
            },
            {
                "WhyChooseheading": "Tailored Packages:",
                "WhyChoosedescription": "We understand that every trip is unique. Our flexible rental options can be customized to fit your specific requirements, whether it’s a day trip, weekend escape, or an extended holiday."
            },
            {
                "WhyChooseheading": "Transparent Pricing:",
                "WhyChoosedescription": "Enjoy exceptional service without breaking the bank. Our straightforward pricing model ensures you know exactly what you’re paying for, with no hidden fees or surprises."
            },
            {
                "WhyChooseheading": "Safety as a Priority:",
                "WhyChoosedescription": "Your safety is paramount. Our Urbania buses are equipped with state-of-the-art safety features, including GPS tracking, emergency exits, and first-aid kits, ensuring peace of mind during your journey."
            },
            {
                "WhyChooseheading": "Effortless Booking Process:",
                "WhyChoosedescription": "Reserving your Urbania bus is quick and easy. With our intuitive online booking platform, planning your trip is just a few clicks away, allowing you to secure your vehicle hassle-free."
            },
            {
                "WhyChooseheading": "Local Insights:",
                "WhyChoosedescription": "As a locally operated travel agency, we offer valuable recommendations and insights about Mahabaleshwar, helping you make the most of your visit to this stunning hill station."
            },
            {
                "WhyChooseheading": "Dedicated Customer Support:",
                "WhyChoosedescription": "Our customer service team is always ready to assist you with any questions or concerns, ensuring a smooth experience from booking to the completion of your journey."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "How can I book a Force Urbania for my trip from Pune to Mahabaleshwar?",
            answer: "You can easily book our Force Urbania buses by contacting us directly at +91 8956170493 or +91 7276307000 or through our user-friendly online booking platform on our website. Our team is here to guide you through the process."
        },
        {
            question: "What factors affect the cost of renting a Force Urbania for Mahabaleshwar?",
            answer: "Rental costs depend on various factors, including the duration of the trip, the number of passengers, and any additional services you may require. For an accurate quote, please reach out to us directly."
        },
        {
            question: "What amenities can I expect on the Urbania buses?",
            answer: "Our Force Urbania buses are equipped with a range of amenities for long-distance travel, including comfortable seating, air conditioning, entertainment systems, and spacious luggage compartments."
        },
        {
            question: "Can I customize my rental package for Mahabaleshwar?",
            answer: "Yes! We offer customizable rental packages that cater to your specific travel needs, whether for a day trip or an extended stay. Let us know your preferences, and we’ll create the perfect itinerary for you."
        },
        {
            question: "How experienced are your drivers?",
            answer: "Our drivers have extensive experience and are familiar with the Pune to Mahabaleshwar route. They are committed to ensuring your safety and providing a pleasant travel experience."
        },
        {
            question: "What safety measures are in place for the Force Urbania buses?",
            answer: "Our Urbania buses are fitted with modern safety features, including GPS tracking, seat belts, emergency exits, and first-aid kits to provide a secure travel environment."
        },
        {
            question: "Is it possible to change my booking after it has been confirmed?",
            answer: "Yes, you can make changes to your booking based on availability. Please contact us as soon as possible to discuss any adjustments to your reservation."
        },
        {
            question: "Is there a minimum rental duration for the buses?",
            answer: "A minimum rental duration may apply depending on the season and availability. For specific details, please consult our customer service team."
        },
        {
            question: "What should I do if I have special requests?",
            answer: "If you have any specific requests or needs, please inform us at the time of booking. We will do our utmost to accommodate your wishes for a comfortable trip."
        },
        {
            question: "How can I get in touch with SSKTravels24 for more details?",
            answer: "For more information, you can reach us at +91 8956170493 or +91 7276307000 or visit our website. Our customer support team is here to help you with any questions or concerns you may have."
        }
    ];
    
    const testimonials = [
        {
            name: "Miss Priya Desai",
            text: "I recently booked a Force Urbania with SSKTravels24 for a trip from Pune to Mahabaleshwar, and it exceeded all my expectations. The bus was spotless and incredibly comfortable, making our long journey a pleasure. The entertainment system and climate control added to the overall experience. Our driver was highly professional and knew the route like the back of his hand, ensuring we arrived on time. The customer service team was attentive and responsive, making the booking process seamless. I wholeheartedly recommend SSKTravels24 for anyone looking to rent a luxury vehicle for their travels!"
        },
        {
            name: "Mr. Rajesh Kumar",
            text: "Our trip from Pune to Mahabaleshwar with SSKTravels24 was nothing short of amazing. The Force Urbania bus we rented was spacious, clean, and packed with all the comforts we could ask for. The journey was smooth, and our driver was both courteous and knowledgeable. What really stood out was the exceptional service provided by the team at SSKTravels24, from the initial booking to the actual trip. They made our travel experience enjoyable and stress-free. I will definitely choose SSKTravels24 for my future journeys and highly recommend their services!"
        }
    ];
    
    const contactData = {
        heading: "Pune to Mahabaleshwar Urbania On Rent",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Mahabaleshwar Urbania On Rent | Affordable Rental Services</title>
  <meta name="description" content="Rent Force Urbania for comfortable travel from Pune to Mahabaleshwar and Panchgani. Book your luxury mini bus today!" />
  <meta name="keywords" content="Pune to Mahabaleshwar Urbania on rent | Pune to Panchgani Urbania on rent | Pune to Mahabaleshwar Urbania on rent contact number | Force Urbania luxury mini bus on rent in Pune | Force Urbania 17 seater for rent | Pune to Tapola Urbania on rent | Force Urbania on rent in Pimpri Chinchwad | Pune to Kolhapur Force Urbania on rent | Pune to Jyotiba Force Urbania on rent | Pune to Wai Satara Force Urbania on rent | Pune to Kaas Plateau Pathar for car Urbania on rent | Force Urbania hire in Karve Nagar Kothrud | Force Urbania hire in Shivaji Nagar Pune | Force Urbania hire in Pune railway station" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Mahabaleshwar Urbania On Rent | Affordable Rental Services" />
  <meta property="og:description" content="Experience comfortable travel with Force Urbania for trips from Pune to Mahabaleshwar. Contact us to book your journey!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-mahabaleshwar-urbania-rent" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//urbania-mahabaleshwar-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-23.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PunetomahabaleshwerUrbaniaonrent;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function KiaCarensonRentinPune(){


    const cardData = 
    {
        keyword: 'Kia Carens on Rent in Pune',
        heading: 'SSK Travels 24: Kia Carens on Rent in Pune',
        headingDescription: 'Planning a trip around Pune? Renting a Kia Carens from SSK Travels 24 is the perfect choice for a comfortable, spacious, and stylish vehicle, ideal for family trips, road trips, or business journeys. Discover the top reasons to choose Kia Carens for your travel needs.',
        
        topPlaces: [
          {
            title: "1. Spacious Interior",
            description: "The Kia Carens boasts a roomy interior that can comfortably accommodate up to seven passengers. Enjoy a relaxing journey with ample legroom and flexible seating arrangements."
          },
          {
            title: "2. Modern Features and Technology",
            description: "Equipped with the latest technology, including a user-friendly infotainment system, Bluetooth connectivity, and premium audio, you can stay connected and entertained."
          },
          {
            title: "3. Safety First",
            description: "The Kia Carens is designed with advanced safety features like multiple airbags, ABS with EBD, and rear parking sensors, ensuring you and your passengers are well-protected."
          },
          {
            title: "4. Smooth Performance",
            description: "Experience a powerful engine with smooth handling, perfect for navigating city streets or cruising on the highway."
          },
          {
            title: "5. Fuel Efficiency",
            description: "With impressive mileage, the Kia Carens helps you save on fuel costs during your rental period, leaving more budget for exploring Pune's attractions."
          },
          {
            title: "6. Affordable Rental Rates",
            description: "Enjoy competitive rental rates without compromising on quality or service. Our transparent pricing ensures no hidden costs."
          },
          {
            title: "7. Flexible Rental Options",
            description: "We offer a variety of rental options tailored to your needs, whether you need the Kia Carens for a day, a week, or longer."
          },
          {
            title: "8. Excellent Customer Service",
            description: "Our dedicated customer service team ensures a seamless rental experience, assisting you from booking to vehicle return."
          },
          {
            title: "9. Convenient Pickup and Drop-off Locations",
            description: "Select from multiple pickup and drop-off locations in Pune for a hassle-free rental experience."
          },
          {
            title: "10. Exploration Opportunities",
            description: "With a Kia Carens on rent, explore Pune and surrounding areas at your own pace, visiting attractions like Shaniwar Wada and Sinhagad Fort."
          }
        ],
        
        services: [
          {
              "name": "Kia Carens Car Rental Service in Pimpri Chinchwad",
              "description": "Our Kia Carens rental service ensures a smooth ride for families and groups, offering comfort for every occasion. With spacious interiors and modern amenities, you can enjoy your journey whether it’s a short trip or a long adventure. We prioritize your comfort and satisfaction, making every ride enjoyable."
          },
          {
              "name": "Kia Carens on Rent for Outstation Trips",
              "description": "Perfect for comfortable journeys to your desired destination, our Kia Carens provides ample space for passengers and luggage, along with impressive fuel efficiency. Enjoy long drives without the stress of cramped seating, and experience the freedom to explore at your own pace."
          },
          {
              "name": "Kia Carens on Hire for Corporate Events",
              "description": "Make a lasting impression at corporate events with our Kia Carens, combining elegance with functionality for transporting clients or employees. Our well-maintained vehicles ensure a professional experience, complete with amenities to make travel comfortable and efficient."
          },
          {
              "name": "Pune to Mahabaleshwar Kia Carens on Rent",
              "description": "Enjoy a scenic drive to Mahabaleshwar, experiencing the beauty of the Western Ghats in comfort. With our Kia Carens, you can savor the stunning landscapes while having all the space you need for your family and luggage. This is the perfect choice for a memorable getaway."
          },
          {
              "name": "Pune to Shirdi Kia Carens on Rent",
              "description": "Visit the sacred city of Shirdi hassle-free with our rental service, allowing you to focus on your spiritual experience. The Kia Carens offers comfort for long drives, ensuring you arrive refreshed and ready to immerse yourself in your pilgrimage."
          },
          {
              "name": "Cab Service in Pune Kia Carens on Rent",
              "description": "Experience convenience and comfort for local travel with our Kia Carens rental, ideal for business meetings, city exploration, or family outings. With our reliable drivers and spacious vehicle, you can navigate Pune effortlessly while enjoying a comfortable ride."
          },
          {
              "name": "Contact Number for Kia Carens on Rent in Pune",
              "description": "For prompt and efficient Kia Carens rental services, contact SSKTravels24 at +91 8956170493. Our dedicated team is ready to assist you with bookings, ensuring a seamless rental experience tailored to your needs."
          }
      ],      
        tableData: [
            ['Kia Carens Car Rental Service in Pimpri Chinchwad', 'Kia Carens on Rent in Pune for Outstation'],
            ['Kia Carens on Hire for Corporate Events', 'Pune to Mahabaleshwar Kia Carens on Rent'],
            ['Pune to Shirdi Kia Carens on Rent', 'Pune to Outstation Kia Carens on Rent'],
            ['Cab Service in Pune Kia Carens on Rent', 'Kia Carens on Rent in Pimpri Chinchwad'],
            ['7 Seater Kia Carens on Rent in Pune', 'Pune Local Kia Carens on Rent'],
            ['Pune to Outstation Kia Carens Taxi Fare Per Km', 'Kia Carens on Rent in Hinjewadi'],
            ['Kia Carens on Rent in Kharadi Pune', 'Car Hire Agency in Viman Nagar Pune'],
            ['Kia Carens on Rent in Magarpatta City', 'SSK Travels24 in Pune']
          ],
          
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for Kia Carens On Rent in Pune – SSKTravels24?",
              WhyChoosedescription: "SSKTravels24 is committed to providing top-notch rental services, ensuring a comfortable and enjoyable experience with our Kia Carens."
            },
            {
              WhyChooseheading: "Spacious Comfort:",
              WhyChoosedescription: "The Kia Carens offers ample space for passengers and luggage, making it ideal for family trips, group outings, or corporate travel."
            },
            {
              WhyChooseheading: "Modern Features:",
              WhyChoosedescription: "Equipped with the latest technology, including a touchscreen infotainment system, Bluetooth connectivity, and advanced safety features."
            },
            {
              WhyChooseheading: "Flexible Rental Options:",
              WhyChoosedescription: "We provide a variety of rental packages to suit your needs, whether for a few hours, a day, or longer."
            },
            {
              WhyChooseheading: "Affordable Pricing:",
              WhyChoosedescription: "Our rental rates are competitive and transparent, ensuring you get the best value without hidden charges."
            },
            {
              WhyChooseheading: "Professional Drivers:",
              WhyChoosedescription: "Our experienced drivers are knowledgeable about Pune and its surroundings, ensuring a safe and pleasant driving experience."
            },
            {
              WhyChooseheading: "24/7 Availability:",
              WhyChoosedescription: "We are committed to serving you around the clock for any transportation needs."
            },
            {
              WhyChooseheading: "Well-Maintained Vehicles:",
              WhyChoosedescription: "All our Kia Carens are regularly serviced to ensure they are in top condition for your journey."
            },
            {
              WhyChooseheading: "Easy Booking Process:",
              WhyChoosedescription: "Our user-friendly online booking system allows you to reserve your Kia Carens with ease."
            },
            {
              WhyChooseheading: "Customer Satisfaction:",
              WhyChoosedescription: "At SSKTravels24, we prioritize customer satisfaction, ensuring your experience is seamless and enjoyable."
            },
            {
              WhyChooseheading: "Local Expertise:",
              WhyChoosedescription: "Familiar with Pune's roads and traffic patterns, our drivers help you navigate efficiently."
            }
          ]
    };    

    
   
    const faqData = [
        {
          question: "What are the rental rates for Kia Carens in Pune?",
          answer: "Rental rates vary based on the duration of your rental and specific requirements. Please contact us for a personalized quote."
        },
        {
          question: "Is there a minimum rental period for the Kia Carens?",
          answer: "Yes, we typically require a minimum rental period, which may vary based on demand. Please check with our team for specific details."
        },
        {
          question: "Can I book the Kia Carens for outstation travel?",
          answer: "Absolutely! Our Kia Carens is perfect for outstation trips, and we offer competitive rates for such journeys."
        },
        {
          question: "Are the drivers experienced?",
          answer: "Yes, all our drivers are well-trained and experienced, ensuring a safe and comfortable ride."
        },
        {
          question: "How can I book a Kia Carens?",
          answer: "You can easily book online through our website or by contacting our customer service team."
        },
        {
          question: "Is there a cancellation policy?",
          answer: "Yes, we have a cancellation policy in place. Please refer to our terms and conditions or contact us for more details."
        },
        {
          question: "Are the vehicles well-maintained?",
          answer: "Yes, we ensure that all our vehicles, including the Kia Carens, are regularly serviced and maintained to provide a safe and comfortable experience."
        },
        {
          question: "Do you provide additional services like airport pickup?",
          answer: "Yes, we offer airport pickup and drop services. Just let us know your requirements while booking."
        },
        {
          question: "Can I rent a Kia Carens for corporate events?",
          answer: "Certainly! Our Kia Carens is an excellent choice for corporate travel, and we can tailor packages to suit your needs."
        },
        {
          question: "Is insurance included in the rental?",
          answer: "Yes, all our rentals come with insurance coverage for your peace of mind. Please inquire for more details on the coverage specifics."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Rahul Mehta",
          text: "I recently rented a Kia Carens from SSKTravels24 for a family trip to Mahabaleshwar, and it was an incredible experience! The car was spacious and comfortable, which made our journey enjoyable. The driver was professional and knew all the best routes. I highly recommend their service for anyone looking for a reliable car rental in Pune!"
        },
        {
          name: "Miss Ananya Joshi",
          text: "SSKTravels24 provided us with a fantastic Kia Carens for our corporate event. The vehicle was clean, well-maintained, and had all the modern features we needed. Our clients were impressed with the comfort and style of the ride. The booking process was smooth, and the team was very accommodating. I’ll definitely use their services again!"
        }
      ];
      
      const contactData = {
        heading: "Kia Carens On Rent in Pune Contact Number",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Kia Carens On Rent in Pune | Affordable 7-Seater Rentals</title>
  <meta name="description" content="Book Kia Carens on rent in Pune for spacious and comfortable travel. Ideal for outstation trips, corporate events, and local journeys." />
  <meta name="keywords" content="Kia Carens rental Pune, Kia Carens on rent for outstation, Kia Carens hire for corporate events, Pune to Mahabaleshwar Kia Carens, cab service in Pune" />
  <meta property="og:title" content="Kia Carens On Rent in Pune | SSK Travels24" />
  <meta property="og:description" content="Experience the comfort of Kia Carens for your travel needs in Pune. Perfect for families and corporate events. Book now!" />
  <meta property="og:url" content="https://https://ssktravels24.com//kia-carens-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//kia-carens-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
  <img src='\images\keyword-base\Keyword 1-32.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default KiaCarensonRentinPune;
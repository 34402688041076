
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punesadeteenshaktipeeth(){


    const cardData = 
    {
        keyword: 'Pune Sade Teen Shakti Peeth Bus on Rent',
        heading: 'SSKTravels24: Pune Sade Teen Shakti Peeth Bus on Rent',
        headingDescription: 'over the spiritual significance of the Sade Teen Shakti Peeth with SSKTravels24 is premium bus rental services. Our dedicated bus service is designed to make your pilgrimage comfortable and memorable as you visit these revered Shakti Peeths.',
        
        topPlaces : [
            {
                title: "1. Venna Lake",
                description: "A serene spot for boating and picnics, perfect for relaxing amidst stunning landscapes."
            },
            {
                title: "2. Pratapgad Fort",
                description: "A historic fort offering panoramic views and a glimpse into Maratha history."
            },
            {
                title: "3. Elephant's Head Point",
                description: "A scenic viewpoint known for its unique rock formation and breathtaking vistas."
            },
            {
                title: "4. Arthur's Seat",
                description: "Dubbed the 'Queen of the Points', offering spectacular views of the Konkan coast."
            },
            {
                title: "5. Mapro Garden",
                description: "Famous for its strawberry farms and delicious fruit-based products."
            },
            {
                title: "6. Lingmala Waterfall",
                description: "A must-visit waterfall for nature lovers, surrounded by a tranquil forest."
            },
            {
                title: "7. Wilson Point",
                description: "The highest point in Mahabaleshwar, ideal for witnessing stunning sunrises."
            },
            {
                title: "8. Sunset Point",
                description: "A perfect spot to enjoy mesmerizing sunset views over the hills."
            },
            {
                title: "9. Mahabaleshwar Temple",
                description: "An ancient temple dedicated to Lord Shiva, known for its intricate carvings."
            },
            {
                title: "10. Bhilar Waterfall",
                description: "A hidden gem known for its peaceful surroundings and serene beauty."
            }
        ],        
        
        services : [
            {
                name: "Sade Teen Shakti Peeth Yatra from Pune",
                description: "Planning the Sade Teen Shakti Peeth Yatra from Pune? SSKTravels24 offers dedicated bus and tempo traveller services to make your pilgrimage comfortable and memorable."
            },
            {
                name: "Pune to Mahalaxmi Temple at Kolhapur Bus Hire",
                description: "SSKTravels24 provides reliable bus hire services for your pilgrimage to Mahalaxmi Temple in Kolhapur, ensuring safety and punctuality."
            },
            {
                name: "Pune to Tuljapur Tempo Traveller on Rent",
                description: "For a comfortable journey to Tuljapur, SSKTravels24 offers tempo travellers on rent, ideal for small to medium-sized groups."
            },
            {
                name: "Pune to Renuka Mata at Mahurgad Bus on Rent",
                description: "Visit Renuka Mata Temple at Mahurgad with ease by renting a bus from SSKTravels24 for a safe and timely journey."
            },
            {
                name: "Pune to Mahurgad Bus on Rent Package",
                description: "SSKTravels24 offers comprehensive bus rental packages for your trip from Pune to Mahurgad, including comfortable buses for a pleasant journey."
            },
            {
                name: "Pune to Saptashrungi Mata Vani Bus on Rent",
                description: "Plan your visit to Saptashrungi Mata Vani with our specialized bus rental services from Pune."
            },
            {
                name: "Bus Hire for Sade Teen Shakti Peeth Yatra from Pune",
                description: "SSKTravels24 offers tailored bus hire services to cover all key pilgrimage sites for the Sade Teen Shakti Peeth Yatra."
            },
            {
                name: "Mini Bus on Rent for Sade Teen Shakti Peeth Yatra",
                description: "Our mini buses are perfect for small groups, offering comfort and convenience for your pilgrimage."
            },
            {
                name: "14, 17, 20, 25 Seater Bus for Pune to Sade Teen Shakti Peeth Yatra",
                description: "Choose from our range of bus options including 14, 17, 20, and 25-seater buses for the pilgrimage."
            },
            {
                name: "35, 40, 45, 50 Seater Bus for Pune to Sade Teen Shakti Peeth Yatra",
                description: "For larger groups, we provide spacious buses designed to accommodate everyone comfortably."
            },
            {
                name: "Tempo Traveller on Rent for Sade Teen Shakti Peeth Yatra",
                description: "Our tempo traveller rentals are ideal for a comfortable journey to each sacred site."
            },
            {
                name: "Sade Teen Shakti Peeth Tour from Pune Bus Hire",
                description: "Plan your Sade Teen Shakti Peeth tour with our bus hire services for a well-organized pilgrimage."
            },
            {
                name: "17 Seater Tempo Traveller on Rent for Sade Teen Shakti Peeth Yatra",
                description: "Our 17-seater tempo travellers are perfect for smaller groups, ensuring a pleasant journey."
            },
            {
                name: "SSKTravels24 Pune On Rent",
                description: "Your trusted partner for vehicle rental needs related to the Sade Teen Shakti Peeth Yatra, offering a variety of vehicles."
            },
            {
                name: "Pune Sade Teen Shakti Peeth Bus on Rent Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient bus rental services."
            }
        ],        
        tableData : [
            ['- Pune to Mahalaxmi Temple at Kolhapur Bus Hire', '- Pune to Tuljapur Tempo Traveller on Rent'],
            ['- Pune to Renuka Mata at Mahurgad Bus on Rent', '- Pune to Mahurgad Bus on Rent Package'],
            ['- Pune to Saptashrungi Mata Vani Bus on Rent', '- Bus Hire for Sade Teen Shakti Peeth Yatra from Pune'],
            ['- Mini Bus on Rent for Sade Teen Shakti Peeth Yatra', '- 14, 17, 20, 25 Seater Bus for Pune to Sade Teen Shakti Peeth Yatra'],
            ['- 35, 40, 45, 50 Seater Bus for Pune to Sade Teen Shakti Peeth Yatra', '- 3.5 Shakti Peethas Darshan in Maharashtra'],
            ['- Tempo Traveller on Rent for Sade Teen Shakti Peeth Yatra', '- Sade Teen Shakti Peeth Tour from Pune Bus Hire'],
            ['- 17 Seater Tempo Traveller on Rent for Sade Teen Shakti Peeth Yatra', '']
        ],
    
        whychoose : [
            {
                "WhyChooseheading": "Comprehensive Coverage of Shakti Peeth Temples",
                "WhyChoosedescription": "SSKTravels24 offers specialized bus rental services for the Sade Teen Shakti Peeth Yatra, covering all key pilgrimage sites including Mahalaxmi Temple at Kolhapur, Tuljapur, Renuka Mata at Mahurgad, and Saptashrungi Mata Vani. Our dedicated service ensures a seamless journey to each sacred site."
            },
            {
                "WhyChooseheading": "Comfortable and Well-Maintained Buses",
                "WhyChoosedescription": "Our fleet is designed for maximum comfort and convenience during your pilgrimage. We maintain clean, well-equipped buses with essential amenities to enhance your journey."
            },
            {
                "WhyChooseheading": "Experienced and Professional Drivers",
                "WhyChoosedescription": "Our drivers are not only experienced but also knowledgeable about the routes to the Shakti Peeth temples. They are committed to providing a safe, smooth travel experience while adhering to punctual schedules."
            },
            {
                "WhyChooseheading": "Flexible and Customizable Packages",
                "WhyChoosedescription": "We offer customizable bus hire packages tailored to meet your specific needs. Whether you require a mini bus for a small group or a larger bus for bigger gatherings, we can adjust our services accordingly."
            },
            {
                "WhyChooseheading": "Competitive and Transparent Pricing",
                "WhyChoosedescription": "At SSKTravels24, we pride ourselves on competitive pricing for our bus rental services with no hidden fees. Our transparent pricing ensures you receive excellent value for your money."
            },
            {
                "WhyChooseheading": "Reliable and Timely Service",
                "WhyChoosedescription": "Punctuality is key to our service. Our buses are regularly maintained to ensure they arrive on time, allowing you to adhere to your travel schedule and maximize your pilgrimage experience."
            },
            {
                "WhyChooseheading": "Personalized Customer Support",
                "WhyChoosedescription": "Our dedicated customer service team is available to assist with any queries or special requests. We provide personalized support to ensure a smooth booking process and a pleasant travel experience."
            },
            {
                "WhyChooseheading": "Safe and Secure Travel",
                "WhyChoosedescription": "Safety is our top priority. Our buses are equipped with essential safety features, and our drivers are trained to navigate various road conditions, ensuring a secure journey for you and your fellow travelers."
            },
            {
                "WhyChooseheading": "Positive Customer Feedback",
                "WhyChoosedescription": "We have built a strong reputation for reliability and customer satisfaction. Our clients consistently praise our professionalism and quality of service, reflecting our commitment to excellence."
            },
            {
                "WhyChooseheading": "Easy Booking Process",
                "WhyChoosedescription": "Booking a bus with SSKTravels24 is simple and convenient. You can make reservations through our website, via phone, or in person, ensuring a hassle-free experience."
            }
        ]        
    };    

    
   
    const faqData = [
        {
            question: "How can I book a bus for the Sade Teen Shakti Peeth Yatra from Pune?",
            answer: "You can book a bus by visiting our website, calling our customer service hotline, or visiting our office in person. We offer convenient booking options to suit your needs."
        },
        {
            question: "What types of buses are available for the Sade Teen Shakti Peeth Yatra?",
            answer: "We provide a variety of bus options including mini buses, 14-seater, 17-seater, 20-seater, 25-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. Choose the bus size that best fits your group."
        },
        {
            question: "Are there any special amenities provided on the buses?",
            answer: "Yes, our buses are equipped with comfortable seating, air conditioning, and other amenities to ensure a pleasant journey. Specific features may vary by bus type."
        },
        {
            question: "What is the cost of hiring a bus for the Sade Teen Shakti Peeth Yatra?",
            answer: "The cost varies based on factors such as bus size, duration of hire, and any additional services required. Contact us for a customized quote based on your needs."
        },
        {
            question: "Can I customize the bus rental package for the Sade Teen Shakti Peeth Yatra?",
            answer: "Yes, we offer customizable packages to meet your travel requirements. You can adjust the itinerary and services to fit your pilgrimage plans."
        },
        {
            question: "Are your drivers experienced and knowledgeable about the routes?",
            answer: "Absolutely! Our drivers are experienced and familiar with the routes to all the Shakti Peeth temples. They prioritize your safety and comfort throughout the journey."
        },
        {
            question: "How far in advance should I book a bus for the Sade Teen Shakti Peeth Yatra?",
            answer: "We recommend booking as early as possible to secure your preferred bus and date. Early booking helps with better planning and availability."
        },
        {
            question: "What is your cancellation policy?",
            answer: "Our cancellation policy includes specific terms and conditions, with potential charges based on the timing of the cancellation. Please contact our customer service team for detailed information."
        },
        {
            question: "Are the buses cleaned and maintained regularly?",
            answer: "Yes, we ensure that all our buses are thoroughly cleaned and regularly maintained to provide a comfortable and hygienic travel experience."
        },
        {
            question: "Can I request additional services or special requirements?",
            answer: "Yes, we can accommodate special requests or additional services based on availability. Please inform us of any specific needs when making your booking."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Rajesh Mehta",
            text: "Our pilgrimage to the Sade Teen Shakti Peeth temples from Pune was an unforgettable experience, thanks to SSKTravels24. We hired a 35-seater bus for our group, and it was perfect for our needs. The bus was impeccably clean, comfortable, and well-equipped, making our journey to Mahalaxmi Temple, Tuljapur, and the other sacred sites smooth and enjoyable. The driver was punctual, professional, and knowledgeable about the routes, which greatly enhanced our travel experience. SSKTravels24 made our spiritual journey seamless and memorable. Highly recommended for anyone planning a similar yatra!"
        },
        {
            name: "Mrs. Anchal Chavan",
            text: "I recently booked a 45-seater bus with SSKTravels24 for our Sade Teen Shakti Peeth Yatra from Pune. The service was exceptional from start to finish. The bus was spacious and comfortable, and the driver was courteous and experienced, ensuring we arrived at each temple safely and on time. The booking process was straightforward, and the customer support team was very accommodating with our special requests. Thanks to SSKTravels24, our pilgrimage was well-organized and enjoyable. We will definitely choose them again for future trips!"
        }
    ];
    
    
    const contactData = {
        heading: "Pune Sade Teen Shakti Peeth Bus on Rent  Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune Sade Teen Shakti Peeth Bus on Rent | Affordable Yatra Packages</title>
  <meta name="description" content="Join the Sade Teen Shakti Peeth Yatra from Pune with comfortable bus rentals to various temples including Mahalaxmi, Tuljapur, and more." />
  <meta name="keywords" content="Pune Sade Teen Shakti Peeth Bus on Rent, Mahalaxmi Temple Kolhapur Bus Hire, Tempo Traveller for Shakti Peeth Yatra, Mahurgad Bus Rent, Saptashrungi Mata Vani Bus Hire" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune Sade Teen Shakti Peeth Bus on Rent | Affordable Yatra Packages" />
  <meta property="og:description" content="Explore our bus rental services for the Sade Teen Shakti Peeth Yatra, including packages for groups and families." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-sade-teen-shakti-peeth-bus-rent" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//shakti-peeth-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-09.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for “Pune Sade Teen Shakti Peeth Bus on Rent” – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punesadeteenshaktipeeth;
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SectionCars = () => {
  const cars = [
    {
      id: 1,
      name: '13 Searter Urbania Bus',
      image: '/images/For mail/Fleet/Our fleets-01.jpg',
      likes: 74,
      attributes: [5, 2, 4, 'SUV'],
      price: 265,
    },
    {
      id: 2,
      name: '13 Seater Tempo Traveller',
      image: '/images/For mail/Fleet/Our fleets-03.jpg',
      likes: 36,
      attributes: [5, 2, 4, 'Sedan'],
      price: 244,
    },
    {
      id: 3,
      name: '14 Seater Tata Winger',
      image: '/images/For mail/Fleet/Our fleets-05.jpg',
      likes: 85,
      attributes: [5, 2, 4, 'Exotic Car'],
      price: 167,
    },
   
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1440, // Large desktops
        settings: {
          slidesToShow: 3, // Show 3 slides
        },
      },
      {
        breakpoint: 1024, // Standard desktops
        settings: {
          slidesToShow: 3, // Show 3 slides
        },
      },
      {
        breakpoint: 992, // Small desktops
        settings: {
          slidesToShow: 2, // Show 2 slides
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 3, // Show 2 slides
        },
      },
      {
        breakpoint: 576, // Large phones
        settings: {
          slidesToShow: 3, // Show 1 slide
        },
      },
      // {
      //   breakpoint: 480, // Small phones
      //   settings: {
      //     slidesToShow: 2, // Show 1 slide
      //   },
      // },
      {
        breakpoint: 425, // Small phones
        settings: {
          slidesToShow: 1, // Show 1 slide
        },
      },
      {
        breakpoint: 320, // Very small devices
        settings: {
          slidesToShow: 1, // Show 1 slide
        },
      },
    ],
  };
  
  return (
    <section id="section-cars" className="section-cars">
      <div className="container">
        <div className="row align-items-center text-center g-0">
          <div className="col-lg-12 mb-4">
            <h2 className='bluecolor'>Our Vehicle Fleet</h2>
          </div>
          <Slider {...settings}>
            {cars.map((car) => (
              <div className="de-item" key={car.id}>
                <div className="d-img">
                  <img src={car.image} className="img-fluid" alt={car.name} />
                </div>
                <div className="d-info">
                  <div className="d-text">
                    <h4>{car.name}</h4>
                    {/* <div className="d-item_like">
                      <i className="fa fa-heart"></i>
                      <span>{car.likes}</span>
                    </div><br/> */}
                   
                    
                  </div>
                  <div className="d-price">
                      {/* Daily rate from <span>${car.price}</span> */}
                      {/* <Link className="btn-main " to="/book-taxi">Book Now</Link> */}
                    </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <style jsx>{`
        .section-cars {
          padding: 40px 0;
          background-color: #f8f9fa;
        }

        h2 {
          font-size: 2.5rem;
          margin-bottom: 10px;
          color: #007bff;
        }

        p {
          font-size: 1.2rem;
          color: #555;
        }

        .de-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 15px;
          background: white;
          border-radius: 8px;
          transition: transform 0.3s, box-shadow 0.3s;
        }

        .de-item:hover {
          transform: scale(1.05);
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        }

        .d-img img {
          border-radius: 8px 8px 0 0;
          width: 100%;
          height: auto;
        }

        .d-info {
          padding: 15px;
          text-align: center;
        }

        .d-item_like {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .d-atr-group {
          display: flex;
          justify-content: center;
          margin: 10px 0;
        }

        .d-atr {
          margin: 0 5px;
        }

        .d-price {
          margin-top: 15px;
          font-weight: bold;
        }

        @media (max-width: 1200px) {
          h2 {
            font-size: 2.2rem;
          }
        }

        @media (max-width: 992px) {
          h2 {
            font-size: 2rem;
          }
          p {
            font-size: 1.1rem;
          }
        }

        @media (max-width: 768px) {
          h2 {
            font-size: 1.8rem;
          }
          p {
            font-size: 1rem;
          }
          .de-item {
            padding: 10px;
          }
        }

        @media (max-width: 576px) {
          .d-price {
            font-size: 1rem;
          }
          .d-item_like {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      `}</style>
    </section>
  );
};

export default SectionCars;

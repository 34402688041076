
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function SwiftDzireonRentinPune(){


    const cardData = 
    {
        keyword: 'Swift Dzire on Rent in Pune',
        heading: 'SSKTravels24: Swift Dzire on Rent in Pune',
        headingDescription: 'Looking for a Swift Dzire on rent in Pune? SSKTravels24 provides well-maintained Swift Dzire cars for your travel needs within Pune or for outstation trips. Whether it\'s a business meeting, family outing, or airport transfer, the Swift Dzire is the ideal vehicle offering a perfect balance of comfort, efficiency, and affordability.',
        
        topPlaces: [
            {
                title: "1. Comfortable Interior",
                description: "The Swift Dzire offers a spacious cabin with comfortable seating, making it perfect for both short trips and long journeys."
            },
            {
                title: "2. Fuel Efficiency",
                description: "Enjoy the benefits of an economical vehicle. The Swift Dzire is known for its impressive fuel efficiency, helping you save on travel costs."
            },
            {
                title: "3. Modern Features",
                description: "Equipped with contemporary amenities like Bluetooth connectivity and a user-friendly infotainment system, the Swift Dzire makes your ride enjoyable and convenient."
            },
            {
                title: "4. Reliable Performance",
                description: "With a robust engine and smooth handling, the Swift Dzire provides a dependable driving experience, whether you’re navigating city streets or hitting the highway."
            },
            {
                title: "5. Affordable Rental Rates",
                description: "At SSKTravels24, we offer competitive rental prices for the Swift Dzire, ensuring you receive great value for your money."
            },
            {
                title: "6. Easy Booking Process",
                description: "Our online booking system is simple and efficient. Rent a Swift Dzire in just a few clicks, and get ready for your journey."
            },
            {
                title: "7. Well-Maintained Vehicles",
                description: "All our vehicles, including the Swift Dzire, are regularly serviced and maintained to ensure safety and reliability throughout your rental period."
            },
            {
                title: "8. Optional Driver Service",
                description: "For those who prefer to relax during their travels, we offer professional drivers familiar with Pune’s routes, ensuring a stress-free experience."
            },
            {
                title: "9. Dedicated Customer Support",
                description: "Our customer support team is available to assist you with any inquiries, ensuring a smooth rental experience from start to finish."
            },
            {
                title: "10. Positive Customer Feedback",
                description: "We take pride in our reputation for excellent service. Our clients often commend us for our reliability and customer care, making SSKTravels24 a trusted choice for rentals in Pune."
            }
        ],
    
        "services": [
            {
                name: "Dzire on Rent for Outstation",
                description: "Planning an outstation trip from Pune? SSKTravels24 offers Dzire on rent for outstation journeys, ensuring a comfortable and safe experience."
            },
            {
                name: "Per km Rate for Swift Dzire Cab",
                description: "The Swift Dzire per km rate in Pune starts at ₹10-12 per km, making it an ideal choice for both short and long-distance travel."
            },
            {
                name: "Swift Dzire Rent Per Day",
                description: "SSKTravels24 offers Swift Dzire rent per day options to cater to your specific needs for local sightseeing or extended business travel."
            },
            {
                name: "Pune to Mahabaleshwar Swift Dzire Tour",
                description: "Enjoy a scenic drive to Mahabaleshwar with our Swift Dzire on rent service, promising comfort and breathtaking views."
            },
            {
                name: "Pune to Shirdi Swift Dzire Cab",
                description: "Our Swift Dzire cabs for Shirdi make your pilgrimage comfortable and stress-free."
            },
            {
                name: "Pune to Lonavala Swift Dzire Cab",
                description: "Perfect for a quick weekend getaway, our Swift Dzire cab service to Lonavala ensures a relaxing trip."
            },
            {
                name: "Pune to Ashtavinayak Swift Dzire Cab",
                description: "Visit the famous Ashtavinayak temples with our Swift Dzire cab service for a smooth and safe pilgrimage experience."
            },
            {
                name: "Pune to Mumbai Airport Swift Dzire Cab",
                description: "Travel to Mumbai airport comfortably with our Swift Dzire on rent service, ensuring timely arrival with ample luggage space."
            },
            {
                name: "Pune to Goa Swift Dzire Hire",
                description: "Hire our Swift Dzire cab to Goa for a scenic journey in comfort and style."
            },
            {
                name: "Pune to Bhimashankar Swift Dzire Taxi",
                description: "Our Swift Dzire taxi service to Bhimashankar is perfect for devotees or nature enthusiasts planning a trip."
            },
            {
                name: "Swift Dzire Cab for Corporate Employees & Events",
                description: "SSKTravels24 specializes in offering Swift Dzire cabs for corporate employees and event shuttle services, ensuring timely and reliable transportation."
            },
            {
                name: "Contact Number for Swift Dzire On Rent in Pune",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Swift Dzire On Rent in Pune."
            }
        ],
        tableData: [
            ['-Swift Dzire on Rent in Pune', '-Dzire on Rent for Outstation'],
            ['-Per km Rate for Swift Dzire Cab', '-Dzire Per km Rate for Cab Booking'],
            ['-Swift Dzire Rent per km in Pune', '-Swift Dzire Rent per km Rate for Outstation'],
            ['-Swift Dzire Rent per Day', '-Swift Dzire Taxi Rate per km'],
            ['-Pune to Mahabaleshwar Swift Dzire Tour', '-Pune to Shirdi Swift Dzire Cab'],
            ['-Pune to Lonavala Swift Dzire Cab', '-Pune to Ashtavinayak Swift Dzire Cab'],
            ['-Pune to Mumbai Airport Swift Dzire Cab', '-Pune to Goa Swift Dzire Hire'],
            ['-Pune to Bhimashankar Swift Dzire Taxi', '-Swift Dzire Taxi for Outstation'],
            ['-Swift Dzire Cab for Corporate Employees', '-Cab Services for Corporate Events'],
            ['-SSK Travels24 in Pune', '']
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose Swift Dzire on Rent in Pune from SSKTravels24?",
                "WhyChoosedescription": "SSKTravels24 is your go-to provider for Swift Dzire rentals in Pune, offering a combination of comfort, reliability, and affordability. Here’s why you should choose us:"
            },
            {
                "WhyChooseheading": "Comfort & Performance:",
                "WhyChoosedescription": "The Swift Dzire offers a smooth driving experience, with spacious interiors and efficient mileage, ensuring your trip is comfortable and economical."
            },
            {
                "WhyChooseheading": "Affordable Rates:",
                "WhyChoosedescription": "Our Swift Dzire rental service comes at pocket-friendly rates, providing one of the best rental deals in Pune for short and long journeys."
            },
            {
                "WhyChooseheading": "Flexible Rental Options:",
                "WhyChoosedescription": "Whether you need the Swift Dzire for a few hours or multiple days, SSKTravels24 offers flexible rental packages to suit your needs."
            },
            {
                "WhyChooseheading": "Airport Transfer:",
                "WhyChoosedescription": "We offer Swift Dzire for airport transfers in Pune, ensuring a hassle-free and comfortable ride with ample boot space for luggage."
            },
            {
                "WhyChooseheading": "Well-maintained Fleet:",
                "WhyChoosedescription": "Our Swift Dzire cars are regularly serviced and inspected, ensuring a safe and enjoyable travel experience."
            }
        ]
    };    

    
    const faqData = [
        {
            question: "What is the seating capacity of the Swift Dzire?",
            answer: "The Swift Dzire can comfortably seat up to 4 passengers, making it ideal for small family trips, solo journeys, or business travel."
        },
        {
            question: "What are the rental charges for Swift Dzire in Pune?",
            answer: "The rental charges for Swift Dzire vary based on the duration and distance. Our per km rate is ₹10-12, and you can contact us for specific pricing based on your travel requirements."
        },
        {
            question: "Can I book Swift Dzire for outstation trips?",
            answer: "Yes, SSKTravels24 offers Swift Dzire for outstation trips from Pune. Whether it's a weekend getaway or a longer journey, you can rent the Swift Dzire for smooth and comfortable travel."
        },
        {
            question: "Is there a minimum rental period for Swift Dzire?",
            answer: "SSKTravels24 offers flexible rental options, and there is no strict minimum rental period. You can rent the Swift Dzire for a few hours, a day, or more depending on your needs."
        },
        {
            question: "How can I book a Swift Dzire with SSKTravels24?",
            answer: "You can easily book a Swift Dzire by visiting our website or contacting our customer support team. We recommend booking in advance to ensure availability, especially during peak travel seasons."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rohit Kulkarni",
            text: "Great experience with SSKTravels24! We rented a Swift Dzire for our family trip to Mahabaleshwar. The car was in excellent condition, the driver was professional, and the entire journey was smooth and comfortable. Highly recommend their services!"
        },
        {
            name: "Ms. Pooja Singh",
            text: "SSKTravels24 provided fantastic service when I booked a Swift Dzire for my business trip to Mumbai. The car was clean, the driver was on time, and the overall service was affordable and reliable. Will definitely use them again!"
        }
    ];
    
    const contactData = {
        heading: "Swift Dzire On Rent in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Swift Dzire On Rent in Pune | Affordable Outstation Cabs</title>
  <meta name="description" content="Rent a Swift Dzire in Pune for comfortable travel. Ideal for outstation trips, corporate events, and local sightseeing." />
  <meta name="keywords" content="Swift Dzire on rent in Pune, Dzire rental for outstation, Swift Dzire per km rate, Pune to Mahabaleshwar Swift Dzire, cab services for corporate events" />
  <meta property="og:title" content="Swift Dzire On Rent in Pune | SSK Travels24" />
  <meta property="og:description" content="Explore Pune and beyond with our Swift Dzire rental services. Perfect for families and business trips. Book now!" />
  <meta property="og:url" content="https://https://ssktravels24.com//swift-dzire-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//swift-dzire-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-33.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default SwiftDzireonRentinPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetoshirditempotravellser(){


    const cardData = 
    {
      keyword: 'Tempo Traveller on Rent for Pune to Mahabaleshwar',
      heading: 'SSKtravels24: Pune to Shirdi Tempo Traveller on  Rent',
      headingDescription: 'Shirdi, a small town in Maharashtra, is renowned for its spiritual significance and serene ambiance. It is celebrated as the abode of Sai Baba, a revered saint whose teachings of love, compassion, and selflessness continue to inspire millions. Each year, countless devotees and travelers make their way to Shirdi, seeking spiritual solace and a deeper connection with their faith.',
      
       topPlaces : [
        {
            title: "1. Sai Baba Samadhi Mandir",
            description: "The Sai Baba Samadhi Mandir is the central hub of Shirdi’s spiritual atmosphere. It houses the samadhi (tomb) of Sai Baba, where thousands gather to seek his blessings. The temple’s serene environment and the sacredness of Sai Baba’s resting place make it a spiritual focal point."
        },
        {
            title: "2. Dwarkamai",
            description: "Dwarkamai is the historic mosque where Sai Baba spent much of his time. It is a site of deep religious significance, housing the sacred 'Dhuni' (eternal fire) that Sai Baba tended to throughout his life. The mosque stands as a symbol of his teachings and miracles."
        },
        {
            title: "3. Shirdi Sai Baba Museum",
            description: "The Shirdi Sai Baba Museum offers a comprehensive look at the life of Sai Baba. It showcases personal belongings, photographs, and artifacts related to the saint, providing insights into his life and the miracles he performed."
        },
        {
            title: "4. Chavadi",
            description: "Chavadi is a significant site where Sai Baba would sleep every alternate night. Visitors can observe relics and photographs that honor Sai Baba’s presence and reflect on his teachings. It’s a place of quiet reflection and devotion."
        },
        {
            title: "5. Sai Teerth Theme Park",
            description: "For an engaging family experience, Sai Teerth Theme Park combines spirituality with entertainment. It features interactive exhibits and 3D shows that bring Sai Baba’s teachings to life, making it an enjoyable destination for visitors of all ages."
        },
        {
            title: "6. Shani Shingnapur Temple",
            description: "A short drive from Shirdi, the Shani Shingnapur Temple is dedicated to Lord Shani, the deity associated with the planet Saturn. This unique temple is renowned for its open-air sanctum and the belief in the deity’s immense power."
        },
        {
            title: "7. Khandoba Temple",
            description: "Khandoba Temple, dedicated to Lord Khandoba, a local deity, is another significant site in Shirdi. The temple is believed to be frequently visited by Sai Baba and offers a peaceful environment for devotees and visitors."
        },
        {
            title: "8. Wet N Joy Water Park",
            description: "For some relaxation and fun, Wet N Joy Water Park provides a refreshing break from the spiritual journey. The park features water rides, wave pools, and leisure zones, making it a great spot for families to unwind."
        },
        {
            title: "9. Hanuman Mandir",
            description: "Hanuman Mandir in Shirdi is dedicated to Lord Hanuman, known for his strength and devotion. The temple offers a tranquil space for prayer and reflection, enhancing the spiritual experience of visitors."
        },
        {
            title: "10. Gurusthan",
            description: "Gurusthan is a revered site where Sai Baba was first seen by his followers. It features a sacred neem tree and a memorial stone, marking the beginning of Sai Baba’s spiritual journey and offering a place of reverence and reflection."
        }
    ],    
    
     services : [
        {
            name: "Tempo Traveller from Pune to Shirdi",
            description: "If you're planning a trip from Pune to Shirdi, our Tempo Travellers ensure a smooth and enjoyable journey. Equipped with modern amenities, our vehicles provide comfort for family, friends, or corporate events."
        },
        {
            name: "Pune Airport to Shirdi Tempo Traveller on Rent",
            description: "Arriving at Pune Airport? Our seamless transfer service to Shirdi allows you to start your journey relaxed, focusing on your trip without worrying about logistics."
        },
        {
            name: "Pune to Shirdi Tempo Traveller AC 13 & 17 Seater",
            description: "For those seeking comfort, our AC Tempo Travellers with 13 and 17 seats provide a cool and comfortable ride, making your journey enjoyable regardless of the weather."
        },
        {
            name: "Pune to Shirdi Mini Bus Rent",
            description: "Our mini buses offer ample space and comfort for larger groups, making them perfect for family outings, corporate trips, or religious tours."
        },
        {
            name: "Bus Hire for Pune to Shirdi",
            description: "We offer flexible bus hire options for day trips or extended stays, with competitive pricing and a focus on customer satisfaction."
        },
        {
            name: "Pune to Shani Shingnapur Tempo Traveller",
            description: "Planning a pilgrimage to Shani Shingnapur? Our Tempo Travellers ensure a comfortable ride to this sacred destination."
        },
        {
            name: "Pune to Shirdi 17-Seater on Rent",
            description: "Our 17-seater Tempo Travellers are ideal for group journeys, providing ample space and comfort for family trips, corporate events, or religious tours."
        },
        {
            name: "Pune to Ahmednagar Tempo Traveller on Rent",
            description: "Choose our Tempo Traveller rentals for a reliable and comfortable journey to Ahmednagar, designed for smooth and enjoyable travel."
        },
        {
            name: "Pune to Ajanta Ellora Tempo Traveller on Rent",
            description: "Explore the historic Ajanta and Ellora Caves with ease in our well-equipped Tempo Travellers, ensuring a spacious and comfortable ride."
        },
        {
            name: "Pune to Shirdi Tempo Traveller Package",
            description: "We offer comprehensive packages for trips from Pune to Shirdi, including comfortable transportation and experienced drivers."
        },
        {
            name: "Pune to Shirdi 14 Seater Bus on Rent",
            description: "Our 14-seater buses provide a perfect blend of space and comfort for family gatherings or group outings to Shirdi."
        },
        {
            name: "Pune to Shirdi Tempo Traveller Contact Number",
            description: "Contact SSKtravels24 at +91 8956170493 for prompt and efficient Tempo Traveller rentals from Pune to Shirdi."
        }
    ],    
     tableData :[
        ['-Pune to Shirdi Tempo Traveller on Rent', '-Pune Airport to Shirdi Tempo Traveller on Rent'],
        ['-Pune to Shirdi Tempo Traveller AC 13 & 17 Seater', '-Pune to Shirdi Tempo Traveller on Rent'],
        ['-Pune to Shirdi Mini Bus Rent', '-Bus Hire for Pune to Shirdi'],
        ['-Pune Airport to Shirdi Tempo Traveller', '-Pune to Shani Shingnapur Tempo Traveller'],
        ['-Pune to Shani Shingnapur Bus on Rent', '-Pune to Shirdi 14 Seater Bus on Rent'],
        ['-Pune to Shirdi 17 Seater on Rent', '-Pune to Ahmednagar Tempo Traveller on Rent'],
        ['-Pune to Ajanta Ellora Tempo Traveller on Rent', '-17 Seater Tempo Traveller on Rent in Pune'],
        ['-Pune to Shirdi Tempo Traveller Package', '-Pune to Shani Shingnapur Tempo Traveller On Rent']
    ],    
     whychoose : [
        {
            "WhyChooseheading": "Why Choose SSKTravels24 for Pune to Shirdi Tempo Traveller?",
            "WhyChoosedescription": "When it comes to renting a Tempo Traveller for your journey from Pune to Shirdi, SSKTravels24 is your ideal provider. Our commitment to comfort, reliability, and customer satisfaction ensures that your travel experience is both enjoyable and hassle-free. Here’s why SSKTravels24 stands out:"
        },
        {
            "WhyChooseheading": "Comfortable Vehicles:",
            "WhyChoosedescription": "Our Tempo Travellers are designed with spacious interiors and plush seating to ensure a comfortable ride. Enjoy ample legroom and a relaxing journey whether you're traveling for business, family, or religious purposes."
        },
        {
            "WhyChooseheading": "Well-Maintained Fleet:",
            "WhyChoosedescription": "We take pride in our fleet of well-maintained vehicles. Regular servicing and thorough inspections ensure that each Tempo Traveller is in optimal condition, providing a smooth and trouble-free travel experience."
        },
        {
            "WhyChooseheading": "Experienced Drivers:",
            "WhyChoosedescription": "Our professional drivers are highly experienced and well-versed with the routes between Pune and Shirdi. They are committed to providing safe and efficient transportation, ensuring that you arrive at your destination on time."
        },
        {
            "WhyChooseheading": "Modern Amenities:",
            "WhyChoosedescription": "Our Tempo Travellers are equipped with modern amenities such as air conditioning, music systems, and comfortable seating. These features are designed to enhance your travel experience and make your journey as enjoyable as possible."
        },
        {
            "WhyChooseheading": "Flexible Rental Options:",
            "WhyChoosedescription": "We offer flexible rental packages to meet your specific needs. Whether you require a Tempo Traveller for a day trip, an extended journey, or a special event, we can customize our services to fit your schedule and budget."
        },
        {
            "WhyChooseheading": "Competitive Pricing:",
            "WhyChoosedescription": "At SSKtravels24, we offer competitive and transparent pricing with no hidden fees. Our rates are designed to provide great value for your money, ensuring you get the best possible service at a fair price."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with any questions or issues. From booking inquiries to on-road support, we are here to ensure a seamless and stress-free experience."
        },
        {
            "WhyChooseheading": "Timely Service:",
            "WhyChoosedescription": "Punctuality is a key aspect of our service. We ensure that our Tempo Travellers adhere to the agreed schedule, allowing you to plan your journey with confidence and peace of mind."
        },
        {
            "WhyChooseheading": "Customizable Itineraries:",
            "WhyChoosedescription": "We understand that each trip is unique. Our team can help you plan and customize your itinerary according to your preferences, ensuring that your journey from Pune to Shirdi meets all your needs."
        },
        {
            "WhyChooseheading": "Positive Customer Feedback:",
            "WhyChoosedescription": "Our satisfied customers frequently commend our excellent service and the quality of our vehicles. We are committed to maintaining high standards and continually improving our offerings based on customer feedback."
        }
    ]    
    };    

    
    const faqData = [
        {
            question: "How can I book a Tempo Traveller from Pune to Shirdi?",
            answer: "You can book a Tempo Traveller by calling us at +91 89561 70493 or by visiting our website. Our team will assist you with the booking process and provide all necessary details."
        },
        {
            question: "What is included in the rental package for a Pune to Shirdi trip?",
            answer: "Our rental package includes a well-maintained Tempo Traveller, a professional driver, and essential amenities such as air conditioning and music systems. Additional services or features can be added upon request."
        },
        {
            question: "Are there any hidden fees in the rental cost?",
            answer: "No, we offer transparent pricing with no hidden charges. Any additional costs, such as extra mileage or extended rental periods, will be clearly communicated before you finalize your booking."
        },
        {
            question: "Can I choose between an AC and non-AC Tempo Traveller?",
            answer: "Yes, we offer both AC and non-AC Tempo Travellers. You can specify your preference at the time of booking to ensure that you have the most comfortable travel experience."
        },
        {
            question: "What if I need to cancel or change my booking?",
            answer: "If you need to cancel or modify your booking, please contact our customer support team as soon as possible. We will guide you through the process and inform you of any applicable cancellation fees."
        },
        {
            question: "How are your Tempo Travellers maintained?",
            answer: "Our Tempo Travellers undergo regular maintenance and inspections to ensure they are in excellent condition. We prioritize safety and comfort to provide a reliable travel experience."
        },
        {
            question: "Can the Tempo Traveller be used for both local and outstation travel?",
            answer: "Yes, our Tempo Travellers are suitable for both local and outstation travel. We provide flexible rental options to accommodate different travel needs."
        },
        {
            question: "What amenities are available on the Tempo Traveller?",
            answer: "Our Tempo Travellers come equipped with air conditioning, comfortable seating, and music systems. Additional amenities can be requested based on your requirements."
        },
        {
            question: "How do you ensure the safety of passengers during the trip?",
            answer: "We ensure safety by employing experienced drivers and maintaining our vehicles to high standards. Our drivers are trained to handle various road conditions and prioritize passenger safety."
        },
        {
            question: "What should I do if I encounter any issues during the trip?",
            answer: "If you experience any issues during your trip, please contact our customer support team immediately. We are available to address any concerns and ensure a smooth travel experience."
        }
    ];
    
      

    const testimonials = [
        {
            name: "Miss Ananya Patel",
            text: "Our trip from Pune to Shirdi with SSKtravels24 was absolutely fantastic! We rented a Tempo Traveller for our family pilgrimage, and it was a comfortable and pleasant journey from start to finish. The vehicle was clean, well-maintained, and equipped with air conditioning, which was a blessing during the long drive. Our driver was professional, courteous, and very knowledgeable about the route. He made sure we reached Shirdi on time and safely. I highly recommend SSKtravels24 for anyone planning a trip to Shirdi. Their service truly exceeded our expectations!"
        },
        {
            name: "Mr. Rajesh Deshmukh",
            text: "I recently booked a Tempo Traveller with SSKtravels24 for a group trip from Pune to Shirdi, and I couldn't be happier with their service. The booking process was straightforward, and the vehicle was exactly as described—spacious, comfortable, and air-conditioned. The driver was punctual and handled the road conditions expertly, making our journey smooth and enjoyable. The entire experience was stress-free, and the support from the SSK Travels24 team was excellent. I will definitely choose them for future trips and recommend them to others looking for reliable travel options."
        }
    ];
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: "Pune to Shirdi Tempo Traveller Contact Number: ",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000",
         
        ],
        email: "booking@ssktravels24.com"
      };


      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Shirdi Tempo Traveller | Affordable Rental Services</title>
  <meta name="description" content="Rent a tempo traveller from Pune to Shirdi. We offer AC options for 13 and 17 seater vehicles. Enjoy competitive rates for your journey today!" />
  <meta name="keywords" content="Pune to Shirdi tempo traveller on rent, Pune Airport to Shirdi tempo traveller on rent, Pune to Shirdi tempo traveller AC 13 & 17 seater, Pune to Shirdi mini bus rent, bus hire for Pune to Shirdi, Pune airport to Shirdi tempo traveller, Pune to Shani Shingnapur tempo traveller, Pune to Shani Shingnapur bus on rent, Pune Airport to Shirdi tempo traveller, Pune to Shirdi 14 seater bus on rent, Pune to Shirdi 17 seater on rent, Pune to Ahmednagar tempo traveller on rent, Pune to Ajanta Ellora tempo traveller on rent, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller package, Pune to Shani Shingnapur tempo traveller on rent" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Shirdi Tempo Traveller | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for tempo travellers from Pune to Shirdi. Choose from AC options and enjoy competitive rates. Contact us to book your journey!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-shirdi-tempo-traveller" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-to-shirdi-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Pune to Shirdi Tempo Traveller</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-07.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About Pune to Shirdi Tempo Traveller with SSKtravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetoshirditempotravellser;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Bushireforschool(){


    const cardData = 
    {
        keyword: 'Bus Hire for School Trips in Pune',
        heading: 'SSKTravels24: Bus Hire for School Trips in Pune',
        headingDescription: 'When organizing school trips, safety, comfort, and reliability are essential. At SSKTravels24, we recognize these needs and provide outstanding bus hire services tailored specifically for school trips in Pune. Here are the top features of our bus hire service that make us the preferred choice for educational institutions:',
        
         topPlaces : [
            {
                title: "1. Spacious and Comfortable Seating",
                description: "Our buses feature roomy seating arrangements designed for student comfort. With ample legroom and well-cushioned seats, students can enjoy a relaxed journey."
            },
            {
                title: "2. Safety First",
                description: "Safety is our highest priority. Our buses are equipped with advanced safety features, including seat belts, emergency exits, and GPS tracking."
            },
            {
                title: "3. Air-Conditioned Buses",
                description: "To keep students comfortable in any weather, we offer air-conditioned buses that guarantee a pleasant atmosphere throughout the trip."
            },
            {
                title: "4. Well-Maintained Fleet",
                description: "Each bus undergoes regular inspections and servicing to ensure it remains in top condition, minimizing the risk of breakdowns."
            },
            {
                title: "5. Professional and Friendly Drivers",
                description: "Our skilled and friendly drivers cater to the unique needs of school trips, ensuring a smooth experience for everyone."
            },
            {
                title: "6. Customizable Travel Packages",
                description: "We offer flexible travel packages tailored to your school’s specific needs for full-day trips, half-day outings, or special events."
            },
            {
                title: "7. Entertainment Options",
                description: "Many of our buses are equipped with audio systems and DVD players to keep students entertained during their journey."
            },
            {
                title: "8. Affordable Rates",
                description: "We provide competitive pricing for our school trip bus hire services with a transparent pricing structure and no hidden fees."
            },
            {
                title: "9. Punctuality and Reliability",
                description: "Our buses follow strict timetables to ensure timely departures and arrivals, helping you stay on track with your itinerary."
            },
            {
                title: "10. Excellent Customer Support",
                description: "Our dedicated customer support team is available to assist you throughout the booking process and during the trip."
            }
        ],        
        
         services : [
            {
                name: "Bus Hire for School Trips in Hadapsar",
                description: "SSKTravels24 offers reliable bus hire services for school trips in Hadapsar, ensuring comfortable and safe transportation for your students."
            },
            {
                name: "Bus Hire for School Trips in Pimpri Chinchwad",
                description: "Enjoy unmatched comfort and convenience for school trips in Pimpri Chinchwad with our spacious and well-equipped buses."
            },
            {
                name: "Bus Hire for School Picnic to Morachi Chincholi",
                description: "Plan a delightful school picnic to Morachi Chincholi with our comfortable buses, providing ample space for students and a stress-free journey."
            },
            {
                name: "Bus Hire for School Picnic Pune to Mahabaleshwar",
                description: "Make your school picnic memorable with our bus hire services to Mahabaleshwar, ensuring a comfortable and enjoyable ride for all."
            },
            {
                name: "Bus Hire for School Picnic Pune to Alibaug",
                description: "Transport your students safely to Alibaug's beautiful beaches with our reliable bus hire services, ensuring a fun-filled outing."
            },
            {
                name: "Bus Hire for Pune to Mumbai for School Picnic",
                description: "Our bus hire services from Pune to Mumbai guarantee a smooth journey, allowing your students to enjoy the city's attractions comfortably."
            },
            {
                name: "Bus Hire for Pune to Water Park for School Picnic",
                description: "Enjoy a fun-filled school picnic to a water park with our spacious and comfortable buses designed for large groups."
            },
            {
                name: "Bus Hire for Pune to Outstation School Picnic",
                description: "Our services provide reliable transportation for outstation school picnics, ensuring a stress-free experience for long journeys."
            },
            {
                name: "Bus Hire for School Picnic in Pimpri Chinchwad",
                description: "Count on SSKTravels24 for quality and reliable bus hire services for school picnics in Pimpri Chinchwad."
            },
            {
                name: "Mini Bus On Rent in Pune Contact Number",
                description: "For prompt and efficient mini bus rentals in Pune, contact SSKTravels at +91 8956170493."
            }
        ],        
         tableData : [
            ['- Bus Hire for School Trips in Hadapsar Pune', '- Bus Hire for School Trips in Pimpri Chinchwad'],
            ['- Bus Hire for School Picnic to Morachi Chincholi', '- Bus Hire for School Picnic Pune to Mahabaleshwar'],
            ['- Bus Hire for School Picnic Pune to Alibaug', '- Bus Hire for Pune to Mumbai for School Picnic'],
            ['- Bus Hire for Pune to Water Park for School Picnic', '- Bus Hire for Pune to Outstation School Picnic'],
            ['- Bus Hire for School Picnic in Pimpri Chinchwad', '']
        ],
        
    
         whychoose : [
            {
                "WhyChooseheading": "Safe and Secure Transportation",
                "WhyChoosedescription": "At SSKTravels24, the safety of your children is our top priority. Our buses come equipped with GPS tracking, seat belts, and are driven by experienced professionals, ensuring a secure journey during school trips."
            },
            {
                "WhyChooseheading": "Well-Maintained Fleet",
                "WhyChoosedescription": "Our diverse fleet is regularly maintained to guarantee optimal performance and comfort. We provide a variety of buses, from mini-buses to larger coaches, catering to groups of all sizes."
            },
            {
                "WhyChooseheading": "Affordable Rates",
                "WhyChoosedescription": "We offer competitive pricing for our school bus hire services, ensuring you receive excellent value for your money. With our transparent pricing policy, there are no hidden charges, making it easy for schools to plan their trips within budget."
            },
            {
                "WhyChooseheading": "Experienced Drivers",
                "WhyChoosedescription": "Our drivers are not only professional and courteous but also well-trained in handling school trips. They are familiar with Pune’s routes, ensuring that students arrive safely and on time."
            },
            {
                "WhyChooseheading": "Comfortable and Spacious Buses",
                "WhyChoosedescription": "Our buses are designed with comfort in mind, featuring spacious seating and plenty of room for students and teachers. The interiors are tailored to create a relaxing environment, keeping students refreshed for their activities."
            },
            {
                "WhyChooseheading": "Tailored Services",
                "WhyChoosedescription": "Understanding that each school trip is unique, we offer customizable bus hire services. Whether it's a one-day excursion, a picnic, or an educational tour, we cater our services to meet your specific needs."
            },
            {
                "WhyChooseheading": "Punctual and Reliable",
                "WhyChoosedescription": "We recognize the importance of punctuality for school trips. Our buses arrive on time, facilitating a smooth schedule without delays. Our reliability has made SSKTravels24 a trusted choice among schools in Pune."
            },
            {
                "WhyChooseheading": "Support for School Events",
                "WhyChoosedescription": "Beyond regular school trips, we provide bus hire services for school events, including sports meets, educational tours, and annual days. Our flexible services can be tailored to meet the requirements of your specific event."
            }
        ]        
    };    

    
   
    const faqData = [
        {
            question: "What safety measures do you provide for school trips?",
            answer: "Safety is our highest priority at SSKTravels24. Our buses are equipped with seat belts, GPS tracking, and first-aid kits. Our drivers are experienced and trained to handle school groups with care."
        },
        {
            question: "What are the seating capacities of your buses?",
            answer: "We offer a range of buses with varying seating capacities, from 20-seater mini-buses to 50-seater large buses. You can choose the bus that best fits your group's size."
        },
        {
            question: "How do you ensure that the buses are clean and well-maintained?",
            answer: "Our buses undergo regular maintenance checks and are thoroughly cleaned before each trip. We guarantee that the vehicles are in excellent condition for a comfortable and hassle-free experience."
        },
        {
            question: "Can we hire buses for overnight school trips or outstation tours?",
            answer: "Yes, we provide bus hire services for both day trips and overnight outstation school tours. Our services are flexible and can be tailored to accommodate the duration and location of your school trip."
        },
        {
            question: "Are the drivers familiar with the routes to common school trip destinations in and around Pune?",
            answer: "Absolutely! Our drivers are experienced and well-acquainted with popular school trip destinations in Pune and the surrounding areas, ensuring smooth journeys."
        },
        {
            question: "What are your charges for school bus hire?",
            answer: "Our charges depend on the type of bus, distance, and duration of the trip. We offer competitive pricing and will provide a detailed quote after discussing your requirements."
        },
        {
            question: "How do I book a bus for a school trip?",
            answer: "You can book a bus by reaching out to us via our website, phone, or email. Our customer service team is ready to assist you in selecting the right bus and providing a customized quote based on your needs."
        },
        {
            question: "Is there an option to reschedule or cancel a bus booking?",
            answer: "Yes, you can reschedule or cancel your booking in accordance with our cancellation policy. We recommend early communication to avoid any cancellation fees."
        },
        {
            question: "Do you offer additional support for school events like sports or annual functions?",
            answer: "Yes, we provide bus hire services for school events such as sports meets, annual functions, and educational tours. We can accommodate any special requirements to ensure smooth transportation for your event."
        },
        {
            question: "Can we request additional staff to accompany the trip for added safety?",
            answer: "Certainly! We can arrange for additional staff or supervisors to accompany the trip for enhanced safety and assistance, particularly for younger students. This can be discussed and arranged during the booking process."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Ms. Kavita Deshmukh",
            text: "We have been using SSKTravels24 for our school trips for the past two years, and their service has always been exceptional. The buses are well-maintained, and the drivers are professional and punctual. Safety is our top concern, and SSKTravels24 has consistently provided a secure and comfortable experience for our students. Their affordable rates and flexibility make them our go-to bus service for all our school events. Highly recommended!"
        },
        {
            name: "Mr. Anil Patil",
            text: "SSKTravels24 made our recent school trip to Sinhagad Fort a breeze. The bus was clean, spacious, and arrived on time. The driver was courteous and ensured that the students were comfortable throughout the journey. We also appreciated the GPS tracking feature, which gave parents peace of mind. SSKTravels24 is reliable, and we will definitely be using their services for future school outings!"
        }
    ];
    
    
    const contactData = {
        heading: "Bus Hire for School Trips in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus Hire for School Trips in Pune | Affordable School Trip Services</title>
  <meta name="description" content="Hire a bus for school trips in Pune. We offer services for picnics to Mahabaleshwar, Alibaug, and more!" />
  <meta name="keywords" content="Bus hire for school trips in Hadapsar Pune | Bus hire for School Trips in Pimpri Chinchwad | Bus Hire for School Picnic Morachi Chincholi | Bus hire for school picnic Pune to Mahabaleshwar | Bus Hire for School Picnic Pune to Alibaug | Bus hire for Pune to Mumbai for school picnic | Bus hire for Pune to water park for school picnic | Bus hire for Pune to outstation school picnic in Pune | Bus hire for school picnic in Pimpri Chinchwad" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus Hire for School Trips in Pune | Affordable School Trip Services" />
  <meta property="og:description" content="Experience hassle-free bus hire for school trips in Pune. Book now for enjoyable and safe school outings!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-hire-school-trips-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//school-trip-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-25.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs: Bus Hire for School Trips in Pune with SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Bushireforschool;

import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const mobileMenuRef = useRef(null);
  const navMenuWrapperRef = useRef(null);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const closeNavbar = () => {
    setIsCollapsed(true);
  };

  useEffect(() => {
    const handleClick = () => {
      navMenuWrapperRef.current.classList.toggle('active');
    };

    const mobileMenuIcon = mobileMenuRef.current;
    mobileMenuIcon.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      mobileMenuIcon.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <header className=" ">
     


<div className=" pt-1 py-md-2 py-lg-3" >
  <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center">
    <ul className="header-info list-unstyled mb-0 d-flex flex-column flex-md-row align-items-center">
      <li className="d-flex align-items-center  mb-md-0 me-md-4">
        <i className="las la-phone-volume fs-4 text-white me-2"></i>
        <div>
          <h5 className="mb-0">
            <span className=" d-none d-md-block fw-bold text-white">Call us now:</span>
            <div className="phone-numbers d-md-flex">
     
              <a href="tel:+918956170493" className="d-block text-white fw-bold"><i class="fas fa-phone"></i>&nbsp;&nbsp;+91 8956170493 &nbsp; </a> 
              <a href="tel:+917276307000" className=" text-white fw-bold d-block d-md-none"><i class="fas fa-phone"></i>&nbsp;+91 7276307000 &nbsp; </a> 
              <a href="tel:+917276307000" className=" text-white fw-bold d-none d-md-block">/&nbsp;+91 7276307000 &nbsp; </a> 
            </div>
          </h5>
        </div>
      </li>
      <li className="d-flex align-items-center mb-1 mb-md-0 ms-md-4">
        <i className="las la-envelope-open fs-4 text-white  colored me-2"></i>
        <div>
          <h5 className="mb-0">
            <span className=" d-none d-md-block colored fw-bold text-white">Email us:</span>
            <a href="mailto:booking@ssktravels24.com
" className="d-block text-white fw-bold"><i class="fas fa-envelope"></i>&nbsp;booking@ssktravels24.com
</a>
          </h5>
        </div>
      </li>
    </ul>
  </div>

</div>





      <div className="mobile-menu-icon" ref={mobileMenuRef}>
      </div>

      <nav className="navbar navbar-expand-lg bg-body-tertiary position-relative px-2">
      <div className="container-fluid">
        <img className='logooo px-1 d-none d-md-block' src='\images\512x512.jpg'/>
        <img className='logoo px-1 d-block d-md-none' src='\images\512x512.jpg'/>
        <button 
          className="navbar-toggler" 
          type="button" 
          onClick={toggleCollapse} 
          aria-controls="navbarSupportedContent" 
          aria-expanded={!isCollapsed} 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>
        <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 px-3">
            <li className="nav-item">
              <Link className="nav-link  fw-bold active" aria-current="page" to="/" onClick={closeNavbar}>Home</Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle fw-bold" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                About
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item fw-bold " to="/about-us" onClick={closeNavbar}>About Us</Link></li>
                <li><Link className="dropdown-item fw-bold " to="/faqs" onClick={closeNavbar}>FAQs</Link></li>
                <li><Link className="dropdown-item fw-bold " to="/Term-&-Condition" onClick={closeNavbar}>Terms & Conditions</Link></li>
                <li><Link className="dropdown-item fw-bold " to="/privacy-policy" onClick={closeNavbar}>Privacy Policy</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-bold active" to="/service" onClick={closeNavbar}>Service</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-bold active" to="/our-fleet" onClick={closeNavbar}>Our Fleet</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-bold active" to="/packages" onClick={closeNavbar}>Packages</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-bold active" to="/our-network" onClick={closeNavbar}>Our Network</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-bold active" to="/book-taxi" onClick={closeNavbar}>Booking</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-bold active" to="/gallery" onClick={closeNavbar}>Gallery</Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle fw-bold" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Contact
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item fw-bold " to="/contact" onClick={closeNavbar}>Contact</Link></li>
                <li><Link className="dropdown-item fw-bold " to="/enquiry" onClick={closeNavbar}>Enquiry</Link></li>
                <li><Link className="dropdown-item fw-bold " to="/carrer" onClick={closeNavbar}>Career</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link fw-bold active" to="/blog" onClick={closeNavbar}>Blog</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    </header>
  );
};

export default Header;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function ForceUrbaniaLuxuryMiniBusonRent(){


    const cardData = 
    {
        keyword: 'Force Urbania Luxury Mini Bus on Rent',
        heading: 'SSKTravels24: Force Urbania Luxury Mini Bus on Rent',
        headingDescription: 'When it comes to luxury group travel, SSKTravels24 proudly offers the Force Urbania luxury mini bus, designed for elegance, comfort, and convenience. With SSKTravels24, your journey is elevated, ensuring a top-tier travel experience. Here are the standout features that make renting the Force Urbania mini bus from SSKTravels24 the ultimate choice for your travel needs:',
    
        topPlaces: [
            {
                title: "1. Spacious and Comfortable Seating",
                description: "The Force Urbania luxury mini bus is designed to offer spacious seating arrangements, ensuring maximum comfort for every passenger."
            },
            {
                title: "2. Climate-Controlled Comfort",
                description: "Equipped with advanced air-conditioning systems that keep the interior cool and comfortable regardless of external weather conditions."
            },
            {
                title: "3. Advanced Entertainment Systems",
                description: "Enjoy top-of-the-line entertainment systems, including high-definition LED TVs and premium sound systems."
            },
            {
                title: "4. Ample Luggage Space",
                description: "Offers generous luggage space to accommodate all your baggage comfortably."
            },
            {
                title: "5. Top-Notch Safety Features",
                description: "Equipped with the latest safety features, including seat belts, emergency exits, GPS tracking, and first-aid kits."
            },
            {
                title: "6. Professional and Experienced Drivers",
                description: "All rentals come with highly professional drivers who prioritize your comfort and safety."
            },
            {
                title: "7. Impeccably Maintained Vehicles",
                description: "Vehicles undergo regular inspections and servicing to ensure they are always in excellent condition."
            },
            {
                title: "8. Customizable Travel Solutions",
                description: "Offers flexible and customizable travel packages to suit your specific needs."
            },
            {
                title: "9. Affordable and Transparent Pricing",
                description: "Luxury travel with competitive and transparent prices, ensuring excellent value for your money."
            },
            {
                title: "10. Exceptional Customer Service",
                description: "Committed to providing outstanding customer service from booking to the end of your journey."
            }
        ],
    
        services: [
            {
                name: "Force Urbania Mini Bus on Rent in Hadapsar",
                description: "For luxurious and comfortable group travel in Hadapsar, our Urbania buses combine style and functionality."
            },
            {
                name: "13-Seater Urbania Bus Hire in Kharadi",
                description: "Ideal for corporate trips, family outings, or group tours in and around Kharadi."
            },
            {
                name: "17-Seater Urbania Bus Hire in Kharadi",
                description: "Perfect for larger groups with ample seating and premium amenities."
            },
            {
                name: "Luxury Bus for Corporate Events",
                description: "Make a lasting impression on clients and colleagues with our luxury buses for corporate events."
            },
            {
                name: "Online Bus Booking for Pune Airport",
                description: "Offers an easy online booking service for seamless airport transfers."
            },
            {
                name: "Pune to Mumbai Airport Urbania Bus on Rent",
                description: "Travel between Pune and Mumbai airports in style with our luxury bus rental."
            },
            {
                name: "Force Urbania Luxury Mini Bus for Outstation",
                description: "Perfect for outstation trips to destinations like Lonavala, Nashik, and more."
            },
            {
                name: "Urbania Bus Hire in Hinjewadi",
                description: "Enjoy comfortable group travel with top-notch amenities for business or personal trips."
            },
            {
                name: "Urbania Bus on Rent for Pune to Shirdi Darshan",
                description: "Provides a serene and luxurious trip from Pune to Shirdi, ensuring a hassle-free travel experience."
            },
            {
                name: "Force Urbania on Rent in Pune Airport",
                description: "Offers convenient pickup and drop-off services for smooth airport transfers."
            },
            {
                name: "Force Urbania on Rent in Pimpri Chinchwad",
                description: "Experience luxury travel ideal for corporate events, family outings, or special occasions."
            },
            {
                name: "Force Urbania on Rent in Magarpatta City",
                description: "Provides luxury rentals for corporate trips, weddings, or personal events."
            },
            {
                name: "Force Urbania on Rent in Hinjewadi",
                description: "Experience premium travel with our luxury fleet designed for maximum comfort."
            },
            {
                name: "Contact Number for Force Urbania Luxury Mini Bus On Rent",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient service."
            }
        ],
        tableData: [
            ['- Force Urbania Luxury Mini Bus on Rent in Hadapsar', '- 13 Seater Urbania Bus Hire in Kharadi'],
            ['- 17 Seater Urbania Bus Hire in Kharadi', '- Luxury Bus for Corporate Events Online Bus Booking'],
            ['- Pune Airport Urbania Bus on Rent', '- Pune to Mumbai Airport Urbania Bus on Rent'],
            ['- Force Urbania Luxury Mini Bus on Rent for Outstation', '- Urbania Bus Hire in Hinjewadi'],
            ['- Urbania Bus Hire in Aundh Baner', '- Urbania Bus on Rent for Pune to Shirdi Darshan'],
            ['- Force Urbania on Rent in Pune Airport', '- Force Urbania on Rent in Pune Airport Near Me'],
            ['- Force Urbania on Rent in Pimpri Chinchwad', '- Force Urbania on Rent in Pimpri Chinchwad Near Me'],
            ['- Force Urbania on Rent in Magarpatta City', '- Force Urbania on Rent in Hinjewadi']
        ],
        
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for Force Urbania Rentals?",
                "WhyChoosedescription": "At SSKTravels24, we take great pride in providing exceptional Force Urbania rentals that meet your every need. Here are the key reasons to choose us:"
            },
            {
                "WhyChooseheading": "Well-Maintained Fleet:",
                "WhyChoosedescription": "We maintain our fleet to the highest standards. Each Force Urbania undergoes rigorous inspections and regular maintenance, ensuring it remains in peak condition and minimizing the risk of disruptions."
            },
            {
                "WhyChooseheading": "Customized Travel Solutions:",
                "WhyChoosedescription": "Whether you need the Force Urbania for corporate events, weddings, airport transfers, or sightseeing tours, we offer tailored solutions to meet your specific requirements. Our team collaborates with you to ensure all your needs are addressed."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "Our rental rates for the Force Urbania are not only competitive but also transparent. We offer clear pricing with no hidden fees, allowing you to budget confidently without surprises."
            },
            {
                "WhyChooseheading": "Convenient Booking Process:",
                "WhyChoosedescription": "Booking your Force Urbania with SSKTravels24 is simple and efficient. You can make reservations via phone, email, or through our user-friendly online booking system. Our team is always available to assist you with your queries."
            },
            {
                "WhyChooseheading": "Excellent Customer Service:",
                "WhyChoosedescription": "Our commitment to customer satisfaction shines through in our personalized service. From the initial inquiry to the conclusion of your journey, our customer service team is dedicated to ensuring a smooth and enjoyable experience."
            },
            {
                "WhyChooseheading": "Punctuality and Reliability:",
                "WhyChoosedescription": "We understand the importance of time in travel. Our Urbania mini buses adhere to strict schedules to ensure timely pickups and drop-offs, helping you stay on track with your plans."
            },
            {
                "WhyChooseheading": "Wide Service Area:",
                "WhyChoosedescription": "We offer Force Urbania rentals across Pune and surrounding areas, including Pimpri Chinchwad, Hadapsar, Magarpatta City, and Hinjewadi. No matter where you need to go, we’ve got you covered."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What is included in the rental of a Force Urbania?",
            answer: "Your rental includes comfortable seating, air conditioning, and professional driver services. Additional amenities like audio systems and Wi-Fi can be arranged upon request."
        },
        {
            question: "How do I book a Force Urbania with SSKTravels24?",
            answer: "Booking is easy! Contact us via phone, email, or fill out our online form with your travel details, including dates, pick-up and drop-off locations, and any special requirements."
        },
        {
            question: "Are there any additional charges I should be aware of?",
            answer: "While we maintain transparent pricing, additional charges may apply for extra services or requests. We provide a detailed quote before finalizing your booking."
        },
        {
            question: "What is the cancellation policy for a Force Urbania rental?",
            answer: "Cancellation policies vary, but generally, early cancellations incur a nominal fee, while last-minute changes may involve higher charges. For specifics, please contact customer service."
        },
        {
            question: "Can I request a specific type of Force Urbania?",
            answer: "Absolutely! You can request particular features or types of Urbania buses based on availability. We offer various options to accommodate different needs."
        },
        {
            question: "Are the drivers experienced and licensed?",
            answer: "Yes, all our drivers are professionally trained, experienced, and possess valid licenses, ensuring a safe and comfortable travel experience."
        },
        {
            question: "Can I make changes to my booking after it is confirmed?",
            answer: "Yes, changes can be made subject to availability and any applicable fees. Please reach out to our customer service team as soon as possible for assistance."
        },
        {
            question: "What should I do if I need assistance during my journey?",
            answer: "If you need help during your journey, contact your driver or our customer service team immediately. We are committed to addressing any concerns promptly."
        },
        {
            question: "How far in advance should I book a Force Urbania?",
            answer: "To secure your rental, we recommend booking as early as possible, especially during peak seasons or special events."
        },
        {
            question: "Are there any restrictions on luggage or other items?",
            answer: "Standard luggage allowances apply, but special arrangements can be made upon request. Please inform us of any specific needs at the time of booking."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rajesh Kumar",
            text: "Our experience with SSKTravels24 for a group trip using the Force Urbania was exceptional. The bus was not only luxurious but also impeccably maintained, ensuring a comfortable ride for everyone. Our driver was professional and attentive, navigating us safely to each destination on time. I highly recommend SSKTravels24 for anyone seeking premium travel experiences in Pune."
        },
        {
            name: "Ms. Priya Mehta",
            text: "We rented a Force Urbania for a corporate event, and the service from SSKTravels24 was outstanding. The bus was incredibly spacious and came equipped with all the modern amenities we needed, making our journey enjoyable and stress-free. The booking process was seamless, and our driver was well-versed in the best routes, ensuring we arrived punctually. SSKTravels24 truly exceeded our expectations, and we’ll definitely be using their services again."
        }
    ];
    
    const contactData = {
        heading: "Luxury Force Urbania Rentals in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@SSKtravels24.com"
    };
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Force Urbania Luxury Mini Bus On Rent | Affordable Rental Services</title>
  <meta name="description" content="Rent Force Urbania luxury mini buses in Pune for corporate events, airport transfers, and outstation trips. Competitive rates for 13 and 17 seater options." />
  <meta name="keywords" content="Force Urbania luxury mini bus on rent Hadapsar | 13 seater Urbania bus hire in Kharadi | 17 seater Urbania bus hire in Kharadi | luxury bus for corporate events | Pune airport Urbania bus on rent | Pune to Mumbai Airport Urbania bus on rent | Force Urbania luxury mini bus on rent for outstation | Urbania bus hire in Hinjewadi | Urbania bus hire in Aundh Baner | Urbania bus on rent for Pune to Shirdi darshan | Force Urbania on rent in Pune airport | Force Urbania on rent in Pune airport near me | Force Urbania on rent in Pimpri Chinchwad | Force Urbania on rent in Magarpatta City | Force Urbania on rent in Hinjewadi" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Force Urbania Luxury Mini Bus On Rent | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for Force Urbania luxury mini buses in Pune. Enjoy competitive rates for corporate events and airport transfers. Contact us to book!" />
  <meta property="og:url" content="https://https://ssktravels24.com//force-urbania-luxury-mini-bus-on-rent" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//force-urbania-mini-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-18.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default ForceUrbaniaLuxuryMiniBusonRent;
import React from 'react';
const BusRatesTable = () => {
  const ratesData = [
    { seater: "13 Seater", mumbai: "Tempo Traveller", mahabaleshwar: "On Call", specialPermitNumber: "8956170493" },
    { seater: "14 Seater", mumbai: "Tata Winger", mahabaleshwar: "On Call", specialPermitNumber: "8956170493" },
    { seater: "17 Seater", mumbai: "Tempo Traveller", mahabaleshwar: "On Call", specialPermitNumber: "8956170493" },
    { seater: "17 Seater", mumbai: "Urbania Bus", mahabaleshwar: "On Call", specialPermitNumber: "8956170493" },
    { seater: "32 Seater", mumbai: "Ac Bus", mahabaleshwar: "On Call", specialPermitNumber: "8956170493" },
    { seater: "35 Seater", mumbai: "Ac Bus", mahabaleshwar: "On Call", specialPermitNumber: "8956170493" },
    { seater: "45 Seater", mumbai: "Ac Bus", mahabaleshwar: "On Call", specialPermitNumber: "8956170493" },
  ];

  const ratesData2 = [
    { seater: "13 Seater", mumbai: "Tempo Traveller", mahabaleshwar: "On Call", specialPermit: "8956170493" },
    { seater: "17 Seater", mumbai: "Tempo Trveller", mahabaleshwar: "On Call",  specialPermit: "8956170493" },
    { seater: "32 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "On Call",  specialPermit: "8956170493" },
    { seater: "35 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "On Call", specialPermit: "8956170493" },
    { seater: "45 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "On Call", specialPermit: "8956170493" },
  ];

  const ratesData3 = [
    { seater: "13 Seater", rate: "₹5000", extraKm: 25, extraHr: "₹300" },
    { seater: "17 Seater", rate: "₹5500", extraKm: 26, extraHr: "₹300" },
    { seater: "20 Seater", rate: "₹6000", extraKm: 27, extraHr: "₹300" },
    { seater: "26 Seater", rate: "₹8500", extraKm: 38, extraHr: "₹500" },
    { seater: "32 Seater", rate: "₹11000", extraKm: 48, extraHr: "₹700" },
    { seater: "35 Seater", rate: "₹12000", extraKm: 50, extraHr: "₹700" },
    { seater: "45 Seater", rate: "₹13500", extraKm: 65, extraHr: "₹1000" }
  ];

  const ratesData4 = [
    { seater: "13 Seater", rate: "₹4500", extraKm: 21, extraHr: "₹300" },
    { seater: "17 Seater", rate: "₹5500", extraKm: 22, extraHr: "₹300" },
    { seater: "20 Seater", rate: "₹5000", extraKm: 24, extraHr: "₹300" },
    { seater: "26 Seater", rate: "₹7000", extraKm: 32, extraHr: "₹500" },
    { seater: "32 Seater", rate: "₹5500", extraKm: 33, extraHr: "₹700" },
    { seater: "35 Seater", rate: "₹9000", extraKm: 44, extraHr: "₹700" },
    { seater: "45 Seater", rate: "₹10000", extraKm: 48, extraHr: "₹1000" }
  ];


  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "20px auto"
  };

  const thStyle = {
    backgroundColor: "#913923",
    color: "white",
    padding: "8px",
    border: "1px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    border: "1px solid #ddd",
    textAlign: "center"
  };

  const trEvenStyle = {
    backgroundColor: "#f2f2f2"
  };

  const trHoverStyle = {
    cursor: "pointer",
  };




  const handleWhatsAppClick = (number) => {
    const message = `Hi, I would like to inquire about the Rates.`;
    const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };


  

  return (
    <div className='container p-0'>
      <h3>Rates for AC Buses</h3>
      <div className='table-responsive'>
      <table style={tableStyle}>
  <thead>
    <tr>
      <th style={thStyle}>BUSES</th>
      <th style={thStyle}>Car Name</th>
      <th style={thStyle}>Rates</th>
      <th style={thStyle}>Book Now</th>
    </tr>
  </thead>
  <tbody>
    {ratesData.map((row, index) => (
      <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
        <td style={tdStyle}>{row.seater}</td>
        <td style={tdStyle}>{row.mumbai}</td>
        <td style={tdStyle}>{row.mahabaleshwar}</td>
        <td style={tdStyle}>
          <button
          className='rounded-3 border-0 p-2'
            onClick={() => handleWhatsAppClick(row.specialPermitNumber)}
            style={{ cursor: 'pointer', backgroundColor:'#913923' ,color:'white'}}
          >
            Book Now
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>
      </div>



    <div>
      <h3>Rates for Non AC buses</h3>
      <div className='table-responsive'>
      <table style={tableStyle}>
  <thead>

    
    <tr>
      <th style={thStyle}>BUSES</th>
      <th style={thStyle}>Car Name</th>
      <th style={thStyle}>Rates</th>
      <th style={thStyle}>Book Now </th>
    </tr>
  </thead>
  <tbody>
    {ratesData2.map((row, index) => (
      <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
        <td style={tdStyle}>{row.seater}</td>
        <td style={tdStyle}>{row.mumbai}</td>
        <td style={tdStyle}>{row.mahabaleshwar}</td>
        <td style={tdStyle}>
          <button
          className='rounded-3 border-0 p-2'
            onClick={() => handleWhatsAppClick(row.specialPermitNumber)}
            style={{ cursor: 'pointer', backgroundColor:'#913923' ,color:'white' }}
          >
            Book Now
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>


      </div>
    </div>



    {/* <div>
      <h3>Local Rates for 8 Hrs / 80 KM AC</h3>
      <div className='table-responsive'>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>BUSES</th>
            <th style={thStyle}>Rate</th>
            <th style={thStyle}>Extra KM</th>
            <th style={thStyle}>Extra HR</th>
          </tr>
        </thead>
        <tbody>
          {ratesData3.map((row, index) => (
            <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
              <td style={tdStyle}>{row.seater}</td>
              <td style={tdStyle}>{row.rate}</td>
              <td style={tdStyle}>{row.extraKm}</td>
              <td style={tdStyle}>{row.extraHr}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div> */}






    {/* <div>
      <h3>Local Rates for 8 Hrs / 80 KM Non AC</h3>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>BUSES</th>
            <th style={thStyle}>Rate</th>
            <th style={thStyle}>Extra KM</th>
            <th style={thStyle}>Extra Hour</th>
          </tr>
        </thead>
        <tbody>
          {ratesData4.map((row, index) => (
            <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
              <td style={tdStyle}>{row.seater}</td>
              <td style={tdStyle}>{row.rate}</td>
              <td style={tdStyle}>{row.extraKm}</td>
              <td style={tdStyle}>{row.extraHr}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> */}


    </div>
  );
};

export default BusRatesTable;

import React from 'react';
import { Helmet } from 'react-helmet'
const ContactSection = () => {
  return (
    <div>

<Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
<section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Contact Us</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



      <section aria-label="section " style={{backgroundColor:'#913923'}} >
        <div className="container">
          <div className="row g-custom-x ">
            <div className="col-lg-4 mb-sm-30">
              <div className="de-box mb30 coloredd">
                <h4 className='text-white'>Contact Numbers</h4>
              
                <address className="s1">
  <span>
    <a href="tel:+918956170493" className='text-white'>
      <i className="fa fa-phone fa-lg" style={{ color: '#913923' }}></i>
      +91 8956170493
    </a>
  </span>
  <span>
    <a href="tel:+917276307000" className='text-white'>
      <i className="fa fa-phone fa-lg" style={{ color: '#913923' }}></i>
      +91 7276307000
    </a>
  </span>
</address>

              </div>
            </div>

            <div className="col-lg-4 mb-sm-30">
              <div className="de-box mb30 coloredd">
                <h4 className='text-white'>Email</h4>
                <address className="s1">
                  <span><i className="fas fa-envelope" style={{color:'#913923'}}></i>
                  <a href="mailto:booking@ssktravels24.com" className='text-white'>booking@ssktravels24.com</a></span>
                </address>
              </div>
            </div>
            
            <div className="col-lg-4 ">
              <div className="de-box mb30 coloredd">
                <h4 className='text-white'>Address</h4>
                <address className="s1">
                  <span><i className="id-color fa fa-map-marker fa-lg" style={{color:'#913923'}}></i>office No 109, Ashoka Plaza, First Floor, Vimannagar Pune, Maharashtra 411014</span>
                  
                 
                </address>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='p-0'>
        <div className="container-fluid">
          <div className="row g-0">
            <div className="col-12">

<iframe 
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3156.8525042069955!2d73.91009167423843!3d18.559916017973073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c140777e0863%3A0xa1e94ed8abda7386!2sAshoka%20Plaza%2C%20Sakore%20Nagar%2C%20Viman%20Nagar%2C%20Pune%2C%20Maharashtra%20411014!5e1!3m2!1sen!2sin!4v1729054310776!5m2!1sen!2sin" 
    width="100%" 
    height="450" 
    style={{ border: 0 }} 
    allowFullScreen 
    loading="lazy" 
    referrerPolicy="no-referrer-when-downgrade"
></iframe>


              
            </div>
          </div>
        </div>
      </section>



    </div>
  );
};

export default ContactSection;

import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics';

const GallerySection = () => {
  const galleryItems = [
    { id: 1, src: '/images/ssktravels____galleryimages/Gallery-01.jpg', alt: 'Gallery Image 1', title: 'Gallery Title 1' },
    { id: 2, src: '/images/ssktravels____galleryimages/Gallery-02.jpg', alt: 'Gallery Image 2', title: 'Gallery Title 2' },
    { id: 3, src: '/images/ssktravels____galleryimages/Gallery-03.jpg', alt: 'Gallery Image 3', title: 'Gallery Title 3' },
    { id: 4, src: '/images/ssktravels____galleryimages/Gallery-04.jpg', alt: 'Gallery Image 4', title: 'Gallery Title 4' },
    { id: 5, src: '/images/ssktravels____galleryimages/Gallery-05.jpg', alt: 'Gallery Image 5', title: 'Gallery Title 5' },
    { id: 6, src: '/images/ssktravels____galleryimages/Gallery-06.jpg', alt: 'Gallery Image 6', title: 'Gallery Title 6' },
    { id: 7, src: '/images/ssktravels____galleryimages/Gallery-07.jpg', alt: 'Gallery Image 7', title: 'Gallery Title 7' },
    { id: 8, src: '/images/ssktravels____galleryimages/Gallery-08.jpg', alt: 'Gallery Image 8', title: 'Gallery Title 8' },
    { id: 9, src: '/images/ssktravels____galleryimages/Gallery-09.jpg', alt: 'Gallery Image 9', title: 'Gallery Title 9' },
    { id: 10, src: '/images/ssktravels____galleryimages/Gallery-10.jpg', alt: 'Gallery Image 10', title: 'Gallery Title 10' },
    { id: 12, src: '/images/ssktravels____galleryimages/Gallery-11.jpg', alt: 'Gallery Image 11', title: 'Gallery Title 11' },
    { id: 13, src: '/images/ssktravels____galleryimages/Gallery-12.jpg', alt: 'Gallery Image 12', title: 'Gallery Title 12' },
    { id: 14, src: '/images/ssktravels____galleryimages/Gallery-13.jpg', alt: 'Gallery Image 13', title: 'Gallery Title 13' },
    { id: 15, src: '/images/ssktravels____galleryimages/Gallery-14.jpg', alt: 'Gallery Image 14', title: 'Gallery Title 14' },
    { id: 16, src: '/images/ssktravels____galleryimages/Gallery-15.jpg', alt: 'Gallery Image 15', title: 'Gallery Title 15' }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openModal = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Inline styles
  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1000,
  };

  const modalContentStyles = {
    position: 'relative',
    textAlign: 'center', // Center text and buttons
  };

  const modalImageStyles = {
    maxWidth: '90%',
    maxHeight: '80%',
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: 'px',
    right: '29px',
    color: 'white',
    fontSize: '30px',
    cursor: 'pointer',
  };

  const modalNavigationStyles = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px', // Space between image and navigation buttons
  };

  const navButtonStyles = {
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '30px',
    cursor: 'pointer',
    margin: '0 10px', // Spacing between buttons
  };

  return (
    <div>
      <GoogleAnalytics/>
<Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
<section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Gallery</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>

    <div className="no-bottom no-top" id="content">
      <section aria-label="Gallery Section ">
        <div className="container p-0">
          <div id="gallery" className="row">
            {galleryItems.map((item, index) => (
              <div className="col-md-4 mb30 item" key={item.id}>
                <div className="de-image-hover rounded">
                  <img
                    src={item.src}
                    className="lazy img-fluid"
                    alt={item.alt}
                    onClick={() => openModal(index)}
                    style={{ cursor: 'pointer' }}
                  />
                  <span className="hover-message">Click Me</span>
                  <span className="dih-title-wrap">
                    <span className="dih-title">{item.title}</span>
                  </span>
                  <span className="dih-overlay"></span>
                </div>
              </div>
            ))}
          </div>

          {/* Modal for image display */}
          {isOpen && (
            <div style={modalStyles} onClick={() => setIsOpen(false)}>
              <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
                <span style={closeButtonStyles} onClick={() => setIsOpen(false)}>&times;</span>
                <img src={galleryItems[photoIndex].src} alt={galleryItems[photoIndex].alt} style={modalImageStyles} />
                <div style={modalNavigationStyles}>
                  <button style={navButtonStyles} onClick={() => setPhotoIndex((photoIndex + galleryItems.length - 1) % galleryItems.length)}>&lt;</button>
                  <button style={navButtonStyles} onClick={() => setPhotoIndex((photoIndex + 1) % galleryItems.length)}>&gt;</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <style>{`
        .de-image-hover {
          position: relative;
        }

        .hover-message {
          position: absolute;
          bottom: 10px;
          left: 10px;
          color: white;
          background-color: rgba(0, 0, 0, 0.7);
          padding: 5px 10px;
          border-radius: 5px;
          opacity: 0; /* Hidden by default */
          transition: opacity 0.3s;
        }

        .de-image-hover:hover .hover-message {
          opacity: 1; /* Show on hover */
        }
      `}</style>
    </div>
    </div>
  );
};

export default GallerySection;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';



function PunetoOutstationUrbaniaBusOnRent(){


    const cardData = 
    {
        keyword: 'Pune to Outstation Urbania Bus On Rent',
        heading: 'SSKTravels24: Pune to Outstation Urbania Bus On Rent',
        headingDescription: 'If you\'re planning an outstation trip from Pune, renting an Urbania bus from SSKTravels24 is the perfect solution for comfortable and spacious travel. The Urbania bus offers an ideal combination of luxury and functionality, accommodating large groups with ease.',
        
        topPlaces: [
            {
                title: "1. Spacious Interiors",
                description: "The Urbania bus is designed with spacious interiors that provide ample legroom and headspace, making it perfect for long journeys. Comfortably seat up to 20-30 passengers without feeling cramped."
            },
            {
                title: "2. Modern Amenities",
                description: "Equipped with modern amenities such as air conditioning, plush seating, and a sound system, the Urbania ensures a comfortable travel experience. Enjoy entertainment options during your journey."
            },
            {
                title: "3. Experienced Drivers",
                description: "Our professional drivers are well-trained and familiar with various routes, ensuring a safe and efficient travel experience. They prioritize passenger comfort and safety."
            },
            {
                title: "4. Affordable Pricing",
                description: "SSKTravels24 offers competitive pricing for Urbania bus rentals, making it an economical choice for group travel. Get excellent value without compromising on quality."
            },
            {
                title: "5. Flexible Rental Options",
                description: "We provide flexible rental options, including hourly, daily, and long-term rentals. Tailor your booking to meet your specific travel needs."
            },
            {
                title: "6. Ideal for Group Trips",
                description: "Whether it's a family outing, corporate retreat, or a school trip, the Urbania bus is perfect for transporting large groups comfortably."
            },
            {
                title: "7. Customized Itineraries",
                description: "We offer customized itineraries based on your travel plans, ensuring you get the most out of your trip. Enjoy the flexibility of choosing your stops and destinations."
            },
            {
                title: "8. Safe and Reliable",
                description: "Safety is our top priority. Our Urbania buses undergo regular maintenance and inspections, ensuring a reliable and safe travel experience for all passengers."
            },
            {
                title: "9. Easy Booking Process",
                description: "Booking an Urbania bus is simple and hassle-free with SSKTravels24. Just give us a call or visit our website to reserve your bus in minutes."
            },
            {
                title: "10. Excellent Customer Support",
                description: "Our dedicated customer support team is always available to assist you with any queries or concerns. We strive to make your travel experience seamless from start to finish."
            }
        ],
        
        services: [
            {
                name: "Urbania Bus for Picnics",
                description: "Perfect for transporting your group comfortably to parks, lakes, or any recreational spot. Enjoy spacious seating and ample storage for all your picnic essentials."
            },
            {
                name: "Pune to Gujarat Urbania Bus on Rent",
                description: "Traveling to Gujarat? Our Urbania buses provide a comfortable and stylish ride for your journey, ensuring a smooth experience."
            },
            {
                name: "Pune to Bangalore Urbania Bus on Rent",
                description: "Book our Urbania bus for your trip to Bangalore. Enjoy luxury travel with modern amenities, making your long-distance journey enjoyable and hassle-free."
            },
            {
                name: "Pune to Hyderabad Urbania Bus on Rent",
                description: "Head to Hyderabad with our spacious Urbania buses, perfect for large groups. Experience comfort and reliability while enjoying the scenic views along the way."
            },
            {
                name: "Pune to Bhimashankar Urbania Bus on Rent",
                description: "Planning a religious trip? Our Urbania bus is an excellent choice for traveling to Bhimashankar, providing comfort and convenience for your pilgrimage."
            },
            {
                name: "Pune to Trimbakeshwar Urbania Bus on Rent",
                description: "Visit the sacred Trimbakeshwar Temple in style. Our Urbania buses offer the perfect blend of luxury and comfort for spiritual journeys."
            },
            {
                name: "Contact Number for Urbania Bus on Rent",
                description: "For prompt and efficient Pune to Outstation Urbania Bus On Rent, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['-Urbania Luxury Bus Rental in Pune for Picnic', '-Pune to Gujarat Urbania Bus on Rent'],
            ['-Pune to Bangalore Urbania Bus on Rent', '-Pune to Hyderabad Urbania Bus on Rent'],
            ['-Pune to Bhimashankar Urbania Bus on Rent', '-Pune to Trimbakeshwar Urbania Bus on Rent'],
            ['-Pune to Grishneshwar Urbania Bus on Rent', '-Urbania Bus Hire for Trip'],
            ['-Pune to Nagpur Urbania Bus on Rent', '-Pune to Pandharpur Urbania Bus on Rent'],
            ['-Pune to Akkalkot Tour Package Urbania on Rent', '-Pune to Tuljapur Urbania on Rent'],
            ['-Force Urbania for Rent Near Me', '-Urbania on Rent in Viman Nagar Pune'],
            ['-Urbania on Rent in Hinjewadi Pune', '-Tempo Traveller Urbania on Rent']
          ],
          whychoose: [
            {
              WhyChooseheading: "Comfortable Travel:",
              WhyChoosedescription: "Our Urbania buses are designed with spacious interiors, plush seating, and modern amenities, ensuring a comfortable journey for you and your group."
            },
            {
              WhyChooseheading: "Affordable Pricing:",
              WhyChoosedescription: "We offer competitive pricing for our Urbania bus rentals, providing excellent value without compromising on quality."
            },
            {
              WhyChooseheading: "Experienced Drivers:",
              WhyChoosedescription: "Our professional drivers are well-trained and familiar with all routes, ensuring a safe and efficient travel experience."
            },
            {
              WhyChooseheading: "Flexible Booking Options:",
              WhyChoosedescription: "Whether you need the bus for a day trip, a weekend getaway, or an extended journey, we provide flexible rental packages to suit your needs."
            },
            {
              WhyChooseheading: "On-Time Service:",
              WhyChoosedescription: "Punctuality is our priority. We ensure that our buses arrive on time for your scheduled pick-up, allowing you to travel without delays."
            },
            {
              WhyChooseheading: "Safety First:",
              WhyChoosedescription: "Safety is paramount at SSKTravels24. Our buses undergo regular maintenance and safety checks, ensuring a secure travel environment."
            },
            {
              WhyChooseheading: "Customer Support:",
              WhyChoosedescription: "Our dedicated customer service team is available to assist you with your queries and booking needs, ensuring a smooth experience from start to finish."
            },
            {
              WhyChooseheading: "Wide Range of Destinations:",
              WhyChoosedescription: "We cater to various outstation destinations, whether it's a pilgrimage, a picnic, or a corporate event, we can take you there."
            },
            {
              WhyChooseheading: "Luxury Amenities:",
              WhyChoosedescription: "Enjoy facilities like air conditioning, audio systems, and ample luggage space, making your journey enjoyable and stress-free."
            },
            {
              WhyChooseheading: "Positive Reviews:",
              WhyChoosedescription: "We pride ourselves on our customer satisfaction, backed by numerous positive testimonials from clients who have enjoyed our services."
            }
          ]
    };    

    
    const faqData = [
        {
            question: "How do I book an Urbania bus for outstation travel?",
            answer: "You can book an Urbania bus through our website or by contacting our customer service team. Provide your travel dates, destination, and group size for an accurate quote."
        },
        {
            question: "What is the seating capacity of the Urbania bus?",
            answer: "Our Urbania buses can accommodate up to 20 passengers comfortably, making them ideal for group travel."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes, our Urbania buses come with air conditioning, comfortable seating, audio systems, and ample storage for luggage."
        },
        {
            question: "Do you provide drivers with the bus rental?",
            answer: "Yes, every Urbania bus rental includes a professional driver who is experienced and knowledgeable about the routes."
        },
        {
            question: "What are the payment options available?",
            answer: "We accept various payment methods, including online transfers, cash, and credit/debit cards for your convenience."
        },
        {
            question: "Can I cancel or modify my booking?",
            answer: "Yes, you can modify or cancel your booking. Please refer to our cancellation policy for details."
        },
        {
            question: "Are there any hidden charges?",
            answer: "No, we believe in transparent pricing. The quote you receive will be inclusive of all charges with no hidden fees."
        },
        {
            question: "What should I do in case of an emergency during travel?",
            answer: "Our drivers are trained to handle emergencies. You can also contact our customer support team for assistance at any time during your journey."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Sandeep Salunkhe",
            text: "I recently booked an Urbania bus for a family trip from Pune to Mahabaleshwar, and the experience was fantastic! The bus was spacious and comfortable, perfect for our group of 15. Our driver was punctual and very friendly, making the journey enjoyable. I highly recommend SSKTravels24 for anyone looking for reliable transportation!"
        },
        {
            name: "Ms. Verma",
            text: "Our corporate team needed transportation for a weekend retreat, and we chose SSKTravels24 for the Urbania bus rental. The booking process was straightforward, and the bus was well-maintained with all the amenities we needed. The driver was professional and ensured we arrived safely and on time. A big thumbs up for their excellent service!"
        }
    ];
    
    const contactData = {
        heading: "Pune to Outstation Urbania Bus On Rent",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Outstation Urbania Bus On Rent | Luxury Bus Rental</title>
  <meta name="description" content="Rent Urbania buses for outstation trips from Pune. Perfect for group travel, picnics, and special occasions. Book your luxury bus today!" />
  <meta name="keywords" content="Pune to outstation Urbania bus on rent, Urbania luxury bus rental in Pune, Pune to Bangalore Urbania bus, Pune to Hyderabad Urbania bus" />
  <meta property="og:title" content="Pune to Outstation Urbania Bus On Rent | SSK Travels24" />
  <meta property="og:description" content="Explore various destinations with our Urbania bus rentals from Pune. Ideal for family trips and corporate events. Contact us for bookings!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-outstation-urbania-bus" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//outstation-urbania-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-36.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PunetoOutstationUrbaniaBusOnRent;
import React from 'react';
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics';
const PrivacyPolicy = () => {
    return (
      <div>
        <GoogleAnalytics/>
        <Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
        <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Privacy Policy</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>
   


    <section className="privacy-policy">
      <div className="container px-0">
        <div className="row g-0">
          <div className="col-12 col-md-7  ">
        
            <h3 className="title">Privacy & Policy</h3>

            <p>
              At SSKTravels24 (A Division of SSK Logistics), we are committed to protecting your privacy and safeguarding your personal information.
              This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our services.
            </p>

            <div className="section px-2">
              <p className="highlight darkcolor">Information We Collect:</p>
              <p className="highlight darkcolor">1. Personal Information:</p>
              <p>
                When you book a ticket or use our services, we may collect personal information such as your name, contact details, payment information, and travel preferences.
              </p>

              <p className="highlight darkcolor">2. Usage Information:</p>
              <p>
                We may collect information about how you interact with our website, mobile app, and other digital platforms, including IP address, device information, browser type, and pages visited.
              </p>

              <p className="highlight darkcolor">3. Location Information:</p>
              <p>
                With your consent, we may collect your location information to provide location-based services or improve our service offerings.
              </p>
            </div>

            <div className="section">
              <p className="highlight darkcolor">1. To Provide Services:</p>
              <p>
                We use your information to process bookings, manage reservations, and provide transportation services.
              </p>

              <p className="highlight darkcolor">2. To Improve Services:</p>
              <p>
                We analyze user behavior and feedback to improve our services, enhance user experience, and develop new features.
              </p>

              <p className="highlight darkcolor">3. For Communication:</p>
              <p>
                We may use your contact information to send booking confirmations, updates, promotional offers, and important notifications related to your travel.
              </p>

              <p className="highlight darkcolor">4. For Marketing:</p>
              <p>
                With your consent, we may use your information to send marketing communications about our services, special offers, and promotions.
              </p>
            </div>

            <div className="section">
              <p className="highlight darkcolor">Information Sharing and Disclosure:</p>
              <p className="highlight darkcolor">1. Third-Party Service Providers:</p>
              <p>
                We may share your information with trusted third-party service providers who assist us in providing and improving our services, such as payment processors, IT service providers, and marketing agencies.
              </p>

              <p className="highlight darkcolor">2. Legal Compliance:</p>
              <p>
                We may disclose your information as required by law or to protect our rights, property, or safety, or the rights, property, or safety of others.
              </p>
            </div>

            <div className="section">
              <p className="highlight darkcolor">Data Security:</p>
              <p>
                We employ industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
              </p>

              <p className="highlight darkcolor">Your Rights:</p>
              <p>
                You have the right to access, update, or delete your personal information held by us. You may also opt-out of receiving marketing communications from us at any time.
              </p>

              <p className="highlight darkcolor">Changes to this Privacy Policy:</p>
              <p>
                We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website or other communication channels.
              </p>
            </div>
          </div>
          <div className='col-12 col-md-5'>
    <div className="image-container">
              <img src="\images\For mail\privacy policy and Term & condition-01.jpg" className="bg-cover" alt="Privacy Policy" />
            </div>
    </div>

   

<div className="services__two-item d-flex flex-column align-items-center">
  <div className="mybox1 text-center p-4 mb-4 shadow-sm" style={{ borderRadius: '8px', backgroundColor: '#913923', color:'white' }}>
    <h4 className='text-white mb-3'>Phone Numbers</h4>
    <i className="bi bi-telephone-fill fs-1 mb-2"></i>
    <ul className="list-unstyled">
      <li>
        <a href="tel:+918956170493" className='text-decoration-none text-white'>
          +91 8956170493
        </a>
      </li>
      <li>
        <a href="tel:+917276307000" className='text-decoration-none text-white'>
          +91 7276307000
        </a>
      </li>
    </ul>
  </div>

  <div className="mybox1 text-center p-4 mb-4 shadow-sm" style={{ borderRadius: '8px', backgroundColor: '#913923' }}>
    <h4 className='text-white mb-3'>Email</h4>
    <i className="bi bi-envelope fs-1 mb-2"></i>
    <a href="mailto:booking@ssktravels24.com
" className='text-decoration-none text-white'>
      booking@ssktravels24.com
    </a>
  </div>
</div>




        </div>
        
      </div>
      
    </section>


    
      </div>
    );
};

export default PrivacyPolicy;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function PuneBushirereantal(){


    const cardData = 
    {
        keyword: 'Pune Bus Hire Rental Service',
        heading: 'SSKTravels24: Pune Bus Hire Rental Service',
        headingDescription: 'When it comes to bus hire rental services in Pune, SSKTravels24 is the leading choice known for its exceptional service and reliability. Whether you’re planning a corporate event, family outing, or group trip, SSKTravels24 offers a variety of options to ensure a comfortable and enjoyable journey. Here’s a guide to the top places you can explore with our bus hire rental service in Pune:',
        
        topPlaces : [
            {
                title: "1. Lonavala",
                description: "Located just a short drive from Pune, Lonavala is a popular hill station celebrated for its scenic beauty and lush landscapes. With SSKTravels24, you can hire a bus to visit attractions like Bhushi Dam, Lonavala Lake, and the Karla Caves."
            },
            {
                title: "2. Mahabaleshwar",
                description: "Famous for its stunning viewpoints, such as Arthur’s Seat and Wilson Point, Mahabaleshwar is a must-visit hill station. SSKTravels24 offers comfortable bus rentals to help you enjoy a relaxing journey."
            },
            {
                title: "3. Khandala",
                description: "Adjacent to Lonavala, Khandala is known for its picturesque valleys and waterfalls. Book a bus with SSKTravels24 to visit popular spots like Duke’s Nose and Khandala Lake."
            },
            {
                title: "4. Alibaug",
                description: "This coastal town features beautiful beaches and historic forts, making it a great destination for a beach getaway. Choose SSKTravels24 for a hassle-free bus rental experience to this serene location."
            },
            {
                title: "5. Pune to Shirdi",
                description: "Known for the Shirdi Sai Baba Temple, Shirdi is a major pilgrimage site. Our bus rental service makes it easy to travel comfortably to this spiritual destination."
            },
            {
                title: "6. Lavasa",
                description: "A planned city with picturesque lakeside views, Lavasa offers a blend of natural beauty and modern amenities. Opt for SSKTravels24 to explore Lavasa’s scenic landscapes and enjoy a peaceful retreat."
            },
            {
                title: "7. Nashik",
                description: "Renowned for its vineyards and wine tours, Nashik is a favorite destination for wine enthusiasts. SSKTravels24 provides reliable bus hire services to explore Nashik’s wineries and temples."
            },
            {
                title: "8. Tungabhadra",
                description: "For a historical adventure, head to Tungabhadra, known for its ancient temples and ruins. SSKTravels24 ensures a comfortable journey to this unique historical site."
            },
            {
                title: "9. Kolhapur",
                description: "Famous for its rich cultural heritage and the Mahalaxmi Temple, Kolhapur offers a fascinating glimpse into Maharashtra’s history. Book a bus with SSKTravels24 for a smooth trip to this vibrant city."
            },
            {
                title: "10. Ashtavinayak Temples",
                description: "This pilgrimage tour covers eight sacred Ganesh temples around Pune. With SSKTravels24, you can hire a bus to comfortably visit all the Ashtavinayak temples and experience a spiritual journey."
            }
        ],
        
        services : [
            {
                name: "Affordable Bus Hire in Pune",
                description: "At SSKTravels24, we offer a variety of affordable bus hire options tailored to meet diverse group sizes and travel needs. Whether you’re planning a wedding, corporate event, or group tour, our extensive fleet includes luxury, standard, and mini buses, ensuring you find the perfect vehicle for your journey."
            },
            {
                name: "Local Bus Companies for Hire",
                description: "We collaborate with reputable local bus companies to provide a wide range of vehicles, from budget-friendly options to premium coaches. This partnership allows us to offer competitive rates while ensuring you have access to the best transportation solutions for any journey, enhancing your travel experience."
            },
            {
                name: "Pune Pandharpur Tuljapur Bus on Rent",
                description: "Embark on a spiritual journey with our bus rental services to Pandharpur and Tuljapur. Our buses are equipped with modern amenities to ensure a comfortable and stress-free experience. Enjoy spacious seating and well-maintained interiors as you travel to these revered destinations."
            },
            {
                name: "Pune to Kolhapur Bus on Rent",
                description: "Discover the rich cultural heritage of Kolhapur effortlessly with our bus rental services. Our professional drivers navigate the route smoothly, allowing you to enjoy the scenic views along the way. Our well-maintained buses ensure a hassle-free trip, making your journey as enjoyable as the destination."
            },
            {
                name: "Pune to Konkan Darshan Bus on Rent",
                description: "Explore the breathtaking beauty of the Konkan region with our reliable bus rentals. We provide comfortable transportation tailored for your Konkan Darshan tour, allowing you to savor the stunning landscapes and cultural attractions without the hassle of travel concerns."
            },
            {
                name: "Pune to Shirdi Bus Hire from Hadapsar",
                description: "Planning a pilgrimage to Shirdi? Our bus hire services from Hadapsar ensure comfort and convenience, allowing you to focus on your spiritual journey. With experienced drivers and well-equipped buses, we take care of your transportation needs to this holy destination."
            },
            {
                name: "Rent a Bus in Pune",
                description: "Whether you need a bus for a single day or for an extended period, SSKTravels24 offers flexible rental options to suit your specific requirements. Our diverse fleet ensures you can find the perfect vehicle, whether for local excursions or outstation trips."
            },
            {
                name: "Luxury Bus on Rent in Pune",
                description: "Experience premium travel with our luxury bus rentals, designed for special occasions or high-profile events. Our luxury buses provide unmatched comfort, stylish interiors, and a range of amenities, including entertainment systems and refreshments, to enhance your travel experience."
            },
            {
                name: "40 Seater Bus on Rent in Pune",
                description: "For larger groups, our 40-seater buses offer ample space and comfort. Ideal for group trips, corporate outings, and events, these buses are designed to ensure that everyone travels comfortably, with spacious seating and sufficient luggage capacity."
            },
            {
                name: "27 Seater Bus on Rent in Pune",
                description: "Our 27-seater buses strike the perfect balance between space and affordability, making them ideal for medium-sized groups. Enjoy comfortable seating and essential amenities for a pleasant travel experience, whether for a corporate retreat or family outing."
            },
            {
                name: "17 Seater Bus on Rent in Pune",
                description: "Catering to smaller groups, our 17-seater buses offer a cozy and comfortable travel experience. These buses are perfect for intimate gatherings or short trips, providing a warm atmosphere while ensuring a smooth journey."
            },
            {
                name: "Traveller Bus on Rent in Pune",
                description: "For versatile travel needs, our Traveller buses offer spacious and comfortable options. Whether transporting a medium-sized group or requiring flexibility in your travel plans, these buses provide an excellent solution for various occasions."
            },
            {
                name: "Mini Bus on Rent in Pune",
                description: "Ideal for smaller groups or short journeys, our mini buses are perfect for local trips or smaller events. Offering convenience and affordability, these buses ensure a practical and comfortable travel experience."
            },
            {
                name: "Bus Nashik to Aurangabad on Rent",
                description: "Travel comfortably between Nashik and Aurangabad with our dedicated bus rental service. Our well-maintained buses are equipped with modern amenities, ensuring a smooth and enjoyable journey as you explore these vibrant cities."
            },
            {
                name: "Mini Bus on Rent in Pune",
                description: "For smaller groups or more intimate travel experiences, consider renting a mini bus from SSKTravels24. Perfect for family outings or small corporate events, our mini buses provide comfortable seating and essential amenities for a cozy and convenient travel solution."
            },
            {
                name: "Pune Bus Hire Rental Service in Pimpri Chinchwad",
                description: "SSKTravels24 offers exceptional bus hire rental services in Pimpri Chinchwad, catering to various transportation needs. Whether planning a local event or an outstation trip, our buses are equipped to handle different group sizes and preferences, ensuring reliable and comfortable travel."
            },
            {
                name: "Bus Hire Rental Service in Kharadi Pune",
                description: "In Kharadi, we provide premium bus hire rental services tailored to your specific needs. Our fleet includes a range of vehicles suitable for corporate events, family gatherings, and other group travel requirements, backed by our commitment to professional service and customer satisfaction."
            },
            {
                name: "Mini Coach Rental in Pune",
                description: "For a more spacious option while still enjoying the benefits of a smaller vehicle, consider renting a mini coach from SSKTravels24. Perfect for medium-sized groups, our mini coaches come equipped with comfortable seating and modern amenities, offering a versatile and comfortable travel solution."
            },
            {
                name: "Bus Hire Rental Service in Kothrud",
                description: "SSKTravels24 extends its reliable bus hire rental services to Kothrud, Pune. Whether you need a bus for a local event, a corporate outing, or an educational trip, our fleet is ready to accommodate your needs, ensuring top-quality service and comfortable travel."
            },
            {
                name: "Best Bus Rental Services in Shivaji Nagar",
                description: "For the best bus rental services in Shivaji Nagar, SSKTravels24 is your go-to provider. We offer a range of buses suited for different travel needs, from luxury options to economical choices, all delivered with our commitment to quality service and customer satisfaction."
            },
            {
                name: "Tempo Traveller Rental Service in Pune",
                description: "If you need a versatile vehicle for your group travel needs, our tempo traveller rental services are perfect for medium-sized groups. Enjoy comfort and flexibility for both local and outstation trips with our well-maintained tempo travellers, designed to provide a reliable and convenient travel experience."
            },
            {
                name: "Mini Bus Rental Service in Pune",
                description: "SSKTravels24 provides mini bus rental services in Pune, offering a practical and comfortable solution for group travel. Our mini buses are ideal for various occasions, including corporate events, family outings, and small tours, ensuring a pleasant journey for all passengers."
            },
            {
                name: "Mini Bus On Rent in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Mini Bus On Rent in Pune. Our team is ready to assist you in arranging a smooth and enjoyable ride tailored to your specific needs."
            }
        ],
         tableData : [
            ['- Bus Hire in Pune at Affordable Rate', '- Local Bus Companies for Hire'],
            ['- Pune Pandharpur Tuljapur Bus on Rent', '- Pune to Kolhapur Bus on Rent'],
            ['- Pune to Konkan Darshan Bus on Rent', '- Pune to Shirdi Bus Hire Hadapsar'],
            ['- Rent a Bus in Pune', '- Luxury Bus on Rent in Pune'],
            ['- 40 Seater Bus on Rent in Pune', '- 27 Seater Bus on Rent in Pune'],
            ['- 17 Seater Bus on Rent in Pune', '- Traveller Bus on Rent in Pune'],
            ['- Bus on Rent in Pune', '- Mini Bus on Rent in Pune'],
            ['- Bus Nashik to Aurangabad on Rent', '- Pune Bus Hire Rental Service in Pimpri Chinchwad'],
            ['- Bus Hire Rental Service in Kharadi Pune', '- Mini Coach Rental in Pune'],
            ['- Bus Hire Rental Service in Kothrud', '- Best Bus Rental Services in Shivaji Nagar'],
            ['- Tempo Traveller Rental Service in Pune', '- Mini Bus Rental Service in Pune']
        ],
        
    
        whychoose : [
            {
                "WhyChooseheading": "Comprehensive Fleet of Vehicles",
                "WhyChoosedescription": "SSKTravels24 offers a diverse range of vehicles, including luxury buses, mini buses, and standard coaches, tailored to meet all your travel needs. Whether you’re organizing a corporate event, wedding, or group tour, we have the ideal bus for your requirements."
            },
            {
                "WhyChooseheading": "Competitive Pricing",
                "WhyChoosedescription": "We provide affordable rates without compromising quality. Our transparent pricing model ensures there are no hidden charges, giving you the best value for your money."
            },
            {
                "WhyChooseheading": "Comfort and Convenience",
                "WhyChoosedescription": "Our buses are equipped with modern amenities to guarantee a comfortable journey. Features such as air conditioning, plush seating, and ample legroom enhance your travel experience."
            },
            {
                "WhyChooseheading": "Experienced Drivers",
                "WhyChoosedescription": "Our professional drivers are dedicated to providing a safe and smooth travel experience. They are well-trained in handling various types of trips and prioritize passenger safety."
            },
            {
                "WhyChooseheading": "Flexible Rental Options",
                "WhyChoosedescription": "We offer flexible rental plans to accommodate both short-term and long-term needs. Whether you need a bus for a few hours or several days, we can tailor a rental package that suits your schedule."
            },
            {
                "WhyChooseheading": "Exceptional Customer Service",
                "WhyChoosedescription": "Our customer support team is available 24/7 to assist with booking, itinerary planning, and any inquiries. We are committed to delivering excellent service from start to finish."
            },
            {
                "WhyChooseheading": "Reliable and Punctual",
                "WhyChoosedescription": "We pride ourselves on reliability and punctuality. You can count on us to be on time and ensure that your journey proceeds smoothly according to plan."
            },
            {
                "WhyChooseheading": "Customizable Itineraries",
                "WhyChoosedescription": "Every trip is unique. We offer customizable itineraries to fit your specific travel needs and preferences, ensuring a personalized experience."
            },
            {
                "WhyChooseheading": "Safety First",
                "WhyChoosedescription": "Safety is our top priority. Our buses undergo regular maintenance and inspections to ensure they are in excellent condition. We adhere to all safety regulations and protocols."
            },
            {
                "WhyChooseheading": "Positive Customer Feedback",
                "WhyChoosedescription": "Our satisfied customers frequently commend us for our reliable service, comfortable vehicles, and professional staff. We take pride in our positive reputation and continually strive to exceed expectations."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "How can I book a bus with SSKTravels24?",
            answer: "You can book a bus by contacting our customer service team via phone at +91 8956170493 or +91 7276307000, or by filling out the booking form on our website."
        },
        {
            question: "What types of buses are available for hire?",
            answer: "We offer a variety of buses including luxury buses, mini buses, standard coaches, and Traveller buses to meet different travel needs."
        },
        {
            question: "Are there additional charges for outstation trips?",
            answer: "Additional charges may apply for outstation trips. Please provide details about your travel plans for accurate pricing."
        },
        {
            question: "Can I customize the itinerary for my bus rental?",
            answer: "Yes, we offer customizable itineraries. Discuss your specific requirements with our team to tailor the itinerary to your needs."
        },
        {
            question: "What payment methods are accepted?",
            answer: "We accept various payment methods including online payments, credit/debit cards, and bank transfers."
        },
        {
            question: "What is the cancellation policy?",
            answer: "Our cancellation policy is flexible. Contact our customer support team to discuss cancellation terms and options."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes, our buses come with amenities such as air conditioning, comfortable seating, and entertainment options to enhance your travel experience."
        },
        {
            question: "How do I contact SSKTravels24 during my trip?",
            answer: "You can reach our 24/7 customer support team via phone or email for assistance during your trip."
        },
        {
            question: "What is the maximum number of passengers allowed?",
            answer: "The maximum number of passengers depends on the bus type and capacity. Choose a bus that suits your group size for optimal comfort."
        },
        {
            question: "Is there a distance limit for the bus rental?",
            answer: "There is no strict distance limit, but additional charges may apply for longer trips. Discuss your travel details with us for more information."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Rahul Deshmukh",
            text: "I recently hired a bus from SSKTravels24 for our company's annual retreat, and I couldn't be more impressed. The booking process was seamless, and the bus was in excellent condition with all the amenities we needed for a comfortable journey. The driver was professional and punctual, ensuring we arrived at our destination on time. I highly recommend SSKTravels24 for anyone looking for reliable and affordable bus rental services in Pune."
        },
        {
            name: "Ms. Priya Shah",
            text: "I used SSKTravels24 for a family wedding event, and their service was outstanding. We rented a luxury bus for our guests, and it was perfect. The bus was clean, well-maintained, and equipped with all the necessary comforts. The team at SSKTravels24 was very helpful and accommodating, making the entire process stress-free. I would definitely use their services again for future events."
        }
    ];
    
    
    const contactData = {
        heading: "Pune Bus Hire Rental Service Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune Bus Hire Rental Service | Affordable Bus Rentals</title>
  <meta name="description" content="Explore affordable bus hire and rental services in Pune. Book local and outstation buses for your travel needs." />
  <meta name="keywords" content="Bus Hire in Pune at Affordable Rate | Bus Rental in Pune | Local bus companies for hire | Pune Pandharpur Tuljapur bus on rent | Pune to Kolhapur bus on rent | Pune to Konkan Darshan bus on rent | Pune to Shirdi bus hire Hadapsar | Rent a bus Pune | Luxury bus on rent in Pune | 40 seater bus on rent in Pune | 27 seater bus on rent in Pune | 17 seater bus on rent in Pune | Traveller bus on rent in Pune | Mini bus on rent Pune | Nashik to Aurangabad bus on rent | Pune bus hire rental service in Pimpri Chinchwad | Bus hire rental service in Kharadi Pune | Mini Coach Rental in Pune | Bus hire rental service in Kothrud | Best bus rental services in Shivaji Nagar | Tempo traveller rental service in Pune | Mini Bus Rental Service in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune Bus Hire Rental Service | Affordable Bus Rentals" />
  <meta property="og:description" content="Book your bus for local and outstation trips at affordable rates in Pune!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-bus-hire-rental-service" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//bus-rental-service-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-39.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Pune Bus Hire Rental Service - SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PuneBushirereantal;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function PunetoGoaCabService(){


    const cardData = 
    {
        keyword: 'Pune to Goa Cab Service',
        heading: 'SSKTravels24: Pune to Goa Cab Service',
        headingDescription: 'Planning a trip to Goa from Pune? SSKTravels24 offers comfortable and reliable cab services, ensuring a smooth journey to the sun-soaked beaches and vibrant nightlife of Goa. Choose from a variety of vehicles tailored to your needs.',
      
        topPlaces: [
          {
            title: "1. Baga Beach",
            description: "One of the most popular beaches in Goa, Baga is known for its lively atmosphere, water sports, and beach shacks serving delicious seafood."
          },
          {
            title: "2. Calangute Beach",
            description: "Often referred to as the 'Queen of Beaches,' Calangute offers a vibrant mix of relaxation and adventure, ideal for families and solo travelers alike."
          },
          {
            title: "3. Fort Aguada",
            description: "This 17th-century Portuguese fort offers stunning views of the Arabian Sea. Explore its lighthouse and rich history while enjoying the scenic surroundings."
          },
          {
            title: "4. Dudhsagar Waterfalls",
            description: "A breathtaking natural wonder, Dudhsagar Waterfalls is one of the tallest waterfalls in India. Trekking to the falls is an exhilarating experience not to be missed."
          },
          {
            title: "5. Old Goa",
            description: "Discover the rich history and heritage of Goa by visiting the churches and cathedrals in Old Goa, including the famous Basilica of Bom Jesus, a UNESCO World Heritage Site."
          },
          {
            title: "6. Anjuna Flea Market",
            description: "For shopping enthusiasts, the Anjuna Flea Market offers a vibrant atmosphere filled with stalls selling clothing, jewelry, handicrafts, and souvenirs."
          },
          {
            title: "7. Chapora Fort",
            description: "Known for its stunning views, Chapora Fort gained fame from the movie 'Dil Chahta Hai.' It’s a great spot to relax and capture beautiful photographs."
          },
          {
            title: "8. Palolem Beach",
            description: "Renowned for its serene beauty, Palolem Beach is perfect for relaxation, kayaking, and enjoying Goa’s vibrant nightlife."
          },
          {
            title: "9. Spice Plantations",
            description: "Take a guided tour of one of Goa’s spice plantations to learn about the cultivation of spices and enjoy a traditional Goan meal amidst nature."
          },
          {
            title: "10. Nightlife in Goa",
            description: "Experience the vibrant nightlife with beach parties, clubs, and shacks offering live music and entertainment. Popular spots include Tito’s and Hilltop."
          }
        ],
      
        services: [
          {
            name: "Pune to Goa Cab Rental",
            description: "Travel in comfort with our Pune to Goa cab rental service, offering various vehicle options for family trips, group outings, or solo adventures."
          },
          {
            name: "One-Way Pune to Goa Cab",
            description: "Opt for a one-way cab service for a straightforward trip to Goa, ideal for those who don’t need a round-trip journey."
          },
          {
            name: "Pune to Goa Taxi Packages",
            description: "Choose from our customizable taxi packages, including round trips, one-way journeys, or personalized itineraries to make your Goa trip stress-free."
          },
          {
            name: "Pune to Goa Innova Crysta on Rent",
            description: "Enjoy luxury and comfort with our Innova Crysta rentals, perfect for families or travelers looking for a premium experience."
          },
          {
            name: "Pune to Goa Tempo Traveller on Rent",
            description: "For larger groups, our Tempo Traveller rental offers ample space and comfort, making it the ideal choice for group trips to Goa."
          },
          {
            name: "Pune to Goa Car Hire",
            description: "With flexible car hire options, explore Goa at your own pace, stopping at attractions along the way and enjoying the scenic drive."
          },
          {
            name: "Goa to Pune Cab Service",
            description: "Need a ride back to Pune? Our Goa to Pune cab service ensures a smooth and reliable return journey, making your travel worry-free."
          },
          {
            name: "Pune to Goa Taxi Fare",
            description: "Our competitive taxi fares are transparent, with no hidden charges. Get a quote tailored to your travel preferences."
          },
          {
            name: "Contact Number for Pune to Goa Cabs",
            description: "For prompt and efficient Pune to Goa cab service, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['Pune to Goa Cabs', 'Pune to Goa Car Rental'],
            ['Pune to Goa Taxi', 'Pune to Goa Taxi Fare'],
            ['Pune to Goa Cab Package', 'Pune to Goa Cab Fare'],
            ['Car Rental Pune to Goa', 'Goa to Pune Car Rental'],
            ['Pune Goa Cab Service', 'One Way Cab from Pune to Goa'],
            ['Pune to Goa Cab Charges', 'Pune to Goa Car Booking'],
            ['Pune to Goa Car Hire', 'Pune to Goa One Way Taxi'],
            ['Pune to Goa One Way Cab', 'Pune to Goa Taxi Packages'],
            ['Pune to Goa Taxi Service', 'Pune to Goa Tempo Traveller on Rent'],
            ['Pune to Goa Innova Crysta on Rent', 'Pune to Goa Innova Car Rental'],
            ['Pune to Goa Ertiga Cab on Rent', 'Pune to Goa Sedan Cab on Rent'],
            ['Pune to Goa Innova Rental', 'Pune to Goa Cab Package'],
            ['Pune to Goa Innova Crysta on Rent', 'Pune to Goa Urbania on Rent'],
            ['Pune to Goa Fortuner on Rent', 'SSK Travels 24'],
            ['Pune to Goa Urbania on Rent', '']
          ],
        
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for Pune to Goa Cabs – SSKTravels24?",
              WhyChoosedescription: "When planning your journey from Pune to Goa, SSKTravels24 stands out as the best choice for reliable and comfortable transportation. Here’s why:"
            },
            {
              WhyChooseheading: "1. Diverse Fleet of Vehicles",
              WhyChoosedescription: "We offer a wide range of vehicles to suit every need, from comfortable sedans to spacious SUVs and tempo travellers, ensuring a perfect fit for your travel group."
            },
            {
              WhyChooseheading: "2. Competitive Pricing",
              WhyChoosedescription: "Our rates are designed to be budget-friendly, with transparent pricing and no hidden charges. We provide detailed quotes so you know exactly what to expect."
            },
            {
              WhyChooseheading: "3. Experienced Drivers",
              WhyChoosedescription: "Our professional drivers are well-trained and familiar with the best routes. They prioritize your safety and comfort, making your journey enjoyable and stress-free."
            },
            {
              WhyChooseheading: "4. Customer-Centric Service",
              WhyChoosedescription: "At SSKTravels24, customer satisfaction is our top priority. Our dedicated support team is available to assist you before, during, and after your trip, ensuring a seamless experience."
            },
            {
              WhyChooseheading: "5. Flexible Booking Options",
              WhyChoosedescription: "We offer easy online booking as well as options for last-minute reservations. Whether you're planning ahead or need a ride on short notice, we've got you covered."
            },
            {
              WhyChooseheading: "6. Punctuality",
              WhyChoosedescription: "We understand the importance of timeliness. Our drivers are committed to ensuring you arrive at your destination on time, every time."
            },
            {
              WhyChooseheading: "7. Comfort and Convenience",
              WhyChoosedescription: "Our cabs are well-maintained and equipped with modern amenities, ensuring a comfortable ride for all passengers. Enjoy amenities like air conditioning and ample luggage space."
            },
            {
              WhyChooseheading: "8. Customizable Packages",
              WhyChoosedescription: "We offer flexible packages tailored to your specific travel needs, whether it’s a one-way trip or a round trip, making it easier to plan your journey."
            }
          ]
    };    

    
   
    const faqData = [
        {
          question: "What types of vehicles are available for Pune to Goa cabs?",
          answer: "We offer a variety of vehicles, including sedans, SUVs, tempo travellers, and luxury cars like the Innova and Fortuner to accommodate different group sizes and preferences."
        },
        {
          question: "How do I book a cab from Pune to Goa?",
          answer: "You can book a cab through our user-friendly website, or by contacting our customer service team directly for assistance."
        },
        {
          question: "What is the fare for a Pune to Goa cab?",
          answer: "The fare varies based on the vehicle type, travel distance, and other factors. We provide detailed quotes upon request, ensuring you know the total cost upfront."
        },
        {
          question: "Are your drivers experienced?",
          answer: "Yes, all our drivers are professional, experienced, and trained to ensure your safety and comfort during the journey."
        },
        {
          question: "Can I modify my booking?",
          answer: "Yes, you can modify your booking by contacting our customer support team. We strive to accommodate your changes whenever possible."
        },
        {
          question: "Do you offer one-way trips to Goa?",
          answer: "Absolutely! We offer one-way trips from Pune to Goa, providing flexibility for your travel plans."
        },
        {
          question: "What amenities are included in the cabs?",
          answer: "Our cabs are equipped with air conditioning, comfortable seating, and sufficient luggage space. Additional amenities may be available depending on the vehicle type."
        },
        {
          question: "What payment methods do you accept?",
          answer: "We accept various payment methods, including credit/debit cards, UPI, and cash payments."
        },
        {
          question: "Is there a cancellation policy?",
          answer: "Yes, we have a cancellation policy in place. Please refer to our website or contact customer service for specific details."
        },
        {
          question: "What if I have more questions?",
          answer: "Feel free to reach out to our customer service team anytime. We are here to assist you with all your queries regarding our Pune to Goa cab services."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Anil Patil",
          text: "Our journey from Pune to Goa with SSKTravels24 was fantastic! We hired an Innova for our family trip, and the vehicle was spacious and comfortable. The driver was punctual, friendly, and knew all the best routes, making our trip enjoyable and hassle-free. Highly recommend SSKTravels24 for anyone planning a trip to Goa!"
        },
        {
          name: "Ms. Neha Sharma",
          text: "I recently booked a cab with SSKTravels24 for a group getaway to Goa, and I couldn't be happier with the service. The booking process was smooth, the cab was clean and well-maintained, and our driver was professional and accommodating. The whole experience exceeded our expectations, and we will definitely choose SSKTravels24 again for our future trips!"
        }
      ];
      
      const contactData = {
        heading: "Contact Us for Pune to Goa Cabs",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Goa Cabs | Affordable & Convenient</title>
  <meta name="description" content="Book reliable Pune to Goa cabs with SSK Travels24. Choose from various vehicles including Innova, Ertiga, and Urbania for a comfortable journey." />
  <meta name="keywords" content="Pune to Goa cabs, Pune to Goa car rental, Pune to Goa taxi, Pune to Goa cab fare, one way cab from Pune to Goa, taxi packages, tempo traveller hire" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Goa Cabs | Affordable & Convenient" />
  <meta property="og:description" content="Explore affordable Pune to Goa cab services with SSK Travels24. Various vehicle options available for your convenience." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-goa-cabs" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-to-goa-cabs-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-17.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PunetoGoaCabService;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import '@fortawesome/fontawesome-free/css/all.min.css';
import HeroSection from './components/HeroSection'; 
import Footer from './components/Footer';
import About from './pages/About';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import FAQSection from './pages/FAQSection';
import ServiceSection from './pages/Service';
import OurFleet from './pages/OurFleet';
import OurNetwork from './pages/OurNetwork';
import BookTaxi from './pages/BookTaxi';
import GallerySection from './pages/GallerySection';
import ContactSection from './pages/ContactSection';
import Enquiry from './pages/Enquiry';
import CareersSection from './pages/CareersSection';
import BlogSection from './pages/BlogSection';
import ContactButtons from './components/ContactButtons';
import Packages from './pages/Packages';
import Tempotraveeronrentinpune from './pages/Tempo Traveller On Rent in Pune';
import Punetomahabaleshwer from './pages/Pune to Mahabaleshwar Tempo Traveller';
import Punetooutstation from './pages/Pune to Outstation Tempo Traveller';
import Minibusonrent from './pages/Mini Bus On Rent in Pune';
import Seateronrent from './pages/14 Seater Tempo Traveller on Rent';
import Seateronrentt from './pages/17 Seater Bus on Rent Pune';
import Punetoshirditempotravellser from './pages/Pune to Shirdi Tempo Traveller';
import Seaterbusonrent from './pages/20 Seater Bus on Rent in Pune';
import Seatertempotravelleronrent from './pages/25 Seater Tempo Traveller on Rent in Pune';
import Tempotravelleronrent from './pages/Tempo Traveller on Rent in Pimpri Chinchwad';
import BusRentInPune32Seater from './pages/32 Seater Bus Rent in Pune';
import BusRentInPune35Seater from './pages/35-Seater Bus Rental in Pune';
import BusRentInPune45Seater from './pages/45-Seater Bus Rental in Pune';
import BusRentInPune50Seater from './pages/50-Seater Bus on Rent in Pune';
import PunetomahabaleshwerUrbaniaonrent from './pages/Pune to Mahabaleshwar Urbania On Rent';
import ForceUrbaniaonRentforAshtavinayakDarshan from './pages/Force Urbania on Rent for Ashtavinayak Darshan';
import TempoTravellerHireinPune from './pages/Tempo Traveller Hire in Pune';
import TravelAgentsinPuneforMiniBusHire from './pages/Travel Agents in Pune for Mini Bus Hire';
import BusRentalinPune14_17_20and_25_Seater from './pages/14, 17, 20, and 25-Seater Bus Rental in Pune';
import ForceUrbaniaLuxuryMiniBusonRent from './pages/Force Urbania Luxury Mini Bus on Rent';
import LuxuryForceUrbaniaonRentinPune from './pages/Luxury Force Urbania on Rent in Pune';
import LuxuryBusRentalinPune from './pages/Luxury Bus Rental in Pune';
import PremierNonACBusRentalServiceinPune from './pages/Premier Non-AC Bus Rental Service in Pune';
import Punetobhimashankar from './pages/Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune';
import Bushireforschool from './pages/Bus Hire for School Trips in Pune';
import PuneLocalbusonrent from './pages/Pune Local Bus on Rent';
import Busonrentinkharadipune from './pages/Bus On Rent in Kharadi Pune';
import Busonrentinhinjewadi from './pages/Bus on Rent in Hinjewadi';
import Busesforcorporateevents from './pages/Buses For Corporate Events in Pune';
import Busrentalforschooltripinpune from './pages/Bus Rentals For School Trips in Pune';
import Busbookingforweddinginpune from './pages/Bus booking for wedding in Pune';
import Busbookingformarrige from './pages/Bus Booking for Marriage function in Pune';
import Busserviceforcorporate from './pages/Bus Service for Corporate Pune';
import Busserviceforevents from './pages/Bus on Rent For Event in Pune';
import Busrentalfortourpackages from './pages/Bus Rental for Tour Packages in Pune';
import Bushireforoutstation from './pages/Bus Hire for Outstation in Pune';
import PuneBushirereantal from './pages/Pune Bus Hire Rental Service';
import Busserviceforpicnicinpune from './pages/Bus Service for Picnic in Pune';
import Luxurybusrentalinpune from './pages/Luxury Bus Rental in-Pune';
import Urbaniabusonrentinmumbai from './pages/Urbania Bus On Rent in Mumbai Airport';
import Busonrentkharadipune from './pages/Bus On Rent in Kharadi-Pune';
import Punetomumbaidarshan from './pages/Pune to Mumbai Darshan Bus On Rent';
import Busonrentinpimpri from './pages/Bus On Rent in Pimpri Chinchwad';
import Bushireschool from './pages/Bus-Hire for School Trips in Pune';
import Busonrentinpune from './pages/Bus On Rent in Pune';
import Bushirenearme from './pages/Bus Hire Near me Pune';
import Manyseaterbus from './pages/32,35,40,45,50 Seater Bus on Rent in Pune';
import Punetolonavala from './pages/Pune to Lonavala Bus Hire';
import Punesadeteenshaktipeeth from './pages/Pune Sade Teen Shakti Peeth Bus on Rent';
import Punetokonkan from './pages/Pune to-Konkan-Darshan-Bus-Hire';




import Punetogoabushire from './pages/Pune to Goa Bus Hire';
import Punetomumbaicab from './pages/Pune to Mumbai Cab Services';
import PunetoOutstationcab from './pages/Pune to Outstation Cab Services';
import Punetomumbaiinternationalairport from './pages/Pune to Mumbai International Airport Cab Service';
import OnlineTaxiBookingPune from './pages/Online Taxi Booking Pune';
import Ashtavinayakdarshancab from './pages/Ashtavinayak Darshan Cab Service';
import Punetomumbaicabtaxi from './pages/Pune to Mumbai Cab Taxi Service';
import PremierCorporateEventCabServiceinPune from './pages/Premier Corporate Event Cab Service in Pune';
import PunetoGoaCabService from './pages/Pune to Goa Cab Service';
import CarsonRentinPune7Seater from './pages/7-Seater Cars on Rent in Pune';
import AirportCabServicesinPune from './pages/Airport Cab Services in Pune';
import MumbaiAirporttoPuneTaxi from './pages/Mumbai Airport to Pune Taxi';
import MumbaiInternationalAirporttoPune from './pages/Mumbai International Airport to Pune';
import JyotirlingDarshanCabBookingwithSSKTravels24 from './pages/5 Jyotirling Darshan Cab Booking with SSKTravels24';
import AshtavinayakDarshanCabService from './pages/Ashtavinayak Darshan Cab Service85';
import OneWayCabinPune from './pages/One Way Cab in Pune ';
import YourTrustedPartnerforPunetoLonavalaCabBooking from './pages/Your Trusted Partner for Pune to Lonavala Cab Booking';
import TheBestCabServiceinPune from './pages/The Best Cab Service in Pune';
import LuxuryCarRentalinPune from './pages/Luxury Car Rental in Pune ';
import Punetomahabaleshwercab from './pages/Pune to Mahabaleshwar Cab';
import FortuneronRentinPune from './pages/Fortuner on Rent in Pune';
import InnovaCrystaonRentinPune from './pages/Innova Crysta on Rent in Pune';
import ErtigaCabBookinginPune from './pages/Ertiga Cab Booking in Pune';
import KiaCarensonRentinPune from './pages/Kia Carens on Rent in Pune';
import SwiftDzireonRentinPune from './pages/Swift Dzire on Rent in Pune';
import SedanCabsBookinginPune from './pages/Sedan Cabs Booking in Pune';
import ForceUrbaniaOnRentinPune from './pages/Force Urbania On Rent in Pune';
import PunetoMumbaiAirportUrbaniaBusOnRent from './pages/Pune to Mumbai Airport Urbania Bus On Rent ';
import PunetoOutstationUrbaniaBusOnRent from './pages/Pune to Outstation Urbania Bus On Rent';
import ScrollToTop from './components/ScrollToTop';


const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Header />
      <ContactButtons/>
      <Routes>
        <Route path="/" element={<HeroSection />} />
        <Route path="/about-us" element={<About/>} />
        <Route path="/Faqs" element={<FAQSection/>} />
        <Route path="/Term-&-Condition" element={<TermsConditions/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/Service" element={<ServiceSection/>} />
        <Route path="/our-fleet" element={<OurFleet/>} />
        <Route path="/our-network" element={<OurNetwork/>} />
        <Route path="/packages" element={<Packages/>} />
        <Route path="/book-taxi" element={<BookTaxi/>} />
        <Route path="/gallery" element={<GallerySection/>} />
        <Route path="/contact" element={<ContactSection/>} />
        <Route path="/enquiry" element={<Enquiry/>} />
        <Route path="/carrer" element={<CareersSection/>} />
        <Route path="/blog" element={<BlogSection/>} />
        <Route path="/Tempo-Traveller-On-Rent-in-Pune" element={<Tempotraveeronrentinpune/>} />
        <Route path="/Pune-to-Mahabaleshwar-Tempo-Traveller" element={<Punetomahabaleshwer/>} />
        <Route path="/Pune-to-Outstation-Tempo-Traveller" element={<Punetooutstation/>} />
        <Route path="/Mini-Bus-On-Rent-in-Pune" element={<Minibusonrent/>} />
        <Route path="/14-Seater-Tempo-Traveller-on-Rent" element={<Seateronrent/>} />
        <Route path="/17-Seater-Bus-on-Rent-Pune" element={<Seateronrentt/>} />
        <Route path="/Pune-to-Shirdi-Tempo-Traveller" element={<Punetoshirditempotravellser/>} />
        <Route path="/20-Seater-Bus-on-Rent-in-Pune" element={<Seaterbusonrent/>} />
        <Route path="/25-Seater-Tempo-Traveller-on-Rent-in-Pune" element={<Seatertempotravelleronrent/>} />
        <Route path="/Tempo-Traveller-on-Rent-in-Pimpri-Chinchwad" element={<Tempotravelleronrent/>} />

        <Route path="/32-Seater-Bus-Rent-in-Pune" element={<BusRentInPune32Seater/>} />
        <Route path="/35-Seater-Bus-Rent-in-Pune" element={<BusRentInPune35Seater/>} />
        <Route path="/45-Seater-Bus-Rent-in-Pune" element={<BusRentInPune45Seater/>} />
        <Route path="/50-Seater-Bus-Rent-in-Pune" element={<BusRentInPune50Seater/>} />
        <Route path="/Non-Ac-Bus-Rental-Service-in-Pune" element={<PremierNonACBusRentalServiceinPune/>} />
        <Route path="/Luxury-Bus-Rental-in-Pune" element={<LuxuryBusRentalinPune/>} />
        <Route path="/Luxury-Force-Urbania-On-Rent-in-Pune" element={<LuxuryForceUrbaniaonRentinPune/>} />
        <Route path="/Force-Urbania-Luxury-Mini-Bus-On-Rent" element={<ForceUrbaniaLuxuryMiniBusonRent/>} />
        <Route path="/Mini-Bus-On-Rent-in-Pune" element={<Minibusonrent/>} />
        <Route path="/14-17-20-25-Seater-Bus-on-in-Pune" element={<BusRentalinPune14_17_20and_25_Seater/>} />
        <Route path="/Travel-Agents-in-Pune-for-Mini-Bus-Hire" element={<TravelAgentsinPuneforMiniBusHire/>} />
        <Route path="/Tempo-Traveller-Hire-in-Pune" element={<TempoTravellerHireinPune/>} />
        <Route path="/Force-Urbania-On-Rent-for-Ashtavinayak-Darshan" element={<ForceUrbaniaonRentforAshtavinayakDarshan/>} />
        <Route path="/Pune-to-Mahabaleshwar-Urbania-On-Rent" element={<PunetomahabaleshwerUrbaniaonrent/>} />
        <Route path="/Pune-to-Bhimashankar-Jyotirlinga-Mini-Bus-Hire-in-Pune" element={<Punetobhimashankar/>} />
        <Route path="/Bus-Hire-for-School-Trips-in-Pune" element={<Bushireforschool/>} />
        <Route path="/Pune-Local-Bus-on-Rent" element={<PuneLocalbusonrent/>} />
        <Route path="/Bus-On-Rent-in-Kharadi-Pune" element={<Busonrentinkharadipune/>} />
        <Route path="/Bus-on-Rent-in-Hinjewadi" element={<Busonrentinhinjewadi/>} />
        <Route path="/Buses-For-Corporate-Events-in-Pune" element={<Busesforcorporateevents/>} />
        <Route path="/Bus-Rentals-For-School-Trips-in-Pune" element={<Busrentalforschooltripinpune/>} />
        <Route path="/Bus-booking-for-wedding-in-Pune" element={<Busbookingforweddinginpune/>} />
        <Route path="/Bus-Booking-for-Marriage-function-in-Pune" element={<Busbookingformarrige/>} />
        <Route path="/Bus-Service-for-Corporate-Pune" element={<Busserviceforcorporate/>} />
        <Route path="/Bus-on-Rent-For-Event-in-Pune" element={<Busserviceforevents/>} />
        <Route path="/Bus-Rental-for-Tour-Packages-in-Pune" element={<Busrentalfortourpackages/>} />
        <Route path="/Bus-Hire-for-Outstation-in-Pune" element={<Bushireforoutstation/>} />
        <Route path="/Pune-Bus-Hire-Rental-Service" element={<PuneBushirereantal/>} />
        <Route path="/Bus-Service-for-Picnic-in-Pune" element={<Busserviceforpicnicinpune/>} />
        <Route path="/Luxury-Bus-Rental-in-Pune" element={<Luxurybusrentalinpune/>} />
        <Route path="/Urbania-Bus-On-Rent-in-Mumbai-Airport" element={<Urbaniabusonrentinmumbai/>} />
        <Route path="/Bus-On-Rent-in-Kharadi-Pune" element={<Busonrentkharadipune/>} />
        <Route path="/Pune-to-Mumbai-Darshan-Bus-On-Rent" element={<Punetomumbaidarshan/>} />
        <Route path="/Bus-On-Rent-in-Pimpri-Chinchwad" element={<Busonrentinpimpri/>} />
        <Route path="/Bus-Hire-for-School-Trips-in-Pune" element={<Bushireschool/>} />
        <Route path="/Bus-On-Rent-in-Pune" element={<Busonrentinpune/>} />
        <Route path="/Bus-Hire-Near-me-Pune" element={<Bushirenearme/>} />
        <Route path="/32-35-40-45-50-Seater-Bus-on-Rent-in-Pune" element={<Manyseaterbus/>} />
        <Route path="/Pune-to-Lonavala-Bus-Hire" element={<Punetolonavala/>} />
        <Route path="/Pune-Sade-Teen-Shakti-Peeth-Bus-on-Rent" element={<Punesadeteenshaktipeeth/>} />
        <Route path="/Pune-to-Konkan-Darshan-Bus-Hire" element={<Punetokonkan/>} />








         <Route path="/Pune-To-Goa-Bus-Hire" element={<Punetogoabushire/>} />
        <Route path="/Pune-to-Mumbai-Cab" element={<Punetomumbaicab/>} />
        <Route path="/Pune-to-Outstation-Cab" element={<PunetoOutstationcab/>} />
        <Route path="/Pune-to-Mumbai-international-Airport-Cab" element={<Punetomumbaiinternationalairport/>} />
        <Route path="/Online-Cab-Booking-in-Pune" element={<OnlineTaxiBookingPune/>} />
        <Route path="/Ashtavinayak-Darshan-Cab" element={<Ashtavinayakdarshancab/>} />
        <Route path="/Pune-to-Mumbai-Cab-Taxi-Service" element={<Punetomumbaicabtaxi/>} />
        <Route path="/Corporate-Event-Cab-Service-in-Pune" element={<PremierCorporateEventCabServiceinPune/>} />
        <Route path="/Pune-to-Goa-Cabs" element={<PunetoGoaCabService/>} />
        <Route path="/7-Seater-Car-on-Rent-in-Pune" element={<CarsonRentinPune7Seater/>} />
        <Route path="/Online-Taxi-Booking-Pune" element={<OnlineTaxiBookingPune/>} />
        <Route path="/Airport-Cab-Services-in-Pune" element={<AirportCabServicesinPune/>} />
        <Route path="/Mumbai-Airport-to-Pune-Taxi" element={<MumbaiAirporttoPuneTaxi/>} />
        <Route path="/Mumbai-International-Airport-to-Pune-Cab" element={<MumbaiInternationalAirporttoPune/>} />
        <Route path="/5-Jyotirling-Darshan-Cab-Booking" element={<JyotirlingDarshanCabBookingwithSSKTravels24/>} />
        <Route path="/Ashtavinayak-Darshan-Cab" element={<Ashtavinayakdarshancab/>} />
        <Route path="/One-Way-Cab-in-Pune" element={<OneWayCabinPune/>} />
        <Route path="/Pune-to-Lonavala-Cab-Booking" element={<YourTrustedPartnerforPunetoLonavalaCabBooking/>} />
        <Route path="/Best-Cab-Service-in-Pune" element={<TheBestCabServiceinPune />} />
        <Route path="/Luxury-Car-Rental-in-Pune" element={<LuxuryCarRentalinPune />} />
        <Route path="/Pune-to-Mahabaleshwar-Cab" element={<Punetomahabaleshwercab />} />
        <Route path="/Fortuner-On-Rent-in-Pune" element={<FortuneronRentinPune />} />
        <Route path="/Innova-Crysta-On-Rent-In-Pune" element={<InnovaCrystaonRentinPune />} />
        <Route path="/Ertiga-Cab-Booking-in-Pune" element={<ErtigaCabBookinginPune />} />
        <Route path="/Kia-Carens-On-Rent-in-Pune" element={<KiaCarensonRentinPune />} />
        <Route path="/Swift-Dzire-On-Rent-in-Pune" element={<SwiftDzireonRentinPune />} />
        <Route path="/Sedan-Cabs-Booking-in-Pune" element={<SedanCabsBookinginPune />} />
        <Route path="/Force-Urbania-On-Rent-in-Pune" element={<ForceUrbaniaOnRentinPune />} />
        <Route path="/Pune-to-Mumbai-Airport-Urbania-Bus-On-Rent" element={<PunetoMumbaiAirportUrbaniaBusOnRent />} />
        <Route path="/Pune-to-Outstation-Urbania-Bus-On-Rent" element={<PunetoOutstationUrbaniaBusOnRent />} /> 

      </Routes>
      <Footer/>
    </Router>
  );
};

export default App;

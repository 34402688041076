
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function InnovaCrystaonRentinPune(){


    const cardData = 
    {
        keyword: 'Innova Crysta on Rent in Pune',
        heading: 'SSKTravels24: Innova Crysta on Rent in Pune',
        headingDescription: 'When it comes to comfortable and spacious travel, the Innova Crysta is a top choice for many travelers in Pune. At SSKTravels24, we offer premium Innova Crysta rentals that cater to both local and outstation journeys, ensuring a smooth and enjoyable ride. Here are the top reasons why renting an Innova Crysta from SSKTravels24 is the best decision for your travel needs.',
        
        topPlaces: [
            {
                title: "1. Spacious Interior",
                description: "The Innova Crysta is renowned for its spacious and comfortable interior, making it ideal for families, group outings, or corporate travel. With ample legroom and seating for up to seven passengers, you can travel without feeling cramped."
            },
            {
                title: "2. Luxury Features",
                description: "Equipped with modern amenities, the Innova Crysta offers a luxurious travel experience. Features like plush seating, climate control, and advanced infotainment systems ensure that your journey is both comfortable and enjoyable."
            },
            {
                title: "3. Safety First",
                description: "Safety is a priority at SSKTravels24. The Innova Crysta comes with multiple airbags, ABS, and stability control, ensuring that you and your passengers are well-protected during your travels."
            },
            {
                title: "4. Professional Drivers",
                description: "Our experienced drivers are well-trained and familiar with Pune and its surrounding areas. They ensure a smooth ride, follow traffic regulations, and provide excellent customer service, enhancing your overall travel experience."
            },
            {
                title: "5. Flexible Rental Options",
                description: "Whether you need the Innova Crysta for a few hours, a full day, or a longer duration, we offer flexible rental options to suit your schedule and travel needs."
            },
            {
                title: "6. Affordable Rates",
                description: "At SSKTravels24, we believe in providing high-quality service at competitive prices. Our Innova Crysta rental rates are transparent, with no hidden charges, making it easier to plan your budget."
            },
            {
                title: "7. Local and Outstation Travel",
                description: "The Innova Crysta is perfect for both local sightseeing in Pune and outstation trips to destinations like Lonavala, Mahabaleshwar, and even Goa. Enjoy the scenic routes in a comfortable vehicle."
            },
            {
                title: "8. Easy Booking Process",
                description: "Renting an Innova Crysta is hassle-free with our user-friendly online booking platform. You can easily check availability, select your preferred rental duration, and confirm your booking in just a few clicks."
            },
            {
                title: "9. Customer Support",
                description: "Our dedicated customer support team is available to assist you at any time. Whether you have questions about your booking or need assistance during your rental period, we're here to help."
            },
            {
                title: "10. Positive Customer Reviews",
                description: "SSKTravels24 has built a reputation for excellence in customer service. Our clients consistently praise us for our reliable vehicles, professional drivers, and overall service quality, making us a trusted choice for Innova Crysta rentals in Pune."
            }
        ],
        
        services: [
            {
                name: "Innova on Rent Pune",
                description: "Our Innova on rent service caters to all your travel needs, whether for leisure or business. With ample legroom and luggage space, it's perfect for group travel."
            },
            {
                name: "Innova Crysta Rental Pune",
                description: "We provide flexible rental options for Innova Crysta, ensuring you get the best rates for your journey. Our vehicles are well-maintained and regularly serviced for your safety and comfort."
            },
            {
                name: "Innova Car Rental in Pune",
                description: "Choose Innova car rental in Pune for a hassle-free booking experience. We offer transparent pricing with no hidden charges, making it easy to budget for your trip."
            },
            {
                name: "Innova Car Hire in Pune",
                description: "Need a ride for a special event or a business meeting? Our Innova car hire in Pune service is equipped with professional drivers to ensure a smooth and timely arrival."
            },
            {
                name: "Innova Car Rental Per Km in Pune",
                description: "Our Innova car rental per km service provides flexibility, allowing you to pay only for the distance traveled. This is especially beneficial for long-distance trips."
            },
            {
                name: "Pune to Mumbai Innova Rental",
                description: "Whether you're traveling to Mumbai for business or leisure, our Pune Mumbai Innova rental service ensures a comfortable journey with reliable drivers."
            },
            {
                name: "Mumbai to Pune Innova Crysta",
                description: "Our Mumbai to Pune Innova Crysta service is perfect for those looking to travel between the two cities in style and comfort."
            },
            {
                name: "Pune to Mumbai International Airport Innova Crysta",
                description: "Enjoy a stress-free ride to the airport with our dedicated Pune to Mumbai International Airport Innova Crysta service."
            },
            {
                name: "Pune to Outstation Innova Crysta Cab",
                description: "Planning a weekend getaway? Our Pune to outstation Innova Crysta cab service offers you the flexibility to explore nearby destinations."
            },
            {
                name: "Innova Crysta on Rent for Corporate in Pune",
                description: "Elevate your corporate travel with our Innova Crysta on rent for corporate in Pune service, designed to meet the needs of businesses."
            },
            {
                name: "Innova Crysta Taxi Service in Pune",
                description: "For reliable transportation within the city, our Innova Crysta taxi service in Pune is available around the clock."
            },
            {
                name: "Contact Number for Innova Crysta on Rent in Pune",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Innova Crysta on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        tableData: [
            ['Innova Crysta On Rent in Pune', 'Innova on Rent Pune'],
            ['Innova Crysta Rental Pune', 'Innova Car Rental Per Km in Pune'],
            ['Innova Car Rental in Pune', 'Innova Car Hire in Pune'],
            ['Innova Crysta On Rent Pune', 'Innova Crysta Rent in Pune'],
            ['Pune Mumbai Innova Rental', 'Mumbai to Pune Innova Crysta'],
            ['Pune to Mumbai International Airport Innova Crysta', 'Pune to Outstation Innova Crysta Cab'],
            ['Innova Crysta on Rent for Corporate in Pune', 'Innova Crysta Taxi Service in Pune'],
            ['SSK Travels24 in Pune', 'SSK Travels24 in Pune']
        ],
        whychoose: [
            {
                WhyChooseheading: "Why Choose Us for Innova Crysta on Rent in Pune?",
                WhyChoosedescription: "Choosing SSK Travels24 for your Innova Crysta rental in Pune ensures a seamless travel experience tailored to your needs. Our commitment to quality service, safety, and customer satisfaction sets us apart. Here are some reasons why you should choose us:"
            },
            {
                WhyChooseheading: "Well-Maintained Fleet:",
                WhyChoosedescription: "Our Innova Crysta vehicles are regularly serviced and maintained, ensuring safety and comfort for every journey."
            },
            {
                WhyChooseheading: "Flexible Rental Options:",
                WhyChoosedescription: "Whether you need the vehicle for a day, a weekend, or a longer duration, we offer flexible rental plans to accommodate your travel needs."
            },
            {
                WhyChooseheading: "Professional Drivers:",
                WhyChoosedescription: "Our experienced drivers are knowledgeable about the best routes and are dedicated to providing a safe and enjoyable ride."
            },
            {
                WhyChooseheading: "Transparent Pricing:",
                WhyChoosedescription: "With SSK Travels24, you get clear pricing with no hidden fees. We provide detailed quotes upfront, allowing you to budget accordingly."
            },
            {
                WhyChooseheading: "Easy Booking Process:",
                WhyChoosedescription: "Our user-friendly booking system allows you to reserve your Innova Crysta easily, either online or via phone."
            },
            {
                WhyChooseheading: "Customer Support:",
                WhyChoosedescription: "Our dedicated customer support team is available to assist you with any queries or concerns before, during, and after your rental experience."
            },
            {
                WhyChooseheading: "Comfort and Space:",
                WhyChoosedescription: "The Innova Crysta is designed for comfort, providing ample space for passengers and luggage, making it ideal for family trips or group travel."
            },
            {
                WhyChooseheading: "Special Packages:",
                WhyChoosedescription: "We offer special packages for corporate events, weddings, and long-distance trips, ensuring you get the best value for your money."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "How can I book an Innova Crysta on rent in Pune?",
            answer: "You can book an Innova Crysta easily through our website or by calling our customer service. Just provide your travel details, and we’ll assist you in making a reservation."
        },
        {
            question: "What are the rental rates for the Innova Crysta?",
            answer: "Our rental rates vary based on the duration of the rental, distance traveled, and any additional services you may require. Contact us for a detailed quote."
        },
        {
            question: "Are the drivers provided with the rental?",
            answer: "Yes, all our Innova Crysta rentals come with a professional driver who is familiar with the local routes and ensures a safe journey."
        },
        {
            question: "Can I rent the Innova Crysta for outstation travel?",
            answer: "Absolutely! Our Innova Crysta is perfect for outstation travel. Just inform us of your destination, and we’ll arrange everything."
        },
        {
            question: "Is there a limit on the number of passengers?",
            answer: "The Innova Crysta can comfortably accommodate up to 7 passengers, making it ideal for family trips or group outings."
        },
        {
            question: "What should I do in case of a breakdown?",
            answer: "In the unlikely event of a breakdown, our customer support team is available 24/7 to assist you and ensure your journey continues smoothly."
        },
        {
            question: "Do you offer pickup and drop services?",
            answer: "Yes, we offer pickup and drop services at various locations in Pune, including airports, hotels, and railway stations."
        },
        {
            question: "What documents do I need to provide for booking?",
            answer: "You will need to provide a valid ID proof and any other documents requested during the booking process."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rahul Singh",
            text: "I recently rented an Innova Crysta from SSK Travels24 for a family trip to Mahabaleshwar, and the experience was fantastic! The vehicle was in excellent condition, spacious, and very comfortable for our group of seven. Our driver was not only professional but also knowledgeable about the best routes and sightseeing spots. I highly recommend SSK Travels24 for anyone looking for a reliable car rental service in Pune!"
        },
        {
            name: "Miss Anjali Mehta",
            text: "SSK Travels24 provided us with an Innova Crysta for our corporate event, and it was the best decision we made. The car was well-maintained and perfect for our needs. The driver arrived on time and was very courteous throughout the journey. Their customer service was outstanding, making the booking process smooth and hassle-free. I will definitely use their services again in the future!"
        }
    ];
    
    const contactData = {
        heading: "Contact Us for Innova Crysta on Rent in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Innova Crysta On Rent in Pune | Car Rental Service</title>
  <meta name="description" content="Rent an Innova Crysta in Pune for comfortable travel. Perfect for corporate events, airport transfers, and outstation trips with SSK Travels24." />
  <meta name="keywords" content="Innova Crysta on rent Pune, Innova rental in Pune, Innova hire Pune, Innova Crysta cab service, Pune to Mumbai Innova Crysta" />
  <meta property="og:title" content="Innova Crysta On Rent in Pune | SSK Travels24" />
  <meta property="og:description" content="Explore Pune and beyond with our Innova Crysta rental services. Ideal for family trips, corporate needs, and airport transfers." />
  <meta property="og:url" content="https://https://ssktravels24.com//innova-crysta-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//innova-crysta-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-30.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default InnovaCrystaonRentinPune;
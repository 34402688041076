
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';



function LuxuryBusRentalinPune(){


    const cardData = 
    {
      keyword: 'Luxury Bus Rental in Pune',
      heading: 'SSKTravels24: Luxury Bus Rental in Pune',
      headingDescription: 'Planning group travel in Pune? SSKTravels24 offers a luxurious fleet of buses for corporate events, family gatherings, weddings, and more. Explore the top features and services for luxury bus rentals tailored to your needs.',
      
      topPlaces: [
        {
          title: "1. Generous Seating Capacity",
          description: "Our luxury buses feature spacious seating designed for comfort, making long journeys relaxing and enjoyable."
        },
        {
          title: "2. Climate-Controlled Comfort",
          description: "Fully air-conditioned buses provide a pleasant environment regardless of the weather."
        },
        {
          title: "3. Adjustable Reclining Seats",
          description: "Reclining seats allow passengers to adjust for maximum comfort during the journey."
        },
        {
          title: "4. Advanced Entertainment Systems",
          description: "Our buses come equipped with LED TVs and premium sound systems to keep passengers entertained."
        },
        {
          title: "5. Ample Luggage Space",
          description: "Spacious compartments and overhead storage ensure that luggage is securely stored during travel."
        },
        {
          title: "6. Top-Notch Safety Features",
          description: "Modern safety features like seat belts, emergency exits, fire extinguishers, and GPS tracking ensure secure journeys."
        },
        {
          title: "7. Skilled and Professional Drivers",
          description: "Our experienced drivers are familiar with local routes and prioritize safety and punctuality for a smooth travel experience."
        },
        {
          title: "8. Impeccably Maintained Vehicles",
          description: "All buses undergo regular servicing and inspections, ensuring cleanliness and optimal performance."
        },
        {
          title: "9. Competitive and Transparent Pricing",
          description: "We offer competitive pricing with no hidden fees, providing great value for your luxury bus rental."
        },
        {
          title: "10. Tailored Travel Solutions",
          description: "Our services are customizable to meet specific needs, whether for weddings, corporate events, or other group travel requirements."
        }
      ],
    
      services: [
        {
          name: "Luxury Bus Rental in Pimpri Chinchwad",
          description: "Ideal for corporate functions and family events, our premium buses offer smooth and stylish group travel in Pimpri Chinchwad."
        },
        {
          name: "Luxury Bus Rental in Kharadi",
          description: "Enjoy a comfortable and elegant travel experience for corporate meetings or family celebrations with our luxury buses in Kharadi."
        },
        {
          name: "Luxury Bus On Rent in Yerawada",
          description: "Our luxury buses in Yerawada provide a premium travel experience, equipped with modern amenities for all group outings."
        },
        {
          name: "Luxury Bus On Rent in Vishrantwadi",
          description: "Perfect for weddings or corporate events, our buses in Vishrantwadi ensure comfort and sophistication."
        },
        {
          name: "14, 17, 20, 25 Seater Luxury Bus on Rent in Pune",
          description: "Our buses with seating capacities of 14, 17, 20, and 25 are perfect for smaller groups looking for comfort and style."
        },
        {
          name: "32, 35, 40, 45 Seater Luxury Bus Hire in Pimpri Chinchwad",
          description: "For larger groups, we offer buses with up to 45 seats, ideal for corporate outings, school trips, and family reunions."
        },
        {
          name: "Luxury Bus On Rent in Hadapsar Pune",
          description: "In Hadapsar, we offer luxurious buses for weddings, corporate events, and special occasions."
        },
        {
          name: "Luxury Bus on Rent for Picnic in Pune",
          description: "Enhance your picnic experience with our luxury bus rentals, equipped with all the amenities needed for a comfortable outing."
        },
        {
          name: "Mini Luxury Bus Hire in Pune",
          description: "Our mini luxury buses provide an elegant and practical solution for short trips or local travel with smaller groups."
        },
        {
          name: "Contact Number for Luxury Bus Rental in Pune",
          description: "For prompt and efficient luxury bus rental in Pune, contact SSKTravels24 at +91 8956170493."
        }
      ],
      tableData: [
        ['- Luxury Bus Rental in Pimpri Chinchwad', '- Luxury Bus Rental in Kharadi'],
        ['- Luxury Bus On Rent in Yerawada, Pune', '- Luxury Bus On Rent in Vishrantwadi, Pune'],
        ['- 14, 17, 20, 25 Seater Luxury Bus On Rent Pune', '- 32, 35, 40, 45 Seater Luxury Bus Hire in Pimpri Chinchwad'],
        ['- Luxury Bus On Rent in Hadapsar Pune', '- Luxury Bus on Rent for Picnic in Pune'],
        ['- Mini Luxury Bus Hire in Pune', '']
    ],
    
    "whychoose": [
        {
            "WhyChooseheading": "Why Choose SSKTravels24 for Luxury Bus Rental in Pune?",
            "WhyChoosedescription": "At SSKTravels24, we provide luxurious travel experiences that prioritize comfort, safety, and reliability. Here’s why we stand out in the luxury bus rental industry:"
        },
        {
            "WhyChooseheading": "Spacious and Comfortable Buses:",
            "WhyChoosedescription": "Our luxury buses feature plush seating, generous legroom, and efficient air-conditioning, ensuring a delightful journey for all passengers."
        },
        {
            "WhyChooseheading": "Professional and Experienced Drivers:",
            "WhyChoosedescription": "Our well-trained drivers are adept at handling diverse road conditions and are dedicated to ensuring a safe and enjoyable travel experience."
        },
        {
            "WhyChooseheading": "Well-Maintained Fleet:",
            "WhyChoosedescription": "We take pride in maintaining our buses through regular servicing and thorough inspections, ensuring a smooth and reliable travel experience."
        },
        {
            "WhyChooseheading": "Affordable Pricing:",
            "WhyChoosedescription": "Our competitive rates offer exceptional value without compromising on quality, with a clear pricing structure and no hidden charges."
        },
        {
            "WhyChooseheading": "Customizable Travel Solutions:",
            "WhyChoosedescription": "We provide flexible travel options tailored to your unique requirements, whether for corporate events, family gatherings, or school trips."
        },
        {
            "WhyChooseheading": "Excellent Customer Service:",
            "WhyChoosedescription": "From booking to the end of your journey, our customer service team is committed to providing prompt and effective support for a hassle-free experience."
        },
        {
            "WhyChooseheading": "Punctual and Reliable:",
            "WhyChoosedescription": "We adhere to strict schedules for timely departures and arrivals, helping you stay on track with your travel plans."
        }
    ]
    };    

    
    const faqData = [
      {
          question: "What is included in the rental of a luxury bus?",
          answer: "The rental includes comfortable seating, air-conditioning, and professional driver services. Additional amenities like audio systems and Wi-Fi may be available upon request."
      },
      {
          question: "How do I book a luxury bus with SSKTravels24?",
          answer: "You can easily book a luxury bus by contacting us via phone, email, or by filling out our online booking form with details such as your travel dates, pick-up and drop-off locations, and any special requirements."
      },
      {
          question: "Are there any additional charges I should be aware of?",
          answer: "While our pricing is transparent, extra charges may apply for additional services or specific requests. We will provide a detailed quote before finalizing your booking."
      },
      {
          question: "What is the cancellation policy for a luxury bus rental?",
          answer: "Our cancellation policy varies based on timing. Generally, cancellations made well in advance incur a nominal fee, while last-minute cancellations may involve higher charges. Please reach out to our customer service for specific details."
      },
      {
          question: "Can I request a specific type of luxury bus?",
          answer: "Yes, you can request specific features or bus types based on availability. We offer a diverse range of options to meet different needs."
      },
      {
          question: "Are the drivers experienced and licensed?",
          answer: "Absolutely! All our drivers are professionally trained, experienced, and hold valid licenses. They are well-acquainted with local routes and dedicated to providing a safe and comfortable travel experience."
      },
      {
          question: "Can I make changes to my booking after it is confirmed?",
          answer: "Yes, modifications to your booking can be made subject to availability and applicable fees. Please contact our customer service team promptly to discuss any changes."
      },
      {
          question: "What should I do if I need assistance during my journey?",
          answer: "If you require any assistance during your trip, please reach out to the driver or our customer service team immediately. We are committed to addressing any concerns promptly."
      },
      {
          question: "How far in advance should I book a luxury bus?",
          answer: "To secure your preferred bus and ensure availability, we recommend booking as early as possible, especially during peak seasons or for special events."
      },
      {
          question: "Are there any restrictions on luggage or other items?",
          answer: "Standard luggage allowances apply, but we can make special arrangements upon request. Please inform us of any specific needs at the time of booking."
      }
  ];
  
  const testimonials = [
      {
          name: "Mr. Aarav Joshi",
          text: "Our organization recently hired a luxury bus from SSKTravels24 for a corporate retreat, and it truly surpassed all our expectations. The bus was exceptional, featuring luxurious seating, modern amenities, and a pristine interior. The onboard entertainment system kept everyone engaged throughout the ride, and the spacious layout made the journey incredibly comfortable. Our driver was professional and ensured we reached our destination promptly. SSKTravels24 delivered on their promise of luxury and comfort. I highly recommend their luxury bus rental services for any upscale corporate or personal events."
      },
      {
          name: "Miss Kavita Sharma",
          text: "I booked a luxury bus from SSKTravels24 for my wedding transportation, and it was a fantastic experience. The bus was beautifully decorated with high-quality seating, making it perfect for our special occasion. The attention to detail was outstanding, from the cleanliness to the amenities offered. Our guests were thoroughly impressed with the luxurious comfort of the ride. The service from SSKTravels24 was exceptional, from the booking process to the actual event. They ensured everything went off without a hitch, and I couldn't have asked for a better experience. I highly recommend SSKTravels24 for anyone seeking luxury bus rentals in Pune."
      }
  ];
  
  const contactData = {
      heading: "Luxury Bus Rental in Pune",
      contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
      ],
      email: "booking@SSKtravels24.com"
  };
  
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
          <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Luxury Bus Rental in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent luxury buses in Pune for corporate events, picnics, and more. Competitive rates for various seating capacities. Book your luxury bus today!" />
  <meta name="keywords" content="Luxury bus rental in Pune | luxury bus rental in Pimpri Chinchwad | luxury bus rental in Kharadi | luxury bus on rent in Yerawada Pune | luxury bus on rent in Vishrantwadi Pune | 14 17 20 25 seater luxury bus on rent Pune | 32 35 40 45 seater luxury bus hire in Pimpri Chinchwad | luxury bus on rent in Hadapsar Pune | luxury bus on rent for picnic in Pune | mini luxury bus hire in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Luxury Bus Rental in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best luxury bus rental services in Pune. Enjoy competitive rates for picnics, corporate events, and more. Contact us to book your bus!" />
  <meta property="og:url" content="https://https://ssktravels24.com//luxury-bus-rental-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//luxury-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-16.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default LuxuryBusRentalinPune;
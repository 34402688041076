
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetobhimashankar(){


    const cardData = 
    {
        keyword: 'Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune',
        heading: 'SSKTravels24: Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune',
        headingDescription: 'When planning a pilgrimage to Bhimashankar Jyotirlinga from Pune, selecting the right transportation is crucial for a smooth journey. At SSKTravels24, we provide reliable and comfortable mini bus hire services, ensuring your trip to this revered destination is both enjoyable and hassle-free. Here’s why our mini bus hire service is the ideal choice for your pilgrimage:',
        
        topPlaces: [
            {
                title: "1. Bhimashankar Temple",
                description: "One of the twelve Jyotirlingas dedicated to Lord Shiva, celebrated for its spiritual ambiance and historical significance."
            },
            {
                title: "2. Nagphani Peak (Horn Peak)",
                description: "Offers breathtaking views of the surrounding landscape and is a favorite among trekkers and photography enthusiasts."
            },
            {
                title: "3. Bhimashankar Wildlife Sanctuary",
                description: "A rich natural reserve home to diverse flora and fauna, making it a paradise for nature lovers and wildlife watchers."
            },
            {
                title: "4. Hanuman Lake",
                description: "Provides a serene environment perfect for relaxation and meditation amid stunning natural surroundings."
            },
            {
                title: "5. Bhimashankar Hill Station",
                description: "A picturesque area offering refreshing cool weather and beautiful landscapes, ideal for escaping city life."
            },
            {
                title: "6. Gupt Bhimashankar",
                description: "An ancient cave temple dedicated to Lord Shiva, steeped in history and tranquility, significant for pilgrims."
            },
            {
                title: "7. Shidi Ghat",
                description: "Known for its stunning vistas, ideal for soaking in panoramic views of the surrounding hills and valleys."
            },
            {
                title: "8. Tamhini Ghat",
                description: "A breathtaking spot featuring lush greenery and waterfalls, perfect for nature lovers seeking a scenic drive."
            },
            {
                title: "9. Ghatghar Dam",
                description: "A lovely site for picnics, providing beautiful views of water and hills for a relaxing break."
            },
            {
                title: "10. Khandas Village",
                description: "Known for its picturesque beauty, serving as a great starting point for treks to nearby scenic locations and waterfalls."
            }
        ],        
        
        services: [
            {
                name: "Pune to Bhimashankar Tempo Traveller",
                description: "Our Tempo Traveller services are tailored for a comfortable journey to Bhimashankar, equipped with modern amenities for a smooth ride."
            },
            {
                name: "Pune to Bhimashankar 17-Seater Mini Bus on Rent",
                description: "Perfect for medium-sized groups, our 17-seater mini buses offer spacious and comfortable seating, ideal for group travels."
            },
            {
                name: "Pune to Bhimashankar Mini Bus on Rent",
                description: "Designed for reliability and comfort, our mini buses are available in various seating capacities to suit your group's needs."
            },
            {
                name: "Pune to Jyotirlinga Bhimashankar Mini Bus on Rent",
                description: "Experience a comfortable pilgrimage with our mini bus rental services, ensuring a memorable journey to this sacred site."
            },
            {
                name: "14-Seater AC Tempo Traveller on Rent in Pune",
                description: "For smaller groups, our 14-seater AC Tempo Travellers provide a luxurious travel experience with air conditioning and comfortable seating."
            },
            {
                name: "Pune to Bhimashankar Round Trip Bus on Rent",
                description: "Our round-trip bus rental services simplify your visit, ensuring a convenient journey without extra arrangements."
            },
            {
                name: "Pune to 5 Jyotirlinga Darshan Mini Bus on Rent",
                description: "Embark on a spiritual journey with our mini bus rentals for a tour of the five Jyotirlingas, starting from Pune."
            },
            {
                name: "Pune to Bhimashankar AC Mini Bus on Rent",
                description: "Travel in comfort with our air-conditioned mini buses, ensuring a cool and pleasant journey even during warm months."
            },
            {
                name: "Pune to Bhimashankar Luxury Bus Rentals",
                description: "Travel in style with our luxury bus rentals, equipped with premium amenities for an unforgettable experience."
            },
            {
                name: "Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune Contact Number:",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient 35 Seater Bus Rent in Pune . We ensure a smooth and enjoyable ride for all our customers."
            }
            
        ],        
        tableData: [
            ['- Pune to Bhimashankar Jyotirlinga Tempo Traveller', '- Pune to Bhimashankar 17 Seater Mini Bus on Rent'],
            ['- Pune to Bhimashankar Mini Bus on Rent', '- Pune to Jyotirlinga Bhimashankar Mini Bus on Rent'],
            ['- 14 Seater AC Tempo Traveller On Rent in Pune', '- Pune to Bhimashankar Round Trip Bus on Rent'],
            ['- Pune to 5 Jyotirlinga Darshan Mini Bus On Rent', '- Pune to Bhimashankar AC Mini Bus On Rent'],
            ['- Best Bus Hire Pune to Bhimashankar', '- Pune to Bhimashankar Bus Tour Package']
        ],        
    
        whychoose: [
            {
                "WhyChooseheading": "Why Choose Us: Pune to Bhimashankar Jyotirlinga Mini Bus Hire – SSKTravels24",
                "WhyChoosedescription": "Comfortable and Well-Maintained Fleet: Our mini buses feature spacious seating, ample legroom, and air-conditioning. Each vehicle undergoes regular maintenance to ensure optimal condition for your trip."
            },
            {
                "WhyChooseheading": "Experienced and Professional Drivers:",
                "WhyChoosedescription": "Our highly skilled drivers are familiar with the route to Bhimashankar, committed to providing a safe and smooth journey, allowing you to focus on your pilgrimage."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "Enjoy transparent and competitive pricing for our mini bus hire services, offering great value with no hidden fees for a cost-effective travel solution."
            },
            {
                "WhyChooseheading": "Customizable Travel Plans:",
                "WhyChoosedescription": "We recognize that every trip is unique. Our services can be tailored to fit your specific requirements, whether it’s a round trip, additional stops, or special requests."
            },
            {
                "WhyChooseheading": "Reliable and Punctual Service:",
                "WhyChoosedescription": "Punctuality is our priority. We guarantee that your mini bus arrives on time, adhering to the schedule so you can maintain your planned itinerary without delays."
            },
            {
                "WhyChooseheading": "Hassle-Free Booking Process:",
                "WhyChoosedescription": "Booking with us is straightforward. Make reservations via our website, by phone, or through email. Our customer support team is always available to assist with inquiries."
            },
            {
                "WhyChooseheading": "Comprehensive Support:",
                "WhyChoosedescription": "From booking to your journey's end, our customer support team is dedicated to providing exceptional assistance and promptly addressing any concerns."
            },
            {
                "WhyChooseheading": "Clean and Hygienic Vehicles:",
                "WhyChoosedescription": "Cleanliness and hygiene are our top priorities. Regular cleaning and sanitation of our vehicles ensure a safe and pleasant environment for all passengers."
            },
            {
                "WhyChooseheading": "Flexibility and Convenience:",
                "WhyChoosedescription": "Our mini bus services offer maximum flexibility. Whether you're traveling with a small group or a larger family, we can accommodate your needs."
            },
            {
                "WhyChooseheading": "Positive Customer Feedback:",
                "WhyChoosedescription": "We pride ourselves on a track record of satisfied customers who have praised our reliable service and comfortable travel experience, reflecting our commitment to excellence."
            }
        ]
        
    };    

    
   
    const faqData = [
        {
            question: "What is included in the mini bus hire for a trip to Bhimashankar?",
            answer: "Our mini bus hire includes comfortable seating, air-conditioning, and professional driver services. Additional amenities, such as Wi-Fi or audio systems, may be available upon request."
        },
        {
            question: "How do I book a mini bus for a trip to Bhimashankar?",
            answer: "You can book a mini bus by contacting us via phone, email, or through our online booking form. Please provide details such as travel dates, group size, and any special requirements."
        },
        {
            question: "Are there any extra charges for mini bus hire?",
            answer: "Our pricing is transparent with no hidden fees. However, extra charges may apply for extended travel times, additional services, or special requests. A detailed quote will be provided before finalizing your booking."
        },
        {
            question: "Can I request a specific type of mini bus?",
            answer: "Yes, you can request specific features or types of mini buses based on availability. We offer various options to meet different needs, including luxury and standard models."
        },
        {
            question: "What is the cancellation policy for mini bus hire?",
            answer: "Our cancellation policy varies based on the timing of the cancellation. Generally, cancellations made well in advance incur lower fees, while last-minute cancellations may involve higher charges."
        },
        {
            question: "Are the drivers trained and licensed?",
            answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are knowledgeable about the route to Bhimashankar and committed to ensuring a safe journey."
        },
        {
            question: "Can I make changes to my booking after it’s confirmed?",
            answer: "Yes, changes can be made subject to availability and applicable fees. Contact our customer service team as soon as possible to discuss any modifications to your booking."
        },
        {
            question: "What should I do if I need assistance during the journey?",
            answer: "If you need assistance during your trip, please contact the driver or our customer service team immediately. We are committed to addressing any concerns promptly."
        },
        {
            question: "How far in advance should I book a mini bus for Bhimashankar?",
            answer: "To secure your preferred vehicle and ensure availability, we recommend booking as early as possible. Early booking is advisable for peak seasons."
        },
        {
            question: "Are there any restrictions on luggage?",
            answer: "Standard luggage allowances apply, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when booking."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Sanjay Patel",
            text: "I recently hired a mini bus from SSKTravels24 for our pilgrimage to Bhimashankar Jyotirlinga from Pune, and the experience was exceptional. The bus was clean, comfortable, and well-equipped with air-conditioning, which made our long journey pleasant despite the weather. Our driver was courteous and knowledgeable, ensuring we arrived on time and safely. The entire process from booking to the trip was smooth, and SSKTravels24 provided excellent service throughout. I highly recommend them for anyone planning a spiritual or leisure trip to Bhimashankar."
        },
        {
            name: "Ms. Anjali Deshmukh",
            text: "Our trip to Bhimashankar Jyotirlinga was greatly enhanced by choosing SSKTravels24 for our mini bus hire. The vehicle was spacious and well-maintained, offering a comfortable ride for our group of devotees. The driver was not only professional but also considerate, making sure our journey was smooth and enjoyable. The onboard amenities were just what we needed to relax during the trip. If you're looking for reliable and comfortable transportation for a pilgrimage or any group travel, SSKTravels24 is the way to go."
        }
    ];
    
    
    const contactData = {
        heading: "Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pun Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Bhimashankar Jyotirlinga Mini Bus Hire | Affordable Rental Services</title>
  <meta name="description" content="Hire a mini bus for your trip from Pune to Bhimashankar Jyotirlinga. We offer 14-seater AC Tempo Travellers and round trip packages!" />
  <meta name="keywords" content="Pune to Bhimashankar Jyotirlinga Tempo Traveller | Pune to Bhimashankar 17 Seater Mini Bus on Rent | Pune to Bhimashankar Mini Bus on Rent | Pune to Jyotirlinga Bhimashankar Mini Bus on Rent | 14 Seater AC Tempo Traveller on Rent | Pune to Bhimashankar Round Trip Bus on Rent | Pune to 5 Jyotirlinga Darshan Mini Bus on Rent | Pune to Bhimashankar AC Mini Bus on Rent | Best Bus Hire Pune to Bhimashankar | Pune to Bhimashankar Bus Tour Package" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Bhimashankar Jyotirlinga Mini Bus Hire | Affordable Rental Services" />
  <meta property="og:description" content="Experience the best travel with our mini bus hire for trips to Bhimashankar Jyotirlinga. Book your journey today!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-bhimashankar-mini-bus-hire" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//bhimashankar-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-24.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>Frequently Asked Questions (FAQs) About Pune to Bhimashankar Jyotirlinga Mini Bus Hire</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetobhimashankar;
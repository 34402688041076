
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function PunetoMumbaiAirportUrbaniaBusOnRent(){


    const cardData = 
    {
        keyword: 'Pune to Mumbai Airport Urbania Bus On Rent',
        heading: 'SSKTravels24: Pune to Mumbai Airport Urbania Bus On Rent',
        headingDescription: 'Traveling from Pune to Mumbai Airport can be a breeze when you choose SSKTravels24’s Urbania bus rental service. Known for its comfort and spaciousness, the Urbania bus is perfect for group travel, ensuring that you arrive at the airport relaxed and ready for your journey. With our experienced drivers and top-notch service, we make your travel experience hassle-free.',
    
        topPlaces: [
            {
                title: "1. Gateway of India",
                description: "This iconic archway is a must-visit landmark, offering stunning views of the Arabian Sea. It’s the perfect spot to start your Mumbai adventure."
            },
            {
                title: "2. Marine Drive",
                description: "Known as the 'Queen’s Necklace,' Marine Drive is a picturesque promenade where you can enjoy a leisurely stroll while soaking in the sea breeze and breathtaking views."
            },
            {
                title: "3. Chhatrapati Shivaji Maharaj Terminus",
                description: "A UNESCO World Heritage Site, this historic railway station is a masterpiece of Victorian Gothic architecture and a hub of Mumbai's railway network."
            },
            {
                title: "4. Elephanta Caves",
                description: "Located on Elephanta Island, these ancient rock-cut caves house impressive sculptures and carvings dedicated to Lord Shiva, offering a glimpse into India's rich history."
            },
            {
                title: "5. Juhu Beach",
                description: "Famous for its vibrant atmosphere, Juhu Beach is ideal for a relaxing evening. Enjoy local snacks from beach vendors while watching the sunset."
            },
            {
                title: "6. Siddhivinayak Temple",
                description: "This revered temple is dedicated to Lord Ganesha and attracts devotees from all over the country. Its architecture and spiritual ambiance make it a peaceful retreat."
            },
            {
                title: "7. Colaba Causeway",
                description: "A bustling shopping street, Colaba Causeway offers a variety of boutiques, street vendors, and restaurants, perfect for those looking to indulge in some retail therapy."
            },
            {
                title: "8. Haji Ali Dargah",
                description: "Situated on an islet off the coast of Worli, this stunning mosque and dargah is a pilgrimage site for many and offers a unique blend of Islamic and Indian architecture."
            },
            {
                title: "9. Chhatrapati Shivaji Maharaj Vastu Sangrahalaya",
                description: "Formerly known as the Prince of Wales Museum, this museum houses an impressive collection of art, artifacts, and natural history."
            },
            {
                title: "10. Bandra-Worli Sea Link",
                description: "An engineering marvel, this cable-stayed bridge connects Bandra and Worli, offering spectacular views of the Mumbai skyline and the sea."
            }
        ],
    
        services: [
            {
                name: "Urbania Luxury Bus Rental in Pune",
                description: "Experience the epitome of comfort with our Urbania luxury bus rentals in Pune. Perfect for weddings, corporate events, or group outings, our buses are equipped with modern amenities."
            },
            {
                name: "Pune to Mumbai Darshan Urbania Bus on Rent",
                description: "Explore Mumbai’s iconic sights with our Pune to Mumbai Darshan Urbania bus service. Enjoy a guided tour in comfort as you visit the city’s landmarks and attractions."
            },
            {
                name: "Pune to Alibaug Urbania Bus on Rent",
                description: "Planning a trip to Alibaug? Our Urbania bus is the ideal choice for group travel. Enjoy the scenic ride to this popular beach destination in style."
            },
            {
                name: "Pune to Adlabs Imagica Urbania Bus on Rent",
                description: "Make your visit to Adlabs Imagica unforgettable with our Urbania bus rental. Perfect for families and friends, enjoy a fun-filled day at the amusement park without the hassle of transport."
            },
            {
                name: "Pune to Nashik Urbania Bus on Rent",
                description: "Traveling to Nashik? Our Urbania bus rental is perfect for vineyard tours and exploring the city’s rich culture. Enjoy spacious seating and a smooth ride."
            },
            {
                name: "Contact Number for Pune to Mumbai Airport Urbania Bus On Rent",
                description: "For prompt and efficient Pune to Mumbai Airport Urbania Bus On Rent, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['Pune to Mumbai Airport Urbania for Hire', 'Urbania Luxury Bus Rental in Pune'],
            ['Pune to Mumbai Darshan Urbania Bus on Rent', 'Pune to Alibaug Urbania Bus on Rent'],
            ['Pune to Adlabs Imagica Urbania Bus on Rent', 'Pune to Nashik Urbania Bus on Rent'],
            ['Pune to Shirdi Urbania Bus on Rent', 'Pune to Mahabaleshwar Urbania Bus on Rent'],
            ['Pune to Ajanta Ellora Tour Package', 'Pune to Goa Urbania Bus on Rent'],
            ['Pune to Lavasa Urbania Bus on Rent', 'Pune to Kolhapur Urbania Bus on Rent'],
            ['Pune to Navi Mumbai Urbania Bus on Rent', 'Pune to Bhimashankar Urbania Bus on Rent'],
            ['Pune to 5 Jyotirlinga Darshan Urbania on Rent', 'Pune to Konkan Darshan Urbania on Rent'],
            ['Mumbai Airport to Pune Urbania on Rent', 'Pune Airport Urbania Bus on Rent'],
            ['Force Urbania Luxury Mini Bus On Rent', '']
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose Us for Pune to Mumbai Airport Urbania Bus On Rent?",
                "WhyChoosedescription": "When it comes to traveling from Pune to Mumbai Airport, SSKTravels24 offers unmatched service and reliability. Here are some reasons to choose us:"
            },
            {
                "WhyChooseheading": "Comfort and Space:",
                "WhyChoosedescription": "Our Urbania buses are designed for maximum comfort, offering spacious seating and ample legroom for a relaxed journey."
            },
            {
                "WhyChooseheading": "Luxury Amenities:",
                "WhyChoosedescription": "Enjoy modern facilities such as air conditioning, entertainment systems, and plush interiors, ensuring a premium travel experience."
            },
            {
                "WhyChooseheading": "Experienced Drivers:",
                "WhyChoosedescription": "Our professional drivers are well-trained and knowledgeable about the best routes, ensuring you reach your destination safely and on time."
            },
            {
                "WhyChooseheading": "Affordable Rates:",
                "WhyChoosedescription": "We provide competitive pricing for our Urbania rentals, giving you great value without compromising on quality."
            },
            {
                "WhyChooseheading": "Flexible Booking Options:",
                "WhyChoosedescription": "Whether you need a one-way transfer or round-trip service, we offer customizable packages to suit your needs."
            },
            {
                "WhyChooseheading": "Punctual Service:",
                "WhyChoosedescription": "At SSKTravels24, we value your time. Our commitment to punctuality ensures you never miss your flight."
            },
            {
                "WhyChooseheading": "Customer Support:",
                "WhyChoosedescription": "Our dedicated support team is available 24/7 to assist you with bookings and any queries you may have."
            },
            {
                "WhyChooseheading": "Safety First:",
                "WhyChoosedescription": "We prioritize your safety with well-maintained vehicles and adherence to all safety protocols."
            },
            {
                "WhyChooseheading": "Group Travel Friendly:",
                "WhyChoosedescription": "Perfect for families, corporate groups, or friends traveling together, our Urbania buses can comfortably accommodate large parties."
            },
            {
                "WhyChooseheading": "Local Expertise:",
                "WhyChoosedescription": "With years of experience in the region, we offer insights and tips for a smoother travel experience."
            }
        ]
    };    

    
    const faqData = [
        {
            question: "What is the capacity of the Urbania bus?",
            answer: "Our Urbania buses can comfortably seat up to 20 passengers, making them ideal for group travel."
        },
        {
            question: "How do I book a bus for the Pune to Mumbai Airport route?",
            answer: "Booking is easy! You can contact us via our website or phone, and our customer service team will assist you in securing your reservation."
        },
        {
            question: "Are there any additional charges?",
            answer: "We provide transparent pricing with no hidden fees. The fare may vary based on distance and additional services requested."
        },
        {
            question: "Can I make changes to my booking?",
            answer: "Yes, we offer flexible booking options. Please contact our customer service team to make any changes."
        },
        {
            question: "What amenities are included in the Urbania bus?",
            answer: "Our Urbania buses come equipped with air conditioning, comfortable seating, and entertainment systems to enhance your travel experience."
        },
        {
            question: "Is there a cancellation policy?",
            answer: "Yes, we have a cancellation policy in place. Please check with our customer service team for detailed information."
        },
        {
            question: "What safety measures do you have in place?",
            answer: "We adhere to strict safety protocols, including regular vehicle maintenance, trained drivers, and sanitation measures."
        },
        {
            question: "Do you provide pickup and drop-off services?",
            answer: "Yes, we can arrange for pickup and drop-off at your specified locations in Pune and Mumbai Airport."
        },
        {
            question: "Can I hire the Urbania bus for other destinations?",
            answer: "Absolutely! Our Urbania buses are available for various routes and destinations, not just Pune to Mumbai Airport."
        },
        {
            question: "How can I contact customer support?",
            answer: "You can reach our customer support team via phone, email, or through our website for any inquiries or assistance."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rohan Patel",
            text: "I recently booked the Urbania bus for a group trip from Pune to Mumbai Airport, and I couldn’t be happier with the service! The bus was spacious and comfortable, perfect for our group of 15. Our driver was punctual and friendly, ensuring we arrived at the airport with plenty of time. I highly recommend SSKTravels24 for anyone looking for a reliable and comfortable travel option!"
        },
        {
            name: "Miss Priya Sharma",
            text: "We hired the Urbania bus for a family trip to Mumbai Airport, and it was a fantastic experience! The bus was clean, well-maintained, and equipped with all the necessary amenities. The journey was smooth, and the driver was very professional. I will definitely choose SSKTravels24 for my future travel needs. Thank you for making our trip enjoyable!"
        }
    ];
    
    const contactData = {
        heading: "Pune to Mumbai Airport Urbania Bus On Rent",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai Airport Urbania Bus On Rent | Luxury Bus Rental</title>
  <meta name="description" content="Hire Urbania buses for your travel from Pune to Mumbai Airport. Perfect for group travel, weddings, and tours. Book now for a comfortable journey!" />
  <meta name="keywords" content="Pune to Mumbai Airport Urbania bus on rent, Urbania luxury bus rental in Pune, Pune to Alibaug urbania bus on rent, Pune to Shirdi urbania bus on rent, Pune to Mahabaleshwar urbania bus" />
  <meta property="og:title" content="Pune to Mumbai Airport Urbania Bus On Rent | SSK Travels24" />
  <meta property="og:description" content="Experience luxury travel with Urbania bus rentals from Pune to Mumbai Airport. Ideal for groups, tours, and special occasions. Inquire today!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-mumbai-airport-urbania-bus" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//urbania-bus-rental-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-35.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PunetoMumbaiAirportUrbaniaBusOnRent ;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busonrentinhinjewadi(){


    const cardData = 
    {
        keyword: 'Buses on Rent in Hinjewadi',
        heading: 'SSKTravels24: Buses on Rent in Hinjewadi',
        headingDescription: 'When you need reliable and comfortable transportation in Hinjewadi, Pune, look no further than SSKTravels24. Our diverse fleet of buses is designed to cater to various needs, from corporate events to family outings. Discover our top bus rental options in Hinjewadi that promise a seamless and enjoyable travel experience:',
        
        topPlaces :[
            {
                title: "1. 32-Seater Luxury Bus",
                description: "Ideal for medium-sized groups, our 32-seater luxury bus offers a high level of comfort with plush seating, air conditioning, and onboard entertainment. Perfect for corporate functions, weddings, and special events, it ensures a premium travel experience."
            },
            {
                title: "2. 35-Seater Mini Bus",
                description: "Our 35-seater mini bus provides ample space for slightly larger groups. With comfortable seating and air conditioning, it’s suitable for school trips, community events, and family gatherings."
            },
            {
                title: "3. 40-Seater Standard Bus",
                description: "For larger groups, the 40-seater standard bus offers a balance of space and comfort. This option is great for group excursions, city tours, or any occasion requiring more seating capacity without compromising on comfort."
            },
            {
                title: "4. 45-Seater Executive Bus",
                description: "Our 45-seater executive bus is designed for those seeking a touch of luxury. With ergonomic seating, refreshments, and modern amenities, this bus is ideal for business trips, team outings, and high-profile events."
            },
            {
                title: "5. 50-Seater Deluxe Bus",
                description: "For very large groups, our 50-seater deluxe bus offers maximum seating and comfort. Suitable for corporate events, family reunions, or large-scale tours, it provides a spacious and comfortable travel experience."
            },
            {
                title: "6. Hinjewadi Local Sightseeing Bus",
                description: "Explore Hinjewadi and its surroundings with our local sightseeing bus. This service is perfect for tourists and locals who want to experience the area’s key attractions in comfort and style."
            },
            {
                title: "7. Hinjewadi Darshan Mini Bus",
                description: "Our Hinjewadi Darshan mini bus is ideal for a comprehensive city tour. It provides a comfortable and organized way to visit local landmarks and attractions, making it perfect for guided tours and group excursions."
            },
            {
                title: "8. Corporate Outing Bus in Hinjewadi",
                description: "For business functions and corporate outings, our buses are equipped to meet professional needs. They offer ample space for employees and clients, ensuring a smooth and efficient travel experience."
            },
            {
                title: "9. School Trip Bus in Hinjewadi",
                description: "Safety and comfort are our priorities for school trips. Our buses are specifically designed for educational outings, providing a secure and enjoyable journey for students and teachers."
            },
            {
                title: "10. Family Gathering Bus in Hinjewadi",
                description: "For family reunions, weddings, or other social gatherings, our family-friendly buses offer comfort and convenience. With spacious interiors and modern features, they ensure a pleasant travel experience for all."
            }
        ],
         services : [
            {
                name: "Bus on Rent Hire in Wakad",
                description: "For groups needing reliable transportation in Wakad, we offer a variety of bus rental options. From comfortable mini buses to larger luxury buses, our services are perfect for events, corporate functions, and local travel."
            },
            {
                name: "Bus on Rent in Hinjewadi for Corporate Outing",
                description: "Organize your corporate outings with ease using our specialized bus rental services in Hinjewadi. Our buses are designed to provide comfort and professionalism, making them ideal for team-building activities, client meetings, and corporate events."
            },
            {
                name: "Bus on Rent in Hinjewadi for Outstations",
                description: "Planning a trip to an outstation destination? Our buses in Hinjewadi are perfect for long-distance travel. With options ranging from standard to luxury, you can travel comfortably and reach your destination with ease."
            },
            {
                name: "Bus Hire for Wedding in Hinjewadi",
                description: "Make your wedding day special with our dedicated bus rental services. We provide stylish and spacious buses for wedding guests, ensuring everyone arrives comfortably and on time."
            },
            {
                name: "Bharat Benz 35-Seater Bus on Rent in Hinjewadi",
                description: "Experience top-notch comfort with our Bharat Benz 35-seater bus. Ideal for medium-sized groups, this bus offers modern amenities and a luxurious travel experience for any event or trip."
            },
            {
                name: "Force Urbania Luxury Mini Bus on Rent in Hinjewadi",
                description: "Our Force Urbania luxury mini bus is perfect for those seeking a blend of luxury and functionality. With comfortable seating and high-end features, it’s suitable for both corporate and personal events."
            },
            {
                name: "Corporate Outing Bus Hire in Hinjewadi",
                description: "Ensure a smooth and enjoyable corporate outing with our specialized bus rental services. Our buses are equipped to meet professional needs, offering a comfortable ride for employees and clients."
            },
            {
                name: "Tempo Traveller Hire in Hinjewadi",
                description: "For flexible and comfortable group travel, our tempo travellers are an excellent choice. Ideal for both short and long trips, they provide ample space and convenience."
            },
            {
                name: "Mini Bus on Rent in Hinjewadi",
                description: "Our mini buses are perfect for small to medium-sized groups. Whether you’re planning a local tour or a special event, our mini buses offer comfort and flexibility."
            },
            {
                name: "Bus Hire for Marriage in Hinjewadi",
                description: "For weddings and other special occasions, our bus hire services ensure that your guests travel in comfort and style. We offer a range of buses to suit different needs and preferences."
            },
            {
                name: "Bus Hire for Wedding Near Me in Pune",
                description: "Planning a wedding and need transportation for your guests? Our bus hire services are perfect for weddings across Pune. We offer a variety of buses to accommodate different group sizes and ensure your guests travel comfortably."
            },
            {
                name: "14, 17, 25, 32-Seater Bus on Rent in Hinjewadi",
                description: "No matter the size of your group, we have the right bus for you in Hinjewadi. Choose from our 14-seater, 17-seater, 25-seater, and 32-seater buses to meet your travel needs, whether for local events or outings."
            },
            {
                name: "Bus on Rent in Hinjewadi Phase 1",
                description: "If you’re located in Hinjewadi Phase 1 and need a bus for any occasion, we offer a range of options to suit your requirements. Our services are designed to provide comfort and reliability for local and outstation travel."
            },
            {
                name: "Bus on Rent in Hinjewadi Phase 2",
                description: "Our bus rental services extend to Hinjewadi Phase 2, offering flexible and convenient transportation options for events, corporate outings, and more. Enjoy a comfortable journey with our well-maintained fleet."
            },
            {
                name: "Bus on Rent in Hinjewadi Phase 3",
                description: "For those in Hinjewadi Phase 3, we provide a variety of bus rental services to meet your needs. Whether you’re organizing a local trip or an outstation journey, our buses are equipped to deliver a smooth and pleasant experience."
            },
            {
                name: "Bus on Rent in Wakad",
                description: "Wakad is covered by our extensive bus rental services. Whether you need a bus for a corporate event, family outing, or any other occasion, we provide reliable and comfortable options."
            },
            {
                name: "Bus on Rent in Hinjewadi for Outstations",
                description: "For those traveling out of Pune, our Hinjewadi bus rental service offers comfortable and reliable options for outstation trips. Choose from various bus sizes to accommodate your group’s needs."
            },
            {
                name: "Bus on Rent in Baner Aundh",
                description: "Our bus rental services in Baner Aundh cater to a range of needs, from corporate events to personal outings. We offer various bus sizes and types to ensure a comfortable and enjoyable travel experience."
            },
            {
                name: "Bus on Rent in Balewadi Pune",
                description: "In Balewadi Pune, we offer flexible and convenient bus rental options for all types of events and trips. Our well-maintained buses ensure a comfortable journey for all passengers."
            },
            {
                name: "Bus on Rent in Hinjewadi Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Bus on Rent in Hinjewadi. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],        
         tableData : [
            ['Bus on Rent Hire in Wakad', 'Bus on Rent in Hinjewadi for Corporate Outing'],
            ['Bus on Rent in Hinjewadi for Outstations', 'Bus Hire for Wedding in Hinjewadi'],
            ['Bharat Benz 35-Seater Bus on Rent in Hinjewadi', 'Force Urbania Luxury Mini Bus on Rent in Hinjewadi'],
            ['Corporate Outing Bus Hire in Hinjewadi', 'Tempo Traveller Hire in Hinjewadi'],
            ['Mini Bus on Rent in Hinjewadi', 'Bus Hire for Marriage in Hinjewadi'],
            ['Bus Hire for Wedding Near Me in Pune', '14, 17, 25, 32-Seater Bus on Rent in Hinjewadi'],
            ['Bus on Rent in Hinjewadi Phase 1', 'Bus on Rent in Hinjewadi Phase 2'],
            ['Bus on Rent in Hinjewadi Phase 3', 'Bus on Rent in Wakad'],
            ['Bus on Rent in Hinjewadi for Outstations', 'Bus on Rent in Baner Aundh'],
            ['Bus on Rent in Balewadi Pune', '']
        ],        
    
        whychoose : [
            {
                "WhyChooseheading": "Extensive Fleet Options:",
                "WhyChoosedescription": "We boast a diverse range of buses, including 14-seater, 17-seater, 25-seater, and 32-seater options. Whether you need a compact minibus or a larger vehicle, we have the perfect fit for your requirements."
            },
            {
                "WhyChooseheading": "Reliable and Comfortable Journeys:",
                "WhyChoosedescription": "Our buses are meticulously maintained and equipped with modern amenities to ensure a pleasant journey for all passengers. Punctuality and reliability are at the heart of our service."
            },
            {
                "WhyChooseheading": "Transparent and Affordable Pricing:",
                "WhyChoosedescription": "Enjoy competitive pricing for all our bus rental services. We believe in offering the best value for your money, with no hidden costs to surprise you."
            },
            {
                "WhyChooseheading": "Experienced and Professional Drivers:",
                "WhyChoosedescription": "Our drivers are seasoned professionals, well-acquainted with the local routes, and dedicated to ensuring a safe and smooth travel experience. They are also ready to accommodate any special requests."
            },
            {
                "WhyChooseheading": "Customizable Rental Services:",
                "WhyChoosedescription": "Whether it’s a corporate outing, a family trip, or a local event, our bus rental services can be tailored to meet your specific needs. We collaborate closely with you to ensure every detail is addressed."
            },
            {
                "WhyChooseheading": "24/7 Customer Support:",
                "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with any queries or concerns. We’re here to make your bus rental experience hassle-free and enjoyable."
            },
            {
                "WhyChooseheading": "Simple and Convenient Booking Process:",
                "WhyChoosedescription": "Reserving a bus with us is straightforward. You can book online or over the phone, with flexible options to accommodate your schedule."
            },
            {
                "WhyChooseheading": "Safety and Hygiene First:",
                "WhyChoosedescription": "We prioritize the safety and cleanliness of our vehicles. Each bus undergoes thorough cleaning and sanitization before and after every trip, ensuring a safe environment for all passengers."
            },
            {
                "WhyChooseheading": "Local Insights and Expertise:",
                "WhyChoosedescription": "With our base in Hinjewadi, we have extensive local knowledge, offering valuable recommendations for your travel needs, including the best routes and destinations."
            },
            {
                "WhyChooseheading": "Trusted Reputation:",
                "WhyChoosedescription": "Our long-standing reputation for outstanding service and customer satisfaction speaks volumes. We have consistently delivered exceptional bus rental experiences to our valued clients."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses do you offer for rent in Hinjewadi?",
            answer: "We provide a variety of buses, including 14-seater, 17-seater, 25-seater, and 32-seater options. Our fleet also features luxury and executive buses for specialized needs."
        },
        {
            question: "How can I book a bus with SSKTravels24?",
            answer: "You can easily book a bus online via our website or by calling our customer support team. We offer flexible booking options to suit your schedule."
        },
        {
            question: "What is the pricing structure for bus rentals in Hinjewadi?",
            answer: "Our pricing is competitive and varies based on the type of bus, duration of the rental, and distance traveled. We provide clear pricing with no hidden charges."
        },
        {
            question: "Are your buses equipped with modern amenities?",
            answer: "Yes, our buses come with modern amenities such as air conditioning, comfortable seating, and entertainment options. Specific features may vary by bus type."
        },
        {
            question: "How do you ensure the safety and cleanliness of your buses?",
            answer: "We adhere to high standards of safety and hygiene. Our buses are regularly cleaned and sanitized, and our drivers follow strict safety protocols for a secure travel experience."
        },
        {
            question: "Can I modify my booking after confirmation?",
            answer: "Yes, we offer flexibility with bookings. If you need to make changes, please reach out to our customer support team as soon as possible, and we will assist you."
        },
        {
            question: "What should I do if I face any issues during the trip?",
            answer: "If you encounter any problems during your journey, please inform the driver immediately. You can also contact our 24/7 customer support team for help."
        },
        {
            question: "Are your drivers qualified and professional?",
            answer: "Absolutely! All our drivers are highly experienced and trained to ensure a safe and enjoyable journey for all passengers."
        },
        {
            question: "Do you provide bus rental services for outstation trips from Hinjewadi?",
            answer: "Yes, we offer bus rental services for outstation trips. We can accommodate various distances and destinations according to your needs."
        },
        {
            question: "How far in advance should I book a bus?",
            answer: "We recommend booking as early as possible to secure availability, especially during peak seasons or special events. However, we do accept last-minute bookings based on availability."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Rajesh Patel",
            text: "I recently hired a bus from SSKTravels24 for a corporate outing in Hinjewadi, and the experience was exceptional. The booking process was effortless, and the customer service team was incredibly helpful. Our 25-seater bus was clean, comfortable, and equipped with all the necessary amenities. The driver was punctual and professional, ensuring we had a safe and enjoyable trip. I highly recommend SSKTravels24 for anyone in need of reliable bus rental services in Hinjewadi!"
        },
        {
            name: "Miss Anita Verma",
            text: "For my daughter's wedding, we required a large bus to transport guests from various parts of Hinjewadi. SSKTravels24 provided us with a 50-seater bus that was perfect for the occasion. The bus was impeccably maintained, and the driver was courteous and efficient. The entire process, from booking to the actual event, was seamless. We received numerous compliments from our guests about the comfort and quality of the transportation. Thank you, SSKTravels24, for making the day special and stress-free!"
        }
    ];
    
    
    const contactData = {
        heading: " Bus on Rent in Hinjewadi Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus on Rent in Hinjewadi | Affordable Bus Rental Services</title>
  <meta name="description" content="Discover bus rental options in Hinjewadi for corporate outings, weddings, and outstation trips. Affordable rates and various bus sizes available." />
  <meta name="keywords" content="Bus on rent Hire in Wakad | Bus on Rent in Hinjewadi for Corporate Outing | Bus on Rent in Hinjewadi for Outstations | Bus hire for wedding Hinjewadi | Bharat Benz 35 seater bus on rent in Hinjewadi | Force Urbania luxury mini bus on rent in Hinjewadi | Corporate outing bus hire in Hinjewadi | Tempo traveller hire in Hinjewadi | Mini bus on rent in Hinjewadi | Bus hire for Marriage in Hinjewadi | Bus hire for wedding near me Pune | 14 17 25 32 Seater bus on rent Hinjewadi | Bus on rent in Hinjewadi phase 1 | Bus on rent in Hinjewadi phase 2 | Bus on rent in Hinjewadi phase 3 | Bus on Rent in Baner Aundh | Bus on rent in Balewadi Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus on Rent in Hinjewadi | Affordable Bus Rental Services" />
  <meta property="og:description" content="Explore a variety of bus rental services in Hinjewadi for corporate outings, weddings, and local trips. Book now for the best deals!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-hinjewadi" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//hinjewadi-bus-rent-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-29.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Bus on Rent in Hinjewadi – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busonrentinhinjewadi;
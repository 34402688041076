
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function PunetoOutstationcab(){


    const cardData = 
    {
        keyword: 'Pune to Outstation Cab Services',
        heading: 'SSKTravels24: Pune to Outstation Cab Services',
        headingDescription: 'When it comes to traveling from Pune to outstation destinations, SSKTravels24 stands out as the go-to service for comfort, reliability, and affordability. Whether you’re planning a weekend getaway, a family vacation, or a business trip, our Pune to outstation cab services are designed to meet all your travel needs. With a fleet of well-maintained vehicles and experienced drivers, we ensure a safe and pleasant journey to some of the most beautiful locations around Pune.',
    
        topPlaces: [
            {
                title: "1. Lonavala",
                description: "Located just about 65 km from Pune, Lonavala is a popular hill station known for its lush greenery, waterfalls, and pleasant climate. Don’t miss the stunning views from Tiger's Leap and the serene Bhushi Dam."
            },
            {
                title: "2. Mahabaleshwar",
                description: "Approximately 120 km from Pune, Mahabaleshwar is famous for its scenic landscapes, strawberry farms, and viewpoints like Arthur’s Seat. Ideal for a weekend retreat, it offers pleasant weather year-round."
            },
            {
                title: "3. Khandala",
                description: "Adjacent to Lonavala, Khandala is another beautiful hill station offering stunning views of the Western Ghats. Visit the famous Rajmachi Fort for a taste of history and breathtaking panoramas."
            },
            {
                title: "4. Alibaug",
                description: "A coastal town located around 140 km from Pune, Alibaug is famous for its beautiful beaches and fort. Enjoy a relaxing day by the sea or explore the historic Kolaba Fort."
            },
            {
                title: "5. Igatpuri",
                description: "About 120 km from Pune, Igatpuri is a tranquil getaway known for its scenic mountains and serene lakes. It’s a great spot for nature lovers and trekking enthusiasts."
            },
            {
                title: "6. Nashik",
                description: "Famous for its vineyards and the sacred Godavari River, Nashik is around 165 km from Pune. Explore the wineries, taste local wines, and visit historical temples like Kalaram Temple."
            },
            {
                title: "7. Panchgani",
                description: "Approximately 100 km from Pune, Panchgani is a charming hill station known for its table land and scenic views. It’s a fantastic place for paragliding and enjoying local strawberries."
            },
            {
                title: "8. Matheran",
                description: "Just 120 km from Pune, Matheran is a serene hill station free from vehicle pollution. Enjoy horse rides and breathtaking views from its various viewpoints."
            },
            {
                title: "9. Kamshet",
                description: "A popular spot for paragliding, Kamshet is about 50 km from Pune. It’s an excellent choice for adventure enthusiasts looking to soar high above the beautiful landscapes."
            },
            {
                title: "10. Kolad",
                description: "Located about 120 km from Pune, Kolad is known for its white-water rafting and beautiful riverside scenery. It’s a perfect destination for thrill-seekers looking for adventure sports."
            }
        ],
    
        services: [
            {
                name: "Pune to Outstation Cab",
                description: "For a stress-free travel experience, book a Pune to outstation cab. These services offer a variety of vehicle options, ensuring a smooth and comfortable ride, no matter the destination."
            },
            {
                name: "Outstation Taxi Service Pune",
                description: "Need a cab for a long-distance trip? The outstation taxi service Pune provides a hassle-free solution for all your travel needs, with professional drivers and well-maintained vehicles."
            },
            {
                name: "Best Outstation Cab Service in Pune",
                description: "Looking for the best outstation cab service in Pune? Choose from trusted providers offering top-notch service, comfortable vehicles, and affordable rates for both short and long journeys."
            },
            {
                name: "Outstation Car Rental Pune",
                description: "For flexibility and convenience, opt for outstation car rental Pune services. Choose from a range of cars based on your budget and preferences, making your journey enjoyable and stress-free."
            },
            {
                name: "Taxi in Pune for Outstation",
                description: "Whether you're traveling alone or with family, booking a taxi in Pune for outstation offers a practical and reliable way to reach your destination. Enjoy on-time pickups and smooth rides every time."
            },
            {
                name: "Outstation Taxi in Pune",
                description: "For a hassle-free long-distance trip, book an outstation taxi in Pune. Whether it's a business trip or a vacation, outstation taxis provide comfort and convenience for your journey."
            },
            {
                name: "Pune to Outstation Taxi",
                description: "Whether you're traveling for business or pleasure, a Pune to outstation taxi service offers a reliable and convenient way to reach your destination. Choose from a variety of vehicle options for a comfortable journey."
            },
            {
                name: "Contact Number for Pune to Outstation Cab",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Pune to Outstation Cab. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        "tableData": [
            ["Pune to Outstation Cab", "Outstation Taxi Service Pune"],
            ["Best Outstation Cab Service in Pune", "Outstation Cab Service in Pune"],
            ["Outstation Car Rental Pune", "Taxi in Pune for Outstation"],
            ["Taxi Service in Pune for Outstation", "Book Outstation Cabs Pune"],
            ["Cab Booking in Pune for Outstation", "Car on Rent in Pune for Outstation"],
            ["Outstation Cabs Mumbai to Pune", "Outstation Cabs Pune to Mumbai"],
            ["Outstation Taxi in Pune", "Outstation Taxi Service in Pune"],
            ["Pune Airport to Kolhapur Cab", "Pune to Outstation Innova Crysta Cab"],
            ["Pune to Outstation Ertiga Cab", "Pune to Outstation Kia Carens Cab"],
            ["Pune to Outstation Swift Dzire Cab Booking", "Best Outstation Cab Service in Pune"],
            ["Pune to Outstation Cab Sedan Cab Booking", "SSK Travels 24"]
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSK Travels 24 for Pune to Outstation Cab?",
                "WhyChoosedescription": "Reliable Service: At SSK Travels 24, we prioritize punctuality and dependability. Our drivers are experienced and trained to ensure that you arrive at your destination on time, every time."
            },
            {
                "WhyChooseheading": "Transparent Pricing:",
                "WhyChoosedescription": "We offer clear and competitive pricing with no hidden fees. You’ll know the cost upfront, allowing for better planning and budgeting."
            },
            {
                "WhyChooseheading": "Diverse Vehicle Options:",
                "WhyChoosedescription": "Whether you need a compact car for a solo trip or a spacious SUV for a family getaway, our fleet caters to all needs and preferences."
            },
            {
                "WhyChooseheading": "Comfort and Safety:",
                "WhyChoosedescription": "Your comfort and safety are our top priorities. Our vehicles are well-maintained and equipped with necessary safety features, ensuring a secure journey."
            },
            {
                "WhyChooseheading": "24/7 Availability:",
                "WhyChoosedescription": "Our services are available around the clock, allowing you to book a cab whenever you need, day or night."
            },
            {
                "WhyChooseheading": "Customer-Centric Approach:",
                "WhyChoosedescription": "We believe in providing exceptional customer service. Our friendly staff is always ready to assist you with any questions or special requests."
            },
            {
                "WhyChooseheading": "Easy Booking Process:",
                "WhyChoosedescription": "Booking a cab is simple and convenient through our user-friendly online platform or by contacting our customer service."
            },
            {
                "WhyChooseheading": "Flexible Itineraries:",
                "WhyChoosedescription": "We understand that travel plans can change. Our flexible booking options allow you to modify your itinerary as needed."
            }
        ]
    };    

    
    const faqData = [
        {
          question: "What destinations can I travel to using your outstation cab services?",
          answer: "We offer cab services to a wide range of outstation destinations including popular locations like Lonavala, Mahabaleshwar, Nashik, and more. You can choose any destination that suits your travel plans."
        },
        {
          question: "How can I book a Pune to outstation cab?",
          answer: "You can easily book your cab through our website or by calling our customer service team. The booking process is quick and hassle-free."
        },
        {
          question: "Are there any hidden charges?",
          answer: "No, we pride ourselves on transparent pricing. The fare you see is the fare you pay, with no hidden costs involved."
        },
        {
          question: "Can I choose the type of vehicle for my journey?",
          answer: "Absolutely! We offer a variety of vehicles, including sedans, SUVs, and luxury cars. You can choose the one that best fits your needs."
        },
        {
          question: "Is your service available 24/7?",
          answer: "Yes, we provide round-the-clock service, ensuring that you can book a cab at any time of the day or night."
        },
        {
          question: "What if my plans change? Can I modify my booking?",
          answer: "Yes, we offer flexible booking options that allow you to modify your itinerary as needed. Just contact our customer service team for assistance."
        },
        {
          question: "Are the drivers experienced and trained?",
          answer: "Yes, all our drivers are experienced, well-trained, and familiar with the routes to ensure a smooth and safe journey."
        },
        {
          question: "What safety measures do you have in place?",
          answer: "We prioritize your safety by adhering to strict hygiene protocols, regularly maintaining our vehicles, and ensuring our drivers follow all safety guidelines."
        },
        {
          question: "How do I contact customer support?",
          answer: "You can reach our customer support team via phone or email. We are always here to help with any inquiries or concerns you may have."
        },
        {
          question: "Can I book a round trip with SSK Travels 24?",
          answer: "Yes, we offer round trip services for your convenience. You can easily book both legs of your journey when making your reservation."
        }
      ];
      
      const testimonials = [
        {
          name: "Ms. Priya Deshmukh",
          text: "SSKTravels24 made our family trip to Mahabaleshwar an absolute delight! The cab was spacious and comfortable, perfect for our needs. Our driver was not only punctual but also very knowledgeable about the area, giving us great tips on where to go. We felt safe and well taken care of throughout the journey. I highly recommend SSKTravels24 for anyone looking for reliable outstation cab services!"
        },
        {
          name: "Mr. Rajan Kulkarni",
          text: "I recently used SSKTravels24 for a weekend getaway to Lonavala, and I couldn’t be more satisfied. The booking process was seamless, and the driver arrived right on time. The car was clean and well-maintained, making the trip enjoyable. I appreciated the transparent pricing with no surprises at the end. I will definitely choose SSKTravels24 for my future outstation travels!"
        }
      ];
      
      const contactData = {
        heading: "Pune to Outstation Cab Contact Information",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Outstation Cab | Reliable Outstation Taxi Service</title>
  <meta name="description" content="Book your outstation cab from Pune with SSK Travels24. Enjoy comfortable rides to various destinations with competitive rates and quality service." />
  <meta name="keywords" content="Pune to Outstation Cab, Outstation Taxi Service Pune, Best Outstation Cab Service in Pune, Outstation Car Rental Pune, Book Outstation Cabs Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Outstation Cab | Reliable Outstation Taxi Service" />
  <meta property="og:description" content="Travel from Pune to various outstation destinations with our dependable cab service. Choose from a range of vehicles for your comfort." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-outstation-cab" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//outstation-cab-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-13.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PunetoOutstationcab;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetomumbaicabtaxi(){


    const cardData = 
    {
        keyword: 'Pune to Mumbai Cab Taxi Service',
        heading: 'SSKTravels24: Pune to Mumbai Cab Taxi Service',
        headingDescription: 'Are you planning a trip from Pune to Mumbai? Look no further than SSKTravels24 for a comfortable, reliable, and hassle-free taxi service. Enjoy a seamless journey to the vibrant city of Mumbai with our experienced drivers and well-maintained vehicles.',
        
        topPlaces: [
            {
                title: "1. Gateway of India",
                description: "An iconic symbol of Mumbai, this stunning archway overlooking the Arabian Sea is a must-visit, offering beautiful views and ferry rides to Elephanta Island."
            },
            {
                title: "2. Marine Drive",
                description: "Known as the 'Queen's Necklace,' Marine Drive is a picturesque promenade along the coast, perfect for a sunset stroll."
            },
            {
                title: "3. Chhatrapati Shivaji Maharaj Terminus",
                description: "A UNESCO World Heritage Site, this historic railway station is famous for its Victorian Gothic architecture and bustling atmosphere."
            },
            {
                title: "4. Colaba Causeway",
                description: "Famous for street shopping and a vibrant atmosphere, Colaba Causeway is the perfect place to find unique souvenirs and local handicrafts."
            },
            {
                title: "5. Elephanta Caves",
                description: "These ancient rock-cut caves house magnificent sculptures dedicated to Lord Shiva, offering a glimpse into India's rich history."
            },
            {
                title: "6. Haji Ali Dargah",
                description: "Situated on an islet in the Arabian Sea, this mosque and tomb is a popular pilgrimage site with breathtaking views during high tide."
            },
            {
                title: "7. Juhu Beach",
                description: "Relax, enjoy street food, and soak in the local culture at one of Mumbai's most famous beaches."
            },
            {
                title: "8. Chowpatty Beach",
                description: "Known for its vibrant atmosphere and food stalls, Chowpatty Beach is perfect for an evening outing with family and friends."
            },
            {
                title: "9. Siddhivinayak Temple",
                description: "This revered Hindu temple dedicated to Lord Ganesha attracts numerous devotees seeking blessings."
            },
            {
                title: "10. Bandra-Worli Sea Link",
                description: "An architectural marvel connecting Bandra and Worli, this bridge offers stunning views of the Mumbai skyline, especially at night."
            }
        ],
        
        services: [
          {
              "name": "Pune to Mumbai Cab",
              "description": "Our standard cab service is perfect for individuals and small groups, ensuring a smooth ride with experienced drivers. Enjoy comfortable seating and a hassle-free journey as we take you to your destination in a timely manner, allowing you to relax and enjoy the ride."
          },
          {
              "name": "Pune to Mumbai Airport Cab",
              "description": "Travel stress-free to the airport with our dedicated airport cab service, which monitors flight schedules for timely pickups. Our professional drivers ensure you arrive on time, equipped with real-time updates to navigate traffic efficiently, so you can focus on your travel plans."
          },
          {
              "name": "Pune to Mumbai Taxi",
              "description": "Offering flexible taxi services for quick rides or full-day hires, our taxis provide a comfortable travel option for any occasion. Whether you need a short trip or a day-long excursion, our drivers are ready to accommodate your needs and preferences."
          },
          {
              "name": "Pune to Mumbai Cheapest Cab",
              "description": "Enjoy affordable rates without compromising service quality, making this option perfect for budget travelers. Our commitment to providing excellent service at low prices ensures you can travel comfortably without breaking the bank."
          },
          {
              "name": "Pune to Mumbai Innova Cab",
              "description": "Ideal for family trips, our Innova cabs offer ample space and comfort for everyone. With premium interiors and plenty of room for luggage, you can enjoy your journey together without feeling cramped."
          },
          {
              "name": "Pune to Mumbai Swift Cabs Booking",
              "description": "Swift cabs provide economical and efficient travel options for quick trips or solo travelers. Our prompt service and clean vehicles ensure that your travel experience is smooth and enjoyable, making it easy to reach your destination on time."
          },
          {
              "name": "Pune to Mumbai Sedan Cabs Booking",
              "description": "Travel in style with our sedan cabs, offering a classy experience for both business and leisure. Equipped with comfortable seating and modern amenities, our sedans are perfect for those who want to make a good impression while on the go."
          },
          {
              "name": "Convenient Booking Options",
              "description": "Our user-friendly online booking platform allows you to secure your ride in just a few clicks. With a simple interface and quick confirmation, booking your cab has never been easier, giving you more time to focus on your plans."
          },
          {
              "name": "Pune to Mumbai Taxi One Way",
              "description": "Our flexible one-way cab service is perfect for those who don’t require a return trip, simplifying your travel. Enjoy the convenience of direct service from Pune to Mumbai without the need to schedule a round trip."
          },
          {
              "name": "Contact Number for Pune to Mumbai Cab Taxi Service",
              "description": "For prompt and efficient service, contact SSKTravels24 at +91 8956170493. Our dedicated customer service team is ready to assist you with bookings, inquiries, and any other travel needs you may have."
          }
      ],      
        "tableData": [
            ["Pune to Mumbai Cab", "Pune to Mumbai Airport Cab"],
            ["Pune Mumbai Cab", "Pune to Mumbai Taxi"],
            ["Pune to Mumbai Airport Cab Fare", "Pune Mumbai Airport Drop"],
            ["Pune to Mumbai Cheapest Cab", "Pune to Mumbai Cab Charges"],
            ["Pune Mumbai Taxi Fare", "Pune to Mumbai International Airport Cab"],
            ["Pune to Mumbai Airport Cab Price", "Pune to Mumbai Cab Booking"],
            ["Pune to Mumbai Taxi One Way", "Cab Booking Mumbai to Pune"],
            ["Pune to Mumbai Innova Cab", "Pune to Mumbai Innova Crysta Cab Booking"],
            ["Pune to Mumbai Ertiga Cabs Booking", "Pune to Mumbai Swift Cabs Booking"],
            ["Pune to Mumbai Sedan Cabs Booking", "Pune to Mumbai One Way Cab Service"],
            ["SSK Travels 24", ""]
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose Us for Pune to Mumbai Cab Taxi Service – SSKTravels24?",
                "WhyChoosedescription": "We offer a wide range of vehicle options, including standard cabs, Innova, Ertiga, and premium sedans, catering to all group sizes and preferences."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "Our transparent pricing structure ensures that you receive excellent value for your money. No hidden fees, just straightforward fare estimates based on your trip."
            },
            {
                "WhyChooseheading": "Reliable and Punctual:",
                "WhyChoosedescription": "We understand the importance of time. Our drivers are experienced, punctual, and committed to getting you to your destination safely and on time."
            },
            {
                "WhyChooseheading": "Comfort and Safety:",
                "WhyChoosedescription": "All our vehicles are well-maintained and equipped with modern amenities. We prioritize passenger comfort and safety, providing a smooth and enjoyable ride."
            },
            {
                "WhyChooseheading": "Easy Booking Process:",
                "WhyChoosedescription": "Our user-friendly online platform allows you to book your cab quickly and effortlessly, whether you need a one-way trip or a round trip."
            },
            {
                "WhyChooseheading": "24/7 Customer Support:",
                "WhyChoosedescription": "Our dedicated customer service team is available around the clock to assist you with any inquiries or changes to your booking."
            },
            {
                "WhyChooseheading": "Experienced Drivers:",
                "WhyChoosedescription": "Our drivers are not only skilled but also familiar with the best routes between Pune and Mumbai, ensuring a seamless journey."
            },
            {
                "WhyChooseheading": "Flexible Packages:",
                "WhyChoosedescription": "Whether you need an airport transfer, a day trip, or a longer hire, we offer customizable packages to suit your specific travel needs."
            }
        ]
    };    

    
   
    const faqData = [
        {
          question: "What types of vehicles do you offer for Pune to Mumbai cab service?",
          answer: "We offer a variety of vehicles, including standard cabs, Innova, Ertiga, and premium sedans, to accommodate different group sizes and travel preferences."
        },
        {
          question: "How do I book a cab for my Pune to Mumbai trip?",
          answer: "You can book your cab easily through our online booking platform or by contacting our customer service team. Provide your travel details, and we’ll take care of the rest."
        },
        {
          question: "What is the fare for a Pune to Mumbai cab?",
          answer: "The fare depends on factors such as the type of vehicle, distance, and any additional services you may require. We provide transparent pricing with no hidden charges."
        },
        {
          question: "Are the cabs air-conditioned?",
          answer: "Yes, all our vehicles are equipped with air conditioning to ensure a comfortable ride, regardless of the weather."
        },
        {
          question: "How long does the journey take from Pune to Mumbai?",
          answer: "The journey typically takes around 3 to 4 hours, depending on traffic conditions and the specific pickup and drop-off locations."
        },
        {
          question: "Can I customize my trip itinerary?",
          answer: "Absolutely! We offer flexible packages that can be tailored to your itinerary, whether you need specific pickup points or want to make stops along the way."
        },
        {
          question: "What safety measures are in place for the journey?",
          answer: "We prioritize safety by ensuring all our vehicles are regularly maintained and our drivers are experienced and trained to handle various road conditions."
        },
        {
          question: "What if I need to cancel or change my booking?",
          answer: "If you need to make changes or cancel your booking, please contact our customer service team as soon as possible. We will do our best to accommodate your requests based on our terms and conditions."
        },
        {
          question: "Is there a minimum booking time for the cab?",
          answer: "We recommend booking in advance, especially during peak seasons, to ensure availability. However, we can accommodate last-minute bookings based on availability."
        },
        {
          question: "What payment methods do you accept?",
          answer: "We accept various payment methods, including cash, credit/debit cards, and online payment options, making it convenient for you to settle the fare."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Arvind Patil",
          text: "I recently used SSKTravels24 for a trip from Pune to Mumbai, and I couldn't be happier with the service. The booking process was simple, and the cab arrived on time. Our driver was professional and knowledgeable, making the journey smooth and enjoyable. The car was clean and comfortable, and the AC worked perfectly. I highly recommend SSKTravels24 for anyone needing reliable cab service!"
        },
        {
          name: "Ms. Neha Deshmukh",
          text: "SSKTravels24 made my airport transfer from Pune to Mumbai seamless! The cab was spacious and well-maintained, which was perfect for my luggage. The driver was courteous and helped with my bags, making the trip stress-free. The fare was also very reasonable compared to other services I checked. I will definitely use SSKTravels24 again for my future travels!"
        }
      ];
      
      const contactData = {
        heading: "Pune to Mumbai Cab Taxi Service Contact Information",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai Cab | Affordable & Reliable Taxi Service</title>
  <meta name="description" content="Book your cab from Pune to Mumbai with SSK Travels24. Enjoy comfortable rides to Mumbai Airport and various city destinations at competitive rates." />
  <meta name="keywords" content="Pune to Mumbai Cabs, Pune Mumbai Cab Service, Pune to Mumbai Airport Cab, Affordable Taxi from Pune to Mumbai, Pune to Mumbai Cab Booking" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai Cab | Affordable & Reliable Taxi Service" />
  <meta property="og:description" content="Travel from Pune to Mumbai with our dependable cab service. Competitive fares and multiple vehicle options for your convenience." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-mumbai-cab" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//mumbai-cab-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\pune-to-mumbai.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetomumbaicabtaxi;
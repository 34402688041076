
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busbookingformarrige(){


    const cardData = 
    {
        keyword: 'Bus Booking for Marriage Functions in Pune',
        heading: 'SSKTravels24: Bus Booking for Marriage Functions in Pune',
        headingDescription: 'Planning a marriage function involves a lot of coordination, and transportation is a key component to ensure everything runs smoothly. SSKTravels24 offers a comprehensive range of bus booking services tailored specifically for marriage functions in Pune. Whether you need local transportation or outstation travel, we provide reliable, comfortable, and luxurious options to suit your needs. Explore our services and features below:',
        
         topPlaces : [
            {
                title: "Wide Range of Bus Sizes",
                description: "Choose from various bus sizes, including 17-seater, 25-seater, 32-seater, 35-seater, and 40-seater, to accommodate all your guests comfortably."
            },
            {
                title: "Luxury and Comfort",
                description: "Our buses are designed for luxury and comfort, featuring plush seating, spacious interiors, and modern amenities to enhance your travel experience."
            },
            {
                title: "Air-Conditioned Buses",
                description: "Enjoy a cool and comfortable journey with our fully air-conditioned buses, ensuring a pleasant ride regardless of the weather."
            },
            {
                title: "Professional Drivers",
                description: "Our experienced drivers are dedicated to providing safe and smooth transportation. They are skilled in managing the logistics of marriage functions and ensuring timely arrivals."
            },
            {
                title: "Customizable Packages",
                description: "We offer flexible and customizable rental packages to fit your specific requirements, including additional amenities and special arrangements."
            },
            {
                title: "Transparent Pricing",
                description: "Benefit from clear and upfront pricing with no hidden fees. We provide detailed quotes so you can plan your budget accurately."
            },
            {
                title: "Punctual Service",
                description: "We emphasize punctuality, making sure that all transportation schedules are met and your guests arrive on time."
            },
            {
                title: "Local and Outstation Rentals",
                description: "Whether your marriage function is within Pune or requires outstation travel, we provide dependable transportation solutions for both scenarios."
            },
            {
                title: "Easy Booking Process",
                description: "Booking is simple with SSKTravels24. Contact us via phone, email, or our online form, and we’ll handle the rest."
            },
            {
                title: "Exceptional Customer Support",
                description: "Our dedicated customer support team is available to address any queries or issues, ensuring a seamless experience from start to finish."
            }
        ],        
        
        services : [
            {
                name: "Full Bus Booking for Marriage Price",
                description: "Obtain a competitive quote for full bus bookings tailored to your marriage function. We provide detailed pricing to help you plan your budget."
            },
            {
                name: "Tourist Bus Booking for Marriage",
                description: "For a comfortable and enjoyable travel experience, consider our tourist bus options. Perfect for long-distance or local travel, ensuring a pleasant journey for your guests."
            },
            {
                name: "Reserve Bus for Marriage in Pune",
                description: "Secure your bus rental with ease. SSKTravels24 offers a straightforward reservation process for marriage functions in Pune."
            },
            {
                name: "Bus Booking for Marriage Party",
                description: "Ensure smooth transportation for your marriage party with our reliable bus rental services, designed to accommodate all your guests comfortably."
            },
            {
                name: "Bus Booking for Marriage Pune to Outstation",
                description: "Need transportation for outstation travel? We offer dependable bus services for marriage functions traveling from Pune to other locations."
            },
            {
                name: "Bus for Marriage Booking in Pune",
                description: "Find the ideal bus for your marriage function in Pune with our wide range of rental options. We provide solutions to suit every need."
            },
            {
                name: "Bus Hire in Pimpri Chinchwad for Marriage Function",
                description: "Residents of Pimpri Chinchwad can benefit from our specialized bus hire services for marriage functions. We offer comfortable and reliable transportation options."
            },
            {
                name: "Sleeper Bus Booking for Marriage",
                description: "For long-distance travel or overnight events, consider our sleeper bus options. Provide your guests with a comfortable and restful journey."
            },
            {
                name: "Luxury Bus Booking for Marriage Function in Pune",
                description: "Enjoy a touch of elegance with our luxury bus rentals. Perfect for making your marriage function special with high-end amenities."
            },
            {
                name: "Full Bus Booking for Marriage Event in Pune",
                description: "Our full bus booking services in Pune cater to all your marriage event transportation needs, providing reliable and comfortable options."
            },
            {
                name: "Bus Booking for Marriage Function in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Bus Booking for Marriage function in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
         tableData : [
            ['- Full Bus Booking for Marriage Price', '- Tourist Bus Booking for Marriage'],
            ['- Reserve Bus for Marriage in Pune', '- Bus Booking for Marriage Party'],
            ['- Bus Booking for Marriage Pune to Outstation', '- Bus for Marriage Booking in Pune'],
            ['- Bus Hire in Pimpri Chinchwad for Marriage Function', '- Bus Booking for Marriage Events in Pune'],
            ['- Sleeper Bus Booking for Marriage', '- Bus Booking for Marriage Events in Pimpri Chinchwad'],
            ['- Luxury Bus Booking for Marriage Function in Pune', '- Luxury Bus Booking for Marriage Function in Karve Nagar'],
            ['- Luxury Bus Booking for Marriage Function in Kothrud', '- Full Bus Booking for Marriage Price in Pune'],
            ['- Full Bus Booking for Marriage in Viman Nagar', '- Full Bus Booking for Marriage in Baner Aundh'],
            ['- Full Bus Booking for Marriage in Pimpri Chinchwad', '- Full Bus Booking for Marriage Event in Pune']
        ],        
    
        whychoose : [
            {
                "WhyChooseheading": "Why Choose Us for Your Marriage Function Bus Rental?",
                "WhyChoosedescription": "With years of experience in handling marriage function transportation, SSKTravels24 provides expert solutions tailored to the unique needs of your event."
            },
            {
                "WhyChooseheading": "Tailored Solutions:",
                "WhyChoosedescription": "We customize our services to fit your specific requirements, ensuring that every detail of your transportation is managed according to your preferences."
            },
            {
                "WhyChooseheading": "High-Quality Fleet:",
                "WhyChoosedescription": "Our fleet of well-maintained, luxury buses guarantees a high standard of comfort and style, making your guests' journey enjoyable."
            },
            {
                "WhyChooseheading": "Reliability:",
                "WhyChoosedescription": "We are committed to delivering reliable service, from punctuality to adherence to your specific needs, ensuring everything runs smoothly on your special day."
            },
            {
                "WhyChooseheading": "Affordability:",
                "WhyChoosedescription": "Our competitive and transparent pricing provides excellent value for your money, making luxury transportation accessible without compromising quality."
            },
            {
                "WhyChooseheading": "Personalized Service:",
                "WhyChoosedescription": "We offer personalized attention and care, ensuring that every aspect of your transportation is handled with precision and professionalism."
            },
            {
                "WhyChooseheading": "Customer Satisfaction:",
                "WhyChoosedescription": "Our focus is on delivering high levels of customer satisfaction, with a dedicated support team available to assist with any concerns and ensure a stress-free experience."
            },
            {
                "WhyChooseheading": "Comprehensive Coverage:",
                "WhyChoosedescription": "Whether your wedding is local or outstation, we provide complete coverage for all your transportation needs, ensuring a hassle-free experience."
            },
            {
                "WhyChooseheading": "Safety and Comfort:",
                "WhyChoosedescription": "We prioritize the safety and comfort of your guests with experienced drivers and well-maintained vehicles that meet all safety standards."
            },
            {
                "WhyChooseheading": "Flexibility:",
                "WhyChoosedescription": "Our flexible booking options and customizable packages adapt to your changing needs, providing the best possible service for your marriage function."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for marriage functions?",
            answer: "We offer a variety of buses to suit your needs, including 17-seater, 25-seater, 32-seater, 35-seater, and 40-seater options. Our fleet also includes luxury buses and sleeper buses for longer journeys or added comfort."
        },
        {
            question: "How do I book a bus for a marriage function?",
            answer: "Booking a bus with SSKTravels24 is straightforward. You can contact us via phone, email, or our online booking form. Provide details about your event, including the date, time, and the number of passengers, and we’ll handle the rest."
        },
        {
            question: "Are the buses air-conditioned?",
            answer: "Yes, all our buses are fully air-conditioned to ensure a comfortable journey for your guests, regardless of the weather."
        },
        {
            question: "What amenities are provided on the buses?",
            answer: "Our buses come equipped with a range of amenities, including comfortable seating, ample legroom, and modern features. Luxury buses offer additional comforts such as plush seating and entertainment options."
        },
        {
            question: "Can I customize the bus rental package?",
            answer: "Yes, we offer customizable packages to meet your specific needs. Whether you need extra amenities or special arrangements, we can tailor our services accordingly."
        },
        {
            question: "How much does it cost to book a full bus for a marriage function?",
            answer: "The cost of booking a full bus varies based on factors such as bus size, amenities, and duration of use. Contact us for a detailed quote tailored to your specific requirements."
        },
        {
            question: "Do you offer outstation bus rentals for marriage functions?",
            answer: "Yes, we provide outstation bus rentals for marriage functions. Whether your event is within Pune or requires travel to other locations, we can accommodate your transportation needs."
        },
        {
            question: "What is the cancellation policy for bus bookings?",
            answer: "Our cancellation policy is based on the terms agreed upon at the time of booking. Please contact us directly for detailed information regarding cancellations and refunds."
        },
        {
            question: "Are the buses equipped with safety features?",
            answer: "Absolutely. Our buses are regularly maintained and adhere to all safety regulations. They are equipped with essential safety features to ensure a secure and reliable journey for your guests."
        },
        {
            question: "How far in advance should I book a bus for a marriage function?",
            answer: "We recommend booking as early as possible to ensure availability and secure your preferred bus. Typically, booking a few months in advance is ideal, especially during peak wedding seasons."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Ms. Aditi Kulkarni",
            text: "SSKTravels24 provided us with outstanding service for our wedding. We booked a luxury bus for our guests, and everything was perfect. The bus was incredibly comfortable, and the driver was very professional. We couldn’t have asked for better transportation on our special day."
        },
        {
            name: "Mr. Rajesh Deshmukh",
            text: "We hired a tourist bus from SSKTravels24 for our daughter's wedding, and it was a fantastic choice. The bus was spacious and well-maintained, making the journey enjoyable for all our guests. Their service was reliable and hassle-free."
        }
    ];
    
    
    const contactData = {
        heading: "Bus Booking for Marriage function in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus Booking for Marriage Function in Pune | Affordable Marriage Bus Services</title>
  <meta name="description" content="Book buses for marriage functions in Pune. Various options including luxury buses and sleeper buses available." />
  <meta name="keywords" content="Full Bus booking for marriage price | Tourist bus booking for marriage | Reserve bus for marriage in Pune | Bus booking for marriage party | Bus booking for marriage Pune to outstation | Bus for marriage booking in Pune | Bus hire in Pimpri Chinchwad for Marriage Function | Bus booking for marriage Events in Pune | Sleeper bus booking for marriage | Bus booking for marriage events in Pimpri Chinchwad | Luxury bus booking for marriage function in Pune | Luxury bus booking for marriage function in Karve Nagar | Luxury bus booking for marriage function in Kothrud | Full bus booking for marriage price in Pune | Full bus booking for marriage in Viman Nagar | Full bus booking for marriage in Baner Aundh | Full bus booking for marriage in Pimpri Chinchwad | Full bus booking for marriage event in Pune | SSK Travels24 Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus Booking for Marriage Function in Pune | Affordable Marriage Bus Services" />
  <meta property="og:description" content="Discover reliable bus booking services for marriage functions in Pune. Luxury and sleeper options available!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-booking-marriage-function-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//marriage-bus-booking-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-34.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Bus Booking for Marriage Functions in Pune</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busbookingformarrige;
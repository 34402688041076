
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function PremierNonACBusRentalServiceinPune(){


    const cardData = 
    {
        keyword: 'Non AC Bus Rental Service in Pune',
        heading: 'SSKTravels24: Premier Non-AC Bus Rental Service in Pune',
        headingDescription: 'Looking for economical and reliable group travel options? SSKTravels24 offers non-AC buses that blend comfort and affordability. Whether for a corporate event, family outing, or outstation trip, discover the top features and services we provide for non-AC bus rentals.',
        
        topPlaces: [
          {
            title: "1. Spacious Seating",
            description: "Our non-AC buses feature ample seating with sufficient legroom, ensuring a comfortable travel experience even for long journeys."
          },
          {
            title: "2. Economical Travel",
            description: "Non-AC buses are a cost-effective solution for budget-conscious travelers without sacrificing comfort."
          },
          {
            title: "3. Well-Maintained Interiors",
            description: "We ensure that our buses are regularly cleaned and well-maintained, adhering to high cleanliness standards."
          },
          {
            title: "4. Ample Luggage Space",
            description: "With dedicated luggage compartments and overhead storage, our buses ensure ample space for passengers and their belongings."
          },
          {
            title: "5. Experienced Drivers",
            description: "Our professional drivers prioritize safety and punctuality, ensuring a smooth and pleasant journey."
          },
          {
            title: "6. Customizable Options",
            description: "Choose from various bus sizes (13-seater, 17-seater, 20-seater, 25-seater, 32-seater, 35-seater, 40-seater, and 45-seater) based on your group size and requirements."
          },
          {
            title: "7. Flexible Rental Terms",
            description: "We offer flexible rental options to fit your schedule, whether for a few hours, a full day, or several days."
          },
          {
            title: "8. Reliable and Safe Travel",
            description: "Our buses come equipped with safety features like seat belts, emergency exits, and fire extinguishers, ensuring safety throughout the journey."
          },
          {
            title: "9. Affordable Pricing",
            description: "We provide competitive and transparent pricing with no hidden fees, ensuring great value for your trip."
          },
          {
            title: "10. Convenient Booking Process",
            description: "Booking is easy—simply contact us via phone, email, or our online form, and we’ll help you secure the perfect bus for your needs."
          }
        ],
      
        services: [
          {
            name: "Non-AC Bus for Outstation Travel",
            description: "Ideal for long-distance journeys, our non-AC buses provide an affordable and comfortable solution for outstation trips."
          },
          {
            name: "Non-AC Tempo Traveller on Rent",
            description: "For smaller groups, our non-AC tempo travellers offer extra comfort and space, making them perfect for intimate gatherings."
          },
          {
            name: "Non-AC Mini Bus Hire",
            description: "Perfect for medium-sized groups, our mini buses are ideal for local trips and short-distance travel."
          },
          {
            name: "Non-AC Bus for Picnics",
            description: "Planning a picnic? Our spacious non-AC buses are perfect for a relaxing and enjoyable family outing."
          },
          {
            name: "Corporate Trip Rentals",
            description: "We offer reliable non-AC buses for corporate events, ensuring your team travels comfortably and on time."
          },
          {
            name: "Non-AC Bus Per KM Rate in Pune",
            description: "We offer competitive and transparent per-kilometer rates for local and outstation non-AC bus rentals."
          },
          {
            name: "Bus on Rent in Pimpri Chinchwad",
            description: "We provide reliable non-AC bus rentals in Pimpri Chinchwad for both local and outstation travel needs."
          },
          {
            name: "Bus on Rent in Hadapsar",
            description: "Our Hadapsar-based rental services ensure comfortable non-AC bus transportation for various events and gatherings."
          },
          {
            name: "Non-AC Bus Hire Prices",
            description: "Receive detailed quotes based on your specific requirements to ensure the best value for your rental."
          },
          {
            name: "Contact Number for Non-AC Bus Rentals in Pune",
            description: "For prompt and efficient service, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['- Non-AC Tempo Traveller on Rent in Pune', '- Non-AC Bus Hire on Rent in Pune'],
            ['- Non-AC Mini Bus Hire on Rent in Pune', '- Non-AC Bus Hire for Picnic in Pune'],
            ['- Best Bus Rental Service in Pune', '- 32, 35, 40, 45 Seater Local and Outstation Bus'],
            ['- 14, 17, 20, 25 Seater Bus on Rent in Pune', '- Tempo Traveller on Rent in Kothrud'],
            ['- Affordable Mini Bus on Rent in Pune', '- 13, 17, 20, 25, 32 Seater Bus on Rent in Pune']
        ],
        
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for Non-AC Bus Rentals in Pune?",
                "WhyChoosedescription": "At SSKTravels24, we ensure that your travel experience is comfortable, safe, and enjoyable. Here’s why we are the best choice for non-AC bus rentals in Pune:"
            },
            {
                "WhyChooseheading": "Spacious and Comfortable Buses:",
                "WhyChoosedescription": "Our non-AC buses are designed with spacious seating arrangements and ample legroom, providing a relaxing travel experience."
            },
            {
                "WhyChooseheading": "Experienced and Professional Drivers:",
                "WhyChoosedescription": "Our skilled drivers know local and long-distance routes well, ensuring a safe and timely journey."
            },
            {
                "WhyChooseheading": "Well-Maintained Fleet:",
                "WhyChoosedescription": "We prioritize safety and reliability, with regular maintenance checks ensuring our buses meet high standards."
            },
            {
                "WhyChooseheading": "Transparent Pricing:",
                "WhyChoosedescription": "We believe in clear pricing with no hidden fees, allowing you to budget effectively for your travel."
            },
            {
                "WhyChooseheading": "Customizable Options:",
                "WhyChoosedescription": "Whether you need a bus for a short outing or an extended trip, we offer customizable packages to fit your needs."
            },
            {
                "WhyChooseheading": "Excellent Customer Support:",
                "WhyChoosedescription": "From booking to the end of your journey, our dedicated support team is here to provide prompt assistance."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What is included in the rental of a non-AC bus?",
            answer: "Our non-AC bus rental comes with comfortable seating and a professional driver. Additional amenities can be arranged based on availability."
        },
        {
            question: "How do I book a non-AC bus with SSKTravels24?",
            answer: "You can easily book by contacting us via phone, email, or through our online booking form. Just provide your travel details, and we’ll help with the reservation process."
        },
        {
            question: "Are there additional charges for extra services?",
            answer: "While our pricing is transparent, extra charges may apply for services such as extended hours or specific requests. We will provide a comprehensive quote before you finalize your booking."
        },
        {
            question: "What is the cancellation policy?",
            answer: "Our cancellation policy varies depending on the timing of the cancellation. Please reach out to us for specific details related to your booking."
        },
        {
            question: "Can I request a specific type of non-AC bus?",
            answer: "Yes, you can request specific bus sizes or configurations based on availability. We offer a variety of options to suit different group sizes."
        },
        {
            question: "Are the drivers experienced and licensed?",
            answer: "Absolutely! All our drivers are highly experienced, licensed, and trained to ensure a safe and enjoyable journey for all passengers."
        },
        {
            question: "Can I make changes to my booking?",
            answer: "Yes, changes to your booking can be accommodated, subject to availability and any applicable fees. Contact our customer service team for assistance."
        },
        {
            question: "What should I do if I need assistance during my journey?",
            answer: "If you require help during your trip, please contact the driver or reach out to our customer service team for immediate assistance."
        },
        {
            question: "How far in advance should I book?",
            answer: "To ensure availability, we recommend booking as early as possible, especially during peak seasons or busy travel periods."
        },
        {
            question: "Are there restrictions on luggage?",
            answer: "Standard luggage allowances apply, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs during your booking."
        }
    ];
    
    const testimonials = [
        {
            name: "Miss Snehal Deshmukh",
            text: "I recently booked a non-AC bus from SSKTravels24 for a school excursion in Pune, and I was thoroughly impressed with the service. The bus was well-maintained and clean, ensuring a comfortable journey for all the students. The driver was punctual and navigated the routes efficiently, making sure we reached our destination safely and on time. Despite being a non-AC bus, the ample ventilation and comfortable seating made the trip pleasant. SSKTravels24 provided excellent value for money, and I highly recommend their non-AC bus rental services for any budget-friendly group travel needs."
        },
        {
            name: "Mr. Ravi Kumar",
            text: "For our family reunion, we needed a non-AC bus to accommodate a large group traveling around Pune. SSKTravels24 offered a fantastic solution. The bus was spacious and in great condition, and the staff ensured everything was up to our expectations. Although it was a non-AC model, the bus had good airflow and was comfortable for the entire trip. The driver was courteous and managed the traffic and routes skillfully, ensuring a smooth journey. I’m very pleased with the service and would certainly use SSKTravels24 again for similar needs."
        }
    ];
    
    const contactData = {
        heading: "Non-AC Bus Rental Service in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000",
        ],
        email: "booking@SSKtravels24.com"
    };
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-15.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PremierNonACBusRentalServiceinPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function PuneLocalbusonrent(){


    const cardData = 
    {
        keyword: 'Local Buses for Rent in Pune',
        heading: 'SSKTravels24: Local Buses for Rent in Pune',
        headingDescription: 'When it comes to finding reliable local bus rental services in Pune, SSKTravels24 stands out as a premier choice. Whether you are planning a corporate event, family reunion, or any group travel, SSKTravels24 offers an exceptional fleet of buses to suit your needs. Here’s a detailed look at the top local buses for rent in Pune provided by SSKTravels24.',
        
         topPlaces : [
            {
                title: "1. Venna Lake",
                description: "A serene spot for boating and picnics, Venna Lake is surrounded by lush greenery and offers paddle boating and horse riding. It’s an ideal location for families and friends to relax and enjoy the scenic views, making it a popular weekend getaway."
            },
            {
                title: "2. Pratapgad Fort",
                description: "This historic fort offers panoramic views of the surrounding valleys and hills. Built in the 17th century, it provides a glimpse into Maratha history with its well-preserved architecture. Visitors can explore the fort's various structures and enjoy trekking trails in the vicinity."
            },
            {
                title: "3. Elephant's Head Point",
                description: "Known for its unique rock formation resembling an elephant's head, this scenic viewpoint is perfect for photography enthusiasts. It offers breathtaking vistas of the Western Ghats and is an excellent spot for a peaceful retreat amidst nature."
            },
            {
                title: "4. Arthur's Seat",
                description: "Dubbed the 'Queen of the Points', Arthur's Seat offers spectacular views of the Konkan coast and the surrounding hills. The viewpoint is famous for its picturesque sunsets, making it a favorite spot for couples and nature lovers alike."
            },
            {
                title: "5. Mapro Garden",
                description: "Famous for its strawberry farms, Mapro Garden is a delightful place to indulge in delicious fruit-based products. Visitors can enjoy fresh strawberry milkshakes, jams, and pastries while exploring the beautiful landscaped gardens."
            },
            {
                title: "6. Lingmala Waterfall",
                description: "A must-visit waterfall for nature lovers, Lingmala Waterfall is surrounded by lush forests and offers a refreshing escape. The cascading water and tranquil environment make it a perfect spot for a picnic or a peaceful hike."
            },
            {
                title: "7. Wilson Point",
                description: "The highest point in Mahabaleshwar, Wilson Point is ideal for witnessing stunning sunrises. With panoramic views of the valleys below, it’s a popular spot for early risers and photographers looking to capture the beauty of the landscape."
            },
            {
                title: "8. Sunset Point",
                description: "A perfect spot to enjoy mesmerizing sunset views over the hills, Sunset Point is known for its vibrant hues as the sun sets behind the mountains. It’s a great place for families and friends to gather and unwind in the evening."
            },
            {
                title: "9. Mahabaleshwar Temple",
                description: "An ancient temple dedicated to Lord Shiva, the Mahabaleshwar Temple is renowned for its intricate carvings and architecture. The temple holds great religious significance and attracts pilgrims and tourists alike, offering a glimpse into the rich cultural heritage of the region."
            },
            {
                title: "10. Bhilar Waterfall",
                description: "A hidden gem known for its peaceful surroundings and serene beauty, Bhilar Waterfall is perfect for those seeking solitude. The cascading water amidst the lush greenery provides a tranquil backdrop for relaxation and nature exploration."
            }
        ],        
        
         services : [
            {
                name: "Luxury Mini Bus (15-20 Seats)",
                description: "Perfect for small groups, our Luxury Mini Bus offers a stylish travel experience with plush seating, air conditioning, and advanced entertainment systems. Ideal for corporate outings, weddings, and family gatherings, ensuring comfort and enjoyment."
            },
            {
                name: "Standard Mini Bus (20-30 Seats)",
                description: "Balancing comfort and affordability, our Standard Mini Bus is equipped with comfortable seating and essential amenities. It’s an excellent choice for school trips, local sightseeing, and small group tours, providing a smooth ride for your adventures."
            },
            {
                name: "Executive Bus (30-40 Seats)",
                description: "For larger groups, our Executive Bus offers spacious seating and high-end features like extra legroom and premium interiors. It’s suited for business meetings, conferences, and group travel within Pune, ensuring a professional and comfortable environment."
            },
            {
                name: "Deluxe Bus (40-50 Seats)",
                description: "Combining spaciousness with comfort, the Deluxe Bus features reclining seats, Wi-Fi, and on-board refreshments. Perfect for longer local trips, including day tours and extended events in and around Pune, it ensures a relaxed travel experience."
            },
            {
                name: "Premium Coach (50-60 Seats)",
                description: "Our Premium Coach is designed for those who seek luxury on a larger scale. With high-quality interiors and ample legroom, it’s ideal for large events, corporate outings, and inter-city tours within Pune, offering a first-class travel experience."
            },
            {
                name: "Executive Luxury Bus (60-70 Seats)",
                description: "Offering an opulent travel environment, the Executive Luxury Bus features reclining seats, personalized air conditioning, and advanced multimedia systems. It’s the go-to option for executive travel and high-profile events, ensuring an upscale experience."
            },
            {
                name: "Economy Bus (30-40 Seats)",
                description: "A budget-friendly option without compromising on comfort, our Economy Bus provides basic amenities and comfortable seating. Suitable for school excursions, local transfers, and casual group outings, it’s perfect for those on a budget."
            },
            {
                name: "Party Bus (20-30 Seats)",
                description: "For a fun and unique travel experience, our Party Bus is equipped with entertainment systems, mood lighting, and comfortable seating. It’s perfect for celebrations, bachelor parties, and group adventures around Pune."
            },
            {
                name: "Tourist Bus (40-50 Seats)",
                description: "Designed for sightseeing, the Tourist Bus features large windows for panoramic views, air conditioning, and comfortable seating. Ideal for guided city tours and leisure activities around Pune, it ensures a memorable exploration of local attractions."
            },
            {
                name: "Shuttle Bus (15-20 Seats)",
                description: "The Shuttle Bus is perfect for short-distance travel and frequent trips. Focused on convenience, it’s ideal for airport transfers, hotel shuttles, and local business commutes, ensuring practical transportation solutions."
            }
        ],        
         tableData : [
            ['- Bus Rental in Pune', '- Bus Hire in Pune'],
            ['- 32, 35, 40, 45, 50 Seater', '- Bus Rental In Pune'],
            ['- Pune Darshan Tempo Traveller on Rent', '- Pune to Sinhagad Fort Mini Bus on Rent'],
            ['- Pune to Jejuri Bus Hire', '- Pune to Ranjangaon Ganpati Bus Hire'],
            ['- Pune to Ketkawale Balaji Temple Bus on Rent', '- Pune to Narayanpur Tempo Traveller on Rent'],
            ['- Pune to Morachi Chincholi Bus on Rent', '- Pune Local Bus on Rent'],
            ['- Pimple Gurav Local Bus on Rent', '- Pune Local Bus Hire for Pune Darshan'],
            ['- Pune Darshan Mini Bus on Rent', '- Pune Local Bus on Rent for One Day'],
            ['- Pune Local Bus on Rent for Local Trips', '- Pune to Jejuri Tempo Traveller on Rent'],
            ['- Pune to Bhimashankar Mini Bus on Rent', '- Bus Hire in Pune']
        ],        
    
         whychoose : [
            {
                "WhyChooseheading": "Exceptional Comfort and Convenience",
                "WhyChoosedescription": "At SSKTravels24, your comfort is our priority. Our fleet includes a variety of options from luxury to standard, ensuring that you travel in style and ease. With spacious seating, air conditioning, and modern amenities, every journey becomes a pleasant experience."
            },
            {
                "WhyChooseheading": "Diverse Fleet of Buses",
                "WhyChoosedescription": "Whether you need a cozy mini-bus for a family outing or a large deluxe bus for corporate events, we have a diverse fleet to meet your needs. Our buses range in size from 32-seater to 50-seater options, ensuring the perfect fit for any group size."
            },
            {
                "WhyChooseheading": "Affordable and Transparent Pricing",
                "WhyChoosedescription": "We offer competitive pricing with no hidden costs. Our rental rates are transparent and tailored to fit your budget, providing great value for your money. Whether it’s a short local trip or a full-day rental, we ensure cost-effectiveness without compromising quality."
            },
            {
                "WhyChooseheading": "Experienced and Professional Drivers",
                "WhyChoosedescription": "Our drivers are skilled, experienced, and trained to provide a safe and smooth travel experience. With in-depth knowledge of local routes and landmarks, they ensure you reach your destination safely and on time."
            },
            {
                "WhyChooseheading": "Reliable and Punctual Service",
                "WhyChoosedescription": "Reliability is at the heart of our services. We understand the importance of punctuality, especially for events and schedules. Our well-maintained buses adhere to strict schedules, ensuring you arrive without delays."
            },
            {
                "WhyChooseheading": "Flexible Rental Options",
                "WhyChoosedescription": "We offer flexible rental options to accommodate your specific needs. Whether you need a bus for a few hours or an entire day, we can customize our services to fit your itinerary and requirements."
            },
            {
                "WhyChooseheading": "Customer-Centric Approach",
                "WhyChoosedescription": "Customer satisfaction is our top priority. From the moment you book with us until the end of your journey, we provide exceptional customer service, addressing any concerns and ensuring a seamless experience."
            },
            {
                "WhyChooseheading": "Hassle-Free Booking Process",
                "WhyChoosedescription": "Our booking process is straightforward and user-friendly. You can easily book your local bus rental through our website or by contacting our customer service team. We offer prompt responses and efficient handling of your requests."
            }
        ]        
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for rent with SSKTravels24?",
            answer: "We offer a range of buses for local rentals, including 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. Mini-buses and tempo travellers for smaller groups are also available. All our vehicles are well-maintained and equipped with modern amenities."
        },
        {
            question: "How can I book a local bus with SSKTravels24?",
            answer: "You can book a local bus by contacting us directly through our website or by phone. Provide us with details such as the date of travel, group size, and destination, and we will assist you in selecting the appropriate bus and finalizing the booking."
        },
        {
            question: "Are the buses equipped with amenities?",
            answer: "Yes, our local buses come with a range of amenities, including comfortable seating, air conditioning, and audio systems. We strive to ensure that your journey is as comfortable and enjoyable as possible."
        },
        {
            question: "What is the rental cost for a local bus?",
            answer: "The rental cost varies depending on factors such as the type of bus, duration of the rental, and distance to be traveled. For an accurate quote, please contact us with your trip details, and we will provide a customized pricing estimate."
        },
        {
            question: "Can I hire a bus for a single day?",
            answer: "Yes, SSKTravels24 offers bus rentals for single-day trips as well as extended periods. Whether you need a bus for a few hours or an entire day, we can accommodate your needs."
        },
        {
            question: "What areas do you cover for local bus rentals?",
            answer: "We provide local bus rentals throughout Pune and its surrounding areas. If you have a specific destination or route in mind, please let us know, and we will ensure our services meet your requirements."
        },
        {
            question: "Are the drivers experienced and professional?",
            answer: "Yes, all our drivers are experienced, professional, and well-versed in local routes. They are committed to providing a safe and pleasant journey for all passengers."
        },
        {
            question: "Can I make changes to my booking after it has been confirmed?",
            answer: "If you need to make changes to your booking, please contact us as soon as possible. We will do our best to accommodate any adjustments based on availability and your new requirements."
        },
        {
            question: "What safety measures are in place for passengers?",
            answer: "We prioritize passenger safety by maintaining our buses to the highest standards and ensuring that all safety protocols are followed. Our drivers are trained to handle various situations and ensure a safe journey for everyone on board."
        },
        {
            question: "Is there a minimum rental duration for local bus hires?",
            answer: "While there is no strict minimum rental duration, we generally recommend booking for a minimum of 4-6 hours to ensure availability and effectively meet your travel needs. For specific details, please contact us."
        }
    ];
    
    const testimonials = [
        {
            name: "Mrs. Anjali Deshmukh",
            text: "I recently rented a 35-seater mini bus from SSKTravels24 for a family outing in Pune, and I couldn’t be more pleased with the service. The booking process was seamless, and the staff were incredibly helpful and responsive. The bus itself was spotless, comfortable, and equipped with everything we needed for a day trip. Our driver, Mr. Singh, was professional, punctual, and knowledgeable about the local area, making our journey smooth and enjoyable. I highly recommend SSKTravels24 for anyone in need of reliable and comfortable bus rental in Pune. They truly made our outing a memorable one!"
        },
        {
            name: "Mr. Rakesh Kulkarni",
            text: "Our company recently hired a 50-seater deluxe bus from SSKTravels24 for an off-site corporate event in Pune. The experience was fantastic from start to finish. The bus was luxurious, with ample space and all the modern amenities, which made the trip extremely comfortable for our team. The booking process was hassle-free, and the driver, Mr. Rao, was courteous and efficient. We were impressed by the level of professionalism and the attention to detail in ensuring everything went smoothly. For anyone looking for top-notch bus rental services in Pune, SSKTravels24 is the way to go. We’ll definitely be using their services again!"
        }
    ];
    
    
    const contactData = {
        heading: "Pune Local Bus on Rent Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune Local Bus on Rent | Affordable Local Bus Services</title>
  <meta name="description" content="Hire local buses in Pune for various trips, including Pune Darshan and excursions to nearby temples and forts." />
  <meta name="keywords" content="Bus Rental in Pune | Bus Hire in Pune | 32,35,40,45,50 Seater | Pune Darshan Tempo Traveller on Rent | Pune to Sinhagad Fort Mini Bus on Rent | Pune to Jejuri Bus Hire | Pune to Ranjangaon Ganpati Bus Hire | Pune to Ketkawale Balaji Temple Bus on Rent | Pune to Narayanpur Tempo Traveller on Rent | Pune to Morachi Chincholi Bus on Rent | Pune Local Bus on Rent | Pimple Gurav Local Bus on Rent | Pune Local Bus Hire for Pune Darshan | Pune Darshan Mini Bus on Rent | Pune Local Bus on Rent for One Day | Pune Local Bus on Rent for Local Trips | Pune to Jejuri Tempo Traveller on Rent | Pune to Bhimashankar Mini Bus on Rent" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune Local Bus on Rent | Affordable Local Bus Services" />
  <meta property="og:description" content="Discover reliable local bus rental services in Pune for various trips and excursions. Book now for a comfortable journey!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-local-bus-rent" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//local-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-07.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs: Pune Local Bus on Rent for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default PuneLocalbusonrent;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function SedanCabsBookinginPune(){


    const cardData = 
    {
        keyword: 'Sedan Cabs Booking in Pune',
        heading: 'SSKTravels24: Sedan Cabs Booking in Pune',
        headingDescription: 'When it comes to convenient and comfortable transportation in Pune, sedan cabs are a popular choice among travelers. At SSKTravels24, we offer an exceptional sedan cab booking experience tailored to meet your needs. Discover the top reasons why choosing sedan cabs with SSKTravels24 is a smart decision.',
        
        topPlaces: [
          {
            title: "1. Comfortable Travel Experience",
            description: "Sedan cabs are designed with spacious interiors and comfortable seating, ensuring a relaxing journey whether you’re commuting to work or exploring the city."
          },
          {
            title: "2. Affordability",
            description: "Sedan cabs offer competitive pricing, making them a budget-friendly option for both short and long-distance travel. With SSKTravels24, you get the best rates without compromising on quality."
          },
          {
            title: "3. Professional Drivers",
            description: "Our drivers are experienced and well-trained, ensuring you receive top-notch service. They know the city routes well, making your journey efficient and hassle-free."
          },
          {
            title: "4. Convenient Booking Process",
            description: "Booking a sedan cab with SSKTravels24 is easy and quick. Our user-friendly online platform allows you to book your ride in just a few clicks."
          },
          {
            title: "5. Wide Range of Sedans",
            description: "We offer a variety of sedan options to choose from, catering to different preferences and group sizes. Whether you need a compact sedan or a luxury option, we have you covered."
          },
          {
            title: "6. Safety and Security",
            description: "Your safety is our priority. Our sedan cabs are regularly maintained, and we follow strict hygiene protocols to ensure a safe travel environment for our passengers."
          },
          {
            title: "7. Flexibility",
            description: "With SSKTravels24, you can book sedan cabs for various purposes, including airport transfers, corporate events, or leisure trips. We offer flexible rental options to suit your needs."
          },
          {
            title: "8. Punctuality",
            description: "We understand the importance of time. Our sedan cabs are known for their punctuality, ensuring you arrive at your destination on time, every time."
          },
          {
            title: "9. Local Knowledge",
            description: "Our drivers possess in-depth knowledge of Pune’s traffic patterns and local attractions. They can provide valuable insights and recommendations for a better travel experience."
          },
          {
            title: "10. 24/7 Availability",
            description: "Whether you need a ride during the day or late at night, our sedan cabs are available 24/7. You can rely on SSKTravels24 for your transportation needs at any time."
          }
        ],
        
        services: [
          {
            name: "Sedan Cabs for Airport Transfers",
            description: "Enjoy hassle-free airport transfers with our reliable sedan cab service, ensuring you reach your destination comfortably and on time."
          },
          {
            name: "Sedan Cabs for Corporate Events",
            description: "Book our sedan cabs for corporate events, providing a professional and comfortable transportation option for your team."
          },
          {
            name: "Sedan Cabs for Outstation Travel",
            description: "Planning a trip out of Pune? Our sedan cab service for outstations is perfect for long journeys to destinations like Nashik, Lonavala, and Goa."
          },
          {
            name: "Toyota Etios Cab in Pune",
            description: "For a reliable and economical option, choose our Toyota Etios cab in Pune, known for its spacious interiors and fuel efficiency."
          },
          {
            name: "Sedan Taxi Service for Leisure Trips",
            description: "Enjoy a leisurely trip around Pune or nearby attractions with our comfortable sedan taxi service, perfect for families and friends."
          },
          {
            name: "24/7 Sedan Cab Booking",
            description: "Our sedan cab service is available 24/7 for all your transportation needs, ensuring you have access to a ride whenever you need it."
          },
          {
            name: "Pune to Mumbai Cab Services",
            description: "Choose our cab services for seamless travel between Pune and Mumbai, focusing on punctuality and comfort."
          },
          {
            name: "Contact Number for Sedan Cabs Booking in Pune",
            description: "For prompt and efficient sedan cab booking in Pune, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['Sedan Cabs in Pune', 'Best Cab Service in Pune'],
            ['Sedan Taxi Near Me in Pune', 'Sedan Cab Service Near Me in Pune'],
            ['Sedan Cab Service for Outstations', 'Toyota Etios Taxi in Pune'],
            ['Toyota Etios Cab in Pune', 'Taxi & Sedan Services Booking'],
            ['Sedan Taxi Service for Outstations', 'Pune to Nashik Sedan Cab Service'],
            ['Pune to Goa Cab Service', 'Pune to Mumbai Cab Services'],
            ['Pune to Lonavala Sedan Cab', 'Outstation Sedan Cab Service'],
            ['Sedan Cabs for Corporate Outstation', 'Pune to Shirdi Sedan Cabs'],
            ['Pune to Aurangabad Sedan Taxi', '']
          ],
          
          whychoose: [
            {
              WhyChooseheading: "Why Choose Us for Sedan Cabs Booking in Pune – SSKTravels24?",
              WhyChoosedescription: "Reliability and Comfort: At SSKTravels24, we prioritize your comfort and safety. Our fleet of well-maintained sedan cabs ensures a smooth ride, whether you're traveling for business or leisure."
            },
            {
              WhyChooseheading: "Professional Drivers:",
              WhyChoosedescription: "Our experienced drivers are trained to provide excellent service while ensuring your journey is safe and enjoyable. They know the best routes to help you avoid traffic and reach your destination on time."
            },
            {
              WhyChooseheading: "Transparent Pricing:",
              WhyChoosedescription: "We offer competitive rates with no hidden charges. You can trust that the price you see is the price you pay, making budgeting for your travel easy and straightforward."
            },
            {
              WhyChooseheading: "Easy Booking Process:",
              WhyChoosedescription: "Our user-friendly online booking system allows you to reserve your sedan cab in just a few clicks. Whether you need a ride in advance or last minute, we’ve got you covered."
            },
            {
              WhyChooseheading: "Flexible Options:",
              WhyChoosedescription: "Whether you need a cab for a few hours, a full day, or an outstation trip, we provide flexible rental options tailored to your needs. Choose from hourly, daily, or long-term rentals."
            },
            {
              WhyChooseheading: "24/7 Availability:",
              WhyChoosedescription: "Our sedan cab services are available around the clock. No matter when you need a ride, we are here to serve you, ensuring you never feel stranded."
            },
            {
              WhyChooseheading: "Customer Support:",
              WhyChoosedescription: "Our dedicated customer support team is always ready to assist you with any queries or concerns. We're committed to making your travel experience as smooth as possible."
            },
            {
              WhyChooseheading: "Variety of Sedans:",
              WhyChoosedescription: "Choose from a range of sedan models, including Toyota Etios, Honda City, and more, catering to different preferences and group sizes."
            },
            {
              WhyChooseheading: "Corporate Packages:",
              WhyChoosedescription: "We offer specialized packages for corporate clients, providing reliable transportation for meetings, events, and more, all while maintaining a professional appearance."
            },
            {
              WhyChooseheading: "Positive Customer Feedback:",
              WhyChoosedescription: "With numerous satisfied customers, SSKTravels24 has built a reputation for delivering quality service. Check our testimonials to see why so many people trust us for their transportation needs."
            }
          ]
    };    

    
   
    const faqData = [
        {
          question: "How do I book a sedan cab with SSKTravels24?",
          answer: "You can book a sedan cab easily through our website or mobile app. Simply enter your pickup and drop-off locations, select your preferred vehicle, and confirm your booking."
        },
        {
          question: "What types of sedans are available for booking?",
          answer: "We offer a variety of sedans, including popular models like the Toyota Etios and Honda City, to meet your travel needs."
        },
        {
          question: "Are there any additional charges for outstation trips?",
          answer: "Yes, additional charges may apply for outstation trips, including tolls and state taxes. However, we provide transparent pricing with no hidden fees."
        },
        {
          question: "Can I book a cab for a specific time?",
          answer: "Absolutely! You can schedule your booking for a specific date and time, ensuring the cab is ready when you need it."
        },
        {
          question: "What if I need to cancel or modify my booking?",
          answer: "You can easily modify or cancel your booking through our website or by contacting our customer support team. Please check our cancellation policy for details."
        },
        {
          question: "Is there a waiting charge if my flight is delayed?",
          answer: "We understand that delays can happen. Our drivers will wait for a reasonable period without any extra charge. However, please inform us of your situation."
        },
        {
          question: "Are your drivers experienced and verified?",
          answer: "Yes, all our drivers are experienced and undergo a thorough background check for your safety and peace of mind."
        },
        {
          question: "Do you offer corporate travel services?",
          answer: "Yes, we provide tailored packages for corporate clients, including transportation for meetings, events, and regular employee travel."
        },
        {
          question: "What payment methods do you accept?",
          answer: "We accept various payment methods, including cash, credit/debit cards, and online payment options for your convenience."
        }
      ];
      
      const testimonials = [
        {
          name: "Mr. Anil Sharma",
          text: "I recently booked a sedan cab through SSKTravels24 for a trip from Pune to Nashik. The process was incredibly smooth, and the driver was punctual and professional. The Toyota Etios was comfortable and well-maintained, making our journey enjoyable. I highly recommend their services for anyone needing reliable transportation!"
        },
        {
          name: "Miss Priya Kapoor",
          text: "SSKTravels24 made my business trip seamless! I booked a sedan for a week for meetings around Pune, and the service was outstanding. The drivers were courteous and knowledgeable about the city, which helped me navigate easily. I’ll definitely use their cab service again for future trips!"
        }
      ];
      
      const contactData = {
        heading: "Contact Us for Sedan Cabs Booking in Pune",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
      };
      
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Sedan Cabs Booking in Pune | Affordable Outstation Taxi Service</title>
  <meta name="description" content="Book sedan cabs in Pune for local travel and outstation trips. Enjoy reliable service with SSK Travels24 for all your transportation needs." />
  <meta name="keywords" content="sedan cabs in Pune, best cab service in Pune, sedan taxi service, Pune to Nashik sedan cab, outstation sedan cab service" />
  <meta property="og:title" content="Sedan Cabs Booking in Pune | SSK Travels24" />
  <meta property="og:description" content="Explore Pune and beyond with our sedan cab services. Perfect for corporate travel and family trips. Book your ride today!" />
  <meta property="og:url" content="https://https://ssktravels24.com//sedan-cabs-booking-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//sedan-cab-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\sedan.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default SedanCabsBookinginPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetomumbaidarshan(){


    const cardData = 
    {
        keyword: 'Pune to Mumbai Darshan Bus on Rent',
        heading: 'SSKTravels24: Pune to Mumbai Darshan Bus on Rent',
        headingDescription: 'Traveling from Pune to Mumbai is a fantastic way to experience the vibrant pulse of one of India’s most dynamic cities. At SSKTravels24, we specialize in providing exceptional bus rental services for your Mumbai Darshan adventure. Our modern, well-maintained buses ensure a comfortable and enjoyable journey, while our experienced drivers guarantee a smooth and safe trip.',
        
        topPlaces : [
            {
                title: "1. Gateway of India",
                description: "This monumental structure overlooking the Arabian Sea symbolizes Mumbai’s grandeur and serves as a popular starting point for exploring the city’s rich history and vibrant culture."
            },
            {
                title: "2. Marine Drive",
                description: "Often referred to as the Queen's Necklace, Marine Drive offers a picturesque promenade along the coastline. It’s an ideal spot for evening walks, enjoying the sea breeze, and capturing breathtaking sunset views."
            },
            {
                title: "3. Chhatrapati Shivaji Maharaj Terminus",
                description: "A UNESCO World Heritage Site, this grand railway station showcases Victorian Gothic Revival architecture. It represents Mumbai’s colonial past and is a must-visit for its stunning design."
            },
            {
                title: "4. Elephanta Caves",
                description: "Located on Elephanta Island, these ancient rock-cut temples are dedicated to Lord Shiva. Renowned for their intricate sculptures, these caves have also been designated as a UNESCO World Heritage Site."
            },
            {
                title: "5. Juhu Beach",
                description: "A popular destination for locals and tourists alike, Juhu Beach is known for its lively atmosphere and delicious street food. It’s perfect for relaxing by the sea or taking a leisurely stroll at sunset."
            },
            {
                title: "6. Haji Ali Dargah",
                description: "Situated on an islet off the coast of Worli, Haji Ali Dargah is a revered pilgrimage site known for its stunning architecture and serene environment. It attracts visitors from all over."
            },
            {
                title: "7. Siddhivinayak Temple",
                description: "This famous Ganesh temple is one of Mumbai's most visited religious sites. Devotees come here seeking blessings from Lord Ganesha, making it an important cultural and spiritual landmark."
            },
            {
                title: "8. Colaba Causeway",
                description: "A bustling shopping street, Colaba Causeway is famous for its vibrant markets, eclectic mix of shops, and diverse eateries. It’s a great place to experience Mumbai’s lively street life and shop for unique items."
            },
            {
                title: "9. Chor Bazaar",
                description: "Known as the 'Thieves Market,' Chor Bazaar is a treasure trove of antiques and vintage goods. It offers a unique shopping experience with its wide range of collectibles and rare items."
            },
            {
                title: "10. Bandra-Worli Sea Link",
                description: "This impressive bridge connects Bandra and Worli, offering stunning views of the city’s skyline and the Arabian Sea. It’s a modern engineering marvel and provides a scenic drive between the two areas."
            }
        ],
        
        services : [
            {
                name: "Pune to Mumbai Darshan Bus on Rent",
                description: "Experience a comfortable and convenient journey with SSKTravels24's bus rental services for your Pune to Mumbai Darshan. Our fleet of modern buses is perfect for exploring Mumbai's iconic landmarks, ensuring a hassle-free trip with professional drivers and well-maintained vehicles."
            },
            {
                name: "Pune to Mumbai International Airport Mini Bus on Rent",
                description: "Travel with ease from Pune to Mumbai International Airport with our mini bus rental services. Perfect for groups and families, our mini buses offer comfort and convenience for airport transfers."
            },
            {
                name: "Pune to Navi Mumbai Bus on Rent",
                description: "Choose SSKTravels24 for dependable and comfortable bus rental service from Pune to Navi Mumbai. Our buses are equipped to handle long journeys with ease."
            },
            {
                name: "Pune to Thane Bus on Rent",
                description: "Explore Thane with our specialized bus rental services from Pune. Ideal for group travel, our buses provide a comfortable ride with modern amenities."
            },
            {
                name: "Pune to Mumbai Airport Urbania Bus on Rent",
                description: "Opt for SSKTravels24 for your Pune to Mumbai Airport Urbania bus rental needs. Enjoy a luxurious and comfortable experience for airport transfers."
            },
            {
                name: "Pune to Mumbai Airport Drop Bus on Rent",
                description: "Ensure a smooth airport drop-off with our bus rental service. Our buses are perfect for groups needing transportation from Pune to Mumbai Airport."
            },
            {
                name: "Pune to Mumbai Domestic Airport Bus on Rent",
                description: "Traveling to Mumbai Domestic Airport from Pune? Choose SSKTravels24 for a comfortable and efficient bus rental service."
            },
            {
                name: "Pune to Panvel Bus on Rent",
                description: "For trips from Pune to Panvel, SSKTravels24 offers reliable and comfortable bus rental services, ideal for both short and long-distance travel."
            },
            {
                name: "Pune to Mumbai Round Trip Bus on Rent",
                description: "Plan a round trip from Pune to Mumbai with ease using our bus rental services. Our buses are equipped to provide comfort and convenience."
            },
            {
                name: "Pune to Mumbai Events Bus on Rent",
                description: "Make your events in Mumbai memorable with SSKTravels24's bus rental services. Ideal for corporate events, weddings, and gatherings."
            },
            {
                name: "Pune to Dadar Mini Bus on Rent",
                description: "Traveling to Dadar from Pune? We provide mini bus rental services to ensure a comfortable journey."
            },
            {
                name: "Pune to Bandra Terminus Bus on Rent",
                description: "SSKTravels24 provides reliable bus rental services for traveling from Pune to Bandra Terminus."
            },
            {
                name: "Pune to Mumbai Tempo Traveller Hire",
                description: "Consider hiring a tempo traveller for a comfortable and flexible travel option between Pune and Mumbai."
            },
            {
                name: "Pune to Mumbai Darshan Mini Bus on Rent",
                description: "Explore Mumbai’s top attractions with our mini bus rental service for Mumbai Darshan from Pune."
            },
            {
                name: "Pimpri to Mumbai Darshan Mini Bus on Rent",
                description: "For those traveling from Pimpri to Mumbai for a Darshan tour, we offer mini bus rentals for your needs."
            },
            {
                name: "Pune Railway Station to Mumbai Darshan Mini Bus on Rent",
                description: "If you’re arriving at Pune Railway Station, we provide mini bus rentals for convenient transportation to Mumbai."
            },
            {
                name: "Pune to Mumbai Darshan Package",
                description: "Experience Mumbai’s landmarks and cultural sites with our dedicated Darshan package, ensuring a hassle-free tour."
            },
            {
                name: "Pune to Mumbai Darshan Urbania on Rent",
                description: "For a premium travel experience, consider our Urbania package for Pune to Mumbai Darshan, providing a luxurious sightseeing tour."
            },
            {
                name: "Pune to Mumbai Darshan Bus On Rent Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Pune to Mumbai Darshan Bus On Rent."
            }
        ],
        tableData : [
            ['Pune to Mumbai Darshan Bus on Rent', 'Pune to Mumbai International Airport Mini Bus on Rent'],
            ['Pune to Thane Bus on Rent', 'Pune to Mumbai Airport Urbania Bus on Rent'],
            ['Pune to Mumbai Airport Drop Bus on Rent', 'Pune to Mumbai Domestic Airport Bus on Rent'],
            ['Pune to Panvel Bus on Rent', 'Pune to Mumbai Round Trip Bus on Rent'],
            ['Pune to Mumbai Events Bus on Rent', 'Pune to Dadar Mini Bus on Rent'],
            ['Pune to Bandra Terminus Bus on Rent', 'Pune Mumbai Tempo Traveller Hire'],
            ['Pune to Mumbai Darshan Mini Bus on Rent', 'Pimpri to Mumbai Darshan Mini Bus on Rent'],
            ['Pune Railway Station to Mumbai Darshan Mini Bus on Rent', 'Pune to Mumbai Darshan Package'],
            ['Pune to Mumbai Darshan Urbania on Rent', '']
        ],        
    
        whychoose :[
            {
                "WhyChooseheading": "Why Choose Us for Pune to Mumbai Darshan – SSKTravels24",
                "WhyChoosedescription": "Comfort and Convenience: Our modern, well-maintained buses are equipped with comfortable seating, air conditioning, and ample legroom, ensuring your journey from Pune to Mumbai is enjoyable."
            },
            {
                "WhyChooseheading": "Reliable Service:",
                "WhyChoosedescription": "Punctuality is our commitment. Our professional drivers are trained to provide reliable and efficient service, keeping your schedule on track."
            },
            {
                "WhyChooseheading": "Experienced Drivers:",
                "WhyChoosedescription": "Our knowledgeable drivers prioritize your safety and comfort, ensuring a smooth journey so you can focus on enjoying your trip."
            },
            {
                "WhyChooseheading": "Customizable Options:",
                "WhyChoosedescription": "We offer flexible rental options tailored to your needs, whether it's a day trip or an extended visit."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "We provide high-quality bus rental services at competitive prices, with a transparent pricing structure and no hidden costs."
            },
            {
                "WhyChooseheading": "Comprehensive Support:",
                "WhyChoosedescription": "Our dedicated customer service team is here to assist with bookings, itinerary planning, and special requests, making your journey hassle-free."
            },
            {
                "WhyChooseheading": "Safety First:",
                "WhyChoosedescription": "We adhere to all safety regulations and conduct regular maintenance checks on our buses to ensure a secure travel experience."
            },
            {
                "WhyChooseheading": "Comfortable Amenities:",
                "WhyChoosedescription": "Enjoy amenities like Wi-Fi, entertainment systems, and refreshments on our luxury buses for a relaxing trip."
            },
            {
                "WhyChooseheading": "Clean and Well-Maintained Vehicles:",
                "WhyChoosedescription": "Our buses are thoroughly cleaned before each journey, providing a fresh and hygienic environment for all passengers."
            },
            {
                "WhyChooseheading": "Positive Customer Feedback:",
                "WhyChoosedescription": "We pride ourselves on delivering excellent service, and our positive reviews reflect our commitment to quality and customer satisfaction."
            }
        ]        
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for rent for the Pune to Mumbai Darshan?",
            answer: "We offer a variety of buses including luxury coaches, standard buses, and mini-buses. Each option is designed to provide maximum comfort and convenience based on your needs."
        },
        {
            question: "How do I book a bus for Pune to Mumbai Darshan?",
            answer: "You can easily book a bus by contacting our customer service team via phone or email. Alternatively, visit our website to fill out the booking form for a quick and easy reservation."
        },
        {
            question: "Are there any additional costs apart from the rental fee?",
            answer: "Our pricing is transparent, with no hidden costs. Additional expenses may include fuel charges, tolls, and parking fees if applicable. We provide a detailed quote upfront so you are aware of all costs involved."
        },
        {
            question: "Can I customize the itinerary for the trip?",
            answer: "Yes, we offer customizable options for your itinerary. Discuss your travel plans with our team, and we will work together to create a schedule that fits your needs."
        },
        {
            question: "What amenities are provided on the bus?",
            answer: "Depending on the bus type, amenities may include air conditioning, comfortable seating, Wi-Fi, entertainment systems, and refreshments. Let us know your preferences when booking."
        },
        {
            question: "How far in advance should I book the bus?",
            answer: "We recommend booking your bus as early as possible to ensure availability, especially during peak travel seasons. Booking at least a few weeks in advance is ideal."
        },
        {
            question: "What is the cancellation policy?",
            answer: "Our cancellation policy varies depending on the booking terms. Generally, cancellations made within a specified period before the departure date may incur a cancellation fee. Please refer to our booking terms or contact our team for specific details."
        },
        {
            question: "Are your buses equipped with safety features?",
            answer: "Yes, all our buses are equipped with essential safety features including seat belts and first aid kits, and are regularly maintained to ensure a safe journey for all passengers."
        },
        {
            question: "Can I make changes to my booking after confirmation?",
            answer: "Yes, changes can be made to your booking based on availability. Please contact our customer service team as soon as possible to discuss any modifications."
        },
        {
            question: "How can I contact SSKTravels24 for more information?",
            answer: "You can reach us through our customer service phone line, email, or by visiting our website. Our team is always available to answer your questions and assist with your booking."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Ravi Sharma",
            text: "Our group recently hired a bus from SSKTravels24 for a Pune to Mumbai Darshan, and the experience was exceptional. The bus was immaculate and equipped with comfortable seating and modern amenities, making our journey smooth and enjoyable. The driver was punctual and knowledgeable about the route, ensuring we arrived on time at each of our destinations. The booking process was easy, and the customer service was outstanding. We highly recommend SSKTravels24 for anyone seeking reliable and comfortable bus rental services."
        },
        {
            name: "Miss Anjali Deshmukh",
            text: "Choosing SSKTravels24 for our Pune to Mumbai Darshan trip was a fantastic decision. The luxury bus we rented was spacious and well-maintained, with all the amenities we needed for a comfortable ride. The driver was professional, navigating the busy Mumbai traffic with ease, allowing us to focus on our sightseeing. The staff at SSKTravels24 was incredibly helpful throughout the booking process, making everything hassle-free. We had a wonderful experience and will definitely use their services again for our future trips."
        }
    ];
    
    
    const contactData = {
        heading: "Pune to Mumbai Darshan Bus On Rent Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai Darshan Bus On Rent | Explore the City</title>
  <meta name="description" content="Book your Pune to Mumbai Darshan bus rental, including airport transfers and sightseeing tours. Reliable and comfortable transportation options available." />
  <meta name="keywords" content="Pune to Mumbai Darshan Bus on Rent, Pune to Mumbai Airport Mini Bus on Rent, Pune to Navi Mumbai Bus On Rent, Pune to Thane Bus on Rent, Pune to Mumbai Round Trip Bus on Rent, Pune to Mumbai Events Bus on Rent" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Mumbai Darshan Bus On Rent | Explore the City" />
  <meta property="og:description" content="Reliable bus services for your Pune to Mumbai Darshan needs, including mini buses and Urbania rentals. Perfect for events, airport transfers, and more." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-mumbai-darshan-bus-on-rent" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//mumbai-darshan-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Correction Keyword images-09.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Pune to Mumbai Darshan Bus on Rent - SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetomumbaidarshan;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busserviceforcorporate(){


    const cardData = 
    {
        keyword: 'Bus Services for Corporate Events in Pune',
        heading: 'Bus Services for Corporate Events in Pune',
        headingDescription: 'When it comes to corporate events, reliable transportation for your team, clients, or partners is essential for ensuring smooth operations. SSKTravels24 offers top-notch bus services specifically tailored for corporate events in Pune. Whether you’re organizing a conference, team-building activity, or business retreat, our diverse fleet and professional services are designed to meet all your corporate transportation needs. Here’s a look at the top bus services provided by SSKTravels24 for corporate functions in Pune:',
        
        topPlaces : [
            {
                title: "1. Luxury Executive Buses",
                description: "Our luxury executive buses are perfect for high-profile corporate events where comfort and style matter. Equipped with plush seating, Wi-Fi, and entertainment systems, these buses provide a premium travel experience for your executives and clients."
            },
            {
                title: "2. Standard Corporate Coaches",
                description: "For more traditional corporate events, our standard corporate coaches offer spacious seating, air conditioning, and ample legroom. These coaches are ideal for transporting large groups to conferences, seminars, or workshops."
            },
            {
                title: "3. Shuttle Services",
                description: "Our shuttle services are designed for efficient transportation within the city. Whether you need to move employees between different office locations or transport guests from hotels to event venues, our shuttle services ensure timely and reliable travel."
            },
            {
                title: "4. Mini Buses",
                description: "Perfect for smaller corporate groups, our mini buses offer the flexibility and convenience needed for various corporate outings. These buses come with comfortable seating and are suitable for local travel or short trips."
            },
            {
                title: "5. Executive Mini Coaches",
                description: "For medium-sized corporate groups, our executive mini coaches provide a balance of comfort and efficiency. With amenities like adjustable seating, climate control, and modern interiors, these coaches are well-suited for business trips and client transportation."
            },
            {
                title: "6. VIP Charter Buses",
                description: "When you need to make a lasting impression, our VIP charter buses offer the ultimate in luxury and sophistication. Featuring high-end interiors, personalized services, and advanced amenities, these buses are ideal for high-profile events and important client meetings."
            },
            {
                title: "7. Conference Buses",
                description: "Our conference buses are equipped with features to facilitate on-the-go meetings and discussions. With built-in AV equipment, spacious work areas, and comfortable seating, these buses ensure that productivity is maintained even during transit."
            },
            {
                title: "8. Employee Transport Buses",
                description: "For regular employee transportation, our employee transport buses provide a cost-effective and reliable solution. These buses are designed for daily commutes or scheduled office pick-ups and drop-offs, ensuring that your team arrives on time and refreshed."
            },
            {
                title: "9. Event Shuttle Buses",
                description: "Tailored for large-scale corporate events, our event shuttle buses can handle high volumes of passengers with ease. These buses are perfect for transporting attendees to and from trade shows, conventions, and corporate gatherings."
            },
            {
                title: "10. Customizable Corporate Packages",
                description: "We offer customizable packages to meet your specific corporate transportation needs. From flexible scheduling to bespoke amenities, our packages are designed to align with your event requirements and budget."
            }
        ],
        
        services : [
            {
                name: "Corporate Cab Services in Viman Nagar",
                description: "Viman Nagar is a key business district, and we offer tailored corporate cab services to cater to its demands. Our services include punctual pick-ups and drop-offs, high-end vehicles for executive transport, and flexible scheduling to accommodate your corporate requirements."
            },
            {
                name: "Corporate Cab Services in Hinjewadi",
                description: "As a major IT and business hub, Hinjewadi requires reliable transportation solutions. Our corporate cab services in Hinjewadi are designed to provide efficient and comfortable travel for your business needs."
            },
            {
                name: "Corporate Cab Services in Baner and Aundh",
                description: "Baner and Aundh are prominent business areas in Pune, and we offer specialized corporate cab services for these localities. Our services include executive cabs for high-profile meetings, shuttle services for large groups, and flexible options to suit your scheduling needs."
            },
            {
                name: "Bus Service for Corporate in Pimpri Chinchwad",
                description: "Looking for bus services for corporate events in Pimpri Chinchwad? SSKTravels24 provides efficient and reliable transportation solutions tailored to corporate needs. Whether you need to transport employees to an event, seminar, or daily commute, our buses are well-equipped to ensure comfort and punctuality."
            },
            {
                name: "Tempo Traveller on Rent in Pune for Corporate Events",
                description: "SSKTravels24 offers tempo travellers on rent in Pune for corporate events, providing the perfect solution for group transportation. Our tempo travellers are spacious and comfortable, ideal for corporate outings, team-building events, and client shuttles."
            },
            {
                name: "Mini Bus on Rent for Corporate Events",
                description: "Looking for a mini bus on rent for corporate events? SSKTravels24 provides mini buses that are ideal for corporate gatherings, team outings, and special events. Our mini buses are well-maintained, comfortable, and equipped with amenities to ensure a pleasant journey."
            },
            {
                name: "Contact Number for Corporate Services in Pune",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Bus Service for Corporate Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
         tableData : [
            ['- Bus Service for Corporate Office Pune', '- Bus Service for Corporate Office Picnic'],
            ['- Bus Service for Corporate Outing Pune', '- Mini Bus for Corporate Outing'],
            ['- AC / Non-AC Bus for Corporate Events', '- 17, 26, 32, 35, 45 Seater Bus for Corporate Outing'],
            ['- Employee Transportation Services in Pune', '- Bus On Rent For Corporate in Pune'],
            ['- Corporate Cab Services in Hadapsar', '- Corporate Cab Services in Viman Nagar'],
            ['- Corporate Cab Services in Hinjewadi', '- Corporate Cab Services in Baner Aundh'],
            ['- Bus Rental for Corporate Events', '- Bus Service for Corporate in Pimpri Chinchwad'],
            ['- Bus Service for Corporate in Pimpri Chinchwad Contact Number', '- Tempo Traveller on Rent in Pune for Corporate Events']
        ],
        
    
        whychoose : [
            {
                "WhyChooseheading": "Diverse Fleet:",
                "WhyChoosedescription": "Our extensive fleet includes a range of vehicles, from standard buses to luxury coaches, accommodating groups of all sizes and ensuring the right fit for your corporate needs."
            },
            {
                "WhyChooseheading": "Modern Amenities:",
                "WhyChoosedescription": "We equip our buses with top-notch amenities such as comfortable seating, air conditioning, Wi-Fi, and entertainment systems, ensuring a pleasant and productive travel experience for your team."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our drivers are highly trained professionals committed to safety and punctuality. They provide reliable and courteous service, enhancing the overall travel experience for your corporate events."
            },
            {
                "WhyChooseheading": "Tailored Solutions:",
                "WhyChoosedescription": "We offer customized transportation solutions to meet the specific requirements of your corporate functions, including employee shuttles, client transport, and large-scale events."
            },
            {
                "WhyChooseheading": "Reliability:",
                "WhyChoosedescription": "Punctuality and dependability are our top priorities. We ensure that your transportation needs are met without any disruptions, helping you stick to your schedule."
            },
            {
                "WhyChooseheading": "Safety and Cleanliness:",
                "WhyChoosedescription": "Our buses undergo regular maintenance and rigorous cleaning protocols to ensure the highest standards of safety and hygiene for your passengers."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "We provide transparent and competitive pricing, offering excellent value for money. Our flexible packages cater to different budgets and event sizes."
            },
            {
                "WhyChooseheading": "24/7 Support:",
                "WhyChoosedescription": "Our dedicated customer service team is available around the clock to assist with bookings, inquiries, and any issues, ensuring a smooth and hassle-free experience."
            },
            {
                "WhyChooseheading": "Experience and Expertise:",
                "WhyChoosedescription": "With years of experience in corporate transport, we understand the unique needs of businesses and deliver services that align with professional standards."
            },
            {
                "WhyChooseheading": "Positive Feedback:",
                "WhyChoosedescription": "Our clients consistently praise our services for their efficiency, reliability, and professionalism. The positive feedback we receive reflects our commitment to exceptional service."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for corporate events?",
            answer: "We offer a variety of buses for corporate events, including standard buses, luxury coaches, and mini buses. Each option comes with modern amenities to suit your specific needs."
        },
        {
            question: "How can I book a bus for a corporate event?",
            answer: "You can book a bus for your corporate event through our website, mobile app, or by contacting our customer support team. Our booking process is simple and efficient, designed for your convenience."
        },
        {
            question: "What is the pricing structure for corporate bus services?",
            answer: "Pricing varies based on the type of bus, duration of service, and any additional requirements. For detailed pricing, please contact our customer support team or use our online booking system for an estimate."
        },
        {
            question: "Are there any additional charges for corporate bus services?",
            answer: "While our pricing is transparent, additional charges may apply for extra services such as extended rental periods, additional stops, or special requests. We ensure clear communication of any extra costs."
        },
        {
            question: "How long can I book the bus for a corporate event?",
            answer: "You can book the bus for as long as needed, from a few hours to multiple days. We offer flexible rental options to accommodate the duration of your corporate event."
        },
        {
            question: "Can I customize the bus service for my corporate needs?",
            answer: "Yes, we offer customizable bus services to meet your specific requirements. Whether you need specific amenities, routes, or schedules, we can tailor the service to fit your needs."
        },
        {
            question: "Are the buses equipped with amenities for a comfortable journey?",
            answer: "Yes, our buses come equipped with amenities such as air conditioning, comfortable seating, Wi-Fi, and entertainment systems. We aim to provide a comfortable and enjoyable travel experience."
        },
        {
            question: "Is the bus service available 24/7?",
            answer: "Yes, our bus services are available 24/7 to accommodate events and schedules at any time of the day or night."
        },
        {
            question: "What measures are taken to ensure the safety and cleanliness of the buses?",
            answer: "We prioritize safety and cleanliness by regularly maintaining our buses and following strict cleaning protocols. Our buses are thoroughly inspected and cleaned before each trip."
        },
        {
            question: "How can I contact customer support for bus service inquiries?",
            answer: "You can reach our customer support team via our website, mobile app, or by phone. We are available around the clock to assist with bookings, inquiries, and any issues you may have."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Ravi Sharma",
            text: "SSK Travels provided us with exceptional bus service for our recent corporate conference in Pune. From the initial booking to the final drop-off, their team was professional, punctual, and attentive to our needs. The buses were well-maintained and equipped with all the necessary amenities, making the journey comfortable for all our attendees. We particularly appreciated their flexibility in accommodating our last-minute changes. We highly recommend SSK Travels for any corporate transportation needs!"
        },
        {
            name: "Miss. Sneha Patel",
            text: "We had a great experience with SSK Travels for our corporate team-building event. Their fleet of buses was perfect for our large group, and the level of service exceeded our expectations. The drivers were courteous and ensured our team arrived on time without any hassle. The amenities on the buses made the commute pleasant and productive. It was reassuring to know that everything was taken care of so efficiently. We will definitely use SSK Travels again for future corporate events."
        }
    ];
    
    
    const contactData = {
        heading: "Bus Service for Corporate Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus Service for Corporate in Pune | Reliable Transportation Solutions</title>
  <meta name="description" content="Explore bus services for corporate outings and events in Pune. AC and Non-AC options available." />
  <meta name="keywords" content="Bus Service for corporate office Pune | Bus Service for corporate office picnic | SSK Travels24 Pune | Bus service for corporate outing Pune | Mini bus for corporate outing | AC / Non-AC bus for corporate events | 17, 26, 32, 35, 45 Seater Bus for corporate outing | Employee Transportation Services in Pune | Bus On Rent For Corporate in Pune | Corporate cab services in Hadapsar | Corporate Cab Services in Viman Nagar | Corporate cab services in Hinjewadi | Corporate cab services in Baner Aundh | Bus rental for corporate events | Bus service for corporate in Pimpri Chinchwad | Tempo Traveller on rent in Pune for corporate events | 14, 17, 25, 32, 35 seater Tempo Traveller on rent in Pune for corporate events | Mini bus on rent for corporate events" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus Service for Corporate in Pune | Reliable Transportation Solutions" />
  <meta property="og:description" content="Discover efficient bus services for corporate events and outings in Pune, with various seating options!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-service-corporate-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//corporate-bus-service-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-35.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Bus Service for Corporate Events in Pune</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busserviceforcorporate;
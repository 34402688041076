
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Luxurybusrentalinpune(){


    const cardData = 
    {
        keyword: 'Luxury Bus Rental in Pune',
        heading: 'SSKTravels24: Luxury Bus Rental in Pune',
        headingDescription: 'When it comes to traveling in style and comfort, SSKTravels24 excels with its premium luxury bus rental services in Pune. Whether you’re planning a corporate event, a wedding, or a special group outing, our luxury buses offer unparalleled comfort, elegance, and amenities. Here’s a look at the top luxury bus rental options with SSKTravels24 in Pune:',
        
         topPlaces : [
            {
                title: "1. Venna Lake",
                description: "A serene spot for boating and picnics, perfect for relaxing amidst stunning landscapes."
            },
            {
                title: "2. Pratapgad Fort",
                description: "A historic fort offering panoramic views and a glimpse into Maratha history."
            },
            {
                title: "3. Elephant's Head Point",
                description: "A scenic viewpoint known for its unique rock formation and breathtaking vistas."
            },
            {
                title: "4. Arthur's Seat",
                description: "Dubbed the 'Queen of the Points', offering spectacular views of the Konkan coast."
            },
            {
                title: "5. Mapro Garden",
                description: "Famous for its strawberry farms and delicious fruit-based products."
            },
            {
                title: "6. Lingmala Waterfall",
                description: "A must-visit waterfall for nature lovers, surrounded by a tranquil forest."
            },
            {
                title: "7. Wilson Point",
                description: "The highest point in Mahabaleshwar, ideal for witnessing stunning sunrises."
            },
            {
                title: "8. Sunset Point",
                description: "A perfect spot to enjoy mesmerizing sunset views over the hills."
            },
            {
                title: "9. Mahabaleshwar Temple",
                description: "An ancient temple dedicated to Lord Shiva, known for its intricate carvings."
            },
            {
                title: "10. Bhilar Waterfall",
                description: "A hidden gem known for its peaceful surroundings and serene beauty."
            }
        ],        
        
        services : [
            {
                name: "Luxury Bus Rental in Pimpri Chinchwad",
                description: "SSKTravels24 offers premium luxury bus rentals in Pimpri Chinchwad, ideal for those seeking comfort and style. Our fleet includes top-of-the-line vehicles equipped with advanced amenities, ensuring a sophisticated travel experience for corporate events, weddings, and other special occasions."
            },
            {
                name: "Luxury Bus Rental in Kharadi",
                description: "Experience unparalleled comfort with our luxury bus rentals in Kharadi. Whether you’re planning a group outing, corporate function, or special event, our luxury buses provide the perfect blend of elegance and convenience."
            },
            {
                name: "Luxury Bus on Rent in Yerawada",
                description: "Our luxury bus rental services in Yerawada are designed to meet the highest standards of comfort and luxury. Ideal for any occasion, from business trips to family outings, these buses offer premium features and a smooth, enjoyable journey."
            },
            {
                name: "Luxury Bus on Rent in Vishrantwadi",
                description: "SSKTravels24 provides exceptional luxury bus rentals in Vishrantwadi, Pune. Our buses come equipped with high-end amenities to ensure a comfortable and memorable travel experience, whether for local events or outstation trips."
            },
            {
                name: "14, 17, 20, 25 Seater Luxury Bus on Rent in Pune",
                description: "We offer a range of luxury buses on rent in Pune, including 14, 17, 20, and 25-seater options. Perfect for smaller groups or intimate gatherings, these buses provide premium comfort and stylish interiors."
            },
            {
                name: "32, 35, 40, 45 Seater Luxury Bus Hire in Pimpri Chinchwad",
                description: "For larger groups, SSKTravels24 offers 32, 35, 40, and 45-seater luxury buses in Pimpri Chinchwad. These spacious and luxurious buses are ideal for corporate events, family gatherings, and special occasions."
            },
            {
                name: "Luxury Bus on Rent in Hadapsar",
                description: "Our luxury bus rentals in Hadapsar, Pune, offer a sophisticated travel experience with a focus on comfort and style. These buses are equipped with premium features and are perfect for various events and long-distance journeys."
            },
            {
                name: "Luxury Bus on Rent for Picnic in Pune",
                description: "Planning a picnic? Our luxury buses are available for rent to make your picnic experience both comfortable and enjoyable. With high-end amenities and ample space, your group can travel in style and ease."
            },
            {
                name: "Mini Luxury Bus Hire in Pune",
                description: "Ideal for smaller groups or short trips, our mini luxury buses in Pune provide a cozy yet luxurious travel experience. Enjoy comfort and elegance with our well-maintained mini buses, perfect for intimate gatherings or special events."
            },
            {
                name: "Non-AC Bus Rental Service in Pune",
                description: "For those looking for a more budget-friendly option, we offer non-AC bus rentals in Pune. These buses are ideal for short trips and local events, providing reliable transportation at an affordable rate."
            },
            {
                name: "Non-AC Tempo Traveller on Rent in Pune",
                description: "For a budget-friendly travel option, consider renting a non-AC tempo traveller from SSKTravels24 in Pune. Our non-AC tempo travellers are ideal for group outings and local trips."
            },
            {
                name: "Luxury Bus Rental in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Luxury Bus Rental in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        tableData : [
            ['- Luxury Bus Rental in Pimpri Chinchwad', '- Luxury Bus Rental in Kharadi'],
            ['- Luxury Bus On Rent in Yerawada', '- Luxury Bus On Rent in Vishrantwadi, Pune'],
            ['- 14, 17, 20, 25 Seater Luxury Bus On Rent in Pune', '- 32, 35, 40, 45 Seater Luxury Bus Hire in Pimpri Chinchwad'],
            ['- Luxury Bus On Rent in Hadapsar Pune', '- Luxury Bus On Rent for Picnic in Pune'],
            ['- Mini Luxury Bus Hire in Pune', '- Non-AC Bus Rental Service in Pune'],
            ['- Non-AC Bus for Hire for Outstation', '- Non-AC Tempo Traveller on Rent in Pune'],
            ['- Non-AC Bus Hire on Rent in Pune', '- Non-AC Mini Bus Hire on Rent in Pune'],
            ['- Non-AC Bus Hire for Picnic in Pune', '- Best Bus Rental Service in Pune'],
            ['- 32, 35, 40, 45 Seater Local and Outstation Bus', '- 14, 17, 20, 25 Seater Bus on Rent in Pune']
        ],
    
        whychoose : [
            {
                "WhyChooseheading": "Exceptional Comfort and Style",
                "WhyChoosedescription": "At SSKTravels24, we prioritize your comfort and style. Our luxury buses are equipped with plush seating, advanced climate control, and state-of-the-art entertainment systems to ensure a premium travel experience for all passengers."
            },
            {
                "WhyChooseheading": "Diverse Fleet",
                "WhyChoosedescription": "We offer a wide range of luxury buses, including 14, 17, 20, 25, 32, 35, 40, and 45-seater options. Whether you need a bus for a small group or a large event, we have the perfect vehicle to meet your needs and preferences."
            },
            {
                "WhyChooseheading": "Professional Drivers",
                "WhyChoosedescription": "Our experienced and professional drivers ensure a smooth and safe journey. They are trained to provide exceptional service and handle all your travel requirements with the utmost care, allowing you to relax and enjoy the ride."
            },
            {
                "WhyChooseheading": "Customizable Packages",
                "WhyChoosedescription": "Every event is unique, and that’s why we offer customizable rental packages to suit your specific needs. From corporate events and weddings to special outings and tours, we can tailor our services to fit your requirements perfectly."
            },
            {
                "WhyChooseheading": "Competitive Rates",
                "WhyChoosedescription": "Enjoy premium luxury without breaking the bank. We offer competitive pricing and flexible rental options to fit your budget while maintaining the highest standards of service, ensuring you get great value."
            },
            {
                "WhyChooseheading": "Well-Maintained Vehicles",
                "WhyChoosedescription": "Our fleet is regularly maintained and inspected to ensure that every bus is in top condition. Travel with peace of mind knowing that you are in a reliable and well-maintained vehicle, enhancing your travel experience."
            },
            {
                "WhyChooseheading": "Excellent Customer Service",
                "WhyChoosedescription": "Our dedicated team is committed to providing outstanding customer service. From the moment you contact us to the end of your journey, we ensure that your experience is seamless and enjoyable, addressing any needs you may have along the way."
            },
            {
                "WhyChooseheading": "Flexibility for Local and Outstation Travel",
                "WhyChoosedescription": "Whether you’re planning a local event or an outstation trip, our luxury buses are available for both types of journeys. We ensure you have the right vehicle for any occasion, making your travel planning hassle-free."
            },
            {
                "WhyChooseheading": "Timely Service",
                "WhyChoosedescription": "We understand the importance of punctuality. Our team ensures that our luxury buses arrive on time and meet your travel schedule with precision, so you can focus on enjoying your trip."
            },
            {
                "WhyChooseheading": "Trusted by Many",
                "WhyChoosedescription": "SSKTravels24 is a trusted name in luxury bus rentals in Pune, with a reputation for reliability and excellence. Join our list of satisfied clients who have experienced the best in luxury travel, making memories that last."
            }
        ]        
    };    

    
   
    const faqData = [
        {
            question: "What types of luxury buses are available for rent?",
            answer: "We offer a diverse range of luxury buses, including 14, 17, 20, 25, 32, 35, 40, and 45-seater options. Each bus is equipped with high-end amenities to provide a comfortable and enjoyable travel experience."
        },
        {
            question: "How can I book a luxury bus with SSKTravels24?",
            answer: "You can easily book a luxury bus by contacting us directly via phone or email. Our team will assist you in selecting the right bus and finalizing your rental arrangements to suit your needs."
        },
        {
            question: "Are the luxury buses equipped with entertainment systems?",
            answer: "Yes, our luxury buses come with advanced entertainment systems, including audio and video options, to enhance your travel experience and keep you entertained throughout your journey."
        },
        {
            question: "Do you provide luxury buses for outstation trips?",
            answer: "Absolutely! We offer luxury buses for both local and outstation trips. Whether you’re traveling within Pune or to other destinations, we have the perfect bus for your journey."
        },
        {
            question: "Are there customizable packages available for luxury bus rentals?",
            answer: "Yes, we understand that each event is unique, and we offer customizable rental packages to suit your specific needs and preferences, ensuring your bus rental is tailored to your event."
        },
        {
            question: "What amenities are included in the luxury buses?",
            answer: "Our luxury buses feature plush seating, climate control, high-end entertainment systems, and other premium features designed for your comfort and convenience, making every journey enjoyable."
        },
        {
            question: "Can I rent a mini luxury bus?",
            answer: "Yes, we offer mini luxury buses for smaller groups or short trips, providing a cozy yet luxurious travel option that is perfect for intimate gatherings or special events."
        },
        {
            question: "How do you ensure the quality of your luxury buses?",
            answer: "Our fleet is regularly maintained and inspected to ensure that every bus is in excellent condition. We prioritize safety and comfort for all our passengers, so you can travel worry-free."
        },
        {
            question: "What is the cost of renting a luxury bus?",
            answer: "The cost of renting a luxury bus depends on various factors, such as the type of bus, rental duration, and destination. Please contact us for a customized quote based on your specific requirements."
        },
        {
            question: "Can I hire a luxury bus for a picnic?",
            answer: "Yes, we offer luxury buses for picnics, providing a comfortable and stylish travel experience for your group. Enjoy your outing with all the amenities and comfort that our luxury buses provide."
        }
    ];
        
    const testimonials = [
        {
            name: "Ms. Ananya Patel",
            text: "Our team’s annual retreat called for a touch of luxury, and SSKTravels24 exceeded our expectations. The bus was a perfect blend of elegance and comfort, featuring state-of-the-art amenities that made our journey both enjoyable and productive. The driver was courteous and highly skilled, ensuring a smooth ride throughout. SSKTravels24 provided a top-notch experience that truly enhanced our corporate event. Highly recommended for any upscale transportation needs!"
        },
        {
            name: "Mr. Arjun Mehta",
            text: "For our recent high-profile event, we chose SSKTravels24 for their luxury bus rental service, and it was an excellent decision. The bus was stylish, spacious, and impeccably clean, adding a touch of sophistication to our gathering. The service team was incredibly responsive and professional, making the entire process seamless. Our guests were delighted, and the overall experience was flawless. SSKTravels24 made a significant impact on the success of our event, and I’ll definitely consider them for future occasions!"
        }
    ];
    
    
    const contactData = {
        heading: "Luxury Bus Rental in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Luxury Bus Rental in Pune | Comfort on the Road</title>
  <meta name="description" content="Explore luxury bus rental options in Pune. Offering a range of seating capacities from 14 to 45 for your corporate events, picnics, and more." />
  <meta name="keywords" content="Luxury Bus Rental in Pune | Luxury Bus Rental in Pimpri Chinchwad | Luxury Bus Rental in Kharadi | Luxury Bus on Rent in Yerawada | Luxury Bus on Rent in Vishrantwadi | 14 17 20 25 Seater Luxury Bus on Rent Pune | 32 35 40 45 Seater Luxury Bus Hire in Pimpri Chinchwad | Luxury Bus on Rent in Hadapsar | Luxury Bus on Rent for Picnic in Pune | Mini Luxury Bus Hire in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Luxury Bus Rental in Pune | Comfort on the Road" />
  <meta property="og:description" content="Rent luxury buses in Pune for all occasions, including corporate events and picnics. Choose from various seating capacities." />
  <meta property="og:url" content="https://https://ssktravels24.com//luxury-bus-rental-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//luxury-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-41.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs Luxury Bus Rental in Pune for  SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Luxurybusrentalinpune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busrentalforschooltripinpune(){


    const cardData = 
    {
        keyword: 'Bus Rentals for School Trips in Pune',
        heading: 'SSKTravels24: Bus Rentals for School Trips in Pune',
        headingDescription: 'Experience the beauty of Mahabaleshwar with our luxurious Force Urbania buses for rent. Enjoy a comfortable and convenient journey from Pune to this picturesque hill station, ensuring an unforgettable getaway.Planning a successful school trip involves more than just choosing a destination; it requires reliable and comfortable transportation to ensure that students arrive safely and in good spirits. In Pune, SSKTravels24 stands out as a leading provider of bus rentals tailored specifically for school trips. Here’s an in-depth look at the top bus rental options offered by SSKTravels24, designed to make your next school trip a resounding success.',
        
         topPlaces : [
            {
                title: "1. Luxury Coaches",
                description: "SSKTravels24’s luxury coaches are perfect for long-distance school trips or special excursions. These buses feature high-end amenities such as plush seating, ample legroom, and state-of-the-art entertainment systems."
            },
            {
                title: "2. Standard Buses",
                description: "For a more budget-friendly option, SSKTravels24’s standard buses offer reliability and comfort for shorter trips. These buses provide ample space and essential features, including air conditioning and comfortable seating."
            },
            {
                title: "3. Mini Buses",
                description: "When you have a smaller group or need to navigate through the bustling streets of Pune, mini buses are an excellent choice. SSKTravels24’s mini buses are compact yet spacious enough to ensure a comfortable ride."
            },
            {
                title: "4. Volvo Buses",
                description: "Volvo buses from SSKTravels24 offer a premium travel experience with advanced features and exceptional comfort. Equipped with reclining seats, onboard Wi-Fi, and enhanced climate control, these buses provide a smooth and enjoyable ride."
            },
            {
                title: "5. Deluxe Buses",
                description: "For a step up from standard options, SSKTravels24’s deluxe buses offer additional comfort and amenities. Well-suited for longer trips, these buses feature enhanced air conditioning and extra legroom."
            },
            {
                title: "6. Executive Buses",
                description: "If your school is looking for a sophisticated travel option, SSKTravels24’s executive buses provide a touch of elegance and luxury. Designed for a refined travel experience, these buses offer leather seating and advanced climate control."
            },
            {
                title: "7. Convertible Buses",
                description: "For a unique and exciting travel experience, consider SSKTravels24’s convertible buses. Designed for scenic routes or outdoor adventures, these buses allow passengers to enjoy an open-air experience."
            },
            {
                title: "8. Eco-Friendly Buses",
                description: "Incorporating sustainability into your school trips, SSKTravels24’s eco-friendly buses help reduce the carbon footprint while maintaining comfort and efficiency."
            },
            {
                title: "9. Party Buses",
                description: "Make any school trip a celebration with SSKTravels24’s party buses. Designed to create a lively and festive atmosphere, they feature interactive entertainment options."
            },
            {
                title: "10. Customizable Buses",
                description: "If your school has specific requirements or preferences, SSKTravels24 offers customizable buses to tailor the experience to your needs."
            }
        ],        
        
        services : [
            {
                name: "Bus Hire for School Trips",
                description: "Choosing SSKTravels24 for bus hire means benefiting from our commitment to providing safe and comfortable transportation for school trips. Our well-maintained buses are equipped with features that ensure a pleasant journey for students and teachers, whether for day trips or extended excursions."
            },
            {
                name: "Bus Rentals for School Trips",
                description: "Our bus rental services are tailored specifically for school trips. We offer various bus sizes and configurations to accommodate different group sizes, ensuring that every student and staff member travels comfortably."
            },
            {
                name: "School Buses for Field Trips",
                description: "Looking for reliable school buses for field trips? SSKTravels24 provides a range of school buses equipped with essential safety features and amenities, ensuring a secure and enjoyable experience for students."
            },
            {
                name: "SSKTravels24 Pune",
                description: "As a leading provider of bus rentals in Pune, SSKTravels24 is renowned for superior service and customer satisfaction. Our experienced team is dedicated to managing every aspect of your bus rental, ensuring a hassle-free experience."
            },
            {
                name: "Affordable School Bus Rentals",
                description: "We offer competitive rates for school bus rentals, ensuring you get the best value for your money without compromising quality or safety."
            },
            {
                name: "Rental Buses for Trips in Pune",
                description: "From educational field trips to recreational outings, SSKTravels24 offers rental buses suitable for various types of trips, providing reliable and comfortable transportation for all your travel needs."
            },
            {
                name: "50-Seater Bus on Rent in Pune",
                description: "For larger school groups, we offer 50-seater buses that provide ample space and comfort, making them ideal for big school events and trips."
            },
            {
                name: "Coach Hire for School Trips",
                description: "For a more luxurious and comfortable travel experience, consider our coach hire services equipped with modern amenities, ensuring a high level of comfort for school trips."
            },
            {
                name: "Bus Hire for School Picnic in Pune",
                description: "Our bus hire services for school picnics ensure a comfortable and enjoyable travel experience to various picnic spots around Pune, catering to all your needs for a successful outing."
            },
            {
                name: "Bus Rentals For School Trips in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient bus rentals for school trips in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
         tableData : [
            ['- Bus hire for school trips', '- Bus rentals for School Trips'],
            ['- School Buses for field trips near me', '- Bus companies for school field trips'],
            ['- Bus rentals for school trips Pune', '- Bus Rent for picnic in Pune'],
            ['- Tourist Bus Rent Per Day', '- Mini Bus rent in Pune for picnic'],
            ['- Bus for rent in Pune School Picnic', '- Buses for college picnic in Pune'],
            ['- Rental Buses for Trips in Pune', '- 50 seater bus on Rent in Pune'],
            ['- 45 seater bus for Rent in Pune', '- 32 seater bus on rent Pune for school trip'],
            ['- 35 seater bus on rent for school trip', '- Coach hire for school trips'],
            ['- Coach hire for school trips near me', '- Sleeper bus hire for school trips']
        ],            
         whychoose : [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for Bus Rentals for School Trips in Pune",
                "WhyChoosedescription": "Comfortable and Spacious Vehicles: Our fleet features buses with comfortable seating and ample legroom, ensuring that every student and staff member travels in utmost comfort."
            },
            {
                "WhyChooseheading": "Modern Amenities:",
                "WhyChoosedescription": "SSKTravels24 buses come equipped with air conditioning, entertainment systems, and other amenities to make your journey enjoyable and engaging for everyone on board."
            },
            {
                "WhyChooseheading": "Experienced and Professional Drivers:",
                "WhyChoosedescription": "Our drivers are not only skilled but also experienced in handling the specific needs of school trips. They prioritize safety and ensure a smooth journey, making them an invaluable part of your travel experience."
            },
            {
                "WhyChooseheading": "Affordable Pricing:",
                "WhyChoosedescription": "We offer competitive rates for our bus rental services, delivering excellent value without compromising on quality or comfort."
            },
            {
                "WhyChooseheading": "Customizable Solutions:",
                "WhyChoosedescription": "Recognizing that every trip is unique, we provide flexible rental options tailored to your specific needs, whether it’s a local outing or a long-distance journey."
            },
            {
                "WhyChooseheading": "Excellent Customer Service:",
                "WhyChoosedescription": "Our dedicated customer service team is here to assist you with every aspect of your booking. We ensure a seamless experience from start to finish, addressing any concerns you may have along the way."
            },
            {
                "WhyChooseheading": "Punctual and Reliable:",
                "WhyChoosedescription": "We value your time and guarantee that our buses adhere to schedules. Reliability is at the core of our service, and we strive to meet and exceed your expectations."
            },
            {
                "WhyChooseheading": "Well-Maintained Fleet:",
                "WhyChoosedescription": "Our buses are regularly serviced and maintained to ensure optimal performance and cleanliness. We take pride in providing a fleet that is in top condition for every trip."
            },
            {
                "WhyChooseheading": "Easy Booking Process:",
                "WhyChoosedescription": "Booking with us is straightforward. You can contact us via phone, email, or through our online booking form, and we’ll handle the rest, making the process as easy as possible."
            },
            {
                "WhyChooseheading": "Safety First:",
                "WhyChoosedescription": "We prioritize safety in all aspects of our service. Our buses are equipped with necessary safety features, and our drivers undergo rigorous training to ensure a safe travel experience for everyone."
            }
        ],        
    };    

    
   
    const faqData = [
        {
            question: "What is included in the rental of a school bus?",
            answer: "Our rental includes comfortable seating, air conditioning, and a professional driver. Additional amenities can be requested based on your specific needs."
        },
        {
            question: "How do I book a bus for a school trip?",
            answer: "To book a bus, contact us via phone, email, or our online booking form. Provide details such as travel dates, pick-up and drop-off locations, and any special requests you may have."
        },
        {
            question: "Are there any additional charges I should be aware of?",
            answer: "We pride ourselves on transparent pricing with no hidden fees. Any additional charges will be communicated clearly during the booking process."
        },
        {
            question: "Can I customize the bus rental package?",
            answer: "Absolutely! We offer customizable packages to meet your specific needs. Let us know your requirements, and we’ll tailor a package that suits your trip."
        },
        {
            question: "What safety measures are in place for school trips?",
            answer: "Our buses are equipped with comprehensive safety features, and our drivers are trained to ensure a secure journey. We adhere to all safety regulations to protect your group."
        },
        {
            question: "Can I cancel or modify my booking?",
            answer: "Yes, you can cancel or modify your booking according to our policies. Please contact us as soon as possible if you need to make changes."
        },
        {
            question: "How far in advance should I book the bus?",
            answer: "We recommend booking as early as possible to secure your preferred vehicle and date. Early booking helps us accommodate your needs more effectively."
        },
        {
            question: "Do you provide transportation for special needs students?",
            answer: "Yes, we can arrange for buses that accommodate special needs students. Please inform us in advance of any specific requirements."
        },
        {
            question: "What happens in case of an emergency during the trip?",
            answer: "Our drivers are trained to handle emergencies, and our customer support team is always available to assist with any issues that may arise during the trip."
        },
        {
            question: "Can I view the bus before booking?",
            answer: "Yes, you can arrange to view the bus before finalizing your booking. Just contact us to schedule a visit and inspect the vehicle."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Ms. Anjali Desai",
            text: "Our school recently chose SSKTravels24 for a field trip to the Pune Zoo, and I couldn't be happier with the service. We opted for their luxury coaches, and the experience was outstanding. The coaches were spotless, and the amenities, including comfortable seating and an excellent entertainment system, kept our students engaged throughout the journey. The drivers were professional and courteous, ensuring a smooth and safe trip. SSKTravels24 made the entire process hassle-free, from booking to the trip itself. I highly recommend their services for any school looking to provide a top-notch travel experience for students."
        },
        {
            name: "Mr. Vikram Joshi",
            text: "Choosing SSKTravels24 for our recent school outing was a fantastic decision. We used their standard buses for a day trip around Pune, and everything went perfectly. The buses were well-maintained, providing ample space for our group of 50 students. The drivers were punctual and efficiently managed the route, ensuring we arrived on time for all our activities. The booking process was straightforward, and the team at SSKTravels24 was very accommodating to our needs. Feedback from both students and teachers was overwhelmingly positive. For any school planning a trip, SSKTravels24 is definitely the way to go."
        }
    ];

    
    const contactData = {
        heading: "Bus Rentals For School Trips in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus Rentals For School Trips in Pune | Affordable School Bus Hire Services</title>
  <meta name="description" content="Explore reliable bus rental services for school trips in Pune. Affordable options for field trips, picnics, and more." />
  <meta name="keywords" content="Bus hire for school trips | Bus rentals for School Trips | School Buses for field trips near me | SSK Travels24 Pune | Bus companies for school field trips | Bus rentals for school trips Pune | Bus Rent for picnic in Pune | Tourist Bus Rent Per Day | Mini Bus rent in Pune for picnic | Bus for rent in Pune School Picnic | Buses for college picnic in Pune | Rental Buses for Trips in Pune | 50 seater bus on Rent in Pune | 45 seater bus for Rent in Pune | 32 seater bus on rent Pune for school trip | 35 seater bus on rent for school trip | Coach hire for school trips | Coach hire for school trips near me | Sleeper bus hire for school trips | Bus Hire for College Trip in Pune | School bus rental for field trip | Cheap school bus rental for field trip | School bus on rent in Pimpri Chinchwad | Private school bus on rent in Pimpri Chinchwad | Pune to Morachi Chincholi School Bus On Rent | Pune to Mahabaleshwar school bus on rent | Pune to Mumbai darshan school bus on rent | Bus hire for school picnic in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus Rentals For School Trips in Pune | Affordable School Bus Hire Services" />
  <meta property="og:description" content="Book reliable and affordable buses for school trips in Pune. Ideal for field trips, picnics, and educational tours." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-rentals-school-trips-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//school-bus-rental-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-32.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>Frequently Asked Questions (FAQs) About Bus Rentals for School Trips in Pune – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busrentalforschooltripinpune;
import React from 'react';
import carImage from '../images/Urbania-02.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeaturesSection = () => {
  return (
   <div>
     <section aria-label="section">
      <div className="container">
        <div className="row align-items-center ">
          <div className="col-lg-6 text-center">
            <h2 className='bluecolor'>Our Features</h2>
            <p>
              Discover a world of convenience, safety, and customization, paving the way for unforgettable adventures and seamless mobility solutions.
            </p>
            <div className="spacer-20"></div>
          </div>

          <div className="clearfix"></div>

          <div className="col-lg-3">
            <div className="box-icon s2 p-small mb20 wow fadeInRight" data-wow-delay=".5s">
              <i className="fa bg-color fa-trophy"></i>
              <div className="d-inner">
                <h4>First class services</h4>
                
                <p>
                  Where luxury meets exceptional care, creating unforgettable moments and exceeding your every expectation.
                </p>
              </div>
            </div>
            <div className="box-icon s2 p-small mb20 wow fadeInLeft fadeInRight" data-wow-delay=".75s">
              <i className="fa bg-color fa-road"></i>
              <div className="d-inner">
                <h4>24/7 road assistance</h4>
                <p>
                  Reliable support when you need it most, keeping you on the move with confidence and peace of mind.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <img src={carImage} alt="Car" className="img-fluid wow fadeInUp" />
          </div>

          <div className="col-lg-3">
            <div className="box-icon s2 d-invert p-small mb20 wow fadeInLeft " data-wow-delay="1s">
              <i className="fa fa-tag bg-color"></i>
              <div className="d-inner">
                <h4>Quality at Minimum Expense</h4>
                <p>
                  Unlocking affordable brilliance with elevating quality while minimizing costs for maximum value.
                </p>
              </div>
            </div>
            <div className="box-icon s2 d-invert p-small mb20 wow fadeInLeft fadeInLeft" data-wow-delay="1.25s">
              <i className="fa bg-color fa-map-pin"></i>
              <div className="d-inner">
                <h4>Free Pick-Up & Drop-Off</h4>
                <p>
                  Enjoy free pickup and drop-off services, adding an extra layer of ease to your car rental experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   </div>
  );
};

export default FeaturesSection;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function ForceUrbaniaOnRentinPune(){


    const cardData = 
    {
        keyword: 'Force Urbania On Rent in Pune',
        heading: 'SSKTravels24: Force Urbania On Rent in Pune',
        headingDescription: 'When it comes to traveling in comfort and style, the Force Urbania stands out as an excellent choice for both personal and corporate travel. SSKTravels24 offers an exceptional experience with this spacious and luxurious vehicle. Here are the top reasons to book the Force Urbania with SSKTravels24 in Pune:',
        
        topPlaces: [
            {
                title: "1. Ample Space for Comfort",
                description: "The Force Urbania is designed to accommodate large groups, offering ample legroom and headspace. Perfect for family trips or corporate outings, everyone can travel comfortably."
            },
            {
                title: "2. Versatile Seating Arrangements",
                description: "With flexible seating options, the Urbania can easily adapt to your travel needs, whether you're transporting passengers or cargo. This versatility makes it ideal for various occasions, from weddings to business trips."
            },
            {
                title: "3. Modern Design",
                description: "The sleek and contemporary design of the Force Urbania makes it an attractive choice. Arrive at your destination in style, impressing your clients or guests with this modern vehicle."
            },
            {
                title: "4. Reliable Performance",
                description: "Equipped with a powerful engine, the Force Urbania delivers a smooth and stable driving experience. Whether navigating through city traffic or cruising on the highway, it handles all conditions effortlessly."
            },
            {
                title: "5. Advanced Safety Features",
                description: "Safety is a priority, and the Force Urbania comes with various safety features, including airbags and anti-lock braking systems. You can travel with peace of mind knowing that you are protected."
            },
            {
                title: "6. Economical for Group Travel",
                description: "Renting the Force Urbania can be more cost-effective for group travel compared to multiple smaller vehicles. Save money while ensuring everyone travels together."
            },
            {
                title: "7. Professional Drivers",
                description: "At SSKTravels24, we provide experienced and courteous drivers familiar with Pune's roads. They ensure a smooth journey and can offer valuable insights about local attractions."
            },
            {
                title: "8. Customizable Rental Options",
                description: "We offer flexible rental packages tailored to your needs. Whether you require the vehicle for a few hours, a day, or a week, we can accommodate your schedule."
            },
            {
                title: "9. Excellent Customer Service",
                description: "Our commitment to customer satisfaction is unmatched. Our team is always ready to assist you with booking and any inquiries you may have before or during your rental period."
            },
            {
                title: "10. Affordable Pricing",
                description: "At SSKTravels24, we pride ourselves on offering competitive pricing for our rentals without compromising on quality or service. Enjoy the luxury of the Force Urbania at an affordable rate."
            }
        ],
        
        services: [
            {
                name: "Pune to Shirdi Force Urbania on Rent",
                description: "Travel comfortably from Pune to Shirdi in a spacious Force Urbania, ideal for family groups or spiritual journeys, ensuring a hassle-free ride to the sacred Sai Baba Temple."
            },
            {
                name: "Pune to Ashtavinayak Urbania Tour Package",
                description: "Experience the divine with our Ashtavinayak Urbania tour package. Visit all eight Ganesha temples in style and comfort, making it a memorable pilgrimage with friends and family."
            },
            {
                name: "Pune to Mahabaleshwar Urbania on Rent",
                description: "Discover the scenic hill station of Mahabaleshwar with our Urbania on rent. Enjoy the journey through lush landscapes while traveling in a spacious and comfortable vehicle."
            },
            {
                name: "Force Urbania Vans on Rent in Viman Nagar",
                description: "Need a reliable vehicle in Viman Nagar? Rent a Force Urbania for your group outings or corporate events. Enjoy ample space and comfort at competitive rates."
            },
            {
                name: "Urbania On Rent in Pune",
                description: "Choose SSKTravels24 for Urbania rentals across Pune. Our well-maintained vans are perfect for both local trips and outstation travel, offering a premium experience."
            },
            {
                name: "Bus Hire for Wedding in Pune",
                description: "Make your wedding day special with our Force Urbania bus hire. Perfect for transporting guests comfortably, adding a touch of luxury to your celebrations."
            },
            {
                name: "Force Urbania on Rent for Corporate Events",
                description: "Impress clients and employees alike with our Urbania rental service for corporate events. Ensure smooth transportation for meetings, conferences, and team outings."
            },
            {
                name: "Force Urbania On Rent in Kothrud",
                description: "Looking for a spacious vehicle in Kothrud? Our Force Urbania rentals offer the perfect solution for family trips, picnics, or group events."
            },
            {
                name: "Force Urbania for Rent in Pune",
                description: "SSKTravels24 provides the best Force Urbania rental service in Pune. Enjoy the spacious interior and modern amenities for a comfortable journey."
            },
            {
                name: "Force Urbania Per Km Rate",
                description: "Our pricing for Force Urbania rentals is competitive and transparent. Check our per km rates to find the best deal for your travel needs."
            },
            {
                name: "Force Urbania Luxury Mini Bus On Rent",
                description: "Opt for our luxury mini bus rental for a touch of elegance. Perfect for long trips, family gatherings, or special events, our Urbania ensures comfort and style."
            },
            {
                name: "Urbania On Rent in Viman Nagar",
                description: "Explore Pune and surrounding areas with our Urbania rental in Viman Nagar. Spacious and comfortable, it’s ideal for all your travel requirements."
            },
            {
                name: "Urbania On Rent in Koregaon Park Pune",
                description: "Travel in style in Koregaon Park with our Force Urbania on rent. Perfect for outings, family trips, or any group activities."
            },
            {
                name: "Urbania On Rent in Kharadi",
                description: "Kharadi residents can now enjoy the convenience of renting a Force Urbania. Perfect for local and outstation travel, our rental service offers competitive rates."
            },
            {
                name: "Urbania On Rent in Hadapsar",
                description: "Looking for a comfortable ride in Hadapsar? Rent a Force Urbania for family outings, corporate events, or special occasions."
            },
            {
                name: "Force Urbania On Rent in Kalyani Nagar",
                description: "Kalyani Nagar’s residents can benefit from our Force Urbania rental service. Experience spacious travel for all your group outings and events."
            },
            {
                name: "Urbania On Rent for Pune Darshan",
                description: "Explore Pune's iconic landmarks with our Urbania on rent for Pune Darshan. Enjoy a guided tour with friends and family in a comfortable and spacious vehicle."
            },
            {
                name: "Contact Number for Force Urbania On Rent in Pune",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Force Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        tableData: [
            ['Pune to Shirdi Force Urbania on Rent', 'Pune to Ashtavinayak Urbania Tour Package from Pune'],
            ['Pune to Mahabaleshwar Urbania on Rent', 'Force Urbania Vans on Rent in Viman Nagar'],
            ['Urbania On Rent in Pune', 'Bus Hire for Wedding in Pune'],
            ['Force Urbania on Rent in Pune for Corporate Events', 'Force Urbania On Rent in Kothrud'],
            ['Force Urbania For Rent in Pune', 'Force Urbania Per Km Rate'],
            ['Force Urbania Luxury Mini Bus On Rent', 'Urbania On Rent in Viman Nagar'],
            ['Urbania On Rent in Koregaon Park Pune', 'Urbania On Rent in Kharadi'],
            ['Urbania On Rent in Hadapsar', 'Force Urbania On Rent in Kalyani Nagar'],
            ['Urbania On Rent for Pune Darshan', '']
        ],
        "whychoose": [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for Force Urbania On Rent in Pune?",
                "WhyChoosedescription": "When it comes to renting the Force Urbania in Pune, SSKTravels24 is your ideal provider. Our commitment to comfort, reliability, and customer satisfaction ensures that your travel experience is enjoyable and hassle-free. Here’s why SSKTravels24 stands out:"
            },
            {
                "WhyChooseheading": "Spacious and Comfortable:",
                "WhyChoosedescription": "Our Force Urbania vans are designed to provide ample space for passengers, making them perfect for family trips, corporate outings, and events. Enjoy the comfort of a well-maintained vehicle equipped with modern amenities."
            },
            {
                "WhyChooseheading": "Flexible Rental Options:",
                "WhyChoosedescription": "We offer a variety of rental packages, whether you need the Urbania for a few hours, a day, or for long-distance travel. Tailor your booking to fit your specific needs."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our experienced drivers are well-versed in the roads of Pune and surrounding areas. They ensure a safe and smooth journey while also being courteous and knowledgeable about local attractions."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "At SSKTravels24, we pride ourselves on offering affordable pricing without compromising on quality. Enjoy transparent pricing with no hidden charges."
            },
            {
                "WhyChooseheading": "Excellent Customer Service:",
                "WhyChoosedescription": "Our dedicated team is available to assist you with your booking and answer any questions you may have. We are committed to providing a seamless rental experience."
            },
            {
                "WhyChooseheading": "Safety First:",
                "WhyChoosedescription": "Safety is our priority. Our Force Urbania vehicles undergo regular maintenance checks and come equipped with necessary safety features, allowing you to travel with peace of mind."
            },
            {
                "WhyChooseheading": "Perfect for Group Travel:",
                "WhyChoosedescription": "Whether you're planning a wedding, corporate event, or family vacation, the Force Urbania comfortably accommodates larger groups, making it the ideal choice for group travel."
            },
            {
                "WhyChooseheading": "Convenient Locations:",
                "WhyChoosedescription": "We offer rental services across Pune, including popular areas like Viman Nagar, Kharadi, and Koregaon Park, making it easy for you to access our vehicles."
            },
            {
                "WhyChooseheading": "Customizable Itineraries:",
                "WhyChoosedescription": "We can assist in creating itineraries tailored to your travel needs, whether it's a local tour or an outstation trip."
            },
            {
                "WhyChooseheading": "Trusted Reputation:",
                "WhyChoosedescription": "With numerous satisfied customers, SSKTravels24 has built a reputation for reliability and quality service in the car rental industry."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What is the seating capacity of the Force Urbania?",
            answer: "The Force Urbania can comfortably seat up to 13 passengers, making it ideal for larger groups."
        },
        {
            question: "Are the vehicles well-maintained?",
            answer: "Yes, all our vehicles, including the Force Urbania, undergo regular maintenance checks to ensure safety and comfort."
        },
        {
            question: "Can I book the Force Urbania for outstation travel?",
            answer: "Absolutely! Our Force Urbania is available for both local and outstation travel."
        },
        {
            question: "How do I make a booking?",
            answer: "You can easily book your Force Urbania by visiting our website or calling our customer service for assistance."
        },
        {
            question: "Is there a minimum rental period?",
            answer: "Yes, we typically have a minimum rental period, which may vary based on your travel needs. Please check with us for specific details."
        },
        {
            question: "Are there any hidden charges?",
            answer: "No, we provide transparent pricing with no hidden charges. The price you see is what you pay."
        },
        {
            question: "Can I customize my itinerary?",
            answer: "Yes, we can help you create a personalized itinerary based on your travel preferences."
        },
        {
            question: "What if I need to cancel my booking?",
            answer: "Cancellation policies vary, but we strive to accommodate our customers. Please contact us for specific cancellation terms."
        },
        {
            question: "Do you provide drivers with the vehicle?",
            answer: "Yes, all our rentals come with professional drivers to ensure a safe and enjoyable journey."
        },
        {
            question: "How can I contact SSKTravels24 for more information?",
            answer: "You can reach us through our website, email, or by phone. Our customer service team is ready to assist you with any inquiries."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Arjun Sharma",
            text: "I recently hired the Force Urbania from SSKTravels24 for a family trip to Mahabaleshwar, and it was an amazing experience! The vehicle was spacious, clean, and comfortable for all of us. Our driver was professional and knew the best routes, making our journey smooth and enjoyable. I highly recommend SSKTravels24 for anyone looking for reliable van rentals in Pune!"
        },
        {
            name: "Miss Priya Mehta",
            text: "We booked the Force Urbania for a corporate event, and it was perfect for our needs. The van had plenty of space for our team and all our equipment. The service from SSKTravels24 was exceptional, from booking to the actual trip. The driver was punctual and very accommodating. I will definitely use their services again for future events!"
        }
    ];
    
    const contactData = {
        heading: "Contact SSKTravels24 for Force Urbania On Rent in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Force Urbania On Rent in Pune | Luxury Mini Bus Hire</title>
  <meta name="description" content="Book Force Urbania on rent in Pune for your travel needs. Ideal for group outings, corporate events, and weddings. Explore Pune and beyond with comfort." />
  <meta name="keywords" content="Pune to Shirdi Force Urbania on rent | Urbania on rent in Pune | Force Urbania for corporate events | luxury mini bus rental Pune | Pune darshan urbania" />
  <meta property="og:title" content="Force Urbania On Rent in Pune | SSK Travels24" />
  <meta property="og:description" content="Experience comfortable travel with Force Urbania rentals in Pune. Perfect for large groups, weddings, and corporate functions. Book now!" />
  <meta property="og:url" content="https://https://ssktravels24.com//force-urbania-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//urbania-rental-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-34.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default ForceUrbaniaOnRentinPune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Seateronrent(){






    const cardData = 
    {
      keyword: 'Tempo Traveller on Rent for Pune to Mahabaleshwar',
      heading: 'SSKTravels24: 14-Seater Bus on Rent in Pune',
      headingDescription: 'When it comes to group travel in Pune, the 14-seater bus from SSKTravels24 offers the perfect blend of comfort, convenience, and style. Whether you are planning a corporate event, family outing, or school trip, our 14-seater buses are designed to meet your needs with ease. Here’s why our 14-seater buses stand out and what you can expect from our rental service.',
      
       topPlaces : [
        {
            title: "1. Spacious Seating Arrangement",
            description: "Our 14-seater buses are designed with ample space to ensure all passengers can travel comfortably. The spacious seating arrangement provides generous legroom and headspace, making long journeys more enjoyable."
        },
        {
            title: "2. Luxurious Interiors",
            description: "Enjoy the luxury of plush, reclining seats that offer exceptional comfort. Each seat is equipped with individual armrests and adjustable headrests, allowing passengers to relax and enjoy the ride."
        },
        {
            title: "3. Advanced Air Conditioning",
            description: "Our buses come equipped with powerful air conditioning systems that keep the interior cool and pleasant, even during the hottest days in Pune. This ensures a comfortable travel experience regardless of external weather conditions."
        },
        {
            title: "4. Modern Entertainment Systems",
            description: "To make your journey more enjoyable, our 14-seater buses feature state-of-the-art entertainment systems. Whether you want to listen to your favorite music or watch a movie, the onboard entertainment options are sure to enhance your travel experience."
        },
        {
            title: "5. Ample Luggage Space",
            description: "Traveling with a lot of luggage? No problem! Our 14-seater buses are equipped with ample luggage compartments to securely store your bags and belongings, ensuring that the interior remains clutter-free and comfortable."
        },
        {
            title: "6. Experienced and Professional Drivers",
            description: "Safety and reliability are paramount. Our experienced drivers are not only skilled but also well-acquainted with Pune’s routes and beyond. They prioritize your safety and comfort, ensuring a smooth and secure journey."
        },
        {
            title: "7. Well-Maintained Vehicles",
            description: "Each bus in our fleet undergoes regular maintenance and inspection to meet the highest standards of cleanliness and performance. This commitment ensures that you travel in a reliable and well-maintained vehicle."
        },
        {
            title: "8. Flexible Rental Options",
            description: "We offer flexible rental packages to suit your specific needs. Whether you need the bus for a few hours or several days, we provide customizable rental options to fit your schedule and budget."
        },
        {
            title: "9. Competitive Pricing",
            description: "At SSKTravels24, we offer competitive rates for our 14-seater buses, ensuring you get great value for your money. Our transparent pricing structure means no hidden fees or unexpected costs."
        },
        {
            title: "10. 24/7 Customer Support",
            description: "Our dedicated customer support team is available around the clock to assist you with any questions or issues. From booking inquiries to on-road support, we are here to ensure a smooth and hassle-free experience."
        }
    ],    
    
    services : [
        {
            name: "14-Seater Bus on Rent in Pune",
            description: "Looking for a reliable and comfortable 14-seater bus on rent in Pune? SSKTravels24 provides top-notch rental services for group travel, corporate events, family outings, and more."
        },
        {
            name: "14-Seater Bus on Rent in Pune Price",
            description: "Our pricing for renting a 14-seater bus is competitive and transparent, with clear rates and no hidden charges based on rental duration and specific requirements."
        },
        {
            name: "14-Seater Bus Rate Per Km in Pune",
            description: "We provide 14-seater bus rentals at a rate per kilometer, allowing you to pay only for the distance you travel, making budgeting easier for longer journeys."
        },
        {
            name: "14-Seater AC Tempo Traveller on Rent in Pune",
            description: "Experience luxury with our air-conditioned 14-seater Tempo Travellers, equipped with powerful AC systems for a cool and comfortable journey."
        },
        {
            name: "14-Seater Non-AC Tempo Traveller on Rent in Pune",
            description: "For a more economical option, our 14-seater non-AC Tempo Travellers provide comfortable and spacious rides at a budget-friendly rate."
        },
        {
            name: "14-Seater Tempo Traveller for Mahabaleshwar from Pune",
            description: "Our 14-seater Tempo Travellers ensure an enjoyable and hassle-free trip to Mahabaleshwar with ample space and experienced drivers."
        },
        {
            name: "14-Seater Tempo Traveller for Pune to Goa",
            description: "Choose our 14-seater Tempo Traveller for a stylish and comfortable ride from Pune to Goa, equipped with all necessary amenities for long-distance travel."
        },
        {
            name: "14-Seater Tempo Traveller Rentals in Pune",
            description: "SSKTravels24 offers a range of options tailored to your travel needs, ensuring a smooth and enjoyable ride in and around Pune."
        },
        {
            name: "14-Seater Tempo Traveller from Pune to Nashik",
            description: "Our 14-seater Tempo Travellers provide a reliable and pleasant journey for trips from Pune to Nashik, whether for pilgrimage, business, or leisure."
        },
        {
            name: "14-Seater Tempo Traveller Pune to Mumbai Rent",
            description: "Our 14-seater Tempo Travellers are perfect for trips between Pune and Mumbai, equipped with modern amenities for both short and long-distance travel."
        },
        {
            name: "14-Seater Tempo Traveller on Rent in Hadapsar",
            description: "Located in Hadapsar? SSKTravels24 provides excellent rental services with comfortable and well-equipped Tempo Travellers for group travel."
        },
        {
            name: "14-Seater Tempo Traveller on Rent in Pimpri Chinchwad",
            description: "For reliable and affordable 14-seater Tempo Travellers in Pimpri Chinchwad, SSKTravels24 is your ideal choice for various occasions."
        },
        {
            name: "14-Seater Tempo Traveller Hire in Viman Nagar",
            description: "Top-notch 14-seater Tempo Traveller hire services in Viman Nagar, with modern amenities for local and outstation trips."
        },
        {
            name: "Pune to Shirdi 14-Seater Bus Booking",
            description: "Our 14-seater buses are ideal for the journey from Pune to Shirdi, offering comfort and spacious travel to one of the most visited pilgrimage destinations."
        }
    ],
      tableData: [
        ['-Tempo Traveller from Pune to Mahabaleshwar', '-Pune to Mahabaleshwar Tempo Traveller Hire'],
        ['-Pune to Panchgani Tempo Traveller', '-Tempo Traveller for Rent in Pune'],
        ['-17-Seater Tempo Traveller for Mahabaleshwar Trip', '-17-Seater Tempo Traveller for Mahabaleshwar Tour Package'],
        ['-Pune Airport to Mahabaleshwar Tempo Traveller on Rent', '-Pune to Tapola Tempo Traveller on Rent'],
        ['-14-Seater Tempo Traveller for Maharashtra Trip', '-Tempo Traveller on Rent for Mahabaleshwar Outings'],
        ['-Mini Bus on Rent for Mahabaleshwar Trip', '-Tempo Traveller on Rent in Pune Rate Per Km']
      ],
       whychoose : [
        {
            WhyChooseheading: "Why Choose SSKTravels24 for Your 14-Seater Bus on Rent in Pune?",
            WhyChoosedescription: "When it comes to renting a 14-seater bus in Pune, SSKTravels24 stands out for its exceptional service and customer satisfaction. Here’s why we are the preferred choice:"
        },
        {
            WhyChooseheading: "Spacious and Comfortable Vehicles:",
            WhyChoosedescription: "Our 14-seater buses are designed to offer ample space and comfort for all passengers. With plush seating and generous legroom, your journey will be enjoyable whether it’s a short trip or long-distance travel."
        },
        {
            WhyChooseheading: "Well-Maintained Fleet:",
            WhyChoosedescription: "We ensure that our 14-seater buses are regularly serviced and maintained to the highest standards, guaranteeing reliability and safety for a trouble-free travel experience."
        },
        {
            WhyChooseheading: "Experienced and Professional Drivers:",
            WhyChoosedescription: "Our drivers are not only experienced but also familiar with Pune’s routes and beyond. They are committed to ensuring a safe and smooth journey, making your travel experience stress-free."
        },
        {
            WhyChooseheading: "Flexible Rental Options:",
            WhyChoosedescription: "SSKTravels24 offers flexible rental packages to suit various needs, whether it’s a day trip, corporate outing, or extended travel. We tailor our services to meet your specific requirements."
        },
        {
            WhyChooseheading: "Competitive and Transparent Pricing:",
            WhyChoosedescription: "We provide clear and upfront pricing with no hidden charges. Our rates are competitive, ensuring you get excellent value for your money without unexpected costs."
        },
        {
            WhyChooseheading: "Customer-Centric Approach:",
            WhyChoosedescription: "We prioritize customer satisfaction, providing personalized service to meet your unique travel needs. Our dedicated customer support team is available to assist with any inquiries or special requests."
        },
        {
            WhyChooseheading: "Modern Amenities:",
            WhyChoosedescription: "Our 14-seater buses come equipped with modern amenities such as air conditioning, music systems, and comfortable seating to enhance your travel experience."
        },
        {
            WhyChooseheading: "On-Time Service:",
            WhyChoosedescription: "Punctuality is a key aspect of our service. We ensure that our buses arrive on time and adhere to the agreed schedule, allowing you to plan your journey with confidence."
        },
        {
            WhyChooseheading: "Hassle-Free Booking Process:",
            WhyChoosedescription: "Our booking process is simple and efficient. You can easily reserve a bus through our website or by contacting our customer support team, making the entire process smooth and convenient."
        },
        {
            WhyChooseheading: "Comprehensive Coverage:",
            WhyChoosedescription: "Whether you need a 14-seater bus for local travel within Pune or for outstation trips, SSKTravels24 has you covered. We offer services for various destinations and occasions."
        }
    ]    
    };    

    
   
    const faqData = [
        {
            question: "How can I book a 14-seater bus with SSKTravels24?",
            answer: "You can book a 14-seater bus by calling us at +91 8956170493 or through our website. Our team will assist you with the booking process and provide all necessary information."
        },
        {
            question: "What is included in the rental of a 14-seater bus?",
            answer: "Our rental package includes a well-maintained 14-seater bus, an experienced driver, and modern amenities. Additional services or features can be requested and may incur extra charges."
        },
        {
            question: "Are there any hidden fees in the rental cost?",
            answer: "No, we provide transparent pricing with no hidden fees. Any additional costs, such as for extra mileage or extended rental periods, will be clearly communicated before you finalize your booking."
        },
        {
            question: "Can I request a non-AC or AC bus?",
            answer: "Yes, we offer both AC and non-AC options for our 14-seater buses. You can specify your preference at the time of booking to ensure your comfort during the trip."
        },
        {
            question: "What if I need to cancel or change my booking?",
            answer: "If you need to cancel or modify your booking, please contact our customer support team as soon as possible. We will guide you through the process and inform you of any applicable cancellation fees."
        },
        {
            question: "How are your buses maintained?",
            answer: "Our buses undergo regular inspections and maintenance to ensure they are in excellent condition. We prioritize safety and comfort, so you can travel with peace of mind."
        },
        {
            question: "Can the bus be used for both local and outstation travel?",
            answer: "Yes, our 14-seater buses are suitable for both local travel within Pune and outstation trips. We provide flexible rental options to accommodate different travel needs."
        },
        {
            question: "What amenities are available on the bus?",
            answer: "Our 14-seater buses come equipped with amenities such as air conditioning, comfortable seating, and music systems. Additional features can be requested based on your needs."
        },
        {
            question: "How do you ensure the safety of passengers?",
            answer: "We prioritize safety by employing experienced drivers and maintaining our buses to high standards. Additionally, our drivers are trained to handle various road conditions and ensure a safe journey."
        },
        {
            question: "What should I do if I encounter any issues during the trip?",
            answer: "If you experience any issues during your trip, please contact our customer support team immediately. We are available to address any concerns and ensure a smooth travel experience."
        }
    ];
    
      

    const testimonials = [
        {
            name: "Miss Ananya Rao",
            text: "We recently hired a 14-seater Tempo Traveller from SSKTravels24 for a family trip from Pune to Mahabaleshwar, and the experience was fantastic. The vehicle was in pristine condition, with comfortable seating and excellent air conditioning that made the journey pleasant despite the warm weather. Our driver was punctual, professional, and ensured we had a smooth ride throughout. The whole process from booking to the trip was seamless. SSKTravels24 exceeded our expectations with their top-notch service. Highly recommended for anyone needing a reliable travel option!"
        },
        {
            name: "Mr. Vikram Sharma",
            text: "SSKTravels24 provided us with a 14-seater Tempo Traveller for our corporate team outing, and it was the perfect choice. The bus was spacious, clean, and equipped with modern amenities, making our trip both comfortable and enjoyable. The driver was courteous and experienced, navigating the routes effortlessly. The booking process was straightforward, and the pricing was transparent with no hidden fees. We were very impressed with the level of service and will definitely use SSKTravels24 for our future travel needs."
        }
    ];
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: "Contact us for 14-Seater Bus on Rent in Pune",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000",
         
        ],
        email: "booking@ssktravels24.com"
      };


      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 14 Seater Tempo Traveller on Rent | Affordable Rental Services</title>
  <meta name="description" content="Rent a 14 seater tempo traveller in Pune for trips to Shirdi, Mahabaleshwar, and more. We offer competitive rates and options for corporate events and picnics. Book your tempo traveller today!" />
  <meta name="keywords" content="14 Seater bus on rent in Pune, 14 seater tempo traveller on rent Pimpri Chinchwad, Hire 14 seater minibus for picnic, 14 seater bus rental in Pune, 13 seater bus hire in Pune, 14 seater mini bus rental for corporate, 14 seater tempo traveller per km rate in Pune, 14 seater AC bus rental for picnic, 13 seater non AC bus on rent, Pune to Goa 14 seater AC bus rental, Pune to Mahabaleshwar 14 seater tempo traveller, Pune to Shirdi 14 seater bus on rent, Pune to Shirdi tempo traveller hire, Pune Airport to Lonavala tempo traveller on rent, Pune Airport to Shirdi tempo traveller on rent, 14 seater tempo traveller rate per km in Pune, 14 seater tempo traveller hire in Pimpri Chinchwad" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 14 Seater Tempo Traveller on Rent | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for 14 seater tempo travellers in Pune. Enjoy competitive rates for trips to Shirdi, Mahabaleshwar, and more. Contact us to book your journey!" />
  <meta property="og:url" content="https://https://ssktravels24.com//14-seater-tempo-traveller-on-rent" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//14-seater-tempo-traveller-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>14 Seater Tempo Traveller on Rent</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-05.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About Renting a 14-Seater Bus in Pune with SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Seateronrent;
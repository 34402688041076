
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Bushirenearme(){


    const cardData = 
    {
        keyword: 'Bus Hire Near Me Pune',
        heading: 'SSKTravels24: Bus Hire Near Me Pune',
        headingDescription: 'Looking for reliable and convenient bus hire services in Pune? SSKTravels24 offers a diverse range of bus rental options to meet all your transportation needs. Whether you are organizing a corporate event, planning a family outing, or need transportation for a special occasion, we have the perfect bus rental solution for you. Here’s a guide to the top bus hire options available near you with SSKTravels24 in Pune.',
        
        topPlaces : [
            {
                title: "1. Standard 20-Seater Bus",
                description: "For smaller groups needing comfortable transportation, our 20-seater bus is an excellent choice. Ideal for family outings, school trips, or local tours, this bus offers a cozy travel experience with ample seating and essential amenities."
            },
            {
                title: "2. 30-Seater Bus",
                description: "Our 30-seater bus is perfect for medium-sized groups. Whether you’re planning a corporate event, a group outing, or a local tour, this bus provides ample space and comfort, making it a versatile option for various types of group travel within Pune."
            },
            {
                title: "3. 32-Seater Bus",
                description: "The 32-seater bus offers extra room for larger groups. It’s suitable for extended local trips or special events requiring more seating. This bus combines comfort with space, making it a popular choice for diverse travel needs."
            },
            {
                title: "4. 35-Seater Bus",
                description: "For even larger groups, our 35-seater bus provides a comfortable and spacious travel option. Ideal for corporate functions, school excursions, or community events, this bus ensures a smooth and enjoyable journey across Pune."
            },
            {
                title: "5. 40-Seater Bus",
                description: "Our 40-seater bus is designed to accommodate bigger groups comfortably. Whether you're organizing a large family event, a corporate retreat, or a major local tour, this bus offers plenty of room and modern amenities for a pleasant travel experience."
            },
            {
                title: "6. 50-Seater Bus",
                description: "The 50-seater bus is our largest option, ideal for very large groups. Perfect for extensive local travel, corporate events, or big family gatherings, this bus provides maximum comfort and space, ensuring everyone travels together comfortably."
            },
            {
                title: "7. Mini Bus (15-Seater)",
                description: "For more intimate group settings, our 15-seater mini bus is a great choice. It’s ideal for smaller group outings, local tours, or short trips around Pune, offering a comfortable and efficient travel solution."
            },
            {
                title: "8. Luxury Bus",
                description: "If you’re seeking a premium travel experience, our luxury bus rental option is perfect. Equipped with high-end amenities such as plush seating, advanced entertainment systems, and superior comfort, this bus is ideal for special occasions, corporate events, or luxurious local tours."
            },
            {
                title: "9. AC Bus",
                description: "Travel in comfort with our AC bus rentals. Equipped with air conditioning, our buses ensure a cool and comfortable journey, especially during Pune’s warmer months. Perfect for local travel, this option enhances your travel experience with a pleasant environment."
            },
            {
                title: "10. Non-AC Bus",
                description: "For a budget-friendly option, consider our non-AC bus rentals. While economical, these buses still provide reliable and comfortable transportation for local travel. They’re ideal for groups looking for a cost-effective yet dependable travel solution."
            }
        ],
        
        services : [
            {
                name: "Bus Hire on Rent in Katraj, Pune",
                description: "Looking for a reliable bus hire service in Katraj, Pune? SSKTravels24 offers top-notch bus rental solutions tailored to meet your needs. Whether you're planning a corporate event, school trip, or family outing, we have a variety of buses to choose from."
            },
            {
                name: "Bus on Rent in Koregaon Park, Pune",
                description: "For those in Koregaon Park, Pune, SSKTravels24 provides excellent bus rental services. Our buses are well-maintained, comfortable, and perfect for any occasion, including corporate events, weddings, and local tours."
            },
            {
                name: "SSKTravels24 Pune",
                description: "SSKTravels24 is your go-to provider for bus rentals in Pune. We offer a diverse fleet of vehicles, including 35, 36, 40, and 50-seater buses, tailored to your specific needs."
            },
            {
                name: "Bus on Rent in Pimple Gurav, Pune",
                description: "Searching for a bus rental in Pimple Gurav, Pune? SSKTravels24 offers a variety of options to suit different group sizes and requirements."
            },
            {
                name: "35, 36, 40, 50 Seater Bus Rental in Pimpri",
                description: "SSKTravels24 offers a wide range of bus rental options in Pimpri, including 35, 36, 40, and 50-seater buses, perfect for corporate events and large group outings."
            },
            {
                name: "Bus on Rent in Hadapsar, Pune",
                description: "For bus rentals in Hadapsar, Pune, look no further than SSKTravels24. We provide well-maintained and comfortable buses suitable for various events and group sizes."
            },
            {
                name: "Bus on Rent in Wagholi, Pune",
                description: "SSKTravels24 offers convenient bus rental services in Wagholi, Pune. Whether you need a bus for a corporate event, wedding, or family trip, our fleet is ready to meet your needs."
            },
            {
                name: "Hire Bus on Rent at Pune Airport",
                description: "Need a bus rental service at Pune Airport? SSKTravels24 provides efficient and reliable transportation solutions for airport transfers."
            },
            {
                name: "Bus on Rent in Lohegaon, Pune",
                description: "SSKTravels24 offers exceptional bus rental services in Lohegaon, Pune. Our buses are well-equipped to handle various group sizes and travel needs."
            },
            {
                name: "AC Bus on Rent in Wanowrie, Pune",
                description: "For an AC bus rental in Wanowrie, Pune, SSKTravels24 has you covered. Our fleet of air-conditioned buses ensures a comfortable and pleasant journey."
            },
            {
                name: "Bus Hire in Hinjewadi IT Park",
                description: "SSKTravels24 provides reliable bus hire services in Hinjewadi IT Park. Enjoy comfort, punctuality, and affordability with our professional bus rental service."
            },
            {
                name: "AC Bus on Rent in Kothrud, Pune",
                description: "Looking for an AC bus rental in Kothrud, Pune? SSKTravels24 offers a range of air-conditioned buses to ensure a comfortable and enjoyable journey."
            },
            {
                name: "Tempo Traveller on Rent Near Me",
                description: "Searching for a tempo traveller on rent near you? SSKTravels24 offers convenient tempo traveller rentals throughout Pune."
            },
            {
                name: "17 Seater Bus on Rent Near Me Pune",
                description: "Need a 17-seater bus on rent in Pune? SSKTravels24 provides a range of options to fit your group size and travel needs."
            },
            {
                name: "Bus Hire Near Me Pimpri Chinchwad",
                description: "For bus hire services near Pimpri Chinchwad, SSKTravels24 offers reliable and affordable options."
            },
            {
                name: "Bus Hire Near Me Kothrud Pune",
                description: "SSKTravels24 provides excellent bus hire services near Kothrud, Pune. Experience comfort and reliability with our professional bus rental services."
            },
            {
                name: "Tempo Traveller on Rent Near Me Pune",
                description: "Looking for a tempo traveller on rent near you in Pune? SSKTravels24 offers a variety of tempo travellers for different group sizes and travel requirements."
            },
            {
                name: "Mini Bus on Rent in Pune Near Me",
                description: "For mini bus rentals in Pune, SSKTravels24 provides convenient and reliable options, ideal for smaller groups or local travel."
            },
            {
                name: "Bus Hire Near me Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient bus hire services in Pune."
            }
        ],
        tableData : [
            ['Bus Hire on Rent in Katraj Pune', 'Bus On Rent in Koregaon Park Pune'],
            ['SSKTravels24 Pune', 'Bus On Rent in Pimple Gurav Pune'],
            ['35, 36, 40, 50 Seater Bus Rental in Pimpri', 'Bus On Rent in Hadapsar'],
            ['Bus on Rent in Wagholi', 'Hire Bus on Rent at Pune Airport'],
            ['Bus On Rent in Lohegaon Pune', 'AC Bus On Rent in Wanowrie Pune'],
            ['Bus Hire in Hinjewadi IT Park', 'AC Bus On Rent in Kothrud Pune'],
            ['Tempo Traveller on Rent Near Me', '17 Seater Bus on Rent Near Me Pune'],
            ['Bus Hire Near Me Pimpri Chinchwad', 'Bus Hire Near Me Kothrud Pune'],
            ['Tempo Traveller on Rent Near Me Pune', 'Mini Bus on Rent in Pune Near Me']
        ],
    
        whychoose : [
            {
                "WhyChooseheading": "Comprehensive Fleet:",
                "WhyChoosedescription": "Our extensive fleet includes a variety of buses, from luxury coaches to standard options, accommodating different group sizes and preferences. Whether you need a 17-seater bus for a small gathering or a 50-seater bus for a large event, we have the ideal vehicle for you."
            },
            {
                "WhyChooseheading": "Comfort & Convenience:",
                "WhyChoosedescription": "All our buses are well-maintained and equipped with modern amenities such as plush seating, air conditioning, and entertainment systems. We prioritize passenger comfort to ensure an enjoyable travel experience."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our team of experienced and professional drivers is committed to providing safe and smooth transportation. They are knowledgeable about local routes and dedicated to punctuality and exceptional customer service."
            },
            {
                "WhyChooseheading": "Transparent Pricing:",
                "WhyChoosedescription": "At SSKTravels24, we offer competitive and transparent pricing with no hidden fees. Our pricing structure is designed to deliver excellent value while maintaining high service standards."
            },
            {
                "WhyChooseheading": "Flexible Booking Options:",
                "WhyChoosedescription": "We provide flexible booking options tailored to your needs, whether you require a bus for a few hours, a full day, or multiple days. Our straightforward booking process guarantees a hassle-free experience."
            },
            {
                "WhyChooseheading": "24/7 Customer Support:",
                "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with bookings, queries, and any issues that may arise. We are committed to providing timely and responsive support."
            },
            {
                "WhyChooseheading": "Punctuality:",
                "WhyChoosedescription": "We understand the importance of time and strive to ensure that our buses arrive punctually, adhering to your schedule. Our reliable service helps you stay on track and enjoy a stress-free journey."
            },
            {
                "WhyChooseheading": "Tailored Services:",
                "WhyChoosedescription": "Whether it’s for corporate events, school trips, family outings, or special occasions, we customize our services to meet your specific requirements. Our goal is to provide a personalized experience that aligns with your needs."
            }
        ]        
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for hire?",
            answer: "SSKTravels24 offers a diverse range of buses, including luxury coaches, semi-luxury buses, and standard options. We have vehicles that accommodate different group sizes, from 17-seaters to 50-seaters."
        },
        {
            question: "How do I book a bus with SSKTravels24?",
            answer: "You can book a bus through our website, by calling our customer service hotline, or by visiting our office. We recommend booking in advance to secure the best vehicle for your needs."
        },
        {
            question: "Are your buses well-maintained and clean?",
            answer: "Yes, all our buses are regularly serviced and thoroughly cleaned to ensure a comfortable and hygienic travel experience. We take great care in maintaining the quality of our fleet."
        },
        {
            question: "What amenities are included in the buses?",
            answer: "Our buses come equipped with amenities such as comfortable seating, air conditioning, and entertainment systems. The specific features may vary depending on the type of bus you choose."
        },
        {
            question: "How much does it cost to hire a bus?",
            answer: "Our pricing is competitive and varies based on factors such as the type of bus, duration of hire, and distance traveled. We provide transparent pricing with no hidden charges."
        },
        {
            question: "Can I hire a bus for an outstation trip?",
            answer: "Yes, we offer bus hire services for both local and outstation trips. Whether you're planning a day excursion or a longer journey, we can accommodate your travel needs."
        },
        {
            question: "What is your cancellation policy?",
            answer: "We have a cancellation policy in place. You can cancel your booking, but cancellation charges may apply depending on the timing of the cancellation. Please contact our customer support for more details."
        },
        {
            question: "Are your drivers experienced and professional?",
            answer: "Yes, all our drivers are experienced, professional, and trained to provide safe and reliable transportation. They are familiar with local routes and committed to ensuring a smooth journey."
        },
        {
            question: "Do you offer 24/7 customer support?",
            answer: "Yes, our customer support team is available 24/7 to assist with bookings, answer queries, and address any issues you may encounter."
        },
        {
            question: "Can I request a specific bus for my trip?",
            answer: "We will do our best to accommodate your request for a specific type of bus based on availability. Please provide your requirements when making a booking, and we will work to meet your needs."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Arvind Sharma",
            text: "SSKTravels24 made our recent corporate event a complete success! We hired a 40-seater bus for our team outing, and the experience was exceptional. The booking process was simple and efficient, and the bus arrived right on time. It was spotless, comfortable, and equipped with all the essential amenities. The driver was professional and friendly, ensuring a smooth and enjoyable journey for everyone. I highly recommend SSKTravels24 for reliable bus hire services in Pune."
        },
        {
            name: "Miss Priya Salunkhe",
            text: "I was extremely satisfied with the bus rental service from SSKTravels24 for my family’s trip to Lonavala. We needed a 35-seater bus, and they provided a well-maintained and comfortable vehicle. The driver was courteous and knowledgeable about the best routes, making our trip completely hassle-free. The entire process, from booking to the actual journey, was seamless. If you're looking for dependable bus hire near Pune, SSKTravels24 is definitely the way to go!"
        }
    ];
    
    
    const contactData = {
        heading: "Bus Hire Near me Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus Hire Near Me in Pune | Convenient Local Rentals</title>
  <meta name="description" content="Find local bus hire services in Pune for various needs including events, outings, and airport transfers. Affordable options available." />
  <meta name="keywords" content="Bus Hire Near Me Pune, Bus Rental in Pune, Tempo Traveller on Rent, Local Bus Hire, Affordable Bus Rentals" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus Hire Near Me in Pune | Convenient Local Rentals" />
  <meta property="og:description" content="Book convenient bus hire services near you in Pune for corporate outings, picnics, and more. Explore our rental options today!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-hire-near-me-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//bus-hire-near-me-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\neare-me.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for “Bus Hire Near Me Pune” – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Bushirenearme;
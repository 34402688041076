import React from 'react';
import testimonialImage1 from '../images/testimonial/sakshi.png'; // Adjust the path as needed
import testimonialImage2 from '../images/testimonial/1.png'; // Adjust the path as needed
import testimonialImage3 from '../images/testimonial/33.jpg'; // Adjust the path as needed

const testimonialsData = [
  {
    quote: "SSK Travels24 has transformed my travel experience! Their fleet of vehicles is always in excellent condition, making every trip comfortable and enjoyable. The customer service is top-notch; they are always ready to assist with any requests. Highly recommend them for all your travel needs!",
    name: "  -Miss Sakshi Sabale ",
    image: testimonialImage1,
    title: "Excellent Service! Car Rent Service!",
  },
  {
    quote: "SSK Travels24 exceeded my expectations! I rented a luxury SUV for a family trip, and it was spotless and fully equipped. The pickup and drop-off were hassle-free, and the staff made the entire process a breeze. I’ll definitely be using them again!",
    name: "  -Mr. Vikram Singh",
    image: testimonialImage2,
    title: "Excellent Service! Car Rent Service!",
  },
  {
    quote: "I cannot praise SSK Travels24 enough! Their prices are competitive, and the quality of service is outstanding. I felt valued as a customer, and the vehicle I rented was perfect for my needs. Thank you, SSK Travels24, for making my travels stress-free!",
    name: "  -Mr. Rohan Sharma",
    image: testimonialImage3,
    title: "Excellent Service! Car Rent Service!",
  },
];

const Testimonials = () => {
  return (
    <section id="section-testimonials" className="no-top no-bottom  border-danger">
      <div className="container-fluid ">
        <div className="row g-0 p-2 align-items-center">
          {testimonialsData.map((testimonial, index) => (
            <div className="col-md-4" key={index}>
              <div className="de-image-text">
                <div className="d-text">
                  <div className="d-quote id-color"><i className="fa fa-quote-right"></i></div>
                  <h4>{testimonial.title}</h4>
                  
                    {testimonial.quote}
                    <span className="by">{testimonial.name}</span>
                  
                </div>
                <img src={testimonial.image} className="img-fluid" alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

import { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Dynamically load the Google Analytics script
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16778559238";
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics when the script is loaded
    script.onload = () => {
      // Ensure dataLayer is defined
      window.dataLayer = window.dataLayer || [];

      // Define the gtag function
      function gtag() {
        window.dataLayer.push(arguments);
      }

      // Initialize the tracking
      gtag('js', new Date());
      gtag('config', 'AW-16778559238');
    };

    // Cleanup the script if the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null; // No need to render anything to the UI
};

export default GoogleAnalytics;

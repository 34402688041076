
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetooutstation(){






    const cardData = 
    {
      keyword: 'Pune to Outstation Tempo Traveller',
      heading: 'SSKTravels24: Pune to Outstation Tempo Traveller ',
      headingDescription: 'When it comes to exploring destinations beyond Pune, SSKTravels24 offers exceptional outstation cab services designed for comfort, reliability, and affordability. Whether youre heading to a serene hill station, a vibrant city, or a sacred pilgrimage site, our Pune Outstation Cabs ensure a seamless travel experience. Here’s everything you need to know about our services and some top outstation destinations from Pune.',
      
       topPlaces : [
        {
          title: "1. Mumbai",
          description: "The bustling metropolis of Mumbai, just a few hours from Pune, is a hub of commerce, culture, and entertainment. Explore iconic landmarks like the Gateway of India, Marine Drive, and the vibrant markets of Colaba and Bandra for a taste of the city's dynamic lifestyle."
        },
        {
          title: "2. Lonavala and Khandala",
          description: "Perfect for a weekend getaway, the hill stations of Lonavala and Khandala offer stunning landscapes and pleasant weather. Attractions like Bhushi Dam, Karla Caves, and the picturesque Tiger’s Leap make these destinations a favorite for nature lovers."
        },
        {
          title: "3. Mahabaleshwar",
          description: "Known for its lush green hills and panoramic views, Mahabaleshwar is a popular hill station. Enjoy attractions such as Venna Lake, Wilson Point, and the ancient Mahabaleshwar Temple, making it an ideal spot for relaxation and exploration."
        },
        {
          title: "4. Panchgani",
          description: "Located close to Mahabaleshwar, Panchgani is renowned for its beautiful viewpoints, including Table Land and Sydney Point. The charming atmosphere and pleasant climate make it a great retreat for families and friends."
        },
        {
          title: "5. Shirdi",
          description: "A revered pilgrimage site, Shirdi is famous for the Sai Baba Temple. SSKTravels24 offers comfortable rides to this spiritual destination, ensuring a peaceful and stress-free journey for all devotees."
        },
        {
          title: "6. Goa",
          description: "For a longer escape, head to Goa, known for its beautiful beaches, vibrant nightlife, and Portuguese heritage. Enjoy the sun, sand, and sea while exploring popular spots like Baga Beach, Anjuna, and Old Goa."
        },
        {
          title: "7. Aurangabad",
          description: "Visit Aurangabad to explore the historical wonders of Ajanta and Ellora Caves, the Bibi Ka Mebarak, and the Daulatabad Fort. This city offers a rich blend of history and culture, making it a must-visit for history enthusiasts."
        },
        {
          title: "8. Nashik",
          description: "Famous for its vineyards and religious sites, Nashik is home to Sula Vineyard, Panchavati, and the Kalaram Temple. It’s a perfect destination for both wine enthusiasts and spiritual seekers looking for a refreshing getaway."
        },
        {
          title: "9. Alibaug",
          description: "For a coastal escape, Alibaug offers sandy beaches, forts, and a relaxing atmosphere. Enjoy the serene beaches, such as Alibaug Beach and Nagaon Beach, and visit historical sites like the Kolaba Fort for a blend of history and leisure."
        },
        {
          title: "10. Kolkata",
          description: "Although a bit farther away, Kolkata is a cultural capital known for its colonial architecture, museums, and vibrant street food. Explore landmarks like the Victoria Memorial, Howrah Bridge, and the Indian Museum for a taste of the city's rich heritage."
        }
      ],      
    
       services :[
        {
          name: "Pune Outstation Cab Services",
          description: "SSKTravels24 specializes in providing top-notch Pune Outstation Cab Services that cater to various destinations and travel preferences. Our services are designed to offer flexibility, convenience, and a high level of comfort for every journey."
        },
        {
          name: "Pune Outstation Taxi Booking",
          description: "Booking an outstation taxi with SSKTravels24 is straightforward and hassle-free. Our user-friendly booking process allows you to secure your ride quickly through our online platform or by contacting our customer support team."
        },
        {
          name: "Pune Outstation Car Rental",
          description: "For those who prefer to drive themselves, SSKTravels24 offers a range of Pune Outstation Car Rental options. Choose from our well-maintained fleet and enjoy the freedom of driving to your chosen destination at your own pace."
        },
        {
          name: "Pune Outstation Chauffeur-Driven Cabs",
          description: "Experience the convenience of Pune Outstation Chauffeur-Driven Cabs with SSKTravels24. Our professional drivers are well-versed with various routes, ensuring a smooth and stress-free journey while you relax and enjoy the ride."
        },
        {
          name: "Pune Outstation One-Way Taxi",
          description: "Planning a trip with a single destination in mind? Our Pune Outstation One-Way Taxi service provides a cost-effective solution for one-way travel, allowing you to reach your destination without the hassle of returning to Pune."
        },
        {
          name: "Pune Outstation Round Trip Cabs",
          description: "For a more comprehensive travel experience, opt for our Pune Outstation Round Trip Cabs. This service is perfect for those who wish to explore a destination and return to Pune, offering convenience and value for money."
        },
        {
          name: "Pune Outstation Luxury Taxi",
          description: "Travel in style with our Pune Outstation Luxury Taxi service. Enjoy premium comfort and high-end amenities in our luxurious vehicles, making your outstation journey a memorable experience."
        },
        {
          name: "Pune Outstation Self-Drive Car Rental",
          description: "If you prefer to drive yourself, our Pune Outstation Self-Drive Car Rental service gives you the flexibility to explore at your own pace. Choose from a range of well-maintained vehicles and enjoy a personalized travel experience."
        },
        {
          name: "Pune Outstation Travel Packages",
          description: "Make your trip more enjoyable with our Pune Outstation Travel Packages. We offer customized packages that include transportation, accommodation, and sightseeing options, tailored to your preferences and needs."
        },
        {
          name: "Pune Outstation Private Car Hire",
          description: "For a more personalized travel experience, opt for our Pune Outstation Private Car Hire service. Enjoy the privacy and comfort of a dedicated vehicle for your journey, with flexible options to suit your schedule and preferences."
        },
        {
          name: "Pune Outstation Cabs",
          description: "When you need reliable and comfortable Pune Outstation Cabs, SSKTravels24 has got you covered. Our fleet of well-maintained vehicles and professional drivers ensure a smooth and enjoyable travel experience."
        },
        {
          name: "Outstation Taxi Service Pune",
          description: "Our Outstation Taxi Service in Pune provides a wide range of options to suit different travel needs. From budget-friendly choices to luxury rides, we offer services that cater to all types of travellers."
        },
        {
          name: "One Way Cab Pune",
          description: "For single journeys to outstation destinations, our One-Way Cab Pune service offers a cost-effective and convenient solution. Book a one-way cab and enjoy a comfortable ride without the need to return to Pune."
        },
        {
          name: "Best Outstation Cab Service in Pune",
          description: "SSKTravels24 is renowned for being the Best Outstation Cab Service in Pune. Our commitment to transparency, reliability, and customer satisfaction sets us apart from the competition, making us the preferred choice for outstation travel."
        },
        {
          name: "Outstation Cab Service in Pune",
          description: "When looking for an Outstation Cab Service in Pune, SSKTravels24 offers unparalleled service quality and competitive rates. Whether you need a quick getaway or an extended trip, our services are designed to meet your needs efficiently and comfortably."
        },
        {
          name: "Outstation Car Rental Pune",
          description: "For a flexible and personalized travel experience, choose Outstation Car Rental Pune with SSKTravels24. Our fleet of well-maintained vehicles is available for rental, allowing you to drive yourself to your destination while enjoying the freedom to explore at your own pace."
        },
        {
          name: "Taxi in Pune for Outstation",
          description: "Need a reliable ride for your outstation journey? Our Taxi in Pune for Outstation service offers a wide selection of vehicles and professional drivers to ensure a comfortable and efficient trip."
        },
        {
          name: "Taxi Service in Pune for Outstation",
          description: "SSKTravels24 offers comprehensive Taxi Service in Pune for Outstation, designed to cater to various travel needs. Our services include one-way and round-trip options, with a focus on customer satisfaction and punctuality."
        },
        {
          name: "Book Outstation Cabs Pune",
          description: "Booking an outstation cab has never been easier with SSKTravels24. Simply Book Outstation Cabs Pune through our user-friendly online platform or by contacting our customer support team."
        },
        {
          name: "Cab Booking in Pune for Outstation",
          description: "When you need to Cab Booking in Pune for Outstation, SSKTravels24 offers a straightforward and convenient process. Our booking system allows you to choose your vehicle, specify your destination, and get an accurate fare estimate."
        },
        {
          name: "Outstation Taxi in Pune",
          description: "Our Outstation Taxi in Pune service is designed to meet the needs of travellers heading beyond the city limits. With a variety of vehicle options and competitive rates, we ensure that you receive quality service for your outstation trips."
        },
        {
          name: "Outstation Taxi Service in Pune",
          description: "Experience top-notch Outstation Taxi Service in Pune with SSKTravels24. Our dedicated team provides reliable and comfortable taxi services for various outstation destinations."
        },
        {
          name: "Pune Airport to Kolhapur Cab",
          description: "For a smooth journey from Pune Airport to Kolhapur, choose SSKTravels24. Our service provides convenient and comfortable transportation from the airport to Kolhapur."
        },
        {
          name: "Pune Car Rental Outstation",
          description: "If you prefer driving yourself, our Pune Car Rental Outstation service offers a range of rental options. Select a well-maintained vehicle from our fleet and embark on your outstation journey."
        },
        {
          name: "Pune to Gokarna Cab",
          description: "Heading to the serene beaches of Gokarna? Our Pune to Gokarna Cab service provides comfortable and reliable transportation to this popular destination."
        },
        {
          name: "Pune to Outstation Taxi",
          description: "For all your outstation travel needs, Pune to Outstation Taxi services from SSKTravels24 offer a reliable and convenient solution."
        },
        {
          name: "Pune Outstation Cabs Contact Number",
          description: "Contact SSKTravels24 at +91 9673101100 for prompt and efficient Pune Cab Service. We ensure a smooth and enjoyable ride for all our customers."
        }
      ],      
       tableData : [
        ['Pune Outstation Cab Services', 'Pune Outstation Taxi Booking'],
        ['Pune Outstation Car Rental', 'Pune Outstation Chauffeur-Driven Cabs'],
        ['Pune Outstation One-Way Taxi', 'Pune Outstation Round Trip Cabs'],
        ['Pune Outstation Luxury Taxi', 'Pune Outstation Self-Drive Car Rental'],
        ['Pune Outstation Travel Packages', 'Pune Outstation Private Car Hire'],
        ['Pune Outstation Cabs', 'Outstation Taxi Service Pune'],
        ['One Way Cab Pune', 'Best Outstation Cab Service in Pune'],
        ['Outstation Cab Service in Pune', 'Outstation Car Rental Pune'],
        ['Taxi in Pune for Outstation', 'Taxi Service in Pune for Outstation'],
        ['Book Outstation Cabs Pune', 'Cab Booking in Pune for Outstation'],
        ['Outstation Taxi in Pune', 'Outstation Taxi Service in Pune'],
        ['Pune Airport to Kolhapur Cab', 'Pune Car Rental Outstation'],
        ['Pune to Gokarna Cab', 'Pune to Outstation Taxi']
      ],      
       whychoose : [
        {
            "WhyChooseheading": "Why Choose SSKTravels24 for Pune to Mahabaleshwar Tempo Traveller?",
            "WhyChoosedescription": "When it comes to renting a Tempo Traveller for your journey from Pune to Mahabaleshwar, SSKTravels24 is your ideal provider. Our commitment to comfort, reliability, and customer satisfaction ensures that your travel experience is both enjoyable and hassle-free. Here’s why SSKTravels24 stands out:"
        },
        {
            "WhyChooseheading": "Transparent Pricing:",
            "WhyChoosedescription": "At SSKTravels24, we believe in clear and upfront pricing with no hidden fees. You receive a detailed fare estimate before you book, ensuring there are no surprises along the way."
        },
        {
            "WhyChooseheading": "Wide Range of Vehicles:",
            "WhyChoosedescription": "Our diverse fleet includes everything from budget-friendly sedans to luxurious SUVs. Whether you’re traveling alone or with a group, we have the perfect vehicle to suit your needs."
        },
        {
            "WhyChooseheading": "Professional Drivers:",
            "WhyChoosedescription": "Our drivers are experienced, courteous, and well-versed with the best routes. They are dedicated to providing a safe, smooth, and enjoyable journey, allowing you to relax and focus on your travel."
        },
        {
            "WhyChooseheading": "Reliable Service:",
            "WhyChoosedescription": "We pride ourselves on punctuality and dependability. With SSKTravels24, you can trust that your cab will arrive on time and get you to your destination efficiently."
        },
        {
            "WhyChooseheading": "Easy Booking Process:",
            "WhyChoosedescription": "Booking a cab with us is straightforward. Use our online platform or contact our customer support team to secure your ride quickly and easily, with flexible payment options."
        },
        {
            "WhyChooseheading": "Flexible Options:",
            "WhyChoosedescription": "Whether you need a one-way trip, round-trip, or a custom package, we offer various options to cater to your specific travel needs. Our services are designed to accommodate your schedule and preferences."
        },
        {
            "WhyChooseheading": "Comfortable Rides:",
            "WhyChoosedescription": "We ensure that all our vehicles are well-maintained and equipped with comfortable amenities to make your journey enjoyable and stress-free."
        },
        {
            "WhyChooseheading": "Excellent Customer Support:",
            "WhyChoosedescription": "Our dedicated customer support team is available to assist you with any queries or concerns, ensuring a smooth and pleasant experience from start to finish."
        }
    ]    
    };    

    
   
    const faqData = [
        {
            question: "What destinations do you cover for outstation cabs from Pune?",
            answer: "We cover a wide range of destinations including Mumbai, Lonavala, Mahabaleshwar, Shirdi, Goa, Nashik, and many more. If you have a specific destination in mind, please contact us to check availability."
        },
        {
            question: "How do I book an outstation cab with SSKTravels24?",
            answer: "You can book an outstation cab through our user-friendly online booking system or by contacting our customer support team directly. We offer flexible booking options to suit your needs."
        },
        {
            question: "Are there any additional charges for outstation cabs?",
            answer: "We provide a detailed fare estimate before you book, which includes all applicable charges. There are no hidden fees, and you will be informed of any additional costs such as tolls or parking in advance."
        },
        {
            question: "Can I modify or cancel my outstation cab booking?",
            answer: "Yes, you can modify or cancel your booking as per our policy. Please contact our customer support team for assistance with any changes to your reservation."
        },
        {
            question: "What types of vehicles are available for outstation travel?",
            answer: "We offer a range of vehicles including sedans, SUVs, luxury cars, and self-drive options. Choose the vehicle that best suits your travel needs and preferences."
        },
        {
            question: "Is there a limit on the distance for outstation trips?",
            answer: "We accommodate both short and long-distance outstation trips. If you have a specific distance or destination in mind, please let us know, and we will provide the necessary arrangements."
        },
        {
            question: "How can I contact SSKTravels24 for support or inquiries?",
            answer: "You can reach our customer support team via phone, email, or through our website. We are available to assist you with any questions or concerns regarding your outstation cab service."
        },
        {
            question: "What if I need a cab for a long-duration outstation trip?",
            answer: "We offer customized packages for long-duration trips. Contact us to discuss your requirements, and we will tailor a package that meets your needs and ensures a comfortable journey."
        }
    ];
    
      

    const testimonials = [
        {
            name: "Mrs. Aditi Sharma",
            text: "I recently used SSKTravels24 for an outstation trip from Pune to Mumbai, and I couldn't be more impressed. The booking process was smooth and transparent, with no hidden charges. The driver was punctual, professional, and made sure we had a comfortable journey. The vehicle was clean and well-maintained, which made the trip even more pleasant. I highly recommend SSKTravels24 for anyone looking for reliable and efficient outstation cab services!"
        },
        {
            name: "Mr. Rajesh Kulkarni",
            text: "SSKTravels24 made our outstation trip from Pune to Mahabaleshwar a delightful experience. The cab was spacious and comfortable, and the driver was very courteous and knowledgeable about the best routes. We appreciated the flexibility in our schedule and the clear communication throughout the process. The fare was reasonable, and the service exceeded our expectations. I will definitely choose SSKTravels24 for all my future outstation travels!"
        }
    ];
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: "Pune Outstation Cab Contact Number: ",
        contactNumbers: [
          "+91 8956170493",
          "+91 7276307000",
         
        ],
        email: "booking@ssktravels24.com"
      };


      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Outstation Tempo Traveller | Affordable Rental Services</title>
  <meta name="description" content="Rent a tempo traveller from Pune for outstation trips to Goa, Kolhapur, Nashik, and more. We offer competitive rates for 17-seater and luxury options. Book your outstation journey today!" />
  <meta name="keywords" content="Tempo traveller Pune rent, Pune tempo traveller hire for outstation, Pune to outstation tempo traveller, Pune to Goa tempo traveller, Pune to Kolhapur tempo traveller rent, Pune tempo traveller hire for outstation, Pune to Nashik tempo traveller rent, Pune to Mumbai tempo traveller rent, Pune to Pandharpur tempo traveller fare, Pune Mumbai Airport drop tempo traveller on rent, Tempo traveller on rent for wedding in Pune, 17 seater tempo traveller for outstation in Pune, Luxury tempo traveller on rent in Pune, Outstation tempo traveller for picnic on rent in Pune, Pune to outstation tempo traveller rate in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Outstation Tempo Traveller | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best tempo traveller rental services for outstation trips from Pune. Enjoy competitive rates for 17-seater and luxury options. Contact us to book your journey!" />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-outstation-tempo-traveller" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//outstation-tempo-traveller-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Pune to Outstation Tempo Traveller</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-03.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Pune Outstation Cabs for SSKTravels24?</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetooutstation;
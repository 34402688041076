
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetolonavala(){


    const cardData = 
    {
        keyword: ' Pune to Lonavala Bus Hire',
        heading: 'SSKTravels24: Pune to Lonavala Bus Hire',
        headingDescription: 'Experience the beauty of Mahabaleshwar with our luxurious Force Urbania buses for rent. Enjoy a comfortChoose SSKTravels24 for a seamless and comfortable journey. Our reliable bus hire service ensures you travel in style and comfort, offering a variety of bus options to meet your needs. Whether you are organizing a family outing, corporate trip, or school excursion, our well-maintained buses are ready to take you to Lonavala, one of Maharashtra’s most enchanting hill stations.',
        
        topPlaces : [
            {
                title: "1. Lonavala Lake",
                description: "A tranquil spot perfect for relaxation, Lonavala Lake is surrounded by lush greenery, offering a serene environment and a popular picnic spot."
            },
            {
                title: "2. Karla Caves",
                description: "These ancient Buddhist rock-cut caves showcase significant historical and architectural marvels, renowned for their intricate carvings and sculptures."
            },
            {
                title: "3. Bhaja Caves",
                description: "Known for their stunning architecture and historical significance, the Bhaja Caves are perched on a hill and offer panoramic views of the surrounding landscape."
            },
            {
                title: "4. Rajmachi Fort",
                description: "A popular trekking destination, Rajmachi Fort provides breathtaking views of the Sahyadri mountain range and is ideal for adventure enthusiasts."
            },
            {
                title: "5. Duke’s Nose",
                description: "Also known as Nagphani, Duke’s Nose resembles a snake’s hood and is a favorite spot for trekking, offering magnificent views of the hills and valleys."
            },
            {
                title: "6. Lion’s Point",
                description: "Famous for stunning sunset views, Lion’s Point is a picturesque vantage point surrounded by lush greenery, perfect for capturing the beauty of Lonavala."
            },
            {
                title: "7. Pawna Lake",
                description: "An artificial reservoir, Pawna Lake is ideal for picnics, camping, and water activities, surrounded by hills for a serene retreat."
            },
            {
                title: "8. Tung Fort",
                description: "A challenging trek leads to Tung Fort, rewarding hikers with spectacular views of the valleys and nearby forts."
            },
            {
                title: "9. Khandala",
                description: "Just a short drive from Lonavala, Khandala is known for its scenic beauty and pleasant weather, with attractions like Amrutanjan Point and Khandala Lake."
            },
            {
                title: "10. Bhushi Dam",
                description: "A popular spot during monsoon, Bhushi Dam is known for its cascading waterfalls, making it a great place to enjoy refreshing showers and scenic views."
            }
        ],        
        
        services : [
            {
                name: "Tempo Travellers On Rent for Lonavala",
                description: "Planning a group trip to Lonavala? SSKTravels24 offers premium tempo travellers on rent for your journey from Pune to Lonavala. Our well-maintained tempo travellers provide ample space and comfort for an enjoyable trip."
            },
            {
                name: "Pune to Lonavala Urbania Bus on Rent",
                description: "For a luxurious travel experience, choose our Urbania bus on rent for your journey from Pune to Lonavala. Enjoy a smooth ride with ample space and modern amenities."
            },
            {
                name: "Pune to Khandala Bus Hire Package",
                description: "Explore Khandala’s scenic beauty with our exclusive bus hire package, ensuring a comfortable and enjoyable journey."
            },
            {
                name: "Pune to Lonavala 17-Seater Bus Hire Package",
                description: "For smaller groups, our 17-seater bus hire package is perfect, equipped with modern amenities for a comfortable journey."
            },
            {
                name: "17-Seater Bus Rate Per Km in Pimpri Chinchwad",
                description: "Looking for competitive rates? SSKTravels24 offers transparent pricing based on distance and duration."
            },
            {
                name: "Pune to Lonavala Khandala Bus Tour Package",
                description: "Combine the best of both worlds with our tour package, ensuring a well-organized experience with customized itineraries."
            },
            {
                name: "Pune to Lonavala 14, 17, 20, 25, 32-Seater Bus on Rent",
                description: "No matter the size of your group, we have the right bus for you, ensuring comfort with all necessary amenities."
            },
            {
                name: "Pune to Lonavala 35, 40, 45, 50-Seater Bus on Rent",
                description: "For larger groups, our buses provide ample space and comfort for your journey."
            },
            {
                name: "Pune to Lonavala 2-Day Bus Package",
                description: "Maximize your trip with our flexible and affordable 2-day bus package, including comfortable transport and a customized itinerary."
            },
            {
                name: "Pune to Khandala Mini Bus Package",
                description: "Perfect for group travel, our mini bus package provides comfort and efficiency tailored to your needs."
            },
            {
                name: "Pune to Lonavala Corporate Bus Package",
                description: "Specialized packages for corporate events, ensuring comfortable transportation for your team."
            },
            {
                name: "Pune to Lonavala Corporate Event Package",
                description: "Tailored packages to ensure smooth transportation for team-building activities or company retreats."
            },
            {
                name: "Pune to Lonavala Bus on Rent",
                description: "Our bus rental service is ideal for group travel, accommodating you comfortably with well-maintained vehicles."
            },
            {
                name: "Pune to Lonavala Tourist Bus on Rent",
                description: "For a sightseeing adventure, our tourist buses provide a comfortable journey while exploring Lonavala."
            },
            {
                name: "Pune to Lonavala Urbania on Rent",
                description: "Experience luxury with our Urbania rentals, perfect for high-profile groups or special occasions."
            },
            {
                name: "Pune to Lonavala Tempo Traveller on Rent",
                description: "Our tempo traveller service is suitable for both small and large groups, ensuring a comfortable ride."
            },
            {
                name: "Pune to Lonavala Full Day Tempo Traveller on Rent",
                description: "Our full-day tempo traveller rental ensures you have a reliable vehicle throughout the day."
            },
            {
                name: "Mini Bus on Rent for Pune to Lonavala",
                description: "For compact group travel, our mini buses offer comfort and ease."
            },
            {
                name: "Tata Winger on Rent Pune to Lonavala",
                description: "Versatile rentals for your journey, suitable for both corporate events and casual trips."
            },
            {
                name: "One Day Trip from Pune to Lonavala",
                description: "Plan a memorable one-day trip with various vehicles for a comfortable journey."
            },
            {
                name: "SSKTravels24 Pune On Rent",
                description: "For all your rental needs, SSKTravels24 is your trusted partner, ensuring a smooth and enjoyable journey."
            },
            {
                name: "Mini Bus On Rent in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Mini Bus On Rent services."
            }
        ],
        tableData : [
            ['- Tempo Travellers On Rent For Lonavala in Pune', '- Pune to Lonavala Urbania Bus on Rent'],
            ['- Pune to Khandala Bus Hire Package', '- Pune to Lonavala 17-Seater Bus Hire Package'],
            ['- 17-Seater Bus Rate Per Km in Pimpri Chinchwad', '- Pune to Lonavala Khandala Bus Tour Package'],
            ['- Pune to Lonavala 14, 17, 20, 25, 32-Seater Bus on Rent', '- Pune to Lonavala 35, 40, 45, 50-Seater Bus on Rent'],
            ['- Pune to Lonavala 2-Day Bus Package', '- Pune to Khandala Mini Bus Package'],
            ['- Pune to Lonavala Corporate Bus Package', '- Pune to Lonavala Corporate Event Package'],
            ['- Pune to Lonavala Bus on Rent', '- Pune to Lonavala Tourist Bus on Rent'],
            ['- Pune to Lonavala Urbania on Rent', '- Pune to Lonavala Tempo Traveller on Rent'],
            ['- Pune to Lonavala Full Day Tempo Traveller on Rent', '- Mini Bus on Rent for Pune to Lonavala'],
            ['- Tata Winger on Rent Pune to Lonavala', '- One Day Trip from Pune to Lonavala']
        ],
    
        whychoose : [
            {
                "WhyChooseheading": "Why Choose Us for Pune to Lonavala Bus Hire – SSKTravels24",
                "WhyChoosedescription": "Extensive Fleet of Vehicles: We offer a diverse range of buses, ensuring you find the perfect size for your group, whether it’s a cozy 17-seater or a spacious 50-seater."
            },
            {
                "WhyChooseheading": "Comfort and Convenience:",
                "WhyChoosedescription": "Our buses are designed with your comfort in mind, featuring spacious seating, air conditioning, and modern amenities to ensure an enjoyable journey."
            },
            {
                "WhyChooseheading": "Experienced and Professional Drivers:",
                "WhyChoosedescription": "Our skilled drivers know the Pune to Lonavala route well, prioritizing safety and punctuality for a smooth trip."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "We provide transparent and competitive pricing with no hidden charges, making our services budget-friendly."
            },
            {
                "WhyChooseheading": "Flexible Booking Options:",
                "WhyChoosedescription": "Our booking options are tailored to meet your travel needs, whether it’s a day trip or an extended stay."
            },
            {
                "WhyChooseheading": "Punctual and Reliable Service:",
                "WhyChoosedescription": "We pride ourselves on timeliness, ensuring our buses arrive on time for a hassle-free travel experience."
            },
            {
                "WhyChooseheading": "Personalized Customer Service:",
                "WhyChoosedescription": "Our dedicated team is here to assist with any queries or special requests, ensuring a seamless booking and travel experience."
            },
            {
                "WhyChooseheading": "Clean and Well-Maintained Vehicles:",
                "WhyChoosedescription": "We prioritize cleanliness and maintenance, regularly inspecting our buses to ensure they are in excellent condition."
            },
            {
                "WhyChooseheading": "Safe and Secure Travel:",
                "WhyChoosedescription": "Safety is our top priority, with well-equipped buses and trained drivers to ensure a secure travel experience."
            },
            {
                "WhyChooseheading": "Positive Customer Feedback:",
                "WhyChoosedescription": "We have built a strong reputation for reliability and satisfaction, consistently receiving praise from our clients."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "How can I book a bus from Pune to Lonavala with SSKTravels24?",
            answer: "You can book a bus by visiting our website, calling our customer service hotline, or visiting our office in person. We offer convenient booking options to suit your preferences."
        },
        {
            question: "What types of buses are available for the Pune to Lonavala route?",
            answer: "We offer a range of buses, including 17-seaters, 32-seaters, 35-seaters, 40-seaters, and 50-seaters. Choose the size that best fits your group and travel needs."
        },
        {
            question: "Are there any special amenities provided on the buses?",
            answer: "Yes, our buses come equipped with comfortable seating, air conditioning, and other amenities to enhance your travel experience. The specific amenities may vary depending on the bus type."
        },
        {
            question: "What is the cost of hiring a bus from Pune to Lonavala?",
            answer: "The cost varies based on factors such as bus size, duration of hire, and any additional services. Contact us for a customized quote based on your specific needs."
        },
        {
            question: "Can I hire a bus for a day trip or multiple days?",
            answer: "Yes, we offer flexible booking options for both day trips and extended journeys. You can choose the duration that best suits your travel plans."
        },
        {
            question: "Are your drivers experienced and reliable?",
            answer: "Absolutely! Our drivers are experienced, professional, and well-versed in the Pune to Lonavala route. They are dedicated to ensuring a safe and smooth journey."
        },
        {
            question: "How far in advance should I book a bus?",
            answer: "We recommend booking as early as possible to secure your preferred bus and date. Early booking helps with better planning and availability."
        },
        {
            question: "What is your cancellation policy?",
            answer: "Our cancellation policy includes specific terms and conditions, with potential charges based on the timing of the cancellation. Please contact our customer service team for detailed information."
        },
        {
            question: "Are the buses cleaned and maintained regularly?",
            answer: "Yes, we ensure that all our buses are thoroughly cleaned and regularly maintained to provide a comfortable and hygienic travel experience."
        },
        {
            question: "Can I request additional services or special requirements?",
            answer: "Yes, we can accommodate special requests or additional services based on availability. Please inform us of any specific needs when making your booking."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mrs. Priya Sharma",
            text: "Our trip from Pune to Lonavala with SSKTravels24 was absolutely fantastic! We hired a 32-seater bus for our family reunion, and the experience was seamless from start to finish. The bus was clean, comfortable, and equipped with amenities that made the journey enjoyable for everyone. Our driver was professional and friendly, ensuring we arrived on time and safely. The booking process was straightforward, and the customer service was exceptional. I highly recommend SSKTravels24 for anyone looking to hire a bus for a trip to Lonavala. We will definitely choose their services again!"
        },
        {
            name: "Mr. Sandeep Kulkarni",
            text: "I recently booked a 50-seater bus from Pune to Lonavala for a corporate outing with SSKTravels24. The entire experience was smooth and hassle-free. The bus was spacious and well-maintained, providing a comfortable ride for our team. Our driver was punctual, courteous, and knowledgeable about the best routes. The service exceeded our expectations, and the entire team was very satisfied with the journey. If you’re looking for reliable and comfortable bus hire for a trip to Lonavala, SSKTravels24 is the way to go!"
        }
    ];
    
    
    const contactData = {
        heading: "Pune to Lonavala Bus Hire Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Lonavala Bus Hire | Affordable Bus Rental</title>
  <meta name="description" content="Book your Pune to Lonavala bus hire with options for 14 to 50 seater buses, tempo travellers, and packages for corporate events and tours." />
  <meta name="keywords" content="Pune to Lonavala Bus Hire, Tempo Travellers on Rent, Lonavala Tour Package, Corporate Bus Package Pune to Lonavala, Affordable Bus Rental" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Lonavala Bus Hire | Affordable Bus Rental" />
  <meta property="og:description" content="Explore comfortable bus rental options for your trip from Pune to Lonavala, including corporate packages and mini bus options." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-to-lonavala-bus-hire" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//lonavala-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-08.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for “Pune to Lonavala Bus Hire” – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetolonavala;
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
const faqs = [
  {
    question: "What services does SSK Travels24 offer?",
    answer: "SSK Travels24 offers a wide range of travel services, including outstation taxi bookings, airport transfers, corporate travel solutions, and local city tours. We also provide luxury car rentals and tempo travellers for group travel."
  },
  {
    question: "How can I book a taxi with SSK Travels24?",
    answer: "You can easily book a taxi with SSK Travels24 through our website, mobile app, or by calling our customer service. Simply enter your travel details, choose the vehicle type, and confirm your booking."
  },
  {
    question: "What types of vehicles are available for booking?",
    answer: "SSK Travels24 provides a variety of vehicles, including hatchbacks, sedans, SUVs, luxury cars, and tempo travellers. Whether you're traveling solo, with family, or in a group, we have the right vehicle to meet your needs."
  },
  {
    question: "Is there 24/7 customer support available?",
    answer: "Yes, SSK Travels24 offers 24/7 customer support to assist you with bookings, queries, or any issues during your trip. You can contact us via phone, email, or live chat."
  },
  {
    question: "Are your drivers experienced and professional?",
    answer: "All drivers at SSK Travels24 are experienced, professional, and background-verified to ensure your safety and a smooth travel experience. They are also familiar with local routes and can provide a comfortable journey."
  },
  {
    question: "Can I modify or cancel my booking?",
    answer: "Yes, you can modify or cancel your booking with SSK Travels24. Cancellations or modifications can be made through our website or by contacting our customer support, subject to our cancellation policy."
  },
  {
    question: "Do you offer intercity travel services?",
    answer: "Yes, SSK Travels24 offers intercity travel services, allowing you to travel between cities in a comfortable and convenient manner. You can book taxis for both short and long-distance intercity travel."
  },
  {
    question: "Are there any additional charges for airport transfers?",
    answer: "Airport transfers may have additional charges based on the distance, time, and type of vehicle you choose. These charges are clearly displayed during the booking process, so you will know the exact fare in advance."
  },
  {
    question: "Can I request a specific driver for my trip?",
    answer: "Currently, we cannot guarantee a specific driver, but we ensure that all our drivers are highly professional and experienced. You can rest assured of a safe and comfortable journey."
  },
  {
    question: "What should I do if I have a complaint or issue during my trip?",
    answer: "If you face any issues or have a complaint during your trip, please contact our 24/7 customer support immediately. Our team will assist you and work to resolve the situation promptly."
  },
  {
    question: "Do you provide car rentals for corporate events?",
    answer: "Yes, SSK Travels24 provides car rental services for corporate events, meetings, and conferences. We offer a variety of vehicles to suit your company's needs, including luxury cars and larger vehicles for group transportation."
  },
  {
    question: "Is there a loyalty program or discount for frequent customers?",
    answer: "Yes, we offer special discounts and loyalty rewards for our frequent customers. You can earn points and enjoy discounts on future bookings by joining our loyalty program."
  }
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
       <Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1>Faqs</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-faq">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h2>Have Any Questions?</h2>
              <div className="spacer-20"></div>
            </div>
          </div>
          <div className="row g-custom-x ">
            <div className="col-md-6 wow fadeInUp">
              <div className="accordion secondary">
                {faqs.slice(0, 6).map((faq, index) => (
                  <div className="accordion-section" key={index}>
                    <div
                      className="accordion-section-title"
                      onClick={() => toggleAccordion(index)}
                      style={{
                        backgroundColor: '#2F305E', // Background color for questions
                        color: '#fff',
                        padding: '15px',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        marginBottom: '10px',
                      }}
                    >
                      {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div
                        className="accordion-section-content"
                        style={{
                          backgroundColor: '#e9f5ff', // Background color for answers
                          padding: '15px',
                          borderRadius: '5px',
                          marginBottom: '10px',
                        }}
                      >
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="col-md-6 wow fadeInUp">
              <div className="accordion secondary">
                {faqs.slice(6).map((faq, index) => (
                  <div className="accordion-section" key={index + 3}>
                    <div
                      className="accordion-section-title"
                      onClick={() => toggleAccordion(index + 3)}
                      style={{
                        backgroundColor: '#2F305E', // Background color for questions
                        color: '#fff',
                        padding: '15px',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        marginBottom: '10px',
                      }}
                    >
                      {faq.question}
                    </div>
                    {activeIndex === index + 3 && (
                      <div
                        className="accordion-section-content"
                        style={{
                          backgroundColor: '#e9f5ff', // Background color for answers
                          padding: '15px',
                          borderRadius: '5px',
                          marginBottom: '10px',
                        }}
                      >
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQSection;

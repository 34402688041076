
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busonrentinpune(){


    const cardData = 
    {
        keyword: 'Bus on Rent in Pune',
        heading: 'Bus on Rent in Pune: SSKTravels24',
        headingDescription: 'If you’re in search of reliable and affordable bus rental services in Pune, SSKTravels24 is your ideal choice. We offer a diverse fleet of buses tailored for various needs, from corporate events to school trips and private tours. Here’s a guide to our top bus rental options in Pune, ensuring comfort, safety, and convenience for every journey.',
        
        topPlaces : [
            {
                title: "1. Luxury Bus on Rent in Pune",
                description: "Indulge in unparalleled comfort with our luxury bus rentals. Perfect for corporate events, weddings, or exclusive gatherings, these buses feature plush seating, modern amenities, and impeccable service. Enjoy high-end entertainment systems and refreshments during your journey."
            },
            {
                title: "2. 40-Seater Bus on Rent in Pune",
                description: "Designed for larger groups, our 40-seater buses provide generous space and comfort. Ideal for corporate outings, family reunions, or group tours, these vehicles ensure a relaxed journey with ample legroom and air conditioning for a pleasant travel experience."
            },
            {
                title: "3. 32-Seater Bus on Rent in Pune",
                description: "Our 32-seater buses strike the perfect balance between space and affordability. Ideal for school trips, picnics, and various group activities, they come with comfortable seating, large windows, and a reliable service team to assist during your trip."
            },
            {
                title: "4. 17-Seater Mini Bus on Rent in Pune",
                description: "For smaller groups or short excursions, our 17-seater mini buses offer a cozy and efficient travel solution. These buses are perfect for family outings, small corporate events, or friends’ day trips, ensuring a pleasant journey with attentive drivers."
            },
            {
                title: "5. Tempo Traveller on Rent in Pune",
                description: "Our tempo travellers cater to flexible travel needs, providing ample space for both passengers and luggage. With comfortable seating and air conditioning, these vehicles are perfect for day trips, sightseeing, or extended journeys."
            },
            {
                title: "6. Mini Bus on Rent in Pune",
                description: "Looking for a compact yet comfortable option? Our mini buses are ideal for smaller groups without compromising on comfort. They are perfect for school outings, community events, or family trips, offering convenience and ease of movement."
            },
            {
                title: "7. Luxury Mini Bus on Rent in Pune",
                description: "Experience the best of both worlds with our luxury mini buses. Combining compact size with high-end features, they are perfect for small groups seeking a premium travel experience, complete with modern amenities such as Wi-Fi and entertainment options."
            },
            {
                title: "8. Bus on Rent in Hadapsar",
                description: "SSKTravels24 provides specialized bus rental services in Hadapsar, ensuring that both local and outstation trips are seamless. Our well-maintained buses are ready to deliver a smooth travel experience for schools, corporate outings, and family events."
            },
            {
                title: "9. Bus on Rent in Baner",
                description: "Residents and businesses in Baner can benefit from our reliable bus rental services. Whether for corporate events, school outings, or personal trips, our services guarantee timely and efficient transportation to any destination."
            },
            {
                title: "10. Bus on Rent in Pimpri Chinchwad",
                description: "For those in Pimpri Chinchwad, SSKTravels24 offers a variety of bus rental options tailored to meet local and outstation needs. Our fleet includes different bus sizes and types, ensuring flexibility and comfort for any journey, whether it’s a day trip or longer excursion."
            }
        ],
        
        services : [
            {
                name: "Pune to Goa Mini Bus on Rent",
                description: "Enjoy a scenic journey from Pune to Goa with our mini bus rentals. Ideal for small to medium-sized groups, our buses provide comfort and convenience, ensuring an enjoyable trip filled with beautiful views and ample space for relaxation."
            },
            {
                name: "Pune to Goa Tempo Traveller on Rent",
                description: "For a more adaptable travel experience, choose our tempo travellers for group trips to Goa. With ample space and comfortable seating, these vehicles make your journey enjoyable and hassle-free, perfect for family outings or group adventures."
            },
            {
                name: "Pune to Goa Urbania on Rent",
                description: "Travel in style with our Urbania buses, equipped with modern amenities for a luxurious experience on your trip from Pune to Goa. Enjoy comfortable seating, climate control, and onboard entertainment, making your journey as enjoyable as your destination."
            },
            {
                name: "Pune to Goa Bus Package on Rent",
                description: "Opt for our comprehensive bus packages for your trip to Goa. These packages provide excellent value, covering everything from transportation to additional services such as refreshments and guided tours, ensuring a memorable experience."
            },
            {
                name: "Corporate Bus Hire from Pune to Goa",
                description: "SSKTravels24 specializes in corporate bus hire for trips from Pune to Goa. Whether for a company retreat, team-building event, or corporate meeting, our buses ensure a professional and comfortable experience tailored to your needs."
            },
            {
                name: "AC Bus Hire Pune to Goa",
                description: "Travel in comfort with our air-conditioned buses for your journey to Goa. Designed to provide a pleasant environment, our AC buses enhance your long-distance travel experience, ensuring you arrive relaxed and ready to enjoy."
            },
            {
                name: "Bus On Rent in Pune Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient bus rental services in Pune. We ensure a smooth and enjoyable ride for all our customers, making your travel planning effortless."
            }
        ],
         tableData : [
            ['- Pune to Goa Mini Bus on Rent', '- Pune to Goa Tempo Traveller on Rent'],
            ['- SSKTravels24 Pune', '- Pune to Goa Urbania on Rent'],
            ['- Pune to Goa 17 Seater on Rent', '- Pune to Goa 14, 17, 25, 32 Seater on Rent'],
            ['- Pune to Goa 35, 45, 50 Seater on Rent', '- Pune to Goa Bus Package on Rent'],
            ['- Hire Pune to Goa Mini Bus & Tempo Traveller on Rent', '- Hire Tempo Traveller Minibus'],
            ['- Bus on Rent in Pune, Pune to Goa Bus Hire on Rent', '- Pune to Goa AC Bus on Rent'],
            ['- Pune to Goa Bus Rental Service', '- Hire a Bus from Pune to Goa'],
            ['- Pune to Goa Holiday Bus Rental', '- Pune to Goa Family Trip Bus Hire'],
            ['- Pune to Goa Weekend Getaway Bus Rental', '- Pune to Goa Corporate Bus Hire'],
            ['- Pune to Goa Group Travel Bus Rental', '- AC Bus Hire Pune to Goa'],
            ['- Comfortable Bus from Pune to Goa', '- 32 Seater Bus Rental Pune to Goa'],
            ['- 50 Seater Bus Hire from Pune to Goa', '- Tempo Traveller on Rent in Pune for Corporate Events'],
            ['- Luxury Tempo Traveller on Rent in Pashan Pune', '']
        ],        
    
        whychoose : [
            {
                "WhyChooseheading": "Extensive Fleet Options:",
                "WhyChoosedescription": "At SSKTravels24, we offer a diverse fleet of buses to meet various group sizes and needs. From luxury coaches to standard AC and non-AC buses, whether you’re organizing a small family outing or require transport for a large event, we have the perfect vehicle for you."
            },
            {
                "WhyChooseheading": "Comfort & Safety:",
                "WhyChoosedescription": "All our buses are meticulously maintained and equipped with modern amenities such as comfortable seating, air conditioning, and entertainment systems, ensuring a pleasant journey. Safety is our top priority, with regular servicing and adherence to the highest safety standards."
            },
            {
                "WhyChooseheading": "Experienced & Professional Drivers:",
                "WhyChoosedescription": "Our drivers are not only skilled and knowledgeable but also friendly and well-versed in local routes. They prioritize passenger safety and customer service, ensuring a smooth and timely travel experience."
            },
            {
                "WhyChooseheading": "Affordable & Transparent Pricing:",
                "WhyChoosedescription": "We strive to offer great value with clear pricing structures and no hidden fees. Whether you need a bus for a city tour or an outstation trip, our competitive rates are designed to fit your budget."
            },
            {
                "WhyChooseheading": "Customizable Packages:",
                "WhyChoosedescription": "We understand that each trip is unique. That’s why we offer flexible and customizable packages tailored to your specific travel needs. From corporate outings to weddings, school trips, and sightseeing tours, we can personalize our services to fit your schedule and preferences."
            },
            {
                "WhyChooseheading": "24/7 Customer Support:",
                "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist with bookings, route planning, and any queries you may have, ensuring a hassle-free experience from start to finish."
            },
            {
                "WhyChooseheading": "Punctuality and Reliability:",
                "WhyChoosedescription": "At SSKTravels24, we value your time. We guarantee punctual arrivals and departures, ensuring that your event or trip runs smoothly without delays."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for rent?",
            answer: "We offer a range of buses, including luxury coaches, semi-luxury buses, AC/non-AC buses, and mini-buses, catering to different group sizes and preferences."
        },
        {
            question: "How many passengers can your buses accommodate?",
            answer: "Our buses can accommodate between 18 to 50 passengers, depending on the model you select."
        },
        {
            question: "Can I book a bus for an outstation trip?",
            answer: "Yes, we provide bus rental services for both local travel within Pune and outstation trips across Maharashtra and beyond."
        },
        {
            question: "How do I make a booking for a bus on rent?",
            answer: "You can book a bus through our website, call our customer service hotline, or visit our office. We recommend booking in advance, especially during peak seasons."
        },
        {
            question: "Do your buses come with a driver?",
            answer: "Yes, every bus rental includes a professional, experienced driver who is knowledgeable about local and long-distance routes, ensuring a safe and smooth journey."
        },
        {
            question: "What are your payment options?",
            answer: "We accept various payment methods, including online transfers, credit/debit cards, and cash payments for your convenience."
        },
        {
            question: "Are there any additional charges for fuel or tolls?",
            answer: "Our pricing is transparent, and any additional charges, such as fuel and tolls, will be clearly outlined during the booking process."
        },
        {
            question: "Can I rent a bus for a few hours?",
            answer: "Yes, we offer flexible rental options, whether you need a bus for a few hours, a full day, or an extended trip."
        },
        {
            question: "Is there a cancellation policy?",
            answer: "Yes, we have a cancellation policy in place. You can cancel your booking, but cancellation charges may apply depending on when you cancel."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Rahul Mehta",
            text: "We booked a luxury bus from SSKTravels24 for our corporate event, and it was an exceptional experience! The bus was incredibly comfortable, spacious, and impeccably maintained. Our driver was professional and navigated through the city with ease. The entire team at SSKTravels24 made sure our group was well taken care of from start to finish. I highly recommend their bus rental service in Pune for any occasion!"
        },
        {
            name: "Miss Sneha Kulkarni",
            text: "SSKTravels24 made our family trip to Lonavala truly memorable! We rented a bus for 30 people, and the service surpassed our expectations. The booking process was straightforward, the rates were very reasonable, and the bus arrived right on time. Our driver was friendly, knowledgeable, and took the best routes. We felt safe and comfortable throughout the journey. I will definitely choose their services again for future trips!"
        }
    ];
    
    
    const contactData = {
        heading: "Bus On Rent in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-06.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for “Bus on Rent in Pune” – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busonrentinpune;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busrentalfortourpackages(){


    const cardData = 
    {
        keyword: 'Bus Rental Options for Tour Packages in Pune',
        heading: 'SSKTravels24: Bus Rental Options for Tour Packages in Pune',
        headingDescription: 'Are you planning an unforgettable tour in and around Pune? SSKTravels24 offers exceptional bus rental services tailored for various tour packages, ensuring a comfortable and enjoyable travel experience. Here’s a guide to the top bus rental options with SSKTravels24 for your tour packages in Pune:',
        
         topPlaces : [
            {
                title: "1. Luxury Coaches for Exclusive Tours",
                description: "SSKTravels24 provides luxury coaches perfect for high-end tours and special events. These coaches come equipped with plush seating, air conditioning, and advanced entertainment systems, making them ideal for long journeys and luxurious travel experiences."
            },
            {
                title: "2. Comfortable Mini Buses for Small Groups",
                description: "For smaller groups, SSKTravels24 offers comfortable mini buses that can accommodate 14 to 25 passengers. These mini buses are perfect for intimate tours, ensuring a cozy and convenient ride."
            },
            {
                title: "3. Spacious 32-Seater Buses for Family Tours",
                description: "Ideal for family vacations or group tours, the 32-seater buses from SSKTravels24 provide ample space and comfort. Enjoy a spacious interior with comfortable seating arrangements and plenty of room for luggage."
            },
            {
                title: "4. Versatile 35-Seater Buses for Medium-Sized Groups",
                description: "Whether you’re planning a corporate outing or a group excursion, the 35-seater buses are a versatile choice. They offer a balance of space and comfort, suitable for medium-sized groups."
            },
            {
                title: "5. Large 40-Seater Buses for Extended Tours",
                description: "For larger groups, SSKTravels24’s 40-seater buses are an excellent option. These buses provide generous seating capacity and are ideal for extended tours or corporate events, ensuring everyone travels comfortably."
            },
            {
                title: "6. 50-Seater Buses for Grand Events",
                description: "When you need to transport a big group, the 50-seater buses from SSKTravels24 are up to the task. They are perfect for grand events, large family gatherings, or any occasion where substantial seating capacity is required."
            },
            {
                title: "7. Customizable Tour Packages with Private Buses",
                description: "SSKTravels24 offers customizable tour packages where you can choose the type of bus that fits your needs. Whether you’re planning a local sightseeing tour or an outstation trip, their private buses can be tailored to suit your itinerary."
            },
            {
                title: "8. Eco-Friendly Bus Options",
                description: "As part of their commitment to sustainability, SSKTravels24 provides eco-friendly bus options that minimize environmental impact while delivering exceptional performance and comfort."
            },
            {
                title: "9. On-Demand Luxury and Executive Bus Services",
                description: "For those seeking executive-level comfort and amenities, SSKTravels24 offers on-demand luxury and executive bus services. These buses come with high-end features, including reclining seats, Wi-Fi, and refreshments."
            },
            {
                title: "10. Reliable and Safe Bus Rentals for Family Tours",
                description: "Safety and reliability are top priorities at SSKTravels24. Their fleet of well-maintained buses ensures a safe and secure travel experience for families, complete with experienced drivers and regular maintenance checks."
            }
        ],        
        
        services :[
            {
                name: "Pune to Lonavala Bus Rentals",
                description: "SSKTravels24 offers exceptional bus rental services for tours from Pune to Lonavala. Whether you’re planning a day trip or a longer stay, our comfortable and well-maintained buses ensure a smooth journey to this popular hill station."
            },
            {
                name: "Pune to Lavasa City Bus Rentals",
                description: "For an unforgettable trip to Lavasa City, SSKTravels24 provides reliable bus rental options. Our buses are equipped with modern amenities to make your journey as comfortable as possible."
            },
            {
                name: "Pune to Mumbai Bus Rentals",
                description: "Traveling from Pune to Mumbai? SSKTravels24 offers efficient bus rental services for this route, catering to both small and large groups. Experience a hassle-free journey with our well-maintained fleet."
            },
            {
                name: "Pune to Nashik Bus Rentals",
                description: "Explore Nashik with ease by renting a bus from SSKTravels24. Our service includes comfortable and spacious buses for all your tour needs."
            },
            {
                name: "Pune to Tuljapur Bus Rentals",
                description: "For trips from Pune to Tuljapur, SSKTravels24 provides reliable bus rental services designed for comfort and efficiency."
            },
            {
                name: "Pune to Pandharpur Bus Rentals",
                description: "Planning a visit to Pandharpur? SSKTravels24 offers dedicated bus rental services for this important pilgrimage destination."
            },
            {
                name: "AC/Non-AC Bus for Outstation Rentals",
                description: "Choose between AC and non-AC options for your outstation trips. Our buses are well-equipped for long-distance travel."
            },
            {
                name: "Corporate Event Bus Rentals",
                description: "For corporate events in Pune, SSKTravels24 offers specialized bus rental services to ensure your business trips are smooth and efficient."
            },
            {
                name: "Ashtavinayak Tour Bus Rentals",
                description: "Embark on the Ashtavinayak pilgrimage with SSKTravels24. Our bus rental services are tailored for this sacred tour."
            },
            {
                name: "Mini Bus Rentals",
                description: "SSKTravels24 also offers mini bus rentals for smaller groups, perfect for local tours or shorter trips."
            },
            {
                name: "17-Seater AC Bus Rentals",
                description: "For smaller groups needing a comfortable travel option, SSKTravels24 offers AC 17-seater buses ideal for family outings or corporate meetings."
            },
            {
                name: "Contact for Bus Rental for Tour Packages",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Bus Rental for Tour Packages in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],        
        tableData : [
            ['- Bus Rental for Tour Package Pune to Lonavala', '- Bus Rental for Tour Packages for Pune to Lavasa City'],
            ['- Bus Rental for Tour Package Pune to Mumbai', '- Bus Rental for Tour Package Pune to Nashik'],
            ['- Bus on Rent for Pune to Tuljapur', '- Bus on Rent for Pune to Pandharpur'],
            ['- AC/Non-AC Bus for Outstation', '- Bus Rental for Corporate Events in Pune'],
            ['- Bus Rental for Ashtavinayak Tour from Pune', '- Bus Hire for Jyotirlinga Darshan From Pune'],
            ['- Mini Bus on Rent in Pune', '- AC 17-Seater Bus on Rent in Pune'],
            ['- Best Bus Rental Services in Pune for Tour Packages', '- Tourist Luxury Bus on Rent in Pune'],
            ['- Tourist Bus Hire in Pimpri Chinchwad', '- Best Tourist Bus Hire in Pimpri Chinchwad for Family'],
            ['- Outstation Tourist Bus Hire in Pune', '- Outstation Tourist Bus Hire in Pune Rates'],
            ['- Pune to 12 Jyotirlinga Bus Tour Package', '']
        ],
    
        whychoose : [
            {
                "WhyChooseheading": "Why Choose Us for Bus Rental for Tour Packages in Pune – SSKTravels24",
                "WhyChoosedescription": "Diverse Fleet Options: We offer a wide range of buses, including luxury coaches, mini buses, and standard options, ensuring we have the perfect vehicle for your tour, whether it's an intimate gathering or a large group."
            },
            {
                "WhyChooseheading": "Exceptional Comfort",
                "WhyChoosedescription": "Our buses are equipped with features such as reclining seats, ample legroom, climate control, and entertainment systems to ensure a comfortable travel experience for all passengers."
            },
            {
                "WhyChooseheading": "Skilled and Professional Drivers",
                "WhyChoosedescription": "Our drivers are highly trained and experienced, familiar with both local and outstation routes. They are dedicated to ensuring a safe and smooth journey, providing peace of mind on every trip."
            },
            {
                "WhyChooseheading": "Tailored Itineraries",
                "WhyChoosedescription": "We understand that every tour is unique. SSKTravels24 offers customizable solutions to meet your specific needs, including itinerary adjustments and bus features tailored to your preferences."
            },
            {
                "WhyChooseheading": "Competitive and Transparent Pricing",
                "WhyChoosedescription": "We provide competitive rates with a transparent pricing model, so you know exactly what you’re paying for and receive great value for your money."
            },
            {
                "WhyChooseheading": "Reliable and Punctual Service",
                "WhyChoosedescription": "Reliability and punctuality are our hallmarks. We ensure your travel plans proceed without delays or disruptions, so you can focus on enjoying your journey."
            },
            {
                "WhyChooseheading": "Special Tour Packages",
                "WhyChoosedescription": "We offer curated packages for popular destinations such as Lavasa City, Mumbai, Nashik, Shirdi, and more, providing a seamless travel experience from start to finish."
            },
            {
                "WhyChooseheading": "High Safety Standards",
                "WhyChoosedescription": "Our buses undergo regular maintenance and safety checks to ensure they meet the highest safety standards, prioritizing your safety on every trip."
            },
            {
                "WhyChooseheading": "Outstanding Customer Support",
                "WhyChoosedescription": "From initial inquiries to post-trip assistance, our dedicated customer service team is here to help you every step of the way, ensuring a smooth and enjoyable experience."
            },
            {
                "WhyChooseheading": "Flexible Booking Options",
                "WhyChoosedescription": "We offer flexible booking policies to accommodate changes in your travel plans, making it easy to adjust dates or itineraries as needed."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for tour packages?",
            answer: "We offer a variety of buses, including luxury coaches, mini buses, and standard AC/non-AC options."
        },
        {
            question: "How can I customize my bus rental for a specific tour package?",
            answer: "Share your tour itinerary and preferences with us, and we will tailor our services to meet your specific needs."
        },
        {
            question: "Are the buses suitable for long-distance travel?",
            answer: "Yes, our buses are equipped with amenities designed for long-distance travel, including comfortable seating, air conditioning, and entertainment systems."
        },
        {
            question: "What is the procedure for booking a bus for a tour package?",
            answer: "Contact us with your travel details such as destination, travel dates, and group size. We will guide you through the booking process and provide a quote."
        },
        {
            question: "Can I rent a bus for both local and outstation tours?",
            answer: "Yes, we offer bus rental services for both local and outstation tours."
        },
        {
            question: "Do you offer special packages for popular destinations?",
            answer: "Yes, we provide special packages for destinations like Lavasa, Nashik, Shirdi, and more. Contact us for detailed information on what’s included."
        },
        {
            question: "What is your cancellation policy?",
            answer: "Our cancellation policy varies based on the booking terms. Please discuss the policy with us at the time of booking for clarity."
        },
        {
            question: "Are AC and non-AC buses available?",
            answer: "Yes, we offer both AC and non-AC bus options to suit your preferences."
        },
        {
            question: "How can I get a quote for bus rental?",
            answer: "Provide us with details about your tour package, including the destination, group size, and type of bus required, and we will offer a competitive quote."
        },
        {
            question: "Is there customer support available for booking and travel issues?",
            answer: "Yes, our customer support team is always available to assist with all aspects of your booking and travel. We are committed to resolving any issues to ensure a smooth experience."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Rajesh Patel",
            text: "Our recent experience with SSKTravels24 for a tour package to Lavasa City was outstanding. The bus was immaculate, comfortable, and equipped with everything we needed for our long journey. The driver was friendly and navigated the route expertly, ensuring a smooth and enjoyable trip for everyone. The flexible booking process and clear communication from the team made the entire experience hassle-free. I highly recommend SSKTravels24 for anyone seeking reliable and top-quality bus rental services for their tour packages."
        },
        {
            name: "Ms. Priya Sharma",
            text: "I had the pleasure of organizing a corporate retreat with SSKTravels24, and their service truly exceeded our expectations. We rented a spacious 35-seater bus for our trip to Shirdi, and the vehicle was modern and well-maintained, making the journey comfortable for all our employees. The professionalism of the driver and the punctuality of the service were impressive. SSKTravels24 made our event memorable with their exceptional bus rental service. I will definitely consider them for future events."
        }
    ];
    
    
    const contactData = {
        heading: "Bus Rental for Tour Packages in Pune Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus Rental for Tour Packages in Pune | Explore Scenic Destinations</title>
  <meta name="description" content="Discover bus rental options for various tour packages from Pune to popular destinations like Lonavala, Nashik, and more." />
  <meta name="keywords" content="Bus rental for tour package Pune to Lonavala | Bus rental for tour packages for Pune Lavasa City | Bus rental for tour package Pune to Mumbai | Bus rental for tour package Pune to Nashik | Bus on rent for Pune to Tuljapur | Bus on rent for Pune to Pandharpur | SSK Travels24 Pune | AC/Non AC bus for outstation | Bus rental for corporate events in Pune | Bus rental for Ashtavinayak tour from Pune | Bus hire for Jyotirlinga Darshan from Pune | Mini bus on rent in Pune | AC 17 seater bus on rent in Pune | Best bus rental services in Pune for tour packages | Tourist luxury bus on rent in Pune | Tourist bus hire in Pimpri Chinchwad | Outstation tourist bus hire in Pune | Pune to 12 Jyotirlinga bus tour package" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus Rental for Tour Packages in Pune | Explore Scenic Destinations" />
  <meta property="og:description" content="Book your bus rental for memorable tours from Pune to various scenic destinations!" />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-rental-for-tour-packages-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//tour-bus-service-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-37.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for Bus Rental for Tour Packages in Pune – SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busrentalfortourpackages;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function BusRentInPune45Seater(){


    const cardData = 
    {
        keyword: '45 Seater Bus Rent in Pune',
        heading: 'SSKTravels24: 45 Seater Bus Rent in Pune',
        headingDescription: 'Looking for comfortable group travel in Pune? SSKTravels24 offers spacious and well-maintained 45-seater buses, ideal for corporate events, weddings, school trips, and more. Explore the top features and services we provide for your travel needs.',
        
        topPlaces: [
          {
            title: "1. Spacious Seating Arrangement",
            description: "Our 45-seater buses provide ample seating for large groups, ensuring every passenger has room to relax."
          },
          {
            title: "2. Advanced Climate Control",
            description: "Enjoy a comfortable ride with our buses' efficient air-conditioning systems, perfect for all weather conditions."
          },
          {
            title: "3. Reclining Seats for Enhanced Comfort",
            description: "Each seat is equipped with reclining options, offering extra comfort during longer journeys."
          },
          {
            title: "4. High-Tech Entertainment Systems",
            description: "Stay entertained with modern entertainment systems, including LED TVs and superior sound systems."
          },
          {
            title: "5. Generous Luggage Space",
            description: "Our buses offer ample luggage space, ensuring all personal belongings are securely stored."
          },
          {
            title: "6. Comprehensive Safety Features",
            description: "Safety is paramount, with seat belts, emergency exits, and GPS tracking included in every bus."
          },
          {
            title: "7. Experienced and Professional Drivers",
            description: "All drivers are highly trained and familiar with local routes, committed to ensuring a safe journey."
          },
          {
            title: "8. Meticulously Maintained Fleet",
            description: "We prioritize cleanliness and regular maintenance for all our buses to ensure optimal performance."
          },
          {
            title: "9. Transparent and Competitive Pricing",
            description: "No hidden costs—our pricing is clear, ensuring you know exactly what you're paying for."
          },
          {
            title: "10. Customized Travel Solutions",
            description: "We offer tailored rental solutions to meet your specific travel needs and budget."
          }
        ],
      
        services: [
          {
            name: "45 Seater Bus for Outstation Trips",
            description: "Ideal for long journeys, providing comfort and space for all passengers."
          },
          {
            name: "45 Seater Bus for Weddings",
            description: "Luxurious seating for transporting guests to and from wedding venues."
          },
          {
            name: "45 Seater AC and Non-AC Bus Options",
            description: "Choose between air-conditioned or budget-friendly non-AC buses for your needs."
          },
          {
            name: "45 Seater Bus for Corporate Events",
            description: "Ensure punctual transportation for your team to conferences and seminars."
          },
          {
            name: "45 Seater Bus for School Picnics",
            description: "Safe and comfortable travel for students and staff during school outings."
          },
          {
            name: "45 Seater Bus Rentals in Pimpri Chinchwad",
            description: "Reliable service for all group travel needs in the Pimpri Chinchwad area."
          },
          {
            name: "45 Seater Bus Rental for Marriage Functions",
            description: "Convenient transport solutions for large marriage functions, ensuring guests arrive comfortably."
          },
          {
            name: "Contact Number for 45 Seater Bus Rent in Pune",
            description: "For prompt bookings, contact SSKTravels24 at +91 8956170493."
          }
        ],
        tableData: [
            ['-45 Seater Bus for Hire for Outstation', '-45 Seater Bus for Wedding for Outstation Pune'],
            ['-45 Seater AC Bus for Hire', '-45 Seater Non-AC Bus for Hire'],
            ['-45 Seater AC Bus for Marriage Function', '-45 Seater Bus for Corporate Events'],
            ['-45 Seater Bus on Rent in Pimpri Chinchwad', '-45 Seater Bus Hire for School Picnic'],
            ['-45 Seater Bus Rental for Marriage Function', '-45 Seater Bus Rent for Corporate Picnic'],
            ['-45 Seater Bus for Outstation from Pune', '-45 Seater Bus Rental Services in Pune'],
            ['-45 Seater Bus Rental Services in Hadapsar', '']
          ],
        
          whychoose: [
            {
              WhyChooseheading: "Why Choose SSKTravels24 for 45-Seater Bus Rental in Pune?",
              WhyChoosedescription: "At SSKTravels24, we are committed to providing exceptional 45-seater bus rental services in Pune. Our focus on delivering a superior travel experience sets us apart. Here’s why you should choose us for your next group travel:"
            },
            {
              WhyChooseheading: "1. Spacious Comfort",
              WhyChoosedescription: "Our 45-seater buses are designed to accommodate large groups with ample space for each passenger, ensuring a comfortable journey for short or long-distance trips."
            },
            {
              WhyChooseheading: "2. Climate-Controlled Environment",
              WhyChoosedescription: "Travel in comfort regardless of external weather conditions with our state-of-the-art air-conditioning systems maintaining a pleasant atmosphere."
            },
            {
              WhyChooseheading: "3. Enhanced Relaxation",
              WhyChoosedescription: "Reclining seats allow for personalized comfort adjustments, ensuring a relaxing ride for every passenger."
            },
            {
              WhyChooseheading: "4. Modern Entertainment",
              WhyChoosedescription: "Each bus features high-definition LED TVs and quality sound systems, making long trips enjoyable."
            },
            {
              WhyChooseheading: "5. Ample Luggage Space",
              WhyChoosedescription: "Our extensive luggage compartments accommodate the storage needs of large groups, keeping belongings safe and convenient."
            },
            {
              WhyChooseheading: "6. Top-Notch Safety",
              WhyChoosedescription: "With essential safety features and GPS tracking, we prioritize the safety and security of our passengers."
            },
            {
              WhyChooseheading: "7. Professional Drivers",
              WhyChoosedescription: "Experienced and reliable drivers ensure a smooth travel experience on both city and outstation routes."
            },
            {
              WhyChooseheading: "8. Impeccable Maintenance",
              WhyChoosedescription: "Our buses undergo regular servicing and thorough inspections to ensure excellent working condition and cleanliness."
            },
            {
              WhyChooseheading: "9. Transparent Pricing",
              WhyChoosedescription: "We offer competitive pricing with no hidden costs, making budgeting straightforward and stress-free."
            },
            {
              WhyChooseheading: "10. Customizable Solutions",
              WhyChoosedescription: "Flexible rental solutions tailored to your specific needs, whether for a day, a week, or a special event."
            }
          ]
    };    

    
  // FAQs for 45-Seater Bus on Rent in Pune
const faqData = [
    {
      question: "What is included in the rental of a 45-seater bus?",
      answer: "The rental includes the bus, driver, fuel, and maintenance. Additional amenities like air-conditioning, entertainment systems, and luggage compartments are also included. We offer transparent pricing with no hidden fees."
    },
    {
      question: "Can I customize the bus rental service?",
      answer: "Yes, we provide customizable solutions to fit your specific needs, whether for corporate events, weddings, or any other occasion."
    },
    {
      question: "Are there any extra charges for long-distance travel?",
      answer: "Long-distance travel might incur additional charges based on the distance covered, but we offer competitive pricing and communicate any extra costs upfront."
    },
    {
      question: "How do I book a 45-seater bus?",
      answer: "You can book a 45-seater bus by contacting us directly via phone or email. Our customer service team will assist you in confirming availability and finalizing the booking."
    },
    {
      question: "What safety measures are in place on the bus?",
      answer: "Our buses are equipped with seat belts, emergency exits, fire extinguishers, first-aid kits, and GPS tracking for real-time monitoring to ensure passenger safety."
    },
    {
      question: "Are the buses well-maintained?",
      answer: "Yes, our buses are regularly serviced and thoroughly inspected to ensure they are in excellent working condition, maintaining high standards of cleanliness."
    },
    {
      question: "What types of events can I use the 45-seater bus for?",
      answer: "Our buses are suitable for various events including corporate functions, weddings, school trips, family outings, and long-distance tours."
    },
    {
      question: "Can I request a specific type of bus?",
      answer: "We offer both air-conditioned and non-air-conditioned buses. You can specify your preference when making a reservation."
    },
    {
      question: "How can I contact SSKTravels24 for more information?",
      answer: "You can reach us via phone, email, or through our website contact form for any questions and assistance with your booking."
    },
    {
      question: "What is the cancellation policy?",
      answer: "Our cancellation policy varies based on the booking terms. Please contact us directly to discuss cancellation terms and any applicable fees."
    }
  ];
  
  // Testimonials for 45-Seater Bus on Rent in Pune
  const testimonials = [
    {
      name: "Mr. Rajesh Sharma",
      text: "We recently hired a 45-seater bus from SSK Travels for our company's annual picnic, and the experience was fantastic! The bus was immaculate and equipped with all the modern amenities we needed. The driver was highly professional, punctual, and ensured a smooth and enjoyable ride for everyone. Highly recommended for anyone in need of reliable and comfortable group transportation in Pune!"
    },
    {
      name: "Miss Priya Joshi",
      text: "SSK Travels provided us with a 45-seater bus for a wedding function, and we couldn’t be happier with their service. The bus was spacious, air-conditioned, and impeccably clean. It comfortably accommodated all our guests, and the driver was courteous and well-organized, making sure everyone was taken care of. SSK Travels truly exceeded our expectations."
    }
  ];
  
  // Contact Information for 45-Seater Bus on Rent in Pune
  const contactData = {
    heading: "Contact SSKTravels24 for 45-Seater Bus Rental",
    contactNumbers: [
      "+91 8956170493",
      "+91 7276307000"
    ],
    email: "booking@SSKtravels24.com"
  };
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 45 Seater Bus on Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent a 45 seater bus in Pune for weddings, corporate events, and outstation trips. Competitive rates for both AC and non-AC options. Book your bus today!" />
  <meta name="keywords" content="45 seater bus on rent in Pune | 45 seater bus for hire for outstation | 45 seater bus for wedding for outstation Pune | 45 seater AC bus for hire | 45 seater non-AC bus for hire | 45 seater AC bus for marriage function | 45 seater bus for corporate events | 45 seater bus on rent in Pimpri Chinchwad | 45 seater bus hire for school picnic | 45 seater bus rental for marriage function | 45 seater bus rent for corporate picnic | 45 seater bus for outstation from Pune | 45 seater bus rental services in Pune | 45 seater bus rental services in Hadapsar | 45 seater bus rental for wedding in Pune | 45 seater bus for company picnic" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 45 Seater Bus on Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for 45 seater buses in Pune. Enjoy competitive rates for weddings, corporate events, and more. Contact us to book your bus!" />
  <meta property="og:url" content="https://https://ssktravels24.com//45-seater-bus-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//45-seater-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-13.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default BusRentInPune45Seater;
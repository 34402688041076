
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Tempotravelleronrent(){


    const cardData = 
    {
        keyword: 'Tempo Traveller on Rent in Pimpri Chinchwad',
        heading: 'SSKTravels24: Tempo Traveller on Rent in Pimpri Chinchwad',
        headingDescription: 'Are you planning a family outing, corporate event, or group excursion in Pimpri Chinchwad? Look no further! SSKTravels24 offers exceptional Tempo Traveller rentals designed for comfort, convenience, and affordability. Whether you’re traveling within the city or to nearby destinations, our well-maintained vehicles ensure an enjoyable and hassle-free journey.',
        
        topPlaces: [
            {
                title: "1. Nashik Highway (Old Mumbai-Pune Highway)",
                description: "A scenic route for a leisurely drive, this highway is lined with numerous restaurants and eateries. It’s a popular spot for food lovers and weekend getaways."
            },
            {
                title: "2. Pimpri-Chinchwad Science Park",
                description: "This educational park is designed to inspire young minds through interactive science exhibits. It’s a great place for families and school trips."
            },
            {
                title: "3. Mahalunge Balewadi",
                description: "This area offers various recreational activities, including parks and sports facilities. It’s a perfect place for outdoor enthusiasts and fitness lovers."
            },
            {
                title: "4. Bhakti Shakti Park",
                description: "A serene park featuring lush greenery, walking paths, and playgrounds. It’s ideal for family outings, morning walks, or simply relaxing amidst nature."
            },
            {
                title: "5. Siddhivinayak Mandir",
                description: "A popular temple dedicated to Lord Ganesha, it attracts devotees from across the region. The temple’s architecture and serene ambiance make it a must-visit."
            },
            {
                title: "6. Amanora Mall",
                description: "This large shopping mall offers a variety of retail outlets, restaurants, and entertainment options, including a multiplex. It’s a popular hangout spot for locals."
            },
            {
                title: "7. Talawade IT Park",
                description: "A hub for technology and innovation, Talawade IT Park houses numerous IT companies and startups. It’s an important area for professionals in the tech industry."
            },
            {
                title: "8. Pimpri Chinchwad Municipal Corporation Garden",
                description: "A well-maintained garden with colorful flowers, walking trails, and benches. It’s a peaceful spot for a morning stroll or a picnic with family."
            },
            {
                title: "9. Indira Gandhi Memorial Park",
                description: "This park features beautiful landscaping, jogging tracks, and a children’s play area. It’s an excellent place for outdoor activities and family gatherings."
            },
            {
                title: "10. Laxmi Narayan Temple",
                description: "An ancient temple known for its beautiful architecture and peaceful surroundings. It’s a great place for spiritual reflection and cultural exploration."
            }
        ],
    
        services: [
            {
                name: "Tempo Traveller on Rent in Pimpri Chinchwad",
                description: "SSKTravels24 provides Tempo Travellers for all types of group travel. From family vacations to corporate outings, our well-maintained vehicles ensure a comfortable and safe journey."
            },
            {
                name: "17 Seater Tempo Traveller on Rent in Pimpri Chinchwad",
                description: "Need a spacious vehicle for a group of 17? Our 17 Seater Tempo Traveller is the ideal choice, perfect for corporate gatherings or family events. Equipped with air-conditioning and luxurious seating, this vehicle promises a relaxing journey."
            },
            {
                name: "13 Seater Tempo Traveller on Rent in Pimpri Chinchwad",
                description: "Our 13 Seater Tempo Traveller is tailored for mid-sized groups, making it ideal for weddings, family functions, or religious tours."
            },
            {
                "name": " 25 Seater Tempo Traveller on Rent in Pune Contact Number:",
                "description": "Contact SSKTravels24 at +91 8956170493 for prompt and efficient  25 Seater Tempo Traveller on Rent in Pune . We ensure a smooth and enjoyable ride for all our customers."
            }
            // Add more services as needed
        ],
    
      tableData: [
        ['-Tempo Traveller from Pune to Mahabaleshwar', '-Pune to Mahabaleshwar Tempo Traveller Hire'],
        ['-Pune to Panchgani Tempo Traveller', '-Tempo Traveller for Rent in Pune'],
        ['-17-Seater Tempo Traveller for Mahabaleshwar Trip', '-17-Seater Tempo Traveller for Mahabaleshwar Tour Package'],
        ['-Pune Airport to Mahabaleshwar Tempo Traveller on Rent', '-Pune to Tapola Tempo Traveller on Rent'],
        ['-14-Seater Tempo Traveller for Maharashtra Trip', '-Tempo Traveller on Rent for Mahabaleshwar Outings'],
        ['-Mini Bus on Rent for Mahabaleshwar Trip', '-Tempo Traveller on Rent in Pune Rate Per Km']
      ],
      "whychoose": [
        {
            "WhyChooseheading": "Why Choose SSKTravels24 for Tempo Traveller on Rent in Pimpri Chinchwad?",
            "WhyChoosedescription": "When it comes to group travel in Pimpri Chinchwad, SSKTravels24 stands out as the premier choice for renting a Tempo Traveller. Our commitment to comfort, reliability, and customer satisfaction ensures that your travel experience is enjoyable and hassle-free. Here’s why you should choose us for your next journey:"
        },
        {
            "WhyChooseheading": "Diverse Fleet Options:",
            "WhyChoosedescription": "We offer a wide range of tempo travellers, accommodating groups from 9 to 24 passengers. Whether it’s a cozy family outing or a large corporate event, we have the perfect vehicle for you."
        },
        {
            "WhyChooseheading": "Comfort and Luxury:",
            "WhyChoosedescription": "Our tempo travellers are designed with your comfort in mind. Enjoy cushioned, reclining seats, air-conditioning, ample legroom, and advanced suspension systems for a smooth, relaxing ride—no matter how long the journey."
        },
        {
            "WhyChooseheading": "Well-Maintained Vehicles:",
            "WhyChoosedescription": "Each vehicle in our fleet undergoes regular inspections and servicing to ensure peak performance. Cleanliness and hygiene are paramount, so you can always expect a neat and well-maintained vehicle."
        },
        {
            "WhyChooseheading": "Experienced and Professional Drivers:",
            "WhyChoosedescription": "Our drivers are licensed professionals who know the local routes extensively. Their courteous demeanor and punctuality guarantee a hassle-free travel experience."
        },
        {
            "WhyChooseheading": "Affordable Pricing:",
            "WhyChoosedescription": "We pride ourselves on offering competitive rates that provide great value without sacrificing quality. Our transparent pricing means no hidden costs—just excellent service at an affordable price."
        },
        {
            "WhyChooseheading": "Customizable Travel Packages:",
            "WhyChoosedescription": "Whether you’re planning a day trip, a weekend getaway, or a long-distance journey, we can tailor travel packages to fit your schedule and budget. Your travel plans, your way."
        },
        {
            "WhyChooseheading": "24/7 Availability:",
            "WhyChoosedescription": "SSKTravels24 operates around the clock, so you can reach out to us any time of day. Our customer service team is always ready to assist with bookings and inquiries."
        },
        {
            "WhyChooseheading": "Safety First:",
            "WhyChoosedescription": "Your safety is our utmost priority. Our vehicles are equipped with GPS tracking, seat belts, and first-aid kits. We strictly follow safety regulations to ensure secure journeys for all passengers."
        },
        {
            "WhyChooseheading": "On-Time Service:",
            "WhyChoosedescription": "We take punctuality seriously. Whether you need an early morning pickup or a late-night ride, our drivers will be there on time to get you to your destination without delay."
        },
        {
            "WhyChooseheading": "Excellent Customer Reviews:",
            "WhyChoosedescription": "SSKTravels24 has earned a strong reputation for exceptional service. Our satisfied customers consistently praise us for making their travel experiences smooth, comfortable, and memorable."
        }
    ]
    };    

    
   
    const faqData = [
        {
            question: "What seating options do you offer for tempo travellers?",
            answer: "We provide a variety of seating options, including 9, 12, 13, 14, 17, 20, and 24-seater tempo travellers to meet your group size needs."
        },
        {
            question: "Can I book a tempo traveller for outstation trips?",
            answer: "Absolutely! We offer tempo traveller services for both local and outstation travel, covering popular destinations like Shirdi, Mahabaleshwar, and Lonavala."
        },
        {
            question: "How do I book a tempo traveller with SSKTravels24?",
            answer: "Booking is easy! Contact us via phone, email, or through our website. Our 24/7 customer service team is always ready to assist you."
        },
        {
            question: "Are your tempo travellers equipped with air-conditioning?",
            answer: "Yes, all our tempo travellers are fully air-conditioned to ensure a comfortable experience, especially during long trips."
        },
        {
            question: "Do your tempo travellers have enough luggage space?",
            answer: "Yes, our vehicles are designed with ample luggage storage to accommodate all passengers' belongings comfortably."
        },
        {
            question: "Is there an entertainment system in your tempo travellers?",
            answer: "Yes, our tempo travellers come with entertainment features such as LED TVs, music systems, and charging ports for an enjoyable journey."
        },
        {
            question: "Do you offer tempo traveller services for weddings and events?",
            answer: "Absolutely! We specialize in providing tempo travellers for weddings, corporate events, family functions, and group tours, ensuring your guests travel in comfort."
        },
        {
            question: "Can I customize my trip itinerary with SSKTravels24?",
            answer: "Yes, we offer customizable travel packages tailored to your specific needs and schedule, whether for a short trip or a long journey."
        },
        {
            question: "What safety measures do you have in place?",
            answer: "Safety is a top priority. Our vehicles are equipped with GPS tracking, seat belts, and first-aid kits. Our drivers are trained to ensure the safety of all passengers."
        },
        {
            question: "How much does it cost to rent a tempo traveller in Pimpri Chinchwad?",
            answer: "Costs vary based on the size of the vehicle and rental duration. We offer competitive, transparent pricing with no hidden charges. Contact us for a personalized quote."
        }
    ];
    const testimonials = [
        {
            name: "Mr. Ajay Kulkarni",
            text: "I recently rented a 17-seater tempo traveller from SSKTravels24 for a family getaway to Mahabaleshwar, and it was an amazing experience! The vehicle was not only clean and spacious but also very comfortable, making our journey enjoyable. Our driver, Mr. Prakash, was extremely courteous and knowledgeable about the route, which helped us relax and enjoy the scenery. The customer service team was helpful and efficient throughout the booking process. I wholeheartedly recommend SSKTravels24 for any group travel needs!"
        },
        {
            name: "Miss Riya Menon",
            text: "Our corporate retreat to Lonavala was a huge success, thanks to SSKTravels24! We hired a 20-seater tempo traveller, and everyone was thrilled with the comfort and amenities. The air-conditioning kept us cool, and the reclining seats made for a pleasant ride. Our coordinator, Miss Neha, ensured everything was in place, from booking to drop-off. Overall, the experience was flawless, and we’ll definitely choose SSKTravels24 for our future outings!"
        }
    ];

    const contactData = { 
        heading: "Tempo Traveller on Rent in Pimpri Chinchwad",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000",
        ],
        email: "booking@SSKtravels24.com"
    };
    
        
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
  


      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Tempo Traveller on Rent in Pimpri Chinchwad | Affordable Rental Services</title>
  <meta name="description" content="Rent a tempo traveller in Pimpri Chinchwad for local and outstation trips. We offer a range of options from 9 to 25 seater vehicles. Book your tempo traveller today!" />
  <meta name="keywords" content="Tempo traveller on rent in Pimpri Chinchwad, 17 seater tempo traveller on rent in Pimpri Chinchwad, 13 seater tempo travellers on rent in Pimpri Chinchwad, 9, 12, 14, 17, 20, 24 seater tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Baner, tempo traveller hire in Hinjewadi, tempo traveller hire in Aundh Pune, Pimpri Chinchwad to Shirdi tempo traveller on rent, tempo traveller on rent in Bhosari, tempo traveller on rent in Pimpri Chinchwad, 25 seater bus on rent in Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Tempo Traveller on Rent in Pimpri Chinchwad | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for tempo travellers in Pimpri Chinchwad. Choose from a variety of seating options for your journey. Contact us to book!" />
  <meta property="og:url" content="https://https://ssktravels24.com//tempo-traveller-on-rent-pimpri-chinchwad" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//tempo-traveller-pimpri-chinchwad-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-10.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Tempotravelleronrent;
import React from 'react';
import { Helmet } from 'react-helmet'
// Sample blog posts data with images
const blogPosts = [
   
    {
        title: "Customer-Focused Innovation",
        description: "At SSK Travels24, we prioritize innovation as a key driver of our services. By incorporating cutting-edge technology, we not only make the booking process easier but also ensure that every journey is personalized to the needs of our clients. Whether it’s a quick ride across the city or a long-distance journey, our goal is to provide a travel experience that is both enjoyable and efficient.",
        link: "#",
        image: "/images/customer.jpg", 
    },
    {
        title: "Our Commitment to Quality",
        description: "Embark on unforgettable adventures with SSK Travels24! Discover India's hidden treasures, vibrWhat sets us apart is our unwavering commitment to quality. Every member of the SSK Travels24 team is focused on ensuring that our services are consistent, reliable, and always meet the high standards our clients have come to expect. We constantly strive to improve, learn, and develop new ways to elevate the customer experience.",
        link: "#",
        image: "/images/Collaboration.jpeg", 
    },
    {
        title: "Join Us on a Journey of Excellence",
        description: "Whether you’re a corporate traveler, a tourist, or a local resident in need of convenient travel solutions, SSK Travels24 is here to meet all your transportation needs. Our blend of top-notch customer service, innovative technology, and a wide range of travel solutions makes us the trusted choice for car rentals and travel services in Maharashtra and beyond.",
        link: "#",
        image: "/images/outside.webp", 
    },
    {
        title: "Urbania On Rent In Pune",
        description: "Experience luxury and comfort on the go with Urbania on rent in Pune! Perfect for city tours, group outings, and corporate travel. Book your ride with SSK Travels24 today for a smooth and stylish journey!",
        link: "#",
        image: "/images/keyword-base/Keyword 1-34.jpg", 
    },
    {
        title: "Urbania on Rent in Pimpri Chinchwad",
        description: "Discover the comfort and elegance of Urbania on rent in Pimpri Chinchwad! Whether for a business trip, family outing, or city tour, SSK Travels24 ensures a premium travel experience. Book now and travel in style!",
        link: "#",
        image: "/images/For mail/Fleet/Our fleets-01.jpg", 
    },
    {
        title: "Urbania on rent in Mumbai",
        description: "Travel in style and comfort with Urbania on rent in Mumbai! Ideal for business, family trips, or exploring the city, SSK Travels24 offers a premium travel experience. Book your Urbania ride today for a luxurious journey!",
        link: "#",
        image: "/images/For mail/Fleet/Our fleets-02.jpg", 
    },

    {
        title: "Urbania On Rent in Viman Nagar",
        description: "Explore Viman Nagar in style with Urbania on rent! Whether it's a corporate event, family outing, or a special occasion, SSK Travels24 offers a comfortable and luxurious ride. Book your Urbania today for an unforgettable journey!",
        link: "#",
        image: "/static/media/Urbania-02.cc9eb56e846c465fbfbe.jpg", 
    },
    {
        title: "Innova Crysta on Rent In Pune",
        description: "Experience unparalleled comfort and luxury with Innova Crysta on rent in Pune! Perfect for family trips, group travel, or corporate events, SSK Travels24 ensures a smooth and stylish ride. Book now and enjoy a premium journey!",
        link: "#",
        image: "/images/For mail/Fleet/Our fleets Cars-08.jpg", 
    },
    {
        title: "Innova Crysta Hire in Pune",
        description: "Need a spacious and luxurious ride? Hire an Innova Crysta in Pune with SSK Travels24 for your family trips, group outings, or corporate needs. Enjoy comfort, style, and reliability with every journey. Book your Innova Crysta today!",
        link: "#",
        image: "/images/innova.jpg", 
    },
    {
        title: "Pune to Mumbai Innova Crysta",
        description: "Travel in luxury and comfort with Innova Crysta from Pune to Mumbai! Whether it’s a business trip, family vacation, or group travel, SSK Travels24 offers a smooth and stylish ride. Book your Innova Crysta today for an unforgettable journey!",
        link: "#",
        image: "/images/innova2.jfif", 
    },
    {
        title: "Pune to Outstation Innova Crysta cab",
        description: "Heading out of Pune? Choose the Innova Crysta for your outstation trip! Enjoy a comfortable, spacious, and luxurious ride with SSK Travels24. Perfect for long journeys, family getaways, and group travel. Book your Innova Crysta cab now for a premium travel experience!",
        link: "#",
        image: "/images/innova3.jfif", 
    },
    {
        title: "Fortuner on Rent in Pune",
        description: "Rent the iconic Fortuner in Pune for an unparalleled driving experience! Whether it's a city tour, business trip, or special occasion, SSK Travels24 offers you the perfect blend of luxury and power. Book your Fortuner today for a premium ride!",
        link: "#",
        image: "/images/fortuner.webp", 
    },
    {
        title: "Toyota Fortuner On Rent in Pune",
        description: "Rent the powerful and stylish Toyota Fortuner in Pune for your next adventure! Perfect for family trips, business travel, or special occasions, SSK Travels24 offers a premium driving experience with comfort and elegance. Book your Toyota Fortuner today!",
        link: "#",
        image: "/images/For mail/Fleet/Our fleets Cars-09.jpg", 
    },
    {
        title: "Hire Toyota Fortuner in Pune",
        description: "Hire the prestigious Toyota Fortuner in Pune for an unforgettable journey! Whether it's a road trip, business event, or family outing, SSK Travels24 ensures a luxurious and powerful ride. Book your Toyota Fortuner today and travel in style!",
        link: "#",
        image: "/images/fortuner2.webp", 
    },
    {
        title: "Toyota Fortuner Rent in Pimpri Chinchwad",
        description: "Looking to rent a Toyota Fortuner in Pimpri Chinchwad? Experience the perfect blend of luxury, power, and comfort with SSK Travels24. Ideal for group travel, business trips, or special occasions. Book your Toyota Fortuner today and enjoy a premium ride!",
        link: "#",
        image: "/images/fortuner4.webp", 
    },
    {
        title: "Explore the Unseen Wonders of India: Memorable Adventures Await with SSK Travels24!",
        description: "Embark on unforgettable adventures with SSK Travels24! Discover India's hidden treasures, vibrant cultures, and breathtaking landscapes. With our expertly crafted itineraries, every journey is tailored to create lasting memories. Explore the unseen wonders of India with us!",
        link: "#",
        image: "/images/blo-img.jpg", 
    },
];

const CustomCard = () => {
    const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];

    return (
        <div className="custom-card col-md-4 mb-4">
            <div className='' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}
              </div>
        </div>
    );
};

const BlogSection = () => {
    return (
        <div>

<Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
<section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Blog</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>


        <section className="blog-section py-5">
            <div className="container p-0">
                <div className="row g-0 ">
                    {blogPosts.map((post, index) => (
                        <div className="col-md-4 mb-4 p-2" key={index}>
                            <div className="blog-card shadow rounded">
                                <img src={post.image} alt={post.title} className="img-fluid rounded-top " />
                                <div className="p-4">
                                    <h4 className="blog-">
                                        <a href={post.link} className="" style={{color:'#913923'}}>{post.title}</a>
                                    </h4>
                                    <p className="blog-description">{post.description}</p>
                                    <p className="blog-date text-muted">{post.date}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    <CustomCard />




                    
                </div>
            </div>

            <style jsx>{`
                .blog-section {
                    background-color: #f8f9fa; /* Light gray background for the entire section */
                    padding: 40px 0;
                    border-radius: 0.5rem;
                }

                .section-title {
                    font-size: 2.5rem;
                    font-weight: bold;
                    color: #007bff;
                    margin-bottom: 20px;
                }

                .blog-card {
                    border: 1px solid #e0e0e0;
                    transition: transform 0.2s, background-color 0.3s;
                    position: relative;
                    overflow: hidden;
                }

                .blog-card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
                    // background-color: #d1ecf1; /* Light blue on hover */
                }

                .blog-card img {
                    border-radius: 0.5rem 0.5rem 0 0; /* Rounded top corners */
                }

                .blog-title {
                    font-weight: bold;
                    // color: #007bff; /* Blue color for headings */
                    background: linear-gradient(90deg, #007bff, #0056b3);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    padding: 5px;
                    margin: 10px 0;
                    border-radius: 4px;
                }

                .blog-description {
                    color: #555;
                    margin: 10px 0;
                }

                .blog-date {
                    font-size: 0.9rem;
                    color: #888;
                }

                .custom-title {
                    font-weight: bold;
                    // color: #dc3545; /* Red color for custom title */
                    margin-bottom: 10px;
                }

                .custom-card {
                    background-color: #fff;
                    border: 1px solid #dee2e6;
                    border-radius: 0.5rem;
                    transition: background-color 0.3s;
                }

                .custom-card:hover {
                    // background-color: #f8d7da; /* Light red */
                }

                .btn-primary {
                    background-color: #007bff;
                    border-color: #007bff;
                }

                .btn-primary:hover {
                    background-color: #0056b3;
                    border-color: #0056b3;
                }
            `}</style>
        </section>
        </div>
    );
};

export default BlogSection;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function YourTrustedPartnerforPunetoLonavalaCabBooking(){


    const cardData = 
    {
        keyword: 'Pune to Lonavala Cab Booking',
        heading: 'Your Trusted Partner for Pune to Lonavala Cab Booking: SSKTravels24',
        headingDescription: 'Planning a getaway from Pune? Lonavala, the picturesque hill station nestled in the Western Ghats, is the perfect destination for a refreshing retreat. Known for its scenic beauty, lush greenery, and pleasant climate, Lonavala attracts tourists throughout the year. At SSKTravels24, we provide seamless Pune to Lonavala cab booking services, ensuring a comfortable and enjoyable journey.',
        
        topPlaces: [
          {
            title: "1. Lonavala Lake",
            description: "A serene spot perfect for picnics, Lonavala Lake offers stunning views and is surrounded by lush greenery, especially during the monsoon."
          },
          {
            title: "2. Bhushi Dam",
            description: "Popular among tourists, this dam is ideal for water play and offers a beautiful backdrop for photos. The sight of water cascading over the dam is especially mesmerizing during the rainy season."
          },
          {
            title: "3. Tiger’s Leap",
            description: "This viewpoint offers breathtaking views of the lush valleys below. The sight of the cascading waterfall and the expansive landscape make it a popular spot for nature lovers."
          },
          {
            title: "4. Karla Caves",
            description: "These ancient rock-cut Buddhist caves date back to the 2nd century BC. The intricate carvings and the stunning Chaitya (prayer hall) make it a significant historical site."
          },
          {
            title: "5. Bhaja Caves",
            description: "Another set of ancient rock-cut caves, Bhaja Caves are famous for their impressive architecture and scenic surroundings, perfect for a day of exploration."
          },
          {
            title: "6. Rajmachi Fort",
            description: "A trekker’s paradise, Rajmachi Fort offers panoramic views of the surrounding mountains and is a great spot for adventure enthusiasts looking to hike."
          },
          {
            title: "7. Della Adventure Park",
            description: "For thrill-seekers, Della Adventure Park offers a range of adventure activities, from zip-lining to bungee jumping, ensuring a fun-filled day for families and friends."
          },
          {
            title: "8. Pawna Lake",
            description: "A serene lake that’s perfect for camping and picnics, Pawna Lake offers stunning sunset views and a tranquil atmosphere away from the hustle and bustle of city life."
          },
          {
            title: "9. Lion’s Point",
            description: "Known for its mesmerizing views, Lion’s Point is the perfect place to witness the sunset. The cool breeze and scenic landscape make it a romantic spot for couples."
          },
          {
            title: "10. Celebrity Wax Museum",
            description: "A unique attraction in Lonavala, this museum features life-size wax statues of famous personalities from Bollywood and other fields, making it a fun stop for photography enthusiasts."
          }
        ],
        
        services: [
          {
            name: "Pune to Lonavala Cab Service",
            description: "Enjoy a comfortable ride with our dedicated Pune to Lonavala cab service. We ensure timely pickups and drop-offs, making your travel hassle-free."
          },
          {
            name: "Pune to Lonavala Cab Tour Package",
            description: "Looking for an all-inclusive experience? Our Pune to Lonavala cab tour package includes sightseeing and flexible itineraries tailored to your preferences."
          },
          {
            name: "Lonavala to Pune Cab",
            description: "Whether you're returning or visiting, our Lonavala to Pune cab services are designed for convenience, offering easy booking and reliable drivers."
          },
          {
            name: "Pune to Lonavala Taxi Fare",
            description: "Our Pune to Lonavala taxi fare is competitive and transparent, with no hidden charges. Check our website for the latest rates."
          },
          {
            name: "Pune Airport to Lonavala Taxi Fare",
            description: "Flying into Pune? Our Pune airport to Lonavala taxi fare is affordable, ensuring you have a smooth transition from air travel to your destination."
          },
          {
            name: "Cab Fare from Pune to Lonavala",
            description: "Our cab fare from Pune to Lonavala is designed to fit various budgets. We provide options for different vehicle types."
          },
          {
            name: "Book Cab from Pune to Lonavala",
            description: "Booking your cab is simple. Use our online platform to book your cab from Pune to Lonavala in just a few clicks."
          },
          {
            name: "Car on Rent from Pune to Lonavala",
            description: "Prefer to drive yourself? We offer cars on rent from Pune to Lonavala, giving you the flexibility to explore at your own pace."
          },
          {
            name: "Lonavala to Pune Airport Taxi",
            description: "Need transportation to the airport? Our Lonavala to Pune airport taxi service ensures you arrive on time for your flight."
          },
          {
            name: "Pune to Lonavala Urbania Bus on Rent",
            description: "Traveling in a group? Our Pune to Lonavala Urbania bus on rent service accommodates larger groups with comfort and convenience."
          }
          ,
          {
            name: "Pune to Lonavala Cab Booking Contact Number:",
            description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Pune to Lonavala Cab Booking. We ensure a smooth and enjoyable ride for all our customers."
          }
        ],
        tableData: [
            ['Pune to Lonavala Cab', 'Pune to Lonavala Cab Tour Package'],
            ['Lonavala to Pune Cab', 'Pune to Lonavala Taxi Fare'],
            ['Pune Airport to Lonavala Taxi Fare', 'Cab Fare from Pune to Lonavala'],
            ['Book Cab from Pune to Lonavala', 'Cab Service from Pune to Lonavala'],
            ['Car on Rent from Pune to Lonavala', 'Lonavala to Pune Airport Taxi'],
            ['Pune Lonavala Taxi', 'Pune to Lonavala by Car'],
            ['Pune to Lonavala Cab Charges', 'Pune to Lonavala Cab Booking'],
            ['Pune to Lonavala Cab Cost', 'Pune to Lonavala Cabs Round Trip'],
            ['Pune to Lonavala Taxi Service', 'Pune to Lonavala Urbania Bus on Rent'],
            ['Force Urbania 17 Seater for Rent', 'Force Urbania on Rent in Pune'],
            ['Pune to Lonavala Fortuner on Rent', 'Innova & Crysta Car Rental in Pune for Outstation & Airport'],
            ['Fortuner on Rent in Pimpri Chinchwad', '']
        ],
        whychoose: [
            {
                WhyChooseheading: "Why Choose Us for Pune to Lonavala Cab Booking at SSKTravels24?",
                WhyChoosedescription: "At SSKTravels24, we pride ourselves on our punctuality and reliability. We understand the importance of timely travel, ensuring you reach your destination without any delays."
            },
            {
                WhyChooseheading: "Reliable Service:",
                WhyChoosedescription: "Our fleet consists of well-maintained and comfortable vehicles, designed to provide you with a smooth and enjoyable journey. Choose from a variety of vehicle options to suit your needs."
            },
            {
                WhyChooseheading: "Comfortable Rides:",
                WhyChoosedescription: "We offer competitive and transparent pricing with no hidden charges. You can easily check our rates online, making it simple to plan your budget."
            },
            {
                WhyChooseheading: "Transparent Pricing:",
                WhyChoosedescription: "Our user-friendly online platform allows you to book your cab from Pune to Lonavala in just a few clicks. It's convenient, quick, and available 24/7."
            },
            {
                WhyChooseheading: "Easy Online Booking:",
                WhyChoosedescription: "Our drivers are experienced, courteous, and familiar with the routes, ensuring a safe and pleasant travel experience. Your comfort and safety are our top priorities."
            },
            {
                WhyChooseheading: "Professional Drivers:",
                WhyChoosedescription: "Whether you're looking for a one-way trip or a round trip, we provide customizable packages that can be tailored to your travel needs and preferences."
            },
            {
                WhyChooseheading: "Customizable Packages:",
                WhyChoosedescription: "Our dedicated customer support team is available to assist you with any queries or concerns before, during, and after your journey, ensuring a hassle-free experience."
            },
            {
                WhyChooseheading: "Customer Support:",
                WhyChoosedescription: "We offer vehicles suitable for group travel, ensuring everyone can travel together comfortably, whether it's for a family trip or a corporate outing."
            }
        ]
    };    

    
    const faqData = [
        {
            question: "How do I book a cab from Pune to Lonavala?",
            answer: "You can easily book a cab through our website or mobile app. Simply enter your pickup and drop-off locations, choose your vehicle, and confirm your booking."
        },
        {
            question: "What are the payment options available?",
            answer: "We accept various payment methods, including credit/debit cards, net banking, and cash payments to the driver."
        },
        {
            question: "How much does a cab from Pune to Lonavala cost?",
            answer: "Our prices are competitive and depend on the type of vehicle you choose and the distance traveled. For an accurate quote, please check our pricing page or contact customer support."
        },
        {
            question: "Are there any additional charges?",
            answer: "No, we have a transparent pricing model with no hidden charges. Any additional costs, such as tolls or waiting time, will be clearly communicated before your ride."
        },
        {
            question: "Can I cancel or modify my booking?",
            answer: "Yes, you can cancel or modify your booking up to a specific time before your scheduled pickup. Please refer to our cancellation policy for more details."
        },
        {
            question: "Do you offer round-trip options?",
            answer: "Yes, we offer round-trip packages from Pune to Lonavala, allowing you to enjoy a full day of exploration with the convenience of a return journey."
        },
        {
            question: "What types of vehicles are available for booking?",
            answer: "We have a range of vehicles, including sedans, SUVs, and luxury cars, to cater to your travel preferences and group size."
        },
        {
            question: "Are your drivers experienced?",
            answer: "Yes, all our drivers are professionally trained, experienced, and familiar with the routes to ensure your safety and comfort during the journey."
        },
        {
            question: "How far is Lonavala from Pune?",
            answer: "Lonavala is approximately 65 kilometers from Pune, and the journey usually takes about 1.5 to 2 hours, depending on traffic conditions."
        },
        {
            question: "Is there customer support available during the trip?",
            answer: "Yes, our customer support team is available to assist you with any queries or issues you may encounter during your trip."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Ravi Kulkarni",
            text: "I recently booked a cab from Pune to Lonavala with SSKTravels24, and I couldn't be happier with the service! The driver was punctual and friendly, and the vehicle was clean and comfortable. The scenic drive made our trip even more enjoyable. I highly recommend SSKTravels24 for anyone looking for a reliable and hassle-free cab service. Will definitely use them again for future trips!"
        },
        {
            name: "Mrs. Anjali Deshmukh",
            text: "Our family had an amazing experience traveling from Pune to Lonavala with SSKTravels24. The booking process was straightforward, and the support team was very helpful in answering all our queries. Our driver was not only skilled but also shared interesting facts about the area during the drive. It made our journey memorable! We will certainly choose SSKTravels24 for our next getaway."
        }
    ];
    
    const contactData = {
        heading: "Contact Us for Pune to Lonavala Cab Booking",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-25.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default YourTrustedPartnerforPunetoLonavalaCabBooking;

import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function BusRentInPune35Seater(){


    const cardData = 
    {
        keyword: '35 Seater Bus Rent in Pune',
        heading: 'SSKTravels24: 35 Seater Bus Rent in Pune',
        headingDescription: 'Looking for a reliable 35-seater bus rental in Pune? SSKTravels24 provides spacious and comfortable buses ideal for corporate events, family gatherings, and school trips. Explore our top features and services designed to meet all your group travel needs.',
        
        topPlaces: [
            {
                title: "1. Spacious Interior",
                description: "Our 35-seater buses are designed for maximum comfort, featuring generous seating arrangements for all passengers."
            },
            {
                title: "2. Climate-Controlled Comfort",
                description: "Enjoy a cool and pleasant ride with fully air-conditioned buses, perfect for all weather conditions."
            },
            {
                title: "3. Reclining Seats",
                description: "Equipped with adjustable reclining seats for added comfort during both short and long journeys."
            },
            {
                title: "4. Advanced Entertainment System",
                description: "Stay entertained on long trips with LED TVs and high-quality audio systems available in our buses."
            },
            {
                title: "5. Ample Storage for Luggage",
                description: "Our buses offer spacious luggage compartments, ensuring ample space for all your belongings."
            },
            {
                title: "6. Commitment to Safety",
                description: "Safety features include seat belts for every passenger, emergency exits, and GPS tracking for real-time monitoring."
            },
            {
                title: "7. Experienced Drivers",
                description: "Our skilled drivers ensure a smooth journey with their extensive knowledge of local routes and punctual service."
            },
            {
                title: "8. Well-Maintained Fleet",
                description: "Each bus undergoes regular inspections and maintenance, ensuring cleanliness and top-notch performance."
            },
            {
                title: "9. Transparent Pricing",
                description: "Enjoy competitive rates with no hidden fees—what you see is what you pay for your rental."
            },
            {
                title: "10. Customizable Travel Packages",
                description: "We offer tailored travel packages to fit your specific needs, whether for a day or an extended rental period."
            }
        ],
        
        services: [
            {
                name: "35 Seater Bus on Rent for Corporate Events",
                description: "Provide comfortable and timely transportation for corporate outings, ensuring a smooth experience for your team."
            },
            {
                name: "35 Seater Bus for Weddings",
                description: "Reliable transportation for wedding guests, with spacious seating and climate-controlled comfort for a special day."
            },
            {
                name: "35 Seater AC and Non-AC Bus Options",
                description: "Choose between air-conditioned and budget-friendly non-AC buses, both ensuring comfort for your group."
            },
            {
                name: "35 Seater Bus for Outstation Trips",
                description: "Ideal for journeys to popular destinations like Mahabaleshwar, Shirdi, and Lonavala, offering safety and comfort."
            },
            {
                name: "35 Seater Bus for School Picnics",
                description: "Ensure a fun and safe experience for school picnics with ample space and entertainment options for students and teachers."
            },
            {
                name: "35 Seater Bus for Religious Tours",
                description: "Comfortable transport for visiting sacred sites, such as the 5 Jyotirlinga temples, ensuring a serene journey."
            },
            {
                name: "Contact Number for 35 Seater Bus Rent in Pune",
                description: "For quick and efficient inquiries about 35 Seater Bus Rent in Pune, contact SSKTravels24 at +91 8956170493."
            }
        ],
        tableData: [
            ['35 Seater Bus on Rent in Pune', '35 Seater Bus on Rent for Pune to Outstation'],
            ['35 Seater Bus on Rent for Corporate Office', '35 Seater Bus on Rent for Corporate Event'],
            ['35 Seater Bus Rental Services in Pune', '35 Seater Bus on Rent AC Bus'],
            ['35 Seater Bus on Rent Non-AC Bus', '35 Seater Bus on Rent for Marriage Function'],
            ['35 Seater Bus on Rent for School Picnic', '35 Seater Bus on Rent Corporate Picnic'],
            ['35 Seater Bus on Rent Pimpri Chinchwad', 'Bharat Benz 35 Seater Bus on Rent'],
            ['Pune to Shirdi 35 Seater Bus on Rent', 'Pune to Konkan Darshan 35 Seater Bus on Rent'],
            ['35 Seater Bus on Rent for 5 Jyotirlinga from Pune', 'Pune to Nashik Bus on Rent for Picnic'],
            ['35 Seater Bus Hire for School Picnic', '']
        ],
        whychoose: [
            {
                "WhyChooseheading": "Why Choose SSKTravels24 for 35-Seater Bus Rental in Pune?",
                "WhyChoosedescription": "When it comes to renting a 35-seater bus for your group travel needs in Pune, SSKTravels24 is your ideal provider. Our dedication to comfort, reliability, and customer satisfaction ensures that your travel experience is enjoyable and hassle-free. Here’s why SSKTravels24 stands out:"
            },
            {
                "WhyChooseheading": "Spacious and Comfortable Buses:",
                "WhyChoosedescription": "Our 35-seater buses are meticulously designed to provide the utmost comfort for every passenger. With generous legroom, plush seating, and air-conditioning, you can enjoy a pleasant journey, whether traveling locally in Pune or heading to distant destinations."
            },
            {
                "WhyChooseheading": "Professional and Experienced Drivers:",
                "WhyChoosedescription": "Safety and reliability are our top priorities. Our drivers are highly trained and possess extensive experience, ensuring a smooth and secure travel experience. Their knowledge of local routes and traffic conditions guarantees an efficient and enjoyable journey."
            },
            {
                "WhyChooseheading": "Well-Maintained Fleet:",
                "WhyChoosedescription": "At SSKTravels24, we pride ourselves on the exceptional maintenance of our fleet. Our 35-seater buses undergo regular servicing and thorough inspections to ensure they are always in peak condition, minimizing the likelihood of breakdowns and providing a dependable travel experience."
            },
            {
                "WhyChooseheading": "Affordable Pricing:",
                "WhyChoosedescription": "We offer competitive rates for our 35-seater bus rentals without compromising on quality. Our transparent pricing structure ensures no hidden fees, allowing you to have clear expectations regarding your costs."
            },
            {
                "WhyChooseheading": "Customizable Travel Solutions:",
                "WhyChoosedescription": "Whether you need transportation for a corporate event, family gathering, school trip, or any other group travel requirement, we provide flexible solutions tailored to your specific needs. Our dedicated team is committed to accommodating your preferences for a seamless travel experience."
            },
            {
                "WhyChooseheading": "Excellent Customer Service:",
                "WhyChoosedescription": "From the moment you book until your journey concludes, our customer service team is available to support you at every step. We prioritize your needs and are dedicated to providing prompt and effective assistance to ensure a hassle-free experience."
            },
            {
                "WhyChooseheading": "Punctual and Reliable:",
                "WhyChoosedescription": "We understand the importance of timeliness in travel. Our buses adhere to strict schedules, ensuring on-time departures and arrivals, helping you stay on track with your plans."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What is included in the rental of a 35-seater bus?",
            answer: "Our rental includes comfortable seating, air-conditioning, and professional driver services. Additional amenities such as audio systems and Wi-Fi may be available upon request, depending on the bus model."
        },
        {
            question: "How do I book a 35-seater bus with SSKTravels24?",
            answer: "You can book a bus by contacting us via phone or email, or by completing our online booking form. Please provide details such as your travel dates, pick-up and drop-off locations, and any specific requirements."
        },
        {
            question: "Are there any additional charges I should be aware of?",
            answer: "Our pricing is straightforward, with no hidden costs. However, extra charges may apply for additional services, such as extended travel hours or special requests. We will provide a detailed quote before confirming your booking."
        },
        {
            question: "What is the cancellation policy for a 35-seater bus rental?",
            answer: "Our cancellation policy varies based on the timing of the cancellation. Generally, cancellations made well in advance may incur a nominal fee, while last-minute cancellations could involve higher charges. Please contact our customer service for specific details."
        },
        {
            question: "Can I request a specific type of 35-seater bus?",
            answer: "Yes, you can request specific features or types of buses based on availability. We offer a variety of options to meet different needs, including luxury and standard models."
        },
        {
            question: "Are the drivers experienced and licensed?",
            answer: "Absolutely! All our drivers are professionally trained, experienced, and hold valid licenses. They are familiar with local routes and committed to providing a safe and comfortable travel experience."
        },
        {
            question: "Can I make changes to my booking after it is confirmed?",
            answer: "Yes, modifications to your booking can be made, subject to availability and any applicable fees. Please reach out to our customer service team as soon as possible to discuss any changes."
        },
        {
            question: "What should I do if I need assistance during my journey?",
            answer: "If you require assistance during your trip, please contact the driver or our customer service team immediately. We are dedicated to resolving any concerns or issues promptly to ensure a smooth journey."
        },
        {
            question: "How far in advance should I book a 35-seater bus?",
            answer: "To secure your preferred bus and ensure availability, we recommend booking as early as possible. For peak seasons or special events, advance booking is advisable to avoid any last-minute issues."
        },
        {
            question: "Are there any restrictions on luggage or other items?",
            answer: "We have standard luggage allowances but can accommodate extra baggage or special items upon request. Please inform us of any specific needs during your booking, and we'll make the necessary arrangements."
        }
    ];
    
    const testimonials = [
        {
            name: "Mr. Rajesh Kumar",
            text: "I recently rented a 35-seater bus from SSKTravels24 for a family reunion trip in Pune, and I couldn’t be more pleased. The bus was spacious and spotless, equipped with all the modern amenities we needed. The air-conditioning kept us perfectly cool throughout the journey. Our driver was punctual and professional, ensuring we reached our destinations safely and on schedule. SSKTravels24 made the entire experience seamless and enjoyable. I highly recommend their services for anyone seeking reliable and comfortable group transportation in Pune."
        },
        {
            name: "Ms. Priya Mehta",
            text: "We hired a 35-seater bus from SSKTravels24 for a corporate team outing, and the service far exceeded our expectations. The bus was well-maintained, featuring comfortable seating with ample legroom. The onboard entertainment system kept everyone engaged during the trip, adding to the fun. Our driver was courteous and well-acquainted with the best routes, making our journey both pleasant and efficient. The booking process was simple, and the pricing was very reasonable for the quality of service provided. SSKTravels24 is definitely our top choice for future group travel needs."
        }
    ];
    
    const contactData = {
        heading: "Contact SSKTravels24 for 35-Seater Bus Rentals in Pune",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@SSKtravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | 35 Seater Bus on Rent in Pune | Affordable Rental Services</title>
  <meta name="description" content="Rent a 35 seater bus in Pune for corporate events, school picnics, and outstation trips. Competitive rates for both AC and non-AC options. Book your bus today!" />
  <meta name="keywords" content="35 seater bus on rent in Pune | 35 seater bus on rent for Pune to outstation | 35 seater bus on rent for corporate office | 35 seater bus on rent for corporate event | 35 seater bus rental services in Pune | 35 seater bus on rent AC bus | 35 seater bus on rent non-AC bus | 35 seater bus on rent for marriage function | 35 seater bus on rent for school picnic | 35 seater bus on rent corporate picnic | 35 seater bus on rent Pimpri Chinchwad | Bharat Benz 35 seater bus on rent | Pune to Shirdi 35 seater bus on rent | Pune to Konkan Darshan 35 seater bus on rent | 35 seater bus on rent for 5 Jyotirlinga from Pune | Pune to Nashik bus on rent for picnic | 35 seater bus hire for school picnic" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | 35 Seater Bus on Rent in Pune | Affordable Rental Services" />
  <meta property="og:description" content="Discover the best rental services for 35 seater buses in Pune. Enjoy competitive rates for corporate events, picnics, and more. Contact us to book your bus!" />
  <meta property="og:url" content="https://https://ssktravels24.com//35-seater-bus-on-rent-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//35-seater-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword base images-12.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs About {cardData.keyword} for SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default BusRentInPune35Seater;
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (

    <div>

<div className="container-fluid  bg-dark">
      <section className="service">
      <div className="row he text-white justify-content-center g-0">
    <div className="col-12 col-md-3 text-white">
      <h4 className="py-3 case">Service</h4>

      
      <Link className="mx-md-2" to="/Tempo-Traveller-On-Rent-in-Pune"><i className="fa fa-bus"></i> Tempo Traveller On Rent in Pune</Link>
      <Link className="mx-md-2" to="/Pune-to-Mahabaleshwar-Tempo-Traveller"><i className="fa fa-bus"></i> Pune to Mahabaleshwar Tempo Traveller</Link>
      <Link className="mx-md-2" to="/Pune-to-Outstation-Tempo-Traveller"><i className="fa fa-bus"></i> Pune to Outstation Tempo Traveller</Link>
      <Link className="mx-md-2" to="/Mini-Bus-on-Rent-in-Pune"><i className="fa fa-bus"></i> Mini Bus on Rent in Pune </Link>
      <Link className="mx-md-2" to="/14-Seater-Tempo-Traveller-on-Rent"><i className="fa fa-bus"></i> 14 Seater Tempo Traveller on Rent </Link>
      <Link className="mx-md-2" to="/17-Seater-Bus-on-Rent-Pune"><i className="fa fa-bus"></i> 17 Seater Bus on Rent Pune </Link>
      <Link className="mx-md-2" to="/Pune-to-Shirdi-Tempo-Traveller"><i className="fa fa-bus"></i> Pune to Shirdi Tempo Traveller </Link>
      <Link className="mx-md-2" to="/20-Seater-Bus-on-Rent-in-Pune"><i className="fa fa-bus"></i> 20 Seater Bus on Rent in Pune</Link>
      <Link className="mx-md-2" to="/25-Seater-Tempo-Traveller-on-Rent-in-Pune"><i className="fa fa-bus"></i> 25 Seater Tempo Traveller on Rent in Pune</Link>
      <Link className="mx-md-2" to="/Tempo-Traveller-on-Rent-in-Pimpri-Chinchwad"><i className="fa fa-bus"></i> Tempo Traveller on Rent in Pimpri Chinchwad</Link>
      <Link className="mx-md-2" to="/32-Seater-Bus-Rent-in-Pune"><i className="fa fa-bus"></i> 32 Seater Bus Rent in Pune</Link>
      <Link className="mx-md-2" to="/35-Seater-Bus-Rent-in-Pune"><i className="fa fa-bus"></i> 35 Seater Bus Rent in Pune</Link>
      <Link className="mx-md-2" to="/45-Seater-Bus-Rent-in-Pune"><i className="fa fa-bus"></i> 45 Seater Bus Rent in Pune</Link>
      <Link className="mx-md-2" to="/50-Seater-Bus-Rent-in-Pune"><i className="fa fa-bus"></i> 50 Seater Bus Rent in Pune</Link>
      <Link className="mx-md-2" to="/Non-Ac-Bus-Rental-Service-in-Pune"><i className="fa fa-bus"></i> Non Ac Bus Rental Service in Pune</Link>
      <Link className="mx-md-2" to="/Luxury-Bus-Rental-in-Pune"><i className="fa fa-bus"></i> Luxury Bus Rental in Pune</Link>
      <Link className="mx-md-2" to="/Luxury-Force-Urbania-On-Rent-in-Pune"><i className="fa fa-bus"></i> Luxury Force Urbania On Rent in Pune</Link>
      <Link className="mx-md-2" to="/Force-Urbania-Luxury-Mini-Bus-On-Rent"><i className="fa fa-bus"></i> Force Urbania Luxury Mini Bus On Rent</Link>
      <Link className="mx-md-2" to="/Mini-Bus-On-Rent-in-Pune"><i className="fa fa-bus"></i> Mini Bus On Rent in Pune</Link>
      <Link className="mx-md-2" to="/14-17-20-25-Seater-Bus-on-in-Pune"><i className="fa fa-bus"></i> 14 17 20 25 Seater Bus on in Pune</Link>
      <Link className="mx-md-2" to="/Travel-Agents-in-Pune-for-Mini-Bus-Hire"><i className="fa fa-bus"></i> Travel Agents in Pune for Mini Bus Hire</Link>
      <Link className="mx-md-2" to="/Tempo-Traveller-Hire-in-Pune"><i className="fa fa-bus"></i> Tempo Traveller Hire in Pune</Link>
      <Link className="mx-md-2" to="/Force-Urbania-On-Rent-for-Ashtavinayak-Darshan"><i className="fa fa-bus"></i> Force Urbania On Rent for Ashtavinayak Darshan</Link>
      <Link className="mx-md-2" to="/Pune-to-Mahabaleshwar-Urbania-On-Rent"><i className="fa fa-bus"></i> Pune to Mahabaleshwar Urbania On Rent</Link>
      <Link className="mx-md-2" to="/Pune-to-Bhimashankar-Jyotirlinga-Mini-Bus-Hire-in-Pune"><i className="fa fa-bus"></i> Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune</Link>
    </div>

    <div className="col-12 col-md-3">
      <h4 className="py-3 case">Service</h4>
      <Link className="mx-md-2" to="/Bus-Hire-for-School-Trips-in-Pune"><i className="fa fa-bus"></i> Bus Hire for School Trips in Pune</Link>
      <Link className="mx-md-2" to="/Pune-Local-Bus-on-Rent"><i className="fa fa-bus"></i> Pune Local Bus on Rent</Link>
      <Link className="mx-md-2" to="/Bus-On-Rent-in-Kharadi-Pune"><i className="fa fa-bus"></i> Bus On Rent in Kharadi Pune</Link>
      <Link className="mx-md-2" to="/Bus-on-Rent-in-Hinjewadi"><i className="fa fa-bus"></i> Bus on Rent in Hinjewadi</Link>
      {/* <Link className="mx-md-2" to="/Bus-Hire-For-Wedding-in-Pune"><i className="fa fa-bus"></i> Bus Hire For Wedding in Pune</Link> */}
      <Link className="mx-md-2" to="/Buses-For-Corporate-Events-in-Pune"><i className="fa fa-bus"></i> Buses For Corporate Events in Pune</Link>
      <Link className="mx-md-2" to="/Bus-Rentals-For-School-Trips-in-Pune"><i className="fa fa-bus"></i> Bus Rentals For School Trips in Pune</Link>
      <Link className="mx-md-2" to="/Bus-booking-for-wedding-in-Pune"><i className="fa fa-bus"></i> Bus booking for wedding in Pune</Link>
      <Link className="mx-md-2" to="/Bus-Booking-for-Marriage-function-in-Pune"><i className="fa fa-bus"></i> Bus Booking for Marriage function in Pune</Link>
      <Link className="mx-md-2" to="/Bus-Service-for-Corporate-Pune"><i className="fa fa-bus"></i> Bus Service for Corporate Pune</Link>
      <Link className="mx-md-2" to="/Bus-on-Rent-For-Event-in-Pune"><i className="fa fa-bus"></i> Bus on Rent For Event in Pune</Link>
      <Link className="mx-md-2" to="/Bus-Rental-for-Tour-Packages-in-Pune"><i className="fa fa-bus"></i> Bus Rental for Tour Packages in Pune</Link>
      <Link className="mx-md-2" to="/Bus-Hire-for-Outstation-in-Pune"><i className="fa fa-bus"></i> Bus Hire for Outstation in Pune</Link>
      <Link className="mx-md-2" to="/Pune-Bus-Hire-Rental-Service"><i className="fa fa-bus"></i> Pune Bus Hire Rental Service</Link>
      <Link className="mx-md-2" to="/Bus-Service-for-Picnic-in-Pune"><i className="fa fa-bus"></i> Bus Service for Picnic in Pune</Link>
      <Link className="mx-md-2" to="/Luxury-Bus-Rental-in-Pune"><i className="fa fa-bus"></i> Luxury Bus Rental in Pune</Link>
      <Link className="mx-md-2" to="/Urbania-Bus-On-Rent-in-Mumbai-Airport"><i className="fa fa-bus"></i> Urbania Bus On Rent in Mumbai Airport</Link>
      <Link className="mx-md-2" to="/Bus-On-Rent-in-Kharadi-Pune"><i className="fa fa-bus"></i> Bus On Rent in Kharadi Pune</Link>
      <Link className="mx-md-2" to="/Pune-to-Mumbai-Darshan-Bus-On-Rent"><i className="fa fa-bus"></i> Pune to Mumbai Darshan Bus On Rent</Link>
      <Link className="mx-md-2" to="/Bus-On-Rent-in-Pimpri-Chinchwad"><i className="fa fa-bus"></i> Bus On Rent in Pimpri Chinchwad</Link>
      <Link className="mx-md-2" to="/Bus-Hire-for-School-Trips-in-Pune"><i className="fa fa-bus"></i> Bus Hire for School Trips in Pune</Link>
      <Link className="mx-md-2" to="/Bus-On-Rent-in-Pune"><i className="fa fa-bus"></i> Bus On Rent in Pune</Link>
      <Link className="mx-md-2" to="/Pune-Local-Bus-On-Rent"><i className="fa fa-bus"></i> Pune Local Bus on Rent</Link>
      <Link className="mx-md-2" to="/Bus-Hire-Near-me-Pune"><i className="fa fa-bus"></i> Bus Hire Near me Pune</Link>
      <Link className="mx-md-2" to="/32-35-40-45-50-Seater-Bus-on-Rent-in-Pune"><i className="fa fa-bus"></i> 32,35,40,45,50 Seater Bus on Rent in Pune</Link>
      <Link className="mx-md-2" to="/Pune-To-Lonavala-Bus-Hire"><i className="fa fa-bus"></i> Pune to Lonavala Bus Hire</Link>
      <Link className="mx-md-2" to="/Pune-Sade-Teen-Shakti-Peeth-Bus-On-Rent"><i className="fa fa-bus"></i> Pune Sade Teen Shakti Peeth Bus on Rent</Link>
      <Link className="mx-md-2" to="/Pune-To-Konkan-Darshan-Bus-Hire"><i className="fa fa-bus"></i> Pune to Konkan Darshan Bus Hire</Link>
      <Link className="mx-md-2" to="/Pune-To-Goa-Bus-Hire"><i className="fa fa-bus"></i> Pune to Goa Bus Hire</Link>
    
    </div>

    <div className="col-12 col-md-3">
      <h4 className="py-3 case">Service</h4>
      <Link className="mx-md-2" to="/Pune-to-Mumbai-Cab"><i className="fa fa-bus"></i> Pune to Mumbai Cab</Link>
      <Link className="mx-md-2" to="/Pune-to-Outstation-Cab"><i className="fa fa-bus"></i> Pune to Outstation Cab</Link>
      <Link className="mx-md-2" to="/Pune-to-Mumbai-international-Airport-Cab"><i className="fa fa-bus"></i> Pune to Mumbai international Airport Cab</Link>
      <Link className="mx-md-2" to="/Online-Cab-Booking-in-Pune"><i className="fa fa-bus"></i> Online Cab Booking in Pune</Link>
      <Link className="mx-md-2" to="/Ashtavinayak-Darshan-Cab"><i className="fa fa-bus"></i> Ashtavinayak Darshan Cab</Link>
      <Link className="mx-md-2" to="/Pune-to-Mumbai-Cab-Taxi-Service"><i className="fa fa-bus"></i> Pune to Mumbai Cab Taxi Service</Link>
      <Link className="mx-md-2" to="/Corporate-Event-Cab-Service-in-Pune"><i className="fa fa-bus"></i> Corporate Event Cab Service in Pune</Link>
      <Link className="mx-md-2" to="/Pune-to-Goa-Cabs"><i className="fa fa-bus"></i> Pune to Goa Cabs</Link>
      <Link className="mx-md-2" to="/7-Seater-Car-on-Rent-in-Pune"><i className="fa fa-bus"></i> 7 Seater Car on Rent in Pune</Link>
      <Link className="mx-md-2" to="/Online-Taxi-Booking-Pune"><i className="fa fa-bus"></i> Online Taxi Booking Pune</Link>
      <Link className="mx-md-2" to="/Airport-Cab-Services-in-Pune"><i className="fa fa-bus"></i> Airport Cab Services in Pune</Link>
      <Link className="mx-md-2" to="/Mumbai-Airport-to-Pune-Taxi"><i className="fa fa-bus"></i> Mumbai Airport to Pune Taxi</Link>
      <Link className="mx-md-2" to="/Mumbai-International-Airport-to-Pune-Cab"><i className="fa fa-bus"></i> Mumbai International Airport to Pune Cab</Link>
      <Link className="mx-md-2" to="/5-Jyotirling-Darshan-Cab-Booking"><i className="fa fa-bus"></i> 5 Jyotirling Darshan Cab Booking</Link>
      <Link className="mx-md-2" to="/One-Way-Cab-in-Pune"><i className="fa fa-bus"></i> One Way Cab in Pune</Link>
      <Link className="mx-md-2" to="/Pune-to-Lonavala-Cab-Booking"><i className="fa fa-bus"></i> Pune to Lonavala Cab Booking</Link>
      <Link className="mx-md-2" to="/Best-Cab-Service-in-Pune"><i className="fa fa-bus"></i> Best Cab Service in Pune</Link>
      <Link className="mx-md-2" to="/Luxury-Car-Rental-in-Pune"><i className="fa fa-bus"></i> Luxury Car Rental in Pune</Link>
      <Link className="mx-md-2" to="/Pune-to-Mahabaleshwar-Cab"><i className="fa fa-bus"></i> Pune to Mahabaleshwar Cab</Link>
      <Link className="mx-md-2" to="/Fortuner-On-Rent-in-Pune"><i className="fa fa-bus"></i> Fortuner On Rent in Pune</Link>
      <Link className="mx-md-2" to="/Innova-Crysta-On-Rent-In-Pune"><i className="fa fa-bus"></i> Innova Crysta On Rent In Pune</Link>
      <Link className="mx-md-2" to="/Ertiga-Cab-Booking-in-Pune"><i className="fa fa-bus"></i> Ertiga Cab Booking in Pune</Link>
      <Link className="mx-md-2" to="/Kia-Carens-On-Rent-in-Pune"><i className="fa fa-bus"></i> Kia Carens On Rent in Pune</Link>
      <Link className="mx-md-2" to="/Swift-Dzire-On-Rent-in-Pune"><i className="fa fa-bus"></i> Swift Dzire On Rent in Pune</Link>
      <Link className="mx-md-2" to="/Sedan-Cabs-Booking-in-Pune"><i className="fa fa-bus"></i> Sedan Cabs Booking in Pune</Link>
      <Link className="mx-md-2" to="/Force-Urbania-On-Rent-in-Pune"><i className="fa fa-bus"></i> Force Urbania On Rent in Pune</Link>
      <Link className="mx-md-2" to="/Pune-to-Mumbai-Airport-Urbania-Bus-On-Rent"><i className="fa fa-bus"></i> Pune to Mumbai Airport Urbania Bus On Rent</Link>
      <Link className="mx-md-2" to="/Pune-to-Outstation-Urbania-Bus-On-Rent"><i className="fa fa-bus"></i> Pune to Outstation Urbania Bus On Rent </Link>
   </div>

    <div className="col-12 col-md-3  ">
      <h4 className="py-3 case">Service</h4>
      <h4 className="case">SSKTravels24</h4>
      <p className='text-white'><strong>Phone:</strong><br />
        <i className="fas fa-phone-alt text-white"></i> <a href="tel:+918956170493" className='d-inline'>+91 8956170493</a><br />
        <i className="fas fa-phone-alt text-white"></i> <a href="tel:+917276307000" className='d-inline'>+91 7276307000</a><br />
       
      </p>
      <p className='row w-100'><strong className='col-12 text-white'>Email:</strong><br />
        <a href="mailto:booking@www.ssktravels24.com" className='col-12 overflow-hidden'>booking@www.ssktravels24.com</a>
      </p>
      <p className='text-white'><strong>Address:</strong><br />
        SSKTravels24:<br />
        office No 109,<br />
        Ashoka Plaza, First Floor,<br />
        Vimannagar Pune, Maharashtra 411014
      </p>
      <div className="mt-4">
        <h5 className="case">Our Location:</h5>
      
<iframe 
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3156.8525042069955!2d73.91009167423843!3d18.559916017973073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c140777e0863%3A0xa1e94ed8abda7386!2sAshoka%20Plaza%2C%20Sakore%20Nagar%2C%20Viman%20Nagar%2C%20Pune%2C%20Maharashtra%20411014!5e1!3m2!1sen!2sin!4v1729054310776!5m2!1sen!2sin" 
    width="100%" 
    height="250" 
    style={{ border: 0 }} 
    allowFullScreen 
    loading="lazy" 
    referrerPolicy="no-referrer-when-downgrade"
></iframe>
      </div>
    </div>

    <hr />
   
    <p className="text-white text-center">
  All Rights Reserved @ <span className="darkcolor">SSKTravels24 </span> 2024 Developed By 
  <a href="https://www.dexterintelligence.com/" className='d-inline' target="_blank" rel="noopener noreferrer">
    <span className="darkcolor">&nbsp;Dexter Intelligence</span>
  </a> | Hosted By  
  <a href="https://www.urvahosting.com/" className='d-inline' target="_blank" rel="noopener noreferrer">
    <span className="darkcolor">&nbsp;Urva Hosting</span>
  </a>
</p>


    </div>
      </section>
  </div>

    </div>
   
  );
};

export default Footer;

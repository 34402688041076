
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Busonrentinpimpri(){


    const cardData = 
    {
        keyword: 'Bus on Rent in Pimpri Chinchwad',
        heading: 'SSKTravels24: Pune to Mahabaleshwar Urbania On RentBus on Rent in Pimpri Chinchwad',
        headingDescription: 'SSKTravels24 offers exceptional bus rental services in Pimpri Chinchwad, catering to various needs from corporate events to family outings. Our fleet is designed to provide comfort, convenience, and reliability, making us the top choice for bus rentals in the area. Here’s why you should choose SSKTravels24 for your next bus rental in Pimpri Chinchwad:',
        
        topPlaces : [
            {
                title: "1. Venna Lake",
                description: "A serene spot for boating and picnics, perfect for relaxing amidst stunning landscapes."
            },
            {
                title: "2. Pratapgad Fort",
                description: "A historic fort offering panoramic views and a glimpse into Maratha history."
            },
            {
                title: "3. Elephant's Head Point",
                description: "A scenic viewpoint known for its unique rock formation and breathtaking vistas."
            },
            {
                title: "4. Arthur's Seat",
                description: "Dubbed the 'Queen of the Points', offering spectacular views of the Konkan coast."
            },
            {
                title: "5. Mapro Garden",
                description: "Famous for its strawberry farms and delicious fruit-based products."
            },
            {
                title: "6. Lingmala Waterfall",
                description: "A must-visit waterfall for nature lovers, surrounded by a tranquil forest."
            },
            {
                title: "7. Wilson Point",
                description: "The highest point in Mahabaleshwar, ideal for witnessing stunning sunrises."
            },
            {
                title: "8. Sunset Point",
                description: "A perfect spot to enjoy mesmerizing sunset views over the hills."
            },
            {
                title: "9. Mahabaleshwar Temple",
                description: "An ancient temple dedicated to Lord Shiva, known for its intricate carvings."
            },
            {
                title: "10. Bhilar Waterfall",
                description: "A hidden gem known for its peaceful surroundings and serene beauty."
            }
        ],
        
        services : [
            {
                name: "Bus Hire in Hinjewadi Pune",
                description: "Discover reliable and comfortable bus hire services in Hinjewadi, Pune with SSKTravels24. We offer a variety of buses for rent, ideal for corporate events, team outings, and more."
            },
            {
                name: "Bus on Rent in Aundh Pune",
                description: "SSKTravels24 provides premium bus rental services in Aundh, Pune. Whether you need a bus for a family gathering or corporate event, we have the right vehicle for you."
            },
            {
                name: "Bus on Rent in Baner Pune",
                description: "Rent a bus in Baner, Pune, with SSKTravels24 for your next event or outing. Our fleet includes various bus sizes and styles, providing comfort and convenience for groups of all sizes."
            },
            {
                name: "Bus on Rent in Bhosari Pune",
                description: "Looking for bus rental services in Bhosari, Pune? SSKTravels24 offers a diverse range of buses for rent, ensuring you find the perfect vehicle for your requirements."
            },
            {
                name: "Urbania Bus on Rent in Pimpri Chinchwad",
                description: "Experience premium travel with Urbania buses on rent in Pimpri Chinchwad from SSKTravels24. Ideal for special events, our Urbania buses offer luxury and comfort for an exceptional travel experience."
            },
            {
                name: "Tempo Traveller on Rent in Pimpri Chinchwad",
                description: "For those needing more space and comfort, SSKTravels24 provides tempo travellers on rent in Pimpri Chinchwad. Ideal for group travel, our tempo travellers offer ample seating and luggage space."
            },
            {
                name: "17 Seater Bus on Rent in Akurdi Pune",
                description: "Rent a 17-seater bus in Akurdi, Pune with SSKTravels24 for an intimate group experience. Our well-maintained buses offer comfort and style for various events."
            },
            {
                name: "32, 35, 40, 45 Seater Bus Hire in Pimpri Chinchwad",
                description: "For larger groups, SSKTravels24 provides 32, 35, 40, and 45-seater buses in Pimpri Chinchwad. Our spacious and comfortable buses are perfect for corporate events, weddings, and other large gatherings."
            },
            {
                name: "Bus On Rent in Pimpri Chinchwad Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Bus On Rent in Pimpri Chinchwad. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
        tableData : [
            ['- Bus hire in Hinjewadi Pune', '- Bus on Rent in Aundh Pune'],
            ['- Bus On Rent in Baner Pune', '- Bus On Rent in Bhosari Pune'],
            ['- Mini Bus On Rent in Talawade Pune', '- Tempo Traveller on Rent in Pimpri Chinchwad'],
            ['- 17 Seater Bus on Rent in Akurdi Pune', '- 14, 17, 20, 25 Seater Bus Hire in Pimpri Chinchwad'],
            ['- 32, 35, 40, 45 Seater Bus Hire in Pimpri Chinchwad', '- Bus On Rent in Wakad Pune'],
            ['- Bus on Rent in Pimple Gurav Pune', '- Urbania Bus on Rent in Pimpri Chinchwad'],
            ['- Bus on Rent in Akurdi', '- Bus on Rent in Baner Aundh'],
            ['- 17 Seater Tempo Traveller on Rent in Pimpri Chinchwad', '- Mini Bus On Rent in Pimpri Chinchwad'],
            ['- Urbania on Rent in Pimpri Chinchwad', '']
        ],
    
        whychoose : [
            {
                "WhyChooseheading": "Comprehensive Fleet:",
                "WhyChoosedescription": "At SSKTravels24, we offer a diverse fleet of buses for rent in Pimpri Chinchwad, including mini buses, luxury coaches, and larger buses. Our extensive selection ensures that we have the right vehicle to meet your group size and travel needs."
            },
            {
                "WhyChooseheading": "Affordable Rates:",
                "WhyChoosedescription": "We provide competitive pricing without compromising on quality. Our transparent pricing structure ensures that you get value for your money, making your travel experience both cost-effective and enjoyable."
            },
            {
                "WhyChooseheading": "Reliable Service:",
                "WhyChoosedescription": "Timeliness and reliability are our top priorities. We pride ourselves on punctual service, ensuring that our buses arrive on time and that your journey is smooth and hassle-free."
            },
            {
                "WhyChooseheading": "Modern Amenities:",
                "WhyChoosedescription": "Our buses are equipped with modern amenities such as comfortable seating, air conditioning, and entertainment systems. We ensure that every ride is comfortable and enjoyable for our passengers."
            },
            {
                "WhyChooseheading": "Professional Drivers:",
                "WhyChoosedescription": "Our experienced and professional drivers are well-trained to handle various travel situations. They prioritize safety and customer satisfaction, providing a secure and pleasant travel experience."
            },
            {
                "WhyChooseheading": "Customized Solutions:",
                "WhyChoosedescription": "We understand that every trip is unique. Whether it's a corporate event, wedding, or group outing, we offer tailored solutions to meet your specific requirements and preferences."
            },
            {
                "WhyChooseheading": "Excellent Customer Support:",
                "WhyChoosedescription": "Our dedicated customer support team is available to assist you with any queries or concerns. We are committed to providing exceptional service from booking to the end of your journey."
            },
            {
                "WhyChooseheading": "Clean and Well-Maintained Vehicles:",
                "WhyChoosedescription": "We ensure that all our buses are regularly cleaned and maintained to the highest standards. This commitment to cleanliness and upkeep guarantees a comfortable and hygienic travel environment."
            },
            {
                "WhyChooseheading": "Flexible Booking Options:",
                "WhyChoosedescription": "We offer flexible booking options to accommodate changes in your travel plans. Our user-friendly booking process makes it easy to reserve a bus that suits your schedule and needs."
            },
            {
                "WhyChooseheading": "Local Expertise:",
                "WhyChoosedescription": "As a local service provider, we have in-depth knowledge of Pimpri Chinchwad and its surroundings. This local expertise allows us to offer valuable insights and recommendations for your journey."
            }
        ]        
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for rent in Pimpri Chinchwad?",
            answer: "We offer a variety of buses for rent in Pimpri Chinchwad, including mini buses, luxury coaches, and larger buses. Whether you need a small vehicle for a few passengers or a large coach for a big group, we have options to suit your needs."
        },
        {
            question: "How do I book a bus rental with SSKTravels24?",
            answer: "Booking a bus rental with us is simple. You can contact us directly via phone or email, or use our online booking system. Provide us with details about your trip, such as the date, time, and destination, and we will assist you in reserving the right bus for your needs."
        },
        {
            question: "Are your buses equipped with amenities?",
            answer: "Yes, our buses are equipped with modern amenities to ensure a comfortable journey. Depending on the bus type, amenities may include air conditioning, comfortable seating, entertainment systems, and more."
        },
        {
            question: "What is the pricing structure for bus rentals?",
            answer: "Our pricing is competitive and varies depending on factors such as the type of bus, rental duration, and distance. We provide transparent pricing with no hidden costs. For a detailed quote, please contact us with your specific requirements."
        },
        {
            question: "Are your drivers experienced and professional?",
            answer: "Yes, all our drivers are experienced and professional. They are trained to handle various travel situations and prioritize safety and customer satisfaction."
        },
        {
            question: "Can I make changes to my booking after it is confirmed?",
            answer: "Yes, we offer flexible booking options. If you need to make changes to your reservation, please contact us as soon as possible, and we will assist you in adjusting your booking."
        },
        {
            question: "How do you ensure the cleanliness and maintenance of your buses?",
            answer: "We take cleanliness and maintenance very seriously. Our buses are regularly cleaned and maintained to ensure they meet high standards of hygiene and safety."
        },
        {
            question: "What should I do if I have a problem during the journey?",
            answer: "If you encounter any issues during your journey, please contact our customer support team immediately. We are available to address any concerns and ensure that your travel experience remains pleasant."
        },
        {
            question: "Do you offer special packages for corporate events or large groups?",
            answer: "Yes, we offer customized packages for corporate events, weddings, and large groups. Contact us to discuss your specific requirements, and we will tailor a package that suits your needs."
        },
        {
            question: "How far in advance should I book a bus?",
            answer: "To ensure availability and the best rates, we recommend booking your bus as early as possible. For peak times or special events, booking well in advance is especially important."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. Rajesh Patel",
            text: "We recently rented a bus from SSKTravels24 for a corporate team outing in Pimpri Chinchwad, and we couldn't be happier with the service. The booking process was seamless, the bus was immaculate, and the driver was both professional and courteous. Our team had a comfortable and enjoyable journey, thanks to SSKTravels24. We highly recommend their services for any group travel needs!"
        },
        {
            name: "Ms. Priya Deshmukh",
            text: "I organized a family reunion and chose SSKTravels24 for our bus rental in Pimpri Chinchwad. From start to finish, their service was exceptional. The mini bus we rented was well-maintained, and the amenities provided made our trip delightful. The driver was friendly and knowledgeable, ensuring we avoided traffic on our journey. Thank you, SSKTravels24, for making our family gathering so memorable!"
        }
    ];
    
    const contactData = {
        heading: "Bus On Rent in Pimpri Chinchwad Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Bus On Rent in Pimpri Chinchwad | Reliable Transport Solutions</title>
  <meta name="description" content="Explore our bus rental services in Pimpri Chinchwad, offering a range of options including mini buses, Urbania buses, and tempo travellers for all your transportation needs." />
  <meta name="keywords" content="Bus On Rent in Pimpri Chinchwad, Bus hire in Hinjewadi Pune, Mini Bus On Rent in Talawade Pune, Tempo Traveller on rent in Pimpri Chinchwad, 17 Seater bus on Rent in Akurdi Pune" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Bus On Rent in Pimpri Chinchwad | Reliable Transport Solutions" />
  <meta property="og:description" content="Affordable and comfortable bus rentals in Pimpri Chinchwad. Options include mini buses, Urbania buses, and tempo travellers for corporate outings and events." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pimpri-chinchwad" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pimpri-chinchwad-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-04.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs for "Bus On Rent in Pimpri Chinchwad" - SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Busonrentinpimpri;
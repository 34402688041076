import React, { useEffect, useState } from 'react';
import backgroundImg from '../images/background/2.jpg'; // Adjust the path as needed

const StatsSection = () => {
  const [completedOrders, setCompletedOrders] = useState(0);
  const [happyCustomers, setHappyCustomers] = useState(0);
  const [vehiclesFleet, setVehiclesFleet] = useState(0);
  const [yearsExperience, setYearsExperience] = useState(0);

  useEffect(() => {
    const countTo = [
      { value: 15425, setter: setCompletedOrders },
      { value: 25545, setter: setHappyCustomers },
      { value: 235, setter: setVehiclesFleet },
      { value: 15, setter: setYearsExperience },
    ];

    countTo.forEach(({ value, setter }) => {
      let count = 0;
      const increment = value / 3000; // Duration for the counter in milliseconds

      const interval = setInterval(() => {
        if (count < value) {
          count += increment;
          setter(Math.floor(count));
        } else {
          clearInterval(interval);
          setter(value); // Ensure it ends exactly at the target value
        }
      }, 1); // Adjust the interval for smoother counting

      return () => clearInterval(interval);
    });
  }, []);

  return (
    <div>
      <section className="text-light jarallax" style={{ backgroundImage: `url(${backgroundImg})`, padding: '50px 0' }}>
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInRight">
              <h2 style={{ color: '#fff' }}>
                We offer customers a wide range of <span className="">commercial cars</span> and <span className="">luxury cars</span> for any occasion.
              </h2>
            </div>
            <div className="col-lg-6 wow fadeInLeft" style={{ color: '#fff' }}>
            At SSK Travels 24, we believe that exploring India should be accessible to everyone. That’s why we offer a wide range of comfortable and reliable travel options to suit every budget. From cozy sedans to spacious vans, our fleet is well-maintained and ready for your journey. With our hassle-free booking process, securing your adventure is quick and easy. Whether it’s a family vacation, a business trip, or a spontaneous getaway, SSK Travels 24 provides flexible options to fit your travel needs.
            </div>
          </div>
          <div className="spacer-double"></div>
          <div className="row text-center">
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count transparent text-light wow fadeInUp">
                <h3>{completedOrders}</h3>
                Completed Orders
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count transparent text-light wow fadeInUp">
                <h3>{happyCustomers}</h3>
                Happy Customers
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count transparent text-light wow fadeInUp">
                <h3>{vehiclesFleet}</h3>
                Vehicles Fleet
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mb-sm-30">
              <div className="de_count transparent text-light wow fadeInUp">
                <h3>{yearsExperience}</h3>
                Years Experience
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StatsSection;


import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel'; 
import './smallkey.css';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';


function Punetokonkan(){


    const cardData = 
    {
        keyword: ' Pune to Konkan Darshan Bus Hire',
        heading: 'SSKTravels24: Pune to Konkan Darshan Bus Hire',
        headingDescription: 'Planning a trip from Pune to the enchanting Konkan region? SSKTravels24 offers the ideal bus hire solutions to make your journey both comfortable and memorable. The stunning Konkan coastline, celebrated for its scenic beauty, lush landscapes, and pristine beaches, is a must-visit for travelers seeking relaxation and adventure. Our dedicated bus services ensure you travel in style while exploring the best of Konkan.',
        
        topPlaces : [
            {
                title: "1. Ganpatipule",
                description: "Known for its beautiful beaches and the revered Swayambhu Ganpati Temple, Ganpatipule is a serene coastal destination perfect for relaxation and spiritual experiences. The beach is lined with lush palm trees, and the temple attracts devotees from all over the country, especially during Ganesh Chaturthi."
            },
            {
                title: "2. Alibaug",
                description: "Famous for its sandy beaches, historic forts, and vibrant local markets, Alibaug is a popular getaway for both beach lovers and history enthusiasts. The Kolaba Fort and various water sports add to its appeal, making it an ideal destination for family trips and romantic getaways."
            },
            {
                title: "3. Kunkeshwar",
                description: "This town is known for the ancient Kunkeshwar Temple dedicated to Lord Shiva and its picturesque beaches, offering a great spot for both spiritual pursuits and beach activities. The serene atmosphere is perfect for meditation and relaxation, while the beach is less crowded than other popular destinations."
            },
            {
                title: "4. Ratnagiri",
                description: "Ratnagiri is rich in historical significance and boasts beautiful beaches. Key attractions include Ratnadurg Fort, Thibaw Palace, and the serene Mandvi Beach. The region is also famous for its Alphonso mangoes, making it a must-visit for food lovers."
            },
            {
                title: "5. Malvan",
                description: "Famous for Tarkarli Beach and Sindhudurg Fort, Malvan also offers vibrant local seafood cuisine, making it ideal for water sports and cultural exploration. Snorkeling, scuba diving, and visiting the nearby islands are popular activities that enhance the coastal experience."
            },
            {
                title: "6. Dapoli",
                description: "Dapoli features various attractions, including beaches, temples, and hill stations. Don’t miss the tranquil Murud Beach and the historic Keshavraj Temple. Dapoli is also known for its agricultural activities, offering fresh local produce and a glimpse into rural life."
            },
            {
                title: "7. Janjira Fort",
                description: "Located off the coast of Murud, Janjira Fort is a formidable sea fort with rich history. Its architecture and panoramic views make it a fascinating place to explore. Accessible only by boat, the fort offers a unique adventure and is a perfect spot for history buffs."
            },
            {
                title: "8. Harihareshwar",
                description: "Known for the Harihareshwar Temple and its tranquil beaches, this town is a peaceful escape from the hustle and bustle of city life. The natural beauty surrounding the temple and the quiet beaches make it a great destination for spirituality and relaxation."
            },
            {
                title: "9. Vengurla",
                description: "Vengurla offers beautiful beaches, historic temples, and a peaceful ambiance, perfect for a unique experience. The town is less commercialized, providing an authentic feel of Konkan culture, and is ideal for those looking to unwind away from crowded tourist spots."
            },
            {
                title: "10. Guhagar",
                description: "Guhagar is a quiet beach town known for its pristine beaches and ancient temples. Popular attractions include Guhagar Beach and Velneshwar Temple. The area is also famous for its coconuts and cashews, and visitors can enjoy local cuisine in a tranquil setting."
            }
        ],
        
        services : [
            {
                name: "Pune to Konkan Darshan Tempo Traveller Package",
                description: "Explore the scenic beauty and cultural heritage of Konkan with our customized Tempo Traveller packages. Ideal for group tours, our packages include comfortable transport to key attractions, ensuring a memorable and hassle-free experience."
            },
            {
                name: "Mini Bus on Rent in Ratnagiri",
                description: "For those visiting Ratnagiri, our mini buses provide a great solution for group travel. Enjoy spacious seating and comfortable amenities while exploring this beautiful coastal region."
            },
            {
                name: "Pune to Palghar Bus on Rent",
                description: "Travel from Pune to Palghar with ease using our reliable bus rental service. Our well-maintained buses are equipped for a comfortable journey, making your trip enjoyable and stress-free."
            },
            {
                name: "Pune to Raigad Darshan Bus on Rent",
                description: "Our bus rental service for Pune to Raigad Darshan provides a convenient way to visit the historic Raigad Fort. Enjoy a smooth ride with our well-equipped buses designed for group travel."
            },
            {
                name: "Pune to Sindhudurg Bus Hire for Picnic",
                description: "Plan a fun and relaxing picnic to Sindhudurg with our bus hire services. Ideal for group outings, our buses offer ample space and comfort for a delightful trip."
            },
            {
                name: "Pune to Imagica Bus Hire for Picnic",
                description: "Make your trip to Imagica theme park enjoyable with our bus hire services. Perfect for family outings or group trips, our buses ensure a comfortable ride to one of India’s premier amusement parks."
            },
            {
                name: "Pune to Sawantwadi Bus Hire for Picnic",
                description: "Explore the vibrant town of Sawantwadi with our bus hire service. Ideal for picnics and group excursions, our buses provide a convenient way to travel from Pune."
            },
            {
                name: "Pune to Konkan Darshan Urbania on Rent",
                description: "Travel in style with our Urbania rental for a Konkan Darshan. Perfect for larger groups or special occasions, the Urbania offers premium comfort and luxury as you explore the region."
            },
            {
                name: "Pune to Devgad Bus on Rent for Picnic",
                description: "Our bus rental service for travel to Devgad is perfect for a relaxing picnic. Enjoy the beauty of Devgad with our reliable buses designed for a pleasant journey."
            },
            {
                name: "Hire Pune to Diveagar Mini Bus & Tempo Traveller on Rent",
                description: "For trips to Diveagar, choose from our mini buses and tempo travellers. Both options provide comfort and flexibility for exploring this charming coastal village."
            },
            {
                name: "SSKTravels24 Pune On Rent",
                description: "SSKTravels24 is your go-to provider for vehicle rentals in Pune. From Konkan Darshan to picnics and special trips, our diverse fleet includes buses, mini buses, tempo travellers, and Urbania rentals tailored to your needs."
            },
            {
                name: "Ganpatipule Tour Package from Pune",
                description: "Experience the divine beauty of Ganpatipule with our comprehensive tour package from Pune, including comfortable transport and tailored itineraries for an unforgettable visit."
            },
            {
                name: "Pune to Alibaug Bus on Rent",
                description: "Travel to Alibaug with our reliable bus rental service. Ideal for beach outings and group excursions, our buses ensure a comfortable and enjoyable journey."
            },
            {
                name: "Pune to Konkan Darshan Bus Package",
                description: "Our Konkan Darshan bus package offers a complete solution for exploring the Konkan region. Enjoy a well-organized tour with comfortable transportation and guided visits to key attractions."
            },
            {
                name: "Tempo Traveller on Rent for Konkan Trip from Pune",
                description: "If you're planning a trip to Konkan, our tempo travellers are an excellent choice for spacious travel. Designed for group excursions, these vehicles ensure a comfortable journey."
            },
            {
                name: "Pune to Ratnagiri Tempo Traveller Hire on Rent",
                description: "Travel from Pune to Ratnagiri with ease using our tempo traveller rental service. Ideal for both small and large groups, our tempo travellers provide a smooth ride to one of Konkan's most scenic destinations."
            },
            {
                name: "Pune to Goa Urbania on Rent",
                description: "Make your trip to Goa memorable with our Urbania rentals. Ideal for groups and special events, the Urbania offers a luxurious and comfortable ride from Pune to Goa."
            },
            {
                name: "SSKTravels24 Pune On Rent",
                description: "SSKTravels24 is your trusted partner for all vehicle rental needs from Pune. We provide a range of options, including tempo travellers, mini buses, and Urbania rentals to suit your travel requirements. Whether you're planning a trip to Konkan, Ratnagiri, or Goa, we ensure a smooth and enjoyable travel experience."
            },
            {
                name: "Pune to Konkan Darshan Bus Hire Contact Number",
                description: "Contact SSKTravels24 at +91 8956170493 for prompt and efficient Pune to Konkan Darshan Bus Hire in Pune. We ensure a smooth and enjoyable ride for all our customers."
            }
        ],
         tableData : [
            ['- Pune to Konkan Darshan Tempo Traveller Package', '- Mini Bus On Rent in Ratnagiri'],
            ['- Pune to Palghar Bus on Rent', '- Pune to Raigad Darshan Bus on Rent'],
            ['- Pune to Sindhudurg Bus Hire for Picnic', '- Pune to Konark Bus Hire for Picnic'],
            ['- Pune to Imagica Bus Hire for Picnic', '- Pune to Sawantwadi Bus Hire for Picnic'],
            ['- Pune to Konkan Darshan Urbania on Rent', '- Pune to Devgad Bus on Rent for Picnic'],
            ['- Hire Pune to Diveagar Mini Bus & Tempo Traveller on Rent', '- Ganpatipule Tour Package from Pune'],
            ['- Pune to Alibaug Bus on Rent', '- Pune to Konkan Darshan Bus Package'],
            ['- Tempo Traveller on Rent for Konkan Trip from Pune', '- Pune to Ratnagiri Tempo Traveller Hire on Rent'],
            ['- Pune to Konkan Darshan Urbania on Rent', '- Pune to Ratnagiri Tempo Traveller Hire on Rent Near Me'],
            ['- Pune to Konkan Darshan Mini Bus on Rent', '- Pune to Konkan Darshan Urbania on Rent'],
            ['- Pune to Goa Urbania on Rent', '']
        ],
        
    
        whychoose : [
            {
                "WhyChooseheading": "Why Choose Us for Pune to Konkan Darshan Bus Hire – SSKTravels24",
                "WhyChoosedescription": "When traveling from Pune to the stunning Konkan region, SSKTravels24 is your top choice for exceptional bus hire services, ensuring a comfortable and hassle-free journey for family vacations, group outings, or pilgrimage tours."
            },
            {
                "WhyChooseheading": "Diverse Fleet of Vehicles:",
                "WhyChoosedescription": "We offer a variety of buses, including 32, 35, 40, 45, and 50-seater options, all meticulously maintained and equipped with modern amenities for your comfort."
            },
            {
                "WhyChooseheading": "Comfort and Luxury:",
                "WhyChoosedescription": "Our buses feature spacious seating, air conditioning, and clean interiors, prioritizing your travel experience to ensure it’s both pleasant and enjoyable."
            },
            {
                "WhyChooseheading": "Experienced Drivers:",
                "WhyChoosedescription": "Our professional drivers are knowledgeable and well-versed in the routes, guaranteeing a safe and smooth journey while being courteous and attentive to your needs."
            },
            {
                "WhyChooseheading": "Competitive Pricing:",
                "WhyChoosedescription": "We provide transparent and competitive pricing with no hidden fees, ensuring our rental packages offer value for money."
            },
            {
                "WhyChooseheading": "Flexible Packages:",
                "WhyChoosedescription": "Whether you require a one-day trip or an extended tour, we offer flexible rental packages tailored to your itinerary and preferences."
            },
            {
                "WhyChooseheading": "Excellent Customer Service:",
                "WhyChoosedescription": "Our dedicated customer support team is available to assist you with bookings, inquiries, and any issues that may arise during your trip."
            },
            {
                "WhyChooseheading": "Well-Maintained Vehicles:",
                "WhyChoosedescription": "Safety and reliability are our top priorities, with regular maintenance and inspections ensuring our buses are in excellent condition."
            },
            {
                "WhyChooseheading": "Customized Itineraries:",
                "WhyChoosedescription": "We help you plan and customize your itinerary to include must-visit spots in Konkan, ensuring you make the most of your travel experience."
            }
        ]
    };    

    
   
    const faqData = [
        {
            question: "What types of buses are available for Pune to Konkan Darshan?",
            answer: "We offer a range of buses including 32, 35, 40, 45, and 50-seater options. Our fleet includes both standard and luxury vehicles to suit your group size and comfort preferences."
        },
        {
            question: "How do I book a bus for Konkan Darshan?",
            answer: "You can book a bus by contacting us directly through our website or customer service. Provide details of your trip, including dates, number of passengers, and any specific requirements."
        },
        {
            question: "Are the buses equipped with air conditioning?",
            answer: "Yes, all our buses come with air conditioning to ensure a comfortable journey, regardless of the weather."
        },
        {
            question: "How much does it cost to hire a bus for Konkan Darshan?",
            answer: "The cost varies based on factors such as the type of bus, duration of hire, and distance traveled. We provide transparent pricing and can offer a detailed quote based on your specific needs."
        },
        {
            question: "Can I customize the itinerary for my Konkan trip?",
            answer: "Absolutely! We will help you plan and customize your itinerary to include top attractions and destinations in Konkan, ensuring a memorable experience."
        },
        {
            question: "Are there any additional charges apart from the rental fee?",
            answer: "Our pricing is transparent, and we aim to avoid hidden charges. Any additional costs, such as tolls or parking fees, will be communicated in advance."
        },
        {
            question: "How do I ensure the safety of my group during the trip?",
            answer: "Safety is our priority. All our buses are regularly maintained and inspected, and our experienced drivers are trained to navigate various road conditions to ensure a safe journey."
        },
        {
            question: "What if I need to make changes to my booking?",
            answer: "If you need to modify your booking, please contact us as soon as possible. We will do our best to accommodate your changes based on availability and applicable terms."
        },
        {
            question: "Is there a provision for luggage storage on the bus?",
            answer: "Yes, our buses are equipped with ample luggage storage space to keep your belongings safe during the trip."
        },
        {
            question: "How far in advance should I book the bus?",
            answer: "We recommend booking as early as possible to secure your preferred vehicle and dates. However, we can also accommodate last-minute bookings based on availability."
        }
    ];
    
    
    const testimonials = [
        {
            name: "Mr. and Mrs. Deshmukh",
            text: "Our journey to Konkan with SSKTravels24 was truly exceptional! We hired a 45-seater bus for our family trip, and it was just perfect. The bus was spotless, comfortable, and well-equipped, making our long journey incredibly pleasant. The driver was professional, courteous, and knew the best routes to take. SSKTravels24 made our Konkan Darshan unforgettable, and we highly recommend their services for anyone planning a group trip!"
        },
        {
            name: "Mr. and Miss Kumar",
            text: "We recently chose SSKTravels24 for a corporate outing to Konkan, and the experience was fantastic. We rented a 35-seater bus, which was both comfortable and in excellent condition. The driver was punctual and managed the trip with great professionalism. SSKTravels24 provided top-notch service, and our team thoroughly enjoyed the experience. We will definitely choose SSKTravels24 for our future travel needs. Highly recommended!"
        }
    ];
    
    
    const contactData = {
        heading: "Pune to Konkan Darshan Bus Hire Contact Number",
        contactNumbers: [
            "+91 8956170493",
            "+91 7276307000"
        ],
        email: "booking@ssktravels24.com"
    };
    
    
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      



      const Images = [
        {
            place: "/images/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/images/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/images/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/images/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/images/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/images/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/images/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];






   return(
        <div>
        <GoogleAnalytics/>
          <Helmet>
  <title>Contact: +91 8956170493 | SSK Travels24 | Pune to Konkan Darshan Bus Hire | Explore the Konkan Coast</title>
  <meta name="description" content="Enjoy a memorable Konkan Darshan with our bus hire services from Pune, covering Ratnagiri, Alibaug, Ganpatipule, and more." />
  <meta name="keywords" content="Pune to Konkan Darshan Bus Hire, Tempo Traveller for Konkan Trip, Pune to Ratnagiri Bus on Rent, Konkan Darshan Package from Pune, Bus Hire for Picnic in Konkan" />
  <meta property="og:title" content="Contact: +91 8956170493 | SSK Travels24 | Pune to Konkan Darshan Bus Hire | Explore the Konkan Coast" />
  <meta property="og:description" content="Book your bus for a delightful Konkan Darshan from Pune, including visits to popular destinations like Ganpatipule and Imagica." />
  <meta property="og:url" content="https://https://ssktravels24.com//pune-konkan-darshan-bus-hire" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//konkan-darshan-bus-image.jpg" />
</Helmet>

 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>{cardData.keyword}</h2>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>



        <section>
    <div className="container-fluid" >
<div className="row  g-0">
<div className="col-12 col-md-7 ">
    <img src='\images\keyword-base\Keyword 1-10.jpg' alt='img'/>
<h3 className="py-1"
           style={{
            color: '#913923', // Red color for the title 
            textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
            fontWeight:'bold'
          }}

>{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
<div className="">
    {cardData.topPlaces.map((place, index) => (
      <div key={index} className="">
        <div 
          style={{ 
            cursor: 'pointer',
            padding: '10px',
            marginBottom: '7px',
            borderRadius: '8px', // Optional: rounded corners
            transition: 'transform 0.2s' // Optional: smooth scaling effect
          }}
           // Centers text within the card
        >
          <h3 className="mb-1 darkcolor">{place.title}</h3>
          <p className="mb-0">{place.description}</p>
        </div>
      </div>
    ))}
  </div>
    <div 
              style={{
                cursor: 'pointer',
                padding: '10px',
                marginBottom: '7px',
                borderRadius: '8px', // Optional: rounded corners
                transition: 'transform 0.2s' // Optional: smooth scaling effect
              }}
    >
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 darkcolor">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key my-2">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key ' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>



   <div id="why-choose-section"
   className='px-2'>
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 darkcolor">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
    <div>
      <div>
        <h3 className='Colo'>FAQs – Pune to Konkan Darshan Bus Hire with SSKTravels24</h3>
        <Accordion data={faqData} />
      </div>

      <CustomCarousel items={carouselItems} options={carouselOptions} />
    </div>

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='darkcolor'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='darkcolor'>Email Id: </strong>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" 
            style={{
              cursor: 'pointer',
              padding: '10px',
              border: '1px solid #ddd',
              backgroundColor:'#913923',
              marginBottom: '7px',
              fontWeight:'bold',
              color:'white'
            }}


>
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8956170493" className="d-block   text-white">+91 8956170493</a>
            <a href="tel:+91 7276307000" className="d-block   text-white">+91 7276307000</a>
            
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@ssktravels24.com" className=" text-white d-block">
              booking@ssktravels24.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border rounded text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
               
              SSKTravels24:<br />
              office No 109, Ashoka Plaza,<br />
              First Floor, Vimannagar<br />
              Pune Maharashtra 411014
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</div>
<BusRatesTable/>
</div>
    </div>
</section>






        </div>
    );
}

export default Punetokonkan;
import React from 'react';
import BusRatesTable from './BusRatesTable';
import { Helmet } from 'react-helmet'
import GoogleAnalytics from './GoogleAnalytics';
const Packages = () => {
  

  return (
  <div>
    <GoogleAnalytics/>
    <Helmet>
  <title>Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa</title>
  <meta name="description" content="Explore our bus rental services for trips from Pune to Goa. Options include mini buses, tempo travellers, and luxury buses for all group sizes." />
  <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller, Pune to Goa Urbania on Rent, AC Bus Hire Pune to Goa" />
  <meta property="og:title" content="Bus On Rent in Pune | SSK Travels24 |  +91 8956170493 | Comfortable Travel to Goa" />
  <meta property="og:description" content="Book reliable and affordable bus rental services for your trips from Pune to Goa. Enjoy comfortable travel with family or colleagues." />
  <meta property="og:url" content="https://https://ssktravels24.com//bus-on-rent-in-pune" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://https://ssktravels24.com//pune-goa-bus-image.jpg" />
</Helmet>
 <section id="about" className="jarallax text-light">
            <div className="center-y relative text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Packages</h1>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </section>

<BusRatesTable/>

  </div>
  );
};

export default Packages;
